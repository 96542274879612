import clsx from 'clsx';
import { TOpeningHours } from 'models';

import Typography from '@mui/material/Typography';

type Props = {
  openingTimes?: TOpeningHours;
  textClassName?: string;
};

const OpeningHours = ({ openingTimes, textClassName = 'text-13' }: Props) => {
  return (
    <>
      {openingTimes?.map(({ startTime, endTime }) => {
        if (!startTime || !endTime) return null;

        return (
          <Typography
            className={clsx('font-500 text-secondaryMain', textClassName)}
            key={`${startTime}-${endTime}`}
          >
            {startTime} - {endTime}
          </Typography>
        );
      })}
    </>
  );
};

export default OpeningHours;
