import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const Text = ({ data, name, listId, readOnly, view, isAudit, canAuditInteractions = true }: Props) => {
  const { resetField, setValue, setError, clearErrors } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({ name });
  const isRequired = value?.item?.required;

  const { mutateAsync: editHistoryTask, isLoading } = useEditHistoryTask({ taskId: listId, responseId: value?.id });

  const { showError, showSuccess } = useShowMessage();

  const handleAnswerChange = (event: any) => {
    const nextAnswer = event.target.value;
    onChange({ ...value, answer: nextAnswer });
  };

  const handleAnswerSend = () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  useEffect(() => {
    if (isRequired && value?.answer) clearErrors(name);
  }, [value?.answer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && !value?.answer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const answer = async (payload: any) => {
    try {
      const res = await editHistoryTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      name={name}
      onSaveEdit={handleAnswerSend}
      view={view}
      isLoading={isLoading}
      isAudit={isAudit}
      canAuditInteractions={canAuditInteractions}
    >
      <div className="relative">
        <TextField
          value={value?.answer || ''}
          multiline
          className="w-full bg-gray-200 rounded-20"
          sx={{
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' },
            minHeight: '100px',
          }}
          onChange={handleAnswerChange}
          disabled={readOnly}
        />
      </div>
    </QuestionLayoutMapper>
  );
};
export default Text;
