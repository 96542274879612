import useOnClickOutside from 'app/hooks/use-on-click-outside';
import useShowMessage from 'app/hooks/use-show-message';
import useTask from 'app/hooks/use-task';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { TResponse } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';

type Props = {
  disabled?: boolean;
  title: string;
  value?: any;
  listStatus?: TResponse['status'];
  scanStatus?: string;
  isLoading?: boolean;
  onComplete?: (code: string, comment: string, options?: any) => void;
};

const ScanCode = ({ disabled, value, title, listStatus, scanStatus, isLoading, onComplete }: Props) => {
  const { showError, showSuccess } = useShowMessage();
  const { state: isOpenModal, setActive: showModal, setInactive: hideModal } = useToggle(false);
  const [tmpCode, setTmpCode] = useState('');
  const [tmpComment, setTmpComment] = useState('');
  const [isFailed, setIsFailed] = useState(false);
  const commentRef = useRef(null);
  const isCommentFocused = useRef(false);
  const actionColor = listStatus === 'overdue' ? 'overdue.main' : 'primary.main';
  const { triggerSubmitTask } = useTask();

  const isCommentChanged = useMemo(() => {
    if (!tmpComment && !value?.comment) return false;
    return tmpComment !== value?.comment;
  }, [value, tmpComment]);

  const isCodeChanged = useMemo(() => {
    if (!value?.code && !tmpCode) return false;
    return value?.code !== tmpCode;
  }, [value?.code, tmpCode]);

  const handleAnswerSuccess = useCallback(
    (res: any) => {
      const { matched, comment } = res?.answer || {};

      if (!matched && (isCodeChanged || !comment)) {
        showError('Could not submit your answer');
        setIsFailed(true);
        return;
      }

      showSuccess('Your answer has been submitted successfully');
      setIsFailed(false);
      hideModal();
    },
    [showSuccess, showError, hideModal, isCodeChanged],
  );

  useOnClickOutside(commentRef, (event: any) => {
    if (!isCommentFocused.current || !isCommentChanged) return;
    onComplete?.(value?.code, tmpComment, {
      onSuccess: handleAnswerSuccess,
    });
    triggerSubmitTask(event?.target);
  });

  useEffect(() => {
    setTmpCode(value?.code || '');
    setTmpComment(value?.comment || '');
  }, [value, isOpenModal]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: reason } = event.target;
    setTmpComment(reason);
  };

  const handleSendComment = () => {
    onComplete?.(value?.code, tmpComment, {
      onSuccess: handleAnswerSuccess,
    });
  };

  const handleComplete = () => {
    onComplete?.(tmpCode, tmpComment, {
      onSuccess: handleAnswerSuccess,
    });
  };

  const handleManuallyCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: code } = event.target;
    setTmpCode(code);
  };

  const handleClearForm = () => {
    setTmpCode('');
    setTmpComment('');
  };

  const handleClose = () => {
    hideModal();
    setTmpCode('');
  };

  const handleCommentFocus = () => {
    isCommentFocused.current = true;
  };

  const handleCommentBlur = () => {
    isCommentFocused.current = false;
  };

  return (
    <>
      <div>
        <button
          disabled={disabled}
          type="button"
          className={clsx(
            'flex items-center justify-center w-full h-40 space-x-8 bg-gray-200 rounded-20',
            disabled && 'cursor-not-allowed',
          )}
          onClick={showModal}
        >
          <PhotoCameraOutlinedIcon className="text-secondaryLight" />
          <Typography className="text-secondaryLight font-600 text-13">{title}</Typography>
          {scanStatus === 'success' && (
            <CheckIcon
              className="font-bold text-24"
              sx={{ color: actionColor }}
            />
          )}
          {scanStatus === 'fail' && (
            <CloseIcon
              className="font-bold text-24"
              sx={{ color: 'error.main' }}
            />
          )}
        </button>

        <div className="mt-16">
          <Typography className="mb-2 text-11 text-secondaryLight font-400">
            If Fail, please state your reason
          </Typography>
          <div
            className="relative"
            ref={commentRef}
          >
            <TextField
              value={tmpComment || ''}
              placeholder="State your reason here"
              multiline={true}
              className="w-full bg-gray-200 rounded-20"
              sx={{
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                minHeight: '100px',
              }}
              disabled={disabled}
              onChange={handleCommentChange}
              onFocus={handleCommentFocus}
              onBlur={handleCommentBlur}
            />
            {!disabled && isCommentChanged && (
              <IconButton
                className="absolute bottom-0 right-0 mb-12 mr-12"
                sx={{ color: actionColor }}
                onClick={handleSendComment}
              >
                <SendIcon
                  className="text-16"
                  color="inherit"
                />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={isOpenModal}
        className="flex items-center justify-center"
      >
        <Card
          sx={{ width: '500px' }}
          className="relative bg-white rounded-16"
        >
          <CardHeader
            title={title}
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon className="text-20" />
              </IconButton>
            }
            classes={{ title: 'text-18 font-600 text-secondaryMain' }}
            className="px-24"
          />
          <Divider />

          <CardContent className="px-24 pb-40 space-y-24">
            <Typography className="mb-16 text-12 text-secondaryLight">
              Code scanner is only available on the mobile app. Currently you can only enter manually code
            </Typography>

            {/* code input */}
            <div className="relative">
              <Typography className="mb-8 text-11 font-500 text-secondaryLight">Manually code</Typography>
              <TextField
                value={tmpCode || ''}
                placeholder="Enter your code"
                className="w-full"
                InputProps={{
                  classes: {
                    root: 'w-full bg-gray-200 rounded-20',
                    input: 'py-8',
                  },
                }}
                FormHelperTextProps={{
                  classes: {
                    root: 'text-11 text-errorMain',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                disabled={disabled}
                onChange={handleManuallyCodeChange}
                helperText={isFailed ? 'The manually code is invalid' : undefined}
              />
            </div>

            {/* failed reason */}
            {scanStatus === 'fail' && (
              <div>
                <Typography className="mb-8 text-11 font-500 text-secondaryLight">
                  If Fail, please state your reason
                </Typography>
                <TextField
                  value={tmpComment || ''}
                  placeholder="State your reason here"
                  multiline={true}
                  className="w-full bg-gray-200 rounded-20"
                  sx={{
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    minHeight: '100px',
                  }}
                  disabled={disabled}
                  onChange={handleCommentChange}
                />
              </div>
            )}
          </CardContent>

          <Divider />
          <CardActions className="flex items-center justify-end px-24">
            <Button
              disableElevation={true}
              variant="outlined"
              sx={{ color: actionColor, borderColor: actionColor }}
              className="capitalize text-13 w-104 font-400"
              onClick={handleClearForm}
            >
              Clear
            </Button>
            <Button
              disableElevation={true}
              variant="contained"
              sx={{ backgroundColor: actionColor }}
              className="capitalize text-13 w-104 font-400"
              onClick={handleComplete}
            >
              Complete
            </Button>
          </CardActions>

          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="absolute inset-0 bg-white opacity-50" />
              <CircularProgress size={24} />
            </div>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default ScanCode;
