import { convertToAbbreviations } from 'utils/string';

import { Avatar, Tooltip } from '@mui/material';

type Props = {
  users?: { fullName: string; id: number }[];
};

function HeaderGroupUsers({ users }: Props) {
  if (!users || users.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-wrap items-center justify-start flex-1 mt-8">
      {users?.map(user => {
        return (
          <Tooltip
            title={user.fullName}
            key={user.id}
          >
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                width: 36,
                height: 36,
                fontSize: 14,
                margin: '0 4px 4px 0',
              }}
            >
              {convertToAbbreviations(user.fullName)}
            </Avatar>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default HeaderGroupUsers;
