import TextInput from 'app/components/cores/inputs/text';
import { useState } from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';

const modalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  bgcolor: 'background.default',
  borderRadius: 2,
  paddingTop: 1,
  paddingBottom: 2,
  width: 640,
  maxWidth: '90%',
  minHeight: 400,
  maxHeight: '90%',
  margin: '0 auto',
  overflow: 'hidden',
};

type Props = {
  open: boolean;
  control?: Control;
  name: string;
  defaultOverrideSubject?: string;
  defaultMessage: string;
  onCancel?: () => void;
  onUpdate?: (overrideSubject: string, message: string) => void;
};

export function NotifyModal({
  open,
  name,
  control,
  defaultOverrideSubject,
  defaultMessage,
  onCancel,
  onUpdate,
}: Props) {
  const [overrideSubject, setOverrideSubject] = useState(defaultOverrideSubject || '');
  const [message, setMessage] = useState(defaultMessage || '');

  const { getValues } = useFormContext();
  const { prompt } = useWatch({ name, control });
  const list = getValues('name');

  const handleChangeSubject = (event: any) => {
    const { value } = event.target;
    setOverrideSubject(value);
  };

  const handleChangeMessage = (event: any) => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handlePreview = () => {
    window.open(`/task-notify-preview?subject=${overrideSubject}&message=${message}&task=${prompt}&list=${list}`);
  };

  const handleUpdate = () => {
    onUpdate?.(overrideSubject, message);
  };

  return (
    <Modal
      open={open}
      sx={modalStyles}
    >
      <Box sx={boxStyles}>
        <div className="sticky top-0 flex items-center justify-between pl-24 pr-12">
          <Typography className="text-20 font-600">Update Message</Typography>
          <div className="flex items-center space-x-8">
            <Tooltip title="Preview">
              <IconButton onClick={handlePreview}>
                <VisibilityOutlinedIcon className="text-20 text-secondaryMain" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={handleCancel}>
                <CloseIcon className="text-20 text-secondaryMain" />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="p-24">
          <Typography className="mb-4 text-small-light">Subject</Typography>
          <TextInput
            value={overrideSubject}
            className="w-full px-12 bg-paper rounded-8"
            variant="standard"
            InputProps={{
              className: 'min-h-32',
              disableUnderline: true,
              classes: { disabled: 'text-secondaryLight' },
            }}
            multiline={true}
            placeholder="Enter new subject"
            onChange={handleChangeSubject}
          />
        </div>

        <div className="flex flex-col flex-1 px-24">
          <Typography className="mb-4 text-small-light">Message</Typography>
          <div className="flex flex-col flex-1">
            <TextInput
              value={message}
              onChange={handleChangeMessage}
              className="flex-1 w-full px-12 bg-paper rounded-8"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: 'flex-1 items-start',
                  disabled: 'text-secondaryLight',
                },
              }}
              multiline={true}
              placeholder="Enter new message"
            />
          </div>
        </div>

        <div className="self-end px-24 pt-32 space-x-8">
          <Button
            disableElevation={true}
            variant="contained"
            className="bg-gray-200 text-11 font-400 text-secondaryMain hover:bg-gray-400"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="text-11 font-400"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
