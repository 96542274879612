import clsx from 'clsx';

import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      margin: '0 !important',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      flex: 1,
      '&:first-child': {
        marginBottom: '8px !important',
      },
      '&:last-child': {
        marginTop: '8px !important',
      },
    },
  },
  cardContent: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '12px !important',
    },
  },
  title: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1rem !important',
    },
  },
  statistic: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2. 6rem !important',
    },
  },
}));

type Props = {
  title: string;
  statistic: string | number;
  isLoading?: boolean;
  classes?: {
    root?: string;
    title?: string;
    cardContent?: string;
    statistic?: string;
  };
};

const BoxStatistic = ({ title, statistic, isLoading, classes: propClasses }: Props) => {
  const classes = useStyles({ classes: propClasses });

  if (isLoading)
    return (
      <Card className={clsx('w-full bg-white rounded-8', classes?.root)}>
        <CardContent className={clsx('pl-24 pr-16', classes?.cardContent)}>
          <Skeleton
            animation="wave"
            variant="rounded"
            className="w-full h-full min-w-104 min-h-40"
          />
        </CardContent>
      </Card>
    );

  return (
    <Card className={clsx('w-full bg-white rounded-8', classes?.root)}>
      <CardContent className={clsx('pl-24 pr-16', classes?.cardContent)}>
        <Typography className={clsx('text-12 font-600 text-secondaryLight', classes?.title)}>{title}</Typography>
        <Typography className={clsx('tracking-tighter  font-500 text-secondaryMain', classes?.statistic)}>
          {statistic?.toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BoxStatistic;
