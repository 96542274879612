/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import { TLocation } from 'models';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { getFilterFromURL } from 'utils/url';

import Typography from '@mui/material/Typography';

type Props = {
  locations: TLocation[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  onFetchData: (params: any) => void;
  toggleDrawer: (open: boolean, unitId: number) => void;
};

function LocationList({
  locations,
  pageCount,
  totalDataCount,
  isLoading,
  searchKey,
  onFetchData,
  toggleDrawer,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const [filters, setFilters] = React.useState(getFilterFromURL(location?.search));

  const handlePreviewClick = (row: any) => {
    const locationId = row?.original?.externalId;
    toggleDrawer(true, locationId);
  };

  const openingHoursStatusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Configured', value: '0' },
    { label: 'Not Configured', value: '1' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: '30%',
        Cell: ({ row }: { row: Row<TLocation> }) => {
          return (
            <Link
              onClick={event => {
                event.stopPropagation();
              }}
              to={`/locations/${row.original.externalId}`}
            >
              <Typography className="cursor-pointer font-600">{row.original?.name}</Typography>
            </Link>
          );
        },
      },
      {
        Header: 'Regions',
        accessor: 'regions',
        id: 'regions_name',
        sortable: false,
        width: '20%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TLocation> }) => {
          return <div className="pl-6">{cell.value?.map((region: { name: string }) => region.name).join(', ')}</div>;
        },
      },
      {
        Header: 'Opening Hours',
        accessor: 'openingHoursStatus',
        id: 'location_opening_hours_id_null',
        sortable: false,
        width: '15%',
        options: openingHoursStatusOptions,
        Filter: SelectFilter,
      },
      {
        Header: 'No. Holidays',
        accessor: 'totalPublicHolidays',
        sortable: false,
        width: '10%',
        Filter: <></>,
      },
      {
        Header: 'No. Lists',
        accessor: 'totalTasks',
        sortable: false,
        width: '10%',
        Filter: <></>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: true,
        width: '10%',
        Filter: <div />,
        className: 'capitalize',
      },
    ],
    // eslint-disable-next-line
    [location],
  );

  return (
    <div className="flex flex-col w-full px-12">
      <IndexTable
        columns={columns as Column<any>[]}
        data={locations}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        onRowClick={handlePreviewClick}
      />
    </div>
  );
}

export default LocationList;
