import TextInput from 'app/components/cores/inputs/text';
import clsx from 'clsx';
import _ from 'lodash';
import { Control, useController, useFormState, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@mui/material';

type Props = {
  name: string;
  control?: Control;
};

function YesNoScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreEnabled = useWatch({ name: scoreEnabledName, control });
  const { errors } = useFormState({ control });

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({ name: scoreSettingsName, control });

  const yesValue = _.find(scoreSettings, { value: 'true' });
  const noValue = _.find(scoreSettings, { value: 'false' });

  const handleChange = (type: 'true' | 'false') => (event: any) => {
    if (!/^\d*$/.test(event.target.value)) return;

    const nextScore = Number(event.target.value);
    const nextScoreSettings = _.cloneDeep(scoreSettings) || [];
    const currentSetting = _.find(nextScoreSettings, { value: type });

    if (currentSetting) {
      currentSetting.score = nextScore ?? 0;
      onChangeScoreSettings(nextScoreSettings);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between px-24 mb-4">
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score if answer is</Typography>
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score</Typography>
      </div>

      <div className="flex flex-col px-24 gap-y-12">
        {/* yes */}
        <div>
          <div className={clsx('flex items-center justify-between', !scoreEnabled && 'cursor-not-allowed')}>
            <Typography className="flex-1 text-secondaryLight text-11 font-500">Yes</Typography>
            <div className={clsx('flex-1', !scoreEnabled && 'pointer-events-none')}>
              <TextInput
                placeholder="Enter score"
                className={clsx('w-full px-8 duration-200 rounded-8', scoreEnabled ? 'bg-paper' : 'bg-gray-200')}
                variant="standard"
                InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-500', disableUnderline: true }}
                value={yesValue?.score ?? ''}
                onChange={handleChange('true')}
              />
              <ErrorMessage
                name={`${scoreSettingsName}.0.score`}
                errors={errors}
                render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
              />
            </div>
          </div>
        </div>

        {/* no */}
        <div>
          <div className={clsx('flex items-center justify-between', !scoreEnabled && 'cursor-not-allowed')}>
            <Typography className="flex-1 text-secondaryLight text-11 font-500">No</Typography>
            <div className={clsx('flex-1', !scoreEnabled && 'pointer-events-none')}>
              <TextInput
                placeholder="Enter score"
                className={clsx('w-full px-8 duration-200 rounded-8', scoreEnabled ? 'bg-paper' : 'bg-gray-200')}
                variant="standard"
                InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-500', disableUnderline: true }}
                value={noValue?.score ?? ''}
                onChange={handleChange('false')}
              />
              <ErrorMessage
                name={`${scoreSettingsName}.1.score`}
                errors={errors}
                render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YesNoScoreSetting;
