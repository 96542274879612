/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  manager: ['admin', 'manager'],
  employee: ['admin', 'manager', 'employee'],
  onlyGuest: [],
};

export default authRoles;
