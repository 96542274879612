import Header from 'app/components/Layout/headers/blank-header-v2';
import DateRange from 'app/components/cores/date-range';
import UserSelect from 'app/components/cores/select/user';
import ListMonitorList from 'app/components/lists-monitor/list';
import { useGetAdminCalendar } from 'app/hooks/api/admin/calendar';
import { useFilter } from 'app/hooks/use-filter';
import { useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { convertDateStringToString } from 'utils/string';
import { getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';

export const DEFAULT_PAGINATION = { page: 1, per_page: 200 };

interface Props extends RouteComponentProps<{}> {}

function ListMonitorIndex(props: Props) {
  const searchString = 'search';

  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));

  const [monitorFilters, setMonitorFilters] = useFilter(
    {
      user_id: 0,
      user_name: '',
      ...DEFAULT_PAGINATION,
    },
    true,
  );

  const defaultSelectedUser = useMemo(
    () => ({ id: monitorFilters.user_id, name: monitorFilters.user_name }),
    [monitorFilters],
  );

  const [selectedUser, setSelectedUser] = useState(defaultSelectedUser);

  const { data, isLoading } = useGetAdminCalendar(monitorFilters);

  const tasks = data || [];
  const totalItems = (data as any)?.length;

  const handleFetchData = (fetchParams: any) => {
    setMonitorFilters({ ...monitorFilters, ...fetchParams } as any);
  };

  const handleUserFilter = (newValue: any) => {
    setSelectedUser(newValue);

    setMonitorFilters({ ...monitorFilters, user_id: newValue?.id, user_name: newValue?.name });
  };

  const handleSelect = ({ startDate, endDate }: any) => {
    setMonitorFilters({
      ...monitorFilters,
      'q[from_date]': convertDateStringToString(startDate),
      'q[end_date]': convertDateStringToString(endDate),
    } as any);
  };

  return (
    <BluefitPage
      classes={{
        contentWrapper: 'p-0 pl-10',
        content: 'flex flex-col relative',
        leftSidebar: 'w-256 border-0',
        header: 'min-h-64 h-64',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              List Monitor
            </Typography>
          }
        />
      }
      content={
        <div className="absolute flex flex-col w-full h-full">
          <div className="flex flex-row p-16 ">
            <div className="w-256">
              <UserSelect
                value={selectedUser}
                onChange={handleUserFilter}
              />
            </div>
            <div className="ml-20 z-999">
              <DateRange onChange={handleSelect} />
            </div>
          </div>
          <ListMonitorList
            tasks={tasks}
            onFetchData={handleFetchData}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
            filters={filters}
            setFilters={setFilters}
            searchKey={searchString}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default ListMonitorIndex;
