import clsx from 'clsx';
import { WEEKDAYS } from 'constants/index';
import _ from 'lodash';
import { Weekday as RRuleWeekday } from 'rrule';

import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  byweekday?: RRuleWeekday[];
  onChange?: (nextByWeekDay: RRuleWeekday[]) => void;
};

export function WeekDayOption({ byweekday = [], onChange }: Props) {
  const classes = useStyles();

  const isSelectedWeekday = (weekdayOption: any, currentByWeekDay: RRuleWeekday[]) => {
    return !!_.find(currentByWeekDay, (item: RRuleWeekday) => item.weekday === weekdayOption.weekday);
  };

  const handleSelectWeekDay = (weekdayOption: any) => () => {
    const nextByWeekDay = _.cloneDeep(byweekday);

    if (isSelectedWeekday(weekdayOption, nextByWeekDay)) {
      _.remove(nextByWeekDay, (item: RRuleWeekday) => item.weekday === weekdayOption.weekday);
    } else {
      nextByWeekDay.push(weekdayOption);
    }

    onChange?.(nextByWeekDay);
  };

  return (
    <div className={clsx('flex items-center', classes.root)}>
      <Typography className={clsx('text-13 font-600 text-secondaryMain', classes.label)}>Day(s)</Typography>
      <div className="flex-1">
        {WEEKDAYS?.map((item: any) => (
          <div
            key={item.value}
            className={clsx('inline-block w-64 px-4', classes.button)}
          >
            <Button
              key={item.value}
              disableElevation={true}
              color="primary"
              variant="contained"
              className={clsx(
                'text-9 lg:text-11 font-500 min-w-0 w-full h-32 rounded-24 bg-paper mt-8 px-4',
                classes.buttonWhite,
                isSelectedWeekday(item.value, byweekday) && classes.buttonSelected,
              )}
              onClick={handleSelectWeekDay(item.value)}
            >
              {item.shortLabel}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingLeft: 42,
  },
  label: {
    width: 86,
  },
  button: {
    maxWidth: 'calc(100% / 7)',
  },
  buttonWhite: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.background.default,
  },
}));
