import ErrorMessage from 'app/components/cores/form/error-message';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useGetRoles } from 'app/hooks/api/roles';
import useSelect from 'app/hooks/use-select';
import { generateFieldName } from 'domains/task.domain';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  control: {
    minHeight: '48px !important',
  },
  text: {
    fontSize: '1.3rem !important',
    color: theme.palette.secondary.dark,
  },
}));

type Props = {
  name?: string;
  control?: Control;
};

function ReportTo({ name, control }: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useGetRoles({
    page: pageIndex,
    per_page: pageSize,
    'q[name_cont]': searchText,
  });

  const {
    field: { onChange: onRoleIdsChange },
  } = useController({
    name: generateFieldName('reportToRoles', name),
    control,
  });

  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: generateFieldName('reportToRolesDetail', name),
    control,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const formattedOptions = useMemo(
    () =>
      _.chain(options)
        .filter((option: any) => option.isTaskAdmin || option.isTaskManager)
        .map((option: any) => ({ name: option.name, id: option.externalId }))
        .value(),
    [options],
  );

  const handleChange = (newValues: any) => {
    onChange(newValues);
    onRoleIdsChange(newValues?.map((v: any) => v.id));
  };

  const presentValue = useMemo(() => {
    return !value?.length ? null : value;
  }, [value]);

  return (
    <div className="p-4">
      <div>
        <SelectInfiniteScroll
          value={presentValue}
          onChange={handleChange}
          onBlur={onBlur}
          isMulti={true}
          isLoading={isLoading}
          isClearable={true}
          control={control}
          defaultOptions={true}
          className="w-full"
          name={generateFieldName('reportToRolesDetail', name)}
          placeholder="Assign report to Role"
          SelectProps={{
            classes: {
              control: classes.control,
              text: classes.text,
              placeholder: classes.text,
            },
          }}
          styles={{
            indicatorsContainer: (_props: any) => ({
              ..._props,
              marginTop: 7,
            }),
          }}
          options={formattedOptions || []}
          onInputChange={handleSearch}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => option.name}
          onMenuScrollToBottom={handleLoadMore}
        />
        <ErrorMessage message={error?.message} />
      </div>
    </div>
  );
}

export default ReportTo;
