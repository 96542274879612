import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseUtils from '@fuse/utils';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import FuseNavBadge from '../FuseNavBadge';
import FuseNavItem from '../FuseNavItem';
import FuseNavVerticalIcon from './FuseNavVerticalIcon';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: 13,
    fontWeight: 400,
  },
  root: {
    padding: 0,
    '&.open': {},
  },
  item: props => ({
    height: 40,
    width: 'calc(100% - 8px)',
    borderRadius: '0 24px 24px 0',
    paddingRight: 16,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    color: theme.palette.text.primary,
    '&.active > .list-item-text > span': {
      fontWeight: 600,
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
  }),
  itemActive: {
    borderWidth: '1px 1px 1px 0',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.light,
  },
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
      return true;
    }
  }

  return false;
}

function FuseNavVerticalCollapse(props) {
  const userRole = useSelector(({ auth }) => auth.user.role);
  const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));
  const { item, nestedLevel, loginLevel = 0 } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 + loginLevel * 2 : 24,
  });
  const { t } = useTranslation('navigation');

  useEffect(() => {
    if (needsToBeOpened(props.location, props.item)) {
      if (!open) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [props.location, props.item]);

  function handleClick() {
    setOpen(!open);
  }

  const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);
  const iconProps = useMemo(
    () => ({
      color: 'action',
      className: 'list-item-icon text-20 flex-shrink-0',
    }),
    [],
  );

  if (!hasPermission) {
    return null;
  }

  const isClasses = item.id.includes('classes-');
  const autoActive = !!item.autoActive;

  return (
    <ul className={clsx(classes.root, open && 'open')}>
      <Tooltip
        title={isClasses ? item.title : ''}
        placement="right-start"
        classes={{ tooltip: classes.tooltip }}
      >
        <ListItem
          button
          className={clsx(classes.item, 'list-item my-8', autoActive && classes.itemActive)}
          onClick={handleClick}
          component={item.url ? NavLinkAdapter : 'li'}
          to={item.url}
          role="button"
        >
          {item.icon && !item.isExternalIcon ? (
            <Icon {...iconProps}>{item.icon}</Icon>
          ) : (
            <FuseNavVerticalIcon
              {...iconProps}
              name={item.icon}
            />
          )}

          <ListItemText
            className="list-item-text"
            primary={item.translate ? t(item.translate) : item.title}
            classes={{ primary: clsx('text-13 truncate', item.isService && 'font-600') }}
          />

          {item.badge && (
            <FuseNavBadge
              className="mx-4"
              badge={item.badge}
            />
          )}

          <IconButton
            disableRipple
            className="w-40 h-40 p-0 focus:bg-transparent hover:bg-transparent"
            onClick={ev => ev.preventDefault()}
          >
            <Icon
              className="text-20 arrow-icon"
              color="inherit"
            >
              {open ? 'expand_less' : 'expand_more'}
            </Icon>
          </IconButton>
        </ListItem>
      </Tooltip>

      {item.children && (
        <Collapse
          in={open}
          className={clsx('collapse-children', !isClasses && 'mx-8')}
        >
          {item.children.map(_item => (
            <FuseNavItem
              key={_item.id}
              type={`vertical-${_item.type}`}
              item={_item}
              nestedLevel={nestedLevel + 1}
            />
          ))}
        </Collapse>
      )}
    </ul>
  );
}

FuseNavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};

const NavVerticalCollapse = withRouter(React.memo(FuseNavVerticalCollapse));

export default NavVerticalCollapse;
