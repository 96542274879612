import { useSelector } from 'react-redux';
import { havePermissionToAction, isAdmin, isManager } from 'utils/role';

function usePermission() {
  const { permissions, userId, role: userRoles } = useSelector(({ auth }: any) => auth.user);
  const isAdminUser = isAdmin(userRoles);
  const isManagerUser = isManager(userRoles);

  return {
    userId,
    permissions,
    isAdminUser,
    isManagerUser,
    havePermissionToAction: (acceptedPermissions: string | string[]) =>
      havePermissionToAction(acceptedPermissions, permissions),
  };
}

export default usePermission;
