import React from 'react';

import { useTheme } from '@mui/material/styles';

type Props = {
  children: JSX.Element;
};

const PreviewHeader = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <div
      className="flex flex-col justify-start w-full"
      style={{
        backgroundColor: theme.palette.primary.dark,
        height: 172,
      }}
    >
      {children}
    </div>
  );
};

export default React.memo(PreviewHeader);
