import { useFetchServices } from 'app/hooks/api/services';
import { generateHeaderItems } from 'domains/header.domain';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

import TopBarDesktop from './TopBarDesktop';
import TopBarMobile from './TopBarMobile';
import TopBarTablet from './TopBarTablet';

function Header() {
  const { data } = useFetchServices();
  const navItems = useMemo(() => {
    return generateHeaderItems(data?.services || []);
  }, [data]);

  const [isLoading, setIsLoading] = useState(!navItems?.length);
  const [isTabletScreen, setIsTabletScreen] = useState(localStorage.isTablet === 'true');
  const isMobileScreen = useMediaQuery('(max-width:767px)');

  const detectScreen = useCallback(() => {
    if (isMobileScreen) return setIsTabletScreen(false);
    const desktopTopBar = document.querySelector('.desktop-top-bar') as any;
    const isTablet = desktopTopBar?.clientWidth < desktopTopBar?.scrollWidth;
    localStorage.isTablet = `${isTablet}`;
    return setIsTabletScreen(isTablet);
  }, [isMobileScreen]);

  useEffect(() => {
    if (!navItems?.length) return;
    setIsLoading(false);
  }, [navItems]);

  useEffect(() => {
    if (isLoading) return;
    detectScreen();
  }, [isLoading, detectScreen]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      _.throttle(() => detectScreen(), 10),
    );
  }, [detectScreen]);

  const renderTopBar = () => {
    if (isMobileScreen) return <TopBarMobile items={navItems} />;

    if (isLoading)
      return (
        <div className="flex items-center h-64 pl-24 border-b">
          <CircularProgress size={16} />
        </div>
      );

    return (
      <>
        {isTabletScreen && <TopBarTablet items={navItems} />}
        <TopBarDesktop
          items={navItems}
          isSmallerScreen={isTabletScreen}
        />
      </>
    );
  };

  return <div className="sticky top-0">{renderTopBar()}</div>;
}

export default Header;
