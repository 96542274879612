import { Location, Pathname } from 'history';
import { useLocation } from 'react-router-dom';

import { usePrevious } from '@fuse/hooks';
import _ from '@lodash';

const usePagePrompt = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location) as Location | undefined;

  const checkPageChange = (nextPathname: Pathname) => {
    return !!prevLocation && !_.isEqual(prevLocation?.pathname, nextPathname);
  };
  return { checkPageChange };
};

export default usePagePrompt;
