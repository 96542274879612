import {
  formatYearlyRule,
  generateDefaultYearlyOn,
  generateDefaultYearlyOnThe,
  isEmptyFrequencyOn,
  isEmptyFrequencyOnThe,
} from 'domains/frequency.domain';
import _ from 'lodash';
import { TFrequencyMonthDay, TFrequencyYearly } from 'models';
import { useEffect, useState } from 'react';
import { Options as RRuleOptions, Weekday as RRuleWeekday } from 'rrule';

import { FrequencyOn } from '../ui/frequency-on';
import { FrequencyOnThe } from '../ui/frequency-on-the';

type TType = 'on' | 'on_the';

type Props = {
  settings: TFrequencyYearly;
  onChange?: (nextFrequencySettings: TFrequencyYearly) => void;
  onRuleFormat?: (formattedRule: Partial<RRuleOptions>) => void;
};

export function FrequencyYearlyType({ settings, onChange, onRuleFormat }: Props) {
  const [type, setType] = useState<TType>(isEmptyFrequencyOn(settings) ? 'on_the' : 'on');
  const bysetpos = settings?.byweekday?.[0]?.n || 1;

  useEffect(() => {
    const newSettings = formatYearlyRule(type, settings);
    onRuleFormat?.(newSettings);
  }, [type, settings, onRuleFormat]);

  const handlePositionChange = (nextPosition: number) => {
    const nextByweekday = settings?.byweekday;
    const newData = nextByweekday?.[0]?.nth(nextPosition) as any;
    updateData('byweekday', newData);
  };

  const handleWeekDayChange = (nextWeekDay: RRuleWeekday[]) => {
    const nextBysetpos = settings?.byweekday?.[0]?.n || 1;
    const newData = nextWeekDay?.[0]?.nth(nextBysetpos);
    updateData('byweekday', newData);
  };

  const handleMonthsChange = (nextMonths: TFrequencyMonthDay) => {
    updateData('bymonth', nextMonths);
  };

  const handleMonthChange = (nextMonth: TFrequencyMonthDay) => {
    updateData('by1month', nextMonth);
  };

  const handleMonthDaysChange = (nextMonthDays: TFrequencyMonthDay) => {
    updateData('bymonthday', nextMonthDays);
  };

  const updateData = (name: string, data: any) => {
    const nextFrequencySettings = _.cloneDeep(settings);
    nextFrequencySettings[name as keyof TFrequencyYearly] = data;
    onChange?.(nextFrequencySettings);
  };

  const handleTypeChange = (value: TType) => {
    if (value === 'on' && isEmptyFrequencyOn(settings)) {
      const defaultMonthlyOn = generateDefaultYearlyOn();
      const nextFrequencySettings = _.cloneDeep(settings);
      onChange?.({ ...nextFrequencySettings, ...defaultMonthlyOn });
    }
    //
    else if (value === 'on_the' && isEmptyFrequencyOnThe(settings)) {
      const defaultMonthlyOnThe = generateDefaultYearlyOnThe();
      const nextFrequencySettings = _.cloneDeep(settings);
      onChange?.({ ...nextFrequencySettings, ...defaultMonthlyOnThe });
    }

    setType(value as TType);
  };

  return (
    <div className="pt-16 space-y-16">
      <FrequencyOn
        bymonthday={settings?.bymonthday}
        bymonth={settings?.bymonth}
        isChecked={type === 'on'}
        isDisabled={type === 'on_the'}
        isShowMonthSelect={true}
        onMonthsChange={handleMonthsChange}
        onMonthDaysChange={handleMonthDaysChange}
        onTypeChange={handleTypeChange}
      />
      <FrequencyOnThe
        bysetpos={bysetpos}
        byweekday={settings?.byweekday}
        by1month={settings?.by1month}
        isChecked={type === 'on_the'}
        isDisabled={type === 'on'}
        isShowMonthSelect={true}
        onPositionChange={handlePositionChange}
        onWeekDayChange={handleWeekDayChange}
        onMonthChange={handleMonthChange}
        onTypeChange={handleTypeChange}
      />
    </div>
  );
}
