import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function ListCheckIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        d="M6.66656 5.00004V7.50004H4.16656V5.00004H6.66656ZM3.49989 3.33337C2.94761 3.33337 2.49989 3.78109 2.49989 4.33337V8.16671C2.49989 8.71899 2.94761 9.16671 3.49989 9.16671H7.33322C7.88551 9.16671 8.33323 8.71899 8.33323 8.16671V4.33338C8.33323 3.78109 7.88551 3.33337 7.33323 3.33337H3.49989ZM10.8332 4.16671C10.8332 3.70647 11.2063 3.33337 11.6666 3.33337H16.6666C17.1268 3.33337 17.4999 3.70647 17.4999 4.16671C17.4999 4.62694 17.1268 5.00004 16.6666 5.00004H11.6666C11.2063 5.00004 10.8332 4.62694 10.8332 4.16671ZM10.8332 10C10.8332 9.5398 11.2063 9.16671 11.6666 9.16671H16.6666C17.1268 9.16671 17.4999 9.5398 17.4999 10C17.4999 10.4603 17.1268 10.8334 16.6666 10.8334H11.6666C11.2063 10.8334 10.8332 10.4603 10.8332 10ZM10.8332 15.8334C10.8332 15.3731 11.2063 15 11.6666 15H16.6666C17.1268 15 17.4999 15.3731 17.4999 15.8334C17.4999 16.2936 17.1268 16.6667 16.6666 16.6667H11.6666C11.2063 16.6667 10.8332 16.2936 10.8332 15.8334ZM8.33323 14.095C8.65861 13.7697 8.65861 13.2421 8.33323 12.9167C8.00784 12.5913 7.48028 12.5913 7.15489 12.9167L4.99989 15.0717L4.09489 14.1667C3.7695 13.8413 3.24195 13.8413 2.91656 14.1667C2.59117 14.4921 2.59117 15.0197 2.91656 15.345L4.29279 16.7213C4.68331 17.1118 5.31647 17.1118 5.707 16.7213L8.33323 14.095Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(ListCheckIcon);
