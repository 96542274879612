import { PropsWithChildren } from 'react';

import Snackbar from '@mui/material/Snackbar';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TaskSnackbar = ({ isOpen, onClose, children }: PropsWithChildren<Props>) => {
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        background: '#F5D02F',
      }}
      className="mb-80 bg-secondaryLight rounded-16"
      onClose={onClose}
      autoHideDuration={5000}
      disableWindowBlurListener
      // click out side of snackbar will not close it
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <div>{children}</div>
    </Snackbar>
  );
};

export default TaskSnackbar;
