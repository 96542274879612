import ErrorMessage from 'app/components/cores/form/error-message';
import { SingleSelect } from 'app/components/cores/select';
import { generateDailyTargetOptions } from 'domains/display-time.domain';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, useController, useFormContext, useWatch } from 'react-hook-form';

import { useTheme } from '@mui/material';

type Props = {
  name: string;
  timeName: string;
  afterName: string;
  control: Control<any>;
};

function UntilTargetDaily({ name, timeName, afterName, control }: Props) {
  const theme = useTheme();
  const { trigger, setValue } = useFormContext();

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const target = useWatch({ name, control });
  const after = useWatch({ name: afterName, control });

  const options = useMemo(() => generateDailyTargetOptions(), []);

  const generateValue = () => _.find(options, { value: target }) || null;

  const handleValueChange = (item: any) => {
    onChange(item.value);
    trigger(timeName);

    if (['end', 'end_time', 'next_start_time'].includes(item.value)) {
      setValue(timeName, null);
    }
    if (['end_time', 'next_start_time'].includes(item.value)) {
      setValue(afterName, null);
    }
    if (!['end_time', 'next_start_time'].includes(item.value) && after === null) {
      setValue(afterName, 0);
    }
  };

  return (
    <div className="w-160">
      <SingleSelect
        minMenuHeight={400}
        menuPlacement="auto"
        placeholder="Select day"
        value={generateValue()}
        options={options}
        styles={{
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            border: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            height: 40,
            minHeight: 40,
            boxShadow: 'none',
            cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
            pointerEvents: 'auto',
          }),
          singleValue: (baseStyles: any) => ({
            ...baseStyles,
            '& *': {
              fontWeight: '400 !important',
            },
          }),
          option: (baseStyles: any) => ({
            ...baseStyles,
            '& *': {
              fontWeight: '400 !important',
            },
          }),
        }}
        onChange={handleValueChange}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default UntilTargetDaily;
