import queryClient from 'app/configs/query-client';
import { THoliday, TLocation, TResponsePagination, TTaskBasic } from 'models';
import { TUpcomingEvent } from 'models/upcoming-events.model';
import api from 'utils/api';

import { useQuery } from '@tanstack/react-query';

import { useAction, useGetList, useGetOne, usePatchAction, useUpdate } from './request';

export const locationKeys = {
  all: ['locations'] as const,
  lists: () => [...locationKeys.all, 'list'] as const,
  list: (filters: any) => [...locationKeys.lists(), { filters }] as const,
  details: () => [...locationKeys.all, 'detail'] as const,
  detail: (id: number) => [...locationKeys.details(), id] as const,
  tasks: (id: number, filter: any) => [...locationKeys.details(), id, filter] as const,
};

const locationURL = '/api/admin/locations';

export const refetchLocation = () => queryClient.invalidateQueries(locationKeys.all);

export function useGetLocations(filters: any, options?: any) {
  return useGetList<TResponsePagination<TLocation>>(
    [...locationKeys.list(filters)],
    `${locationURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useGetLocation(locationId: number) {
  return useGetOne<TLocation>(
    [...locationKeys.detail(locationId)],
    `${locationURL}/${locationId}`,
    {},
    {
      enabled: !!locationId,
    },
  );
}

export function useGetLocationTasks(locationId: number, filters: any, options?: any) {
  return useGetList<TResponsePagination<TTaskBasic>>(
    [...locationKeys.tasks(locationId, filters)],
    `${locationURL}/${locationId}/tasks`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useAssignListsToLocation(locationId: number, options?: any) {
  return useUpdate<{ location: { status: 'draft' | 'active' | 'inactive'; taskIds: number[] } }, any>(
    `${locationURL}/${locationId}/update_assignment_status`,
    {
      onSuccess: () => refetchLocation(),
      ...options,
    },
  );
}

export function useAssignListsToLocation2(options?: any) {
  return usePatchAction(locationURL, options);
}

export function useDeactivateLocationTask(locationId: number) {
  return useAction(`${locationURL}/${locationId}/tasks`, {
    onSuccess: () => refetchLocation(),
  });
}

export function useActivateLocationTask(locationId: number) {
  return useAction(`${locationURL}/${locationId}/tasks`, {
    onSuccess: () => refetchLocation(),
  });
}

export function useGetPublicHolidaysByLocation(locationId: number) {
  return useGetList<TResponsePagination<THoliday>>(
    [...locationKeys.detail(locationId), 'public_holidays'],
    `${locationURL}/${locationId}/public_holidays`,
    {},
    { refetchOnMount: true },
  );
}

export function useUpcomingEventsByLocation(locationId: number, options: any = {}) {
  return useGetList<TResponsePagination<TUpcomingEvent>>(
    [...locationKeys.detail(locationId), 'upcoming_events', options],
    `${locationURL}/${locationId}/upcoming_events`,
    options,
    { enabled: !!locationId },
  );
}

async function unavailableRoles(locationIds: number[], orgRoleIds: number[]) {
  const response = await api.post(`${locationURL}/unavailable_roles`, { locationIds, orgRoleIds });

  return response.data;
}

export function useUnavailableRoles(locationIds: number[], orgRoleIds: number[]) {
  return useQuery({
    queryKey: [locationKeys.all, locationIds, orgRoleIds, 'unavailableRoles'],
    queryFn: () => unavailableRoles(locationIds, orgRoleIds),
    enabled: !!locationIds?.length && !!orgRoleIds?.length,
  });
}

export function useUnavailableRolesForVariations(data: [number, number[]][]) {
  return useQuery({
    queryKey: [locationKeys.all, data, 'unavailableRolesForVariations'],
    queryFn: () => Promise.all(data.map(([locationId, orgRoleIds]) => unavailableRoles([locationId], orgRoleIds))),
    enabled: !!data?.length,
  });
}
