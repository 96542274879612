import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  test: {
    textTransform: 'lowercase',

    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

type Props = {
  message?: string;
};

function ErrorMessage({ message }: Props) {
  const classes = useStyles();
  if (!message) return null;

  return <Typography className={clsx('text-11 font-400 text-errorMain mt-4', classes.test)}>{message}</Typography>;
}

export default ErrorMessage;
