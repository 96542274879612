import { useDuplicate } from 'app/hooks/use-duplicate';

import { Skeleton } from '@mui/material';

export const TaskSidebarItemSkeleton = () => {
  const header = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={36}
        className="mt-16 rounded-t-8"
      />
    ),
  });

  const title = useDuplicate({
    initialCount: 3,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={18}
        className="mb-4 w-100"
      />
    ),
  });

  const time = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="text"
        width="80%"
        height={18}
        className="mb-8"
      />
    ),
  });

  const role = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="text"
        width={100}
        height={28}
        className="rounded-32"
      />
    ),
  });

  const progress = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="circular"
        width={40}
        height={40}
      />
    ),
  });

  const divider = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={1}
      />
    ),
  });

  return (
    <div className="w-100">
      <div className="w-100">{header}</div>
      <div className="flex items-start justify-between px-6 py-12 space-x-8">
        <div className="flex-1">
          {title}
          {time}
          {role}
        </div>
        <div className="flex-initial">{progress}</div>
      </div>
      {divider}
    </div>
  );
};

export const TaskSidebarNameRoleSkeleton = () => {
  const skeleton = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        className="mb-8 rounded-8"
      />
    ),
  });

  return <>{skeleton}</>;
};

export const DuplicateTaskSidebarItemSkeleton = () => {
  const duplicate = useDuplicate({
    initialCount: 5,
    component: <TaskSidebarItemSkeleton />,
  });

  return <>{duplicate}</>;
};
