import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import UpdateIcon from '@mui/icons-material/Update';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  id: number;
  status?: string;
  label?: {
    activate: string;
    deactivate: string;
  };
  onView?: (id: number) => void;
  onDuplicate?: (id: number) => void;
  onDeactivate?: (id: number) => void;
  onActivate?: (id: number) => void;
  onRefresh?: (id: number) => void;
}
const Action = ({ id, status, label, onView, onDuplicate, onDeactivate, onActivate, onRefresh }: Props) => {
  const isActive = status !== 'inactive';
  const isDraft = status === 'draft';

  const handleView = (event: React.MouseEvent) => {
    event.stopPropagation();
    onView?.(id);
  };

  const handleDuplicate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDuplicate?.(id);
  };

  const handleDeactivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDeactivate?.(id);
  };

  const handleActivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onActivate?.(id);
  };

  const handleRefresh = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRefresh?.(id);
  };

  return (
    <div className="flex items-center justify-end">
      {!!onRefresh && (
        <Tooltip title="Refreshment">
          <IconButton onClick={handleRefresh}>
            <UpdateIcon className="text-20" />
          </IconButton>
        </Tooltip>
      )}

      {!!onDuplicate && (
        <Tooltip title="Duplicate">
          <IconButton onClick={handleDuplicate}>
            <ContentCopyIcon className="text-20" />
          </IconButton>
        </Tooltip>
      )}

      {isActive
        ? !!onDeactivate && (
            <Tooltip title={isDraft ? 'Delete' : label?.deactivate || 'Deactivate'}>
              <IconButton onClick={handleDeactivate}>
                {isDraft ? (
                  <DeleteOutlineOutlinedIcon className="text-20" />
                ) : (
                  <PowerSettingsNewIcon className="text-20" />
                )}
              </IconButton>
            </Tooltip>
          )
        : !!onActivate && (
            <Tooltip title={label?.activate || 'Activate'}>
              <IconButton onClick={handleActivate}>
                <ControlPointIcon className="text-20" />
              </IconButton>
            </Tooltip>
          )}

      <Tooltip title="View">
        <IconButton onClick={handleView}>
          <VisibilityOutlinedIcon className="text-20" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Action;
