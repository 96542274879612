import clsx from 'clsx';
import { TResponse } from 'models';
import { TTaskStatus } from 'models/employee/task.model';
import { useEffect, useState } from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/system/Stack';

const useStyles = makeStyles((theme: any) => ({
  overdueLoading: {
    backgroundColor: `${theme.palette.overdue.light} !important`,
  },
}));

type Props = {
  disabled?: boolean;
  value?: boolean | null;
  status?: TResponse['status'];
  isLoading?: boolean;
  onChange?: (nextValue: boolean | null) => void;
  listStatus?: TTaskStatus;
};

const YesNoButton = ({ disabled, value: valueProp = null, status, isLoading, onChange, listStatus }: Props) => {
  const [value, setValue] = useState(valueProp);
  const classes = useStyles();

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleSelect = (newValue: boolean) => {
    const nextValue = value === newValue ? null : newValue;
    setValue(nextValue);
    onChange?.(nextValue);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <StyledYesNoButton
        disableElevation={true}
        variant="contained"
        active={value === true ? 'true' : undefined}
        disabled={disabled}
        status={status}
        className={clsx(
          'text-13 font-400',
          isLoading && 'pointer-events-none',
          isLoading && listStatus === 'overdue' && classes.overdueLoading,
        )}
        onClick={() => handleSelect(true)}
      >
        {isLoading ? (
          <CircularProgress
            size={16}
            color="inherit"
          />
        ) : (
          'Yes'
        )}
      </StyledYesNoButton>
      <StyledYesNoButton
        disableElevation={true}
        variant="contained"
        active={value === false ? 'true' : undefined}
        disabled={disabled}
        status={status}
        className={clsx(
          'text-13 font-400',
          isLoading && 'pointer-events-none',
          isLoading && listStatus === 'overdue' && classes.overdueLoading,
        )}
        onClick={() => handleSelect(false)}
      >
        {isLoading ? (
          <CircularProgress
            size={16}
            color="inherit"
          />
        ) : (
          'No'
        )}
      </StyledYesNoButton>
    </Stack>
  );
};

export default YesNoButton;

const StyledYesNoButton = styled(Button)<ButtonProps & { active?: string; status?: string }>(
  ({ theme, active, status }: any) => {
    const { palette } = theme;

    const getBgColor = () => {
      if (status === 'overdue') return palette.overdue.main;
      return palette.primary.main;
    };

    return {
      color: active === 'true' ? palette.common.white : palette.secondary.light,
      width: 120,
      height: 40,
      borderRadius: 20,
      backgroundColor: active === 'true' ? `${getBgColor()} !important` : palette.grey[200],

      '&:hover': {
        backgroundColor: palette.grey[300],
      },

      '&:disabled': {
        color: active === 'true' ? palette.common.white : palette.secondary.light,
      },
    };
  },
);
