import { Props as ReactSelectProps } from 'react-select';

import SingleSelect from './single-select';

type Props = ReactSelectProps & {
  onLoadMore?: () => void;
};

function MultipleSelect({ onLoadMore, ...props }: Props) {
  return (
    <SingleSelect
      {...props}
      isMulti={true}
      onMenuScrollToBottom={onLoadMore}
    />
  );
}

export default MultipleSelect;
