/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import { useGetLocations } from 'app/hooks/api/locations';
import { useGetRoles } from 'app/hooks/api/roles';
import _ from 'lodash';
import { TAdminCalendar } from 'models';
import moment from 'moment';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Cell, Column } from 'react-table';

import Typography from '@mui/material/Typography';

type Props = {
  tasks: TAdminCalendar[];
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  filters: any;
  setFilters: any;
  onFetchData: (params: any) => void;
};

function ListMonitorList({ tasks, totalDataCount, isLoading, searchKey, filters, setFilters, onFetchData }: Props) {
  const history = useHistory();
  const location = useLocation();

  const statusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Completed', value: 'completed' },
    { label: 'Expired', value: 'expired' },
  ];

  const columns = useMemo(
    () => [
      {
        Header: 'Task Name',
        accessor: 'name',
        id: 'task_root_name',
        sortable: true,
        width: '15%',
        className: 'capitalize',
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: false,
        width: '5%',
        options: statusOptions,
        Filter: SelectFilter,
        className: 'capitalize',
      },
      {
        Header: 'Start Time',
        accessor: 'start',
        id: 'start',
        sortable: true,
        width: '10%',
        Filter: <></>,
        className: 'capitalize',
        Cell: ({ cell }: { cell: Cell<TAdminCalendar> }) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const [time, timezone] = cell.value?.split(':00.000');
          return (
            <Typography className="font-400">
              {moment(time).format('DD/MM/YYYY HH:mm')} {timezone}
            </Typography>
          );
        },
      },
      {
        Header: '',
        accessor: 'start',
        id: 'start2',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAdminCalendar> }) => {
          return (
            <Typography className="font-400">{moment(new Date(cell.value)).format('DD/MM/YYYY HH:mm Z')}</Typography>
          );
        },
      },
      {
        Header: 'Location',
        accessor: 'location',
        id: 'location_id',
        sortable: false,
        width: '10%',
        Cell: ({ cell }: { cell: Cell<TAdminCalendar> }) => {
          return <Typography className="cursor-pointer font-600">{cell.value?.name}</Typography>;
        },
        Filter: (props: any) => (
          <div className="min-w-168">
            <SearchInputDropdownFilter
              name="location_ids_in"
              optionValue="externalId"
              useFetchData={useGetLocations}
              {...props}
            />
          </div>
        ),
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        id: 'org_role_id',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        Cell: ({ cell }: { cell: Cell<TAdminCalendar> }) => {
          const roleNamesArr = _.map(cell.value, 'name');
          const roleNames = roleNamesArr?.join(', ');
          return <Typography className="cursor-pointer font-600">{roleNames}</Typography>;
        },
        Filter: (props: any) => (
          <div className="min-w-168">
            <SearchInputDropdownFilter
              name="role_ids_in"
              optionValue="externalId"
              useFetchData={useGetRoles}
              {...props}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="flex flex-col w-full px-12">
      <IndexTable
        columns={columns as Column<any>[]}
        data={tasks}
        pageCount={0}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        withSelection={false}
      />
    </div>
  );
}

export default ListMonitorList;
