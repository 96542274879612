import useLocalStorage from 'app/hooks/use-local-storage';
import { IS_FILTER_MULTIPLE_DATES } from 'constants/index';
import { getTimezoneBySelector } from 'domains/calendar.domain';
import { TCalendarMode, TIMEZONE_SELECTOR_TYPES, TTimezoneSelectorType, TUserLocation } from 'models';
import { useMemo, useRef } from 'react';

import CoreCalendar from './core-calendar';
import FilterContext, { TFilterContext } from './filter-context';
import ListViewCalendar from './listview-calendar';
import Toolbar from './toolbar';

type Props = {
  events: any;
  listViewEvents?: any;
  isFetching?: boolean;
  isListView?: boolean;
  isMyList?: boolean;
  canViewUserCalendar?: boolean;
  view: TCalendarMode;
  dayView: Date;
  dayRange: { startDate?: Date; endDate?: Date };
  filter?: any;
  setFilter?: any;
  onView: (newView: TCalendarMode) => void;
  onReload: () => void;
  onViewDateChange?: (newDate: Date) => void;
  onListViewChange?: (isListView: boolean) => void;
  onMyListChange?: (isMyList: boolean) => void;
  onSelectLocation?: (nextLocation: TUserLocation) => void;
  onDayRangeChange?: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
  timezone: TUserLocation['timezone'];
};

function EmployeeCalendar({
  isFetching,
  isListView,
  isMyList,
  canViewUserCalendar,
  view,
  events,
  listViewEvents,
  dayView,
  dayRange,
  filter,
  setFilter,
  onView,
  onViewDateChange,
  onReload,
  onListViewChange,
  onMyListChange,
  onSelectLocation,
  onDayRangeChange,
  timezone,
}: Props) {
  const calendarRef = useRef<any>(null);
  const [timezoneSelector, setSelectedTimezoneSelector] = useLocalStorage<TTimezoneSelectorType>(
    'timezoneSelector',
    TIMEZONE_SELECTOR_TYPES.COMPUTER,
  );

  const handleViewChange = (newView: TCalendarMode) => {
    const calendar = calendarRef.current?.getApi();
    calendar.changeView(newView, new Date());
    onView(newView);
  };

  const handleTimezoneSelectorChange = (newTimeType: TTimezoneSelectorType) => {
    setSelectedTimezoneSelector(newTimeType);
  };

  const memoizedFilter: TFilterContext = useMemo(
    () => ({
      roleId: filter?.roleId,
      userId: filter?.userId,
      locationId: filter?.locationId,
      status: filter?.status,
      timezoneSelector,
      isGlobalView: !isMyList,
      timezone,
      timezoneBySelector: getTimezoneBySelector(timezoneSelector, timezone),
      isListView,
    }),
    [filter, timezoneSelector, isMyList, timezone, isListView],
  );

  return (
    <div className="p-24 bg-white rounded-8">
      <Toolbar
        calendarRef={calendarRef}
        view={view}
        date={dayView}
        filter={filter}
        setFilter={setFilter}
        isListView={isListView}
        isMyList={isMyList}
        canViewUserCalendar={canViewUserCalendar}
        onViewDateChange={onViewDateChange}
        onView={handleViewChange}
        onReload={onReload}
        onListViewChange={onListViewChange}
        onMyListChange={onMyListChange}
        onSelectLocation={onSelectLocation}
        timezoneSelector={timezoneSelector}
        onTimezoneSelectorChange={handleTimezoneSelectorChange}
      />

      <FilterContext.Provider value={memoizedFilter}>
        {isListView ? (
          <ListViewCalendar
            isListView={isListView}
            isMyList={isMyList}
            isFilterMultipleDates={IS_FILTER_MULTIPLE_DATES}
            hasLocation={!!filter?.locationId}
            view={view}
            listViewEvents={listViewEvents}
            loading={isFetching}
            calendarHeight="auto"
            dayRange={dayRange}
            dayView={dayView}
            onViewDateChange={onViewDateChange}
            onDayRangeChange={onDayRangeChange}
          />
        ) : (
          <CoreCalendar
            calendarRef={calendarRef}
            isMyList={isMyList}
            view={view}
            events={events}
            loading={isFetching}
            calendarHeight="auto"
            dayView={dayView}
            onViewDateChange={onViewDateChange}
          />
        )}
      </FilterContext.Provider>
    </div>
  );
}

export default EmployeeCalendar;
