import RolesAndAssignees from 'app/components/cores/audit/roles-and-assignees';
import ConfirmDialog from 'app/components/cores/confirm-dialog';
import AuthorTimeInfo from 'app/components/cores/todos/components/author-time-info';
import AddActionManagerModal from 'app/components/histories/audit/action-items-modal/add-action-manager-modal';
import queryClient from 'app/configs/query-client';
import { useDeleteActionItem } from 'app/hooks/api/manager/audit';
import { historyKeys } from 'app/hooks/api/manager/histories';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { TResponse } from 'models';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { adjustToLocalTimezone } from 'utils/date';

import { Delete, Edit, MoreVertOutlined, Star } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

type Props = {
  auditAction: TResponse['auditActions'][number];
  listId: number;
  canAuditInteractions: boolean;
};

const AuditActionItem = ({ auditAction, listId, canAuditInteractions }: Props) => {
  const { description, displayAt, author, important, submitStatus, orgRoles, users: assignees } = auditAction;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mutate: deleteActionItem } = useDeleteActionItem(listId);
  const { state: isOpenAddActionModal, setActive: openAddActionModal, setInactive: closeAddActionModal } = useToggle();
  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteActionItem(
      { id: auditAction.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(historyKeys.detail(listId));
        },
      },
    );
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    dispatch(closeDialog({}));
  };

  const handleClickDelete = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete Action Item"
            message="Are you sure you want to delete this action item?"
            onClose={handleCloseDialog}
            onConfirm={handleDelete}
            cancelButtonLabel="No, Cancel"
            confirmButtonLabel="Yes, Delete it"
          />
        ),
      }),
    );
    handleClose();
  };

  const handleClickEdit = () => {
    openAddActionModal();
    handleClose();
  };

  return (
    <Box className="px-12 py-8 bg-paper rounded-8">
      <div className="flex justify-between items-start gap-8">
        <div className="flex-1 space-y-4">
          <Typography className="flex-1 text-13 font-600">{description}</Typography>

          <div>
            <RolesAndAssignees
              roles={orgRoles}
              assignees={assignees}
            />
          </div>

          <AuthorTimeInfo
            name={author?.fullName as string}
            time={adjustToLocalTimezone(displayAt) as string}
            mode="added"
            status={submitStatus}
          />
        </div>

        <div className="flex gap-8 items-center">
          {important && <Star className="text-warning text-20" />}

          <IconButton
            onClick={handleClick}
            disabled={!canAuditInteractions}
          >
            <MoreVertOutlined className="text-secondaryLight text-20" />
          </IconButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleClickEdit}
              disabled={!canAuditInteractions}
            >
              <ListItemIcon>
                <Edit className="text-20" />
              </ListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              onClick={handleClickDelete}
              disabled={!canAuditInteractions}
            >
              <ListItemIcon>
                <Delete className="text-20" />
              </ListItemIcon>
              Remove
            </MenuItem>
          </Menu>
        </div>
      </div>

      {isOpenAddActionModal && (
        <AddActionManagerModal
          isOpen={isOpenAddActionModal}
          onClose={closeAddActionModal}
          listId={listId}
          responseId={auditAction.id}
          value={auditAction as any}
        />
      )}
    </Box>
  );
};

export default AuditActionItem;
