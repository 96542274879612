import moment from 'moment';

export const ACTION_DEFAULT_VALUES = {
  description: '',
  important: false,
  users: {
    userIds: [],
    users: [],
  },
  orgRoles: {
    ids: [],
    names: [],
  },
  startDate: new Date().toISOString(),
  // start time should be 00:00 of the day
  startTime: moment(new Date().setHours(0, 0)).format('HH:mm'),
  endDate: undefined,
  endTime: undefined,
};
