import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { TMeasurementType, TUnit, TUnitOption, mapUnits } from 'models';
import { useEffect, useMemo } from 'react';
import { Control, useController } from 'react-hook-form';

import { Box } from '@mui/material';

type Props = {
  measurement: TMeasurementType;
  control: Control;
  name: string;
};

const variants = {
  hidden: { opacity: 0, x: -40 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 40 },
};

const MotionBtn = motion(Box);

const MeasurementUnits = ({ measurement, control, name }: Props) => {
  const options = useMemo(() => mapUnits.get(measurement) || [], [measurement]);

  const {
    field: { value, onChange },
  } = useController({ control, name });

  useEffect(() => {
    if (!value?.unit) {
      onChange({ ...value, unit: options?.[0]?.value });
    }
  }, [value, options, onChange]);

  const handleClick = (option: TUnitOption<TUnit<TMeasurementType>>) => () => {
    onChange({ ...value, unit: option.value });
  };

  if (!measurement || measurement === 'custom') {
    return null;
  }

  const checkIsSelected = (option: TUnitOption<TUnit<TMeasurementType>>) => {
    return option.value === value?.unit;
  };

  return (
    <AnimatePresence>
      <div className="flex flex-wrap items-center">
        {options.map(option => (
          <MotionBtn
            key={option.value}
            color="primary"
            className={clsx(
              'flex flex-col items-center justify-center h-40 px-16 border rounded rounded-6 min-w-60 my-4 mx-4 cursor-pointer',
            )}
            onClick={handleClick(option)}
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.4 }}
            layout
            sx={{ bgcolor: checkIsSelected(option) ? 'primary.main' : 'grey.200' }}
          >
            <span className={clsx('font-bold text-13 text-secondaryLight', checkIsSelected(option) && 'text-white')}>
              {option.display}
            </span>
            <span className={clsx('text-11 text-secondaryLight', checkIsSelected(option) && 'text-white')}>
              ({option.label})
            </span>
          </MotionBtn>
        ))}
      </div>
    </AnimatePresence>
  );
};

export default MeasurementUnits;
