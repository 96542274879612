import { WEEK_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TFrequencyWeekly } from 'models';
import { useEffect } from 'react';
import { Options as RRuleOptions, Weekday as RRuleWeekday } from 'rrule';

import { FrequencyEvery } from '../ui/frequency-every';
import { WeekDayOption } from '../ui/weekday-option';

type Props = {
  settings: TFrequencyWeekly;
  onChange?: (nextFrequencySettings: TFrequencyWeekly) => void;
  onRuleFormat?: (formattedRule: Partial<RRuleOptions>) => void;
};

export function FrequencyWeeklyType({ settings, onChange, onRuleFormat }: Props) {
  useEffect(() => {
    onRuleFormat?.(settings);
  }, [settings, onRuleFormat]);

  const handleEveryChange = (nextInterval: number) => {
    updateData('interval', nextInterval);
  };

  const handleWeekDayChange = (nextByWeekDay: RRuleWeekday[]) => {
    updateData('byweekday', nextByWeekDay);
  };

  const updateData = (name: string, data: any) => {
    const nextFrequencySettings = _.cloneDeep(settings);
    nextFrequencySettings[name as keyof TFrequencyWeekly] = data;
    onChange?.(nextFrequencySettings);
  };

  return (
    <div className="pt-24 space-y-20">
      <FrequencyEvery
        interval={settings?.interval}
        label="Week(s)"
        options={WEEK_OPTIONS}
        onChange={handleEveryChange}
      />
      <WeekDayOption
        byweekday={settings?.byweekday}
        onChange={handleWeekDayChange}
      />
    </div>
  );
}
