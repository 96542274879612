import _ from 'lodash';
import { useMemo } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatDate } from 'utils/date';

import { Skeleton, useTheme } from '@mui/material';

type Props = {
  overview: any;
  isLoading?: boolean;
};

function AuditLineChartContent({ overview, isLoading }: Props) {
  const theme = useTheme();
  const scoreRatePerDate = overview?.scoreRatePerDate || [];
  const lineChartLabels = scoreRatePerDate.map((item: { date: string }) => formatDate(item.date, 'DD/MM'));
  const lineChartData = scoreRatePerDate.map((item: { rate: number }) => item.rate);

  const data = useMemo(() => {
    return _.map(lineChartLabels, (label, index) => ({
      name: label,
      rate: lineChartData[index],
    }));
  }, [lineChartLabels, lineChartData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full px-24">
        <Skeleton
          height={200}
          className="w-full"
        />
      </div>
    );
  }

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <LineChart
        key={Math.random()}
        data={data}
        margin={{
          top: 24,
          right: 24,
          left: -8,
          bottom: 8,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          fontSize={11}
        />
        <YAxis fontSize={11} />
        <Tooltip />
        <Line
          name="Score"
          type="monotone"
          dataKey="rate"
          stroke={theme.palette.primary.main}
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default AuditLineChartContent;
