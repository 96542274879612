import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function AlbumMedia(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 18 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49935 8.83333C7.88006 8.83333 8.99935 7.71405 8.99935 6.33333C8.99935 4.95262 7.88006 3.83333 6.49935 3.83333C5.11864 3.83333 3.99935 4.95262 3.99935 6.33333C3.99935 7.71405 5.11864 8.83333 6.49935 8.83333ZM5.66602 6.33333C5.66602 5.8731 6.03911 5.5 6.49935 5.5C6.95959 5.5 7.33268 5.8731 7.33268 6.33333C7.33268 6.79357 6.95959 7.16667 6.49935 7.16667C6.03911 7.16667 5.66602 6.79357 5.66602 6.33333Z"
        fill="#1F242F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33268 0.5C1.41221 0.5 0.666016 1.24619 0.666016 2.16667V13.8333C0.666016 14.7538 1.41221 15.5 2.33268 15.5H15.666C16.5865 15.5 17.3327 14.7538 17.3327 13.8333V2.16667C17.3327 1.24619 16.5865 0.5 15.666 0.5H2.33268ZM15.666 2.16667H2.33268V13.8333H2.45188L6.80498 10.2717C7.10176 10.0289 7.52592 10.0199 7.83268 10.25L8.95463 11.0915L12.1957 8.21049C12.496 7.94361 12.9439 7.92891 13.261 8.17554L15.666 10.0461V2.16667ZM15.666 13.8333H5.08385L7.35503 11.9751L8.49935 12.8333C8.81632 13.0711 9.25685 13.0527 9.55299 12.7895L12.7849 9.9167L15.666 12.1576V13.8333Z"
        fill="#1F242F"
      />
    </SvgIcon>
  );
}

export default React.memo(AlbumMedia);
