import moment, { Moment } from 'moment';
import { useRef } from 'react';

import { DatePicker } from '@mui/x-date-pickers';

type Props = {
  value?: Date | null;
  disabled?: boolean;
  onChange?: (value: Date | null) => void;
};

const DateInput = ({ value, disabled, onChange }: Props) => {
  const inputRef = useRef<any>(null);

  const handleChange = (momentValue: Moment | null) => {
    if (!momentValue?.isValid()) return;
    onChange?.(momentValue?.toDate() || null);
  };

  return (
    <DatePicker
      ref={inputRef}
      value={value ? moment(value, 'DD/MM/YYYY') : null}
      onChange={handleChange}
      slotProps={{
        textField: {
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root': {
              height: '40px',
            },
          },
          placeholder: 'Set Date',
        },
      }}
      format="DD/MM/YYYY"
      disabled={disabled}
      className="w-full bg-gray-200 rounded-20"
    />
  );
};
export default DateInput;
