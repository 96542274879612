import React from 'react';

import Drawer from '@mui/material/Drawer';

type Props = {
  open: boolean;
  children: JSX.Element;
  onToggle: (open: boolean) => void;
};

export default function DrawerWrapper(props: Props) {
  const toggleDrawer = (localOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    props.onToggle(localOpen);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={props.open}
      onClose={toggleDrawer(false)}
    >
      {props.children}
    </Drawer>
  );
}
