import { DataSelectV2 } from 'app/components/cores/data-select';
import { ITEM_TYPES_MAP, QUESTION_TYPE_OPTIONS } from 'constants/index';
import { Control, useController, useFormContext } from 'react-hook-form';

import TypeOption from './type-options/type-option';
import TypeOptionArrow from './type-options/type-option-arrow';
import TypeOptionValue from './type-options/type-option-value';

type Props = {
  name: string;
  control?: Control;
  onChangeCallback?: () => void;
};

function ItemTypeSelection({ name, control, onChangeCallback }: Props) {
  const { getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const commonAttributes = {
    required: true,
    notApplicable: true,
  };

  const handleChange = (newType: string) => {
    const { prompt, order, id } = getValues(name);
    let defaultItem = {};
    switch (newType) {
      case 'Items::Rating':
        defaultItem = {
          ...commonAttributes,
          prompt,
          order,
          id,
          type: newType,

          minNumber: 1,
          maxNumber: 5,
        };
        break;
      case 'Items::DateTime':
        defaultItem = {
          ...commonAttributes,
          prompt,
          order,
          id,
          type: newType,
          dateTimeType: 'datetime',
        };
        break;
      case ITEM_TYPES_MAP.MEDIA:
        defaultItem = {
          ...commonAttributes,
          prompt,
          order,
          id,
          type: newType,
          keepMediaOriginal: false,
        };
        break;
      default:
        defaultItem = { ...commonAttributes, prompt, order, id, type: newType };
        break;
    }

    onChange?.(defaultItem);
    onChangeCallback?.();
  };

  const getValue = (_value?: string) => {
    if (!_value) return undefined;
    return QUESTION_TYPE_OPTIONS?.find((item: any) => item.value === _value);
  };

  return (
    <div className="w-192">
      <DataSelectV2
        isDisabled={value.id}
        value={getValue(value.type)}
        options={QUESTION_TYPE_OPTIONS}
        components={{
          Option: TypeOption,
          SingleValue: TypeOptionValue,
          IndicatorsContainer: TypeOptionArrow,
        }}
        maxMenuHeight={1000}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        noOptionsMessage={() => 'No types'}
        onChange={handleChange}
        placeholder="Select a type"
      />
    </div>
  );
}

export default ItemTypeSelection;
