import ItemsEditor from 'app/components/cores/items-editor';
import { TLocation, TTask, TTaskLocation } from 'models';
import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';

function TaskQuestionSetting() {
  const methods = useFormContext<TTask>();
  const control = methods?.control;
  const register = methods?.register;

  const itemsAttributesName = 'currentVersion.itemsAttributes';
  const locationTasksAttributes = methods?.watch('locationTasksAttributes');

  const { fields } = useFieldArray({
    control,
    name: itemsAttributesName,
    keyName: 'DOMId',
  });

  const locations: TLocation[] = useMemo(() => {
    return (
      locationTasksAttributes
        ?.filter((location: TTaskLocation) => !!location?.id)
        ?.map(
          (location: TTaskLocation) =>
            ({
              id: location.id,
              externalId: location.locationId,
              name: location.locationName,
            } as TLocation),
        ) || []
    );
  }, [locationTasksAttributes]);

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="flex items-center">
          <Typography className="ml-24 text-11 w-512">
            <span className="font-600">Note:</span> Please create a new version before editing
          </Typography>
        </div>
      </div>

      <ItemsEditor
        isPublished={true}
        isDisabled={true}
        name={itemsAttributesName}
        control={control}
        items={fields as any}
        register={register}
        locations={locations}
      />
    </>
  );
}

export default TaskQuestionSetting;
