import clsx from 'clsx';
import { TItem, questionsLayoutView } from 'models';

import {
  Area,
  BarCode,
  CheckMark,
  DateTime,
  Employee,
  Media,
  MultipleChoice,
  Numeric,
  QrCode,
  Rating,
  SaltOpsForm,
  Section,
  Signature,
  Text,
  YesNo,
} from '../questions';

type Props = {
  question: TItem;
  readOnly?: boolean;
};

const QuestionMap = ({ question, readOnly = false }: Props) => {
  const getQuestionComponent = (type: string) => {
    const mapping = new Map();
    mapping.set('Items::YesNo', YesNo);
    mapping.set('Items::Numeric', Numeric);
    mapping.set('Items::Rating', Rating);
    mapping.set('Items::MultipleChoice', MultipleChoice);
    mapping.set('Items::CheckMark', CheckMark);
    mapping.set('Items::Media', Media);
    mapping.set('Items::Area', Area);
    mapping.set('Items::DateTime', DateTime);
    mapping.set('Items::Text', Text);
    mapping.set('Items::Employee', Employee);
    mapping.set('Items::SaltOpsForm', SaltOpsForm);
    mapping.set('Items::BarCode', BarCode);
    mapping.set('Items::QrCode', QrCode);
    mapping.set('Items::Signature', Signature);
    mapping.set('Items::Section', Section);

    return mapping.get(type);
  };

  const QuestionComponent = getQuestionComponent(question?.type);

  return (
    <div className={clsx(question.type !== 'Items::Section' && 'px-24 py-8')}>
      {QuestionComponent && (
        <QuestionComponent
          data={question}
          name={`response-${question.id}`}
          readOnly={readOnly}
          view={questionsLayoutView.TEMPLATE}
        />
      )}
    </div>
  );
};
export default QuestionMap;
