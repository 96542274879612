import clsx from 'clsx';
import { ElementRef, useRef } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  value: any;
  onMonthChange?: (dateObj: DateObject) => void;
  onYearChange?: (dateObj: DateObject) => void;
};

function BlackoutCalendar({ value, onMonthChange, onYearChange }: Props) {
  const calendarRef = useRef<ElementRef<typeof Calendar>>(null);
  const classes = useStyles();

  return (
    <div className={clsx('flex justify-center w-full', classes.calendar)}>
      <Calendar
        ref={calendarRef}
        multiple={true}
        range={true}
        value={value}
        weekStartDayIndex={1}
        className="w-full"
        headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        renderButton={(direction: any, handleClick: any) => (
          <IconButton
            size="small"
            className="ml-4"
            onClick={handleClick}
          >
            {direction === 'right' ? <ChevronRightOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
          </IconButton>
        )}
        onMonthChange={onMonthChange}
        onYearChange={onYearChange}
      />
    </div>
  );
}

export default BlackoutCalendar;

const useStyles = makeStyles((theme: any) => ({
  calendar: {
    '& .rmdp-wrapper': {
      width: '100%',
      boxShadow: 'none',
      fontFamily: theme.typography.fontFamily,
    },
    '& .rmdp-top-class': {
      width: '100%',
    },
    '& .rmdp-calendar': {
      width: '100%',
      padding: 0,
    },
    '& .rmdp-header-values': {
      margin: 0,
      marginRight: 'auto',
      '&  span': {
        padding: 0,
        fontSize: 20,
      },
      '& span:last-child': {
        fontWeight: 600,
      },
    },
    '& .rmdp-week-day': {
      flex: 1,
      color: theme.palette.secondary.main,
      fontWeight: 400,
    },
    '& .rmdp-week': {
      marginBottom: 4,
    },
    '& .rmdp-day-picker': {
      width: '100%',
      padding: 0,
      pointerEvents: 'none',
      '& > div': {
        width: '100%',
      },
    },
    '& .rmdp-day': {
      flex: 1,
      width: 36,
      height: 36,
    },
    '& .rmdp-range': {
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      '&.start': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&.end': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '& .rmdp-today span': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      minWidth: 30,
      height: 30,
      borderRadius: 30,
      margin: '0 auto',
    },
  },
}));
