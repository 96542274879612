import clsx from 'clsx';
import { TUserRole } from 'models';
import { TEmployeeTaskFormatted, TEmployeeTaskItem } from 'models/employee/task.model';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TaskSidebarFilter from './filter';
import TaskSidebarSection from './section';
import { DuplicateTaskSidebarItemSkeleton, TaskSidebarNameRoleSkeleton } from './skeleton';

type Props = {
  lists?: any;
  selectedList?: TEmployeeTaskItem | null;
  isLoading?: boolean;
  hasFilter?: boolean;
  roles?: TUserRole[];
  searchValue?: string;
  isFacility?: boolean;
  isRenderSection?: (task: TEmployeeTaskFormatted) => boolean;
  locationRoles?: TUserRole[];
  onSelectList?: (task: TEmployeeTaskItem, isRefetchLists?: boolean) => void;
  onSearch?: (search: string) => void;
  onSelectRoles?: (roles: TUserRole[]) => void;
};

function TaskSidebarTablet({
  lists,
  selectedList,
  isLoading,
  hasFilter,
  roles,
  searchValue,
  isFacility,
  isRenderSection,
  locationRoles,
  onSelectList,
  onSearch,
  onSelectRoles,
}: Props) {
  const classes = useStyles();

  if (!lists && !isLoading) {
    return (
      <div className="flex col-span-4 justify-center items-center pb-8 w-64 h-full bg-white md:w-auto xl:col-span-3">
        <Typography className="text-13">No List</Typography>
      </div>
    );
  }

  if (isLoading && !hasFilter) {
    return (
      <div className="overflow-hidden col-span-4 pl-16 space-y-16 w-64 h-full md:w-auto xl:col-span-3">
        <div className={clsx('overflow-auto mt-16 bg-white')}>
          <TaskSidebarNameRoleSkeleton />
          <DuplicateTaskSidebarItemSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden pl-16 space-y-16 w-64 h-full md:w-auto md:col-span-4 xl:col-span-3">
      <TaskSidebarFilter
        roles={roles}
        searchValue={searchValue}
        isFacility={isFacility}
        locationRoles={locationRoles}
        onSearch={onSearch}
        onSelectRoles={onSelectRoles}
      />
      {isLoading ? (
        <DuplicateTaskSidebarItemSkeleton />
      ) : (
        <div className={clsx('overflow-auto', classes.sidebar)}>
          {lists?.map(
            (task: TEmployeeTaskFormatted) =>
              isRenderSection?.(task) && (
                <TaskSidebarSection
                  key={task.taskGroup}
                  item={task}
                  selectedList={selectedList}
                  isFacility={isFacility}
                  onSelectList={onSelectList}
                />
              ),
          )}
        </div>
      )}
    </div>
  );
}

export default TaskSidebarTablet;

const useStyles = makeStyles(() => {
  return {
    sidebar: {
      height: 'calc(100% - 120px)',
    },
  };
});
