import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function Salt(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
    >
      <path
        d="M2.5 8.99009C2.5 8.53547 2.86855 8.16692 3.32317 8.16692C3.7778 8.16692 4.14634 8.53547 4.14634 8.99009V12.8316C4.14634 13.2862 3.7778 13.6547 3.32317 13.6547C2.86855 13.6547 2.5 13.2862 2.5 12.8316V8.99009Z"
        fill="currentColor"
      />
      <path
        d="M5.06098 7.52668C5.06098 7.07205 5.42952 6.70351 5.88415 6.70351C6.33877 6.70351 6.70732 7.07205 6.70732 7.52668V15.3925C6.70732 15.8472 6.33877 16.2157 5.88415 16.2157C5.42952 16.2157 5.06098 15.8472 5.06098 15.3925V7.52668Z"
        fill="currentColor"
      />
      <path
        d="M7.80488 4.59985C7.80488 4.14522 8.17342 3.77668 8.62805 3.77668C9.08267 3.77668 9.45122 4.14522 9.45122 4.59985V14.6608C9.45122 15.1154 9.08267 15.484 8.62805 15.484C8.17342 15.484 7.80488 15.1154 7.80488 14.6608V4.59985Z"
        fill="currentColor"
      />
      <path
        d="M10.3659 6.9779C10.3659 6.52327 10.7344 6.15473 11.189 6.15473C11.6436 6.15473 12.0122 6.52327 12.0122 6.9779V11.734C12.0122 12.1886 11.6436 12.5572 11.189 12.5572C10.7344 12.5572 10.3659 12.1886 10.3659 11.734V6.9779Z"
        fill="currentColor"
      />
      <path
        d="M13.1098 4.41692C13.1098 3.9623 13.4783 3.59375 13.9329 3.59375C14.3876 3.59375 14.7561 3.9623 14.7561 4.41692V13.0145C14.7561 13.4691 14.3876 13.8377 13.9329 13.8377C13.4783 13.8377 13.1098 13.4691 13.1098 13.0145V4.41692Z"
        fill="currentColor"
      />
      <path
        d="M15.8537 8.99009C15.8537 8.53547 16.2222 8.16692 16.6768 8.16692C17.1315 8.16692 17.5 8.53547 17.5 8.99009V13.3803C17.5 13.835 17.1315 14.2035 16.6768 14.2035C16.2222 14.2035 15.8537 13.835 15.8537 13.3803V8.99009Z"
        fill="currentColor"
      />
      <path
        d="M14.939 15.1181C14.939 15.0171 15.0209 14.9352 15.122 14.9352H16.4024C16.5035 14.9352 16.5854 15.0171 16.5854 15.1181V16.2157C16.5854 16.3167 16.5035 16.3986 16.4024 16.3986H15.122C15.0209 16.3986 14.939 16.3167 14.939 16.2157V15.1181Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(Salt);
