import Header from 'app/components/Layout/headers/blank-header-v2';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import { usePagePrompt } from 'app/components/cores/page-prompt';
import TaskForm from 'app/components/lists/form';
import { useCreateTask } from 'app/hooks/api/tasks';
import useTab from 'app/hooks/ui/use-tab';
import useBack from 'app/hooks/use-back';
import useShowMessage from 'app/hooks/use-show-message';
import { Paths } from 'constants/index';
import { updateItemsAttributes } from 'domains/item.domain';
import {
  generateDefaultGlobalAssignmentAttributes,
  generateTaskCode,
  taskFullSchema as schema,
} from 'domains/task.domain';
import { Location } from 'history';
import _omit from 'lodash/omit';
import { TAssignment, TTask } from 'models';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

function New() {
  // Common
  const history = useHistory();
  const { showError, showSuccess } = useShowMessage();
  const { goBack } = useBack(Paths.list);
  const { tabValue, handleTabChange } = useTab(0);

  const { checkPageChange } = usePagePrompt();
  const [saved, setSaved] = useState(false);

  // Data
  const { mutateAsync: createTask, isLoading } = useCreateTask();

  const form = useForm<TTask>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = form;

  const checkCondition = useCallback(
    (nextLocation: Location) => {
      if (saved) {
        return false;
      }

      if (!isDirty) {
        return false;
      }

      return checkPageChange(nextLocation.pathname);
    },
    // eslint-disable-next-line
    [saved, isDirty],
  );

  // Functions
  const handleBackClick = () => {
    goBack();
  };

  const handleCancelButton = () => {
    handleBackClick();
  };

  const handleTaskError = (errors: any) => {
    let statusError: string | null = null;
    errors?.forEach((error: any) => {
      const message = `${error?.field} ${error?.message?.[0]}`;
      if (error?.field === 'status') {
        statusError = message;
      }
      form.setError(error?.field, { type: 'custom', message });
    });
    showError(statusError || 'Could not create List');
  };

  // TODO handle create task
  const handleCreateTask = (status?: string, isLeaving?: boolean) => async (value: Partial<TTask>) => {
    const taskTags = value?.taskTagsAttributes?.map(taskTag => _omit(taskTag, ['id']));

    const itemsAttributes = updateItemsAttributes(value?.itemsAttributes);
    const newStatus = status || value?.status;
    const currentVersionAttributes = {
      status: newStatus,
      itemsAttributes,
    };
    const payload = {
      task: {
        ...value,
        status: newStatus,
        currentVersionAttributes,
        taskTagsAttributes: taskTags,
        globalAssignmentAttributes: generateDefaultGlobalAssignmentAttributes() as Partial<TAssignment>,
        code: generateTaskCode(),
      } as Partial<TTask>,
    };

    setSaved(true);

    return createTask(payload)
      .then((createdTask: TTask) => {
        showSuccess('List has been successfully created');
        const taskId = createdTask?.id;
        if (isLeaving || !taskId) {
          history.push(Paths.list);
        } else {
          history.replace(`${Paths.list}/${taskId}`);
        }
        return Promise.resolve();
      })
      .catch(err => {
        handleTaskError(err?.errors);
        setSaved(false);
        return Promise.reject();
      });
  };

  const handleInvalid = async () => {
    showError('Could not create List');
  };

  const handleLeavePage = async () => {
    const values = getValues();
    return handleCreateTask('draft', true)(values);
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <Typography className="ml-16 uppercase text-18 font-600 line-clamp-1">Add New List</Typography>
            </div>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleCancelButton}
                    btnTitle="Cancel"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="contained"
                    onClick={handleSubmit(handleCreateTask('draft'), handleInvalid)}
                    btnTitle="Create"
                  />
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
          onChange={handleTabChange}
        >
          <Tab
            className="h-48 normal-case font-400"
            label="Info"
          />
        </Tabs>
      }
      content={
        <div className="relative w-full h-full p-16 sm:p-24 bg-paper">
          <FormProvider {...form}>
            <TaskForm tabIndex={tabValue} />
          </FormProvider>
        </div>
      }
      leavePageCondition={checkCondition}
      onLeavePage={handleLeavePage}
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default New;
