import { Control, useController } from 'react-hook-form';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

type Props = {
  name: string;
  label: string;
  control?: Control;
};

const SkipSaltInbox = ({ name, control, label }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue: false });

  const handleCheck = (checked: boolean) => {
    onChange(checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
          icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
        />
      }
      label={<Typography className="text-12 font-400">{label}</Typography>}
      onChange={(_, checked) => handleCheck(checked)}
    />
  );
};

export default SkipSaltInbox;
