import ErrorMessage from 'app/components/cores/form/error-message';
import TextInput from 'app/components/cores/inputs/text';
import { Control, useController } from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

type Props = {
  control?: Control;
  name: string;
  index: number;
  onRemove?: () => void;
};

const MultipleChoiceItem = ({ index, name, control, onRemove }: Props) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    onChange?.({ value: index, label: newValue });
  };

  const generateAnswerLabel = (_index: number) => {
    const answerLabel = String.fromCharCode(65 + _index);
    return answerLabel;
  };

  const errorMessage = (error as any)?.value?.message || (error as any)?.label?.message;

  return (
    <div>
      <div className="flex flex-row w-full space-x-8">
        <Typography
          className="mt-8 font-bold max-w-40 text-13 text-secondaryLight"
          sx={{ width: '24px' }}
        >
          {generateAnswerLabel(index)}
        </Typography>
        <TextInput
          multiline={true}
          value={value?.label || ''}
          onChange={handleChange}
          className="w-full px-8 mt-4 bg-paper rounded-8"
          variant="standard"
          InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-bold', disableUnderline: true }}
        />

        <IconButton onClick={onRemove}>
          <DeleteOutlineIcon className="text-16" />
        </IconButton>
      </div>
      <ErrorMessage message={errorMessage} />
    </div>
  );
};

export default MultipleChoiceItem;
