import ErrorMessageText from 'app/components/cores/form/error-message';
import { SingleSelect } from 'app/components/cores/select';
import { ComponentProps } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { type ActionMeta } from 'react-select';

import { ErrorMessage } from '@hookform/error-message';

type SelectOption = {
  id: number;
  name: string;
};

type Props = {
  name: string;
  control?: Control;
  onChange?: (value: number | null) => void;
  placeholder?: string;
} & Omit<ComponentProps<typeof SingleSelect>, 'onChange'>;

const ControlledSelectList = ({
  name,
  control,
  onChange: onChangeProp,
  placeholder,
  menuPlacement,
  isClearable = true,
  options,
  filterOption,
  ...rest
}: Props) => {
  const {
    field: { onChange },
  } = useController({ name, control });

  const value = useWatch({
    control,
    name,
  });

  const handleChange = (option: any, action: ActionMeta<any>) => {
    if (action.action === 'clear') {
      onChange?.(null);
      onChangeProp?.(null);
      return;
    }

    const optionId = typeof option.id === 'number' ? +option.id : option.id;

    onChange(option ? optionId : null);
    onChangeProp?.(option ? optionId : null);
  };

  const selectedValue =
    value !== null && value !== undefined ? (options as SelectOption[]).find(option => option?.id === value) : null;

  return (
    <>
      <SingleSelect
        value={selectedValue ?? null}
        onChange={handleChange}
        isSearchable={true}
        options={options}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.id}
        menuPlacement={menuPlacement}
        isClearable={isClearable}
        placeholder={placeholder}
        filterOption={filterOption}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        {...rest}
      />

      <ErrorMessage
        name={name}
        render={({ message }) => <ErrorMessageText message={message} />}
      />
    </>
  );
};

export default ControlledSelectList;
