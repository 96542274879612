import { TEmployeeTaskItem, TEmployeeTasks } from 'models/employee/task.model';

import { useGetList, useGetOne } from '../request';

const taskURL = '/api/manager/tasks';

export const facilityTaskKeys = {
  all: ['facilityTasks'] as const,
  lists: () => [...facilityTaskKeys.all, 'list'] as const,
  list: (filters: any) => [...facilityTaskKeys.lists(), { filters }] as const,
  details: () => [...facilityTaskKeys.all, 'detail'] as const,
  detail: (id: number, locationId?: number) => [...facilityTaskKeys.details(), id, locationId] as const,
};

export function useGetFacilityTasks(filters: any, options?: any) {
  return useGetList<TEmployeeTasks>(
    [...facilityTaskKeys.list(filters)],
    `${taskURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useGetFacilityTask(taskId?: number | string, locationId?: number, options?: any) {
  const url = locationId ? `${taskURL}/${taskId}?location_id=${locationId}` : `${taskURL}/${taskId}`;
  return useGetOne<TEmployeeTaskItem>(
    [...facilityTaskKeys.detail(Number(taskId), Number(locationId))],
    url,
    {},
    {
      enabled: !!taskId,
      cacheTime: 0,
      ...options,
    },
  );
}
