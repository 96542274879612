import { DEFAULT_END_TIME, DEFAULT_START_TIME } from 'constants/index';
import { generateDisplayTimes, generateDisplayTimesByCount } from 'domains/display-time.domain';
import { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';

import DisplayTimePreviewHeader from './preview-header';
import DisplayTimePreviewItem from './preview-item';

const modalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const boxStyles = {
  bgcolor: 'background.default',
  borderRadius: 2,
  paddingTop: 1,
  paddingBottom: 2,
  width: 640,
  maxWidth: '90%',
  height: 640,
  maxHeight: '90%',
  margin: '0 auto',
  overflow: 'hidden',
};

type Props = {
  open: boolean;
  displayTime?: any;
  assignmentStartTime?: string;
  assignmentEndTime?: string;
  frequency?: number;
  onClose: () => void;
};

function DisplayTimePreview({ open, displayTime, assignmentStartTime, assignmentEndTime, frequency, onClose }: Props) {
  const {
    time: sTime,
    endTime: eTime,
    count,
    interval,
    dueIn,
    expireIn,
    displayUntil,
    dueUntil,
    expireUntil,
  } = displayTime || {};
  const startTime = sTime || assignmentStartTime || DEFAULT_START_TIME;
  const endTime = eTime || assignmentEndTime || DEFAULT_END_TIME;
  const isGenerateList = count === -1 || count > 1;
  const isShowNote = !sTime;

  const displayTimeItems = useMemo(() => {
    if (count > 1) {
      return generateDisplayTimesByCount({ startTime, endTime, interval, dueIn, expireIn, count });
    }

    if (count === -1) {
      return generateDisplayTimes({ startTime, endTime, interval, dueIn, expireIn });
    }

    return [];
  }, [count, startTime, endTime, interval, dueIn, expireIn]);

  return (
    <Modal
      open={open}
      sx={modalStyles}
      onClose={onClose}
    >
      <Box sx={boxStyles}>
        <div className="sticky top-0 flex items-center justify-between pl-24 pr-12">
          <Typography className="text-20 font-600">Display Time Preview</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className="text-20 text-secondaryMain" />
          </IconButton>
        </div>

        {isShowNote && (
          <Typography className="px-24 text-11 font-400 text-secondaryLight">
            The display times will be determined by the start and end times set for individual locations. This preview
            is for illustrative purposes and uses a demo start time of 07:00 and end time of 17:00
          </Typography>
        )}

        <div className="h-full mt-24">
          <DisplayTimePreviewHeader />

          {isGenerateList ? (
            <div className="h-full overflow-auto pb-88">
              {displayTimeItems?.map((item: any, index: number) => (
                <DisplayTimePreviewItem
                  key={item.startTime}
                  isEven={index % 2 === 0}
                  startTime={item.startTime}
                  dueIn={item.dueIn}
                  expireIn={item.expireIn}
                />
              ))}
            </div>
          ) : (
            <DisplayTimePreviewItem
              startTime={startTime}
              dueIn={dueIn}
              expireIn={expireIn}
              displayUntil={displayUntil}
              dueUntil={dueUntil}
              expireUntil={expireUntil}
              frequency={frequency}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default DisplayTimePreview;
