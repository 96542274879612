import ErrorMessage from 'app/components/cores/form/error-message';
import { TItem } from 'models';
import { Control, useController } from 'react-hook-form';

import { Typography } from '@mui/material';

import { MultipleLogicSelect } from './multiple-logic-select';

type Props = {
  name: string;
  control?: Control;
  selectedConditionalItem: TItem;
  defaultCondition?: any;
  instructionText?: string;
  onChange?: (item: any) => void;
};

export function MultipleConditionSelect({
  name,
  control,
  selectedConditionalItem,
  defaultCondition,
  instructionText = 'Display this, if answer is',
  onChange,
}: Props) {
  const {
    fieldState: { error },
  } = useController({ control, name });

  const errorMessage = error?.message;

  return (
    <>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">{instructionText}</Typography>
        <MultipleLogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={defaultCondition}
          placeholder="Select conditional"
          onChange={onChange}
        />
      </div>
      <ErrorMessage message={errorMessage} />
    </>
  );
}
