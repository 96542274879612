import QuestionIconType from 'app/components/cores/items-editor/question-icon-type';
import { BULK_IMPORT_OPTIONS, ITEM_TYPES } from 'constants/index';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export default function ImportTypeMappingTable() {
  return (
    <Accordion className="w-full bg-white shadow-0 rounded-0">
      <AccordionSummary expandIcon={<ExpandMoreIcon className="text-20" />}>
        <div className="flex justify-end w-full pr-8 ">
          <Typography className="font-500">** Type Importing List</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Import File Types</TableCell>
                <TableCell align="left">My Task Types</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {BULK_IMPORT_OPTIONS.map(row => {
                const Icon = QuestionIconType(row.type);

                return (
                  <TableRow
                    key={row.value}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {row.value}
                    </TableCell>
                    <TableCell align="left">
                      {Icon && (
                        <Icon
                          color="primary"
                          className="mr-12 text-18"
                        />
                      )}
                      {ITEM_TYPES.find(x => x.type === row.type)?.label}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}
