import clsx from 'clsx';
import { useState } from 'react';

import Box from '@mui/material/Box';

const TABS = [
  { label: 'My Tasks', value: 0 },
  { label: 'Facility Tasks', value: 1, hide: true },
  { label: 'Calendar', value: 2, hide: true },
];

const TaskNav = () => {
  const [value] = useState(0);

  return (
    <div className="flex items-center h-full border-b-1">
      {TABS.map(tab => (
        <div
          key={tab.value}
          className="relative flex-1 h-full text-center"
        >
          <button
            className={clsx(
              `flex-1 h-full text-secondaryLight font-bold text-13`,
              value === tab.value && ' text-secondaryMain',
              tab.hide && 'hidden',
            )}
            type="button"
          >
            {tab.label}
          </button>

          {value === tab.value && (
            <Box
              className="absolute bottom-0 left-0 w-full"
              sx={{
                backgroundColor: 'primary.main',
                height: '2px',
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default TaskNav;
