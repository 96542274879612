import { forwardRef } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

const RadioInput = forwardRef((props: { options: { label: string; value: any }[] } & RadioGroupProps, ref: any) => {
  const { options, ...restProps } = props;
  return (
    <FormControl ref={ref}>
      <RadioGroup {...restProps}>
        {options.map((option: any) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size="small" />}
            label={option.label}
            classes={{
              label: 'text-12',
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default RadioInput;
