import useMediaQuery from '@mui/material/useMediaQuery';

function useScreen() {
  const is1280 = useMediaQuery('(min-width:1280px)');
  const is1366 = useMediaQuery('(min-width:1366px)');
  const is1440 = useMediaQuery('(min-width:1440px)');
  const is1536 = useMediaQuery('(min-width:1536px)');
  const is1600 = useMediaQuery('(min-width:1600px)');
  const is1920 = useMediaQuery('(min-width:1920px)');
  const is2560 = useMediaQuery('(min-width:2560px)');
  const is3840 = useMediaQuery('(min-width:3840px)');

  return {
    is1280,
    is1366,
    is1440,
    is1536,
    is1600,
    is1920,
    is2560,
    is3840,
  };
}

export default useScreen;
