import ConfirmDialog from 'app/components/cores/confirm-dialog';
import StatusBadge from 'app/components/cores/tasks/status-badge';
import AddActionManagerModal from 'app/components/histories/audit/action-items-modal/add-action-manager-modal';
import TableHeader from 'app/components/histories/audit/action-items-modal/table-header';
import { useDeleteActionItem, useGetActionsByListId } from 'app/hooks/api/manager/audit';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { type TActionItem } from 'models';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { formatDate } from 'utils/date';

import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Actions from './actions';
import ActionItemsLoading from './loading';

type Props = {
  listId: number | null;
  onClose: () => void;
  auditName: string;
  user: string;
  date: string;
};

const ActionItemsModal = ({ listId, onClose, auditName, user, date }: Props) => {
  const {
    data: actionsItems = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetActionsByListId(listId!, { enabled: !!listId });
  const { mutate: deleteActionItem, isLoading: isDeletingActionItem } = useDeleteActionItem(listId!);
  const dispatch = useDispatch();
  const { state: isOpenAddActionModal, setActive: openAddActionModal, setInactive: closeAddActionModal } = useToggle();
  const [actionItemToEdit, setActionItemToEdit] = useState<TActionItem | undefined>();

  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const handleCloseDialog = () => {
    dispatch(closeDialog({}));
  };

  const confirmDelete = (id: number) => {
    deleteActionItem({ id });
    handleCloseDialog();
  };

  const handleClickDelete = (id: number) => () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete Action Item"
            message="Are you sure you want to delete this action item?"
            onClose={handleCloseDialog}
            onConfirm={() => confirmDelete(id)}
            isLoading={isDeletingActionItem}
            cancelButtonLabel="No, Cancel"
            confirmButtonLabel="Yes, Delete it"
          />
        ),
      }),
    );
  };

  const handleClickEdit = (id: number) => () => {
    const foundActionItem = actionsItems.find(actionItem => actionItem.id === id);
    setActionItemToEdit(foundActionItem);
    openAddActionModal();
  };

  const handleClickAddAction = () => {
    setActionItemToEdit(undefined);
    openAddActionModal();
  };

  return (
    <>
      {isOpenAddActionModal && (
        <AddActionManagerModal
          isOpen={isOpenAddActionModal}
          onClose={closeAddActionModal}
          listId={listId ?? 0}
          responseId={actionItemToEdit?.id ?? 0}
          value={actionItemToEdit}
          isAuditList={true}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={!!listId}
        classes={{
          paper: 'rounded-16',
        }}
      >
        <DialogTitle
          component="div"
          className="flex justify-between items-start space-y-8 normal-case bg-white"
        >
          <div className="flex-1">
            <Typography className="text-13 text-secondaryMain font-600">Action Items</Typography>
            <Typography className="mt-8 text-18 text-secondaryMain font-600">{auditName}</Typography>
            <div className="flex gap-8 justify-between items-start w-full">
              <Typography className="mt-8 text-13 text-secondaryLight font-500">
                {user} | {formatDate(date, 'HH:mm - DD/MM/YYYY')}
              </Typography>

              <Button
                disableElevation
                variant="contained"
                className="h-40 text-13 font-500 rounded-8"
                onClick={handleClickAddAction}
              >
                Add Action
              </Button>
            </div>
          </div>
          <IconButton
            aria-label="close"
            sx={{ right: 8, top: 8 }}
            className="absolute text-16"
            onClick={onClose}
          >
            <CloseIcon className="text-black text-20" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className="flex overflow-y-auto flex-col gap-4 px-24 bg-white h-512"
          dividers
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <ActionItemsLoading />
          ) : actionsItems?.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              threshold={50}
              useWindow={false}
            >
              <TableContainer component="div">
                <Table sx={{ minWidth: 650 }}>
                  <TableHeader />
                  <TableBody>
                    {actionsItems.map(row => (
                      <TableRow
                        key={row.id}
                        className="border-0"
                      >
                        <TableCell
                          width="35%"
                          className="border-0"
                          sx={{ verticalAlign: 'top' }}
                        >
                          <Typography className="text-13 text-secondaryMain font-600">
                            {row.important ? (
                              <>
                                <StarIcon
                                  sx={{ color: '#F5D02F' }}
                                  className="text-20"
                                />
                                &nbsp;
                              </>
                            ) : null}
                            {row.description}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="20%"
                          className="border-0"
                          sx={{ verticalAlign: 'top' }}
                        >
                          {row.users.length > 0
                            ? row.users.map(assignee => (
                                <Typography
                                  key={assignee.id}
                                  className="text-13 text-secondaryMain font-500"
                                >
                                  {assignee.fullName}
                                </Typography>
                              ))
                            : 'Task Item Trigger'}
                        </TableCell>
                        <TableCell
                          width="15%"
                          className="border-0"
                          sx={{ verticalAlign: 'top' }}
                        >
                          <Typography className="text-13 text-secondaryMain font-500">
                            {row.expireAt ? formatDate(row.expireAt, 'HH:mm - DD/MM/YYYY') : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell
                          width="20%"
                          className="border-0"
                          sx={{ verticalAlign: 'top' }}
                        >
                          <div className="mt-4">
                            <StatusBadge status={row.submitStatus} />
                            {row.completedAt ? (
                              <Typography className="mt-4 text-center text-11 font-400 text-secondaryLight">
                                {formatDate(row.completedAt, 'HH:mm DD/MM/YYYY')}
                              </Typography>
                            ) : null}
                          </div>
                        </TableCell>

                        <TableCell
                          width="10%"
                          className="flex gap-8 border-0"
                        >
                          <Actions
                            onClickEdit={handleClickEdit(row.id)}
                            onClickDelete={handleClickDelete(row.id)}
                            isLoading={isDeletingActionItem}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          ) : (
            <div className="flex justify-center">
              <Typography className="text-13 text-secondaryLight font-500">No actions found</Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActionItemsModal;
