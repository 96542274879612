import MenuTablet from './MenuTablet';

type Props = {
  items?: any;
};

function TopBarTablet({ items }: Props) {
  return (
    <>
      <div className="flex items-center w-full h-64 px-24 py-6 bg-white">
        <MenuTablet items={items} />
      </div>
    </>
  );
}

export default TopBarTablet;
