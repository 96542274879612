import { ControlledTextInput } from 'app/components/cores/inputs/text';
import Toggle from 'app/components/cores/inputs/toggle/toggle';
import { Control } from 'react-hook-form';

import { FormControlLabel, Typography } from '@mui/material';

import TodoRoleAssignment from './role-assignment';
import TodoUserAssignment from './user-assignment';

type Props = {
  name: string;
  control?: Control;
  locationIds?: number[];
};

function TodoForm({ name, control, locationIds }: Props) {
  const actionName = `${name}[0]`;
  const descriptionName = `${actionName}.description`;
  const importantName = `${actionName}.important`;

  return (
    <div className="space-y-16">
      {/* description */}
      <ControlledTextInput
        name={descriptionName}
        placeholder="Write to-do..."
        control={control}
        variant="filled"
        className="h-full shadow"
        multiline={true}
        minRows={3}
        InputProps={{
          className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
          disableUnderline: true,
          classes: { input: 'p-8 text-13' },
        }}
      />

      {/* important */}
      <FormControlLabel
        className="ml-0"
        control={
          <Toggle
            name={importantName}
            control={control}
          />
        }
        label={<Typography className="ml-8 text-13 font-400">Mark as important</Typography>}
        labelPlacement="end"
      />

      {/* assignment */}
      <div>
        <Typography className="text-12 font-500 text-secondaryLight">Assign To</Typography>
        <TodoRoleAssignment
          name={actionName}
          control={control}
          locationIds={locationIds}
        />
        <TodoUserAssignment
          name={actionName}
          control={control}
        />
      </div>
    </div>
  );
}

export default TodoForm;
