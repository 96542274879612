import { formatTotal } from 'domains/report/performance.domain';
import _ from 'lodash';
import { TReportPerformanceMetadata } from 'models';
import { useCallback, useMemo } from 'react';
import { Cell, useTable } from 'react-table';
import { clsxm } from 'utils/clsxm';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, Typography } from '@mui/material';

type Props = {
  reports?: TReportPerformanceMetadata;
};

function PerformanceReportList({ reports }: Props) {
  const { kind, total, performances } = reports || {};

  const LocationCell = useCallback(
    ({ cell }: { cell: Cell<any> }) => <Typography className="text-center">{cell.value?.name}</Typography>,
    [],
  );

  const CompletionRateCell = useCallback(
    ({ cell }: { cell: Cell<any> }) => <Typography className="text-center">{cell.value?.completionRate}</Typography>,
    [],
  );

  const HeaderComponent = useCallback((value: { title: string; current: boolean }) => {
    return (
      <span className={clsxm('text-center', value?.current && '!font-600')}>
        {value?.title}
        {value?.current && (
          <Tooltip title="The performance score for this period is a current estimate and might be adjusted based on the working list.">
            <InfoOutlinedIcon className="text-14" />
          </Tooltip>
        )}
      </span>
    );
  }, []);

  const data = useMemo(() => {
    return _.map(performances, item => ({
      location: item.location,
      ...formatTotal(item.data, kind),
    }));
  }, [performances, kind]);

  const columns = useMemo(() => {
    const defaultColumn = {
      Header: 'Location',
      accessor: 'location',
      Cell: LocationCell,
      classes: {
        header: 'min-w-256',
        title: 'w-full text-center',
      },
    };

    const dataColumns: any = [];
    const formattedTotal = formatTotal(total, kind);

    _.forIn(formattedTotal, (value, key) => {
      dataColumns.push({
        Header: () => HeaderComponent(value),
        accessor: key,
        Cell: CompletionRateCell,
        classes: {
          header: clsxm({
            'min-w-128': kind === 'daily',
            'min-w-224': kind === 'weekly',
            'min-w-160': kind === 'monthly',
          }),
          title: 'whitespace-pre-line w-full text-center font-400',
        },
      });
    });

    return [defaultColumn, ...dataColumns];
  }, [total, kind, CompletionRateCell, LocationCell, HeaderComponent]);

  const { headerGroups, rows, prepareRow }: any = useTable({
    data,
    columns,
  });

  return (
    <table className="min-w-full border-collapse table-fixed">
      <thead className="relative z-10">
        {headerGroups?.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((header: any, index: number) => {
              const firstCell = index === 0;
              return (
                <th
                  {...header.getHeaderProps()}
                  className={clsxm(
                    'border-gray-300 border-1 py-8',
                    header.classes?.header,
                    firstCell && 'sticky left-0 bg-white z-10',
                  )}
                >
                  <div className="flex items-center justify-start px-8 min-h-28">
                    <Typography className={clsxm('text-13 text-secondaryMain font-600', header.classes?.title)}>
                      {header.render('Header')}
                    </Typography>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows?.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any, index: number) => {
                const firstCell = index === 0;
                const { current, isFuture } = cell.value || {};
                return (
                  <td
                    {...cell.getCellProps()}
                    className={clsxm(
                      'py-8 border-gray-300 border-1',
                      firstCell && 'sticky left-0 bg-white z-10',
                      current && '!bg-paper',
                    )}
                  >
                    {isFuture ? (
                      <div className="text-center min-h-28"> - </div>
                    ) : (
                      <div className="min-h-28">{cell.render('Cell')}</div>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default PerformanceReportList;
