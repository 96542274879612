import clsx from 'clsx';
import _ from 'lodash';
import { TTaskLocation } from 'models';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type Props = {
  control: Control;
  mode?: 'add' | 'edit';
  locationTasksAttributes?: TTaskLocation[];
};

function TaskVariationLocation({ control, mode, locationTasksAttributes }: Props) {
  const isAddVariation = mode === 'add';
  const fieldName = isAddVariation ? 'locationIds' : 'location';

  const {
    field: { value, onChange },
  } = useController({
    name: fieldName,
    control,
  });

  const locationIds = useMemo(() => {
    if (_.isArray(value)) return value;
    return value ? [value?.id] : [];
  }, [value]);

  const handleChangeLocation = (event: any) => {
    const locationId = Number(event.target.value);
    const nextLocationIds = _.cloneDeep(locationIds || []);

    if (!nextLocationIds?.includes(locationId)) {
      nextLocationIds.push(locationId);
    } else {
      _.pull(nextLocationIds, locationId);
    }

    onChange(nextLocationIds);
  };

  return (
    <div className="flex flex-col">
      <Typography className="text-13 font-400">1. Select applied location(s)</Typography>
      {locationTasksAttributes?.map((location: TTaskLocation) => (
        <FormControlLabel
          key={location.locationId}
          className={clsx(!isAddVariation && 'pointer-events-none')}
          control={
            <Checkbox
              value={location.locationId}
              checked={!!locationIds?.includes(location.locationId)}
              disabled={!isAddVariation && !locationIds?.includes(location.locationId)}
            />
          }
          label={location.locationName}
          onChange={handleChangeLocation}
        />
      ))}
    </div>
  );
}

export default TaskVariationLocation;
