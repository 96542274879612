import Header from 'app/components/Layout/headers/blank-header-v2';
import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import HistoryForm from 'app/components/histories/form';
import { useGetHistory } from 'app/hooks/api/manager/histories';
import useBack from 'app/hooks/use-back';
import clsx from 'clsx';
import { Paths } from 'constants/index';
import { TEmployeeList } from 'models';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { isAdmin } from 'utils/role';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    status: yup.string(),
  })
  .required();

interface ParamsTypes {
  employeeTaskId: string;
}

const useStyles = makeStyles(() => ({
  late: {
    background: '#FFF8DB',
    fontWeight: '500 !important',
    color: '#806C14 !important',
    fontSize: '11px !important',
  },
  onTime: {
    background: '#F2FCFF',
    fontWeight: '500 !important',
    color: 'rgb(0, 160, 212) !important',
    fontSize: '11px !important',
  },
  expired: {
    background: '#F7F7F7',
    fontWeight: '500 !important',
    color: '#E0115F !important',
    fontSize: '11px !important',
  },
}));

function Edit() {
  // Common
  const { goBack } = useBack(`${Paths.history}/lists`);

  const routeParams = useParams<ParamsTypes>();
  const { employeeTaskId: stringTaskId } = routeParams;
  const employeeTaskId = Number(stringTaskId);

  const classes = useStyles();

  // Data
  const { permissions, role: roles } = useSelector(({ auth }: any) => auth.user);

  const canExportPDF =
    permissions?.['managerReports.export'] || permissions?.['managerAuditReport.export'] || isAdmin(roles);

  const { data: employeeTask } = useGetHistory(employeeTaskId);

  const form = useForm<TEmployeeList>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // Effect
  useEffect(() => {
    form.reset(employeeTask);
  }, [employeeTask, form]);

  // Functions
  const handleBackClick = () => {
    goBack();
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => (printRef as any).current,
  });

  const handleExportPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    handlePrint();
  };

  const generateClassStatus = () => {
    if (employeeTask?.submitStatus === 'Completed Late') {
      return classes.late;
    }
    if (employeeTask?.submitStatus === 'Expired') {
      return classes.expired;
    }
    return classes.onTime;
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-64 h-64 border-b',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <div className="flex flex-row space-x-16">
                <Typography className="ml-16 text-18 font-600 line-clamp-1 w-fit">
                  {employeeTask?.task?.name}
                </Typography>
                <Typography
                  className={clsx(
                    'px-8 pt-4 h-24 capitalize text-13 font-600 text-secondaryMain rounded-16',
                    generateClassStatus(),
                  )}
                >
                  {employeeTask?.submitStatus ?? ''}
                </Typography>
              </div>
            </div>
          }
          rightHeader={
            <>
              {canExportPDF && (
                <IconButton
                  color="primary"
                  className="ml-8 h-40"
                  onClick={handleExportPDF}
                >
                  <ExportPDFIcon />
                </IconButton>
              )}
            </>
          }
        />
      }
      content={
        <div className={clsx('w-full h-full p-16 sm:p-24 bg-paper', !canExportPDF && 'print:hidden')}>
          <FormProvider {...form}>
            <HistoryForm
              printRef={printRef}
              isAudit={(employeeTask as any)?.audit}
            />
          </FormProvider>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default Edit;
