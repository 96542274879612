import queryClient from 'app/configs/query-client';
import { TResponsePagination, TTemplate, TTemplateBasic } from 'models';

import { useAction, useCreate, useGetList, useGetOne, useUpdate } from './request';

const templateURL = '/api/admin/templates';

export const templateKeys = {
  all: ['templates'] as const,
  lists: () => [...templateKeys.all, 'list'] as const,
  list: (filters: any) => [...templateKeys.lists(), { filters }] as const,
  details: () => [...templateKeys.all, 'detail'] as const,
  detail: (id: number) => [...templateKeys.details(), id] as const,
};

export function useGetTemplates(filters: any) {
  return useGetList<TResponsePagination<TTemplateBasic>>([...templateKeys.list(filters)], `${templateURL}`, filters, {
    refetchOnMount: true,
  });
}

export function useGetTemplate(templateId: number) {
  return useGetOne<TTemplate>(
    [...templateKeys.detail(templateId)],
    `${templateURL}/${templateId}`,
    {},
    {
      enabled: !!templateId,
    },
  );
}

export function useCreateTemplate() {
  return useCreate<{ template: Partial<TTemplate> }, TTemplate>(`${templateURL}`, {
    onSuccess: () => queryClient.invalidateQueries(templateKeys.all),
  });
}

export function useUpdateTemplate(templateId: number) {
  return useUpdate<{ template: Partial<TTemplate> }, TTemplate>(`${templateURL}/${templateId}`, {
    onSuccess: () => queryClient.invalidateQueries(templateKeys.all),
  });
}

export function useDeactivateTemplate() {
  return useAction(`${templateURL}`, {
    onSuccess: () => queryClient.invalidateQueries(templateKeys.all),
  });
}

export function useActivateTemplate() {
  return useAction(`${templateURL}`, {
    onSuccess: () => queryClient.invalidateQueries(templateKeys.all),
  });
}
