import { BoxStatistic } from 'app/components/cores/statistics';
import { useGetAuditPerformance } from 'app/hooks/api/audit-performance';
import useScreen from 'app/hooks/use-screen';
import { getFormattedDateRange } from 'domains/locations/performance';
import _ from 'lodash';
import { TDateRange } from 'models';
import { clsxm } from 'utils/clsxm';

import { Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AuditLineChartContent from './line-chart-content';
import OverviewContent from './overview-content';

const useStyles = makeStyles((theme: any) => ({
  overviewList: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      '& div:first-child': {
        marginRight: '8px !important',
      },
      '& div:last-child': {
        marginLeft: '8px !important',
      },
    },
  },
  root: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row !important',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  },
  overviewContent: {
    minHeight: '40% !important',
    [theme.breakpoints.up('lg')]: {
      overflow: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '0 !important',
      marginLeft: 16,
      marginRight: 8,
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      marginTop: '16px !important',
    },
  },
}));

type Props = {
  period: string;
  customPeriod: TDateRange;
  locationId: number;
};

const LocationOverview = ({ period, customPeriod, locationId }: Props) => {
  const { from, to } = getFormattedDateRange(period, customPeriod);
  const classes = useStyles();
  const { is1280, is1440, is2560, is3840 } = useScreen();

  const { data: overview, isLoading } = useGetAuditPerformance(Number(locationId), {
    'q[from_date]': from ?? '',
    'q[to_date]': to ?? '',
  });

  const chartTitle = period !== 'custom' ? _.startCase(period) : `${from} - ${to}`;

  return (
    <div className={clsxm('flex flex-1 flex-col h-full ', classes.root)}>
      <div className={clsxm('flex justify-between space-x-16', classes.overviewList)}>
        <BoxStatistic
          title="Total Audit Lists"
          statistic={overview?.totalLists ?? 0}
          isLoading={isLoading}
          classes={{
            root: clsxm(!is1280 && 'h-64', is1280 && 'h-64', is1440 && 'h-72', is2560 && 'h-136', is3840 && 'h-200'),
            statistic: clsxm(!is1280 && 'text-16', is1280 && 'text-20', is1440 && 'text-24'),
          }}
        />
        <BoxStatistic
          title="Completed Lists"
          statistic={overview?.completedLists ?? 0}
          isLoading={isLoading}
          classes={{
            root: clsxm(!is1280 && 'h-64', is1280 && 'h-64', is1440 && 'h-72', is2560 && 'h-136', is3840 && 'h-200'),
            statistic: clsxm(!is1280 && 'text-16', is1280 && 'text-20', is1440 && 'text-24'),
          }}
        />
      </div>

      <Card className={clsxm('mt-8 rounded-8 bg-white flex-1 relative pb-8', classes.overviewContent)}>
        {!isLoading && is1440 && (
          <div className="flex absolute top-0 left-0 justify-center pt-8 pr-12 w-full">
            <Typography className="capitalize text-18 font-500">{chartTitle}</Typography>
          </div>
        )}
        <OverviewContent
          isLoading={isLoading}
          overview={overview as any}
        />
      </Card>

      <Card className={clsxm('mt-8 rounded-8 bg-white flex-1', classes.overviewContent)}>
        <AuditLineChartContent
          overview={overview}
          isLoading={isLoading}
        />
      </Card>
    </div>
  );
};

export default LocationOverview;
