import ErrorMessage from 'app/components/cores/form/error-message';
import { ComponentProps } from 'react';
import { Control, useController } from 'react-hook-form';

import DataSelect from './data-select-v2';

type Props = {
  options: { value: any; label: string }[];
  name: string;
  control?: Control<any>;
  rules?: any;
  onChange?: (value: any) => void;
} & Omit<ComponentProps<typeof DataSelect>, 'options'>;

function ControlledDataSelectV2({ options, name, control, rules, onChange: onChangeProps, ...dataSelectProps }: Props) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const errorMessage = error?.message;

  const handleChange = (newValue: any) => {
    onChange(newValue);
    onChangeProps?.(newValue);
  };

  const getValue = (_value?: string) => {
    if (!_value) return undefined;
    return options?.find((item: any) => +item.value === +_value);
  };

  return (
    <div>
      <DataSelect
        {...dataSelectProps}
        onChange={handleChange}
        value={getValue(`${value}`)}
        options={options}
      />

      <ErrorMessage message={errorMessage} />
    </div>
  );
}

export default ControlledDataSelectV2;
