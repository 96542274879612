import { SALT_OPS_FORM_TYPE } from 'constants/task';
import { TSaltOpsForm } from 'models';
import { formatDate } from 'utils/date';
import { capitalizeFirstCharacter } from 'utils/string';

const renderLabelWithPoolType = (option: TSaltOpsForm) => {
  const date = formatDate(option?.timestamp, 'DD/MM/YYYY HH:mm');
  if (!option?.poolType) return date;
  return `${capitalizeFirstCharacter(option?.poolType)} - ${date}`;
};

const renderLabelWithRefNo = (option: TSaltOpsForm) =>
  `${option?.refNo} - ${formatDate(option?.timestamp, 'DD/MM/YYYY HH:mm')}`;

// template : type - timestamp
const renderHeadcountLabel = (option: TSaltOpsForm) => {
  const date = formatDate(option?.timestamp, 'DD/MM/YYYY HH:mm');
  if (!option?.type) return date;
  return `${capitalizeFirstCharacter(option?.type)} - ${date}`;
};

// template : pool type - timestamp
const renderWaterLevelLabel = (option: TSaltOpsForm) => renderLabelWithPoolType(option);
const renderPoolTestLabel = (option: TSaltOpsForm) => renderLabelWithPoolType(option);

// template : refNo - timestamp
const renderHazardLabel = (option: TSaltOpsForm) => renderLabelWithRefNo(option);
const renderMaintenanceLabel = (option: TSaltOpsForm) => renderLabelWithRefNo(option);
const renderIncidentLabel = (option: TSaltOpsForm) => renderLabelWithRefNo(option);

export const renderSaltFormsLabelTemplate = {
  renderHeadcountLabel,
  renderWaterLevelLabel,
  renderHazardLabel,
  renderMaintenanceLabel,
  renderIncidentLabel,
  renderPoolTestLabel,
};

export const renderFormLabel = (formType: string, option: TSaltOpsForm) => {
  const mapLabel = new Map<string, string>();
  mapLabel.set(SALT_OPS_FORM_TYPE.POOLTEST, renderSaltFormsLabelTemplate.renderPoolTestLabel(option));
  mapLabel.set(SALT_OPS_FORM_TYPE.HEADCOUNT, renderSaltFormsLabelTemplate.renderHeadcountLabel(option));
  mapLabel.set(SALT_OPS_FORM_TYPE.WATER_CHEMISTRY, renderSaltFormsLabelTemplate.renderWaterLevelLabel(option));
  mapLabel.set(SALT_OPS_FORM_TYPE.HAZARD, renderSaltFormsLabelTemplate.renderHazardLabel(option));
  mapLabel.set(SALT_OPS_FORM_TYPE.MAINTENANCE, renderSaltFormsLabelTemplate.renderMaintenanceLabel(option));
  mapLabel.set(SALT_OPS_FORM_TYPE.INCIDENT, renderSaltFormsLabelTemplate.renderIncidentLabel(option));

  return mapLabel.get(formType!) || '';
};
