import _ from '@lodash';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { Chip, Tooltip } from '@mui/material';

type Variations = ({ name: string } | string)[];

type Props = {
  items: {
    id: number;
    name: string;
    [key: string]: any;
  }[];
  onMore?: (event: React.MouseEvent) => void;
  expanded?: boolean;
  variationName: string;
};

const MAX_ITEMS = 3;

function VariationChip({ items, onMore, expanded = false, variationName = '' }: Props) {
  const renderItems = expanded ? items : _.take(items, MAX_ITEMS);

  const generateVariations = (variations: Variations) => {
    if (variations.length === 0) return '';

    const joinVariants = variations.map(v => (typeof v === 'string' ? v : v.name)).join(', ');
    return `Variations: ${joinVariants}`;
  };

  return (
    <>
      {renderItems?.map(l => {
        const variations: Variations = l[variationName] ?? [];

        return (
          <Tooltip
            title={generateVariations(variations)}
            key={l.id}
          >
            <Chip
              size="small"
              label={l.name}
              className="mt-4 mr-4 cursor-pointer text-11 font-500"
              sx={{ borderColor: l?.variation ? '#F5D02F' : 'transparent', backgroundColor: '#F6F6F6' }}
              variant="outlined"
            />
          </Tooltip>
        );
      })}

      {expanded ? (
        <Tooltip
          title="Less"
          className="cursor-pointer"
          onClick={onMore}
        >
          <Chip
            key={items.length + 1}
            size="small"
            label={
              <FirstPageIcon
                fontSize="small"
                className="mt-2 text-16"
              />
            }
            className="mt-4"
            classes={{ label: 'h-full' }}
            sx={{ backgroundColor: '#F6F6F6' }}
          />
        </Tooltip>
      ) : (
        <>
          {items.length > MAX_ITEMS && (
            <Tooltip
              title="More"
              className="cursor-pointer"
              onClick={onMore}
            >
              <Chip
                key={items.length + 2}
                size="small"
                label="..."
                className="mt-4"
                classes={{ label: 'h-full' }}
                sx={{ backgroundColor: '#F6F6F6' }}
              />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}

export default VariationChip;
