import Header from 'app/components/Layout/headers/blank-header-v2';
import AuditList from 'app/components/histories/audit/audit-list';
import ReportTabs from 'app/components/reports/tabs';
import { useGetAuditReport } from 'app/hooks/api/manager/audit';
import { useFilter } from 'app/hooks/use-filter';
import { REPORT_TABS } from 'constants/tabs';
import _omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { getDefaultFilterFromUrl, parseFilterNumberToString } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';

export const DEFAULT_PAGINATION = { page: 1, per_page: 50 };

interface Props extends RouteComponentProps<{}> {}

function AuditIndex(props: Props) {
  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));
  const today = formatDate(new Date());

  const [auditFilters, setAuditFilters] = useFilter(
    {
      ...DEFAULT_PAGINATION,
      'q[completed_at_gteq]': today,
      'q[completed_at_lteq]': today,
    },
    true,
  );
  const parsedFilters = parseFilterNumberToString(auditFilters);
  const { data, isLoading } = useGetAuditReport(_omit(parsedFilters, 'q[completed_ateq_cont]'));
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);
  const auditActions = data?.records ?? [];

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const handleFetchData = (fetchParams: any) => {
    setAuditFilters({ ...auditFilters, ...fetchParams } as any);
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              Reports
            </Typography>
          }
        />
      }
      contentToolbar={
        <div className="flex justify-between items-center w-full">
          <ReportTabs currentTab={REPORT_TABS[3].tab} />
        </div>
      }
      content={
        <div className="flex absolute py-8 w-full h-full border-t-1">
          <AuditList
            data={auditActions}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            searchKey=""
            filters={filters}
            setFilters={setFilters}
            onFetchData={handleFetchData}
            auditFilters={auditFilters}
            setAuditFilters={setAuditFilters}
            isLoading={isLoading}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
      isLoading={false}
    />
  );
}

export default AuditIndex;
