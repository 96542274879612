import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, CircularProgress, IconButton, Modal, Typography } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  minWidth: 590,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.default',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
  boxShadow: 24,
};

type TStatus = 'draft' | 'active' | 'inactive';

type Props = {
  status: TStatus;
  open: boolean;
  isLoading?: boolean;
  onFinish: (status: TStatus) => void;
  onBack: () => void;
  onClose: () => void;
};

function SetStatusModal({ open, isLoading, onFinish, onBack, status: statusProps, onClose }: Props) {
  const [status, setStatus] = useState<TStatus>(statusProps);

  useEffect(() => {
    setStatus(statusProps);
  }, [statusProps]);

  const handleClose = () => {
    onClose?.();
  };

  const handleChangeStatus = (nextStatus: TStatus) => () => {
    setStatus(nextStatus);
  };

  const handleBack = () => {
    onBack?.();
  };

  const handleFinish = () => {
    onFinish?.(status);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Box className="flex items-start justify-between pt-12 pb-8 pl-24 pr-12 mb-16 border-b-1">
          <div className="flex-1">
            <Typography className="text-18 font-600">Set Status</Typography>
            <Typography className="text-13 text-secondaryLight">
              Please choose status for all lists that you have added
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon className="text-20 text-secondaryMain" />
          </IconButton>
        </Box>

        <Box sx={{ flex: 1, px: 3, pb: 3 }}>
          <Typography className="text-13 font-600">Choose Status</Typography>
          <Box className="mt-8 space-x-8">
            <Chip
              className="h-40 rounded-8 text-13 font-500"
              label="Pending"
              color={status === 'draft' ? 'primary' : 'default'}
              onClick={handleChangeStatus('draft')}
            />
            <Chip
              className="h-40 rounded-8 text-13 font-500"
              label="Published"
              color={status === 'active' ? 'primary' : 'default'}
              onClick={handleChangeStatus('active')}
            />
          </Box>
          <Typography className="mt-24 text-13 text-secondaryLight">
            Note: You can change status for each list later
          </Typography>
        </Box>

        <Box className="bottom-0 left-0 flex items-center justify-end w-full p-16 space-x-8 bg-white border-t-1 rounded-8">
          <Button
            disableElevation={true}
            variant="outlined"
            color="primary"
            className="text-11 font-500 w-80"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="text-11 font-500"
            onClick={handleFinish}
          >
            Finish
          </Button>
        </Box>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-25 z-99">
            <CircularProgress size={32} />
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default SetStatusModal;
