import _ from 'lodash';

function hasRole(userRoles: string[], roleName: string): boolean {
  if (!userRoles || !Array.isArray(userRoles) || userRoles.length === 0) return false;
  return userRoles.some((role: string) => role === roleName);
}

export function isAdmin(userRoles: string[]): boolean {
  return hasRole(userRoles, 'admin');
}

export function isManager(userRoles: string[]): boolean {
  return hasRole(userRoles, 'manager');
}

export function isManagerOnly(userRoles: string[]): boolean {
  return hasRole(userRoles, 'manager') && !isAdmin(userRoles);
}

export function isEmployee(userRoles: string[]): boolean {
  return hasRole(userRoles, 'employee');
}

function hasRoleFromIds(accessRoles?: string[], roleId?: string) {
  if (!accessRoles?.length || !roleId) return false;
  return !!accessRoles?.includes(roleId);
}

export function isHrisAdmin(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '1');
}

export function isHrisManager(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '3');
}

export function isHrisEmployee(accessRoles?: string[]) {
  return hasRoleFromIds(accessRoles, '2');
}

export function canAccessDashboard(accessRoles?: string[]) {
  return !!accessRoles?.length;
}

export function havePermissionToAction(
  acceptedPermissions: string | string[],
  permissions: { [key: string]: boolean },
) {
  if (!acceptedPermissions || _.isEmpty(acceptedPermissions)) return true;
  if (_.isEmpty(permissions)) return false;

  if (Array.isArray(acceptedPermissions)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const permission of acceptedPermissions) {
      if (permissions?.[permission]) return true;
    }
    return false;
  }

  if (permissions?.[acceptedPermissions]) return true;

  return false;
}
