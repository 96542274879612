import clsx from 'clsx';
import { TItem } from 'models';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  attachments?: TItem['attachmentsAttributes'];
};

const AttachmentViewer = ({ attachments }: Props) => {
  const classes = useStyles();

  if (attachments?.length === 0) {
    return null;
  }

  const files = attachments?.[0].files || [];

  return (
    <>
      {files.map(file => (
        <button
          className="w-full space-x-4 min-h-32"
          key={file.id}
          type="button"
        >
          <a
            className={clsx('w-full flex items-center justify-between', classes.link)}
            href={file.url}
            target="_blank"
            rel="noreferrer"
          >
            <Typography className="flex-1 text-left truncate text-13 font-600">{file.name}</Typography>
            <OpenInNewIcon className="text-20" />
          </a>
        </button>
      ))}
    </>
  );
};

export default AttachmentViewer;

const useStyles = makeStyles(() => ({
  link: {
    background: 'none !important',
    borderBottomWidth: '0 !important',
    textDecoration: 'underline !important',
  },
}));
