import clsx from 'clsx';
import { generateDaysFromToString } from 'domains/locations/performance';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';

type Props = {
  from: string | null;
  to: string | null;
  dateValue: Date;
  onDateValueChange: (date: Date) => void;
};

const TimeLineYear = ({ from, to, dateValue, onDateValueChange }: Props) => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(today);

  const isSameDate = (date1: Date, date2: Date) => moment(date1).isSame(date2, 'day');

  const handleSelectDate = (date: Date) => () => {
    setSelectedDate(date);
    onDateValueChange(date);
  };

  const days = generateDaysFromToString(from, to);

  useEffect(
    function updateDateValue() {
      if (dateValue) {
        setSelectedDate(dateValue);
      }
    },
    [dateValue],
  );

  // scroll to selected date
  useEffect(
    function scrollToSelectedDate() {
      setTimeout(() => {
        const selectedDateElement = document.querySelector(`.selected-day`);
        if (selectedDateElement) {
          selectedDateElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }, 500);
    },
    [from, to],
  );

  return (
    <div className="flex-col overflow-hidden overflow-y-auto">
      {days &&
        days.length > 0 &&
        days.map(date => (
          <Typography
            key={date.toString()}
            className={clsx(`px-16 py-12 mr-8 border-transparent cursor-pointer text-13 font-600 border-1`, {
              'selected-day': isSameDate(selectedDate, date),
            })}
            sx={{
              ...(isSameDate(selectedDate, date) && {
                borderRadius: '0 32px 32px 0',
                borderColor: '#00A0D4 !important',
                backgroundColor: '#F2FCFF',
              }),
            }}
            onClick={handleSelectDate(date)}
          >
            {formatDate(date.toISOString(), 'MMM DD')}
            <Typography
              component="span"
              className="ml-16 font-400 text-secondaryLight text-13"
            >
              {formatDate(date.toISOString(), 'dddd')}
            </Typography>
          </Typography>
        ))}
    </div>
  );
};

export default TimeLineYear;
