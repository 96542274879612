import OperationContainer from 'app/components/locations/operation-container';
import UpcomingEvents from 'app/components/locations/upcoming-events';
import { useGetLocation } from 'app/hooks/api/locations';

import { Box } from '@mui/material';

import Holidays from './overview/holidays';
import LocationInfo from './overview/info';
import OpeningTimes from './overview/opening-times';

type Props = {
  locationId: number;
};

const Overview = ({ locationId }: Props) => {
  const { data: location, isLoading } = useGetLocation(locationId);

  return (
    <Box className="w-full h-full bg-paper">
      <div className="grid w-full grid-cols-12 p-16 space-y-16 md:space-x-16 md:space-y-0 bg-paper">
        <div className="flex flex-col col-span-12 space-y-16 md:col-span-7 lg:col-span-7">
          {/* Info */}
          <OperationContainer title="Info">
            <LocationInfo info={location as any} />
          </OperationContainer>
          {/* End of Info */}

          {/* Opening Times */}
          <OperationContainer title="Opening Times">
            <OpeningTimes
              openingTimes={location?.openingHours}
              isLoading={isLoading}
            />
          </OperationContainer>
          {/* End of Opening Times */}

          <OperationContainer title="Upcoming Events">
            <UpcomingEvents locationId={locationId} />
          </OperationContainer>
        </div>

        <div className="h-full col-span-12 md:col-span-5 lg:col-span-5">
          <OperationContainer title="Holidays">
            <Holidays />
          </OperationContainer>
        </div>
      </div>
    </Box>
  );
};

export default Overview;
