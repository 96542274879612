import { ITEM_TYPES_MAP } from 'constants/index';
import _ from 'lodash';
import { TItem, TItemConditionalOption, TOption } from 'models';
import { DropResult } from 'react-beautiful-dnd';

export function reorderItems<T extends { order: number; _destroy?: boolean }>(items?: T[]) {
  if (!Array.isArray(items)) {
    return [];
  }

  let order = 0;

  const newItems = items.map(item => {
    if (item._destroy) return item;
    order += 1;
    return { ...item, order };
  });

  return newItems;
}

export function sortByItemOder<T extends { order: number }>(items?: T[]) {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.sort((a: T, b: T) => a.order - b.order);
}

export function getConditionalItems(items?: TItem[], currentItem?: TItem) {
  if (!Array.isArray(items) || !currentItem) {
    return [];
  }

  const presentItems = _.filter(items, (item: TItem) => !item._destroy);
  const currentItemIndex = _.findIndex(presentItems, { order: currentItem.order });

  return _.chain(presentItems)
    .filter((item: TItem, index: number) => item.type !== ITEM_TYPES_MAP.SECTION && index < currentItemIndex)
    .map((item: TItem) => ({
      ...item,
      isDisabled: !item.id,
    }))
    .value();
}

export function getYesNoConditionalOptions(defaultOptions: TItemConditionalOption[]) {
  const typeOptions = [
    { conditionalLogic: 'eq::true', conditionalLabel: 'Yes' },
    { conditionalLogic: 'eq::false', conditionalLabel: 'No' },
  ];
  return [...typeOptions, ...defaultOptions];
}

export function getMultipleChoiceConditionalOptions(
  defaultOptions: TItemConditionalOption[],
  multipleChoiceOptions?: TOption[],
) {
  const typeOptions =
    multipleChoiceOptions?.map((option: TOption) => ({
      conditionalLogic: `eq::${option.value}`,
      conditionalLabel: option.label,
    })) || [];
  return [...typeOptions, ...defaultOptions];
}

export function getRatingConditionalOptions(defaultOptions: TItemConditionalOption[], rating: number = 0) {
  if (!rating) return defaultOptions;
  const ratings = _.range(1, rating + 1);
  const typeOptions =
    ratings?.map((item: number) => ({
      conditionalLogic: `eq::${item}`,
      conditionalLabel: item,
    })) || [];
  return [...typeOptions, ...defaultOptions];
}

export function getCustomConditionalOptions(defaultOptions: TItemConditionalOption[]) {
  const typeOptions = [{ conditionalLogic: 'eq', conditionalLabel: 'Custom...' }];
  return [...typeOptions, ...defaultOptions];
}

export function getDateTimeConditionalOptions(defaultOptions: TItemConditionalOption[]) {
  return [
    {
      conditionalLogic: 'custom',
      conditionalLabel: 'Custom...',
    },
    ...defaultOptions,
  ];
}

export function getOrderItems({
  dropResult,
  fields,
  onError,
}: {
  dropResult: DropResult;
  fields: any;
  onError?: () => void;
}) {
  const { source, destination } = dropResult;
  if (!source || !destination) return {};

  const sourceIndex = source.index;
  const sourceItem = fields?.[sourceIndex];
  const sourceItemConditionalId = sourceItem?.conditionalId;
  const destinationIndex = destination.index;

  if (sourceItem?.type === ITEM_TYPES_MAP.SECTION) {
    return { sourceIndex, destinationIndex };
  }

  // drag an item without conditional added
  if (!sourceItemConditionalId) {
    // find dragged item is included in other item's additional
    const conditionalIndex = fields?.findIndex(
      (field: any) => !!sourceItem?.id && field.conditionalId === sourceItem?.id,
    );

    // not included?
    if (conditionalIndex < 0) {
      return { sourceIndex, destinationIndex };
    }

    // if drop dragged item to place with higher order
    // show error and rollback
    if (conditionalIndex <= destinationIndex) {
      onError?.();
      return {};
    }
  }

  // drag an item with conditional added
  else {
    const conditionalIndex = fields?.findIndex((field: any) => field.id === sourceItem?.conditionalId);

    if (conditionalIndex < 0) {
      return { sourceIndex, destinationIndex };
    }

    if (destinationIndex <= conditionalIndex) {
      onError?.();
      return {};
    }
  }

  return { sourceIndex, destinationIndex };
}

export function duplicateItem(item: TItem, items: TItem[]) {
  const nextItem = _.cloneDeep(item);
  nextItem.order = getNextItemOrder(items);
  return _.omit(nextItem, ['id', 'conditionalId', 'conditionalType', 'conditionalComparison']) as TItem;
}

export function getNextItemOrder(items: TItem[]) {
  return (items?.filter(item => !item._destroy)?.length || 0) + 1;
}

export function updateItemsAttributes(taskAttributes?: TItem[]) {
  if (_.isEmpty(taskAttributes)) return [];

  // reorder items
  const nextTaskAttributes = reorderItems(taskAttributes);

  // format data for task all location
  return _.map(nextTaskAttributes, (item: TItem) => ({
    ...item,
    locationIds: item.isAllLocation ? [] : item.locationIds,
    locations: undefined,
  }));
}
