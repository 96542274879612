/* eslint-disable react/no-unstable-nested-components */
import PerformanceContext, { TPerformanceContext } from 'app/components/performances/context';
import { useGetStatistics } from 'app/hooks/api/location-performance';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { EMPLOYEE_VALUE, LIST_VALUE, ROLE_VALUE, STATISTICS_TYPES } from 'constants/performance';
import { getFormattedDateRange } from 'domains/locations/performance';
import _debounce from 'lodash/debounce';
import { TDateRange, TTaskEmployeeStatistics, TTaskListStatistics, TTaskRoleStatistics } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Row } from 'react-table';
import { calcPagination } from 'utils/pagination';
import { getFilterFromURL } from 'utils/url';

import { Card, Chip, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';

import PerformanceExportingStatisticAction from './exporting/exporting-statistic-action';
import StatisticDetailsModal from './statistic-details-modal/modal';
import StatisticTable from './statistic-table-elements/table';

const useStyles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 !important',
    },
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      overflow: 'auto',
    },
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
  },
  headerButton: {
    [theme.breakpoints.down('sm')]: {
      width: 'unset !important',
      paddingLeft: '4px !important',
      paddingRight: '4px !important',
    },
  },
  statisticLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem !important',
    },
  },
  search: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  distribution: {
    paddingRight: '12px !important',
  },
  pagination: {
    [theme.breakpoints.down('lg')]: {
      backgroundColor: 'white',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
    },
  },
}));

type Props = {
  locationId: number;
  period: string;
  customPeriod: TDateRange;
  locationName?: string;
};

const LocationPerformanceList = ({ locationId, period, customPeriod, locationName }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { statsType, setStatsType } = useContext(PerformanceContext) as TPerformanceContext;
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<any>(getFilterFromURL(location?.search));
  const { from, to } = getFormattedDateRange(period, customPeriod);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [params, setParams] = useState({
    page: 1,
    per_page: 50,
  });
  const { data: statistics, isLoading } = useGetStatistics(locationId, statsType, {
    ...params,
    'q[from_date]': from,
    'q[to_date]': to,
    'q[name_cont]': debouncedSearchText,
  });
  const data = statistics?.records || [];
  const { state: isOpenDialog, setActive: openDialog, setInactive: closeDialog } = useToggle();
  const [distribution, setDistribution] = useState<number[]>([]);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [itemName, setItemName] = useState<string>('');
  const [rolesHeader, setRolesHeader] = useState<string>('');
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [parentData, setParentData] = useState<{ roles: string; employee: string }>({
    roles: '',
    employee: '',
  });

  const { pageCount } = calcPagination(statistics?.total, statistics?.perPage);

  const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFetchData = (fetchParams: any) => {
    setParams(fetchParams);
  };

  const handleClickTableRow = (row: Row<TTaskListStatistics | TTaskRoleStatistics | TTaskEmployeeStatistics>) => {
    const { completedLate, completedOnTime, incomplete, inProgress, pending } = row.original;
    const nextDistribution = [completedOnTime, completedLate, incomplete, inProgress, pending];
    setDistribution(nextDistribution);

    const rowData = row.original;

    const clickedDataMap = {
      [LIST_VALUE]: (rowData as TTaskListStatistics)?.list,
      [ROLE_VALUE]: (rowData as TTaskRoleStatistics)?.role,
      [EMPLOYEE_VALUE]: (rowData as TTaskEmployeeStatistics)?.user,
    };
    const clickedData = clickedDataMap[statsType] || {};

    setParentData(prevData => ({
      ...prevData,
      roles: (clickedData as any)?.roles || '',
    }));

    const nameMap = {
      [LIST_VALUE]: (clickedData as TTaskListStatistics['list']).name,
      [ROLE_VALUE]: (clickedData as TTaskRoleStatistics['role']).name,
      [EMPLOYEE_VALUE]: (clickedData as TTaskEmployeeStatistics['user']).fullName,
    };

    setItemName(nameMap[statsType] || '');
    setSelectedId(clickedData.id);
    if (LIST_VALUE === statsType) {
      setIsGroup((clickedData as TTaskListStatistics['list']).group);
      setRolesHeader((clickedData as TTaskListStatistics['list']).roles);
    }
    openDialog();
  };

  const handleClickCloseDialog = () => {
    setDistribution([]);
    setSelectedId(undefined);
    setItemName('');
    setRolesHeader('');
    closeDialog();
  };

  const handleStatsTypeChange = (nextStatsType: string) => {
    setStatsType(nextStatsType);
    setParentData({ roles: '', employee: '' });
  };

  // debounce search text
  useEffect(() => {
    const debounced = _debounce(() => {
      setDebouncedSearchText(searchText);
    }, 1000);

    debounced();

    return debounced.cancel;
  }, [searchText]);

  return (
    <Card className={clsx('h-full p-24 bg-white rounded-8', classes.root)}>
      <div className={clsx('flex items-center justify-between', classes.header)}>
        <div className="flex items-center">
          <Typography className={clsx('text-18 font-600 text-secondaryMain', classes.statisticLabel)}>
            Statistics
          </Typography>

          <PerformanceExportingStatisticAction
            isGettingStatistic={isLoading}
            locationId={locationId}
            statsType={statsType}
            from={from}
            to={to}
            locationName={locationName}
          />
        </div>

        <div className="space-x-8">
          {STATISTICS_TYPES.map(chip => (
            <Chip
              key={chip.value}
              label={chip.label}
              className={clsx('px-0 py-8 h-36 w-104', classes.headerButton)}
              onClick={() => handleStatsTypeChange(chip.value)}
              color={statsType === chip.value ? 'primary' : 'default'}
              sx={{ borderRadius: '32px' }}
            />
          ))}
        </div>
      </div>

      <div className={clsx('mt-16', classes.search)}>
        <Input
          placeholder="Search"
          className="flex flex-1 h-40 px-16 text-black rounded-4"
          disableUnderline={true}
          fullWidth={true}
          value={searchText || ''}
          inputProps={{
            'aria-label': 'search',
          }}
          sx={{ backgroundColor: '#F5F5F5' }}
          onChange={handleSearchText}
        />
      </div>

      <div className={clsx('mt-16', classes.tableContainer)}>
        <StatisticTable
          statsType={statsType}
          data={data as any}
          pageCount={pageCount ?? 50}
          totalDataCount={statistics?.total ?? 0}
          isLoading={isLoading}
          location={location}
          history={history}
          searchText={searchText}
          filters={filters}
          setFilters={setFilters}
          onRowClick={handleClickTableRow}
          onFetchData={handleFetchData}
          classes={{
            table: classes.table,
            pagination: classes.pagination,
            distribution: classes.distribution,
          }}
        />
      </div>

      {isOpenDialog && (
        <StatisticDetailsModal
          open={isOpenDialog}
          onClose={handleClickCloseDialog}
          distribution={distribution}
          statsType={statsType}
          selectedId={selectedId}
          from={from}
          to={to}
          modalTitle={itemName}
          rolesHeader={rolesHeader}
          isGroup={isGroup}
          locationName={locationName}
          locationId={locationId}
          parentData={parentData}
          setParentData={setParentData}
        />
      )}
    </Card>
  );
};

export default LocationPerformanceList;
