import { generateFieldName } from 'domains/task.domain';
import { TTask } from 'models';
import { useEffect } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';

import { ControlledFrequency } from './frequency/controlled-frequency';

type Props = {
  name?: string;
  methods: UseFormReturn<TTask>;
  defaultValue?: string;
};

function TaskFrequencySetting({ name, methods, defaultValue }: Props) {
  const control = methods?.control;
  const fieldName = generateFieldName('recurringRule', name) as any;

  const {
    field: { onChange },
  } = useController({
    control,
    name: fieldName,
  });

  useEffect(() => {
    if (!defaultValue) return;
    onChange(defaultValue);
  }, [defaultValue, onChange]);

  return (
    <div className="flex flex-col justify-between">
      <ControlledFrequency
        name={fieldName}
        control={control}
      />
    </div>
  );
}

export default TaskFrequencySetting;
