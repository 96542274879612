import ItemsEditor from 'app/components/cores/items-editor';
import useShowMessage from 'app/hooks/use-show-message';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { BULK_IMPORT_OPTIONS } from 'constants/index';
import { duplicateItem, getNextItemOrder, getOrderItems } from 'domains/item.domain';
import _ from 'lodash';
import { TItem, TLocation, TTask, TTaskLocation } from 'models';
import { useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BulkImport from './bulk-import';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: -4,
    width: 'calc(100% + 8px) !important',
  },
}));

type Props = {
  onSave?: (options: any, onSuccess?: () => void) => void;
};

function TaskQuestionSetting({ onSave }: Props) {
  const classes = useStyles();
  const { showError } = useShowMessage();
  const methods = useFormContext<TTask>();
  const control = methods?.control;
  const register = methods?.register;

  const { state: isOpenDialog, setActive: openDialog, setInactive: closeDialog } = useToggle();

  const taskStatus = useWatch({ name: 'status' });
  const isDraftTask = taskStatus === 'draft';

  const itemsAttributesName = 'draftVersion.itemsAttributes';
  const locationTasksAttributes = methods?.watch('locationTasksAttributes');

  const { fields, move, update, remove, append } = useFieldArray({
    control,
    name: itemsAttributesName,
    keyName: 'DOMId',
  });

  const locations: TLocation[] = useMemo(() => {
    return (
      locationTasksAttributes
        ?.filter((location: TTaskLocation) => !!location?.id)
        ?.map(
          (location: TTaskLocation) =>
            ({
              id: location.id,
              externalId: location.locationId,
              name: location.locationName,
            } as TLocation),
        ) || []
    );
  }, [locationTasksAttributes]);

  const handleOrderChange = (dropResult: DropResult) => {
    const { sourceIndex, destinationIndex } = getOrderItems({
      dropResult,
      fields,
      onError: () => showError('Cannot re-order items. The item should have been placed below the conditional item'),
    });
    if (sourceIndex === undefined || destinationIndex === undefined) return;
    move(sourceIndex, destinationIndex);
  };

  const handleRemove = (index: number) => {
    const existingItem = fields[index].id;
    if (existingItem) {
      update(index, { ...fields[index], _destroy: true });
      return;
    }
    remove(index);
  };

  const handleDuplicate = (index: number) => {
    const clonedItem = fields[index];
    if (!clonedItem) return;

    const nextItem = duplicateItem(clonedItem, fields);
    append(nextItem);
  };

  const getDefaultTaskItem = (itemsAttributes: TItem[]) =>
    ({
      prompt: '',
      type: '',
      order: getNextItemOrder(itemsAttributes),
      required: false,
    } as any);

  const handleAddQuestion = () => {
    const newItem = getDefaultTaskItem(fields);
    append(newItem);
  };

  const handleBulkAdd = () => {
    openDialog();
  };

  const handleAddSection = () => {
    append({ type: 'Items::Section', prompt: '', order: getNextItemOrder(fields) } as any);
  };

  const generateItem = (item: any, order: number) => {
    const typeValue: any = item[0].value;
    const type = BULK_IMPORT_OPTIONS.find(_item => _.lowerCase(_item.value) === _.lowerCase(typeValue))?.type;
    const defaultPrompt = type === 'Items::Section' ? 'Section name' : 'Task name';
    const prompt = item[1].value || defaultPrompt;
    const required = _.lowerCase(item[2].value) === 'true';
    const notApplicable = _.lowerCase(item[3].value) === 'true';
    return { type, prompt, order, required, notApplicable };
  };

  const transformData = (sheetData: any) => {
    const nextOrder = getNextItemOrder(fields);
    const result = _.chain(sheetData)
      .map((item: any, index: number) => generateItem(item, nextOrder + index))
      .value();
    return result;
  };

  const handleBulkImport = (data: any) => {
    const newItems = transformData(data);
    append(newItems as any);
    onSave?.({});
  };

  return (
    <>
      <div className={clsx('sticky top-0 z-10 flex flex-row w-full py-12 -mt-16 bg-paper sm:-mt-24', classes.root)}>
        <div className="flex items-center ">
          {!isDraftTask && (
            <Typography className="ml-24 text-11 w-512">
              <span className="font-600">Note:</span> You are working on a draft version, changes will not be applied
              until publishing
            </Typography>
          )}
        </div>
        <div className="flex items-center justify-end w-full space-x-8">
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
            onClick={handleAddQuestion}
          >
            Add Task
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
            onClick={handleAddSection}
          >
            Add Section
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
            onClick={handleBulkAdd}
          >
            Bulk Import
          </Button>
        </div>
      </div>

      <ItemsEditor
        name={itemsAttributesName}
        control={control}
        items={fields as any}
        register={register}
        locations={locations}
        onOrderChange={handleOrderChange}
        onRemove={handleRemove}
        onSave={onSave}
        onDuplicate={handleDuplicate}
      />

      <BulkImport
        open={isOpenDialog}
        onClose={closeDialog}
        onImport={handleBulkImport}
      />
    </>
  );
}

export default TaskQuestionSetting;
