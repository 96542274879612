import queryClient from 'app/configs/query-client';
import { TResponsePagination, TTag } from 'models';

import { useCreate, useGetInfiniteList, useGetList, useGetOne, useUpdate } from './request';

const tagURL = '/api/admin/tags';

export const tagKeys = {
  all: ['tags'] as const,
  lists: () => [...tagKeys.all, 'list'] as const,
  list: (filters: any) => [...tagKeys.lists(), { filters }] as const,
  details: () => [...tagKeys.all, 'detail'] as const,
  detail: (id: number) => [...tagKeys.details(), id] as const,
};

export function useGetTags(filters: any) {
  return useGetList<TResponsePagination<TTag>>(
    [...tagKeys.list(filters)],
    `${tagURL}`,
    filters,
    { refetchOnMount: true },
    true,
  );
}

export function useGetTag(tagId: number) {
  return useGetOne<TTag>([...tagKeys.detail(tagId)], `${tagURL}/${tagId}`, { enabled: !!tagId });
}

export function useCreateTag() {
  return useCreate<{ tag: Partial<TTag> }, TTag>(`${tagURL}`, {
    onSuccess: () => queryClient.invalidateQueries(tagKeys.all),
  });
}

export function useUpdateTag(tagId: number) {
  return useUpdate<{ tag: Partial<TTag> }, TTag>(`${tagURL}/${tagId}`, {
    onSuccess: () => queryClient.invalidateQueries(tagKeys.all),
  });
}

export function useGetInfiniteTags(filters: any) {
  const {
    data = [],
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetInfiniteList<TTag>([...tagKeys.lists(), 'infinite', { filters }], tagURL, filters);

  const tags = data.map(item => ({
    tagName: item.name,
    tagId: item.id,
  }));

  return {
    tags,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
