import { Box, Typography, useTheme } from '@mui/material';

const WEEKDAYS = [
  { value: 'monday', label: 'Mon' },
  { value: 'tuesday', label: 'Tue' },
  { value: 'wednesday', label: 'Wed' },
  { value: 'thursday', label: 'Thu' },
  { value: 'friday', label: 'Fri' },
  { value: 'saturday', label: 'Sat' },
  { value: 'sunday', label: 'Sun' },
];

type Props = {
  selectedDays?: string[];
};

const ItemWeekDays = ({ selectedDays }: Props) => {
  const theme = useTheme();
  const isSelected = (day: string) => selectedDays?.includes(day);

  return (
    <div className="flex flex-row flex-wrap">
      {WEEKDAYS.map((day: any) =>
        isSelected(day.value) ? (
          <Box
            key={day.value}
            className="flex items-center justify-center w-40 h-40 mb-4 mr-4 rounded-4"
            bgcolor={theme.palette.primary.main}
          >
            <Typography className="text-white text-11 font-500">{day.label}</Typography>
          </Box>
        ) : null,
      )}
    </div>
  );
};

export default ItemWeekDays;
