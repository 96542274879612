import { motion } from 'framer-motion';

import { Card } from '@mui/material';

type Props = {
  colors: string[];
  values: number[];
};

const ProgressItem = motion(Card);

const ProgressBar = ({ colors, values }: Props) => {
  const convertValues = (arr: number[]) => {
    // value after will be the sum of all values before
    return arr.map((value, index) => {
      const previousValues = arr.slice(0, index);
      const previousValue = previousValues.reduce((acc, cur) => acc + cur, 0);
      return previousValue + value;
    });
  };

  return (
    <div className="relative w-full h-8 bg-paper">
      {convertValues(values).map((value, index) => (
        <ProgressItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          initial={{ width: 0 }}
          animate={{ width: `${value}%` }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="absolute top-0 left-0 h-full"
          sx={{ backgroundColor: colors[index], width: value, zIndex: values.length - index, boxShadow: 'none' }}
        />
      ))}
    </div>
  );
};
export default ProgressBar;
