import moment from 'moment';
import * as yup from 'yup';

export const validateAudit = (isDisabledStartDate: boolean = false) =>
  yup.object({
    description: yup.string().required('Description is required'),
    important: yup.boolean().optional().default(false),
    users: yup.object({}).optional().nullable(),
    orgRoles: yup.object({}),
    startDate: isDisabledStartDate
      ? yup.string().optional().nullable()
      : yup
          .string()
          .required('Start date is required')
          .test('is-valid', 'Start date is invalid', (value: unknown) => {
            const currentDate = new Date(value as Date);

            // if dont have value, dont check
            if (!value) return true;

            return (
              !Number.isNaN(currentDate.getTime()) &&
              moment(currentDate.toISOString()).isSameOrAfter(moment().startOf('day'))
            );
          }),
    startTime: isDisabledStartDate
      ? yup.string().optional().nullable()
      : yup.string().required('Start time is required'),
    endDate: yup.string().test('is-valid', 'End date is invalid', (value: unknown, context: any) => {
      // if dont have value, dont check
      if (!value) return true;

      // check if end date is after start date
      const startDate = moment(context.parent.startDate, 'YYYY/MM/DD');
      const endDate = moment(value as string, 'YYYY/MM/DD');
      return endDate.isAfter(startDate);
    }),
    endTime: yup.string().test('is-valid', 'End time is invalid', (value: unknown, context: any) => {
      const currentDate = new Date();
      const convertedTime = moment(value as string, 'h:mm A').format('HH:mm');
      const [hours, minutes] = convertedTime.split(':');
      currentDate.setHours(Number(hours), Number(minutes), 0, 0);
      const parentValue = context.parent;
      const { endDate } = parentValue;
      if (endDate) return !Number.isNaN(currentDate.getTime());
      return true;
    }),
  });
