import { SHOW_NAVBAR_SWITCH } from 'constants/navbar';
import { DEFAULT_ADMIN_PATH, DEFAULT_EMPLOYEE_PATH, DEFAULT_MANAGER_PATH } from 'constants/paths';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isAdmin, isManager } from 'utils/role';

function Index() {
  const { role: roles } = useSelector(({ auth }: any) => auth.user);

  // admin
  if (isAdmin(roles)) {
    return <Redirect to={DEFAULT_ADMIN_PATH} />;
  }

  // manager
  if (isManager(roles)) {
    return <Redirect to={DEFAULT_MANAGER_PATH} />;
  }

  if (!SHOW_NAVBAR_SWITCH) {
    return <Redirect to="/403" />;
  }

  //  employee
  return <Redirect to={DEFAULT_EMPLOYEE_PATH} />;
}

export default Index;
