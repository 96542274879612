import Chip from '@mui/material/Chip';

type Props = {
  roles?: string;
};

const RolesHeader = ({ roles = '' }: Props) => {
  if (!roles) {
    return null;
  }

  const rolesToRender = roles?.split(',');

  return (
    <>
      {rolesToRender?.map(role => (
        <Chip
          key={role}
          className="text-11 font-600"
          label={role}
          sx={{ backgroundColor: '#F2FCFF', color: '#00A0D4' }}
          size="small"
        />
      ))}
    </>
  );
};
export default RolesHeader;
