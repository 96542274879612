import _isEmpty from 'lodash/isEmpty';
import { TFile } from 'models';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { Box } from '@mui/material';

type Props = {
  value?: TFile;
};

const UploadMedia = ({ value }: Props) => {
  return (
    <Box
      className="flex items-center justify-center w-full bg-gray-200 rounded-16"
      sx={{
        border: '1px dashed #7C808B',
        height: '184px',
      }}
    >
      {_isEmpty(value) ? (
        <ImageOutlinedIcon className="text-secondaryLight text-24" />
      ) : (
        <img
          className="object-cover object-center h-full"
          src={value?.url}
          alt={value?.name}
        />
      )}
    </Box>
  );
};

export default UploadMedia;
