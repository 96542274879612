import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export const baseUrl: string | undefined = process.env.REACT_APP_API_BASE;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE || '',
  headers: {
    'Content-type': 'application/json',
  },
  responseType: 'json',
});

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

export function setupInterceptorsTo(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(onRequest, onRequestError);

  instance.interceptors.response.use(onResponse, onResponseError);
  return instance;
}

export default setupInterceptorsTo(axiosInstance);
