export const RESPONSES_TYPES = [
  { type: 'Items::Area', label: 'Area' },
  { type: 'Items::BarCode', label: 'Bar Code' },
  { type: 'Items::CheckMark', label: 'Check Mark' },
  { type: 'Items::DateTime', label: 'Date Time' },
  { type: 'Items::Employee', label: 'Employee' },
  { type: 'Items::Media', label: 'Media' },
  { type: 'Items::MultipleChoice', label: 'Multiple Choice' },
  { type: 'Items::Numeric', label: 'Numeric' },
  { type: 'Items::QrCode', label: 'QR Code' },
  { type: 'Items::Rating', label: 'Rating' },
  { type: 'Items::SaltOpsForm', label: 'Salt Ops Form' },
  { type: 'Items::Signature', label: 'Signature' },
  { type: 'Items::Text', label: 'Text' },
  { type: 'Items::YesNo', label: 'Yes No' },
] as const;

export const RESPONSE_TYPE_MAP = {
  NUMERIC: 'Responses::Numeric',
  RATING: 'Responses::Rating',
  YES_NO: 'Responses::YesNo',
  BARCODE: 'Responses::BarCode',
  CHECK_MARK: 'Responses::CheckMark',
  DATE_TIME: 'Responses::DateTime',
  EMPLOYEE: 'Responses::Employee',
  MEDIA: 'Responses::Media',
  MULTIPLE_CHOICE: 'Responses::MultipleChoice',
  QR_CODE: 'Responses::QrCode',
  SALT_OPS_FORM: 'Responses::SaltOpsForm',
  SIGNATURE: 'Responses::Signature',
  TEXT: 'Responses::Text',
  AREA: 'Responses::Area',
  SECTION: 'Responses::Section',
};
