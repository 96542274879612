import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import Detail from './detail';
import Index from './index';

const TagsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: `${Paths.location}`,
      exact: true,
      component: Index,
    },
    {
      path: `${Paths.location}/:locationId`,
      exact: true,
      component: Detail,
    },
  ],
};

export default TagsPagesConfig;
