import { ITEM_TYPES_MAP } from 'constants/index';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from 'lodash';
import { TListReport, TListReportEmployeeTask, TListReportItem, TListReportLocation } from 'models';
import moment from 'moment';
import { Column, ColumnGroup } from 'react-table';
import { generateImageSize } from 'utils/image';

// check for pdf
export const isLandscapeDisplay = (columns: Column[]) => columns?.length > 8;

// check for table ui
export const isLargeScreen = (columns: ColumnGroup[]) => {
  const nextColumns = _.cloneDeep(columns);
  nextColumns?.shift();

  let totalColumns = 1;

  _.forEach(nextColumns, d => {
    totalColumns += d.columns?.length || 0;
  });

  return totalColumns > 12;
};

export const getTableOrientation = (columns: Column[], tableCount: number) => {
  if (tableCount > 1) return 'l';
  return isLandscapeDisplay(columns) ? 'l' : 'p';
};

export const getFileTitle = (listTitle: string, tableIndex: number, tableCount: number) => {
  if (tableCount > 1) return `${listTitle} - File ${tableIndex + 1}`;
  return listTitle;
};

export const getFileName = (fileTitle: string) => {
  return _.snakeCase(fileTitle);
};

export const getCellWidth = (columns: Column[], tableWidth: number, itemCellWidth: number) => {
  return (tableWidth - itemCellWidth) / (columns.length - 1);
};

export function formatData(data: TListReport) {
  return _.map(data?.items, (item: TListReportItem) => {
    const itemId = item?.id;

    const result: any = {};
    _.forEach(data?.locations, (location: TListReportLocation) => {
      _.forEach(location?.employeeTasks, (employeeTask: TListReportEmployeeTask) => {
        const nextKey = `employeeTask-${employeeTask.id}`;
        const response = _.find(employeeTask?.responses, { itemId });
        result[nextKey] = {
          response: response ? { ...response } : null,
        };
      });
    });

    return {
      item: { ...item },
      ...result,
    };
  });
}

export function formatPrintData(data: any, tableCount: number) {
  if (tableCount <= 1) return [data];

  // setup tables
  const tableData: any = [];
  for (let i = 0; i < tableCount; i += 1) {
    tableData[i] = [];
  }

  _.forEach(data, row => {
    const nextColumns = _.cloneDeep(row);
    const itemColumn = nextColumns?.shift();
    const otherColumns = _.chunk(nextColumns, 11);

    _.forEach(otherColumns, (columns, index) => {
      if (!tableData[index]) {
        tableData[index] = [];
      }
      tableData[index].push([itemColumn, ...columns]);
    });
  });

  return tableData;
}

export function formatPrintColumn(columns: any) {
  const nextColumns = _.cloneDeep(columns);

  // remove Items column
  nextColumns?.shift();

  // define default rows
  const topRow: any = [{ content: 'Items', colSpan: 1, rowSpan: 2 }];
  const bottomRow: any = [];
  const groupedByLocation = _.groupBy(nextColumns, 'parent.Header');

  // add header
  _.forEach(nextColumns, column => {
    bottomRow.push({ content: column.Header });

    const location = column.parent?.Header;
    const groupedLocation = _.find(topRow, { content: location });

    if (groupedByLocation[location] && !groupedLocation) {
      topRow.push({
        content: location,
        colSpan: groupedByLocation[location]?.length || 1,
      });
    }
  });

  return [topRow, bottomRow];
}

export function chunkColumns(columns: Column[]) {
  const nextColumns = _.cloneDeep(columns);
  const itemColumn = nextColumns?.shift();
  const otherColumns = _.chunk(nextColumns, 11);
  const tableCount = otherColumns?.length;
  const tableColumns = _.map(otherColumns, c => [itemColumn, ...c]) as any;
  return { tableCount, tableColumns };
}

export function getExportFileBlob(
  {
    columns,
    data,
    fileType,
  }: {
    columns: Column[];
    data: any;
    fileType: string;
  },
  listTitle: string,
) {
  const textColor = '#000';
  const headFillColor = '#f7f7f7';
  const defaultCellItemWidth = 140;

  if (fileType === 'pdf') {
    const { tableCount, tableColumns } = chunkColumns(columns);
    const tableData = formatPrintData(data, tableCount);

    _.forEach(tableData, (newData: any, index: number) => {
      const newColumns = tableColumns[index];
      const orientation = getTableOrientation(newColumns, tableCount);
      const doc = new JsPDF(orientation, 'pt', 'a4', true);

      const tableWidth = doc.internal.pageSize.getWidth() - 24;
      const fileTitle = getFileTitle(listTitle, index, tableCount);
      const fileName = getFileName(fileTitle);
      const cellWidth = getCellWidth(newColumns, tableWidth, defaultCellItemWidth);
      const head = formatPrintColumn(newColumns);

      doc.text(fileTitle, 12, 24);
      autoTable(doc, {
        head,
        body: newData,
        styles: {
          minCellHeight: 22,
          valign: 'middle',
          halign: 'center',
          lineWidth: 0.1,
          lineColor: '#ddd',
          cellPadding: 2,
          cellWidth,
        },
        headStyles: {
          fontStyle: 'bold',
          fontSize: 10,
          fillColor: headFillColor,
          textColor,
        },
        bodyStyles: {
          fontStyle: 'normal',
          fontSize: 10,
          textColor,
        },
        tableWidth,
        margin: { left: 12 },
        didParseCell: (d: any) => {
          if (d.cell.section === 'head') {
            if (d.column.index === 0) {
              d.cell.styles.cellWidth = defaultCellItemWidth;
            }
          }

          if (d.cell.section === 'body') {
            d.cell.styles.fillColor = '#fff';

            if (d.column.index === 0) {
              d.cell.text = d.cell.raw?.prompt;
              d.cell.styles.halign = 'left';
              d.cell.styles.textColor = textColor;
              d.cell.styles.fillColor = headFillColor;
              d.cell.styles.cellPadding = 4;
              d.cell.styles.cellWidth = defaultCellItemWidth;
            } else {
              const firstCell = d.row.raw?.[0];
              const type = firstCell?.type;
              const answer = d.cell.raw?.response?.answer;
              d.cell.text = generateAnswer(type, answer);

              if ([ITEM_TYPES_MAP.MEDIA, ITEM_TYPES_MAP.SIGNATURE].includes(type)) {
                d.cell.styles.minCellHeight = 64;
              }
            }
          }
        },
        didDrawCell: (d: any) => {
          if (d.column.index !== 0 && d.cell.section === 'body') {
            const firstCell = d.row.raw?.[0];
            const type = firstCell?.type;
            const answer = d.cell.raw?.response?.answer;

            if (answer && [ITEM_TYPES_MAP.MEDIA, ITEM_TYPES_MAP.SIGNATURE].includes(type)) {
              if (answer === 'Not Applicable') {
                return;
              }

              const tableCellWidth = d.cell.width;
              const tableCellHeight = d.cell.height;
              const tableCellX = d.cell.x;
              const tableCellY = d.cell.y;
              const responseId = d.cell.raw?.response?.id;
              const imageData =
                type === ITEM_TYPES_MAP.MEDIA ? mediaResponseCache.getResponseBase64(responseId) : answer;
              const defaultWidth = 96;
              const { width: imageWidth, height: imageHeight } = generateImageSize(
                tableCellWidth > defaultWidth ? defaultWidth : tableCellWidth,
                '16:9',
              );

              if (!imageData) return;

              const marginLeft = (tableCellWidth - imageWidth) / 2;
              const marginTop = (tableCellHeight - imageHeight) / 2;
              const x = tableCellX + marginLeft;
              const y = tableCellY + marginTop;

              doc.addImage(imageData, 'JPEG', x, y, imageWidth, imageHeight, undefined, 'FAST');
            }
          }
        },
      });
      doc.save(`${fileName}.pdf`);
    });

    return false;
  }

  // Other formats goes here
  return false;
}

export function generateAnswer(type: string, answer: string) {
  if (!type || (!answer && typeof answer !== 'number')) return '-';

  if (answer === 'Not Applicable') {
    return answer;
  }

  if (type === ITEM_TYPES_MAP.DATE_TIME) {
    return moment(answer, 'DD-MM-YYYY HH:mm:z').format('HH:mm DD/MM/YYYY');
  }

  if ([ITEM_TYPES_MAP.MEDIA, ITEM_TYPES_MAP.SIGNATURE].includes(type as any)) {
    return '';
  }

  return `${answer}`;
}

export const mediaResponseCache = (() => {
  const responseBase64: { [key: string]: string } = {};

  const setResponseBase64 = (responseId: number, base64: string) => {
    responseBase64[responseId] = base64;
  };

  const getResponseBase64 = (responseId: number) => {
    return responseBase64[responseId];
  };

  return {
    setResponseBase64,
    getResponseBase64,
  };
})();
