import ErrorMessage from 'app/components/cores/form/error-message';
import { SALT_OPS_FORM_TYPE } from 'constants/task';
import { Control, useController } from 'react-hook-form';

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

type Props = {
  control?: Control;
  name: string;
};

const SaltOpsFormOptions = ({ name, control }: Props) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: `${name}`,
  });

  const errorMessage = (error as any)?.formType?.message;

  const SALT_OPS_OPTIONS = [
    { label: 'Hazard', value: SALT_OPS_FORM_TYPE.HAZARD },
    { label: 'Headcount', value: SALT_OPS_FORM_TYPE.HEADCOUNT },
    { label: 'Incident', value: SALT_OPS_FORM_TYPE.INCIDENT },
    { label: 'Maintenance', value: SALT_OPS_FORM_TYPE.MAINTENANCE },
    { label: 'Water Chemistry', value: SALT_OPS_FORM_TYPE.WATER_CHEMISTRY },
    { label: 'Pool Test', value: SALT_OPS_FORM_TYPE.POOLTEST },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, formType: (event.target as HTMLInputElement).value });
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="text-12 font-600">Salt Operation Options</Typography>

      <div>
        <FormControl className="w-full">
          <RadioGroup
            value={value?.formType || ''}
            onChange={handleChange}
          >
            {SALT_OPS_OPTIONS.map(option => (
              <FormControlLabel
                key={option?.value}
                value={option?.value}
                control={
                  <Radio
                    size="small"
                    className="ml-auto text-11"
                  />
                }
                label={option.label}
                labelPlacement="start"
                classes={{
                  label: 'text-11 font-600',
                  root: 'mx-0',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <ErrorMessage message={errorMessage} />
      </div>
    </div>
  );
};

export default SaltOpsFormOptions;
