import PreviewBox from 'app/components/cores/preview/preview-box';
import PreviewElement from 'app/components/cores/preview/preview-element';
import PreviewHeader from 'app/components/cores/preview/preview-header';
import PreviewHeaderTitle from 'app/components/cores/preview/preview-header-title';
import { useGetTask } from 'app/hooks/api/tasks';
import clsx from 'clsx';
import { capitalizeFirstCharacter, convertDateStringToString } from 'utils/string';

import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    content: {
      width: 312,
      overflow: 'auto',
      borderLeft: '1px solid',
      borderColor: '#BDBDBD',
      height: '100%',
    },
    icon: {
      fontSize: '1.8rem',
      color: 'white',
    },
    redButton: {
      background: red[400],
      borderRadius: 3,
      border: 0,
      color: 'white',
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    chip: { backgroundColor: theme.palette.primary.main, color: 'white' },
    chipDark: { backgroundColor: theme.palette.primary.dark, color: 'white' },
  };
});

type Props = {
  taskId: number;
  onEditClick: (id: number) => void;
  onCloseClick: () => void;
};

function TaskPreview({ taskId, onEditClick, onCloseClick }: Props) {
  const theme: any = useTheme();
  const classes = useStyles();

  const { data: task, isLoading } = useGetTask(taskId);

  /**
   * If object user is empty or preview component is loading data,
   * we will return loading state
   */

  if (isLoading) {
    return (
      <div
        className={clsx(classes.content)}
        role="presentation"
      >
        <PreviewHeader>
          <></>
        </PreviewHeader>
        <div className="flex flex-col justify-end h-256">
          <CircularProgress className="ml-136" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.content)}
      role="presentation"
    >
      <div className="flex flex-col w-full">
        <PreviewHeader>
          <div className="relative">
            <div className="flex flex-row justify-end mb-8 mr-24">
              <IconButton
                aria-label="Close"
                className="px-8"
                onClick={onCloseClick}
              >
                <Icon className={clsx(classes.icon)}>close</Icon>
              </IconButton>
            </div>
            <PreviewHeaderTitle
              title={task?.name}
              description={task?.code}
            />
            <Fab
              style={{ backgroundColor: theme.palette.primary.main }}
              aria-label="Edit"
              size="medium"
              className="absolute bottom-0 ltr:left-0 rtl:right-0 mx-232 -mb-36 z-999"
              onClick={() => onEditClick(taskId)}
            >
              <Icon style={{ color: 'white' }}>edit</Icon>
            </Fab>
          </div>
        </PreviewHeader>
        <div>
          <PreviewBox title="Basic Settings">
            <div className="flex flex-col">
              <PreviewElement title="Date created">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {convertDateStringToString(task?.createdAt)}
                </Typography>
              </PreviewElement>
              <PreviewElement title="Last edited">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {convertDateStringToString(task?.updatedAt)}
                </Typography>
              </PreviewElement>

              <PreviewElement title="Status">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {capitalizeFirstCharacter(task?.status || '')}
                </Typography>
              </PreviewElement>
            </div>
          </PreviewBox>
          <PreviewBox title="Assignments">
            <>
              <PreviewElement title="Locations">
                <>
                  {task?.locationTasksAttributes?.map(l => (
                    <Typography
                      className="text-11 font-400"
                      key={l.id}
                    >
                      {l.locationName}
                    </Typography>
                  ))}
                </>
              </PreviewElement>
              <PreviewElement title="Roles">
                <>
                  {task?.globalAssignmentAttributes?.assignmentOrgRolesAttributes?.map(role => (
                    <Typography
                      className="text-11 font-400"
                      key={role.id}
                    >
                      {role.orgRoleName}
                    </Typography>
                  ))}
                </>
              </PreviewElement>
              <PreviewElement title="Variations">
                <>
                  {task?.assignmentsAttributes?.map(v => (
                    <Typography
                      className="text-11 font-400"
                      key={v.id}
                    >
                      {v.location.name}
                    </Typography>
                  ))}
                </>
              </PreviewElement>
            </>
          </PreviewBox>

          <PreviewBox title="List Tasks">
            <>
              <PreviewElement title="Current Version">
                <Typography className="text-11 font-400">{task?.currentVersion.version}</Typography>
              </PreviewElement>
              <PreviewElement title="Status">
                <Typography className="text-11 font-400">
                  {capitalizeFirstCharacter(task?.currentVersion.status || '')}
                </Typography>
              </PreviewElement>
            </>
          </PreviewBox>
        </div>
      </div>
    </div>
  );
}

export default TaskPreview;
