import ErrorMessage from 'app/components/cores/form/error-message';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';

import TextInput from './text';

type Props = {
  name: string;
  control?: Control;
  rules?: any;
  showErrorMessage?: boolean;
  type?: string;
  onBlur?: () => void;
} & TextFieldProps;

function ControlledInput({
  name,
  control,
  rules,
  showErrorMessage = true,
  type,
  onBlur: onBlurProp,
  ...textFieldProps
}: Props) {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  const watchValue = useWatch({ name, control });

  useEffect(() => {
    onChange(watchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValue]);

  const hasError = !!error?.message;

  const handleChange = (event: any) => {
    const newValue = event.target.value;

    if (!type || type !== 'number') {
      onChange(newValue);
      return;
    }

    const valueLength = newValue?.length;
    if (!newValue || (valueLength === 1 && newValue === '-')) {
      onChange(newValue);
      return;
    }

    if (!/^(?!-0$)(?!-0\d)(-?\d+(\.\d*)?)$/.test(newValue)) return;
    onChange(newValue);
  };

  const handleBlur = () => {
    onBlurProp?.();
    onBlur();
  };

  return (
    <div>
      <TextInput
        {...textFieldProps}
        ref={ref}
        InputProps={{
          ...textFieldProps.InputProps,
          classes: {
            ...textFieldProps.InputProps?.classes,
            input: clsx(textFieldProps.InputProps?.classes?.input, hasError && 'text-errorMain'),
          },
        }}
        value={value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {showErrorMessage && <ErrorMessage message={error?.message} />}
    </div>
  );
}

export default ControlledInput;
