import clsx from 'clsx';
import _ from 'lodash';
import React, { useState } from 'react';
import { UseFormReturn, useController, useFormState } from 'react-hook-form';
import { rrulestr } from 'rrule';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type Props = {
  name: string;
  index: number;
  children: React.ReactNode;
  onRemove?: (index: number) => void;
  methods: UseFormReturn<any>;
};

function FrequencyContainer({ name, methods, index, children, onRemove }: Props) {
  const control = methods?.control;
  const { errors } = useFormState({ control, name });
  const hasError = !_.isEmpty(errors);

  const isDynamicFrequency = index >= 0;

  const {
    field: { value },
  } = useController({
    control,
    name: `${name}` as const,
  });

  const { recurringRule, displayTimes } = value || {};

  const ruleText = !recurringRule ? '' : rrulestr(recurringRule).toText();

  const handleRemoveItem = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRemove?.(index);
  };

  const [isExpand, setIsExpand] = useState(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setIsExpand(isExpanded);
  };

  const hasNoDisplayTime = isDynamicFrequency ? !displayTimes || displayTimes.length === 0 : value?.length === 0;

  return (
    <Accordion
      className={clsx(
        'mb-8 bg-white border-1 shadow-0 ',
        (hasNoDisplayTime || hasError) && 'border-l-errorMain border-l-4 ',
      )}
      onChange={handleChange}
    >
      <AccordionSummary
        className="flex items-start"
        expandIcon={
          <div className={clsx(isExpand ? 'my-24' : 'my-16 pt-2')}>
            <ExpandMoreIcon />
          </div>
        }
      >
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row">
            {isDynamicFrequency ? (
              <Typography className="text-small-dark min-w-80">Frequency {index + 1}:</Typography>
            ) : (
              <Typography className="text-small-dark min-w-80">
                Frequency : <span className="ml-10">Closed Day</span>
              </Typography>
            )}
            {!isExpand && (
              <>
                {isDynamicFrequency && (
                  <Typography
                    className={clsx(
                      'ml-4 text-11 line-clamp-1 font-500 text-secondaryMain ',
                      hasNoDisplayTime && 'max-w-256',
                    )}
                  >
                    Repeat {ruleText}.
                  </Typography>
                )}
                {hasNoDisplayTime && (
                  <Typography className="ml-4 text-11 line-clamp-1 font-500 text-errorMain">
                    This Frequency has no Display Time configured
                  </Typography>
                )}
                {!hasNoDisplayTime && hasError && (
                  <Typography className="ml-4 text-11 line-clamp-1 font-500 text-errorMain">
                    This Frequency has errors
                  </Typography>
                )}
              </>
            )}
          </div>

          <div className="mr-8">
            {isDynamicFrequency ? (
              <Tooltip title={`Delete Frequency ${index + 1}`}>
                <IconButton onClick={handleRemoveItem}>
                  <DeleteOutlineIcon className="text-20" />
                </IconButton>
              </Tooltip>
            ) : (
              <div className="h-32" />
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default FrequencyContainer;
