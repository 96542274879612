import AccordionExpandTitle from 'app/components/cores/items-editor/accordion-expand-title';
import { ITEM_TYPES_MAP } from 'constants/index';
import _ from 'lodash';
import { TItemType } from 'models';
import { useEffect, useMemo } from 'react';
import { Control, useController, useFormContext, useWatch } from 'react-hook-form';

import { Accordion, AccordionDetails, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DateTimeCondition } from './display/date-time-condition/conditions';
import { MultipleCondition } from './multiple-condition';
import { SingleCondition } from './single-condition';
import TodoForm from './todo/form';

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  isExpanded?: boolean;
  onPanelChange?: (
    nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list' | 'action-item' | undefined,
  ) => void;
};

function ActionItemLogicContainer({ isDisabled, control, name, isExpanded, onPanelChange }: Props) {
  const classes = useStyles();
  const { setValue, clearErrors } = useFormContext();

  const {
    field: { value: currentItem },
    fieldState: { error },
  } = useController({ control, name });

  const conditionalActionsName = `${name}.conditionalActions`;
  const firstConditionalActionName = `${name}.conditionalActions[0]`;
  const comparisonsName = `${firstConditionalActionName}.comparisons`;
  const actionsName = `${firstConditionalActionName}.actions`;

  const conditionalActions = currentItem?.conditionalActions ?? [];
  const comparisons = conditionalActions?.[0]?.comparisons;

  const isNumericConditions = ([ITEM_TYPES_MAP.NUMERIC] as TItemType[]).includes(currentItem?.type);
  const isDateTimeConditions = ([ITEM_TYPES_MAP.DATE_TIME] as TItemType[]).includes(currentItem?.type);
  const comparisonsWatch = useWatch({ control, name: comparisonsName });
  const locationTasksAttributes = useWatch({ control, name: 'locationTasksAttributes' });
  const locationIds = useMemo(() => _.map(locationTasksAttributes, 'locationId'), [locationTasksAttributes]);

  const hasComparisons = !!comparisonsWatch?.length;
  const hasError = !!(error as any)?.conditionalActions;
  const hasConfig = !!comparisons?.length;

  const handleRemove = () => {
    setValue(conditionalActionsName, []);
    clearErrors(conditionalActionsName);
  };

  const handleRemoveAllLogic = () => {
    setValue(conditionalActionsName, []);
  };

  useEffect(() => {
    const isEmptyComparisons = !comparisons || _.isEmpty(comparisons?.[0]) || !comparisons?.[0]?.logic;

    if (isEmptyComparisons) {
      setValue(conditionalActionsName, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisons?.[0]?.logic]);

  const renderConditionComponent = () => {
    if (isNumericConditions) {
      return (
        <MultipleCondition
          control={control}
          name={comparisonsName}
          selectedConditionalItem={currentItem}
          key={comparisons?.[0]?.logic ?? 0}
          onRemoveAllLogic={handleRemoveAllLogic}
        />
      );
    }
    if (isDateTimeConditions) {
      return (
        <DateTimeCondition
          control={control}
          name={firstConditionalActionName}
          selectedConditionalItem={currentItem}
          key={comparisonsWatch?.[0]?.logic ?? 0}
        />
      );
    }
    return (
      <SingleCondition
        control={control}
        name={comparisonsName}
        selectedConditionalItem={currentItem}
        instructionText="If answer is"
        key={comparisons?.[0]?.logic ?? 0}
      />
    );
  };

  return (
    <Accordion
      className="bg-white shadow-0"
      classes={{
        disabled: classes.accordionRootDisabled,
      }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('action-item')}
      sx={{ '&:before': { display: 'none' } }}
    >
      <AccordionExpandTitle
        isExpanded={isExpanded}
        hasError={hasError}
        hasConfig={hasConfig}
        title="Add Action Item Logic (optional)"
        classes={{
          notifySummaryRoot: classes?.notifySummaryRoot,
          accordionSummaryDisabled: classes?.accordionSummaryDisabled,
        }}
      />

      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <div className="space-y-8">
          <div className="pt-8">{renderConditionComponent()}</div>

          {hasComparisons && (
            <>
              <Typography className="text-13 font-400">Trigger this to-do</Typography>
              <TodoForm
                name={actionsName}
                control={control}
                locationIds={locationIds}
              />
              <div className="flex items-center justify-end mt-8">
                <Button
                  disableElevation={true}
                  variant="outlined"
                  color="error"
                  className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              </div>
            </>
          )}
        </div>

        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default ActionItemLogicContainer;

const useStyles = makeStyles(() => {
  return {
    accordionRootDisabled: {
      backgroundColor: 'white !important',
      '& *': {
        color: '#C8CBD2',
      },
    },
    accordionSummaryDisabled: {
      opacity: '1 !important',
    },
    notifySummaryRoot: {
      boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1) !important',
      borderRadius: '16px 16px 0px 0px !important',
    },
  };
});
