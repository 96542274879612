import clsx from 'clsx';

import { Typography } from '@mui/material';

type Props = {
  answer: any;
  isUpperCaseAnswer?: boolean;
};

function ReportTableCommonCell({ answer, isUpperCaseAnswer }: Props) {
  return (
    <Typography className={clsx('text-13 font-400', isUpperCaseAnswer && 'uppercase')}>{answer || '-'}</Typography>
  );
}

export default ReportTableCommonCell;
