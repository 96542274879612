import { TIME_FORMAT } from 'constants/index';
import moment, { Moment } from 'moment';

import { TimePicker } from '@mui/x-date-pickers';

type Props = {
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  disabled?: boolean;
};

const TimeInput = ({ value, onChange, disabled }: Props) => {
  const handleChange = (momentValue: Moment | null) => {
    onChange?.(momentValue?.toDate() || null);
  };

  return (
    <TimePicker
      value={value ? moment(value, 'DD/MM/YYYY HH:mm') : null}
      onChange={handleChange}
      slotProps={{
        textField: {
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root': {
              height: '40px',
            },
          },
          placeholder: 'Set Time',
        },
      }}
      format={TIME_FORMAT}
      disabled={disabled}
      className="w-full bg-gray-200 rounded-20"
    />
  );
};

export default TimeInput;
