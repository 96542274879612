import AttributeContainer from 'app/components/lists/attribute-container';
import ClosedFrequency from 'app/components/lists/closed-frequency';
import { generateDefaultDisplayTime } from 'domains/display-time.domain';
import { generateFieldName } from 'domains/task.domain';
import { TTask } from 'models';
import { Control, UseFormReturn, useController, useFieldArray } from 'react-hook-form';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';

type Props = {
  name?: string;
  control: Control;
  methods: UseFormReturn<TTask>;
  isShowHoliday?: boolean;
  isShowBlackout?: boolean;
  isShowBreakTime?: boolean;
  isShowClosedDay?: boolean;
};

function OthersAssignment({
  name,
  control,
  methods,
  isShowHoliday = true,
  isShowBlackout = true,
  isShowBreakTime = true,
  isShowClosedDay = true,
}: Props) {
  const {
    field: { value: holidayAvailable, onChange: onHolidayChange },
  } = useController({
    name: generateFieldName('holidayAvailable', name),
    control,
  });

  const {
    field: { value: blackoutTimeEnable, onChange: onBlackoutChange },
  } = useController({
    name: generateFieldName('blackoutTimeEnable', name),
    control,
  });

  const {
    field: { value: breakTime, onChange: onBreakTimeChange },
  } = useController({
    name: generateFieldName('generateBetweenBreakTime', name),
    control,
  });

  const {
    field: { value: closedDay, onChange: onclosedDayChange },
  } = useController({
    name: generateFieldName('enableOnClosedDay', name),
    control,
  });

  const displayTimeName = generateFieldName('closedDayDisplayTimes', name);

  const { append, replace } = useFieldArray({
    control,
    name: displayTimeName,
  });

  const handleHolidayChange = (_: any, checked: boolean) => {
    onHolidayChange(checked);
  };

  const handleBlackoutChange = (_: any, checked: boolean) => {
    onBlackoutChange(checked);
  };

  const handleChangeBreakTime = (_: any, checked: boolean) => {
    onBreakTimeChange(checked);
  };

  const handleChangeClosedDay = (_: any, checked: boolean) => {
    onclosedDayChange(checked);
    if (checked) {
      append(generateDefaultDisplayTime());
    } else replace([]);
  };

  return (
    <AttributeContainer label="Others">
      <div className="flex flex-col">
        {isShowHoliday && (
          <FormControlLabel
            label="Enable List on Holidays"
            classes={{ label: 'text-12' }}
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
              />
            }
            checked={!!holidayAvailable}
            onChange={handleHolidayChange}
          />
        )}

        {isShowBlackout && (
          <FormControlLabel
            label="Enable List During Blackout Period"
            classes={{ label: 'text-12' }}
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
              />
            }
            checked={!!blackoutTimeEnable}
            onChange={handleBlackoutChange}
          />
        )}

        {isShowBreakTime && (
          <FormControlLabel
            label="Enable List on Break Time"
            classes={{ label: 'text-12' }}
            control={
              <Checkbox
                checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
              />
            }
            checked={!!breakTime}
            onChange={handleChangeBreakTime}
          />
        )}
        {isShowClosedDay && (
          <>
            <FormControlLabel
              label="Enable List on Closed Day"
              classes={{ label: 'text-12' }}
              control={
                <Checkbox
                  checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                  icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
                />
              }
              checked={!!closedDay}
              onChange={handleChangeClosedDay}
            />

            {closedDay && (
              <ClosedFrequency
                name={displayTimeName}
                methods={methods}
              />
            )}
          </>
        )}
      </div>
    </AttributeContainer>
  );
}

export default OthersAssignment;
