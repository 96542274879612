import {
  AFTER_END_OF_VALUE,
  BEFORE_END_OF_VALUE,
  CONDITIONAL_DATE_TIME_OPTIONS,
  DATE_TIME_HOUR_OPTIONS,
  DATE_TIME_TIME_TYPE_OPTIONS,
  DATE_TIME_VALUE_OPTIONS,
  IN_THE_PAST_VALUE,
  WITHIN_VALUE,
} from 'constants/index';
import { TDateTimeLogic } from 'models/date-time-condition.model';

export const getConditionalDateTimeOptionsByType = (type: string) => {
  const isTime = type === 'time';

  if (isTime) {
    return CONDITIONAL_DATE_TIME_OPTIONS.filter(option =>
      [IN_THE_PAST_VALUE, WITHIN_VALUE].includes(option.conditionalLogic),
    );
  }

  return CONDITIONAL_DATE_TIME_OPTIONS;
};

const getDefaultValue = <T>(options: T[]): T => {
  return options[0];
};

export const getValueOptionsInfoByLogic = (logic: TDateTimeLogic) => {
  if (logic === WITHIN_VALUE) {
    const options = DATE_TIME_HOUR_OPTIONS ?? [];
    return { options, defaultValue: getDefaultValue(options) };
  }

  return {
    options: DATE_TIME_VALUE_OPTIONS,
    defaultValue: getDefaultValue(DATE_TIME_VALUE_OPTIONS),
  };
};

export const getTimeTypeOptionsByLogic = (logic: TDateTimeLogic, dateTimeType?: string) => {
  if (!dateTimeType) {
    return { options: [], defaultValue: undefined };
  }

  if (dateTimeType === 'time') {
    const options = DATE_TIME_TIME_TYPE_OPTIONS.filter(val => val.id === 'hour');
    return { options, defaultValue: getDefaultValue(options) };
  }

  if (dateTimeType === 'date' || [BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE].includes(logic)) {
    const options = DATE_TIME_TIME_TYPE_OPTIONS.filter(val => val.id !== 'hour');
    return { options, defaultValue: getDefaultValue(options) };
  }

  return { options: DATE_TIME_TIME_TYPE_OPTIONS, defaultValue: getDefaultValue(DATE_TIME_TIME_TYPE_OPTIONS) };
};
