import Header from 'app/components/Layout/headers/blank-header-v2';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import TemplateForm from 'app/components/templates/form';
import { useCreateTemplate } from 'app/hooks/api/templates';
import useBack from 'app/hooks/use-back';
import useShowMessage from 'app/hooks/use-show-message';
import { Paths } from 'constants/index';
import { reorderItems } from 'domains/item.domain';
import { templateFullSchema as schema } from 'domains/template.domain';
import { TTemplate } from 'models';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

function New() {
  // Common

  const history = useHistory();
  const { showError, showSuccess } = useShowMessage();

  const { goBack } = useBack(Paths.template);

  // Display
  const [tabValue] = useState(0);
  const isLoading = false;

  // Data
  const { mutateAsync: createTemplate } = useCreateTemplate();
  const form = useForm<TTemplate>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = form;

  // Effect

  // Function
  const handleBackClick = () => {
    goBack();
  };

  const handleAddTemplate = (status?: string) => (value: Partial<TTemplate>) => {
    const itemsAttributes = reorderItems(value?.itemsAttributes);
    const newStatus = status || value?.status;
    const payload = { template: { ...value, status: newStatus, itemsAttributes } };
    createTemplate(payload)
      .then(() => {
        showSuccess('Template has been successfully created');
        history.push(Paths.template);
      })
      .catch(res => {
        res?.errors.forEach((error: any) => {
          const message = `${error?.field} ${error?.message?.[0]}`;
          form.setError(error?.field, { type: 'custom', message });
        });
        showError('Could not create a new template');
      });
  };

  const handleInvalid = async () => {
    showError('Could not update Template');
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              className="flex items-center uppercase sm:mb-12 text-secondaryMain"
              role="button"
              onClick={handleBackClick}
              color="inherit"
              variant="button"
            >
              <div className="flex flex-row">
                <Icon
                  role="button"
                  className="mt-4 text-18"
                >
                  arrow_back_ios
                </Icon>
                <div className="flex flex-col">
                  <Typography className="ml-16 text-18 font-600">Add New Template</Typography>
                </div>
              </div>
            </Typography>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleSubmit(handleAddTemplate('draft'), handleInvalid)}
                    btnTitle="Draft"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="contained"
                    onClick={handleSubmit(handleAddTemplate('published'), handleInvalid)}
                    btnTitle="Publish"
                  />
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
        >
          <Tab
            className="h-48 normal-case font-400"
            label="Template Settings"
          />
        </Tabs>
      }
      content={
        <div className="w-full h-full p-16 sm:p-24 bg-paper">
          <FormProvider {...form}>
            <TemplateForm tabIndex={tabValue} />
          </FormProvider>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default New;
