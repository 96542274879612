import { getTimePeriod } from 'domains/locations/opening-hours';
import { TOpeningTime } from 'models';

import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { Typography } from '@mui/material';

import OpeningHours from './opening-hours';

type Props = {
  item?: TOpeningTime;
};

const HolidayItemContent = ({ item }: Props) => {
  return (
    <div className="grid grid-cols-12 px-24 py-8 duration-300 hover:bg-paper">
      <div className="col-span-5 pt-10">
        {item?.publicHoliday && <Typography className="text-13 font-600">{item?.publicHoliday?.name}</Typography>}
      </div>
      <div className="col-span-3 pt-10">
        {item?.closedOnThisHoliday ? (
          <Typography className="text-11 font-500 text-secondaryMain">Close</Typography>
        ) : (
          <OpeningHours openingTimes={item?.openingTimes} />
        )}
      </div>

      <div className="col-span-2 pt-10">
        <Typography className="text-13 font-500">{item?.timePeriod ? getTimePeriod(item) : 'Whole year'}</Typography>
      </div>
      <div className="col-span-2 pt-8">
        {item?.override && (
          <HistoryOutlinedIcon
            color="primary"
            className="ml-12 text-20"
          />
        )}
      </div>
    </div>
  );
};

export default HolidayItemContent;
