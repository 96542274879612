import { SingleSelect } from 'app/components/cores/select';
import { Props as ReactSelectProps } from 'react-select';

type Props = {
  value?: { value: string; label: string };
  options: { value: string; label: string }[];
  onChange?: (value: string) => void;
  onChangeCallback?: (value: string) => void;
} & Omit<ReactSelectProps, 'onChange'>;

function DataSelectV2({ value, options, onChange, onChangeCallback, ...reactSelectProps }: Props) {
  const handleChange = (item: any) => {
    onChange?.(item?.value);
  };

  return (
    <SingleSelect
      options={options}
      {...reactSelectProps}
      onChange={handleChange}
      value={value}
    />
  );
}

export default DataSelectV2;
