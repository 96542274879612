import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import useSelect from 'app/hooks/use-select';
import clsx from 'clsx';
import { TSelectItem } from 'models';
import { useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { UseQueryResult } from '@tanstack/react-query';

const useStyles = makeStyles((theme: any) => ({
  background: { background: theme.palette.background.paper },
}));

type Props = {
  column: { options?: { label: string; value: string }[]; [key: string]: any };
  name?: string;
  keySearch?: string;
  optionValue?: string;
  placeholder?: string;
  useFetchData: (filter: any) => UseQueryResult;
};

function SearchInput({
  column: { filterValue, setFilter, id },
  name,
  keySearch = 'search',
  optionValue = 'id',
  placeholder,
  useFetchData,
}: Props) {
  const randomId = useRef(Date.now()).current;

  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(1000);

  const { data, isLoading } = useFetchData({
    page: pageIndex,
    per_page: pageSize,
    [keySearch]: searchText,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: (data as any)?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const getValue = options?.find((o: TSelectItem) => {
    if (!o) {
      return false;
    }

    const itemId = o?.[optionValue as keyof TSelectItem] as any;

    return itemId?.toString() === filterValue?.toString();
  });

  const handleClearValue = () => {
    setSearchText('');
    setFilter('_all');
  };

  const handleChange = (value: TSelectItem) => {
    if (value) {
      setFilter(value?.[optionValue as keyof TSelectItem]);
      return;
    }
    handleClearValue();
  };

  return (
    <div className={clsx(classes.background, 'w-11/12')}>
      <SelectInfiniteScroll
        isLoading={isLoading}
        defaultOptions={true}
        className="w-full"
        name={name || id}
        value={getValue}
        placeholder={placeholder}
        options={options || []}
        onChange={handleChange}
        onInputChange={handleSearch}
        getOptionValue={(option: TSelectItem) => option.id}
        getOptionLabel={(option: TSelectItem) => option.name}
        onMenuScrollToBottom={handleLoadMore}
        menuPortalTarget={document.getElementById(`menuPortalTarget-${randomId}`)}
        menuPosition="fixed"
      />
      <div id={`menuPortalTarget-${randomId}`} />
    </div>
  );
}

export default SearchInput;
