import { useSelector } from 'react-redux';

export default function useUserSetting() {
  const { canAccessDiary, permissions, accessDiaryLocationIds } = useSelector(({ auth }) => auth.user);

  // TODO update return fields...
  return {
    canAccessDiary,
    permissions,
    accessDiaryLocationIds,
  };
}
