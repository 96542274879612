/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import Action from 'app/components/cores/index-table/action';
import VariationChip from 'app/components/cores/index-table/cells/variations-chip';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import { useGetLocations } from 'app/hooks/api/locations';
import { useGetRoles } from 'app/hooks/api/roles';
import { useUniqueList } from 'app/hooks/use-unique-list';
import { Paths } from 'constants/index';
import { generateListTypeLabel } from 'domains/task.domain';
import { TTaskBasic } from 'models';
import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { capitalizeFirstCharacter } from 'utils/string';

import _ from '@lodash';
import Typography from '@mui/material/Typography';

import TemplateHeaderAction from '../templates/header-action';

const statusOptions = [
  { label: 'All', value: 'active' },
  { label: 'Draft', value: 'draft' },
  { label: 'Published', value: 'published' },
  { label: 'Inactive', value: 'inactive' },
];

const kindOptions = [
  { label: 'All', value: '_all' },
  { label: 'Recurring', value: 'recurring' },
  { label: 'Fixed', value: 'fixed' },
];

const typeOptions = [
  { label: 'All', value: '_all' },
  { label: 'Location', value: '2' },
  { label: 'Group', value: '1' },
  { label: 'Individual', value: '0' },
];

type Props = {
  tasks: TTaskBasic[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  selectedRowIds: number[];
  filters: any;
  setFilters: any;
  toggleDrawer: (open: boolean, unitId: number) => void;
  onFetchData: (params: any) => void;
  setSelectedRowIds: (ids: number[]) => void;
  onAction?: ({ taskId, action }: { taskId: number; action: string }) => void;
  onBulkAction?: (action: string, tasks: { action: string; id: number; skipReload?: boolean }[]) => void;
};

function TaskList({
  tasks,
  pageCount,
  totalDataCount,
  isLoading,
  searchKey,
  filters,
  selectedRowIds,
  setFilters,
  toggleDrawer,
  onFetchData,
  setSelectedRowIds,
  onBulkAction,
  onAction,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    list: expandedLocationIds,
    toggleList: toggleLocationList,
    checkExist: isLocationExpanded,
  } = useUniqueList<number>([]);
  const { list: expandedRoleIds, toggleList: toggleRoleList, checkExist: isRoleExpanded } = useUniqueList<number>([]);

  const handleDeactivateButtonClick = (taskId: any, taskStatus: string) => {
    return taskStatus === 'draft'
      ? onAction?.({ taskId, action: 'delete' })
      : onAction?.({ taskId, action: 'deactivate' });
  };

  const handleActivateButtonClick = (taskId: any) => {
    onAction?.({ taskId, action: 'activate' });
  };

  const handleViewButtonClick = (taskId: number) => {
    history.push(`${Paths.list}/${taskId}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: '20%',
        Cell: ({ row }: { row: Row<TTaskBasic> }) => {
          return (
            <Link
              onClick={event => {
                event.stopPropagation();
              }}
              to={`/lists/${row.original.id}`}
            >
              <Typography className="cursor-pointer font-600">{row.original?.name}</Typography>
            </Link>
          );
        },
      },
      {
        Header: 'Locations',
        accessor: 'locations',
        sortable: false,
        id: 'locations_external_id',
        width: '20%',
        Filter: (props: any) => (
          <div className="min-w-168">
            <SearchInputDropdownFilter
              name="location_ids_in"
              optionValue="externalId"
              placeholder="Select Location"
              keySearch="q[name_cont]"
              useFetchData={useGetLocations}
              {...props}
            />
          </div>
        ),
        Cell: ({ row }: { row: Row<TTaskBasic> }) => {
          const locations = row.original?.locations || [];
          const { id } = row.original;

          return (
            <VariationChip
              items={locations}
              onMore={event => {
                event.stopPropagation();
                toggleLocationList(id);
              }}
              expanded={isLocationExpanded(id)}
              variationName="variationAttributes"
            />
          );
        },
      },
      {
        Header: 'Roles',
        accessor: 'orgRoles',
        id: 'assignments_org_roles_external_id',
        width: '15%',
        Filter: (props: any) => (
          <div className="min-w-168">
            <SearchInputDropdownFilter
              name="role_ids_in"
              keySearch="q[name_cont]"
              optionValue="externalId"
              placeholder="Select Role"
              useFetchData={useGetRoles}
              {...props}
            />
          </div>
        ),
        Cell: ({ row }: { row: Row<any> }) => {
          const roles = row.original?.orgRoles || [];
          const { id } = row.original;
          return (
            <VariationChip
              items={roles}
              onMore={event => {
                event.stopPropagation();
                toggleRoleList(id);
              }}
              expanded={isRoleExpanded(id)}
              variationName="variationLocations"
            />
          );
        },
      },
      {
        Header: () => (
          <Typography className="truncate text-13 font-400 text-secondaryLight">Completion Type</Typography>
        ),
        accessor: 'kind',
        sortable: false,
        width: '12.5%',
        options: kindOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => {
          return <Typography>{capitalizeFirstCharacter(cell?.value)}</Typography>;
        },
      },
      {
        Header: 'Type',
        accessor: 'listType',
        sortable: false,
        width: '10%',
        id: 'global_assignment_list_type',
        options: typeOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => {
          return <Typography>{generateListTypeLabel(cell?.value)}</Typography>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => <Typography className="ml-16">{cell.value}</Typography>,
      },
      {
        Header: () => {
          return <div className="flex justify-end pr-32">Action</div>;
        },
        id: 'action',
        sortable: false,
        width: '10%',
        className: '',
        Cell: ({ row }: { row: Row<TTaskBasic> }) => (
          <Action
            onView={handleViewButtonClick}
            id={row.original.id}
            onActivate={handleActivateButtonClick}
            onDeactivate={(id: number) => {
              handleDeactivateButtonClick(id, row.original.status);
            }}
            status={row.original.status}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, SearchInputDropdownFilter, expandedLocationIds, expandedRoleIds],
  );

  const handleRowSelected = (ids: number[]) => {
    if (tasks.length === 0) {
      return;
    }
    setSelectedRowIds(ids);
  };

  const selectedTasks = useMemo(() => {
    return _(tasks).keyBy('id').at(selectedRowIds).value();
  }, [tasks, selectedRowIds]);

  const handleBulkAction = (action: string) => {
    const bulk = selectedTasks.map((x: TTaskBasic) => ({
      action,
      id: x.id,
      skipReload: true,
    }));
    onBulkAction?.(action, bulk);
  };

  const currentFilterStatus = filters.find((x: any) => x.id === 'status')?.value;

  const handleClickRow = (row: Row<TTaskBasic>) => {
    // eslint-disable-next-line react/prop-types
    toggleDrawer(true, row?.original?.id);
  };

  return (
    <div className="flex flex-col w-full">
      <IndexTable
        columns={columns as Column<any>[]}
        data={tasks}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        withSelection={true}
        onRowSelected={handleRowSelected}
        onRowClick={handleClickRow}
        headerActions={
          <TemplateHeaderAction
            status={currentFilterStatus}
            totalRecords={totalDataCount}
            pageRecords={tasks.length}
            selectedRecords={selectedRowIds.length}
            title="item"
            onBulkAction={handleBulkAction}
          />
        }
      />
    </div>
  );
}

export default TaskList;
