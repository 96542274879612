import IndexTable from 'app/components/cores/index-table';
import { ProgressBar } from 'app/components/cores/statistics';
import clsx from 'clsx';
import { EMPLOYEE_VALUE, LIST_VALUE, ROLE_VALUE } from 'constants/performance';
import { TASK_COLORS } from 'constants/task';
import { TBaseTaskStatistics, TTaskListStatistics } from 'models';
import { useMemo } from 'react';
import { Cell, Row } from 'react-table';
import { toFixed } from 'utils/number';

import HelpIcon from '@mui/icons-material/Help';
import { Tooltip, Typography } from '@mui/material';

import DistributionCell from './distribution-cell';
import ListCell from './list-cell';

const listAccessors = {
  [LIST_VALUE]: 'list',
  [ROLE_VALUE]: 'role',
  [EMPLOYEE_VALUE]: 'user',
};

const totalAccessors = {
  [LIST_VALUE]: 'totalTasks',
  [ROLE_VALUE]: 'totalLists',
  [EMPLOYEE_VALUE]: 'totalLists',
};

const listHeader = {
  [LIST_VALUE]: 'List',
  [ROLE_VALUE]: 'Role',
  [EMPLOYEE_VALUE]: 'Employees',
};

const totalHeader = {
  [LIST_VALUE]: 'No. Tasks',
  [ROLE_VALUE]: 'No. Lists',
  [EMPLOYEE_VALUE]: 'No. Individual Lists',
};

type Props = {
  statsType: string;
  filterTask?: string | null;
  data?: any;
  pageCount?: number;
  totalDataCount?: number;
  searchText: string;
  isLoading?: boolean;
  location?: any;
  history?: any;
  filters?: any;
  classes?: {
    table?: string;
    pagination?: string;
    distribution?: string;
  };
  tableConfig?: any;
  setFilters?: any;
  onFetchData?: any;
  onRowClick?: any;
};

function StatisticTable({
  filterTask,
  statsType,
  data,
  pageCount,
  totalDataCount,
  searchText,
  isLoading,
  location,
  history,
  filters,
  classes,
  tableConfig,
  setFilters,
  onFetchData,
  onRowClick,
}: Props) {
  const getListAccessors = () => listAccessors[statsType];
  const getTotalAccessors = () => totalAccessors[statsType];
  const getListHeader = () => listHeader[statsType];
  const getTotalHeader = () => totalHeader[statsType];

  const columns = useMemo(
    () => [
      {
        Header: getListHeader(),
        accessor: getListAccessors(),
        className: 'border-t-1 border-gray-100',
        width: '38%',
        Filter: <></>,
        sortable: true,
        id: 'name',
        // eslint-disable-next-line
        Cell: ({ cell, row }: { cell: Cell; row: Row }) => {
          const rowData = row.original;
          const cellValueMap = {
            [EMPLOYEE_VALUE]: <Typography>{cell?.value?.fullName}</Typography>,
            [ROLE_VALUE]: <Typography>{cell?.value?.name}</Typography>,
            [LIST_VALUE]: (
              <ListCell
                text={cell.value?.name}
                list={(rowData as TTaskListStatistics)?.list}
              />
            ),
          };
          return cellValueMap[statsType] || null;
        },
      },
      {
        Header: getTotalHeader(),
        accessor: getTotalAccessors(),
        className: 'border-t-1 border-gray-100',
        width: tableConfig?.total?.width || '12%',
        Filter: <></>,
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: any }) => {
          return <Typography className="text-13 font-500">{cell.value}</Typography>;
        },
      },
      {
        // eslint-disable-next-line
        Header: () => (
          <div className="flex items-center">
            <Typography className="text-13 font-400 text-secondaryLight">Completion rate</Typography>
            {!tableConfig?.completionRate?.hideHelpText && (
              <Tooltip title="Completion rate = Completed on time + Completed late">
                <HelpIcon className="ml-4 text-12 text-secondaryMain" />
              </Tooltip>
            )}
          </div>
        ),
        accessor: 'completionRate',
        className: 'capitalize border-t-1 border-gray-100',
        width: tableConfig?.completionRate?.width || '20%',
        Filter: <></>,
        id: 'completion_rate',
        sortable: true,
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<TBaseTaskStatistics> }) => {
          const rowData = row.original;
          const { completedOnTime, completedLate, completionRate } = rowData;
          const values = [completedOnTime, completedLate];

          return (
            <div className="flex items-center justify-between space-x-12">
              <Typography className="font-600 text-14 text-secondaryMain w-56">
                {completionRate ? toFixed(completionRate, 2) : 0}%
              </Typography>
              <div className="flex-1">
                <ProgressBar
                  colors={[TASK_COLORS.onTime, TASK_COLORS.late]}
                  values={values}
                />
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Distribution',
        accessor: 'distribution',
        Filter: <></>,
        className: clsx('border-t-1 border-gray-100', classes?.distribution || ''),
        // eslint-disable-next-line
        Cell: ({ row }: { row: any }) => (
          <DistributionCell
            rowData={row.original}
            filterTask={filterTask}
            circularProgressSize={tableConfig?.distribution?.circularProgressSize}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterTask, statsType, tableConfig],
  );

  return (
    <IndexTable
      columns={columns as any[]}
      isUpdateAddressUrl={false}
      classes={{ table: classes?.table || '' }}
      data={data as any}
      pageCount={pageCount ?? 50}
      totalDataCount={totalDataCount ?? 0}
      searchKey={searchText}
      loading={!!isLoading}
      location={location}
      onFetchData={onFetchData}
      history={history}
      searchText={searchText || ''}
      filters={filters}
      setFilters={setFilters}
      onRowClick={onRowClick}
      PaginationProps={{
        classes: {
          root: classes?.pagination || '',
        },
      }}
    />
  );
}

export default StatisticTable;
