import { PropsWithChildren } from 'react';

import { CircularProgress } from '@mui/material';

import AuthorTimeInfo from './author-time-info';

type Props = {
  task?: any;
  isLoading?: boolean;
  responseId?: number;
  author?: { fullName?: string };
  displayAt?: null | string;
};

const QuestionLayout = ({ children, task, isLoading, responseId, author, displayAt }: PropsWithChildren<Props>) => {
  const { user, answer, submittedAt } = task || {};

  return (
    <div
      className="flex"
      data-responseid={responseId}
    >
      <div className="flex-1 px-16 md:px-32">
        {/* Start of answer */}
        <div>{children}</div>
        {/* End of answer */}

        {/* Start of Status */}
        {answer === 'yes' ? (
          <div className="flex items-center ml-32">
            <AuthorTimeInfo
              name={user?.fullName}
              time={submittedAt}
              mode="completed"
            />
          </div>
        ) : (
          <div className="ml-32">
            <AuthorTimeInfo
              name={author?.fullName as string}
              time={displayAt as string}
              mode="added"
            />
          </div>
        )}
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center mt-0 bg-white bg-opacity-25 z-99">
          <CircularProgress
            size={24}
            sx={{
              color: 'primary.main',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionLayout;
