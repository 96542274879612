import useTaskFilter from 'app/hooks/use-task-filter';
import _ from 'lodash';
import { TUserRole } from 'models';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TextField } from '@mui/material';

import TaskSidebarRoleSelect from './role-select';

type Props = {
  searchRef?: any;
  roles?: TUserRole[];
  searchValue?: string;
  isFacility?: boolean;
  locationRoles?: TUserRole[];
  onSearch?: (search: string) => void;
  onSelectRoles?: (roles: TUserRole[]) => void;
};

function TaskSidebarFilter({
  searchRef,
  roles,
  searchValue,
  isFacility,
  locationRoles = [],
  onSearch,
  onSelectRoles,
}: Props) {
  const userRoles = useSelector(({ auth }: any) => auth.user.roles);
  const { allRoles } = useTaskFilter();
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  const allRoleOptions = useMemo(() => {
    const sortedRoles = _.orderBy(locationRoles, 'name');
    return [allRoles, ...sortedRoles];
  }, [locationRoles, allRoles]);

  const roleOptions = useMemo(() => {
    const sortedRoles = _.orderBy(userRoles, 'name');
    return [allRoles, ...sortedRoles];
  }, [userRoles, allRoles]);

  const options = isFacility ? allRoleOptions : roleOptions;

  useImperativeHandle(searchRef, () => ({
    focusInput: () => inputRef.current?.focus(),
    focusSelect: () => selectRef.current?.focus(),
  }));

  const handleSetSearchText = (text: string) => {
    onSearch?.(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounceSearch = useCallback(_.debounce(handleSetSearchText, 800), []);

  useEffect(() => {
    setSearchText(searchValue || '');
  }, [searchValue]);

  const handleSearchBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    handleDebounceSearch(value);
  };

  const handleSelectRole = (nextSelectedRoles: TUserRole[]) => {
    onSelectRoles?.(nextSelectedRoles);
  };

  const handleClearSearch = () => {
    setSearchText('');
    onSearch?.('');
  };

  return (
    <div className="pt-16 bg-white min-h-40">
      <TextField
        inputRef={inputRef}
        placeholder="Search for name"
        className="w-full"
        variant="outlined"
        InputProps={{
          className: 'w-full h-40 mb-8 rounded-8 bg-paper',
          classes: {
            root: 'pr-4',
            notchedOutline: 'border-0',
            input: 'text-13',
          },
          endAdornment: !!searchText && (
            <IconButton onClick={handleClearSearch}>
              <CloseIcon className="text-16 text-secondaryMain" />
            </IconButton>
          ),
        }}
        value={searchText || ''}
        onChange={handleSearchBoxChange}
      />
      <TaskSidebarRoleSelect
        selectRef={selectRef}
        openMenuOnFocus={true}
        values={roles}
        options={options}
        onChange={handleSelectRole}
      />
    </div>
  );
}

export default TaskSidebarFilter;
