import Toggle from 'app/components/cores/inputs/toggle/toggle';
import ScoreSettingContainer from 'app/components/cores/items-editor/score-setting-logic/container';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import {
  generateCommonDefaultScoreSettings,
  generateDateTimeDefaultScoreSettings,
  generateMultipleChoiceDefaultScoreSettings,
  generateNumericDefaultScoreSettings,
  generateRatingDefaultScoreSettings,
  generateYesNoDefaultScoreSettings,
} from 'domains/task.domain';
import _ from 'lodash';
import { TItemSoreSetting } from 'models';
import { useEffect, useRef } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';

import { Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
  isDisabled?: boolean;
  className?: string;
};

function ScoreSetting({ control, name, isDisabled, className }: Props) {
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreSettingsName = `${name}.scoreSettings`;

  const { state: isOpenSettingModal, setActive: openSettingModal, setInactive: closeSettingModal } = useToggle();
  const previousScoreSettings = useRef<TItemSoreSetting[] | null>(null);
  const canUpdatePreviousScoreSettings = useRef(true);

  const list = useWatch({ name, control });
  const listType = list?.type;
  const isShowSettingButton = [
    'Items::MultipleChoice',
    'Items::Numeric',
    'Items::Rating',
    'Items::DateTime',
    'Items::YesNo',
  ].includes(listType);

  const {
    field: { onChange: onChangeScoreEnabled },
  } = useController({ name: scoreEnabledName, control });

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({ name: scoreSettingsName, control });

  // effect to set previous score settings
  useEffect(() => {
    if (!isShowSettingButton) {
      if (!canUpdatePreviousScoreSettings.current) return;
      canUpdatePreviousScoreSettings.current = false;
      previousScoreSettings.current = scoreSettings;
    } //
    else if (isOpenSettingModal) {
      previousScoreSettings.current = scoreSettings;
    }
  }, [isShowSettingButton, isOpenSettingModal, scoreSettings]);

  // score enabled
  // true: open settings modal and update settings
  // false: clear all settings
  const handleValueChange = (nextValue: boolean) => {
    if (nextValue) {
      openSettingModal();
      setDefaultScoreSettings();
    } else {
      closeSettingModal();
      handleClearSetting();
    }
  };

  // close modal and reset to previous settings
  const handleCloseSettingModal = () => {
    closeSettingModal();
    handleResetSetting();

    // disable score settings if previous score settings is empty
    // this ensures that we don't submit empty score settings while it is enabled.
    if (_.isEmpty(previousScoreSettings.current)) {
      onChangeScoreEnabled(false);
    }
  };

  // clear all settings
  const handleClearSetting = () => {
    onChangeScoreSettings([]);
  };

  // reset to previous settings
  const handleResetSetting = () => {
    onChangeScoreSettings(previousScoreSettings.current || []);
  };

  // save settings to state
  const handleSaveScoreSetting = () => {
    closeSettingModal();
  };

  const setDefaultScoreSettings = () => {
    switch (listType) {
      case 'Items::YesNo':
        onChangeScoreSettings(generateYesNoDefaultScoreSettings());
        break;
      case 'Items::DateTime':
        onChangeScoreSettings(generateDateTimeDefaultScoreSettings());
        break;
      case 'Items::Numeric':
        onChangeScoreSettings(generateNumericDefaultScoreSettings());
        break;
      case 'Items::Rating': {
        const { minNumber, maxNumber } = list || {};
        onChangeScoreSettings(generateRatingDefaultScoreSettings(minNumber, maxNumber));
        break;
      }
      case 'Items::MultipleChoice': {
        const { multipleChoiceOptions } = list || {};
        onChangeScoreSettings(generateMultipleChoiceDefaultScoreSettings(multipleChoiceOptions));
        break;
      }
      case 'Items::Area':
      case 'Items::Employee':
      case 'Items::BarCode':
      case 'Items::QrCode':
      case 'Items::CheckMark':
      case 'Items::Media':
      case 'Items::SaltOpsForm':
      case 'Items::Signature':
      case 'Items::Text':
        onChangeScoreSettings(generateCommonDefaultScoreSettings());
        break;
      default:
        break;
    }
  };

  return (
    <div className={clsx('px-24 py-16', className)}>
      <div className="flex items-center justify-between">
        <Typography className="text-12 font-600 text-secondaryMain">Enable Score</Typography>

        <Toggle
          control={control}
          name={scoreEnabledName}
          disabled={isDisabled}
          onValueChange={handleValueChange}
        />
      </div>

      <ScoreSettingContainer
        control={control}
        name={name}
        isOpenSettingModal={isOpenSettingModal}
        onOpenSettingModal={openSettingModal}
        onClose={handleCloseSettingModal}
        onSave={handleSaveScoreSetting}
      />
    </div>
  );
}

export default ScoreSetting;
