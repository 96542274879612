export const DATE_TIME_VALUE_OPTIONS = [
  { id: '0', name: 'This' },
  { id: '1', name: 'Next' },
  { id: '2', name: 'Next 2' },
  { id: '3', name: 'Next 3' },
  { id: '4', name: 'Next 4' },
  { id: '5', name: 'Next 5' },
];

const hours = Array.from({ length: 24 }, (_, i) => i + 1);

export const DATE_TIME_HOUR_OPTIONS = hours.map(hour => ({
  id: hour.toString(),
  name: hour.toString(),
}));

export const DATE_TIME_TIME_TYPE_OPTIONS = [
  { id: 'hour', name: 'Hour(s)' },
  { id: 'day', name: 'Day(s)' },
  { id: 'week', name: 'Week(s)' },
  { id: 'month', name: 'Month(s)' },
];

export const IN_THE_PAST_VALUE = 'in_the_past';
export const BEFORE_END_OF_VALUE = 'before_end_of';
export const AFTER_END_OF_VALUE = 'after_end_of';
export const WITHIN_VALUE = 'within';

export const CONDITIONAL_DATE_TIME_OPTIONS = [
  { conditionalLogic: IN_THE_PAST_VALUE, conditionalLabel: 'In The Past' },
  { conditionalLogic: BEFORE_END_OF_VALUE, conditionalLabel: 'Before End Of' },
  { conditionalLogic: AFTER_END_OF_VALUE, conditionalLabel: 'After End Of' },
  { conditionalLogic: WITHIN_VALUE, conditionalLabel: 'Within' },
] as const;
