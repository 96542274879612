import _ from 'lodash';
import {
  TAssignment,
  TFrequencyDaily,
  TFrequencyMonthDay,
  TFrequencyMonthly,
  TFrequencySetting,
  TFrequencyWeekly,
  TFrequencyYearly,
} from 'models';
import moment from 'moment';
import { RRule, Frequency as RRuleFrequency, Weekday as RRuleWeekday } from 'rrule';

export function generateDefaultYearlyOn() {
  return {
    bymonth: [1],
    bymonthday: [1],
  };
}

export function generateDefaultYearlyOnThe() {
  return {
    by1month: 1,
    byweekday: [RRule.MO.nth(1)],
  };
}

export function generateDefaultMonthlyOn() {
  return {
    bymonthday: [1],
  };
}

export function generateDefaultMonthlyOnThe() {
  return {
    byweekday: [RRule.MO.nth(1)],
  };
}

export function isEmptyFrequencyOn(settings: TFrequencyYearly | TFrequencyMonthly) {
  if (!settings?.bymonthday) return true;
  if (_.isNumber(settings?.bymonthday)) return false;
  return _.isEmpty(settings?.bymonthday);
}

export function isEmptyFrequencyOnThe(settings: TFrequencyYearly | TFrequencyMonthly) {
  return _.isEmpty(settings?.byweekday);
}

export function generateDefaultRule(ruleString?: string) {
  const defaultRule: {
    frequency: RRuleFrequency;
    settings: TFrequencySetting;
    ruleText: string;
    ruleString: string;
    error: string;
  } = {
    frequency: RRule.YEARLY,
    settings: {
      [RRule.YEARLY]: {
        interval: 1,
        bymonth: [1],
        bymonthday: [1],
        by1month: undefined,
        byweekday: [],
      },
      [RRule.MONTHLY]: {
        interval: 1,
        bymonthday: [1],
        byweekday: [],
      },
      [RRule.WEEKLY]: {
        interval: 1,
        byweekday: [RRule.MO],
      },
      [RRule.DAILY]: {
        interval: 1,
      },
    },
    ruleText: '',
    ruleString: '',
    error: '',
  };

  try {
    if (!ruleString) {
      const { ruleText: defaultRuleText, ruleString: defaultRuleString } = formatRule(
        defaultRule.frequency,
        defaultRule.settings,
      );
      defaultRule.ruleText = defaultRuleText;
      defaultRule.ruleString = defaultRuleString;

      return defaultRule;
    }

    const rule = RRule.fromString(ruleString);
    const origOptions = rule?.origOptions;
    if (_.isUndefined(origOptions?.freq)) return defaultRule;

    const frequency = origOptions.freq as RRuleFrequency;
    const interval = origOptions.interval || 1;
    const byweekday = origOptions.byweekday as RRuleWeekday[];
    const bymonthday = origOptions.bymonthday as TFrequencyMonthDay;
    const bymonth = origOptions.bymonth as TFrequencyMonthDay;
    const isOnTheSelected = _.isNumber(bymonthday) ? !bymonthday : _.isEmpty(bymonthday);

    const generateMonthDay = (currentValue?: TFrequencyMonthDay) => {
      const defaultValue = [1];
      if (!currentValue) return defaultValue;
      if (_.isNumber(currentValue)) return [currentValue];
      if (currentValue?.length > 0) return currentValue;
      return defaultValue;
    };

    const generateMonth = (currentValue?: TFrequencyMonthDay) => {
      const defaultValue = 1;
      if (!currentValue) return defaultValue;
      if (_.isNumber(currentValue)) return currentValue;
      if (currentValue?.length > 0) return currentValue?.[0];
      return defaultValue;
    };

    // update frequency
    defaultRule.frequency = frequency;

    // update settings
    // daily
    if (frequency === RRule.DAILY) {
      defaultRule.settings[frequency] = {
        interval,
      };
    }

    // weekly
    if (frequency === RRule.WEEKLY) {
      defaultRule.settings[frequency] = {
        interval,
        byweekday,
      };
    }

    // monthly
    if (frequency === RRule.MONTHLY) {
      defaultRule.settings[frequency] = {
        interval,
        bymonthday: isOnTheSelected ? undefined : generateMonthDay(bymonthday),
        byweekday,
      };
    }

    // yearly
    if (frequency === RRule.YEARLY) {
      defaultRule.settings[frequency] = {
        interval,
        bymonth: isOnTheSelected ? undefined : generateMonthDay(bymonth),
        bymonthday: isOnTheSelected ? undefined : generateMonthDay(bymonthday),
        by1month: isOnTheSelected ? generateMonth(bymonth) : undefined,
        byweekday,
      };
    }

    const { ruleText: defaultRuleText, ruleString: defaultRuleString } = formatRule(
      defaultRule.frequency,
      defaultRule.settings,
    );
    defaultRule.ruleText = defaultRuleText;
    defaultRule.ruleString = defaultRuleString;

    return defaultRule;
  } catch (error: any) {
    const errorString = error?.toString();

    defaultRule.error = errorString;
    return defaultRule;
  }
}

export function formatRule(frequency?: RRuleFrequency, settings?: TFrequencySetting) {
  if (_.isUndefined(frequency)) {
    return {
      ruleString: '',
      ruleText: '',
    };
  }

  const frequencySettings = _.cloneDeep(settings?.[frequency as keyof TFrequencySetting]) as any;

  if (frequencySettings?.by1month) {
    frequencySettings.bymonth = frequencySettings?.by1month;
  }

  _.unset(frequencySettings, 'by1month');

  const rule = new RRule({
    freq: frequency,
    ...frequencySettings,
  });

  const ruleString = rule.toString();
  const ruleText = rule.toText();

  return { ruleString, ruleText };
}

export function formatYearlyRule(type: 'on' | 'on_the', settings: TFrequencyYearly) {
  if (type === 'on') {
    return _.pick(settings, ['interval', 'bymonth', 'bymonthday']);
  }
  return _.pick(settings, ['interval', 'by1month', 'byweekday']);
}

export function formatMonthlyRule(type: 'on' | 'on_the', settings: TFrequencyMonthly) {
  if (type === 'on') {
    return _.pick(settings, ['interval', 'bymonthday']);
  }
  return _.pick(settings, ['interval', 'byweekday']);
}

export function updateSettings(
  frequency: RRuleFrequency,
  settings: TFrequencySetting,
  frequencySettings: TFrequencyYearly | TFrequencyMonthly | TFrequencyWeekly | TFrequencyDaily,
) {
  const nextSettings = _.cloneDeep(settings);

  if (frequency === RRule.YEARLY) {
    nextSettings[RRule.YEARLY] = frequencySettings as TFrequencyYearly;
  }
  if (frequency === RRule.MONTHLY) {
    nextSettings[RRule.MONTHLY] = frequencySettings as TFrequencyMonthly;
  }
  if (frequency === RRule.WEEKLY) {
    nextSettings[RRule.WEEKLY] = frequencySettings as TFrequencyWeekly;
  }
  if (frequency === RRule.DAILY) {
    nextSettings[RRule.DAILY] = frequencySettings as TFrequencyDaily;
  }

  return nextSettings;
}

export function generateDTStartRule(date?: string) {
  const dtStart = moment(date).format('YYYYMMDDTHHmm');
  return `DTSTART:${dtStart}00Z`;
}

export const updateRecurringDTStart = (assignmentAttributes?: TAssignment) => {
  const nextAssignmentAttributes = _.cloneDeep(assignmentAttributes) || ({} as TAssignment);

  if (nextAssignmentAttributes?.frequencies?.length) {
    const dtStart = generateDTStartRule(nextAssignmentAttributes?.startDate);
    _.forEach(nextAssignmentAttributes?.frequencies, frequency => {
      const [recurringRule] = frequency?.recurringRule?.split('\n') || [];
      frequency.recurringRule = `${recurringRule}\n${dtStart}`;
    });
  }
  return nextAssignmentAttributes;
};

export function formatFrequencyRule(ruleString?: string) {
  if (!ruleString) return ruleString;

  const nextRuleString = _.cloneDeep(ruleString);
  const matchedBySetPos = nextRuleString.match(/BYSETPOS=([0-9])+;/);
  if (!matchedBySetPos) return nextRuleString;

  const hasByDay = nextRuleString.includes('BYDAY=');
  if (!hasByDay) return nextRuleString;

  const [prevBySetPos, bySetPosValue] = matchedBySetPos || [];
  if (!prevBySetPos || !bySetPosValue) return nextRuleString;

  const removedBySetPos = nextRuleString.replace(prevBySetPos, '');
  const updatedValue = removedBySetPos.replace('BYDAY=', `BYDAY=+${bySetPosValue}`);

  return updatedValue;
}
