import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { generatePaginationURLWithPage } from 'utils/url';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

const TablePaginationActions = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, isUpdateAddressUrl = true } = props;
  const pageURLParams = page + 1;
  const currentPage = window.location.pathname + window.location.search;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const goToFirstPage = location => {
    if (!isUpdateAddressUrl) return currentPage;
    return generatePaginationURLWithPage(location, 1);
  };

  const goToPrevPage = location => {
    if (!isUpdateAddressUrl) return currentPage;
    return generatePaginationURLWithPage(location, pageURLParams - 1);
  };

  const goToNextPage = location => {
    if (!isUpdateAddressUrl) return currentPage;
    return generatePaginationURLWithPage(location, pageURLParams + 1);
  };

  const goToLastPage = location => {
    if (!isUpdateAddressUrl) return currentPage;
    return generatePaginationURLWithPage(location, Math.ceil(count / rowsPerPage));
  };

  return (
    <div className="flex-shrink-0 px-12 overflow-hidden">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className="p-8"
      >
        <Link
          to={goToFirstPage}
          role="button"
          className="flex"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </Link>
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className="p-8"
      >
        <Link
          to={goToPrevPage}
          role="button"
          className="flex"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </Link>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="p-8"
      >
        <Link
          to={goToNextPage}
          role="button"
          className="flex"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Link>
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className="p-8"
      >
        <Link
          to={goToLastPage}
          role="button"
          className="flex"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </Link>
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
