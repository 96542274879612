import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyMessageIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96912 6.12989C4.10449 5.97315 4.34129 5.95582 4.49804 6.09119L12.5029 13.0045L20.5078 6.09119C20.6646 5.95582 20.9014 5.97315 21.0367 6.12989C21.1721 6.28664 21.1548 6.52344 20.998 6.65881L12.748 13.7838C12.6073 13.9054 12.3986 13.9054 12.2578 13.7838L4.00782 6.65881C3.85108 6.52344 3.83375 6.28664 3.96912 6.12989Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5465 13.2667C10.6754 13.4288 10.6484 13.6647 10.4862 13.7936L4.82374 18.2936C4.6616 18.4224 4.4257 18.3955 4.29685 18.2333C4.16799 18.0712 4.19498 17.8353 4.35712 17.7064L10.0196 13.2064C10.1818 13.0776 10.4177 13.1045 10.5465 13.2667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37793 6C4.76004 6 4.25293 6.50711 4.25293 7.125V16.875C4.25293 17.4929 4.76004 18 5.37793 18H12.7654C12.9725 18 13.1404 18.1679 13.1404 18.375C13.1404 18.5821 12.9725 18.75 12.7654 18.75H5.37793C4.34582 18.75 3.50293 17.9071 3.50293 16.875V7.125C3.50293 6.09289 4.34582 5.25 5.37793 5.25H19.6279C20.66 5.25 21.5029 6.09289 21.5029 7.125V12.9375C21.5029 13.1446 21.335 13.3125 21.1279 13.3125C20.9208 13.3125 20.7529 13.1446 20.7529 12.9375V7.125C20.7529 6.50711 20.2458 6 19.6279 6H5.37793Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3404 12.75C15.4558 12.75 13.9279 14.2778 13.9279 16.1625C13.9279 18.0472 15.4558 19.575 17.3404 19.575C19.2251 19.575 20.7529 18.0472 20.7529 16.1625C20.7529 14.2778 19.2251 12.75 17.3404 12.75ZM13.1779 16.1625C13.1779 13.8636 15.0415 12 17.3404 12C19.6393 12 21.5029 13.8636 21.5029 16.1625C21.5029 18.4614 19.6393 20.325 17.3404 20.325C15.0415 20.325 13.1779 18.4614 13.1779 16.1625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2931 15.0348C19.4395 15.1813 19.4395 15.4187 19.2931 15.5652L17.2681 17.5902C17.1971 17.6611 17.1007 17.7007 17.0003 17.7C16.8999 17.6993 16.804 17.6584 16.7341 17.5864L15.4216 16.2364C15.2772 16.0879 15.2805 15.8505 15.429 15.7061C15.5775 15.5618 15.8149 15.5651 15.9593 15.7136L17.0067 16.7909L18.7628 15.0348C18.9092 14.8884 19.1467 14.8884 19.2931 15.0348Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(MyMessageIcon);
