import ErrorMessage from 'app/components/cores/form/error-message';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { Props as RSelectProps } from 'react-select';

import SelectInfiniteScroll from './select-infinite-scroll';

type Props = {
  name: string;
  control?: Control;
  rules?: any;
  allOptionsPlaceholder?: boolean;
  existingOptions?: (adding: any, option: any) => boolean;
  onChange?: (value: any) => void;
  [key: string]: any;
} & RSelectProps;

function ControlledAttributesSelectInfiniteScroll({
  name,
  control,
  rules,
  existingOptions,
  onChange: onChangeProp,
  ...selectProps
}: Props) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = (newValues: any, actionPayload: any) => {
    const isRemove = actionPayload?.action === 'remove-value';
    const isClear = actionPayload?.action === 'clear';
    const isAdd = actionPayload?.action === 'select-option';

    if (isAdd) {
      const selectOption = actionPayload.option;

      // If existingOptions doesn't exist, optionIndex always return -1
      const optionIndex = value?.findIndex((v: any) => existingOptions?.(v, selectOption));
      if (optionIndex >= 0) {
        const cloned = _.cloneDeep(value);
        const newOption = _.omit(cloned[optionIndex], '_destroy');
        cloned[optionIndex] = newOption;
        onChange(cloned);
        onChangeProp?.(cloned);
        return;
      }
    }

    if (isRemove && actionPayload?.removedValue?.id) {
      const nextValue = _.cloneDeep(value);
      const currentItem = _.find(nextValue, { id: actionPayload?.removedValue?.id });

      if (currentItem) {
        currentItem._destroy = true;
      }

      onChange(nextValue);
      onChangeProp?.(nextValue);
      return;
    }

    if (isClear) {
      const processedValues =
        _.chain(actionPayload?.removedValues)
          .filter((item: any) => item.id)
          .map((item: any) => ({ ...item, _destroy: true }))
          .value() || [];
      onChange(processedValues);
      onChangeProp?.(processedValues);

      return;
    }

    onChange(newValues);
    onChangeProp?.(newValues);
  };

  const presentValue = useMemo(() => {
    return !value?.length ? null : value.filter((x: any) => !x._destroy);
  }, [value]);

  return (
    <div>
      <SelectInfiniteScroll
        {...selectProps}
        isMulti={true}
        value={presentValue}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <ErrorMessage message={error?.message} />
    </div>
  );
}

export default ControlledAttributesSelectInfiniteScroll;
