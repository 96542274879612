import Header from 'app/components/Layout/headers/blank-header-v2';
import ActionFilter from 'app/components/actions/filters';
import ActionList from 'app/components/actions/list';
import ActionStatistic from 'app/components/actions/statistic';
import { useGetActions } from 'app/hooks/api/actions';
import { useFilter } from 'app/hooks/use-filter';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isManagerOnly } from 'utils/role';
import { getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';

interface Props extends RouteComponentProps<{}> {}

function ActionIndex(props: Props) {
  const { role: roles } = useSelector(({ auth }: any) => auth.user);
  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));
  const isManagerAccessOnly = isManagerOnly(roles);

  const [actionFilters, setActionFilters] = useFilter<any>(
    {
      page: 1,
      per_page: 50,
    },
    true,
  );
  const { data, isLoading } = useGetActions(actionFilters);

  const actionRecords = data?.records || [];

  const managerRecords = actionRecords;

  const actions = isManagerAccessOnly ? managerRecords : actionRecords;
  const pageSize = data?.perPage || 50;
  const totalItems = isManagerAccessOnly ? actions.length : data?.total;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const searchString = 'q[name_cont]';

  const handleFetchData = (fetchParams: any) => {
    setActionFilters({ ...actionFilters, ...fetchParams } as any);
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: 'min-h-84 bg-white px-24 border-b-1',
        header: 'min-h-44 h-44 sm:h-44 sm:min-h-44',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              Actions Index
            </Typography>
          }
        />
      }
      contentToolbar={
        <div className="flex flex-row items-center justify-between w-full overflow-x-auto gap-8">
          <div className="pr-16 flex-1">
            <div className="px-8 py-4 border-1 rounded-8 w-800">
              <ActionStatistic
                locationId={actionFilters['q[location_id_eq]']}
                locationName={actionFilters.location_name}
              />
            </div>
          </div>

          <div className="h-40">
            <ActionFilter
              filters={actionFilters}
              setFilters={setActionFilters}
            />
          </div>
        </div>
      }
      content={
        <div className="absolute flex w-full h-full">
          <ActionList
            actions={actions}
            onFetchData={handleFetchData}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
            searchKey={searchString}
            filters={filters}
            setFilters={setFilters}
            actionFilters={actionFilters}
            setActionFilters={setActionFilters}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default ActionIndex;
