/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import StatusBadge from 'app/components/cores/tasks/status-badge';
import FlaggedItemsModal from 'app/components/histories/audit/flagged-items-modal/modal';
import HistoryHeaderFilter from 'app/components/histories/header-filter';
import { useGetLocations } from 'app/hooks/api/locations';
import { Paths } from 'constants/index';
import { TAuditReport } from 'models';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { formatDate } from 'utils/date';

import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography';

import ActionItemsModal from './action-items-modal/modal';

type Props = {
  data: TAuditReport[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  filters: any;
  setFilters: any;
  onFetchData: (params: any) => void;
  ignoreFilters?: string[];
  auditFilters: any;
  setAuditFilters: Dispatch<SetStateAction<any>>;
};

function AuditList({
  data,
  totalDataCount,
  isLoading,
  searchKey,
  filters,
  setFilters,
  pageCount,
  ignoreFilters,
  onFetchData,
  auditFilters,
  setAuditFilters,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const [selectedAudit, setSelectedAudit] = useState<TAuditReport | null>(null);
  const [modalType, setModalType] = useState<'actionItems' | 'flaggedItems' | null>(null);

  const statusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Completed On Time', value: 'completed_on_time' },
    { label: 'Completed Late', value: 'completed_late' },
    { label: 'Expired', value: 'expired' },
  ];

  const columns = useMemo(
    () => [
      {
        Header: 'Audit Name',
        accessor: 'name',
        id: 'task_root_name',
        sortable: false,
        width: '20%',
        Cell: ({ cell }: { cell: Cell<TAuditReport> }) => {
          return (
            <Link to={`${Paths.history}/lists/${cell.row.original.id}`}>
              <Typography className="text-13 font-600">{cell.value}</Typography>
            </Link>
          );
        },
      },
      {
        Header: 'Audit Date Time',
        accessor: 'completedAt',
        id: 'completedAt',
        sortable: false,
        width: '10%',
        Filter: () => (
          <HistoryHeaderFilter
            setHistoryFilters={setAuditFilters}
            historyFilters={auditFilters}
          />
        ),
        Cell: ({ cell }: { cell: Cell<TAuditReport> }) => {
          return <Typography className="text-13 font-500">{formatDate(cell.value, 'HH:mm - DD/MM/YYYY')}</Typography>;
        },
      },
      {
        Header: 'Location',
        accessor: 'location.name',
        sortable: false,
        id: 'location_id',
        width: '15%',
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'location_ids_in',
            optionValue: 'externalId',
            keySearch: 'q[name_cont]',
            useFetchData: useGetLocations,
            ...props,
          }),
        Cell: ({ cell }: { cell: Cell<TAuditReport> }) => {
          return <Typography className="text-13 font-500">{cell.value}</Typography>;
        },
      },
      {
        Header: 'Auditor Name',
        accessor: 'users',
        id: 'user_full_name',
        sortable: false,
        width: '15%',
        Cell: ({ cell }: { cell: Cell<TAuditReport> }) => {
          return (
            <Typography className="text-13 font-500">
              {cell.value?.map((value: { fullName: string }) => value.fullName).join(', ')}
            </Typography>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'submitStatus',
        id: 'submit_status',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TAuditReport> }) => {
          const status = cell.value;
          return <StatusBadge status={status} />;
        },
      },
      {
        Header: 'Score',
        accessor: 'score',
        id: 'score',
        sortable: true,
        width: '7.5%',
        className: 'capitalize',
        Filter: <></>,
        Cell: ({ cell, row }: { cell: Cell<TAuditReport>; row: Row<TAuditReport> }) => {
          return (
            <Typography className="text-13 font-500">
              {cell.value}/{row.original.maxScore}
            </Typography>
          );
        },
      },
      {
        Header: 'Flagged Items',
        accessor: 'flaggedItems',
        id: 'flaggedItems',
        sortable: false,
        width: '7.5%',
        className: 'capitalize',
        options: statusOptions,
        Filter: <></>,
        Cell: ({ cell, row }: { cell: Cell<TAuditReport>; row: Row<TAuditReport> }) => {
          const numberOfFlaggedItems = cell.value;

          if (numberOfFlaggedItems === 0) {
            return <Typography className="cursor-default text-13 font-500">{numberOfFlaggedItems}</Typography>;
          }

          return (
            <button
              className="flex gap-8 items-center w-full cursor-pointer hover:underline"
              type="button"
              onClick={e => {
                e.stopPropagation();
                setSelectedAudit(row.original);
                setModalType('flaggedItems');
              }}
            >
              <Typography className="text-13 font-500">{numberOfFlaggedItems}</Typography>
              <TableViewIcon className="text-16 text-secondaryLight" />
            </button>
          );
        },
      },
      {
        Header: 'Follow-up Actions',
        accessor: 'followUpActions',
        id: 'followUpActions',
        sortable: false,
        width: '15%',
        className: '',
        options: statusOptions,
        Filter: <></>,
        Cell: ({ row }: { row: Row<TAuditReport> }) => {
          const { pendingActions, completedActions } = row.original;

          if (pendingActions === 0 && completedActions === 0)
            return (
              <div className="flex flex-col cursor-default">
                <Typography className="text-left text-13 font-500">{pendingActions} pending</Typography>
                <Typography className="text-left text-13 font-500">{completedActions} resolved</Typography>
              </div>
            );

          return (
            <button
              className="flex gap-8 items-center w-full cursor-pointer hover:underline"
              type="button"
              onClick={e => {
                e.stopPropagation();
                setSelectedAudit(row.original);
                setModalType('actionItems');
              }}
            >
              <div>
                <Typography className="text-left text-13 font-500">{pendingActions} pending</Typography>
                <Typography className="text-left text-13 font-500">{completedActions} resolved</Typography>
              </div>
              <TableViewIcon className="text-16 text-secondaryLight" />
            </button>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleRowClick = (item: any) => {
    history.push(`${Paths.history}/lists/${item.original.id}`);
  };

  return (
    <div className="flex flex-col px-12 w-full">
      {modalType === 'actionItems' && (
        <ActionItemsModal
          listId={selectedAudit?.id ?? null}
          auditName={selectedAudit?.name ?? ''}
          onClose={() => setSelectedAudit(null)}
          user={selectedAudit?.user?.fullName ?? ''}
          date={selectedAudit?.completedAt ?? ''}
        />
      )}
      {modalType === 'flaggedItems' && (
        <FlaggedItemsModal
          listId={selectedAudit?.id ?? null}
          auditName={selectedAudit?.name ?? ''}
          onClose={() => setSelectedAudit(null)}
          user={selectedAudit?.user?.fullName ?? ''}
          date={selectedAudit?.completedAt ?? ''}
        />
      )}

      <IndexTable
        columns={columns as Column<any>[]}
        pageCount={pageCount}
        data={data}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        ignoreFilters={ignoreFilters}
        withSelection={false}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default AuditList;
