import { useCallback, useState } from 'react';

const useToggle = (initialState: boolean = false) => {
  const [state, setState] = useState<boolean>(initialState);

  const setToggle = useCallback((): void => setState((_state: boolean) => !_state), []);
  const setActive = useCallback((): void => setState(true), []);
  const setInactive = useCallback((): void => setState(false), []);

  return { state, setToggle, setActive, setInactive } as const;
};

export default useToggle;
