import { useUnavailableRoles } from 'app/hooks/api/locations';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { checkUnavailableRoles } from 'domains/role.domain';
import { useDispatch } from 'react-redux';

import Alert from '@mui/material/Alert';

import UnavailableRolesDialog from './unavailable-roles-dialog';

type Props = {
  locationIds: number[];
  roleIds: number[];
  locationVariations?: { id: number; name: string }[];
};

const AlertExistedRoles = ({ locationIds, roleIds, locationVariations }: Props) => {
  const dispatch = useDispatch();

  const { data: unavailableRolesByLocations } = useUnavailableRoles(locationIds, roleIds);
  const visible = checkUnavailableRoles(unavailableRolesByLocations);

  const handleOpen = () => {
    dispatch(
      openDialog({
        children: (
          <UnavailableRolesDialog
            unavailableRolesByLocations={unavailableRolesByLocations}
            locationVariations={locationVariations}
          />
        ),
        fullWidth: true,
        maxWidth: 'md',
      }),
    );
  };

  if (!visible) return null;

  return (
    <Alert
      severity="error"
      className="mx-4 underline cursor-pointer text-11"
      onClick={handleOpen}
      icon={false}
    >
      Warning: Please check here for the missing roles at the selected locations
    </Alert>
  );
};
export default AlertExistedRoles;
