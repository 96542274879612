import { SingleSelect } from 'app/components/cores/select';
import _ from 'lodash';
import { components as ReactSelectComponents } from 'react-select';

import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
  selectRef?: any;
  openMenuOnFocus?: boolean;
  values?: any;
  options?: any;
  onChange?: (nextValues: any) => void;
};

function TaskSidebarRoleSelect({ selectRef, openMenuOnFocus, values, options, onChange }: Props) {
  const theme = useTheme();

  return (
    <SingleSelect
      selectRef={selectRef}
      openMenuOnFocus={openMenuOnFocus}
      hideSelectedOptions={false}
      isMulti={true}
      value={values}
      menuPlacement="auto"
      noOptionsMessage={() => 'No roles'}
      placeholder="Select a role"
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.externalId}
      options={options}
      onChange={onChange}
      styles={{
        control: (baseStyles: any, state: any) => ({
          ...baseStyles,
          border: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 8,
          height: 40,
          minHeight: 40,
          boxShadow: 'none',
          cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: 'auto',
        }),
        menu: (baseStyles: any) => ({
          ...baseStyles,
          borderRadius: 8,
          paddingTop: 4,
          paddingBottom: 4,
          zIndex: 11,
          width: 'calc(100% - 1px)',
        }),
        valueContainer: (baseStyles: any) => ({
          ...baseStyles,
          paddingLeft: 12,
        }),
        indicatorsContainer: (baseStyles: any) => ({
          ...baseStyles,
          paddingRight: 6,
        }),
      }}
      components={{
        MultiValue,
        ClearIndicator,
      }}
    />
  );
}

export default TaskSidebarRoleSelect;

function MultiValue(props: any) {
  const values = props.selectProps?.value || [];
  const isMultiValues = values?.length > 1;
  return (
    <ReactSelectComponents.SingleValue
      {...props}
      className="hidden first:block"
    >
      <Typography className="text-13 font-400">{isMultiValues ? 'Multiple Roles' : props.children}</Typography>
    </ReactSelectComponents.SingleValue>
  );
}

function ClearIndicator(props: any) {
  const values = props.selectProps?.value || [];
  const hasAllRolesOption = !!_.find(values, (role: any) => role.externalId === null);
  return hasAllRolesOption ? null : (
    <ReactSelectComponents.ClearIndicator {...props}>
      <CloseIcon className="text-16" />
    </ReactSelectComponents.ClearIndicator>
  );
}
