import clsx from 'clsx';
import { ITEM_TYPES_MAP } from 'constants/index';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';

import BarCode from './questions/bar-code';
import DateTime from './questions/date-time';
import MultiChoice from './questions/multiple-choice';
import Numeric from './questions/numeric';
import QRCode from './questions/qr-code';
import Rating from './questions/rating';
import SaltOpsForm from './questions/salt-ops-form';

type Props = {
  control?: Control;
  name: string;
  className?: string;
};

const EmptyOptions = () => {
  return null;
};

function QuestionOptionsSwitch(props: Props) {
  const { name, control, className } = props;
  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  const mappingTypeToComponent = (_type: string) => {
    const typeMapping = new Map();

    typeMapping.set(ITEM_TYPES_MAP.NUMERIC, EmptyOptions);
    typeMapping.set(ITEM_TYPES_MAP.MULTIPLE_CHOICE, MultiChoice);
    typeMapping.set(ITEM_TYPES_MAP.RATING, Rating);
    typeMapping.set(ITEM_TYPES_MAP.SALT_OPS_FORM, SaltOpsForm);
    typeMapping.set(ITEM_TYPES_MAP.DATE_TIME, DateTime);
    typeMapping.set(ITEM_TYPES_MAP.QR_CODE, QRCode);
    typeMapping.set(ITEM_TYPES_MAP.BAR_CODE, BarCode);
    typeMapping.set(ITEM_TYPES_MAP.NUMERIC, Numeric);
    typeMapping.set(ITEM_TYPES_MAP.SECTION, null);

    return typeMapping.get(_type) || null;
  };

  const OptionsComponent = useMemo(() => mappingTypeToComponent(type), [type]);

  if (!OptionsComponent) {
    return null;
  }
  return (
    <div className={clsx('px-24', className)}>
      <OptionsComponent
        name={name}
        control={control}
      />
    </div>
  );
}

export default QuestionOptionsSwitch;
