import RadioGroup from 'app/components/cores/inputs/radio-group';
import { Control, useController } from 'react-hook-form';

type Props = {
  name: string;
  options: any;
  control: Control;
};

function ListSubmission({ name, options, control }: Props) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value === 'true';
    onChange(nextValue);
  };

  return (
    <RadioGroup
      value={!!value}
      onChange={handleChange}
      options={options}
    />
  );
}

export default ListSubmission;
