import { holidayStateOptions } from 'constants/options';
import { TIME_ZONES } from 'constants/time-zones';
import { TLocation } from 'models';

import { Chip, Grid, Typography } from '@mui/material';

const HEADERS = ['Status', 'Regions', 'State', 'Timezone'];

type Props = {
  info?: TLocation;
};

const LocationInfo = ({ info }: Props) => {
  return (
    <div className="p-16">
      {/* Header */}
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        container
      >
        {HEADERS.map(item => (
          <Grid
            key={item}
            item
            xs
          >
            <Typography className="text-secondaryLight text-13 font-600">{item}</Typography>
          </Grid>
        ))}
      </Grid>

      {/* Info */}
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        container
        className="mt-8"
      >
        <Grid
          item
          xs
        >
          <Chip
            size="small"
            label={info?.status}
            color="success"
            className="capitalize"
          />
        </Grid>
        <Grid
          item
          xs
        >
          <Typography className="text-13 font-600">
            {info?.regionsAttributes?.map(region => region.name).join(', ')}
          </Typography>
        </Grid>
        <Grid
          item
          xs
        >
          <Typography className="text-13 font-600">
            {holidayStateOptions.find(state => state.value === info?.state)?.label}
          </Typography>
        </Grid>
        <Grid
          item
          xs
        >
          <Typography className="text-13 font-600">
            {TIME_ZONES.find(timezone => timezone.value === info?.timezone)?.label}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default LocationInfo;
