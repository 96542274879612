import { useEffect, useRef, useState } from 'react';

type Params = {
  data?: any;
  isLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  currentPage?: number;
  setSearchText: (value: string) => void;
  setPageIndex: (value: number) => void;
};

function useSelect({ data, isLoading, pageIndex, pageSize = 50, currentPage, setSearchText, setPageIndex }: Params) {
  const firstLoad = useRef(false);
  const canLoadMore = useRef(true);
  const searchText = useRef('');
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (!data) return;

    if (currentPage === 1) {
      firstLoad.current = true;
      setOptions(data);
      return;
    }

    if (!canLoadMore.current) return;

    if (data?.length < pageSize) {
      canLoadMore.current = false;
    }
    if (!firstLoad.current) {
      firstLoad.current = true;
      setOptions(data);
      return;
    }
    setOptions((prevData: any) => [...prevData, ...data]);
  }, [data, currentPage, pageSize]);

  const handleSearch = (value: string, actionPayload: any) => {
    if (actionPayload.action === 'input-change' || (!!searchText.current && actionPayload.action === 'input-blur')) {
      setPageIndex(1);
      firstLoad.current = false;
      canLoadMore.current = true;
      searchText.current = value;
      setSearchText?.(value);
    }
  };

  const handleLoadMore = () => {
    if (!canLoadMore.current || isLoading) return;
    setPageIndex(pageIndex + 1);
  };

  return {
    options,
    handleSearch,
    handleLoadMore,
  };
}

export default useSelect;
