import useToggle from 'app/hooks/use-toggle';
import { ITEM_TYPES_MAP } from 'constants/index';
import { TItem, TItemType } from 'models';
import { Control, useController, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { DateTimeCondition } from './date-time-condition/conditions';
import { NotifyModal } from './modal';
import { MultipleCondition } from './multiple-condition';
import { SingleCondition } from './single-condition';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
};

export function NotifyDisplaySwitch({ name, control, selectedConditionalItem }: Props) {
  const notificationConditionalName = `${name}.notificationConditionals.0`;

  const {
    field: { onChange },
  } = useController({
    name: notificationConditionalName,
    control,
  });
  const notificationConditional = useWatch({ name: notificationConditionalName, control });
  const { state: isOpenModal, setActive: showModal, setInactive: hideModal } = useToggle(false);

  const taskItemType = selectedConditionalItem?.type;
  const isNumericCondition = ([ITEM_TYPES_MAP.NUMERIC] as TItemType[]).includes(taskItemType);
  const isDateTimeCondition = ([ITEM_TYPES_MAP.DATE_TIME] as TItemType[]).includes(taskItemType);

  const handleUpdateMessage = (overrideSubject: string, message: string) => {
    onChange({ ...notificationConditional, overrideSubject, message });
    hideModal();
  };

  const renderConditionComponent = () => {
    if (isNumericCondition) {
      return (
        <MultipleCondition
          control={control}
          name={name}
          selectedConditionalItem={selectedConditionalItem}
          onShowMessageModal={showModal}
        />
      );
    }
    if (isDateTimeCondition) {
      return (
        <DateTimeCondition
          control={control}
          name={name}
          selectedConditionalItem={selectedConditionalItem}
          key={selectedConditionalItem?.dateTimeType ?? 0}
          onShowMessageModal={showModal}
        />
      );
    }
    return (
      <SingleCondition
        key={uuidv4()}
        control={control}
        name={name}
        selectedConditionalItem={selectedConditionalItem}
        onShowMessageModal={showModal}
      />
    );
  };

  return (
    <>
      {renderConditionComponent()}

      {isOpenModal && (
        <NotifyModal
          open={true}
          name={name}
          control={control}
          defaultOverrideSubject={notificationConditional?.overrideSubject || ''}
          defaultMessage={notificationConditional?.message || ''}
          onCancel={hideModal}
          onUpdate={handleUpdateMessage}
        />
      )}
    </>
  );
}
