import { StaticDatePicker } from 'app/components/cores/date-picker/static-date-picker';
import clsx from 'clsx';
import { TAdminCalendar, TCalendarMode } from 'models';
import React, { useContext } from 'react';
import { convertDateByTimezone } from 'utils/date';

import { EventContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

import FilterContext, { TFilterContext } from './filter-context';
import CalendarHeader from './header';
import ListStatusLegend from './list-status-legend';
import { CalendarTaskCell } from './task-cell';

type Props = {
  view: TCalendarMode;
  events: any;
  calendarHeight?: string | number;
  calendarRef?: React.RefObject<any>;
  loading?: boolean;
  onViewDateChange?: (newDate: Date) => void;
  dayView?: Date;
  hasLocation?: boolean;
  isMyList?: boolean;
  [key: string]: any;
};

function CoreCalendar(props: Props) {
  const {
    events,
    calendarHeight,
    calendarRef,
    loading,
    onViewDateChange,
    dayView,
    view,
    hasLocation,
    isMyList,
    ...restProps
  } = props;

  const classes = useStyles();

  const { timezoneBySelector } = useContext(FilterContext) as TFilterContext;

  const eventsByTimezone = events.map((event: TAdminCalendar) => ({
    ...event,
    start: convertDateByTimezone(event.start, timezoneBySelector),
    end: convertDateByTimezone(event.end, timezoneBySelector),
  }));

  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <CalendarTaskCell
        eventInfo={eventInfo}
        isMyList={isMyList}
      />
    );
  };

  const renderHeader = (headerInfo: any) => {
    const date = new Date(headerInfo?.date).toISOString();

    return (
      <CalendarHeader
        date={date}
        isToday={headerInfo?.isToday}
      />
    );
  };

  return (
    <div className={clsx('flex items-start justify-center w-100', classes.gridView)}>
      <div className="flex-1 border-neutral-100 border-1">
        {loading && (
          <div className="absolute top-0 right-0 z-50 flex items-center justify-center w-full h-full">
            <CircularProgress />
          </div>
        )}
        <FullCalendar
          allDaySlot={true}
          slotEventOverlap={false}
          viewClassNames={clsx('border-t-1 border-gray-100', classes.view, classes.weekView)}
          firstDay={1}
          height={calendarHeight}
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialDate={new Date()}
          initialView="timeGridDay"
          headerToolbar={{ left: '', center: '', right: '' }}
          events={eventsByTimezone}
          scrollTimeReset={true}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23',
            meridiem: false,
          }}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit',
              hourCycle: 'h23',
              omitZeroMinute: false,
              meridiem: 'short',
            },
          ]}
          slotMinTime="04:00"
          eventContent={renderEventContent}
          dayHeaderContent={renderHeader}
          nowIndicator={true}
          {...restProps}
        />
      </div>
      {view === 'timeGridDay' && (
        <div>
          <StaticDatePicker
            dayView={dayView}
            calendarRef={calendarRef}
            onViewDateChange={onViewDateChange}
          />
          <ListStatusLegend />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  gridView: {
    '& .fc-toolbar': {
      display: 'none',
    },
  },
  event: {
    '& .fc-event-time': {
      display: 'none',
    },
  },
  view: {
    '& *': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& td, & .fc-col-header-cell': {
      borderColor: '#F5F5F5 !important',
    },
    '& th, & .fc-col-header-cell': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .fc-col-header-cell': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& > table, & > table > tbody > tr > td': {
      border: '0 !important',
    },
    '& > table > thead > tr > th': {
      border: 0,
    },
    // day week
    '& .fc-col-header-cell-cushion': {
      fontSize: 13,
      fontWeight: 500,
      color: `${theme.palette.secondary.light} !important`,
      textTransform: 'uppercase',
      textDecoration: 'none !important',
    },
    // day
    '& .fc-daygrid-day-number': {
      fontSize: 18,
      fontWeight: 600,
    },
    // day inner
    '& .fc-daygrid-day-top': {
      flexDirection: 'row',
      paddingTop: 4,
      paddingLeft: 4,
    },
    // today
    '& td.fc-day-today > .fc-daygrid-day-frame': {
      backgroundColor: '#F2FCFF',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    // event dot
    '& .fc-daygrid-event-dot': {
      borderColor: theme.palette.primary.main,
    },
    // event title
    '& .fc-event-title': {
      fontSize: 11,
      fontWeight: 500,
    },
  },
  monthView: {},
  weekView: {
    '& > table > tbody > tr': {
      display: 'none',
    },
    '& > table > tbody > tr:last-child': {
      display: 'table-row',
    },
    '& th.fc-timegrid-axis': {
      border: 0,
      borderBottom: `1px solid #F5F5F5`,
    },
    '& th.fc-day-today': {
      padding: 0,
    },
    '& th.fc-day-today .fc-scrollgrid-sync-inner': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& th.fc-day-today .fc-col-header-cell-cushion': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& td.fc-day-today': {
      backgroundColor: 'unset !important',
    },
    '& td.fc-day-today .fc-timegrid-col-frame': {},
    '& .fc-timegrid-slot-label-cushion': {
      fontSize: 11,
      fontWeight: 500,
      color: theme.palette.secondary.light,
    },
    '& .fc-v-event': {
      background: 'white',
      textDecoration: 'none !important',
      border: 0,
    },
    '& .fc-day-past .fc-v-event': {
      opacity: 0.7,
    },
    '& .fc-event-main': {
      overflow: 'hidden',
    },
    '& .fc-timegrid-col-events': {
      margin: '0 1px',
    },
  },
}));

export default CoreCalendar;
