/* eslint-disable */
import IndexTable from 'app/components/cores/index-table';
import { ProgressBar } from 'app/components/cores/statistics';
import { TASK_COLORS } from 'constants/task';
import { TAuditPerformanceAuditorStats, TAuditPerformanceListStats } from 'models';
import { useMemo } from 'react';
import { Cell, Row } from 'react-table';
import { toFixed } from 'utils/number';

import { Typography } from '@mui/material';

type Props = {
  statsType: string;
  filterTask?: string | null;
  data?: any;
  pageCount?: number;
  totalDataCount?: number;
  searchText: string;
  isLoading?: boolean;
  location?: any;
  history?: any;
  filters?: any;
  classes?: {
    table?: string;
    pagination?: string;
    distribution?: string;
  };
  tableConfig?: any;
  setFilters?: any;
  onFetchData?: any;
  onRowClick?: any;
};

function StatisticTable({
  filterTask,
  statsType,
  data,
  pageCount,
  totalDataCount,
  searchText,
  isLoading,
  location,
  history,
  filters,
  classes,
  tableConfig,
  setFilters,
  onFetchData,
  onRowClick,
}: Props) {
  const listColumn = useMemo(
    () => [
      {
        Header: 'List',
        accessor: 'list',
        className: 'border-t-1 border-gray-100',
        width: '25%',
        Filter: <></>,
        sortable: true,
        id: 'name',
        Cell: ({ row }: { row: Row<TAuditPerformanceListStats> }) => (
          <Typography className="text-13 font-500">{row.original.list.name}</Typography>
        ),
      },
      {
        Header: 'Score',
        accessor: 'score',
        className: 'border-t-1 border-gray-100',
        width: '20%',
        Filter: <></>,
        Cell: ({ row }: { row: Row<TAuditPerformanceListStats> }) => {
          const { scoreRate, score, maxScore } = row.original;

          return (
            <Typography className="text-13 font-500">
              {scoreRate}% ({score}/{maxScore})
            </Typography>
          );
        },
      },
      {
        Header: 'Flagged Items',
        accessor: 'flaggedItems',
        className: 'border-t-1 border-gray-100',
        width: '15%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAuditPerformanceListStats> }) => (
          <Typography className="text-13 font-500">{cell.value}</Typography>
        ),
      },
      {
        Header: 'Completed Actions',
        accessor: 'completedActions',
        className: 'border-t-1 border-gray-100',
        width: '15%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAuditPerformanceListStats> }) => (
          <Typography className="text-13 font-500">{cell.value}</Typography>
        ),
      },
      {
        Header: 'Completion Rate',
        accessor: 'completionRate',
        className: 'capitalize border-t-1 border-gray-100',
        width: '25%',
        Filter: <></>,
        sortable: true,
        Cell: ({ row }: { row: Row<TAuditPerformanceListStats> }) => {
          const rowData = row.original;
          const { totalCompletedOnTime, totalCompletedLate, completionRate } = rowData;
          const values = [totalCompletedOnTime, totalCompletedLate];

          return (
            <div className="flex items-center justify-between space-x-12">
              <Typography className="font-600 text-14 text-secondaryMain w-56">
                {completionRate ? toFixed(completionRate, 2) : 0}%
              </Typography>
              <div className="flex-1">
                <ProgressBar
                  colors={[TASK_COLORS.onTime, TASK_COLORS.late]}
                  values={values}
                />
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterTask, tableConfig, statsType],
  );

  // Auditor, Audits Conducted, Flagged Items,
  const auditorColumns = useMemo(() => {
    return [
      {
        Header: 'Auditor',
        accessor: 'user.fullName',
        width: '50%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAuditPerformanceAuditorStats> }) => {
          return <Typography className="text-13 font-500">{cell.value}</Typography>;
        },
      },
      {
        Header: 'Audits Conducted',
        accessor: 'totalCompleted',
        width: '25%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAuditPerformanceAuditorStats> }) => (
          <Typography className="text-13 font-500">{cell.value}</Typography>
        ),
      },
      {
        Header: 'Flagged Items',
        accessor: 'flaggedItems',
        width: '25%',
        Filter: <></>,
        Cell: ({ cell }: { cell: Cell<TAuditPerformanceAuditorStats> }) => (
          <Typography className="text-13 font-500">{cell.value}</Typography>
        ),
      },
    ];
  }, [filterTask, tableConfig, statsType]);

  return (
    <IndexTable
      columns={statsType === 'auditor' ? auditorColumns : (listColumn as any[])}
      isUpdateAddressUrl={false}
      classes={{ table: classes?.table || '' }}
      data={data as any}
      pageCount={pageCount ?? 50}
      totalDataCount={totalDataCount ?? 0}
      searchKey={searchText}
      loading={!!isLoading}
      location={location}
      onFetchData={onFetchData}
      history={history}
      searchText={searchText || ''}
      filters={filters}
      setFilters={setFilters}
      onRowClick={onRowClick}
      PaginationProps={{
        classes: {
          root: classes?.pagination || '',
        },
      }}
    />
  );
}

export default StatisticTable;
