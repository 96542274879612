import { TResponsePagination } from 'models';
import { TLocationCategory } from 'models/location-category.model';
import { useState } from 'react';

import { useGetInfiniteList } from './request';

export const locationCategoriesKeys = {
  all: ['locationCategories'] as const,
  lists: () => [...locationCategoriesKeys.all, 'list'] as const,
  list: (filters: any) => [...locationCategoriesKeys.lists(), { filters }] as const,
  details: () => [...locationCategoriesKeys.all, 'detail'] as const,
  detail: (id: number) => [...locationCategoriesKeys.details(), id] as const,
  tasks: (id: number, filter: any) => [...locationCategoriesKeys.details(), id, filter] as const,
};

const locationCategoriesURL = '/api/select_options/location_categories';

export const useInfiniteLocationCategories = (filters: any) => {
  const [searchText, setSearchText] = useState('');

  const query = useGetInfiniteList<TResponsePagination<TLocationCategory>>(
    [...locationCategoriesKeys.lists(), 'infinite', { filters, searchText }],
    `${locationCategoriesURL}`,
    { filters, 'q[name_cont]': searchText },
  );

  const convertedData = [
    {
      externalId: 0,
      locationCategoryId: 0,
      locationCategoryName: 'Global',
    },
    ...(query.data?.map((item: any) => ({
      locationCategoryId: item.externalId,
      locationCategoryName: item.name,
    })) ?? []),
  ];

  return {
    ...query,
    data: convertedData,
    setSearchText,
  };
};
