import DateRange from 'app/components/cores/date-range';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { convertDateStringToString } from 'utils/string';

type Props = {
  actionFilters: any;
  setActionFilters: (filters: any) => void;
  inputClass?: string;
  startDateKey?: string;
  endDateKey?: string;
};

function ActionsHeaderFilter({
  actionFilters,
  setActionFilters,
  inputClass,
  startDateKey = 'q[completed_at_gteq]',
  endDateKey = 'q[completed_at_lteq]',
}: Props) {
  const defaultSelectedSubmittedRange = useMemo(() => {
    if (!actionFilters[startDateKey] || !actionFilters[endDateKey]) {
      return {
        startDate: null,
        endDate: null,
      };
    }

    const startDate = moment(actionFilters[startDateKey], 'DD/MM/YYYY').toDate();
    const endDate = moment(actionFilters[endDateKey], 'DD/MM/YYYY').toDate();

    return { endDate, startDate };
  }, [actionFilters, startDateKey, endDateKey]);

  const [selectedSubmittedRange] = useState(defaultSelectedSubmittedRange);

  const handleSelect = ({ startDate, endDate }: any) => {
    setActionFilters((prevFilter: any) => ({
      ...prevFilter,
      [startDateKey]: convertDateStringToString(startDate),
      [endDateKey]: convertDateStringToString(endDate),
    }));
  };

  return (
    <DateRange
      onChange={handleSelect}
      value={selectedSubmittedRange}
      classes={{ input: inputClass }}
    />
  );
}

export default ActionsHeaderFilter;
