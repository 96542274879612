import ErrorMessage from 'app/components/cores/form/error-message';
import DuplicateIcon from 'app/components/cores/icons/duplicate';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import LocationTransferList from 'app/components/cores/location-transfer-list';
import clsx from 'clsx';
import { ITEM_TYPES_MAP } from 'constants/index';
import _ from 'lodash';
import { TLocation } from 'models';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Control, FieldValues, useController, useWatch } from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Checkbox, FormControlLabel, Tooltip, Typography, alpha } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import TypeSelection from './type-selection';

const useStyles: any = makeStyles((theme: any) => {
  return {
    error: {
      background: alpha(theme.palette.error.main, 0.2),
    },
    focus: {
      background: alpha(theme.palette.primary.main, 0.2),
    },
    conditionalDot: {
      top: 2,
      left: 14,
    },
  };
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

type Props = {
  name: string;
  control: Control<FieldValues>;
  isFocus?: boolean;
  isOpenLocationSetting?: boolean;
  setOpenDrawer: (open: boolean, name: string) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  locations?: TLocation[];
  onRemove?: () => void;
  onDuplicate?: () => void;
  onOpenLocationSetting?: (name: string) => void;
  conditionalId?: number;
  conditionalGroup?: number;
  isDisabled?: boolean;
  onCloseLocationSetting?: () => void;
  openLocationSetting?: boolean;
};

const ItemDetail = ({
  setOpenDrawer,
  name,
  control,
  isFocus,
  isOpenLocationSetting,
  dragHandleProps,
  locations,
  conditionalId,
  conditionalGroup,
  isDisabled,
  onRemove,
  onDuplicate,
  onOpenLocationSetting,
  onCloseLocationSetting,
  openLocationSetting,
}: Props) => {
  const classes = useStyles();
  const {
    fieldState: { error },
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const hasError = Object.keys(error || {}).length > 0;

  const errorMessages = getErrorMessage(error);

  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  const isAllLocation = useWatch({
    control,
    name: `${name}.isAllLocation`,
  });

  const prompt = useWatch({
    control,
    name: `${name}.prompt`,
  });

  const isSection = type === ITEM_TYPES_MAP.SECTION;
  const questionLocations = useMemo(() => value?.locations || [], [value]);
  const hasConditional = !!value?.conditionalId;
  const isAllLocationSettled = useRef(false);

  const setAllLocation = useCallback(
    (isAllLocationChecked: boolean) => {
      if (isAllLocationChecked) {
        const locationIds = locations?.map((item: TLocation) => item.externalId);
        onChange({ ...value, locations, locationIds, isAllLocation: isAllLocationChecked });
      } else {
        onChange({ ...value, isAllLocation: isAllLocationChecked });
      }
    },
    [locations, value, onChange],
  );

  // effect to set default value of All Location checkbox
  useEffect(() => {
    if (isAllLocationSettled.current) return;
    isAllLocationSettled.current = true;

    if (!isAllLocation) {
      setAllLocation(!questionLocations?.length);
    } else {
      setAllLocation(true);
    }
  }, [questionLocations, setAllLocation, isAllLocation]);

  // effect to set task's locations
  useEffect(() => {
    if (!isAllLocation || value?.locationIds?.length === locations?.length) return;
    setAllLocation(true);
  }, [isAllLocation, locations, value, onChange, setAllLocation]);

  const handleEdit = (open: boolean) => (event: React.MouseEvent) => {
    event.preventDefault();
    setOpenDrawer(open, name);
  };

  const handleOpenLocationSetting = () => {
    onOpenLocationSetting?.(name);
  };

  const handleChangeLocation = (nextLocations: TLocation[]) => {
    const locationIds = nextLocations?.map((item: TLocation) => item.externalId);
    onChange({ ...value, locations: nextLocations, locationIds });
  };

  const handleChangeAllLocation = (event: React.SyntheticEvent, checked: boolean) => {
    setAllLocation(checked);
    isAllLocationSettled.current = checked;
  };

  const getLocationBadge = () => {
    const taskLocationLength = questionLocations?.length;
    return isAllLocation ? undefined : taskLocationLength;
  };

  function getErrorMessage(errors: any) {
    const keys = Object.keys(errors || {});

    const result = keys.reduce((acc: string[], err: any) => {
      if (!Array.isArray(errors[err])) {
        const { message } = errors[err];
        return [...acc, message];
      }

      const nextErrors = errors[err]?.map((childErrors: any) => getErrorMessage(childErrors));
      const flatErrors = nextErrors?.flat() || [];
      return [...acc, ...flatErrors];
    }, []);
    return _.uniq(result);
  }

  const getDefaultPrompt = () => {
    return `Please input ${isSection ? 'section' : 'task'} name`;
  };

  const handleBlur = () => {
    if (!prompt) {
      onChange({ ...value, prompt: getDefaultPrompt() });
    }
  };

  const handleFocus = () => {
    if (value.prompt === getDefaultPrompt()) {
      onChange({ ...value, prompt: '' });
    }
  };

  const isHideActions = openLocationSetting;
  const isHideLocationSetting = openLocationSetting && !isFocus;

  return (
    <div className={clsx(hasError && classes.error, isFocus && classes.focus, 'pr-8 rounded-8')}>
      <div className={clsx('flex flex-row items-start w-full')}>
        <div
          className="flex items-center justify-center w-40 h-40"
          {...dragHandleProps}
        >
          <ReorderIcon className={clsx('text-secondaryLight text-14 rotate-90')} />
        </div>
        <div className="w-full pl-8 pr-12 mt-4">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="relative w-full">
                <ControlledTextInput
                  name={`${name}.prompt`}
                  control={control}
                  className="w-full pl-32 pr-8 bg-paper rounded-8"
                  variant="standard"
                  InputProps={{
                    className: 'min-h-32',
                    disableUnderline: true,
                    classes: { disabled: 'text-secondaryLight' },
                  }}
                  showErrorMessage={false}
                  disabled={isDisabled}
                  multiline={true}
                  placeholder={getDefaultPrompt()}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                {conditionalGroup && (
                  <Tooltip title="This item is a conditional target">
                    <StyledBadge
                      className={clsx('absolute', classes.conditionalDot)}
                      classes={{
                        standard: 'bg-white',
                      }}
                      badgeContent={
                        <Typography
                          color="primary"
                          className="text-11"
                        >
                          {conditionalGroup}
                        </Typography>
                      }
                    />
                  </Tooltip>
                )}

                {hasConditional && (
                  <Tooltip title="This item has conditional logic">
                    <StyledBadge
                      color="primary"
                      className={clsx('absolute', classes.conditionalDot)}
                      badgeContent={<Typography className="text-11">{conditionalId}</Typography>}
                    />
                  </Tooltip>
                )}
              </div>

              {type !== 'Items::Section' && (
                <div className="ml-12">
                  <TypeSelection
                    name={name}
                    control={control}
                    onChangeCallback={() => setOpenDrawer(true, name)}
                  />
                </div>
              )}
            </div>
            {errorMessages.map((mess, index) => (
              <ErrorMessage
                message={mess}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-row items-center pt-4 ml-auto space-x-8 w-108">
          <div className={clsx(isHideLocationSetting && 'cursor-not-allowed')}>
            <div
              className={clsx(
                'flex flex-row items-center justify-center w-32 h-32 bg-paper rounded-8',
                isHideLocationSetting && 'pointer-events-none',
              )}
            >
              <Tooltip title="Location Setting">
                <span>
                  <IconButton
                    onClick={handleOpenLocationSetting}
                    color="primary"
                  >
                    <Badge
                      badgeContent={getLocationBadge()}
                      color="primary"
                      classes={{ badge: 'text-9 min-w-20 min-h-20' }}
                    >
                      <LocationOnOutlinedIcon className="text-16" />
                    </Badge>
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>

          <div className={clsx(isHideActions && 'cursor-not-allowed')}>
            <div
              className={clsx(
                'flex flex-row items-center justify-center w-32 h-32 bg-paper rounded-8',
                isHideActions && 'pointer-events-none',
              )}
            >
              <Tooltip title="Duplicate">
                <IconButton
                  onClick={onDuplicate}
                  disabled={isDisabled}
                >
                  <DuplicateIcon className="text-16" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {!isDisabled && (
            <div className={clsx(isHideActions && 'cursor-not-allowed')}>
              <div
                className={clsx(
                  'flex flex-row items-center justify-center w-32 h-32 bg-paper rounded-8',
                  isHideActions && 'pointer-events-none',
                )}
              >
                <Tooltip title="Remove">
                  <span>
                    <IconButton
                      onClick={onRemove}
                      disabled={isDisabled}
                    >
                      <DeleteOutlineIcon className="text-16" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          )}

          <div className={clsx(isHideActions && 'cursor-not-allowed')}>
            <div
              className={clsx(
                'flex flex-row items-center justify-center w-32 h-32 bg-paper rounded-8',
                isHideActions && 'pointer-events-none',
              )}
            >
              <Tooltip title={!type ? 'Please select a type before editing' : 'Edit'}>
                <span>
                  <IconButton
                    onClick={handleEdit(true)}
                    disabled={!type}
                  >
                    <EditOutlinedIcon className="text-16" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {/* location settings */}
      {isOpenLocationSetting && (
        <div className="pt-4 pb-8 pl-44">
          <div className="w-full px-24 pb-12 -mb-8 bg-white rounded-t-8">
            <FormControlLabel
              label="Apply to all locations"
              control={<Checkbox />}
              classes={{ label: 'text-13' }}
              checked={!!isAllLocation}
              onChange={handleChangeAllLocation}
            />
          </div>

          <LocationTransferList
            isDisabled={isDisabled || isAllLocation}
            isAllLocation={isAllLocation}
            className="items-start overflow-auto bg-white rounded-8"
            classes={{ container: 'min-h-160 max-h-256' }}
            optionValue="externalId"
            values={questionLocations}
            options={locations}
            onChange={handleChangeLocation}
            onClose={onCloseLocationSetting}
          />
        </div>
      )}
    </div>
  );
};

export default ItemDetail;
