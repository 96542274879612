import { DISPLAY_TIME_FORMAT, TIME_FORMAT } from 'constants/index';
import _ from 'lodash';
import { TDisplayTime, TUntilItem } from 'models';
import moment from 'moment';
import ordinal from 'ordinal';
import { RRule } from 'rrule';

// TODO move to constants
const WEEKDAYS = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tueday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

// TODO move to constants
// 28 days of a month
const DAYS = _.map(_.range(1, 29), day => ({ value: `${day}`, label: ordinal(day) }));

export function CalculateNextDisplayTimes(
  count: number,
  interval: number,
  duration: number, // in second
  currentDisplayTime: TDisplayTime & {
    id: string;
  },
): { nextTimes: TDisplayTime[]; error: boolean } {
  const firstTime = currentDisplayTime?.time || '0:00 AM';
  const dueIn = currentDisplayTime?.dueIn || '3600';
  const expireIn = currentDisplayTime?.expireIn || '7200';
  const intervalInSecond = interval * duration;

  const nextDisplayTimes = [];
  const startTime = moment(firstTime, DISPLAY_TIME_FORMAT).startOf('day');
  let error = false;

  /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
  for (let i = 1; i <= count; i++) {
    const nextDisplay = moment(firstTime, DISPLAY_TIME_FORMAT);
    nextDisplay.add(intervalInSecond * i, 'seconds');
    const diff = nextDisplay.diff(startTime, 'days');
    if (diff >= 1) {
      error = true;
      break;
    }
    nextDisplayTimes.push({ time: nextDisplay.format(DISPLAY_TIME_FORMAT), dueIn, expireIn });
  }

  return { nextTimes: nextDisplayTimes, error };
}

export function generateDisplayTimes({ startTime, endTime, interval, dueIn, expireIn }: any) {
  let range = 0;
  const startTimes: string[] = [];
  const isInfinity = true;

  while (isInfinity) {
    const nextStartTime = getNewTimes({ startTime, endTime, interval, range });
    range += 1;

    if (nextStartTime) {
      startTimes.push(nextStartTime);
    } else {
      break;
    }
  }

  return _.map(startTimes, sTime => displayTimeMapping(sTime, dueIn, expireIn));
}

export function generateDisplayTimesByCount({ startTime, endTime, interval, dueIn, expireIn, count }: any) {
  const startTimes: string[] = [];

  _.range(count).forEach(range => {
    const nextStartTime = getNewTimes({ startTime, endTime, interval, range });
    if (nextStartTime) {
      startTimes.push(nextStartTime);
    }
  });

  return _.map(startTimes, sTime => displayTimeMapping(sTime, dueIn, expireIn));
}

export function displayTimeMapping(startTime: string, dueIn: string, expireIn: string) {
  return {
    startTime,
    dueIn,
    expireIn,
  };
}

export function getNewTimes({ startTime, endTime, interval, range }: any) {
  const nextStartTime = moment(startTime, TIME_FORMAT).add(interval * range, 's');
  const nextEndTime = moment(endTime, TIME_FORMAT);

  if (nextStartTime.valueOf() <= nextEndTime.valueOf()) {
    return nextStartTime.format(TIME_FORMAT);
  }

  return null;
}

export function formatTimeValue(time?: string) {
  if (!time) {
    return null;
  }
  // TODO check time includes ampm
  if (time?.includes('AM') || time?.includes('PM')) {
    return moment(time, 'hh:mm:aa');
  }
  return moment(time, TIME_FORMAT);
}

export function generateDefaultDisplayTime() {
  return {
    time: null,
    dueIn: '3600',
    expireIn: '7200',
    endTime: null,
    count: 1,
    interval: 300,
    startAfter: 0,
    endAfter: 0,
  };
}

export const getFrequency = (recurringRule: string) => {
  if (!recurringRule) return 0;
  const rule = RRule.fromString(recurringRule);
  const freq = rule?.origOptions?.freq ?? 0;
  return freq;
};

export const generateUntilAfterLabel = (frequency: number, target?: string) => {
  if (frequency === RRule.WEEKLY && target !== 'end') return '-';
  if (frequency === RRule.DAILY && !target) return 'on';
  return 'of';
};

export const generateUntilAfterOptions = (frequency: number) => {
  const frequencyMapping: { [key: number]: string } = { 1: 'month', 2: 'week', 3: 'day' };
  if (!frequencyMapping[frequency]) return [];

  const frequencyName = { 1: 'month', 2: 'week', 3: 'day' }[frequency];
  const isDaily = frequency === RRule.DAILY;
  const defaultOptions = [
    { value: 0, label: isDaily ? 'Today' : `This ${frequencyName}` },
    { value: 1, label: isDaily ? 'Tomorrow' : `Next ${frequencyName}` },
  ];
  const extraOptions = _.map(_.range(2, 11), value => ({
    value,
    label: `Next ${value} ${frequencyName}s`,
  }));

  return [...defaultOptions, ...extraOptions];
};

export const generateWeeklyTargetOptions = () => {
  return [
    { value: 'next_start_time', label: 'Next start time' },
    { value: 'end_time', label: 'End time' },
    { value: 'end', label: 'End of the week' },
    ...WEEKDAYS,
  ];
};

export const generateMonthlyTargetOptions = () => {
  return [
    { value: 'next_start_time', label: 'Next start time' },
    { value: 'end_time', label: 'End time' },
    { value: 'end', label: 'End of the month' },
    ...DAYS,
  ];
};

export const generateDailyTargetOptions = () => {
  return [
    { value: 'next_start_time', label: 'Next start time' },
    { value: 'end_time', label: 'End time' },
    { value: 'end', label: 'End of day' },
    { value: null, label: 'Specific time' },
  ];
};

export const generateUntilText = (frequency: number, untilObject?: TUntilItem) => {
  const { target, after, time } = untilObject || {};
  const afterOptions = generateUntilAfterOptions(frequency);
  const afterLabel = _.find(afterOptions, { value: after })?.label;
  const label = generateUntilAfterLabel(frequency, target);
  const timeLabel = !time ? '' : ` at ${time}`;
  const isTimeTarget = ['end_time', 'next_start_time'].includes(target as string);

  if (frequency === RRule.MONTHLY) {
    const targetMonthlyOptions = generateMonthlyTargetOptions();
    const targetMonthlyLabel = _.find(targetMonthlyOptions, { value: target })?.label;
    return isTimeTarget ? targetMonthlyLabel : `${targetMonthlyLabel} ${label} ${afterLabel}${timeLabel}`;
  }

  if (frequency === RRule.WEEKLY) {
    const targetWeeklyOptions = generateWeeklyTargetOptions();
    const targetWeeklyLabel = _.find(targetWeeklyOptions, { value: target })?.label;
    return isTimeTarget ? targetWeeklyLabel : `${targetWeeklyLabel} ${label} ${afterLabel}${timeLabel}`;
  }

  if (frequency === RRule.DAILY) {
    const targetDailyOptions = generateDailyTargetOptions();
    const targetDailyLabel = _.find(targetDailyOptions, { value: target })?.label;
    if (isTimeTarget) return targetDailyLabel;
    return target === 'end' ? `${targetDailyLabel} ${afterLabel}` : `${time} ${afterLabel}`;
  }

  return '';
};
