import { TListTrigger } from 'models/task.model';

export const transformOrgRoles = (roles: TListTrigger['orgRoles'] = []) => {
  if (!roles) return [];

  return roles.map(role => ({
    externalId: role?.orgRoleId ?? role?.externalId,
    name: role?.orgRoleName ?? role?.name,
  }));
};

export const getOrgRoleIds = (roles: TListTrigger['orgRoles'] = []) => {
  if (!roles) return [];
  return roles.map(role => role.externalId);
};

export const removeDestroyedOrgRoles = (roles: TListTrigger['orgRoles'] = []) => {
  if (!roles) return [];

  return roles.filter(role => !role._destroy);
};

export const checkExistDestroyedOrgRoles = (roles: TListTrigger['orgRoles'] = []) => {
  if (!roles) return false;
  return roles.some(role => role._destroy);
};
