import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const mediaResponseSlice = createSlice({
  name: 'report/mediaResponseSlice',
  initialState: {
    uncompletedDownloadMediaResponseIds: [],
    isDownloading: false,
  },
  reducers: {
    setDownloadingMediaResponseId: (state, action) => {
      const downloadingMediaResponseId = action.payload;
      const uncompletedDownloadMediaResponseIds = _.cloneDeep(state.uncompletedDownloadMediaResponseIds) || [];

      uncompletedDownloadMediaResponseIds.push(downloadingMediaResponseId);

      state.isDownloading = true;
      state.uncompletedDownloadMediaResponseIds = uncompletedDownloadMediaResponseIds;
    },
    clearDownloadingMediaResponseId: (state, action) => {
      const downloadingMediaResponseId = action.payload;
      const uncompletedDownloadMediaResponseIds = _.cloneDeep(state.uncompletedDownloadMediaResponseIds) || [];

      _.pull(uncompletedDownloadMediaResponseIds, downloadingMediaResponseId);

      state.isDownloading = !!uncompletedDownloadMediaResponseIds?.length;
      state.uncompletedDownloadMediaResponseIds = uncompletedDownloadMediaResponseIds;
    },
  },
});

export const { setDownloadingMediaResponseId, clearDownloadingMediaResponseId } = mediaResponseSlice.actions;

export default mediaResponseSlice.reducer;
