import Toggle from 'app/components/cores/inputs/toggle/toggle';
import clsx from 'clsx';
import { Control, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FormControlLabel, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ParamsTypes {
  listId: string;
}

type Props = {
  name: string;
  control?: Control;
};

function AuditSwitch({ name, control }: Props) {
  const classes = useStyles();
  const routeParams = useParams<ParamsTypes>();
  const { listId } = routeParams;

  const { getValues } = useFormContext();
  const status = getValues('status');
  const audit = getValues('audit');
  const isPublishedList = status === 'published';

  if (!!listId && audit === undefined) return null;

  return (
    <Tooltip title={isPublishedList ? 'This value cannot be changed once the list is published' : ''}>
      <FormControlLabel
        className={clsx(isPublishedList && classes.root)}
        control={
          <Toggle
            disabled={isPublishedList}
            name={name}
            control={control}
          />
        }
        label={<Typography className="ml-8 text-13 font-400">Audit List</Typography>}
        labelPlacement="end"
      />
    </Tooltip>
  );
}

export default AuditSwitch;

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'not-allowed !important',
  },
}));
