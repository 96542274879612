import TypeOption from 'app/components/cores/items-editor/type-options/type-option';
import { SingleSelect as Select } from 'app/components/cores/select';
import { BULK_IMPORT_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TSpreadSheetCell } from 'models';
import * as React from 'react';
import { DataEditorComponent, DataViewerComponent } from 'react-spreadsheet';

export const ItemTypeSelectView: DataViewerComponent<TSpreadSheetCell> = ({ cell }: any) => {
  const option = React.useMemo(
    () => cell && BULK_IMPORT_OPTIONS.find((_option: any) => _.lowerCase(_option.value) === _.lowerCase(cell.value)),
    [cell],
  );
  return (
    <Select
      value={option}
      options={BULK_IMPORT_OPTIONS}
      className="mx-8"
    />
  );
};

export const ItemTypeSelectEdit: DataEditorComponent<TSpreadSheetCell> = ({ cell, onChange, exitEditMode }: any) => {
  const handleChange = React.useCallback(
    (selection: any) => {
      onChange({ ...cell, value: selection ? selection.value : null });
    },
    [cell, onChange],
  );

  const option = React.useMemo(
    () => cell && BULK_IMPORT_OPTIONS.find((_option: any) => _option.value === cell.value),
    [cell],
  );

  return (
    <Select
      value={option}
      onChange={handleChange}
      options={BULK_IMPORT_OPTIONS}
      isDisabled={false}
      className="mx-8"
      components={{
        Option: TypeOption,
      }}
      menuPosition="fixed"
      autoFocus={true}
      defaultMenuIsOpen={true}
      onMenuClose={() => exitEditMode()}
    />
  );
};
