import { TASK_COLORS, TASK_INACTIVE_COLORS } from 'constants/task';
import { forwardRef, useEffect, useState } from 'react';
import { toFixed } from 'utils/number';

import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

type Props = {
  type: keyof typeof TASK_COLORS;
  value: number;
  filterTask?: string | null;
  tooltipText?: string;
  circularProgressSize?: number;
};

const CircularWithValueLabel = forwardRef(function CircularWithValueLabel(
  { type, value, filterTask, tooltipText, circularProgressSize }: Props,
  ref: any,
) {
  const [animated, setAnimated] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimated(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getColorFilterTask = () => {
    if (!filterTask || filterTask === type) return TASK_COLORS[type];
    return TASK_INACTIVE_COLORS[type];
  };

  const color = getColorFilterTask();

  return (
    <Tooltip title={tooltipText}>
      <div
        className="relative inline-flex cursor-pointer"
        ref={ref}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{ color: '#F6F6F6' }}
          className="absolute"
          size={circularProgressSize}
        />
        <CircularProgress
          variant={animated ? 'indeterminate' : 'determinate'}
          value={value}
          sx={{ color }}
          size={circularProgressSize}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <Typography
            variant="caption"
            component="span"
            className="text-9 font-600"
            sx={{ color }}
          >{`${value ? toFixed(value, 2) : 0}%`}</Typography>
        </div>
      </div>
    </Tooltip>
  );
});

export default CircularWithValueLabel;
