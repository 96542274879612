import HistoryInfo from './info';
import ListTask from './tasks';

type Props = {
  printRef: React.Ref<any>;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
  hideInfo?: boolean;
};

function HistoryForm({ printRef, isAudit, canAuditInteractions = true, hideInfo = false }: Props) {
  return (
    <div
      className="space-y-24"
      ref={printRef as any}
    >
      {!hideInfo && <HistoryInfo />}

      <div className="grid grid-cols-8">
        <div className="col-span-6 export-histories print:max-w-full print:col-span-8">
          <ListTask
            isAudit={isAudit}
            canAuditInteractions={canAuditInteractions}
          />
        </div>
      </div>
    </div>
  );
}

export default HistoryForm;
