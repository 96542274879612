import ConfirmDialog from 'app/components/cores/confirm-dialog';
import useTask from 'app/hooks/use-task';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { Control, useController, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Typography } from '@mui/material';

import MultipleChoiceItem from './multiple-choice-item';

type Props = {
  control?: Control;
  name: string;
};

const MultipleChoiceOptions = ({ name, control }: Props) => {
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreSettingsName = `${name}.scoreSettings`;
  const dispatch = useDispatch();
  const { isShowAudit } = useTask();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.multipleChoiceOptions`,
  });

  const {
    field: { value: scoreEnabled, onChange: onChangeScoreEnabled },
  } = useController({
    control,
    name: scoreEnabledName,
  });

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({
    control,
    name: scoreSettingsName,
  });

  const hasScoreSettings = scoreEnabled && scoreSettings?.length > 0;

  const handleOpenConfirmDialog = (isRemove: boolean, removedItemIndex?: number) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Date Time Options Change"
            message="Changing Date Time Options will remove the current Score Settings, are you sure you want to continue?"
            statusVariant="warning"
            confirmButtonLabel="Continue"
            cancelButtonLabel="Cancel"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              if (isRemove) {
                remove(removedItemIndex);
              } else {
                append({ value: '', label: '' });
              }
              onChangeScoreEnabled(false);
              onChangeScoreSettings([]);
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };

  const handleAddOption = () => {
    const handler =
      isShowAudit && hasScoreSettings ? () => handleOpenConfirmDialog(false) : () => append({ value: '', label: '' });
    handler();
  };

  const handleRemoveOption = (index: number) => {
    const handler = isShowAudit && hasScoreSettings ? () => handleOpenConfirmDialog(true, index) : () => remove(index);
    handler();
  };

  return (
    <div className="mb-8 space-y-8">
      <Typography className="text-12 font-600">Multiple Choice Options</Typography>
      {fields.map((item: any, index: number) => {
        return (
          <MultipleChoiceItem
            key={item.id}
            name={`${name}.multipleChoiceOptions.${index}`}
            control={control}
            index={index}
            onRemove={() => handleRemoveOption(index)}
          />
        );
      })}
      <Button
        className={clsx(
          ' normal-case p-0 bg-transparent shadow-0 text-11 font-400 text-primaryLight',
          'hover:text-primaryLight hover:shadow-0 hover:font-600 hover:bg-transparent',
        )}
        variant="contained"
        onClick={handleAddOption}
      >
        Add Answer Choice
      </Button>
    </div>
  );
};

export default MultipleChoiceOptions;
