import EmployeeCalendar from 'app/components/cores/employee-calendar';
import { TaskHeader } from 'app/components/cores/tasks';
import { useGetEmployeeCalendar } from 'app/hooks/api/employee/calendar';
import { useGetManagerCalendar } from 'app/hooks/api/manager/calendar';
import usePermission from 'app/hooks/use-permission';
import useTask from 'app/hooks/use-task';
import useTaskFilter from 'app/hooks/use-task-filter';
import { IS_FILTER_MULTIPLE_DATES, LOCALE_DATE_FORMAT } from 'constants/index';
import _ from 'lodash';
import { TCalendarCommonFilter, TCalendarMode, TUserLocation } from 'models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { endOfDay, formatDate, formatDateWithTimezone, getEndOfWeek, getStartOfWeek, isSameDates } from 'utils/date';

import BluefitPage from '@fuse/core/BluefitPage';

function Index() {
  // Common
  // Data
  const { location: defaultLocation, setTaskFilterLocation: setDefaultTaskFilterLocation } = useTaskFilter();
  const { getListStatusColor } = useTask();
  const { havePermissionToAction, isAdminUser, isManagerUser } = usePermission();
  const canViewUserCalendar =
    (isAdminUser || isManagerUser) &&
    havePermissionToAction(['calendar.viewUserCalendar', 'managerCalendar.viewUserCalendar']);

  const isMounted = useRef(false);
  const [location, setTaskFilterLocation] = useState<TUserLocation>(defaultLocation as TUserLocation);
  const [viewMode, setMode] = useState<TCalendarMode>('timeGridDay');
  const [dayView, setDayView] = useState<Date>(new Date());
  const [dayRange, setDayRange] = useState<{ startDate?: Date; endDate?: Date }>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isListView, setIsListView] = useState(true);
  const [isMyList, setIsMyList] = useState(true);
  const [commonFilter, setCommonFilter] = useState<TCalendarCommonFilter>({
    roleId: undefined,
    status: undefined,
    locationId: undefined,
    userId: undefined,
  });

  // Effect
  useEffect(() => {
    if (!location?.externalId || !isMyList) return;
    setCommonFilter((prevFilter: any) => ({
      ...prevFilter,
      locationId: location?.externalId,
    }));
  }, [location, isMyList]);

  // Functions
  const dateRangeFilter = useMemo(() => {
    if (isListView && IS_FILTER_MULTIPLE_DATES) {
      return {
        fromDate: formatDateWithTimezone(dayRange?.startDate, LOCALE_DATE_FORMAT),
        endDate: formatDateWithTimezone(dayRange?.endDate, LOCALE_DATE_FORMAT),
      };
    }

    if (isListView || viewMode === 'timeGridDay') {
      return {
        fromDate: formatDateWithTimezone(dayView, LOCALE_DATE_FORMAT),
        endDate: formatDateWithTimezone(dayView, LOCALE_DATE_FORMAT),
      };
    }

    const startOfWeek = getStartOfWeek(dayView);
    const endOfWeek = getEndOfWeek(dayView);
    return {
      fromDate: formatDateWithTimezone(startOfWeek, LOCALE_DATE_FORMAT),
      endDate: formatDateWithTimezone(endOfWeek, LOCALE_DATE_FORMAT),
    };
  }, [isListView, viewMode, dayView, dayRange]);

  const calendarFilter: any = { ...dateRangeFilter, ...commonFilter };

  // admin get user's calendar
  const {
    data: allEmployeeData,
    refetch: adminRefetchEmployeeCalendars,
    isFetching: isFetchingAllEmployeeData,
  } = useGetManagerCalendar(
    {
      'q[from_date]': calendarFilter?.fromDate,
      'q[end_date]': calendarFilter?.endDate,
      'q[location_id_eq]': calendarFilter?.locationId,
      'q[org_role_id_eq]': calendarFilter?.roleId,
      user_id: calendarFilter?.userId,
      status: calendarFilter?.status,
    },
    {
      enabled: !isMyList && !!calendarFilter?.locationId && !!location?.externalId,
      onSuccess: () => {
        isMounted.current = true;
      },
    },
  );

  // user get calendar
  const {
    data: employeeData,
    refetch: employeeRefetchEmployeeCalendars,
    isFetching: isFetchingEmployeeData,
  } = useGetEmployeeCalendar(
    {
      'q[from_date]': calendarFilter?.fromDate,
      'q[end_date]': calendarFilter?.endDate,
      'q[location_id_eq]': isMyList && calendarFilter?.locationId,
      'q[org_role_id_eq]': calendarFilter?.roleId,
      status: calendarFilter?.status,
    },
    {
      enabled: isMyList && !!calendarFilter?.locationId,
      onSuccess: () => {
        isMounted.current = true;
      },
    },
  );

  const data = isMyList ? employeeData : allEmployeeData;
  const isFetching = isMyList ? isFetchingEmployeeData : isFetchingAllEmployeeData;
  const refetchEmployeeCalendars = isMyList ? employeeRefetchEmployeeCalendars : adminRefetchEmployeeCalendars;

  const generateCalendarEvents = useCallback((sources: any) => {
    if (!sources) {
      return [];
    }

    const result = sources.map((item: any) => ({
      ...item,
      id: item.id,
      title: item.name,
      start: new Date(item.start),
      end: new Date(item.end),
    }));
    return result;
  }, []);

  // TODO for date range filter, check dateRangeFilter start, end date is same date to format the events
  const generateListViewCalendarEvents = useCallback(
    (sources: any) => {
      if (!sources) {
        return [];
      }

      const result = _.map(sources, (item: any) => {
        const endDate = isSameDates(item.start, item.end) ? item.end : endOfDay(item.start);
        return {
          ...item,
          id: item.id,
          title: item.name,
          start: new Date(item.start),
          end: new Date(endDate),
          startDate: item.start,
          endDate: item.end,
          color: getListStatusColor(item.status, item.onDemandList),
        };
      });

      return result;
    },
    [getListStatusColor],
  );

  const events = useMemo(
    () => (isListView ? generateListViewCalendarEvents(data) : generateCalendarEvents(data)),
    [data, isListView, generateCalendarEvents, generateListViewCalendarEvents],
  );

  const listViewEvents = useMemo(() => {
    const nextEvents: any = {
      events: [],
      onDemandEvents: [],
    };

    if (!isListView) {
      nextEvents.events = events;
      return nextEvents;
    }

    _.forEach(events, event => {
      if (event.onDemandList) {
        nextEvents.onDemandEvents.push(event);
      } else {
        nextEvents.events.push(event);
      }
    });

    const now = formatDate(new Date().toISOString(), 'YYYY-MM-DD');

    nextEvents.onDemandEvents = _.map(nextEvents.onDemandEvents, item => {
      const startTime = formatDate(item.startDate, 'HH:mm:ss');
      const newStartDate = new Date(`${now} ${startTime}`);
      return { ...item, start: newStartDate };
    });

    return nextEvents;
  }, [isListView, events]);

  const handleSelectLocation = (nextLocation: any) => {
    const nextFilterLocation = nextLocation || ({} as TUserLocation);

    if (isMyList) {
      setDefaultTaskFilterLocation(nextFilterLocation);
    }

    setTaskFilterLocation(nextFilterLocation);
    setCommonFilter((prevFilter: any) => ({
      ...prevFilter,
      locationId: nextFilterLocation?.externalId,
      roleId: undefined,
      userId: undefined,
    }));
  };

  const handleMyListChange = (_isMyList: boolean) => {
    const nextFilterLocation = _isMyList ? defaultLocation : ({} as TUserLocation);
    setIsMyList(_isMyList);
    setTaskFilterLocation(nextFilterLocation);
    setCommonFilter((prevFilter: any) => ({
      ...prevFilter,
      locationId: nextFilterLocation?.externalId,
      roleId: undefined,
      userId: undefined,
    }));
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        header: 'min-h-header h-header sm:h-header sm:min-h-header border-b-1',
        wrapper: 'min-h-0',
      }}
      content={
        <div className="absolute w-full">
          <TaskHeader
            title="Calendar"
            isHideLocation={!isMyList}
            location={location}
            onSelectLocation={handleSelectLocation}
          />

          <div className="p-24 bg-paper">
            <EmployeeCalendar
              isFetching={isFetching}
              isListView={isListView}
              isMyList={isMyList}
              canViewUserCalendar={canViewUserCalendar}
              view={viewMode}
              events={events}
              listViewEvents={listViewEvents}
              dayView={dayView}
              dayRange={dayRange}
              filter={commonFilter}
              setFilter={setCommonFilter}
              onView={setMode}
              onReload={refetchEmployeeCalendars}
              onViewDateChange={setDayView}
              onListViewChange={setIsListView}
              onMyListChange={handleMyListChange}
              onSelectLocation={handleSelectLocation}
              onDayRangeChange={setDayRange}
              timezone={location?.timezone}
            />
          </div>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default Index;
