import { Typography } from '@mui/material';

const HEADERS = [
  { label: 'Selected Days', className: 'col-span-5' },
  { label: 'Opening Hours', className: 'col-span-3' },
  { label: 'Period', className: 'col-span-2' },
  { label: 'Override', className: 'col-span-2' },
];

const ItemHeader = () => {
  return (
    <div className="grid grid-cols-12 px-24 pb-8">
      {HEADERS.map((item, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={item.className}
        >
          <Typography className="text-13 font-600 text-secondaryLight">{item.label}</Typography>
        </div>
      ))}
    </div>
  );
};

export default ItemHeader;
