import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function DuplicateIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        d="M8.30776 6.69224C7.88292 6.69224 7.53852 7.03664 7.53852 7.46148C7.53852 7.88631 7.88292 8.23071 8.30776 8.23071H14.4617C14.8865 8.23071 15.2309 7.88631 15.2309 7.46148C15.2309 7.03664 14.8865 6.69224 14.4617 6.69224H8.30776Z"
        fill="currentcolor"
      />
      <path
        d="M7.53852 10.5384C7.53852 10.1136 7.88292 9.76919 8.30776 9.76919H14.4617C14.8865 9.76919 15.2309 10.1136 15.2309 10.5384C15.2309 10.9633 14.8865 11.3077 14.4617 11.3077H8.30776C7.88292 11.3077 7.53852 10.9633 7.53852 10.5384Z"
        fill="currentcolor"
      />
      <path
        d="M8.30776 12.8461C7.88292 12.8461 7.53852 13.1905 7.53852 13.6154C7.53852 14.0402 7.88292 14.3846 8.30776 14.3846H11.3847C11.8095 14.3846 12.1539 14.0402 12.1539 13.6154C12.1539 13.1905 11.8095 12.8461 11.3847 12.8461H8.30776Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00005 3.6153C5.15038 3.6153 4.46158 4.30409 4.46158 5.15377V17.4616C4.46158 18.3112 5.15038 19 6.00005 19H16.7694C17.619 19 18.3078 18.3112 18.3078 17.4616V5.15377C18.3078 4.30409 17.619 3.6153 16.7694 3.6153H6.00005ZM16.7694 5.15377H6.00005L6.00005 17.4616H12.5864L16.7694 12.93V5.15377ZM16.7694 17.4616H14.6801L16.7694 15.1982V17.4616Z"
        fill="currentcolor"
      />
      <path
        d="M13.077 1.76918C13.077 1.34434 12.7326 0.999939 12.3078 0.999939H3.53848C2.69232 0.999939 2.00001 1.69225 2.00001 2.53841L2 14.077C2 14.5018 2.3444 14.8462 2.76924 14.8462C3.19407 14.8462 3.53847 14.5018 3.53847 14.077L3.53848 2.53841H12.3078C12.7326 2.53841 13.077 2.19401 13.077 1.76918Z"
        fill="currentcolor"
      />
    </SvgIcon>
  );
}

export default React.memo(DuplicateIcon);
