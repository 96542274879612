import useShowMessage from 'app/hooks/use-show-message';
import { TResponsePagination, TTaskBasic } from 'models';
import { useSelector } from 'react-redux';
import api from 'utils/api';
import { isAdmin, isManager } from 'utils/role';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { employeeCalendarKeys } from './employee/calendar';
import { employeeTaskKeys } from './employee/tasks';
import { facilityTaskKeys } from './facility/tasks';
import { managerCalendarKeys } from './manager/calendar';
import { useGetList } from './request';

const adminTaskURL = '/api/employee/tasks';

export const onDemandTaskKeys = {
  all: ['onDemandTaskKeys'] as const,
  lists: () => [...onDemandTaskKeys.all, 'list'] as const,
  list: (filters: any) => [...onDemandTaskKeys.lists(), { filters }] as const,
  details: () => [...onDemandTaskKeys.all, 'detail'] as const,
  detail: (id: number) => [...onDemandTaskKeys.details(), id] as const,
};

export function useOnDemandTasks(filters: { orgRoleId: number; externalLocationId: number }) {
  return useGetList<TResponsePagination<TTaskBasic>>(
    [...onDemandTaskKeys.list(filters)],
    `${adminTaskURL}/on_demand`,
    {
      'q[location_id_eq]': filters.externalLocationId,
      'q[org_role_id_eq]': filters.orgRoleId,
      page: 1,
      search: '',
      per_page: 200,
    },
    {
      refetchOnMount: true,
      enabled: !!filters.externalLocationId && !!filters.orgRoleId,
    },
  );
}

export function useCreateOnDemandTask() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; description: string; orgRoleId: number; locationId: number }) => {
      return api.post(adminTaskURL, { task: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(employeeTaskKeys.all);
      },
    },
  );
}

export function useDeleteOnDemandTask() {
  const queryClient = useQueryClient();

  const userRoles = useSelector(({ auth }: { auth: { user: { role: string[] } } }) => auth?.user?.role);
  const canDeleteOnDemandTask = isAdmin(userRoles) || isManager(userRoles);

  const { showSuccess, showError } = useShowMessage();

  const mutation = useMutation((id: number) => api.delete(`api/manager/tasks/on_demand/${id}`), {
    onSuccess: () => {
      showSuccess('On-demand task has been successfully deleted');
      queryClient.invalidateQueries(managerCalendarKeys.all);
      queryClient.invalidateQueries(employeeCalendarKeys.all);
      queryClient.invalidateQueries(facilityTaskKeys.lists());
      queryClient.invalidateQueries(employeeTaskKeys.lists());
    },
    onError: () => {
      showError('Could not delete on-demand task');
    },
  });

  return {
    ...mutation,
    canDeleteOnDemandTask,
  } as const;
}
