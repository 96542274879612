import clsx from 'clsx';
import React from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

type Props = {
  children: JSX.Element[] | JSX.Element;
  onDragEnd?: (values: DropResult) => void;
  isDropDisabled?: boolean;
  styleParent?: string;
  isHorizontal?: boolean;
};

function DragOrder({ onDragEnd, children, isDropDisabled, styleParent, isHorizontal }: Props) {
  const handleDragEnd = (result: DropResult) => {
    if (onDragEnd) {
      onDragEnd(result);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        droppableId="droppable"
        isDropDisabled={isDropDisabled}
        direction={isHorizontal ? 'horizontal' : 'vertical'}
      >
        {droppableProvided => (
          <div
            ref={droppableProvided.innerRef}
            className={clsx('w-full p-8 drag-list', styleParent)}
          >
            {children}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragOrder;
