import clsx from 'clsx';
import { Moment } from 'moment';
import { forwardRef } from 'react';

import { DatePicker as DatePickerMui, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

const DatePicker = forwardRef((props: DatePickerProps<Moment>, ref: any) => {
  return (
    <DatePickerMui
      inputRef={ref}
      slotProps={{
        textField: {
          size: 'small',
          variant: 'filled',
          InputProps: {
            disableUnderline: true,
            className: 'bg-paper rounded-8',
            classes: {
              input: 'py-10 rounded-8 bg-paper',
            },
          },
        },
      }}
      className={clsx('w-full border-transparent bg-paper rounded-8', props.className)}
      format={props.format || 'DD/MM/YYYY'}
      {...props}
    />
  );
});

export default DatePicker;
