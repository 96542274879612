import clsx from 'clsx';

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  tabIndex: number;
  onTabChange?: (tabIndex: number) => void;
  onAddQuestion?: () => void;
  onAddSection?: () => void;
};

function TemplateHeader({ tabIndex, onTabChange, onAddQuestion, onAddSection }: Props) {
  const classes = useStyles();
  const showTaskQuestionActions = tabIndex === 1;

  const isSelectedTab = (currentTabIndex: number) => currentTabIndex === tabIndex;

  const handleTabChange = (nextTabIndex: number) => () => {
    onTabChange?.(nextTabIndex);
  };

  return (
    <div
      className={clsx(
        'flex items-center justify-between sticky top-0 z-10 bg-paper py-16 sm:py-20 pl-24 pr-8',
        classes.root,
      )}
    >
      <div className="flex items-center space-x-8">
        <Button
          disableElevation={true}
          variant="contained"
          className={clsx(
            'h-32 rounded-32 text-13 font-600 capitalize px-16 md:px-24 shadow-sm',
            classes.button,
            isSelectedTab(0) && classes.buttonSelected,
          )}
          endIcon={<InfoOutlinedIcon />}
          onClick={handleTabChange(0)}
        >
          Info
        </Button>
        <Button
          disableElevation={true}
          variant="contained"
          className={clsx(
            'h-32 rounded-32 text-13 font-600 capitalize px-16 md:px-24 shadow-sm',
            classes.button,
            isSelectedTab(1) && classes.buttonSelected,
          )}
          endIcon={<CreateOutlinedIcon />}
          onClick={handleTabChange(1)}
        >
          Task Questions
        </Button>
        <Button
          disableElevation={true}
          variant="contained"
          className={clsx(
            'h-32 rounded-32 text-13 font-600 capitalize px-16 md:px-24 shadow-sm',
            classes.button,
            isSelectedTab(2) && classes.buttonSelected,
          )}
          onClick={handleTabChange(2)}
        >
          Preview
        </Button>
      </div>

      {showTaskQuestionActions && (
        <div className="flex items-center space-x-8">
          <Button
            disableElevation={true}
            variant="contained"
            className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
            onClick={onAddQuestion}
          >
            Add Question
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
            onClick={onAddSection}
          >
            Add Section
          </Button>
        </div>
      )}
    </div>
  );
}

export default TemplateHeader;

const useStyles: any = makeStyles((theme: any) => {
  return {
    root: {
      width: 'calc(100% + 32px)',
      marginLeft: -24,
    },
    button: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        color: theme.palette.background.default,
      },
    },
    buttonSelected: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
    },
  };
});
