import DisplayTime from 'app/components/cores/display-time/display-time-v2';
import useToggle from 'app/hooks/use-toggle';
import { generateDefaultDisplayTime, getFrequency } from 'domains/display-time.domain';
import { generateFieldName } from 'domains/task.domain';
import { TTask } from 'models';
import { useMemo, useState } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

import DisplayTimePreview from './display-times/preview';

type Props = {
  name: string;
  methods: UseFormReturn<TTask>;
  index: number;
};

function TaskDisplayTimesSetting({ name, methods, index }: Props) {
  const control = methods?.control as any;
  const isNonFrequencySetting = index < 0;
  const displayTimeName = isNonFrequencySetting ? name : generateFieldName('displayTimes', name);
  const startTimeName = generateFieldName('startTime', name);
  const endTimeName = generateFieldName('endTime', name);
  const { state: isOpenPreviewModal, setActive: showPreviewModal, setInactive: hidePreviewModal } = useToggle(false);
  const [selectedDisplayTime, setSelectedDisplayTime] = useState(null);

  const { fields, remove, append } = useFieldArray({
    control,
    name: displayTimeName,
  });

  const startTime = useWatch({ name: startTimeName, control });
  const endTime = useWatch({ name: endTimeName, control });
  const recurringRule = useWatch({ name: `${name}.recurringRule`, control });

  const frequency = useMemo(() => getFrequency(recurringRule), [recurringRule]);

  const handleAddDisplayTime = () => {
    append(generateDefaultDisplayTime());

    setTimeout(() => {
      const displayTimes = document.querySelector('.displayTimes');
      displayTimes?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handlePreviewDisplayTime = (previewDisplayTime: any) => {
    setSelectedDisplayTime(previewDisplayTime);
    showPreviewModal();
  };

  const handleRemoveDisplayItem = (removedIndex: number) => {
    remove(removedIndex);
  };

  const handleClosePreviewModal = () => {
    hidePreviewModal();
    setSelectedDisplayTime(null);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <Typography className="text-small-dark">Display Time</Typography>
        <Button
          className="h-32 px-10 capitalize whitespace-no-wrap text-12 font-500"
          color="primary"
          variant="contained"
          onClick={handleAddDisplayTime}
        >
          New Display Time
        </Button>
      </div>

      <div className="mt-24 displayTimes">
        {fields.map((item: any, displayTime: number) => (
          <DisplayTime
            key={item.id}
            index={displayTime}
            name={`${displayTimeName}.${displayTime}`}
            control={control}
            methods={methods}
            frequency={frequency}
            isUseOpeningTime={!isNonFrequencySetting}
            onPreview={handlePreviewDisplayTime}
            onRemove={() => handleRemoveDisplayItem(displayTime)}
          />
        ))}
      </div>
      <DisplayTimePreview
        open={isOpenPreviewModal}
        displayTime={selectedDisplayTime}
        assignmentStartTime={startTime}
        assignmentEndTime={endTime}
        frequency={frequency}
        onClose={handleClosePreviewModal}
      />
    </div>
  );
}

export default TaskDisplayTimesSetting;
