import Header from 'app/components/Layout/headers/blank-header-v2';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import TagForm from 'app/components/tags/form';
import { useGetTag, useUpdateTag } from 'app/hooks/api/tags';
import useShowMessage from 'app/hooks/use-show-message';
import { TTag } from 'models';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    status: yup.string(),
  })
  .required();

interface ParamsTypes {
  tagId: string;
}
function Edit() {
  // Common
  const history = useHistory();
  const { showError, showSuccess } = useShowMessage();

  const routeParams = useParams<ParamsTypes>();
  const { tagId: stringTaskId } = routeParams;
  const tagId = Number(stringTaskId);

  // Display
  const [tabValue] = useState(0);

  // Data
  const { data: tag } = useGetTag(tagId);
  const { mutateAsync: updateTag, isLoading } = useUpdateTag(tagId);
  const form = useForm<TTag>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // Effect
  useEffect(() => {
    form.reset(tag);
  }, [tag, form]);

  // Functions
  const handleBackClick = () => {
    history.goBack();
  };

  const handleCancelButton = () => {
    handleBackClick();
  };

  const handleUpdateTag = (value: Partial<TTag>) => {
    const payload = { tag: value };
    updateTag(payload)
      .then(() => {
        showSuccess('Tag has been successfully updated');
      })
      .catch((res: any) => {
        let statusError: string | null = null;
        res?.errors.forEach((error: any) => {
          const message = `${error?.field} ${error?.message?.[0]}`;
          if (error?.field === 'status') {
            statusError = message;
          }
          form.setError(error?.field, { type: 'custom', message });
        });
        showError(statusError || 'Could not update Tag');
      });
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header border-b',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <Typography className="ml-16 text-18 font-600 line-clamp-1">{tag?.name}</Typography>
            </div>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  <HeadButtonRight
                    className="whitespace-no-wrap"
                    onClick={handleCancelButton}
                    btnTitle="Cancel"
                  />
                  <HeadButtonRight
                    className="ml-4 whitespace-no-wrap"
                    variant="contained"
                    onClick={form.handleSubmit(handleUpdateTag)}
                    btnTitle="Save"
                  />
                </div>
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <Tabs
          value={tabValue}
          indicatorColor="secondary"
          textColor="primary"
          scrollButtons="auto"
          classes={{ root: 'w-full h-48 relative' }}
        >
          <Tab
            className="h-48 normal-case font-400"
            label="Tag Settings"
          />
        </Tabs>
      }
      content={
        <div className="w-full h-full p-16 sm:p-24 bg-paper">
          <FormProvider {...form}>
            <TagForm
              tabIndex={tabValue}
              type="edit"
            />
          </FormProvider>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default Edit;
