import DataSelect from 'app/components/cores/data-select';
import { NOTIFICATION_TYPES, TOGGLE_OPTIONS } from 'constants/index';
import { generateFieldName } from 'domains/task.domain';
import _ from 'lodash';
import { TOption } from 'models';
import { useEffect, useState } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import SendNotificationToRole from './assignment/send-notification-to-role';
import SendNotificationToUser from './assignment/send-notification-to-user';
import SkipSaltInbox from './assignment/skip-salt-inbox';

type Props = {
  name?: string;
  control: Control;
};

function TaskNotification({ name, control }: Props) {
  const notificationSettingName = generateFieldName('notificationSettings', name);
  const notificationAvailableName = generateFieldName('notificationAvailable', name);

  const [activeType, setActiveType] = useState('');

  const {
    field: { value: notificationAvailable, onChange },
  } = useController({ name: notificationAvailableName, control });

  const {
    fields: notificationSettings,
    append,
    remove,
  } = useFieldArray({
    control,
    name: notificationSettingName,
  });

  // effect to set default active type
  useEffect(() => {
    if (!notificationAvailable) return;

    // already active
    if (activeType) {
      const isActive = _.find(notificationSettings, { type: activeType });
      if (isActive) return;
    }

    // set active for first setting
    const firstType = NOTIFICATION_TYPES[0].value;
    const firstSetting = _.find(notificationSettings, { type: firstType });

    if (firstSetting) {
      setActiveType(firstType);
    } else {
      const firstNotificationSettingItem = notificationSettings?.[0] as any;
      setActiveType(firstNotificationSettingItem?.type);
    }
  }, [notificationSettings, notificationAvailable, activeType]);

  const getFieldIndex = (type: string) => {
    return _.findIndex(notificationSettings as any, { type });
  };

  const isFieldActive = (index: number) => {
    return !!notificationSettings?.[index];
  };

  const handleToggleNotificationType = (type: string) => () => {
    const fieldIndex = getFieldIndex(type);

    if (fieldIndex > -1) {
      remove(fieldIndex);
    } else {
      append({
        type,
        sendToUsers: [],
        sendToUsersDetail: [],
        sendToRoles: [],
        sendToRolesDetail: [],
      });
    }
  };

  return (
    <div>
      <div className="w-128">
        <DataSelect
          value={`${notificationAvailable || false}`}
          options={TOGGLE_OPTIONS}
          onChange={onChange}
        />
      </div>

      <Typography className="mt-8 text-small-light">
        Select trigger action: submitted, incomplete or late submission, and who to notify
      </Typography>

      {notificationAvailable && (
        <div className="mt-16">
          {NOTIFICATION_TYPES?.map((option: TOption) => {
            const fieldIndex = getFieldIndex(option.value as string);
            const isActive = isFieldActive(fieldIndex);
            return (
              <div key={option.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActive}
                      checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                      icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
                    />
                  }
                  label={<Typography className="text-12 font-400">{option.label}</Typography>}
                  onChange={handleToggleNotificationType(option.value as string)}
                />

                {isActive && (
                  <div className="pl-32 mb-16">
                    <div className="py-16">
                      <Typography className="mb-4 text-small-light">Send Notification To Role(s)</Typography>
                      <SendNotificationToRole
                        name={`${notificationSettingName}.${fieldIndex}`}
                        control={control}
                      />
                      <div className="ml-4">
                        <SkipSaltInbox
                          name={`${notificationSettingName}.${fieldIndex}.skipRoleInbox`}
                          control={control}
                          label="Skip SALT Inbox for assigned role(s)"
                        />
                      </div>
                    </div>

                    <div>
                      <Typography className="mb-4 text-small-light">Send Notification To User(s)</Typography>
                      <SendNotificationToUser
                        name={`${notificationSettingName}.${fieldIndex}`}
                        control={control}
                      />
                      <div className="ml-4">
                        <SkipSaltInbox
                          name={`${notificationSettingName}.${fieldIndex}.skipUserInbox`}
                          control={control}
                          label="Skip SALT Inbox for assigned user(s)"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TaskNotification;
