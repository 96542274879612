import DragAndDrop from 'app/components/cores/drag-and-drop';
import clsx from 'clsx';
import _ from 'lodash';
import { TMedia } from 'models';
import { Accept } from 'react-dropzone';
import { Control, useController } from 'react-hook-form';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Button, IconButton, Typography } from '@mui/material';

type Props = {
  name: string;
  control?: Control;
  uploadZipFile?: boolean;
  acceptFiles: Accept;
  children?: JSX.Element;
  multiple?: boolean;
  classes?: { [key: string]: any };
  onLoading?: (loading: boolean) => void;
  isPublic?: boolean;
  readOnly?: boolean;
};

const FileUploadField = ({
  name,
  control,
  uploadZipFile,
  acceptFiles,
  children,
  multiple,
  classes,
  isPublic,
  readOnly = false,
  onLoading,
}: Props) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (media: TMedia) => {
    const nextValue = _.cloneDeep(value || []);
    const files = [media?.file];
    nextValue.push({ ...media, files });
    onChange(nextValue);
  };

  const handleDeleteFile = (fileId: number) => () => {
    const nextValue = _.cloneDeep(value || []);
    const removedItemIndex = _.findIndex(nextValue, { id: fileId });

    if (removedItemIndex < 0) {
      return;
    }

    nextValue[removedItemIndex]._destroy = true;
    onChange(nextValue);
  };

  return (
    <div className={clsx('flex flex-col w-full', readOnly && 'pointer-events-none')}>
      <div className="flex w-full">
        <DragAndDrop
          uploadZipFile={uploadZipFile}
          onChange={handleChange}
          acceptFiles={acceptFiles}
          multiple={multiple}
          classes={{ root: classes?.dragAndDropRoot }}
          onLoading={onLoading}
          isPublic={isPublic}
        >
          <div className="flex flex-row items-center w-full h-64 bg-paper rounded-4">
            <Button
              className="h-32 ml-16 text-white uppercase w-104 text-10 font-400"
              variant="contained"
              color="primary"
            >
              Browse
            </Button>
            <Typography className="px-8 text-13 font-300 text-secondaryLight">
              Drag and drop file here or browse
            </Typography>
          </div>
        </DragAndDrop>
      </div>

      <div className="mt-8">
        {value?.map(
          (item: any) =>
            !item._destroy && (
              <div
                key={item.id}
                className="flex items-center"
              >
                <IconButton
                  className="p-6"
                  onClick={handleDeleteFile(item.id)}
                >
                  <DeleteOutlineOutlinedIcon className="text-16" />
                </IconButton>
                <a
                  href={item?.files?.[0].url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center ml-8 !bg-white !border-0 !underline"
                >
                  <Typography className="text-11 text-secondaryLight hover:text-secondaryMain">
                    {item?.files?.[0]?.name}
                  </Typography>
                  <OpenInNewOutlinedIcon className="ml-4 text-12 text-secondaryLight" />
                </a>
              </div>
            ),
        )}
      </div>

      <div>{children}</div>
      {!value && error && (
        <div className="flex w-full pt-4 error-message">
          <Typography className="text-errorMain text-10">There is an error, please check</Typography>
        </div>
      )}
    </div>
  );
};

export default FileUploadField;
