import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import { TOption } from 'models/option.model';
import * as React from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type TValueData = { id: number; name: string };

type Props = {
  modalTitle: string;
  value?: TValueData[] | TValueData;
  options?: TOption[];
  disabled?: boolean;
  placeholder?: string;
  placeholderStyle?: string;
  rightIcon?: React.ReactNode;
  onClickPlaceholder?: () => void;
};

function SelectBox({
  modalTitle,
  options = [],
  disabled = false,
  placeholder = '',
  placeholderStyle,
  rightIcon,
  value = [],
  onClickPlaceholder,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const isEmptyValue = _isEmpty(value);

  const valueLabel =
    React.useMemo(() => {
      if (Array.isArray(value))
        return (value as TValueData[])
          .filter(Boolean)
          ?.map(val => val?.name)
          ?.join(', ');
      return (value as TValueData)?.name;
    }, [value]) || '';

  const handleClickOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = (_: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleClickOpen}
        className="flex items-center justify-between w-full px-16 py-8 bg-gray-200 min-h-40 rounded-20"
      >
        {isEmptyValue ? (
          <Typography
            className={clsx('text-13 text-secondaryLight', placeholderStyle)}
            onClick={onClickPlaceholder}
          >
            {placeholder}
          </Typography>
        ) : (
          <Typography className={clsx('text-13 text-secondaryMain font-bold text-left')}>{valueLabel}</Typography>
        )}
        {rightIcon ?? <ArrowDropDownOutlinedIcon className="text-24 text-secondaryLight" />}
      </button>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className="text-center">{modalTitle}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {/* TODO: implement later */}
            {options?.length > 0 && options.map(option => <div key={option.value}>{option.label}</div>)}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SelectBox;
