import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';

interface IProps {
  classes: any;
  header: JSX.Element;
}

function FusePageSimpleHeader(props: IProps) {
  const mainTheme = useSelector(selectMainTheme);

  return (
    <div className={props.classes.header}>
      {props.header && <ThemeProvider theme={mainTheme}>{props.header}</ThemeProvider>}
    </div>
  );
}

export default FusePageSimpleHeader;
