import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import Index from './index';

const CalendarPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.employee,
  routes: [
    {
      path: `${Paths.calendar}`,
      exact: true,
      component: Index,
    },
  ],
};

export default CalendarPagesConfig;
