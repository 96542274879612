import {
  Area,
  BarCode,
  CheckMark,
  DateTime,
  Employee,
  Media,
  MultipleChoice,
  Numeric,
  QrCode,
  Rating,
  SaltOpsForm,
  Section,
  Signature,
  Text,
  YesNo,
} from 'app/components/templates/questions';
import clsx from 'clsx';
import _omit from 'lodash/omit';
import { questionsLayoutView } from 'models';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  flagged: {
    border: '1px solid #F5D02F',
    borderRadius: '8px',
  },
}));

type Props = {
  question: any;
  location?: string;
  name: string;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const ResponseMap = ({ question, location, name, isAudit, canAuditInteractions = true }: Props) => {
  const { employeeTaskId } = useParams<{ employeeTaskId: string }>();
  const classes = useStyles();

  const getQuestionComponent = (type: string) => {
    const mapping = new Map();
    mapping.set('Responses::YesNo', YesNo);
    mapping.set('Responses::Numeric', Numeric);
    mapping.set('Responses::Rating', Rating);
    mapping.set('Responses::MultipleChoice', MultipleChoice);
    mapping.set('Responses::CheckMark', CheckMark);
    mapping.set('Responses::Media', Media);
    mapping.set('Responses::Area', Area);
    mapping.set('Responses::DateTime', DateTime);
    mapping.set('Responses::Text', Text);
    mapping.set('Responses::Employee', Employee);
    mapping.set('Responses::SaltOpsForm', SaltOpsForm);
    mapping.set('Responses::BarCode', BarCode);
    mapping.set('Responses::QrCode', QrCode);
    mapping.set('Responses::Signature', Signature);
    mapping.set('Responses::Section', Section);

    return mapping.get(type);
  };

  const QuestionComponent = getQuestionComponent(question?.type);

  const isEditing = useWatch({ name: `${name}-isEditing` });

  return (
    <div
      className={clsx(
        question.type !== 'Responses::Section' && 'px-24 py-8 break-inside-avoid',
        isAudit && question.flagged && classes.flagged,
      )}
    >
      {QuestionComponent && (
        <QuestionComponent
          key={name}
          name={name}
          data={question.item}
          answer={question.answer}
          readOnly={!isEditing}
          user={question.user}
          task={{ ..._omit(question, 'item'), location }}
          listId={+employeeTaskId}
          view={questionsLayoutView.HISTORY}
          isAudit={isAudit}
          canAuditInteractions={canAuditInteractions}
        />
      )}
    </div>
  );
};
export default ResponseMap;
