import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

function useObjectBulkAction<T>(
  useActionHook: any,
  objectName: string,
  actionName: string,
  title?: string,
  parentId?: number,
) {
  const dispatch = useDispatch();
  const { mutateAsync: doAction } = useActionHook(parentId);
  const handleAction = (objects: T[]) => {
    const { length } = objects;
    Promise.all(
      objects.map((object: T, index) => {
        const isNotLastObject = index < length - 1;
        if (isNotLastObject) {
          return doAction(object);
        }
        return doAction({ ...object, skipReload: false });
      }),
    )
      .then(() => {
        dispatch(showMessage({ message: `${actionName} the ${objectName} has been successfully`, variant: 'success' }));
      })
      .catch(() => {
        dispatch(showMessage({ message: `Could not ${actionName} the ${objectName}`, variant: 'error' }));
      });
  };

  return (objects: T[]) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title={`${title || actionName} ${objectName}`}
            message={`Are you sure you want to ${actionName} this ${objectName}?`}
            statusVariant="warning"
            confirmButtonLabel={actionName}
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              handleAction(objects);
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };
}
export default useObjectBulkAction;
