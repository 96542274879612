import { PerformanceLayout } from 'app/components/locations/tabs/performance';
import PerformanceExportingModal from 'app/components/locations/tabs/performance/exporting/exporting-modal';
import PerformanceContext from 'app/components/performances/context';
import PerformanceHeader from 'app/components/performances/header';
import useTaskFilter from 'app/hooks/use-task-filter';
import { LIST_VALUE, PERIOD_OPTIONS } from 'constants/performance';
import { TDateRange, TUserLocation } from 'models';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAdmin } from 'utils/role';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';

function PerformanceIndex() {
  const printRef = useRef();
  const { managerLocationIds, role: roles } = useSelector(({ auth }: any) => auth.user);
  const { location, setTaskFilterLocation } = useTaskFilter();
  const [period, setPeriod] = useState<string>(PERIOD_OPTIONS[0].value);
  const [statsType, setStatsType] = useState<string>(LIST_VALUE);

  const isFetchingPerformance = useIsFetching({ queryKey: ['performance'] }) > 0;

  const todayRange = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  };

  const [customPeriod, setCustomPeriod] = useState<TDateRange>(todayRange);

  const memorizedContextState = useMemo(() => ({ statsType, setStatsType }), [statsType, setStatsType]);

  const hasPerformancePermission = isAdmin(roles) || managerLocationIds.includes(location?.externalId);

  const setCustom = (range: TDateRange) => {
    setCustomPeriod({ startDate: range.startDate, endDate: range.endDate });
  };

  const handleSelectLocation = (nextLocation: TUserLocation) => {
    setTaskFilterLocation(nextLocation);
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      content={
        <PerformanceContext.Provider value={memorizedContextState}>
          <div className="absolute w-full h-full bg-paper">
            <PerformanceExportingModal
              period={period}
              customPeriod={customPeriod}
              locationId={location?.externalId ?? 0}
              locationName={location?.name || ''}
              ref={printRef}
            />
            <PerformanceHeader
              title={`${location?.name ?? ''} Performance`}
              location={location}
              onSelectLocation={handleSelectLocation}
              period={period}
              setPeriod={setPeriod}
              tmpPeriod={customPeriod}
              setCustomPeriod={setCustom}
              printRef={printRef}
              isFetchingPerformance={isFetchingPerformance}
            />
            {hasPerformancePermission ? (
              <div className="mt-2">
                <PerformanceLayout
                  locationName={location?.name ?? ''}
                  period={period}
                  customPeriod={customPeriod}
                  locationId={location?.externalId ?? 0}
                />
              </div>
            ) : (
              <div className="flex absolute justify-center items-center w-full h-full">
                <Typography className="text-13">You are not a manager of this location.</Typography>
              </div>
            )}
          </div>
        </PerformanceContext.Provider>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default PerformanceIndex;
