import { ITEM_TYPES_MAP } from 'constants/index';
import * as yup from 'yup';

export const templateFullSchema = yup
  .object({
    name: yup.string().required('Template title is required'),
    status: yup.string(),
    draftVersion: yup.object().shape({
      itemsAttributes: yup.array().of(
        yup.object().shape({
          prompt: yup.string().required('Question is required'),
          type: yup.string().required('Type is required'),
          multipleChoiceOptions: yup
            .array()
            .of(
              yup.object({
                label: yup.string().required(),
                value: yup.string().trim().required('Please input the choice'),
              }),
            )
            .when(['type', '_destroy'], {
              is: (type: string, _destroy: boolean) => !_destroy && type === ITEM_TYPES_MAP.MULTIPLE_CHOICE,
              then: schema => schema.min(2, 'Multiple choice options have to greater or equal 2'),
              otherwise: schema => schema.nullable(),
            }),
          code: yup.string().when(['type', '_destroy'], {
            is: (type: any, _destroy: boolean) =>
              !_destroy && [ITEM_TYPES_MAP.BAR_CODE, ITEM_TYPES_MAP.QR_CODE].includes(type),
            then: schema => schema.trim().required('Code is required'),
            otherwise: schema => schema.nullable(),
          }),
        }),
      ),
    }),
  })
  .required();
