import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type Props = {
  text: string;
  list: {
    group: boolean;
    frequency: boolean;
  };
};

const ListCell = ({ text, list }: Props) => {
  return (
    <div className="flex items-center space-x-8">
      <Typography>{text}</Typography>
      {list?.group && (
        <IconButton
          className="bg-paper"
          size="small"
        >
          <GroupsOutlined className=" text-16" />
        </IconButton>
      )}
      {list?.frequency && (
        <IconButton
          className="bg-paper"
          size="small"
        >
          <FormatListBulletedIcon className=" text-16" />
        </IconButton>
      )}
    </div>
  );
};
export default ListCell;
