import clsx from 'clsx';
import { components as ReactSelectComponents } from 'react-select';

import { Typography } from '@mui/material';

import QuestionIconType from '../question-icon-type';

function TypeOptionValue(props: any) {
  const {
    isDisabled,
    data: { value },
  } = props;
  const Icon = QuestionIconType(value);

  return (
    <ReactSelectComponents.SingleValue {...props}>
      <div className="flex items-center">
        {Icon && (
          <Icon
            color="primary"
            className={clsx('mr-12 text-18', isDisabled && 'text-secondaryLight')}
          />
        )}
        <Typography className={clsx('text-13 font-700', isDisabled ? 'text-secondaryLight' : 'text-secondaryMain')}>
          {props.children}
        </Typography>
      </div>
    </ReactSelectComponents.SingleValue>
  );
}

export default TypeOptionValue;
