import queryClient from 'app/configs/query-client';
import _ from 'lodash';
import queryString from 'query-string';

import { useGetOne } from '../request';

const employeeTaskURL = '/api/admin/employee_tasks';

export const adminEmployeeKeys = {
  all: ['adminCalendarTask'] as const,
  lists: () => [...adminEmployeeKeys.all, 'list'] as const,
  list: (filters: any) => [...adminEmployeeKeys.lists(), { filters }] as const,
  details: () => [...adminEmployeeKeys.all, 'detail'] as const,
  detail: (id: number, filters?: any) => [...adminEmployeeKeys.details(), id, filters] as const,
};

export const refetchTask = () => queryClient.invalidateQueries(adminEmployeeKeys.all);

export function useGetAdminEmployeeTask(taskId?: number, filters?: any, options?: any) {
  let url = `${employeeTaskURL}/${taskId}`;

  if (!_.isEmpty(filters)) {
    const query = queryString.stringify(filters);
    url = `${url}?${query}`;
  }

  return useGetOne<any>(
    [...adminEmployeeKeys.detail(Number(taskId))],
    url,
    {},
    {
      enabled: !!taskId,
      cacheTime: 0,
      ...options,
    },
    true,
  );
}
