import { TOpeningTime } from 'models';

import { CircularProgress, Typography } from '@mui/material';

import ItemContent from './opening/content';
import ItemHeader from './opening/header';
import HolidayItemContent from './opening/holiday-content';

type Props = {
  openingTimes?: TOpeningTime[];
  isLoading?: boolean;
};

const OpeningTimes = ({ openingTimes, isLoading }: Props) => {
  if (!Array.isArray(openingTimes) || openingTimes?.length === 0) {
    return (
      <div className="w-full p-24 italic text-center text-11">Operating times not configured for this location</div>
    );
  }

  const openingTimesWithCategory = openingTimes?.reduce(
    (acc: any, item: TOpeningTime) => {
      return item?.publicHolidayId
        ? { ...acc, holidays: [...acc.holidays, item] }
        : { ...acc, normalDays: [...acc.normalDays, item] };
    },
    { holidays: [], normalDays: [] },
  );

  return (
    <div className="relative">
      <div className="pt-24 pb-24">
        <ItemHeader />
        {openingTimesWithCategory.normalDays?.map((item: TOpeningTime, i: number) => (
          <ItemContent
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            item={item}
          />
        ))}

        {!openingTimesWithCategory?.normalDays.length && (
          <Typography className="px-24 text-11">No opening times in this location</Typography>
        )}

        {openingTimesWithCategory?.holidays.length > 0 && (
          <div className="px-24">
            <Typography className="pb-8 mt-16 text-13 font-600 text-secondaryLight">Holidays</Typography>
          </div>
        )}

        {openingTimesWithCategory.holidays?.map((item: TOpeningTime, i: number) => (
          <HolidayItemContent
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            item={item}
          />
        ))}
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-25 z-99">
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};

export default OpeningTimes;
