import { ITEM_TYPES_MAP } from 'constants/index';
import { TItem, TItemType } from 'models';
import { Control } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { MultipleCondition } from './multiple-condition';
import { SingleCondition } from './single-condition';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  onClearConditional?: () => void;
};

export function ConditionDisplaySwitch({ name, control, selectedConditionalItem, onClearConditional }: Props) {
  const taskItemType = selectedConditionalItem?.type;
  const isMultipleConditions = ([ITEM_TYPES_MAP.NUMERIC] as TItemType[]).includes(taskItemType);

  if (isMultipleConditions) {
    return (
      <MultipleCondition
        control={control}
        name={name}
        selectedConditionalItem={selectedConditionalItem}
        onClearConditional={onClearConditional}
      />
    );
  }

  return (
    <SingleCondition
      key={uuidv4()}
      control={control}
      name={name}
      selectedConditionalItem={selectedConditionalItem}
      onClearConditional={onClearConditional}
    />
  );
}
