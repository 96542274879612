import ErrorMessage from 'app/components/cores/form/error-message';
import { LogicSelect } from 'app/components/cores/items-editor/conditional-logic/display/logic-select';
import { TItem } from 'models';
import { Control, useController, useWatch } from 'react-hook-form';

import { Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  instructionText?: string;
};

export function SingleCondition({
  control,
  name,
  selectedConditionalItem,
  instructionText = 'Display this, if answer is',
}: Props) {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ control, name: `${name}` });

  const currentItem = useWatch({ control, name: `${name}` });

  const errorMessage = error?.message;

  const index = 0;
  const conditionalComparison = currentItem?.[index] as any;

  const handleChangeConditional = (item: any) => {
    const conditionalLogic = item.conditionalLogic?.split('::');
    const nextLogic = conditionalLogic?.[0];
    const nextValue = conditionalLogic?.[1] || '';

    onChange([
      {
        ...conditionalComparison,
        ...item,
        logic: nextLogic,
        value: nextValue,
      },
    ]);
  };

  return (
    <div>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">{instructionText}</Typography>
        <LogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={conditionalComparison}
          placeholder="Select conditional"
          onChange={handleChangeConditional}
        />
      </div>

      <ErrorMessage message={errorMessage} />
    </div>
  );
}
