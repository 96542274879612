import React from 'react';

import { Tooltip, Typography } from '@mui/material';

type Props<T extends {}> = {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  icon: React.ReactNode;
  keyExtractor: (item: T) => string;
  getItemName: (item: T) => string;
  limit?: number;
  sign?: string;
};

const ToggleList = <T extends {}>({
  data = [],
  limit = 3,
  renderItem,
  icon,
  keyExtractor,
  sign,
  getItemName,
}: Props<T>) => {
  const overLimit = data.length > limit;

  if (overLimit) {
    const tooltipItems = data.map(item => `${sign}${getItemName(item)}`);
    return (
      <Tooltip
        title={tooltipItems.join(', ')}
        className="cursor-pointer"
      >
        <Typography
          component="span"
          color="primary"
          className="ml-8 text-13 font-600"
        >
          {data.length}
          {icon}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <>
      {data.map(item => (
        <React.Fragment key={keyExtractor(item)}>{renderItem(item)}</React.Fragment>
      ))}
    </>
  );
};

export default ToggleList;
