import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const pxToRem = (px: number, oneRemPx = 17) => `${px / oneRemPx}rem`;

const useStyles = makeStyles((theme: any) => {
  const borderWidth = 2;
  const width = pxToRem(52);
  const height = pxToRem(28);
  const size = pxToRem(16);
  const gap = (28 - 16) / 2;

  return {
    root: {
      width,
      height,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'unset',
    },
    switchBase: {
      padding: pxToRem(gap),
      '&$checked': {
        color: theme.palette.primary.main,
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        '& + $track': {
          backgroundColor: theme.palette.common.white,
          opacity: 1,
          borderRadius: 40,
          border: `solid ${theme.palette.primary.main}`,
          borderWidth,
        },
        '& $thumb': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    track: {
      borderRadius: 40,
      border: `solid ${theme.palette.grey[400]}`,
      borderWidth,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      boxSizing: 'border-box',
    },
    thumb: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[400],
      width: size,
      height: size,
    },
    checked: {},
  };
});

function Switch(props: SwitchProps) {
  const styles = useStyles();

  return (
    <MuiSwitch
      {...props}
      classes={styles}
    />
  );
}

export default Switch;
