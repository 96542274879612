import {
  ASSIGNMENT_FIELDS,
  ASSIGNMENT_FIELDS_MAP,
  NOTIFICATION_FIELDS,
  SHOW_SWEEP_LIST_SETTING,
} from 'constants/index';
import _ from 'lodash';
import { TOption } from 'models';
import { Control, useController } from 'react-hook-form';
import { clsxm } from 'utils/clsxm';

import { Button } from '@mui/material';

type Props = {
  name: string;
  control?: Control;
  onAddNew?: (fieldName: string) => void;
};

function VariationAssignmentFields({ name, control, onAddNew }: Props) {
  const {
    field: { value: customisedSettings, onChange },
  } = useController({
    control,
    name,
  });

  const hiddenFields = SHOW_SWEEP_LIST_SETTING ? ['kind'] : ['kind', 'sweep'];
  const assignmentFields = _.filter(ASSIGNMENT_FIELDS, field => !hiddenFields.includes(field.value));

  const handleClickNotificationField = (value: string) => {
    const isSelected = !!customisedSettings?.includes(value);
    let nextCustomisedSettings = _.cloneDeep(customisedSettings || []);

    if (isSelected) {
      _.forEach(NOTIFICATION_FIELDS, (field: string) => {
        _.pull(nextCustomisedSettings, field);
      });
    } else {
      nextCustomisedSettings = [...nextCustomisedSettings, ...NOTIFICATION_FIELDS];
    }

    onChange(nextCustomisedSettings);
  };

  const handleClick = (value: string) => () => {
    if (value === ASSIGNMENT_FIELDS_MAP.NOTIFICATION_AVAILABLE) {
      handleClickNotificationField(value);
      return;
    }

    const isSelected = !!customisedSettings?.includes(value);
    const nextCustomisedSettings = _.cloneDeep(customisedSettings || []);

    if (!nextCustomisedSettings?.includes(value)) {
      onAddNew?.(value);
    }

    if (isSelected) {
      _.pull(nextCustomisedSettings, value);
      if (value === ASSIGNMENT_FIELDS_MAP.ENABLE_ON_CLOSED_DAY) {
        _.pull(nextCustomisedSettings, 'closed_day_display_times');
      }
    } else {
      nextCustomisedSettings.push(value);
      if (value === ASSIGNMENT_FIELDS_MAP.ENABLE_ON_CLOSED_DAY) {
        nextCustomisedSettings.push('closed_day_display_times');
      }
    }

    onChange(nextCustomisedSettings);
  };

  const isSelectedField = (selectedCustomisedSettings: string[], value: string) => {
    return !!selectedCustomisedSettings?.includes(value);
  };

  return (
    <div>
      {assignmentFields?.map((item: TOption) => (
        <Button
          key={item.value}
          disableElevation={true}
          variant="contained"
          color="inherit"
          className={clsxm(
            'text-11 font-400 capitalize mr-8 mb-8 rounded-16',
            isSelectedField(customisedSettings, item.value as string) && '!bg-warning',
          )}
          onClick={handleClick(item.value as string)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
}

export default VariationAssignmentFields;
