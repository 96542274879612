import ErrorMessage from 'app/components/cores/form/error-message';
import { TItem } from 'models';
import { Control, useController } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

import { LogicSelect } from './logic-select';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  onClearConditional?: () => void;
  instructionText?: string;
};

export function SingleCondition({
  control,
  name,
  selectedConditionalItem,
  onClearConditional,
  instructionText = 'Display this, if answer is',
}: Props) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: `${name}.conditionalComparison` });

  const errorMessage = error?.message;

  const index = 0;
  const conditionalComparison = value?.[index] as any;

  const handleChangeConditional = (item: any) => {
    const conditionalLogic = item.conditionalLogic?.split('::');
    const nextLogic = conditionalLogic?.[0];
    const nextValue = conditionalLogic?.[1] || '';
    onChange([
      {
        ...conditionalComparison,
        ...item,
        logic: nextLogic,
        value: nextValue,
      },
    ]);
  };

  const handleRemoveAllConditional = () => {
    onClearConditional?.();
  };

  return (
    <div>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">{instructionText}</Typography>
        <LogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={conditionalComparison}
          placeholder="Select conditional"
          onChange={handleChangeConditional}
        />
      </div>

      <ErrorMessage message={errorMessage} />

      <div className="flex items-center justify-end mt-8 space-x-4">
        <Button
          disableElevation={true}
          variant="outlined"
          color="error"
          className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
          onClick={handleRemoveAllConditional}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
