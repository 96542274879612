import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { refetchTask, refetchTasks } from 'app/hooks/api/employee/tasks';
import { useDeleteOnDemandTask } from 'app/hooks/api/on-demand-tasks';
import useTaskUrl from 'app/hooks/use-task-url';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { isCompletedRequiredTasks } from 'domains/employee/task.domain';
import { TEmployeeTaskItem } from 'models/employee/task.model';
import { useMemo } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { displayTime } from 'utils/date';

import { DeleteOutline } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

import HeaderGroupUsers from './header-group-users';

type Props = {
  list?: TEmployeeTaskItem;
  readOnly?: boolean;
  onRefresh?: () => void;
  onSubmit?: () => void;
  setSelectedList?: (newList: TEmployeeTaskItem | null) => void;
};

// TODO display refresh time, group user
function TaskContentHeader({ list, readOnly, onRefresh, onSubmit, setSelectedList }: Props) {
  const dispatch = useDispatch();
  const isOnDemand = list?.onDemand;
  const listStatus = list?.status;
  const isUpcoming = listStatus === 'upcoming';
  const isOverdue = listStatus === 'overdue';
  const isSweepList = !!list?.sweep;
  const isFixedList = list?.kind === 'fixed';

  const formContext = useFormContext();
  const { clearEmployeeListId } = useTaskUrl();
  const {
    mutate: deleteOnDemandTask,
    canDeleteOnDemandTask,
    isLoading: isDeletingOnDemandTask,
  } = useDeleteOnDemandTask();

  const countDownColor = useMemo(() => {
    if (listStatus === 'upcoming') return 'text-taskUpcoming';
    return 'text-white';
  }, [listStatus]);

  const countDownBgColor = useMemo(() => {
    if (listStatus === 'overdue') return 'bg-taskOverdue';
    if (listStatus === 'in_progress') return 'bg-taskInProgress';
    if (listStatus === 'pending') return 'bg-taskPending';
    if (listStatus === 'upcoming') return 'bg-paper';
    return 'bg-paper';
  }, [listStatus]);

  const updatedResponses = formContext?.watch('responses');

  const isCompletedAllRequiredTasks = useMemo(() => {
    if (!isSweepList) return true;
    return isCompletedRequiredTasks(updatedResponses);
  }, [isSweepList, updatedResponses]);

  const getCountDownTime = () => {
    if (!list) return undefined;

    if (isOverdue) return list?.expireAt;
    if (isUpcoming) return list?.displayAt;

    return list?.dueAt;
  };

  const countDownTime = getCountDownTime();

  const submit = () => {
    onSubmit?.();
  };

  const handleSubmit = () => {
    if (readOnly) return;
    const title = !isSweepList || isCompletedAllRequiredTasks ? 'Submit Report' : 'Submit Completed Tasks';
    const message =
      !isSweepList || isCompletedAllRequiredTasks
        ? 'Are you sure you want to submit?'
        : 'Are you sure you want to submit completed tasks';

    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title={title}
            message={message}
            statusVariant="warning"
            cancelButtonLabel="No, Cancel"
            confirmButtonLabel="Yes, Submit"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              return formContext.handleSubmit(submit)();
            }}
          />
        ),
      }),
    );
  };

  const statusCheck = (completed: boolean) => {
    const showDialog = (title: string, message: string) => {
      dispatch(
        openDialog({
          children: (
            <ConfirmDialog
              title={title}
              message={message}
              statusVariant="warning"
              confirmButtonLabel="Ok"
              hideCancelButton={true}
              onClose={() => {
                clearEmployeeListId();
                onRefresh?.();
                setSelectedList?.(null);
                dispatch(closeDialog({}));
              }}
              onConfirm={() => {
                clearEmployeeListId();
                onRefresh?.();
                setSelectedList?.(null);
                dispatch(closeDialog({}));
              }}
            />
          ),
        }),
      );
    };

    if (isOverdue && isFixedList) {
      return;
    }

    if (listStatus === 'expired') {
      showDialog('Expired List', 'The list you are looking for is expired');
      return;
    }

    if (listStatus === 'completed') {
      showDialog('Completed List', 'The list you are looking for is completed');
      return;
    }

    if (!completed) {
      return;
    }

    if (listStatus) {
      refetchTask();
    }

    if (!listStatus) {
      setSelectedList?.(null);
    }

    refetchTasks();
  };

  const handleCloseConfirmDialog = () => {
    dispatch(closeDialog({}));
  };

  const handleConfirm = () => {
    deleteOnDemandTask(Number(list?.id), {
      onSuccess: () => {
        handleCloseConfirmDialog();
        setSelectedList?.(null);
      },
    });
  };

  const handleClickDeleteOnDemandTask = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete On-Demand Task"
            message="Are you sure you want to delete this on-demand task?"
            onClose={handleCloseConfirmDialog}
            onConfirm={handleConfirm}
            isLoading={isDeletingOnDemandTask}
            confirmButtonLabel="Delete"
          />
        ),
      }),
    );
  };

  const renderCountdown = ({ hours, minutes, seconds, completed, days }: CountdownRenderProps) => {
    statusCheck(completed);
    const totalHours = days * 24 + hours;

    return (
      <Typography className={clsx('text-12', countDownColor)}>
        {completed ? '00h : 00m : 00s' : `${totalHours}h : ${minutes}m : ${seconds}s`}
      </Typography>
    );
  };

  return (
    <div className="flex flex-col p-16 bg-white md:px-32 border-b-1 border-selected">
      <div className="flex items-center">
        <div className="flex flex-col flex-1 pr-16">
          <Typography className="uppercase text-18 font-600">{list?.task?.name}</Typography>

          <div className="flex items-center mt-4 space-x-8">
            {list && (
              <Typography className="flex-initial text-14 font-500 text-secondaryLight">
                {displayTime(list?.displayAt, list?.dueAt)}
              </Typography>
            )}

            {isOnDemand && canDeleteOnDemandTask && (
              <Tooltip
                title="Delete On-Demand Task"
                placement="top"
              >
                <IconButton onClick={handleClickDeleteOnDemandTask}>
                  <DeleteOutline
                    className="text-20"
                    color="primary"
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {!readOnly && (
          <div className="flex items-center justify-end">
            {!readOnly && (
              <IconButton
                color="secondary"
                className="mr-8"
                onClick={() => {
                  onRefresh?.();
                }}
              >
                <RefreshSharpIcon className="text-20 text-secondary" />
              </IconButton>
            )}

            <div className="items-center hidden md:flex">
              {!isOnDemand && (
                <div
                  className={clsx(
                    'flex items-center justify-center h-40 mr-12 rounded-4',
                    isUpcoming ? 'w-160' : 'w-128',
                    countDownBgColor,
                  )}
                >
                  {isUpcoming && (
                    <Typography className="pr-8 mb-2 text-12 font-500 text-secondaryMain">Start in</Typography>
                  )}
                  <Countdown
                    key={`${list?.id}-${list?.status}`}
                    date={countDownTime ?? Date.now()}
                    renderer={renderCountdown}
                  />
                </div>
              )}

              {!isUpcoming ? (
                <Button
                  id="submit-task"
                  disabled={isUpcoming}
                  variant="outlined"
                  color="primary"
                  className="h-40 uppercase text-13 font-400 min-w-128"
                  onClick={handleSubmit}
                >
                  {!isSweepList || isCompletedAllRequiredTasks ? 'Submit' : 'Submit Completed Tasks'}
                </Button>
              ) : (
                <Box
                  className="flex items-center justify-center w-32 h-32 rounded-full"
                  sx={{
                    border: '1px solid #ECEDEE',
                  }}
                >
                  <LockOutlinedIcon
                    sx={{
                      fontSize: 16,
                      color: '#7C808B',
                    }}
                  />
                </Box>
              )}
            </div>
          </div>
        )}
      </div>

      {/* small screen */}
      <div className="flex items-center my-8 md:hidden">
        {!isOnDemand && (
          <div
            className={clsx(
              'flex items-center justify-center h-40 mr-12 rounded-4',
              isUpcoming ? 'w-160' : 'w-128',
              countDownBgColor,
            )}
          >
            {isUpcoming && <Typography className="pr-8 mb-2 text-12 font-500 text-secondaryMain">Start in</Typography>}
            <Countdown
              key={`${list?.id}-${list?.status}`}
              date={countDownTime ?? Date.now()}
              renderer={renderCountdown}
            />
          </div>
        )}

        {!isUpcoming ? (
          <Button
            id="submit-task"
            disabled={isUpcoming}
            variant="outlined"
            color="primary"
            className="flex-1 h-40 uppercase text-13 font-400"
            onClick={handleSubmit}
          >
            {!isSweepList || isCompletedAllRequiredTasks ? 'Submit' : 'Submit Completed Tasks'}
          </Button>
        ) : (
          <Box
            className="flex items-center justify-center w-32 h-32 rounded-full"
            sx={{
              border: '1px solid #ECEDEE',
            }}
          >
            <LockOutlinedIcon
              sx={{
                fontSize: 16,
                color: '#7C808B',
              }}
            />
          </Box>
        )}
      </div>

      <div className="flex justify-between">
        <div>{!list?.task?.individual && <HeaderGroupUsers users={list?.users} />}</div>
        {isSweepList && (
          <div className="flex justify-end mt-12">
            <Typography className="text-13 font-500 text-secondaryLight">
              Sweep List - Partial Submission Accepted
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskContentHeader;
