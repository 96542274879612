import TextField from '@mui/material/TextField';

type Props = {
  comment?: string;
  preview?: boolean;
};

const TaskComment = ({ comment, preview }: Props) => {
  return (
    <TextField
      multiline
      variant="filled"
      placeholder="(Optional) Add a comment ..."
      fullWidth
      className="bg-gray-20"
      sx={{
        textarea: {
          fontSize: '11px',
        },
        '& .Mui-disabled': {
          backgroundColor: 'white !important',
          WebkitTextFillColor: '#7C808B !important',
        },
        '& .MuiFilledInput-root': {
          padding: '4px !important',

          '&:before, &:after': {
            display: 'none',
          },
        },
      }}
      disabled={preview}
      value={comment || ''}
    />
  );
};
export default TaskComment;
