import { useMemo } from 'react';

type Props = {
  customisedSettings: string[];
  fieldName: string | string[];
  children: React.ReactNode;
};

function TaskVariationVisibleCustomizeField({ customisedSettings, fieldName, children }: Props) {
  const isCustomizedField = useMemo(() => {
    if (typeof fieldName === 'string') {
      return customisedSettings?.includes(fieldName);
    }
    return !!fieldName?.find(f => customisedSettings?.includes(f));
  }, [fieldName, customisedSettings]);

  return !isCustomizedField ? null : <div className="py-16">{children}</div>;
}

export default TaskVariationVisibleCustomizeField;
