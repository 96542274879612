export const DISPLAY_TIME_FORMAT = 'h:mm A';

export const TIME_FORMAT = 'HH:mm';

export const START_TIME_OPTIONS = [
  { label: 'Use Location Opening Hour', value: true },
  { label: 'Customize Start Time', value: false },
];

export const END_TIME_OPTIONS = [
  { label: 'Use Location Closing Hour', value: true },
  { label: 'Customize End Time', value: false },
];

export const METRIC_OPTIONS = [
  { label: 'Minute(s)', value: '60' },
  { label: 'Hour(s)', value: '3600' },
];

export const POSITION_OPTIONS = [
  { label: 'After', value: 'after' },
  { label: 'Before', value: 'before' },
];

export const DEFAULT_START_TIME = '07:00';

export const DEFAULT_END_TIME = '17:00';

export const DEFAULT_START_AFTER = 0;

export const DEFAULT_END_AFTER = 0;

export const DEFAULT_INTERVAL = 3600;

export const THIS_YEAR = new Date().getFullYear();

export const LOCALE_DATE_FORMAT = 'DD/MM/YYYY';
