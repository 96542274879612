import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

function useObjectAction<T>(
  useActionHook: any,
  objectName: string,
  actionName: string,
  title?: string,
  parentId?: number,
) {
  const dispatch = useDispatch();
  const { mutateAsync: doAction } = useActionHook(parentId);
  const handleAction = (object: T) => {
    doAction(object)
      .then(() => {
        dispatch(showMessage({ message: `${actionName} the ${objectName} has been successfully`, variant: 'success' }));
      })
      .catch(() => {
        dispatch(showMessage({ message: `Could not ${actionName} the ${objectName}`, variant: 'error' }));
      });
  };

  return (object: T) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title={`${title || actionName} ${objectName}`}
            message={`Are you sure you want to ${actionName} this ${objectName}?`}
            statusVariant="warning"
            confirmButtonLabel={actionName}
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              handleAction(object);
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };
}
export default useObjectAction;
