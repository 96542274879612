import queryClient from 'app/configs/query-client';
import { TAssignment, TAssignmentResponse, TResponsePagination, TTask, TTaskBasic } from 'models';

import { useAction, useCreate, useDelete, useGetInfiniteList, useGetList, useGetOne, useUpdate } from './request';

const taskURL = '/api/admin/tasks';

export const taskKeys = {
  all: ['tasks'] as const,
  lists: () => [...taskKeys.all, 'list'] as const,
  list: (filters: any) => [...taskKeys.lists(), { filters }] as const,
  details: () => [...taskKeys.all, 'detail'] as const,
  detail: (id: number) => [...taskKeys.details(), id] as const,
  infinite: () => [...taskKeys.all, 'infinite'] as const,
};

export const refetchTask = async () => {
  return queryClient.invalidateQueries(taskKeys.all);
};

export function useGetTasks(filters: any, options = {}) {
  return useGetList<TResponsePagination<TTaskBasic>>([...taskKeys.list(filters)], `${taskURL}`, filters, {
    refetchOnMount: false,
    ...options,
  });
}

export function useGetTask(taskId: number) {
  return useGetOne<TTask>(
    [...taskKeys.detail(taskId)],
    `${taskURL}/${taskId}`,
    {},
    {
      enabled: !!taskId,
      cacheTime: 0,
    },
  );
}

export function useCreateTask() {
  return useCreate<{ task: Partial<TTask> }, TTask>(`${taskURL}`, {
    onSuccess: () => refetchTask(),
  });
}

export function useUpdateTask(taskId: number) {
  return useUpdate<{ task: Partial<TTask> }, TTask>(`${taskURL}/${taskId}`, {
    onSuccess: () => refetchTask(),
  });
}

export function useDeactivateTask() {
  return useAction(`${taskURL}`, {
    onSuccess: () => refetchTask(),
  });
}

export function useActivateTask() {
  return useAction(`${taskURL}`, {
    onSuccess: () => refetchTask(),
  });
}

export function useUpdateTaskDraftVersion(taskId: number) {
  return useUpdate<{ task: Partial<TTask> }, TTask>(`${taskURL}/${taskId}/update_draft_version`, {
    onSuccess: () => refetchTask(),
  });
}

export function useCreateTaskDraftVersion(taskId: number) {
  return useCreate<undefined, TTask>(`${taskURL}/${taskId}/create_draft_version`, {
    onSuccess: () => refetchTask(),
  });
}

export function usePublishTaskDraftVersion(taskId: number) {
  return useCreate<{ task: Partial<TTask> }, TTask>(`${taskURL}/${taskId}/publish_draft_version`, {
    onSuccess: () => refetchTask(),
  });
}

export function useCreateVariation(taskId: number, options?: any) {
  return useCreate<{ assignment: TAssignment }, TAssignmentResponse>(`${taskURL}/${taskId}/assignment`, options);
}

export function useUpdateAssignment(taskId: number, options?: any) {
  return useCreate<{ assignment: TAssignment }, TAssignmentResponse>(`${taskURL}/${taskId}/assignment`, options);
}

export function useDeleteVariation(taskId: number, options?: any) {
  return useDelete(`${taskURL}/${taskId}/assignment`, options);
}

export function useInfiniteQueryTasks(filters: { per_page: number; status: string }, options = {}) {
  const {
    data = [],
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetInfiniteList(taskKeys.infinite(), taskURL, filters, options);

  const convertedData = data.map((item: any) => ({ ...item, value: item.id, label: item.name }));

  return {
    data: convertedData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
