import { formatFrequencyRule, generateDefaultRule, updateSettings } from 'domains/frequency.domain';
import {
  TFrequencyChangedData,
  TFrequencyDaily,
  TFrequencyMonthly,
  TFrequencyRuleFormat,
  TFrequencySetting,
  TFrequencyWeekly,
  TFrequencyYearly,
} from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Frequency as RRuleFrequency } from 'rrule';

import { FrequencyType, FrequencyTypeSwitch } from './ui';

type Props = {
  value?: string;
  onRuleFormat?: (formattedRule: TFrequencyRuleFormat) => void;
  onError?: (ruleErrorString: string) => void;
  onChange?: ({ frequency, settings }: TFrequencyChangedData) => void;
};

export function Frequency({ value, onRuleFormat, onError, onChange }: Props) {
  const [frequency, setFrequency] = useState<RRuleFrequency | undefined>();
  const [settings, setSettings] = useState<TFrequencySetting>({} as TFrequencySetting);

  useEffect(() => {
    const {
      frequency: defaultFrequency,
      settings: defaultSettings,
      error: ruleError,
    } = generateDefaultRule(formatFrequencyRule(value));
    setFrequency(defaultFrequency);
    setSettings(defaultSettings);

    if (ruleError) {
      onError?.(ruleError);
    }
  }, [value, onError]);

  const handleFrequencyChange = (nextFrequency: RRuleFrequency) => {
    setFrequency(nextFrequency);
    onChange?.({ frequency: nextFrequency as RRuleFrequency, settings });
  };

  const handleDataChange = (
    nextFrequencySettings: TFrequencyYearly | TFrequencyMonthly | TFrequencyWeekly | TFrequencyDaily,
  ) => {
    const nextSettings = updateSettings(frequency as RRuleFrequency, settings, nextFrequencySettings);
    setSettings(nextSettings);
    onChange?.({ frequency: frequency as RRuleFrequency, settings: nextSettings });
  };

  const handleRuleFormat = useCallback(
    (formattedRule: TFrequencyRuleFormat) => {
      onRuleFormat?.(formattedRule);
    },
    [onRuleFormat],
  );

  return (
    <div>
      <FrequencyType
        frequency={frequency}
        onChange={handleFrequencyChange}
      />
      <FrequencyTypeSwitch
        frequency={frequency}
        settings={settings}
        onChange={handleDataChange}
        onRuleFormat={handleRuleFormat}
      />
    </div>
  );
}
