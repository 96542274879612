export const QUESTION_TYPES = [
  { type: 'Items::Area', label: 'Area' },
  { type: 'Items::BarCode', label: 'Bar Code' },
  { type: 'Items::CheckMark', label: 'Check Mark' },
  { type: 'Items::DateTime', label: 'Date Time' },
  { type: 'Items::Employee', label: 'Employee' },
  { type: 'Items::Media', label: 'Media' },
  { type: 'Items::MultipleChoice', label: 'Multiple Choice' },
  { type: 'Items::Numeric', label: 'Numeric' },
  { type: 'Items::QrCode', label: 'QR Code' },
  { type: 'Items::Rating', label: 'Rating' },
  { type: 'Items::SaltOpsForm', label: 'Salt Ops Form' },
  { type: 'Items::Signature', label: 'Signature' },
  { type: 'Items::Text', label: 'Text' },
  { type: 'Items::YesNo', label: 'Yes No' },
] as const;

export const QUESTION_TYPE_OPTIONS = QUESTION_TYPES.map(({ label, type }) => ({
  label,
  value: type,
}));

export const ITEM_SECTION = { type: 'Items::Section', label: 'Section' };

export const ITEM_TYPES = [...QUESTION_TYPES, ITEM_SECTION];

export const ITEM_TYPES_MAP = {
  AREA: 'Items::Area',
  BAR_CODE: 'Items::BarCode',
  CHECK_MARK: 'Items::CheckMark',
  DATE_TIME: 'Items::DateTime',
  EMPLOYEE: 'Items::Employee',
  MEDIA: 'Items::Media',
  MULTIPLE_CHOICE: 'Items::MultipleChoice',
  NUMERIC: 'Items::Numeric',
  QR_CODE: 'Items::QrCode',
  RATING: 'Items::Rating',
  SALT_OPS_FORM: 'Items::SaltOpsForm',
  SECTION: 'Items::Section',
  SIGNATURE: 'Items::Signature',
  TEXT: 'Items::Text',
  YES_NO: 'Items::YesNo',
} as const;

export const BULK_IMPORT_OPTIONS = [
  { value: 'Yes/No', label: 'Yes/No', type: ITEM_TYPES_MAP.YES_NO, extraOptions: {}, extraLabel: '' },
  { value: 'Photo', label: 'Photo', type: ITEM_TYPES_MAP.MEDIA, extraOptions: {} },
  { value: 'Media', label: 'Media', type: ITEM_TYPES_MAP.MEDIA, extraOptions: {} },
  { value: 'Checkmark', label: 'Check Mark', type: ITEM_TYPES_MAP.CHECK_MARK, extraOptions: {} },
  { value: 'Measurement', label: 'Measurement', type: ITEM_TYPES_MAP.NUMERIC, extraOptions: {} },
  { value: 'Numeric', label: 'Numeric', type: ITEM_TYPES_MAP.NUMERIC, extraOptions: {} },
  { value: 'Subtitle', label: 'Subtitle', type: ITEM_TYPES_MAP.SECTION, extraOptions: {} },
  { value: 'Section', label: 'Section', type: ITEM_TYPES_MAP.SECTION, extraOptions: {} },
  { value: 'Free Response', label: 'Free Response', type: ITEM_TYPES_MAP.TEXT, extraOptions: {} },
  { value: 'Text', label: 'Text', type: ITEM_TYPES_MAP.TEXT, extraOptions: {} },
  { value: 'MultipleChoice', label: 'Multiple Choice', type: ITEM_TYPES_MAP.MULTIPLE_CHOICE, extraOptions: {} },
  { value: 'Area', label: 'Area', type: ITEM_TYPES_MAP.AREA, extraOptions: {} },
  { value: 'QrCode', label: 'Qr Code', type: ITEM_TYPES_MAP.QR_CODE, extraOptions: {} },
  { value: 'BarCode', label: 'Bar Code', type: ITEM_TYPES_MAP.BAR_CODE, extraOptions: {} },
  { value: 'Rating', label: 'Rating', type: ITEM_TYPES_MAP.RATING, extraOptions: {} },
  { value: 'DateTime', label: 'Date Time', type: ITEM_TYPES_MAP.DATE_TIME, extraOptions: {} },
  { value: 'Employee', label: 'Employee', type: ITEM_TYPES_MAP.EMPLOYEE, extraOptions: {} },
  { value: 'Signature', label: 'Signature', type: ITEM_TYPES_MAP.SIGNATURE, extraOptions: {} },
  { value: 'SaltOpsForm', label: 'Salt Ops Form', type: ITEM_TYPES_MAP.SALT_OPS_FORM, extraOptions: {} },
];

export const CONDITIONAL_DEFAULT_OPTIONS = [
  { conditionalLogic: 'custom', conditionalLabel: 'Custom...' },
  { conditionalLogic: 'completed', conditionalLabel: 'Completed' },
  { conditionalLogic: 'not_completed', conditionalLabel: 'Not Completed' },
  { conditionalLogic: 'not_applicable', conditionalLabel: 'Not Applicable' },
] as const;

export const CONDITIONAL_NUMERIC_OPTIONS = [
  { conditionalLogic: 'eq', conditionalLabel: 'Equal to' },
  { conditionalLogic: 'gt', conditionalLabel: 'Greater than' },
  { conditionalLogic: 'gteq', conditionalLabel: 'Greater than or equal to' },
  { conditionalLogic: 'lt', conditionalLabel: 'Less than' },
  { conditionalLogic: 'lteq', conditionalLabel: 'Less than or equal to' },
] as const;

export const GROUP_OPTIONS = [
  { label: 'Location', value: 'location_list' },
  { label: 'Group', value: 'group_list' },
  { label: 'Individual', value: 'individual_list' },
];

export const TASK_TYPE_OPTIONS = [
  { label: 'Recurring', value: 'recurring' },
  { label: 'Fixed', value: 'fixed' },
];

export const ON_DEMAND_OPTIONS = [
  { label: 'Disable', value: false },
  { label: 'Enable', value: true },
];

export const ASSIGNMENT_FIELDS_MAP = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  START_TIME: 'start_time',
  END_TIME: 'end_time',
  RECURRING_RULE: 'recurring_rule',
  DISPLAY_TIMES: 'display_times',
  FREQUENCIES: 'frequencies',
  ASSIGNMENT_ORG_ROLES_ATTRIBUTES: 'assignment_org_roles_attributes',
  REPORT_TO_ROLES: 'report_to_roles',
  NOTIFICATION_AVAILABLE: 'notification_available',
  NOTIFICATION_SETTINGS: 'notification_settings',
  SEND_TO_ROLES: 'send_to_roles',
  SEND_TO_USERS: 'send_to_users',
  INDIVIDUAL: 'individual',
  KIND: 'kind',
  LIST_TYPE: 'list_type',
  ON_DEMAND: 'on_demand',
  HOLIDAY: 'holiday_available',
  BLACKOUT: 'blackout_time_enable',
  GENERATE_BETWEEN_BREAK_TIME: 'generate_between_break_time',
  LIST_SUBMISSION: 'sweep',
  LIST_TRIGGER: 'conditional_task_settings',
  ENABLE_ON_CLOSED_DAY: 'enable_on_closed_day',
};

export const NOTIFICATION_FIELDS = [
  ASSIGNMENT_FIELDS_MAP.NOTIFICATION_AVAILABLE,
  ASSIGNMENT_FIELDS_MAP.NOTIFICATION_SETTINGS,
];

export const ASSIGNMENT_FIELDS = [
  { label: 'Start Date', value: 'start_date' },
  { label: 'End Date', value: 'end_date' },
  { label: 'Start Time', value: 'start_time' },
  { label: 'End Time', value: 'end_time' },
  { label: 'Frequency', value: 'frequencies' },
  { label: 'Assign Roles', value: 'assignment_org_roles_attributes' },
  { label: 'Report To', value: 'report_to_roles' },
  { label: 'Notifications', value: 'notification_available' },
  { label: 'List Type', value: 'list_type' },
  { label: 'List Completion Type', value: 'kind' },
  { label: 'On Demand', value: 'on_demand' },
  { label: 'Holiday', value: 'holiday_available' },
  { label: 'Blackout Period', value: 'blackout_time_enable' },
  {
    label: 'Break Time',
    value: 'generate_between_break_time',
  },
  {
    label: 'Closed Day',
    value: 'enable_on_closed_day',
  },
  { label: 'List Submission', value: 'sweep' },
  { label: 'List Trigger', value: 'conditional_task_settings' },
];

export const SALT_OPS_FORM_TYPE = {
  HEADCOUNT: 'headcount',
  POOLTEST: 'pooltest',
  MAINTENANCE: 'maintenance',
  HAZARD: 'hazard',
  INCIDENT: 'incident',
  WATER_CHEMISTRY: 'water_chemistry',
} as const;

export const NOTIFICATION_TYPES = [
  { label: 'List Submitted', value: 'task_submitted' },
  { label: 'List Incomplete', value: 'task_incomplete' },
  { label: 'Late Submission', value: 'late_submission' },
];

export const TASK_COLORS = {
  onTime: '#00A0D4',
  late: '#F5D02F',
  incomplete: '#E0115F',
  inProgress: '#60B57C',
  pending: '#7C808B',
} as const;

export const TASK_INACTIVE_COLORS = {
  onTime: 'rgba(0, 160, 212, 0.5)',
  late: 'rgba(245, 208, 47, 0.5)',
  incomplete: 'rgba(224, 17, 95, 0.5)',
  inProgress: 'rgba(96, 181, 124, 0.5)',
  pending: 'rgba(124, 128, 139, 0.5)',
} as const;

export const TASK_STATUSES = {
  onTime: 'onTime',
  late: 'late',
  incomplete: 'incomplete',
  inProgress: 'inProgress',
  pending: 'pending',
} as const;

export const LIST_SUBMISSION_OPTIONS = [
  { label: 'This list can be submitted only after all items are completed', value: false },
  { label: 'Individual list items can be submitted when complete', value: true },
];

export const SHOW_SWEEP_LIST_SETTING = true;

export const AUDIT_COLORS = {
  compliant: '#9C27B0',
  nonCompliant: '#FF9F5D',
} as const;
