import { SingleSelect } from 'app/components/cores/select';
import _ from 'lodash';
import { TSelectItem } from 'models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

type Props = {
  roleId: number;
  onChange?: (data: TSelectItem) => void;
};

function RoleSelect({ roleId, onChange }: Props) {
  const theme = useTheme();

  const roles = useSelector(({ auth }: any) => auth.user.roles);
  const roleOptions = useMemo(() => _.orderBy(roles, 'name'), [roles]);

  const value = roles.find((item: any) => item.externalId === roleId) || null;

  const handleChange = (item: any) => {
    onChange?.(item);
  };

  return (
    <SingleSelect
      value={value}
      options={roleOptions}
      maxMenuHeight={1000}
      menuPlacement="auto"
      noOptionsMessage={() => 'No Role'}
      placeholder="All Roles"
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.externalId}
      onChange={handleChange}
      isClearable={true}
      styles={{
        control: (baseStyles: any, state: any) => ({
          ...baseStyles,
          border: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 8,
          height: 40,
          minHeight: 40,
          boxShadow: 'none',
          cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: 'auto',
        }),
      }}
    />
  );
}

export default RoleSelect;
