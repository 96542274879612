import { ITEM_TYPES_MAP } from 'constants/index';

import { TLocation } from './location.model';
import { TFile } from './media.model';
import { TOption } from './option.model';

export type TItemType = (typeof ITEM_TYPES_MAP)[keyof typeof ITEM_TYPES_MAP];

type AttachmentsAttributes = {
  id: number;
  files: TFile[];
};

export type TItem = {
  id?: string;
  type: TItemType;
  prompt: string;
  description: string;
  time_to_complete: string;
  order: number;
  required: boolean;
  attachmentsAttributes: AttachmentsAttributes[];
  notApplicable: boolean;
  multipleChoiceOptions?: TOption[];
  conditionalId?: number;
  conditionalComparison?: TItemConditional[];
  employeeOptions?: TItemOption[];
  areaOptions?: TItemOption[];
  maxNumber?: number;
  locationIds?: number[];
  locations?: TLocation[];
  isAllLocation?: boolean;
  dateTimeType?: string;
  scoreEnabled?: boolean;
  scoreSettings?: TItemSoreSetting[];
  _destroy?: boolean;
};

export type TItemSoreSetting = {
  logic: string;
  score: number | string;
  value?: string;
  label?: string;
  timeType?: string;
};

export type TRatingItem = TItem & {
  minLabel: string;
  maxLabel: string;
  minNumber: number;
  maxNumber: number;
};

export type TDateTimeItem = TItem & {
  dateTimeType: 'date' | 'time' | 'datetime';
};

export type TSaltOpsFormItem = TItem & {
  formType: 'feedback' | 'hazard' | 'headcount' | 'incident' | 'maintenance' | 'water_chemistry' | 'pooltest';
};

export type TBarCodeItem = TItem & {
  code: string;
};

export type TMultipleChoiceItem = TItem & {
  multipleChoiceOptions: TOption[];
};

/**
Temperature Length Weight Capacity Symbol 
 */
export type TMeasurementType = 'temperature' | 'length' | 'weight' | 'capacity' | 'symbol' | 'custom';

// Temperature: Celsius (C) Fahrenheit (F)
export type TTemperatureUnit = 'c' | 'f';

// length: millimeter (mm) centimeter (cm) meter (m) kilometer (km)
export type TLengthUnit = 'mm' | 'cm' | 'm' | 'km';

// Weight: miligram (mg) gram (g) kilogram (kg)
export type TWeightUnit = 'mg' | 'g' | 'kg';

// Capacity: millilitre (ml)  liter (l)
export type TCapacityUnit = 'ml' | 'l';

// Symbol: percentage (%) Dollar ($)
export type TSymbolUnit = 'percentage' | 'dollar';

export type TUnit<T extends TMeasurementType> = T extends 'temperature'
  ? TTemperatureUnit
  : T extends 'length'
  ? TLengthUnit
  : T extends 'weight'
  ? TWeightUnit
  : T extends 'capacity'
  ? TCapacityUnit
  : T extends 'symbol'
  ? TSymbolUnit
  : never;

export type TUnitOption<T> = {
  label: string;
  value: T;
  group?: TMeasurementType;
  display?: string;
};

export const temperatureOptions: TUnitOption<TTemperatureUnit>[] = [
  { label: 'celsius', value: 'c', group: 'temperature', display: '°C' },
  { label: 'fahrenheit', value: 'f', group: 'temperature', display: '°F' },
];
export const lengthOptions: TUnitOption<TLengthUnit>[] = [
  { label: 'millimeter', value: 'mm', group: 'length', display: 'mm' },
  { label: 'centimeter', value: 'cm', group: 'length', display: 'cm' },
  { label: 'meter', value: 'm', group: 'length', display: 'm' },
  { label: 'kilometer', value: 'km', group: 'length', display: 'km' },
];
export const weightOptions: TUnitOption<TWeightUnit>[] = [
  { label: 'milligram', value: 'mg', group: 'weight', display: 'mg' },
  { label: 'gram', value: 'g', group: 'weight', display: 'g' },
  { label: 'kilogram', value: 'kg', group: 'weight', display: 'kg' },
];
export const capacityOptions: TUnitOption<TCapacityUnit>[] = [
  { label: 'milliliter', value: 'ml', group: 'capacity', display: 'ml' },
  { label: 'liter', value: 'l', group: 'capacity', display: 'l' },
];
export const symbolOptions: TUnitOption<TSymbolUnit>[] = [
  { label: 'percentage', value: 'percentage', group: 'symbol', display: '%' },
  { label: 'dollar', value: 'dollar', group: 'symbol', display: '$' },
];

export const UNIT_OPTIONS = {
  TEMP: temperatureOptions,
  LEN: lengthOptions,
  WEI: weightOptions,
  CAP: capacityOptions,
  SYM: symbolOptions,
};

export const groupOptions: {
  label: TMeasurementType;
  options: TUnitOption<TUnit<TMeasurementType>>[];
}[] = [
  { label: 'temperature', options: UNIT_OPTIONS.TEMP },
  { label: 'length', options: UNIT_OPTIONS.LEN },
  { label: 'weight', options: UNIT_OPTIONS.WEI },
  { label: 'capacity', options: UNIT_OPTIONS.CAP },
  { label: 'symbol', options: UNIT_OPTIONS.SYM },
];

export const MEASUREMENT_OPTIONS: { label: string; value: TMeasurementType }[] = [
  { label: 'Temperature', value: 'temperature' },
  { label: 'Length', value: 'length' },
  { label: 'Weight', value: 'weight' },
  { label: 'Capacity', value: 'capacity' },
  { label: 'Symbol', value: 'symbol' },
];

export const mapUnits = new Map<TMeasurementType, TUnitOption<TUnit<TMeasurementType>>[]>([
  ['temperature', UNIT_OPTIONS.TEMP],
  ['length', UNIT_OPTIONS.LEN],
  ['weight', UNIT_OPTIONS.WEI],
  ['capacity', UNIT_OPTIONS.CAP],
  ['symbol', UNIT_OPTIONS.SYM],
]);

export type TItemConditional = {
  logic: 'lt' | 'gt' | 'lteq' | 'gteq' | 'eq' | 'completed' | 'not_completed|' | 'not_applicable';
  value: string;
  conditionalLogic?: string;
  conditionalLabel?: string;
};

export type TItemOption = {
  id: number | string;
  name: string;
};

export type TItemConditionalOption = {
  conditionalLogic: string;
  conditionalLabel: string;
};
