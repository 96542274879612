import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import clsx from 'clsx';
import { TASK_TABS } from 'constants/tabs';

type Props = {
  isLoading?: boolean;
  isDraft?: boolean;
  havingDraftVersion?: boolean;
  tabValue?: number;
  onCancel?: () => void;
  onDraftTask?: () => void;
  onPublishTask?: () => void;
  onUpdateDraftQuestionVersion?: () => void;
  onPublishDraftQuestionVersion?: () => void;
  onCreateDraftVersion?: () => void;
  onSaveGlobalSetting?: () => void;
};

function TaskHeaderActions({
  isLoading,
  isDraft,
  havingDraftVersion,
  tabValue,
  onCancel,
  onDraftTask,
  onPublishTask,
  onUpdateDraftQuestionVersion,
  onPublishDraftQuestionVersion,
  onCreateDraftVersion,
  onSaveGlobalSetting,
}: Props) {
  if (isLoading) {
    return (
      <div>
        <HeadButtonRight
          className="mx-8 normal-case whitespace-no-wrap"
          disabled={true}
          isFetchingData={true}
        />
        <HeadButtonRight
          className="mx-8 normal-case whitespace-no-wrap"
          disabled={true}
          isFetchingData={true}
        />
      </div>
    );
  }

  // global setting tab
  if (tabValue === TASK_TABS.GLOBAL_SETTING.value) {
    return (
      <div>
        <HeadButtonRight
          className="whitespace-no-wrap"
          onClick={onCancel}
          btnTitle="Cancel"
        />
        <HeadButtonRight
          className="ml-4 whitespace-no-wrap"
          variant="contained"
          onClick={onSaveGlobalSetting}
          btnTitle="Save"
        />
        {isDraft && (
          <HeadButtonRight
            className="ml-4 whitespace-no-wrap"
            variant="contained"
            onClick={onPublishTask}
            btnTitle="Publish"
          />
        )}
      </div>
    );
  }

  // location setting tab
  if (tabValue === TASK_TABS.LOCATION.value) {
    const rightButtonAction = isDraft ? onDraftTask : onPublishTask;
    return (
      <div>
        <HeadButtonRight
          className="whitespace-no-wrap"
          onClick={onCancel}
          btnTitle="Cancel"
        />
        <HeadButtonRight
          className="ml-4 whitespace-no-wrap"
          variant="contained"
          onClick={rightButtonAction}
          btnTitle="Save"
        />
        {isDraft && (
          <HeadButtonRight
            className="ml-4 whitespace-no-wrap"
            variant="contained"
            onClick={onPublishTask}
            btnTitle="Publish"
          />
        )}
      </div>
    );
  }

  // info tab
  if (tabValue === TASK_TABS.INFO.value) {
    const leftButtonTitle = isDraft ? 'Draft' : 'Cancel';
    const leftButtonAction = isDraft ? onDraftTask : onCancel;
    const rightButtonTitle = isDraft ? 'Publish' : 'Save';
    return (
      <div>
        <HeadButtonRight
          className="whitespace-no-wrap"
          onClick={leftButtonAction}
          btnTitle={leftButtonTitle}
        />
        <HeadButtonRight
          className="ml-4 whitespace-no-wrap"
          variant="contained"
          onClick={onPublishTask}
          btnTitle={rightButtonTitle}
        />
      </div>
    );
  }

  // task list tab
  if (tabValue === TASK_TABS.QUESTION.value) {
    if (isDraft) {
      return (
        <div>
          <HeadButtonRight
            className="whitespace-no-wrap"
            onClick={onCancel}
            btnTitle="Cancel"
          />
          <HeadButtonRight
            className="ml-4 whitespace-no-wrap"
            variant="contained"
            onClick={onDraftTask}
            btnTitle="Save"
          />
          <HeadButtonRight
            className="ml-4 whitespace-no-wrap"
            variant="contained"
            onClick={onPublishTask}
            btnTitle="Publish"
          />
        </div>
      );
    }

    const leftButtonTitle = !havingDraftVersion ? 'Cancel' : 'Save';
    const leftButtonAction = !havingDraftVersion ? onCancel : onUpdateDraftQuestionVersion;
    const rightButtonTitle = !havingDraftVersion ? 'New Version' : 'Publish';
    const rightButtonAction = !havingDraftVersion ? onCreateDraftVersion : onPublishDraftQuestionVersion;

    return (
      <div>
        <HeadButtonRight
          className="whitespace-no-wrap"
          onClick={leftButtonAction}
          btnTitle={leftButtonTitle}
        />
        <HeadButtonRight
          className={clsx('ml-4 whitespace-no-wrap', !havingDraftVersion && 'w-128')}
          variant="contained"
          onClick={rightButtonAction}
          btnTitle={rightButtonTitle}
        />
      </div>
    );
  }

  // others
  return null;
}

export default TaskHeaderActions;
