import { ControlledTextInput } from 'app/components/cores/inputs/text';
import FileUploadField from 'app/components/cores/items-editor/file-upload-field';
import TemplateHeader from 'app/components/templates/header';
import { FieldValues, useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';

type Props = {
  tabIndex: number;
  onTabChange?: (tabIndex: number) => void;
};

function TemplateInfo({ tabIndex, onTabChange }: Props) {
  const { control } = useFormContext<FieldValues>();
  return (
    <>
      <TemplateHeader
        tabIndex={tabIndex}
        onTabChange={onTabChange}
      />
      <div className="p-24 space-y-16 layout-box ">
        <div>
          <Typography className="text-secondaryLight text-12 font-400">Code</Typography>
          <ControlledTextInput
            name="code"
            control={control}
            variant="filled"
            className="h-full shadow"
            placeholder="Add a code here"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
          />
        </div>

        <div>
          <Typography className="text-secondaryLight text-12 font-400">Template Title *</Typography>
          <ControlledTextInput
            name="name"
            control={control}
            variant="filled"
            className="h-full shadow"
            placeholder="Add a title here"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
          />
        </div>

        <div>
          <Typography className="text-secondaryLight text-12 font-400">Template Description (optional)</Typography>
          <ControlledTextInput
            name="description"
            placeholder="Add description here"
            control={control}
            variant="filled"
            className="h-full shadow"
            multiline={true}
            minRows={3}
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
          />
        </div>
        <div>
          <Typography className="text-secondaryLight text-12 font-400">Add Attachment (optional)</Typography>

          <FileUploadField
            key="answerUpload"
            name="attachments"
            acceptFiles={{
              'application/pdf': ['.pdf'],
              'image/png': ['.png'],
            }}
            isPublic={false}
          />
        </div>
      </div>
    </>
  );
}

export default TemplateInfo;
