import useTask from 'app/hooks/use-task';
import useTaskFilter from 'app/hooks/use-task-filter';
import useUserSetting from 'app/hooks/use-user-setting';
import { formatTasks } from 'domains/employee/task.domain';
import _ from 'lodash';
import { TUserRole } from 'models';
import { TEmployeeTaskFormatted, TEmployeeTaskItem, TEmployeeTasks } from 'models/employee/task.model';
import { useMemo } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import TaskSidebarMobile from './sidebar/mobile';
import TaskSidebarTablet from './sidebar/tablet';

type Props = {
  lists?: TEmployeeTasks;
  selectedList?: TEmployeeTaskItem | null;
  isLoading?: boolean;
  roles?: TUserRole[];
  searchValue?: string;
  onSelectList?: (task: TEmployeeTaskItem, isRefetchLists?: boolean) => void;
  onSearch?: (search: string) => void;
  onSelectRoles?: (roles: TUserRole[]) => void;
  isFacility?: boolean;
};

function TaskSidebar({
  lists,
  selectedList,
  isLoading,
  roles,
  searchValue,
  onSelectList,
  onSearch,
  onSelectRoles,
  isFacility = false,
}: Props) {
  const { isShowAudit } = useTask();
  const { canAccessDiary, accessDiaryLocationIds } = useUserSetting();
  const { location } = useTaskFilter();
  const showDiary = !!canAccessDiary && accessDiaryLocationIds?.includes(location?.externalId);

  const { formattedTasks, locationRoles } = useMemo(() => {
    const nextLists = _.cloneDeep(lists);
    const totalRoles = nextLists?.totalRoles;
    _.unset(nextLists, 'totalRoles');

    if (!isShowAudit) {
      _.unset(nextLists, 'audit');
    }

    if (!showDiary) {
      _.unset(nextLists, 'todo');
    }

    return {
      formattedTasks: formatTasks(nextLists),
      locationRoles: (totalRoles || []) as any,
    };
  }, [lists, isShowAudit, showDiary]);
  const hasFilter = useMemo(() => !!roles?.[0]?.externalId || !!searchValue, [roles, searchValue]);
  const isLargeScreen = useMediaQuery('(min-width:960px)');

  const isRenderSection = (task: TEmployeeTaskFormatted) => {
    return task.taskGroup !== 'onDemand' || task.total > 0;
  };

  if (isLargeScreen) {
    return (
      <TaskSidebarTablet
        lists={formattedTasks}
        selectedList={selectedList}
        isLoading={isLoading}
        hasFilter={hasFilter}
        roles={roles}
        searchValue={searchValue}
        isFacility={isFacility}
        isRenderSection={isRenderSection}
        locationRoles={locationRoles}
        onSelectList={onSelectList}
        onSearch={onSearch}
        onSelectRoles={onSelectRoles}
      />
    );
  }

  return (
    <TaskSidebarMobile
      lists={formattedTasks}
      selectedList={selectedList}
      isLoading={isLoading}
      roles={roles}
      searchValue={searchValue}
      isFacility={isFacility}
      isRenderSection={isRenderSection}
      locationRoles={locationRoles}
      onSelectList={onSelectList}
      onSearch={onSearch}
      onSelectRoles={onSelectRoles}
    />
  );
}

export default TaskSidebar;
