import clsx from 'clsx';

import { CircularProgress, Typography } from '@mui/material';

type Props = {
  value: number;
  className?: string;
  isActive?: boolean;
};

function TaskProgress({ value, className, isActive }: Props) {
  return (
    <div className={clsx('relative', className)}>
      <div className={clsx(isActive ? 'text-gray-300' : 'text-gray-200')}>
        <CircularProgress
          variant="determinate"
          value={100}
          color="inherit"
          className="absolute"
          size={36}
        />
      </div>
      <div className="text-secondaryLight">
        <CircularProgress
          variant="determinate"
          value={value}
          color="inherit"
          size={36}
        />
      </div>
      <div className="absolute inset-0 flex items-center justify-center pb-4">
        <Typography className="text-10 font-500 text-secondaryLight">{`${value.toFixed(0)}%`}</Typography>
      </div>
    </div>
  );
}

export default TaskProgress;
