import { ControlledDataSelect } from 'app/components/cores/data-select';
import CheckboxDefault from 'app/components/cores/items-editor/list-trigger/checkbox-default';
import { SingleSelect } from 'app/components/cores/select';
import RoleSelection from 'app/components/lists/assignment/role-selection';
import AttributeContainer from 'app/components/lists/attribute-container';
import { useGetTasks } from 'app/hooks/api/tasks';
import { GROUP_OPTIONS } from 'constants/index';
import {
  checkExistDestroyedOrgRoles,
  getOrgRoleIds,
  removeDestroyedOrgRoles,
  transformOrgRoles,
} from 'domains/conditional-task-settings';
import { TListTrigger } from 'models/task.model';
import { useEffect } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { ActionMeta } from 'react-select';

type Props = {
  name: string;
  control: Control;
};

const TriggerListVariant = ({ name, control }: Props) => {
  const { data } = useGetTasks({ page: 1, per_page: 999, status: 'published' });
  const { listId: listIdParam } = useParams<{ listId: string }>();

  const {
    field: { onChange },
  } = useController({ name, control });

  const currentTaskName = `${name}`;
  const defaultName = `${currentTaskName}.default`;
  const listTypeName = `${currentTaskName}.listType`;
  const orgRolesName = `${currentTaskName}.orgRoles`;
  const listIdName = `${currentTaskName}.id`;

  const currentItem = useWatch({ name: currentTaskName, control });
  const listIdValue = useWatch({ name: listIdName, control });
  const defaultValue = useWatch({ name: defaultName, control, defaultValue: false });
  const orgRolesValue: TListTrigger['orgRoles'] = useWatch({ name: orgRolesName, control });

  const lists = data?.records || [];

  const getSelectedList = (id: number) => lists.find(list => list.id === id);

  const selectedList = getSelectedList(listIdValue) as unknown as TListTrigger;

  const handleChangeItem = (item: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action === 'clear') {
      onChange(undefined);
      return;
    }

    const { id, listType, orgRoles } = (item || {}) as TListTrigger;

    onChange({
      ...currentItem,
      id,
      default: true,
      listType,
      orgRoles,
      orgRoleIds: getOrgRoleIds(orgRoles),
    });
  };

  const handleCheckboxChange = (checked: boolean) => {
    const { orgRoles, listType } = selectedList;

    if (checked) {
      onChange({
        ...currentItem,
        orgRoles,
        orgRoleIds: getOrgRoleIds(orgRoles),
        listType,
        default: true,
      });
    }
  };

  const handleChangeRoles = (roles: TListTrigger['orgRoles']) => {
    const orgRoles = transformOrgRoles(roles);

    onChange({
      ...currentItem,
      orgRoles,
      orgRoleIds: getOrgRoleIds(orgRoles),
    });
  };

  useEffect(() => {
    if (!listIdValue) return;

    if (defaultValue) {
      onChange({
        ...currentItem,
        listType: selectedList?.listType,
        orgRoles: selectedList?.orgRoles,
        orgRoleIds: getOrgRoleIds(selectedList?.orgRoles),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, selectedList, listIdValue]);

  useEffect(() => {
    if (!orgRolesValue) return;
    const existDestroy = checkExistDestroyedOrgRoles(orgRolesValue);

    if (existDestroy) {
      const filteredRoles = removeDestroyedOrgRoles(orgRolesValue);
      onChange({
        ...currentItem,
        orgRoles: filteredRoles,
        orgRoleIds: getOrgRoleIds(filteredRoles),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRolesValue]);

  return (
    <div className="space-y-8">
      <>
        <SingleSelect
          placeholder="List Name"
          value={selectedList}
          options={lists}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          onChange={handleChangeItem}
          isClearable
          key={listIdValue ?? 0}
          filterOption={(option: any, input: string) =>
            Number(option.value) !== Number(listIdParam) && option?.label?.toLowerCase().includes(input.toLowerCase())
          }
        />

        {!!listIdValue && (
          <>
            <CheckboxDefault
              name={defaultName}
              label="Use default Assign Role(s) and List Type"
              key={listIdValue ?? 0}
              onChange={handleCheckboxChange}
              control={control}
            />

            <AttributeContainer
              label="Assign Role(s)"
              labelClassName="text-11 font-400"
            >
              <RoleSelection
                name={orgRolesName}
                placeholder="Assign Role to List"
                menuPlacement="top"
                disabled={defaultValue}
                onChange={handleChangeRoles}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.externalId}
                control={control}
                key={listIdValue ?? 0}
              />
            </AttributeContainer>

            <AttributeContainer
              label="List Type"
              labelClassName="text-11 font-400"
            >
              <div className="w-128">
                <ControlledDataSelect
                  options={GROUP_OPTIONS}
                  name={listTypeName}
                  key={listIdValue ?? 0}
                  disabled={defaultValue}
                  control={control}
                />
              </div>
            </AttributeContainer>
          </>
        )}
      </>
    </div>
  );
};
export default TriggerListVariant;
