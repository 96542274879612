import TaskSnackbar from 'app/components/cores/app-snackbar/task-snackbar';
import useToggle from 'app/hooks/use-toggle';
import { TEmployeeTaskItem } from 'models/employee/task.model';
import { useEffect } from 'react';

import { usePrevious } from '@fuse/hooks';
import Typography from '@mui/material/Typography';

type Props = {
  location: any;
  selectedList: any;
  isDisabled?: boolean;
};

const EmployeeTaskSnackbar = ({ location, selectedList, isDisabled }: Props) => {
  const { state: isOpenSnackbar, setActive: openSnackbar, setInactive: closeSnackbar } = useToggle();
  const prevLocation: Record<string, any> = usePrevious(location) || {};
  const prevSelectedList: TEmployeeTaskItem | undefined = usePrevious(selectedList);

  useEffect(() => {
    if (isDisabled || !selectedList?.id) return;

    const isLocationChanged = prevLocation?.externalId !== location?.externalId;
    const isListChanged = (prevSelectedList as any)?.id !== selectedList?.id;

    if (isListChanged || isLocationChanged) openSnackbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, isDisabled, location]);

  return (
    <TaskSnackbar
      isOpen={isOpenSnackbar}
      onClose={closeSnackbar}
    >
      <div
        className="flex items-center justify-center h-40 px-24 rounded-16"
        style={{ background: '#F5D02F' }}
      >
        <Typography className="text-13">
          You are completing the list for{' '}
          <Typography
            className="text-13 font-600"
            component="span"
          >
            {location?.name}
          </Typography>
        </Typography>
      </div>
    </TaskSnackbar>
  );
};
export default EmployeeTaskSnackbar;
