import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { ElementRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';

type Props = {
  signaturePadRef?: any;
  disabled?: boolean;
  value?: string | null;
  listStatus?: string;
  onChange?: (nextValue: string) => void;
};

function SignaturePad({ signaturePadRef, disabled, value, listStatus, onChange }: Props) {
  const { state: isOpenPad, setActive: showPad, setInactive: hidePad } = useToggle(false);
  const [signature, setSignature] = useState('');
  const innerRef = useRef<ElementRef<typeof SignatureCanvas>>(null);
  const actionColor = listStatus === 'overdue' ? 'overdue.main' : 'primary.main';

  useImperativeHandle(signaturePadRef, () => ({
    clear: handleClear,
    open: showPad,
  }));

  useEffect(() => {
    if (!value || !isOpenPad) return;
    setSignature(value);
    setTimeout(() => {
      innerRef.current?.fromDataURL(value);
    }, 0);
  }, [value, isOpenPad]);

  useEffect(() => {
    if (!isOpenPad) {
      setSignature('');
    }
  }, [isOpenPad]);

  const handleEnd = () => {
    const nextSignature = innerRef.current?.toDataURL('image/png');
    if (typeof nextSignature === 'string') {
      setSignature(nextSignature);
    }
  };

  const handleClear = () => {
    innerRef.current?.clear();
    setSignature('');
  };

  const handleComplete = () => {
    onChange?.(signature);
    hidePad();
  };

  return (
    <>
      <Box
        className={clsx(
          'flex items-center justify-center w-full bg-gray-200 rounded-16',
          disabled && ' cursor-not-allowed',
        )}
        sx={{ border: '1px dashed #7C808B', height: '184px' }}
      >
        {value ? (
          <img
            src={value}
            alt="signature"
            className="h-full"
          />
        ) : (
          <div
            role="button"
            tabIndex={-1}
            className={clsx('text-center', disabled && 'pointer-events-none')}
            onClick={showPad}
          >
            <DrawIcon className="text-24" />
            <Typography className="uppercase text-13">sign here</Typography>
          </div>
        )}
      </Box>

      <Modal
        open={isOpenPad}
        className="flex items-center justify-center"
      >
        <Card
          sx={{ width: '600px' }}
          className="bg-white rounded-16"
        >
          <CardHeader
            title="Signature"
            action={
              <IconButton onClick={hidePad}>
                <CloseIcon className="text-20" />
              </IconButton>
            }
            classes={{ title: 'text-18 font-600 text-secondaryMain' }}
            className="px-24"
          />
          <Divider />

          <CardContent className="px-24 space-y-24">
            <div className="w-full m-16 mx-auto border-gray-400 border-dashed max-w-488 h-360 bg-paper rounded-8 border-1">
              <SignatureCanvas
                ref={innerRef}
                canvasProps={{ width: 488, height: 360 }}
                onEnd={handleEnd}
              />
            </div>
          </CardContent>

          <Divider />
          <CardActions className="flex items-center justify-end px-24">
            <Button
              disableElevation={true}
              variant="outlined"
              sx={{ color: actionColor, borderColor: actionColor }}
              className="capitalize text-13 w-104 font-400"
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              disableElevation={true}
              variant="contained"
              sx={{ backgroundColor: actionColor }}
              className="capitalize text-13 w-104 font-400"
              onClick={handleComplete}
            >
              Complete
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}

export default SignaturePad;
