import clsx from 'clsx';
import { TUpcomingEvent } from 'models/upcoming-events.model';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import BlackoutDisplayTime from './display-time/blackout';
import PublicHolidayDisplayTime from './display-time/public-holiday';

const useStyles = makeStyles(() => {
  return {
    bgHoliday: {
      backgroundColor: 'rgba(255, 246, 250, 1)',
    },
    borderHoliday: {
      borderColor: 'rgba(224, 17, 95, 1)',
    },
  };
});

type Props = {
  event: TUpcomingEvent;
};

const UpcomingEventCardItem = ({ event }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        'flex flex-col p-8 border-l-4  rounded-4 shadow-1',
        event.type === 'public_holiday' ? classes.bgHoliday : 'bg-white',
        event.type === 'public_holiday' ? classes.borderHoliday : 'border-secondaryMain',
      )}
    >
      <div className="grid grid-cols-12">
        <div className="col-span-10">
          <Typography className="text-13 font-600">
            {event.type === 'blackout_time' ? event.reason : event.name}
          </Typography>
        </div>
        <div className="flex items-center justify-end col-span-2 pr-4 space-x-2">
          {event.type === 'blackout_time' && !!event.note && (
            <Tooltip title={event.note}>
              <InfoOutlinedIcon className="text-18" />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex flex-row items-start mt-4">
        <AccessTimeOutlinedIcon className="mt-2 mb-2 mr-4 text-16 text-secondaryLight" />
        {event.type === 'blackout_time' && <BlackoutDisplayTime event={event} />}
        {event.type === 'public_holiday' && <PublicHolidayDisplayTime event={event} />}
      </div>
    </div>
  );
};

export default UpcomingEventCardItem;
