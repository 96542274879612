import clsx from 'clsx';
import _ from 'lodash';
import { TResponse } from 'models';
import { TOption } from 'models/option.model';
import { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  options: TOption[];
  value?: number[];
  onChange?: (value: TOption[]) => void;
  disabled?: boolean;
  status?: TResponse['status'];
};

const StyledOption = styled('button')<{}>(() => {
  return {};
});

const getSelectedOptions = (options: TOption[], value: number[] = []) => {
  return options?.filter(option => value?.includes(+option.value));
};

const MultipleChoiceOptions = ({ options, value, onChange, disabled, status }: Props) => {
  const [selectedOptions, setSelectOptions] = useState<TOption[]>(getSelectedOptions(options, value) ?? []);

  const generateAlphabet = (index: number) => String.fromCharCode(65 + index);

  const handleSelectOption = (option: TOption) => () => {
    if (disabled) {
      return;
    }
    const newSelectedOptions = _.xorBy(selectedOptions, [option], 'value');
    setSelectOptions(newSelectedOptions);
    onChange?.(newSelectedOptions);
  };

  const checkIfOptionIsSelected = (option: TOption) => {
    return selectedOptions?.some(selectedOption => selectedOption.value === option.value);
  };

  const getBgColorByStatus = () => {
    if (status === 'overdue') return 'overdue.light';
    return 'primary.main';
  };

  const getColorByStatus = () => {
    if (status === 'overdue') return '#FF7414';
    return 'white';
  };

  return (
    <Stack gap={2}>
      {options?.length > 0 &&
        options.map((option: TOption, index: number) => {
          return (
            <StyledOption
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              type="button"
              className="flex items-center w-full px-24 py-8 text-left min-h-40 rounded-8"
              onClick={handleSelectOption(option)}
              sx={{
                backgroundColor: checkIfOptionIsSelected(option) ? getBgColorByStatus() : 'grey.200',
              }}
            >
              <Typography
                component="span"
                className={clsx('font-bold text-13')}
                sx={{
                  color: checkIfOptionIsSelected(option) ? getColorByStatus() : 'grey.600',
                }}
              >
                {generateAlphabet(options.indexOf(option))}
              </Typography>
              <Typography
                component="span"
                className={clsx('ml-16 font-bold text-13')}
                sx={{
                  color: checkIfOptionIsSelected(option) ? getColorByStatus() : 'grey.600',
                  wordWrap: 'break-word',
                }}
              >
                {option.label}
              </Typography>
            </StyledOption>
          );
        })}
    </Stack>
  );
};

export default MultipleChoiceOptions;
