import { ControlledTextInput } from 'app/components/cores/inputs/text';
import { CONDITIONAL_DEFAULT_OPTIONS, CONDITIONAL_NUMERIC_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TItem } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, IconButton } from '@mui/material';

import { MultipleConditionSelect } from './multiple-condition-select';
import { MultipleLogicSelect } from './multiple-logic-select';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  onShowMessageModal?: () => void;
};

// TODO optimize this
export function MultipleCondition({ control, name, selectedConditionalItem, onShowMessageModal }: Props) {
  const notificationConditionalName = `${name}.notificationConditionals.0`;
  const notificationConditionalComparisonName = `${notificationConditionalName}.comparisons`;

  const [isShowMoreLogic, setIsShowMoreLogic] = useState(false);

  // notification conditional
  const {
    field: { onChange },
  } = useController({ control, name: notificationConditionalName });

  // comparisons
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: notificationConditionalComparisonName,
  });

  const firstFieldLogic = (fields?.[0] as any)?.logic;
  const isMaxConditional = fields?.length >= CONDITIONAL_NUMERIC_OPTIONS?.length;
  const hasConditional = !!fields?.length;

  const isCustomConditional = useMemo(() => {
    if (fields?.length > 1) return true;
    if (fields?.length === 1) {
      if (firstFieldLogic === 'custom') return true;
      return !['completed', 'not_completed', 'not_applicable'].includes(firstFieldLogic);
    }
    return false;
  }, [firstFieldLogic, fields]);

  const defaultCondition = useMemo(() => {
    return !isCustomConditional
      ? _.find(CONDITIONAL_DEFAULT_OPTIONS, { conditionalLogic: firstFieldLogic })
      : CONDITIONAL_DEFAULT_OPTIONS[0];
  }, [firstFieldLogic, isCustomConditional]);

  const mappedFields = useMemo(
    () =>
      _.map(fields, (field: any) => ({
        ...field,
        conditionalLogic: field.logic,
      })),
    [fields],
  );

  useEffect(() => {
    setIsShowMoreLogic(isCustomConditional);
  }, [isCustomConditional]);

  const handleChangeDefaultLogic = (item: any) => {
    const isCustomize = item.conditionalLogic === 'custom';

    remove();
    setIsShowMoreLogic(isCustomize);

    if (isCustomize) {
      append({
        logic: 'eq',
        conditionalLogic: 'eq',
        conditionalLabel: 'Equal to',
      });
    } else {
      append({
        logic: item.conditionalLogic,
        ...item,
      });
    }
  };

  const handleChangeConditional = (index: number) => (item: any) => {
    update(index, {
      logic: item.conditionalLogic,
      ...item,
    });
  };

  const handleAddMoreConditional = () => {
    if (isMaxConditional) return;
    append({});
  };

  const handleRemoveConditional = (index: number) => () => {
    remove(index);
  };

  const handleRemoveAllConditional = () => {
    onChange({
      message: null,
      overrideSubject: null,
      sendToRoles: [],
      sendToRolesDetail: [],
      sendToUsers: [],
      sendToUsersDetail: [],
      comparisons: [],
    });
    remove();
  };

  return (
    <div>
      <MultipleConditionSelect
        name={name}
        control={control}
        selectedConditionalItem={selectedConditionalItem}
        defaultCondition={defaultCondition}
        onChange={handleChangeDefaultLogic}
        onShowMessageModal={onShowMessageModal}
      />

      {hasConditional &&
        (isShowMoreLogic ? (
          <div className="mt-12">
            <div className="mb-16 space-y-4">
              {mappedFields?.map((field: any, index: number) => (
                <div
                  key={field.id}
                  className="flex items-start space-x-8"
                >
                  <MultipleLogicSelect
                    isCustomize={true}
                    selectedConditionalItem={selectedConditionalItem}
                    conditionalComparison={mappedFields}
                    value={field}
                    placeholder="Select conditional"
                    className="mt-4"
                    onChange={handleChangeConditional(index)}
                  />
                  <div className="flex-1">
                    <ControlledTextInput
                      disabled={!field?.logic}
                      type="number"
                      name={`${notificationConditionalComparisonName}.${index}.value`}
                      control={control}
                      variant="filled"
                      className="h-full shadow"
                      InputProps={{
                        className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
                        disableUnderline: true,
                        classes: {
                          input: 'p-8 text-11',
                        },
                      }}
                    />
                  </div>
                  {mappedFields?.length > 1 && (
                    <div>
                      <IconButton onClick={handleRemoveConditional(index)}>
                        <DeleteOutlineIcon className="text-16" />
                      </IconButton>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="flex items-center justify-end space-x-4">
              <Button
                disableElevation={true}
                variant="outlined"
                color="error"
                className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
                onClick={handleRemoveAllConditional}
              >
                Remove
              </Button>
              <Button
                disabled={isMaxConditional}
                disableElevation={true}
                variant="contained"
                color="primary"
                className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
                onClick={handleAddMoreConditional}
              >
                Add
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-end space-x-4">
            <Button
              disableElevation={true}
              variant="outlined"
              color="error"
              className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
              onClick={handleRemoveAllConditional}
            >
              Remove
            </Button>
          </div>
        ))}
    </div>
  );
}
