import TaskResponse from 'app/components/cores/tasks/response';
import { TEmployeeTaskItem, TEmployeeTaskResponse } from 'models/employee/task.model';

import { useIsMutating } from '@tanstack/react-query';

type Props = {
  list?: TEmployeeTaskItem;
  readOnly?: boolean;
  useIndexAsKey?: boolean;
};

function TaskContentBody({ list, readOnly = false, useIndexAsKey }: Props) {
  const responses = list?.responses || [];
  const isMutating = useIsMutating();

  return (
    <div className="space-y-8">
      {responses?.map(
        (response: TEmployeeTaskResponse, index: number) =>
          response.displayable && (
            <TaskResponse
              key={useIndexAsKey ? index : response.id}
              name={`responses.${index}`}
              response={response}
              list={list}
              readOnly={readOnly || isMutating > 0}
            />
          ),
      )}
    </div>
  );
}

export default TaskContentBody;
