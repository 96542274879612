import QuestionMap from 'app/components/templates/preview/question-map';
import { TTemplate } from 'models';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

type Props = {
  taskQuestions: TTemplate['itemsAttributes'];
};

const TaskQuestions = ({ taskQuestions }: Props) => {
  return (
    <Paper
      className="bg-white "
      square
      sx={{ overflowY: 'auto' }}
    >
      <Stack divider={<Divider />}>
        {taskQuestions?.map(question => (
          <QuestionMap
            key={question.id || 0}
            question={question}
            readOnly={true}
          />
        ))}
      </Stack>
    </Paper>
  );
};

export default TaskQuestions;
