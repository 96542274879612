import clsx from 'clsx';

import { Skeleton, Typography } from '@mui/material';

type Props = {
  name: string;
  value: string;
  isLoading?: boolean;
  containerClassName?: string;
  valueClassName?: string;
};

function InfoItem({ name, value, containerClassName, valueClassName, isLoading = false }: Props) {
  return (
    <div className={clsx('flex flex-col items-start', containerClassName)}>
      <Typography className="text-13 font-400 text-secondaryLight">{name}</Typography>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rounded"
          className="w-40 h-20 mt-4"
        />
      ) : (
        <>
          {value ? (
            <Typography className={clsx('text-13 font-600 text-secondaryMain mt-4', valueClassName)}>
              {value}
            </Typography>
          ) : (
            <div className="min-h-20" />
          )}{' '}
        </>
      )}
    </div>
  );
}

export default InfoItem;
