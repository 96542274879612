/* eslint-disable */
import AdminIcon from 'app/components/cores/icons/admin';
import MyTaskIcon from 'app/components/cores/icons/my-task';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

type Props = {
  foldedAndClosed: boolean;
  currentRole: string;
  show?: boolean;
  onNavigationSwitch: (role: string) => void;
};

function NavigationSwitch(props: Props) {
  const { foldedAndClosed, onNavigationSwitch, currentRole, show } = props;

  if (!show) return null;

  const isActiveByRole = (role: string) => {
    return role === currentRole;
  };

  return (
    <div>
      {foldedAndClosed ? (
        <AppBar
          position="static"
          color="secondary"
          elevation={0}
          className="relative z-0 flex flex-col user"
        >
          <div
            className="flex justify-center flex-auto"
            onClick={() => onNavigationSwitch('my_tasks')}
          >
            <div
              className={`flex flex-col items-center justify-center text-13 h-64 w-40 border-white ${
                isActiveByRole('my_tasks') ? 'border-b-2' : ''
              }`}
            >
              <MyTaskIcon />
            </div>
          </div>
          <div
            className="flex justify-center flex-auto"
            onClick={() => onNavigationSwitch('admin')}
          >
            <div
              className={`flex flex-col items-center justify-center text-13 h-64 w-40 border-white ${
                isActiveByRole('admin') ? 'border-b-2' : ''
              }`}
            >
              <AdminIcon />
            </div>
          </div>
        </AppBar>
      ) : (
        <AppBar
          position="static"
          color="secondary"
          elevation={0}
          className="relative z-0 flex flex-row h-64 cursor-pointer user"
        >
          <div
            className="flex justify-center flex-auto"
            onClick={() => onNavigationSwitch('my_tasks')}
          >
            <div
              className={`flex flex-col items-center justify-center w-72 h-full border-white ${
                isActiveByRole('my_tasks') ? 'border-b-2' : ''
              }`}
            >
              <MyTaskIcon />
              <Typography className="pt-8 uppercase text-13">My Tasks</Typography>
            </div>
          </div>
          <div
            className="flex justify-center flex-auto"
            onClick={() => onNavigationSwitch('admin')}
          >
            <div
              className={`flex flex-col items-center justify-center w-72 h-full border-white ${
                isActiveByRole('admin') ? 'border-b-2' : ''
              }`}
            >
              <AdminIcon />
              <Typography className="pt-8 uppercase text-13">Manager</Typography>
            </div>
          </div>
        </AppBar>
      )}
    </div>
  );
}

export default NavigationSwitch;
