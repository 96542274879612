import { clsxm } from 'utils/clsxm';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const TableHeader = () => {
  const HEADER_COLUMNS = [
    { name: 'Action', width: '35%' },
    { name: 'Assignee', width: '20%' },
    { name: 'Deadline', width: '15%' },
    { name: 'Status', width: '20%' },
    { name: 'Actions', width: '10%' },
  ];

  return (
    <TableHead>
      <TableRow>
        {HEADER_COLUMNS.map(column => (
          <TableCell
            key={column.name}
            width={column.width}
            className="border-0"
          >
            <Typography
              className={clsxm('text-13 text-secondaryLight font-600', column.name === 'Status' && 'text-center')}
            >
              {column.name}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
