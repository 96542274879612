export const TOGGLE_OPTIONS = [
  { label: 'Disable', value: false },
  { label: 'Enable', value: true },
];

export const defaultStates: { value: string; label: string }[] = [
  { value: 'QLD', label: 'Queensland' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'SA', label: 'South Australia' },
  { value: 'WA', label: 'Western Australia' },
  { value: 'NT', label: 'Northern Territory' },
  { value: 'ACT', label: 'Australian Capital Territory' },
];

export const stateOptions: { value: string; label: string }[] = defaultStates.map(state => ({
  value: state.value,
  label: state.label,
}));

export const holidayStateOptions = [{ value: 'National', label: 'National Public Holiday' }, ...stateOptions];
