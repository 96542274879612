import { Paths } from 'constants/index';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useTaskUrl = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const listId = queryParams.get('list-id');
  const responseId = queryParams.get('response-id');
  const saltFormId = queryParams.get('salt-id');

  const goToEmployeeList = (_listId: number) => {
    if (!_listId) return;
    history.push({
      pathname: Paths.myTask,
      search: `?list-id=${_listId}`,
    });
  };

  const replaceEmployeeListId = useCallback(
    (_listId: number | string) => {
      if (!_listId) return;
      history.replace({
        pathname: Paths.myTask,
        search: `?list-id=${_listId}`,
      });
    },
    [history],
  );

  const clearEmployeeSaltFormId = useCallback(() => {
    if (!listId) return;
    replaceEmployeeListId(Number(listId));
  }, [listId, replaceEmployeeListId]);

  const clearEmployeeListId = useCallback(() => {
    history.replace({ pathname: Paths.myTask });
  }, [history]);

  const scrollToActiveListItem = useCallback((_listId: number | string) => {
    const listItem = document.querySelector(`[data-listid="${_listId}"]`);
    listItem?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, []);

  const scrollToActiveResponseItem = useCallback((_responseId: number | string) => {
    const responseItem = document.querySelector(`[data-responseid="${_responseId}"]`);
    responseItem?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, []);

  return {
    listId,
    responseId,
    saltFormId,
    goToEmployeeList,
    replaceEmployeeListId,
    clearEmployeeListId,
    scrollToActiveListItem,
    scrollToActiveResponseItem,
    clearEmployeeSaltFormId,
  };
};

export default useTaskUrl;
