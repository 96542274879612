import ConfirmDialog from 'app/components/cores/confirm-dialog';
import DataSelect from 'app/components/cores/data-select';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import useTask from 'app/hooks/use-task';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { Control, useController } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
};

const RatingOptions = ({ name, control }: Props) => {
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreSettingsName = `${name}.scoreSettings`;
  const dispatch = useDispatch();
  const { isShowAudit } = useTask();

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: `${name}`,
  });

  const {
    field: { value: scoreEnabled, onChange: onChangeScoreEnabled },
  } = useController({
    control,
    name: scoreEnabledName,
  });

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({
    control,
    name: scoreSettingsName,
  });

  const RATING_OPTIONS = [
    { label: '5', value: '5' },
    { label: '8', value: '8' },
    { label: '10', value: '10' },
  ];

  const handleChange = (maxNumberStr: string) => {
    const maxNumber = Number(maxNumberStr);
    const hasScoreSettings = scoreEnabled && scoreSettings?.length !== maxNumber;

    const handler =
      isShowAudit && hasScoreSettings
        ? () => handleOpenConfirmDialog(maxNumber)
        : () => onChange({ ...value, maxNumber, minNumber: 1 });

    handler();
  };

  const handleOpenConfirmDialog = (maxNumber: number) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Rating Options Change"
            message="Changing Rating Options will remove the current Score Settings, are you sure you want to continue?"
            statusVariant="warning"
            confirmButtonLabel="Continue"
            cancelButtonLabel="Cancel"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              onChange({ ...value, maxNumber, minNumber: 1 });
              onChangeScoreEnabled(false);
              onChangeScoreSettings([]);
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="text-12 font-600">Rating Options</Typography>
      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-600">Rating Type</Typography>
        <div className="w-80">
          <DataSelect
            options={RATING_OPTIONS}
            onChange={handleChange}
            value={(value.maxNumber || 5).toString()}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Minimum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.minLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Maximum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.maxLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RatingOptions;
