import { TOpeningHours } from 'models';

import { Typography } from '@mui/material';

import OpeningHours from './opening/opening-hours';

type Props = {
  date?: string;
  openingTimes: TOpeningHours;
  closedOnThisHoliday?: boolean;
};

const HolidayDate = ({ date, openingTimes, closedOnThisHoliday }: Props) => {
  return (
    <div className="flex flex-col">
      {!!date && <Typography className="text-13 font-500 text-secondaryMain">{date}</Typography>}

      {closedOnThisHoliday ? (
        <Typography className="text-11 font-500 text-secondaryMain">Close</Typography>
      ) : (
        <OpeningHours
          openingTimes={openingTimes}
          textClassName="text-11"
        />
      )}
    </div>
  );
};

export default HolidayDate;
