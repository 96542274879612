import clsx from 'clsx';
import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& .logo-icon': {
      width: 'auto',
      height: 28,
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    '& .logo-text': {
      width: 'auto',
      maxHeight: 56,
    },
    '& .react-badge, & .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
}));

type Props = {
  brandLogo?: string;
  brandLogoSymbol?: string;
  foldedAndClosed: boolean;
};

function Logo({ brandLogo, brandLogoSymbol, foldedAndClosed }: Props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, 'flex flex-col justify-center')}>
      {foldedAndClosed ? (
        <div className="flex flex-row">
          <img
            className="logo-icon"
            src={brandLogoSymbol || 'assets/images/logos/salt-logo.svg'}
            alt="logo"
          />
          <div
            className="h-full text-28 logo-text"
            color="inherit"
            style={{ marginLeft: '10px', width: '128px' }}
          >
            <img
              className="logo-text"
              src={brandLogo || 'assets/images/logos/bluefit-name.svg'}
              alt="logo"
            />
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col justify-center h-full text-28 logo-text"
          color="inherit"
          style={{ marginLeft: '2px', maxWidth: '128px' }}
        >
          <img
            className="logo-text"
            src={brandLogo || 'assets/images/logos/bluefit-name.svg'}
            alt="logo"
          />
        </div>
      )}
    </div>
  );
}

export default Logo;
