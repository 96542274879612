import _ from 'lodash';
import { TReportPerformanceKind, TReportPerformanceTotal } from 'models';
import moment from 'moment';
import { formatDate } from 'utils/date';

export function formatTotal(input?: TReportPerformanceTotal, kind?: TReportPerformanceKind) {
  if (_.isEmpty(input)) return {};
  const output: { [key: string]: any } = {};

  _.forIn(input, (yearData, year) => {
    _.forEach(yearData, (data, index) => {
      let title = '';
      let current = false;
      const key = `${year}-${index}`;
      const { completionRate, weekMonthNumber } = data || {};

      const isFuture = moment().isBefore(moment(data.fromDate));

      if (kind === 'daily') {
        title = formatDate(new Date(data.fromDate), 'DD/MM/YYYY');
        if (title === moment().format('DD/MM/YYYY')) {
          current = true;
        }
      } else if (kind === 'weekly') {
        if (weekMonthNumber === moment().week()) {
          current = true;
        }
        const fromDate = formatDate(new Date(data.fromDate), 'DD/MM/YYYY');
        const toDate = formatDate(new Date(data.toDate), 'DD/MM/YYYY');
        title = `Week ${weekMonthNumber}\n(${fromDate} - ${toDate})`;
      } else if (kind === 'monthly') {
        title = formatDate(new Date(data.fromDate), 'MMMM YYYY');
        if (title === moment().format('MMMM YYYY')) {
          current = true;
        }
      }

      output[key] = { title, completionRate, current, isFuture };
    });
  });

  return output;
}

export function generateDefaultDateRange() {
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');
  return { startDate, endDate };
}
