import { TDateRange } from 'models';
import moment from 'moment';

export const getFormattedDateRange = (periodTime: string, customPeriod: TDateRange) => {
  const { startDate, endDate } = customPeriod;

  if (periodTime === 'custom') {
    if (!startDate && !endDate)
      return {
        from: null,
        to: null,
      };

    return {
      from: moment(startDate).format('DD/MM/YYYY'),
      to: moment(endDate).format('DD/MM/YYYY'),
    };
  }

  const dateRanges: Record<string, [fromUnit: moment.unitOfTime.StartOf, toUnit: moment.unitOfTime.StartOf]> = {
    today: ['day', 'day'],
    yesterday: ['day', 'day'],
    this_week: ['isoWeek', 'isoWeek'],
    this_month: ['month', 'month'],
    this_year: ['year', 'year'],
    last_week: ['isoWeek', 'isoWeek'],
  };

  const [fromUnit, toUnit] = dateRanges[periodTime] || [];
  const from = moment().startOf(fromUnit);
  const to = moment().endOf(toUnit);

  if (periodTime === 'yesterday') {
    return {
      from: from.subtract(1, 'day').format('DD/MM/YYYY'),
      to: to.subtract(1, 'day').format('DD/MM/YYYY'),
    };
  }

  if (periodTime === 'last_week') {
    return {
      from: from.subtract(1, 'week').format('DD/MM/YYYY'),
      to: to.subtract(1, 'week').format('DD/MM/YYYY'),
    };
  }

  return {
    from: from.format('DD/MM/YYYY'),
    to: to.format('DD/MM/YYYY'),
  };
};

export const generateDaysFromToString = (fromDate: string | null, toDate: string | null) => {
  const days = [];
  const formattedFrom = moment(fromDate, 'DD/MM/YYYY');
  const formattedTo = moment(toDate, 'DD/MM/YYYY');

  while (formattedFrom.isSameOrBefore(formattedTo)) {
    days.push(formattedFrom.toDate());
    formattedFrom.add(1, 'days');
  }

  return days;
};

export const getYearRange = (from?: string | null, to?: string | null): string => {
  if (from && to) {
    const fromYear = from.split('/')[2];
    const toYear = to.split('/')[2];

    if (fromYear === toYear) return fromYear;
    return `${fromYear} - ${toYear}`;
  }

  return new Date().getFullYear().toString();
};
