import SendNotificationToRole from 'app/components/lists/assignment/send-notification-to-role';
import SendNotificationToUser from 'app/components/lists/assignment/send-notification-to-user';
import SkipSaltInbox from 'app/components/lists/assignment/skip-salt-inbox';
import { Control } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

type Props = {
  name: string;
  control?: Control;
  onShowMessageModal?: () => void;
};

export function NotifyForm({ name, control, onShowMessageModal }: Props) {
  return (
    <div className="my-16 space-y-8">
      <div className="mb-8">
        <Typography className="mb-4 text-small-light">Send Notification To Role(s)</Typography>
        <SendNotificationToRole
          name={name}
          control={control}
        />
        <SkipSaltInbox
          name={`${name}.skipRoleInbox`}
          control={control}
          label="Skip SALT Inbox for assigned role(s)"
        />
      </div>

      <div className="mb-8">
        <Typography className="mb-4 text-small-light">Send Notification To User(s)</Typography>
        <SendNotificationToUser
          name={name}
          control={control}
          menuPlacement="top"
        />
        <SkipSaltInbox
          name={`${name}.skipUserInbox`}
          control={control}
          label="Skip SALT Inbox for assigned user(s)"
        />
      </div>

      <div className="">
        <Button
          variant="text"
          className="px-0 py-0 normal-case text-11 font-400 hover:bg-transparent hover:underline"
          onClick={onShowMessageModal}
        >
          Click here to update default subject and message
        </Button>
      </div>
    </div>
  );
}
