import { TResponsePagination, TTag } from 'models';

import { useGetList } from '../request';

const employeeCalendarURL = '/api/employee/calendars';

export const employeeCalendarKeys = {
  all: ['employeeCalendar'] as const,
  lists: () => [...employeeCalendarKeys.all, 'list'] as const,
  list: (filters: any) => [...employeeCalendarKeys.lists(), { filters }] as const,
};

export function useGetEmployeeCalendar(filters: any, options?: any) {
  return useGetList<TResponsePagination<TTag>>(
    [...employeeCalendarKeys.list(filters)],
    `${employeeCalendarURL}`,
    filters,
    { refetchOnMount: true, cacheTime: 0, ...options },
    true,
  );
}
