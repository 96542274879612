import { SingleSelect } from 'app/components/cores/select';
import TaskQuestionHeader from 'app/components/lists/preview/task-question-header';
import TaskQuestions from 'app/components/lists/preview/task-questions';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { filterTasksByLocation } from 'domains/task.domain';
import _ from 'lodash';
import { TTask, TTaskLocation } from 'models';
import { useMemo, useState } from 'react';
import { DeviceFrameset, DeviceOptions } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { useFormContext, useWatch } from 'react-hook-form';
import { delay } from 'utils/date';

import { Box, Button, CircularProgress, Divider, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Sidebar from './sidebar';
import TaskNav from './task-nav';

const useStyles = makeStyles(() => ({
  device: {
    '& .screen': {
      overflow: 'hidden !important',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  locationDropdown: {
    top: 200,
    right: 24,
  },
}));

const PreviewLayout = () => {
  const { getValues } = useFormContext<TTask>();
  const classes = useStyles();
  const theme = useTheme();
  const { state: isLoading, setActive: setLoading, setInactive: hideLoading } = useToggle();
  const deviceName = 'iPad Mini';
  const { height: deviceHeight } = DeviceOptions[deviceName];

  const [taskName, taskStatus, draftVersion] = getValues(['name', 'status', 'draftVersion']);
  const havingDraftVersion = draftVersion?.version;
  const isTaskDraft = taskStatus === 'draft';

  const [location, setLocation] = useState<TTaskLocation | null>(null);
  const [isDraftingQuestion, setIsDraftingQuestion] = useState(havingDraftVersion || isTaskDraft);

  const draftQuestions = useWatch({ name: 'draftVersion.itemsAttributes' });
  const currentQuestions = useWatch({ name: 'currentVersion.itemsAttributes' });
  const locations = useWatch({ name: 'locationTasksAttributes' });

  const draftLocationQuestions = useMemo(() => {
    return !location || !draftQuestions ? draftQuestions : filterTasksByLocation(draftQuestions, location);
  }, [draftQuestions, location]);

  const currentLocationQuestions = useMemo(() => {
    return !location || !currentQuestions ? currentQuestions : filterTasksByLocation(currentQuestions, location);
  }, [currentQuestions, location]);

  const locationOptions = useMemo(() => _.orderBy(locations, 'locationName'), [locations]);

  const handleLocationChange = async (selectedLocation: any) => {
    setLocation(selectedLocation as TTaskLocation);
    setLoading();
    await delay(500);
    hideLoading();
  };

  return (
    <div>
      {!isTaskDraft && (
        <div className="flex flex-row justify-center">
          {(havingDraftVersion || isTaskDraft) && (
            <>
              <Button
                className={clsx(
                  classes.button,
                  'text-13 capitalize w-160 mb-12 flex flex-col',
                  !isDraftingQuestion ? 'font-600' : 'font-400 text-secondaryLight',
                )}
                onClick={() => setIsDraftingQuestion(false)}
              >
                Published Version
              </Button>
              <Divider
                orientation="vertical"
                flexItem={true}
                className="h-32"
              />

              <Button
                className={clsx(
                  classes.button,
                  'text-13 capitalize w-160 mb-12',
                  isDraftingQuestion ? 'font-600' : 'font-400 text-secondaryLight',
                )}
                onClick={() => setIsDraftingQuestion(true)}
              >
                Draft Version
              </Button>
            </>
          )}
        </div>
      )}

      <div className={clsx(classes.device, 'flex flex-row items-start -mt-128')}>
        <DeviceFrameset
          device={deviceName}
          color="black"
          landscape={false}
          zoom={0.75}
        >
          <div>
            <Box
              sx={{
                backgroundColor: 'primary.main',
                height: '60px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div className="pr-20 w-232">
                <SingleSelect
                  value={location}
                  options={locationOptions}
                  maxMenuHeight={1000}
                  menuPlacement="auto"
                  noOptionsMessage={() => 'No locations'}
                  placeholder="Select a location"
                  getOptionLabel={(option: any) => option.locationName}
                  getOptionValue={(option: any) => option.locationId}
                  onChange={handleLocationChange}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      border: 0,
                      backgroundColor: theme.palette.background.default,
                      borderRadius: 4,
                      height: 40,
                      minHeight: 40,
                      boxShadow: 'none',
                      cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
                      pointerEvents: 'auto',
                    }),
                  }}
                />
              </div>
            </Box>
            <Box sx={{ height: '40px' }}>
              <TaskNav />
            </Box>
          </div>

          <div className="flex">
            <Sidebar />

            <Box
              className="flex flex-col flex-1"
              sx={{ height: `calc(${deviceHeight}px - 100px)`, maxWidth: 'calc(100% - 60px)' }}
            >
              <TaskQuestionHeader name={taskName} />
              {isLoading ? (
                <Box className="flex items-center justify-center flex-1">
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <TaskQuestions
                  taskQuestions={isTaskDraft || isDraftingQuestion ? draftLocationQuestions : currentLocationQuestions}
                />
              )}
            </Box>
          </div>
        </DeviceFrameset>
      </div>
    </div>
  );
};

export default PreviewLayout;
