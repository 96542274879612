import { useState } from 'react';

export function useUniqueList<T>(defaultList: T[] = []) {
  const [list, setList] = useState<T[]>(defaultList);

  const checkExist = (item: T) => list.includes(item);

  const toggleList = (item: T) => {
    const isExist = checkExist(item);

    if (!isExist) {
      setList([...list, item]);
    } else {
      setList(list.filter(i => i !== item));
    }
  };

  return { list, toggleList, checkExist } as const;
}
