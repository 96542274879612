import clsx from 'clsx';
import moment from 'moment';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';
import { convertDateStringToString } from 'utils/string';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Menu } from '@mui/material';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';

// theme css file

type Props = {
  onChange?: (ranges: any) => void;
  disabled?: boolean;
  value?: {
    startDate: any;
    endDate: any;
  };
  maxDate?: Date;
  noAllTime?: boolean;
  classes?: {
    input?: string;
  };
};

function DateRange({ onChange, disabled = false, value, maxDate, noAllTime = false, classes }: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ranges, setRanges] = useState({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    const { startDate, endDate } = value;
    setRanges({
      key: 'selection',
      startDate,
      endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rangesString = useMemo(() => {
    if (!ranges.startDate || !ranges.endDate) {
      return 'All Time';
    }
    const startDate: string = ranges?.startDate || 'All';
    const endDate: string = ranges?.endDate || 'Time';

    if (moment(startDate).isSame(moment(endDate), 'day')) {
      return convertDateStringToString(startDate);
    }

    return `${convertDateStringToString(startDate?.toString())} - ${convertDateStringToString(endDate?.toString())}`;
  }, [ranges]);

  const handleSelect = (newRanges: any) => {
    const { startDate } = newRanges.selection;
    const { endDate } = newRanges.selection;
    onChange?.({ startDate, endDate });
    setRanges({
      key: 'selection',
      startDate,
      endDate,
    });
  };

  const newStatistics = createStaticRanges(
    noAllTime
      ? []
      : [
          {
            label: 'All Time',
            range: () => ({
              startDate: null,
              endDate: null,
            }),
          } as any,
        ],
  );

  // Tto convert This Week and Last Week start from Monday to Sunday
  const convertedDefaultStaticRanges = defaultStaticRanges.map(range => {
    const today = moment();
    const startOfIsoWeek = today.startOf('isoWeek').toDate();
    const endOfIsoWeek = today.endOf('isoWeek').toDate();
    const startOfLastWeek = today.subtract(1, 'weeks').startOf('isoWeek').toDate();
    const endOfLastWeek = today.endOf('isoWeek').toDate();

    const rangeMap = {
      'This Week': {
        startDate: startOfIsoWeek,
        endDate: endOfIsoWeek,
        key: 'selection',
      },
      'Last Week': {
        startDate: startOfLastWeek,
        endDate: endOfLastWeek,
        key: 'selection',
      },
    };

    return {
      ...range,
      range: () => rangeMap[range.label as keyof typeof rangeMap] || range.range(),
    };
  });

  return (
    <>
      <button
        type="button"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Input
          className={clsx('px-8 h-36 text-dark w-224 bg-paper', classes?.input)}
          value={rangesString}
          disabled={disabled}
          style={{ background: theme.palette.background.paper }}
          disableUnderline={true}
          readOnly={true}
          endAdornment={
            <InputAdornment position="end">
              <DateRangeIcon className="text-18" />
            </InputAdornment>
          }
        />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: 2 }}
      >
        <DateRangePicker
          className="mt-8"
          ranges={[ranges]}
          onChange={handleSelect}
          months={2}
          direction="horizontal"
          maxDate={maxDate}
          inputRanges={[]}
          staticRanges={[...newStatistics, ...convertedDefaultStaticRanges]}
          weekStartsOn={1}
        />
      </Menu>
    </>
  );
}

export default DateRange;
