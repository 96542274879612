import { TOption } from 'models';
import { ComponentProps } from 'react';
import { Control, useController } from 'react-hook-form';

import CreatableChipSelect from './creatable-chip-select';

type Props = {
  name: string;
  control: Control;
  onChangeCallback?: (value: TOption[]) => void;
} & ComponentProps<typeof CreatableChipSelect>;

function ControlledCreatableChipSelect({ name, control, onChangeCallback, ...props }: Props) {
  const {
    field: { value, onChange, onBlur },
  } = useController({ name, control });

  const handleChange = (newValue: unknown) => {
    const newValues = newValue as TOption[];
    onChange(newValues);
    onChangeCallback?.(newValues);
  };

  return (
    <CreatableChipSelect
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      {...props}
    />
  );
}

export default ControlledCreatableChipSelect;
