import { type TActionItem } from 'models/audit.model';
import { EmployeeAction, type EmployeeAddActionFormValues } from 'models/employee/action.model';
import moment from 'moment';
import { adjustToLocalTimezone, formatDate } from 'utils/date';

export const convertActionToFormValues = (action: TActionItem) => {
  const adjustDate = adjustToLocalTimezone(action.displayAt);
  const startDate = new Date(adjustDate).toISOString();
  const startTime = formatDate(adjustDate, 'HH:mm');

  let endDate;
  let endTime;
  if (action?.expireAt) {
    const adjustEndDate = adjustToLocalTimezone(action.expireAt);
    endDate = new Date(adjustEndDate).toISOString();
    endTime = formatDate(adjustEndDate, 'HH:mm');
  }

  const users = {
    userIds: action.users?.map(user => user.id),
    users: action.users?.map(user => ({
      id: user.id,
      name: user.fullName,
    })) as any,
  };

  const orgRoles = {
    ids: action.orgRoles?.map(role => role.externalId),
    names: action.orgRoles?.map(
      role =>
        ({
          id: role.externalId,
          name: role.name,
          externalId: role.externalId,
        } as any),
    ),
  };

  return {
    startDate,
    startTime,
    endDate,
    endTime,
    users,
    orgRoles,
    description: action.description,
    important: action.important,
  };
};

export function convertFormValuesToSubmitValues(values: EmployeeAddActionFormValues) {
  const { startTime, endTime, description, important, orgRoles, users, startDate, endDate } = values;

  // if AM, convert to 00:00 AM
  const formattedStartTime = startTime.includes('AM') ? moment(startTime, 'h:mm A').format('HH:mm') : startTime;
  const formattedEndTime = endTime?.includes('AM') ? moment(endTime, 'h:mm A').format('HH:mm') : endTime;

  const body: EmployeeAction = {
    description,
    important,
    orgRoleIds: orgRoles.ids,
    userIds: users?.userIds?.length > 0 ? users.userIds : undefined,
    displayAt: `${formatDate(startDate, 'YYYY/MM/DD')} ${formattedStartTime}`,
    expireAt: endDate && endTime ? `${formatDate(endDate, 'YYYY/MM/DD')} ${formattedEndTime}` : undefined,
  };

  return body;
}
