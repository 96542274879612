import { useGetLocationTasks } from 'app/hooks/api/locations';
import useShowMessage from 'app/hooks/use-show-message';
import _ from 'lodash';
import { TTaskBasic } from 'models';
import { useEffect, useState } from 'react';
import { generateQueryObject } from 'utils/string';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, IconButton, Modal, Typography } from '@mui/material';

import TaskTransferList from './transfer-list';

const style = {
  position: 'absolute',
  inset: 36,
  bgcolor: 'background.default',
  boxShadow: 24,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};

type Props = {
  locationId: number;
  taskIds: number[];
  open: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onAddLists: (taskIds: number[]) => void;
};

function LocationAssignListModal({ locationId, open, isLoading, onAddLists, onClose, taskIds }: Props) {
  const { showError } = useShowMessage();

  const [selected, setSelected] = useState<TTaskBasic[]>([]);
  const [chosenListFilters, setChosenListFilters] = useState([]);

  const [filters, setFilters] = useState([]);
  const search = generateQueryObject(filters);

  // get inactive lists of location to show them to left columns of transfer list
  const [filter] = useState<{ [key: string]: any }>({
    page: 1,
    per_page: 200,
    'q[assignment_status_eq]': 'inactive',
    'q[status_eq]': 'published',
  });

  const { data } = useGetLocationTasks(locationId, { ...filter, ...search });
  const lists = data?.records || [];

  useEffect(() => {
    const selectedItems = _.filter(lists, item => _.includes(taskIds, item.id));
    setSelected(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskIds]);

  const handleUpdateList = (nextLists: any) => {
    setSelected(nextLists);
  };

  const handleReset = () => {
    setSelected([]);
  };

  const handleAddLists = () => {
    if (_.isEmpty(selected)) {
      showError('Please assign at least one list to the location');
      return;
    }

    const newTaskIds = _.map(selected, 'id');
    onAddLists?.(newTaskIds);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={style}>
        <div className="flex items-start justify-between pt-12 pb-8 pl-24 pr-12 mb-16 border-b-1">
          <div className="flex-1">
            <Typography className="text-18 font-600">Add Lists</Typography>
            <Typography className="text-13 text-secondaryLight">
              Select and transfer lists from left column to right column to add lists
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon className="text-20 text-secondaryMain" />
          </IconButton>
        </div>

        <div className="flex flex-col flex-1 my-16 mb-48 overflow-hidden">
          <Typography className="px-24 text-12 font-600">Assign List(s)</Typography>
          <TaskTransferList
            values={selected}
            options={lists}
            filters={filters}
            setFilters={setFilters}
            chosenListFilters={chosenListFilters}
            setChosenListFilters={setChosenListFilters}
            onChange={handleUpdateList}
            onFetchData={() => {}}
          />
        </div>

        <div className="absolute bottom-0 left-0 flex items-center justify-end w-full p-16 space-x-8 bg-white border-t-1 rounded-8">
          <Button
            disableElevation={true}
            variant="outlined"
            color="primary"
            className="text-11 font-500 w-80"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="text-11 font-500"
            onClick={handleAddLists}
          >
            Add Lists
          </Button>
        </div>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-25 z-99">
            <CircularProgress size={32} />
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default LocationAssignListModal;
