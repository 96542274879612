import { useGetMediaEncoded } from 'app/hooks/api/media';
import { useParams } from 'react-router';

interface ParamsTypes {
  encode: string;
}

function MediaPreview() {
  const routeParams = useParams<ParamsTypes>();
  const encode = routeParams?.encode;
  const { data } = useGetMediaEncoded(encode || '');
  const fileUrl = data?.file?.url;
  const fileName = data?.file?.name;

  if (!encode || typeof data === 'string') return null;

  return (
    <img
      alt={fileName}
      src={fileUrl}
      className="mx-auto"
    />
  );
}

export default MediaPreview;
