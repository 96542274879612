/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import ChipCell from 'app/components/cores/index-table/cells/chip';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import HistoryHeaderFilter from 'app/components/histories/header-filter';
import { useGetLocations } from 'app/hooks/api/locations';
import { useGetRoles } from 'app/hooks/api/roles';
import { useGetTags } from 'app/hooks/api/tags';
import clsx from 'clsx';
import { Paths, TIME_FORMAT } from 'constants/index';
import { THistory } from 'models';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { convertDateStringToString } from 'utils/string';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  backgroundLate: {
    background: '#FFF8DB',
  },
  backgroundOnTime: {
    background: '#F2FCFF',
  },

  backgroundExpired: {
    background: '#F7F7F7',
  },
}));

type Props = {
  histories: THistory[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  filters: any;
  setFilters: any;
  onFetchData: (params: any) => void;
  ignoreFilters?: string[];
  historyFilters: any;
  setHistoryFilters: Dispatch<SetStateAction<any>>;
};

function HistoriesList({
  histories,
  totalDataCount,
  isLoading,
  searchKey,
  filters,
  setFilters,
  pageCount,
  ignoreFilters,
  onFetchData,
  historyFilters,
  setHistoryFilters,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const statusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Completed On Time', value: 'completed_on_time' },
    { label: 'Completed Late', value: 'completed_late' },
    { label: 'Expired', value: 'expired' },
  ];

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'location',
        id: 'location_external_id',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'location_ids_in',
            optionValue: 'externalId',
            keySearch: 'q[name_cont]',
            useFetchData: useGetLocations,
            ...props,
          }),
        Cell: ({ cell }: { cell: Cell<THistory> }) => {
          return <Typography>{cell.value.name}</Typography>;
        },
      },
      {
        Header: 'Role',
        accessor: 'roles',
        id: 'org_role_id_in',
        sortable: false,
        width: '10%',
        className: 'capitalize',
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'role_ids_in',
            optionValue: 'externalId',
            keySearch: 'q[name_cont]',
            useFetchData: useGetRoles,
            ...props,
          }),
        Cell: ({ cell }: { cell: Cell<THistory> }) => {
          return <Typography>{cell.value?.[0]?.name}</Typography>;
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        sortable: false,
        id: 'task_root_tags_id',
        width: '10%',
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'task_root_tags_id_in',
            useFetchData: useGetTags,
            ...props,
          }),
        Cell: ({ row }: { row: Row<THistory> }) => {
          const tags = row.original?.tags || [];
          return <ChipCell items={tags} />;
        },
      },
      {
        Header: 'List',
        accessor: 'task.name',
        id: 'task_root_name',
        sortable: false,
        width: '15%',
        Cell: ({ cell, row }: { cell: Cell<THistory>; row: Row<THistory> }) => {
          return (
            <div className="flex flex-col">
              <Link
                onClick={event => {
                  event.stopPropagation();
                }}
                to={`/histories/lists/${row.original.id}`}
              >
                <Typography className="capitalize font-600">{cell.value}</Typography>
              </Link>
              <Typography className="text-11 text-secondaryLight">
                {convertDateStringToString(row.original.displayAt, 'DD/MM/YYYY HH:mm')}
              </Typography>
            </div>
          );
        },
      },
      {
        Header: 'Employees',
        accessor: 'user.fullName',
        id: 'user',
        sortable: false,
        width: '15%',
        className: 'capitalize',
        Filter: <></>,
        Cell: ({ row, cell }: { cell: Cell<THistory>; row: Row<THistory> }) => {
          const { individual } = row.original.task;
          return (
            <>
              {individual ? (
                <Typography className="font-500">{cell.value}</Typography>
              ) : (
                <div className="flex flex-row w-full">
                  <Typography
                    className="break-normal font-500"
                    component="span"
                  >
                    {row.original.users?.map(x => x?.fullName).join(', ')}
                  </Typography>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'Time Submitted',
        accessor: 'completedAt',
        id: 'completedAt',
        sortable: true,
        width: '10%',
        className: 'capitalize',
        Filter: () => (
          <HistoryHeaderFilter
            setHistoryFilters={setHistoryFilters}
            historyFilters={historyFilters}
          />
        ),
        Cell: ({ cell }: { cell: Cell<THistory> }) => {
          return (
            <div className="flex flex-col">
              <Typography>{convertDateStringToString(cell.value)}</Typography>
              <Typography className="text-11 text-secondaryLight">
                {convertDateStringToString(cell.value, TIME_FORMAT)}
              </Typography>
            </div>
          );
        },
      },

      {
        Header: 'Status',
        accessor: 'submitStatus',
        id: 'submit_status',
        sortable: false,
        width: '15%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell, row }: { cell: Cell<THistory>; row: Row<THistory> }) => {
          const isOnDemand = row.original.onDemand;
          const status = cell.value;
          let backgroundColor;
          let textColor;
          switch (status) {
            case 'Completed Late':
              backgroundColor = classes.backgroundLate;
              textColor = '#806C14';
              break;

            case 'Expired':
              textColor = '#E0115F';
              backgroundColor = classes.backgroundExpired;
              break;

            default:
              textColor = 'primary';
              backgroundColor = classes.backgroundOnTime;
              break;
          }
          if (isOnDemand) {
            return null;
          }
          return (
            <div className={clsx('p-4 text-center  rounded-16', backgroundColor)}>
              <Typography
                color={textColor}
                className="text-11 font-500"
              >
                {status}
              </Typography>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleRowClick = (item: any) => {
    history.push(`${Paths.history}/lists/${item.original.id}`);
  };

  return (
    <div className="flex flex-col w-full px-12">
      <IndexTable
        columns={columns as Column<any>[]}
        pageCount={pageCount}
        data={histories}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        ignoreFilters={ignoreFilters}
        withSelection={false}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default HistoriesList;
