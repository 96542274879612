import queryClient from 'app/configs/query-client';
import { TReportPerformanceMetadata } from 'models';

import { useExportCSV, useGetOne } from '../request';

const reportURL = '/api/admin/locations/performance';
const exportURL = '/api/admin/locations/performance/export';

export const performanceReportKeys = {
  all: ['performanceReport'] as const,
  lists: () => [...performanceReportKeys.all, 'list'] as const,
  list: (filters: any) => [...performanceReportKeys.lists(), { filters }] as const,
};

export const refetchTask = () => queryClient.invalidateQueries(performanceReportKeys.all);

export function useGetPerformanceReports(filters: any, options?: any) {
  return useGetOne<TReportPerformanceMetadata>([...performanceReportKeys.list(filters)], `${reportURL}`, filters, {
    refetchOnMount: true,
    ...options,
  });
}

export function useExportReport() {
  return useExportCSV(exportURL);
}
