import ReactSelect, { components as ReactSelectComponents, Props as ReactSelectProps } from 'react-select';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const IndicatorSeparator = () => null;

const DropdownIndicator = (props: any) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <ArrowDropUpIcon className="text-20 text-secondaryMain" />
      ) : (
        <ArrowDropDownIcon className="text-20 text-secondaryMain" />
      )}
    </ReactSelectComponents.DropdownIndicator>
  );
};

const SingleValue = (props: any) => {
  return (
    <ReactSelectComponents.SingleValue {...props}>
      <Typography className="inline-block text-13 font-600">{props.children}</Typography>
    </ReactSelectComponents.SingleValue>
  );
};

const Option = (props: any) => {
  const { isSelected } = props;
  return (
    <ReactSelectComponents.Option {...props}>
      <Button
        disableElevation={true}
        variant={isSelected ? 'contained' : 'outlined'}
        color="primary"
        className="mb-8 text-13 font-500 rounded-16 min-w-48 h-28"
      >
        {props.children}
      </Button>
    </ReactSelectComponents.Option>
  );
};

const MultiValue = (props: any) => {
  return (
    <ReactSelectComponents.MultiValue {...props}>
      <Typography className="text-white text-11 font-500">{props.children}</Typography>
    </ReactSelectComponents.MultiValue>
  );
};

const Placeholder = (props: any) => {
  return (
    <ReactSelectComponents.Placeholder {...props}>
      <Typography className="inline-block text-13 font-400 text-secondaryLight">{props.children}</Typography>
    </ReactSelectComponents.Placeholder>
  );
};

const MenuList = (props: any) => {
  const { options, setValue, selectProps } = props || {};
  const { menuLabel, isSelectAll } = selectProps || {};

  const handleSelectAll = () => setValue?.(options);
  const handleDeselectAll = () => setValue?.([]);

  return (
    <ReactSelectComponents.MenuList {...props}>
      <Typography className="mb-16 ml-16 text-11 font-500 text-secondaryLight">{menuLabel || 'Select'}</Typography>
      <div className="px-8">{props.children}</div>
      {isSelectAll && (
        <div className="flex items-center justify-end px-16 pt-16 pb-8 space-x-8">
          <Button
            disableElevation={true}
            variant="outlined"
            color="primary"
            className="px-0 text-10 font-400 min-w-96 w-96 h-28"
            onClick={handleDeselectAll}
          >
            Deselect All
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="px-0 text-10 font-400 min-w-96 w-96 h-28"
            onClick={handleSelectAll}
          >
            Select All
          </Button>
        </div>
      )}
    </ReactSelectComponents.MenuList>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <ReactSelectComponents.ClearIndicator {...props}>
      <CloseIcon className="text-16" />
    </ReactSelectComponents.ClearIndicator>
  );
};

type Props = {
  isSelectAll?: boolean;
  menuLabel?: string;
} & ReactSelectProps;

function FullItemViewMultipleChipSelect(props: Props) {
  const theme = useTheme();

  const components = {
    IndicatorSeparator,
    DropdownIndicator,
    SingleValue,
    Option,
    MultiValue,
    Placeholder,
    MenuList,
    ClearIndicator,
    ...props.components,
  };

  const styles = {
    control: (baseStyles: any) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      minHeight: 32,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
      paddingRight: 4,
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      borderRadius: 8,
      paddingTop: 4,
      paddingBottom: 4,
      boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
    }),
    option: (baseStyles: any) => ({
      ...baseStyles,
      display: 'inline',
      backgroundColor: 'unset !important',
      padding: 4,
    }),
    multiValue: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderRadius: 4,
      paddingLeft: 4,
      backgroundColor: state?.isDisabled ? theme.palette.grey[300] : theme.palette.primary.main,
      color: theme.palette.background.default,
    }),
    multiValueRemove: (baseStyles: any) => ({
      ...baseStyles,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    }),
    ...props.styles,
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: theme.palette.primary.light,
      primary25: '#eee',
      primary: theme.palette.primary.main,
    },
  });

  return (
    <ReactSelect
      {...props}
      components={components}
      styles={styles}
      theme={generateTheme}
      isMulti={true}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      backspaceRemovesValue={false}
      isClearable={true}
      isSearchable={false}
    />
  );
}

export default FullItemViewMultipleChipSelect;
