import ListTaskLayout from 'app/components/histories/list-tasks/layout';
import { useGetHistory } from 'app/hooks/api/manager/histories';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatDate } from 'utils/date';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type Props = {
  listId: number | null;
  onClose: () => void;
  auditName: string;
  user: string;
  date: string;
};

const FlaggedItemsModal = ({ listId, onClose, auditName, user, date }: Props) => {
  const { data: employeeTask } = useGetHistory(listId!);
  const form = useForm();

  useEffect(() => {
    if (!employeeTask) return;

    const flaggedEmployeeTasks = {
      ...employeeTask,
      responses: employeeTask?.responses?.filter((response: any) => response.flagged),
    };

    form.reset(flaggedEmployeeTasks);
  }, [employeeTask, form]);

  const list = form.getValues();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={!!listId}
        classes={{
          paper: 'rounded-16',
        }}
      >
        <DialogTitle
          component="div"
          className="flex justify-between items-start space-y-8 normal-case bg-white"
        >
          <div className="flex-1">
            <Typography className="text-13 text-secondaryMain font-600">Flagged Items</Typography>
            <Typography className="mt-8 text-18 text-secondaryMain font-600">{auditName}</Typography>
            <div className="flex gap-8 justify-between items-start w-full">
              <Typography className="mt-8 text-13 text-secondaryLight font-500">
                {user} | {formatDate(date, 'HH:mm - DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
          <IconButton
            aria-label="close"
            sx={{ right: 8, top: 8 }}
            className="absolute text-16"
            onClick={onClose}
          >
            <CloseIcon className="text-black text-20" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className="flex overflow-y-auto flex-col gap-4 px-24 bg-white h-512"
          dividers
        >
          <FormProvider {...form}>
            <ListTaskLayout
              responses={list?.responses}
              location={list?.location?.name}
              isAudit
              canAuditInteractions={false}
            />
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FlaggedItemsModal;
