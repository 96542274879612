import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import { components as ReactSelectComponents } from 'react-select';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  menu: {
    width: '512px !important',
  },
  flex: {
    display: 'flex !important',
  },
}));

export type MultipleValueProps = {
  placeholder: string;
  type: string;
  value?: any;
};

export const LocationValueContainer = (props: any) => {
  const classes = useStyles();
  const { selectProps, children } = props || {};
  const { value, inputValue } = selectProps || {};
  const input = _.last(children);

  return (
    <ReactSelectComponents.ValueContainer
      {...props}
      className={clsx('items-center', classes.flex)}
    >
      <MultipleValue
        value={value}
        placeholder={inputValue ? '' : 'Locations'}
        type="location"
      />
      {input}
    </ReactSelectComponents.ValueContainer>
  );
};

export const ListValueContainer = (props: any) => {
  const classes = useStyles();
  const { selectProps, children } = props || {};
  const { value, inputValue } = selectProps || {};
  const input = _.last(children);

  return (
    <ReactSelectComponents.ValueContainer
      {...props}
      className={clsx('items-center', classes.flex)}
    >
      <MultipleValue
        value={value}
        placeholder={inputValue ? '' : 'All Lists'}
        type="list"
      />
      {input}
    </ReactSelectComponents.ValueContainer>
  );
};

const MultipleValue = ({ placeholder, type, value }: MultipleValueProps) => {
  const selectedLength = value?.length;
  const isEmpty = !selectedLength;
  return (
    <Typography className="text-13 font-400">
      {isEmpty ? placeholder : `${selectedLength} ${pluralize(type, selectedLength)} selected`}
    </Typography>
  );
};

export function getDefaultDateRange(
  defaultFilter: any,
  options: {
    fromDateName: string;
    toDateName: string;
    dateFormat: string;
  } = {
    fromDateName: 'q[display_at_gteq]',
    toDateName: 'q[display_at_lteq]',
    dateFormat: 'DD/MM/YYYY',
  },
) {
  const defaultStartDateFilterValue = defaultFilter?.[options.fromDateName];
  const defaultEndDateFilterValue = defaultFilter?.[options.toDateName];

  if (!defaultStartDateFilterValue || !defaultEndDateFilterValue) {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  const startDate = moment(defaultStartDateFilterValue, options.dateFormat).toDate();
  const endDate = moment(defaultEndDateFilterValue, options.dateFormat).toDate();
  return { startDate, endDate };
}

export const Menu = (props: any) => {
  const classes = useStyles();
  return (
    <ReactSelectComponents.Menu
      {...props}
      className={clsx(classes.menu)}
    />
  );
};
