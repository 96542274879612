import { Control, useController } from 'react-hook-form';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type Props = { label: string; name: string; onChange?: (checked: boolean) => void; control?: Control };

const CheckboxDefault = ({ name, label, onChange: onChangeProp, control }: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const handleChange = (_: unknown, checked: boolean) => {
    onChange(checked);
    onChangeProp?.(checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
          icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
        />
      }
      label={<Typography className="text-12 font-400">{label}</Typography>}
      onChange={handleChange}
    />
  );
};
export default CheckboxDefault;
