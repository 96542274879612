import { useDuplicate } from 'app/hooks/use-duplicate';

import { Skeleton } from '@mui/material';

interface Props {
  haveSubmit?: boolean;
}

export const TaskContentHeaderSkeleton = ({ haveSubmit = true }: Props) => {
  const title = useDuplicate({
    initialCount: 2,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={32}
        className="mt-4 rounded-8"
      />
    ),
  });

  const time = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="45%"
        height={18}
        className="mt-8 mb-8 rounded-8"
      />
    ),
  });

  const refreshButton = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="circular"
        width={32}
        height={32}
      />
    ),
  });

  const timeRange = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width={140}
        height={40}
        className="rounded-8"
      />
    ),
  });

  const submitBtn = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width={140}
        height={40}
        className="rounded-8"
      />
    ),
  });

  const divider = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={1}
      />
    ),
  });

  return (
    <>
      <div className="flex items-start justify-between p-16 pb-8 space-x-8">
        <div className="flex-1">
          {title}
          {time}
        </div>
        {haveSubmit && (
          <div className="flex items-center flex-initial pt-4 space-x-8">
            {refreshButton} {timeRange} {submitBtn}
          </div>
        )}
      </div>
      {divider}
    </>
  );
};

export const TaskContentBodySkeleton = () => {
  const title = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="20%"
        height={36}
        className="mt-4 rounded-8"
      />
    ),
  });

  const longContent = useDuplicate({
    initialCount: 3,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={20}
        className="mt-4 rounded-8"
      />
    ),
  });

  const shortContent = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="30%"
        height={18}
        className="mt-4 rounded-8"
      />
    ),
  });

  const textEditor = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={184}
        className="mt-4 rounded-16"
      />
    ),
  });

  const divider = useDuplicate({
    initialCount: 1,
    component: (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={1}
      />
    ),
  });

  return (
    <div>
      <div className="p-16 space-y-8">
        {title}

        <div className="pt-8 pb-8">
          {longContent} {shortContent}
        </div>

        {textEditor}
      </div>
      {divider}
    </div>
  );
};

export const DuplicateTaskContentBodySkeleton = () => {
  const duplicate = useDuplicate({
    initialCount: 3,
    component: <TaskContentBodySkeleton />,
  });

  return <>{duplicate}</>;
};
