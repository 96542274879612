import { LOCALE_DATE_FORMAT } from 'constants/index';
import { useCallback } from 'react';
import { formatDate, formatDateByLocationTimezone } from 'utils/date';

type InputType = Date | string | null | undefined;

/**
 * Custom hook for handling date and time with timezone.
 *
 * @param {string | undefined} timezone - The timezone to be used for formatting the date and time. If not provided, the default timezone will be used.
 * @param {boolean} disabled - (Optional) Flag indicating whether the hook is disabled or not. Default is false.
 * @returns {Object} An object containing utility functions for handling date and time with timezone.
 *
 * @example
 * const { extractDateTimeInTz, checkSameDateTz, checkTodayTz, getDateTz } = useDateTimeTz(timezone);
 */
export const useDateTimeTz = (timezone?: string | undefined, disabled = false) => {
  /**
   * Formats the provided date in the provided timezone.
   * @param {InputType} date - The date to format.
   * @param {string} format - The format to use for formatting the date. Default is LOCALE_DATE_FORMAT.
   * @returns {string} The formatted date.
   *
   * if timezone is not provided or disabled, return the date in the default format
   * if disabled is true, return the date in the default format
   */
  const getDateTz = useCallback(
    (date: InputType, format = LOCALE_DATE_FORMAT) => {
      if (!timezone || disabled) return formatDate(date, format);
      return formatDateByLocationTimezone(date, timezone, format);
    },
    [timezone, disabled],
  );

  /**
   * Checks if the two dates are the same in the provided timezone.
   * @param {InputType} time1 - The first date.
   * @param {InputType} time2 - The second date.
   * @returns {boolean} True if the two dates are the same in the provided timezone, false otherwise.
   * @example
   * const isSameDate = checkSameDateTz(date1, date2);
   */
  const checkSameDateTz = (time1: InputType, time2: InputType) => {
    const start = getDateTz(time1);
    const end = getDateTz(time2);

    return start === end;
  };

  /**
   * Extracts the date and time in the provided timezone.
   * @param {InputType} startDate - The start date.
   * @param {InputType} endDate - The end date.
   * @returns {Object} An object containing the extracted date and time in the provided timezone.
   * @example
   * const { tzStartDate, tzStartTime, tzEndDate, tzEndTime } = extractDateTimeInTz(startDate, endDate);
   */
  const extractDateTimeInTz = (startDate: InputType, endDate: InputType) => {
    const tzStartDate = getDateTz(startDate, 'DD/MM');
    const tzStartTime = getDateTz(startDate, 'HH:mm');
    const tzEndDate = getDateTz(endDate, 'DD/MM');
    const tzEndTime = getDateTz(endDate, 'HH:mm');

    return {
      tzStartDate,
      tzStartTime,
      tzEndDate,
      tzEndTime,
    };
  };

  /**
   * Checks if the provided date is today in the provided timezone.
   * @param {InputType} date - The date to check.
   * @returns {boolean} True if the provided date is today in the provided timezone, false otherwise.
   */
  const checkTodayTz = (date: InputType) => {
    return checkSameDateTz(date, new Date());
  };

  return { extractDateTimeInTz, checkSameDateTz, checkTodayTz, getDateTz };
};
