import PropTypes from 'prop-types';
import React from 'react';

import FuseLoading from '@fuse/core/FuseLoading';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */ function FuseSuspense(props) {
  const { loadingProps = { delay: 0 } } = props;
  return <React.Suspense fallback={<FuseLoading {...loadingProps} />}>{props.children}</React.Suspense>;
}

FuseSuspense.propTypes = {
  loadingProps: PropTypes.object,
};

export default FuseSuspense;
