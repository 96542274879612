import { clearDownloadingMediaResponseId, setDownloadingMediaResponseId } from 'app/store/report/mediaResponseSlice';
import clsx from 'clsx';
import { mediaResponseCache } from 'domains/list-report.domain';
import { toPng } from 'html-to-image';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateImageSize } from 'utils/image';

import * as browserDetection from '@braintree/browser-detection';
import { CircularProgress, Typography } from '@mui/material';

const isSafari = browserDetection.isSafari();

type Props = {
  answer: any;
  responseId?: number;
};

function ReportTableMediaCell({ answer, responseId }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!answer || answer === 'Not Applicable' || !responseId) {
      setIsLoading(false);
      return;
    }

    const imgElement = document.getElementById(`media-${responseId}`) as any;
    if (!imgElement) return;

    const { width, height } = generateImageSize(160, '16:9');
    const formatOptions = {
      width,
      height,
      cacheBust: true,
      quality: 0.8, // seems like this option doesn't work
      fontEmbedCSS: '', // prevent download project fonts
    };

    dispatch(setDownloadingMediaResponseId(responseId));

    if (!isSafari) {
      toPng(imgElement, formatOptions)
        .then(newDataUrl => {
          mediaResponseCache.setResponseBase64(Number(responseId), newDataUrl);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          dispatch(clearDownloadingMediaResponseId(responseId));
        });
      return;
    }

    (async () => {
      const minDataLength = 1000;
      const maxAttempts = 30;
      let i = 0;
      let dataUrl = await toPng(imgElement, formatOptions);

      if (dataUrl?.length > minDataLength) {
        setIsLoading(false);
        mediaResponseCache.setResponseBase64(Number(responseId), dataUrl);
        dispatch(clearDownloadingMediaResponseId(responseId));
        return;
      }

      const interval = setInterval(async () => {
        const handleClearInterval = (nextDataUrl: string) => {
          clearInterval(interval);
          mediaResponseCache.setResponseBase64(Number(responseId), nextDataUrl);
          dispatch(clearDownloadingMediaResponseId(responseId));
        };

        if (dataUrl?.length < minDataLength && i < maxAttempts) {
          await toPng(imgElement, formatOptions)
            .then(newDataUrl => {
              dataUrl = newDataUrl;

              if (newDataUrl?.length > minDataLength) {
                handleClearInterval(newDataUrl);
              }
            })
            .catch(() => {})
            .finally(() => {
              setIsLoading(false);
            });
          i += 1;
        } else {
          handleClearInterval(dataUrl);
        }
      }, 50);
    })();
  }, [responseId, answer, dispatch]);

  if (!answer) {
    return <Typography className="text-13 font-400">-</Typography>;
  }

  if (answer === 'Not Applicable') {
    return <Typography className="text-13 font-400">Not Applicable</Typography>;
  }

  return (
    <div className={clsx(isLoading && 'relative')}>
      <img
        id={`media-${responseId}`}
        alt="answer"
        src={answer}
        className="object-contain rounded-4 w-96 aspect-video"
      />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          <CircularProgress size={12} />
        </div>
      )}
    </div>
  );
}

export default ReportTableMediaCell;
