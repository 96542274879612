import clsx from 'clsx';
import { FREQUENCIES } from 'constants/index';
import { Frequency as RRuleFrequency } from 'rrule';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  frequency?: RRuleFrequency;
  onChange?: (nextType: RRuleFrequency) => void;
};

export function FrequencyType({ frequency, onChange }: Props) {
  const classes = useStyles();

  const handleSelectType = (nextType: RRuleFrequency) => () => {
    onChange?.(nextType);
  };

  return (
    <div>
      {FREQUENCIES?.map((item: any) => (
        <div
          key={item.value}
          className={clsx('inline-block w-128 pr-8 last:pr-0', classes.button)}
        >
          <Button
            disableElevation={true}
            color="primary"
            variant="contained"
            className={clsx(
              'text-11 font-500 h-32 rounded-8 bg-paper mt-8 mr-8 min-w-0 w-full',
              classes.buttonWhite,
              frequency === item.value && classes.buttonSelected,
            )}
            onClick={handleSelectType(item.value)}
          >
            {item.label}
          </Button>
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  button: {
    maxWidth: 'calc(100% / 4)',
  },
  buttonWhite: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.background.default,
  },
}));
