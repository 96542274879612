import { ControlledTextInput } from 'app/components/cores/inputs/text';
import { MultipleConditionSelect } from 'app/components/cores/items-editor/conditional-logic/display/multiple-condition-select';
import { MultipleLogicSelect } from 'app/components/cores/items-editor/conditional-logic/display/multiple-logic-select';
import { CONDITIONAL_DEFAULT_OPTIONS, CONDITIONAL_NUMERIC_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TItem } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, IconButton } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  hasComparisons?: boolean;
  onRemoveAllLogic?: () => void;
};

// TODO optimize this
export function MultipleCondition({ control, name, selectedConditionalItem, hasComparisons, onRemoveAllLogic }: Props) {
  const [isShowMoreLogic, setIsShowMoreLogic] = useState(false);
  const fieldName = `${name}`;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: fieldName,
  });

  const firstFieldLogic = (fields?.[0] as any)?.logic;
  const isMaxConditional = fields?.length >= CONDITIONAL_NUMERIC_OPTIONS?.length;

  const isCustomConditional = useMemo(() => {
    if (!firstFieldLogic) return false;
    return !['completed', 'not_completed', 'not_applicable'].includes(firstFieldLogic);
  }, [firstFieldLogic]);

  const defaultCondition = useMemo(() => {
    if (!firstFieldLogic) return undefined;

    return !isCustomConditional
      ? _.find(CONDITIONAL_DEFAULT_OPTIONS, { conditionalLogic: firstFieldLogic })
      : CONDITIONAL_DEFAULT_OPTIONS[0];
  }, [firstFieldLogic, isCustomConditional]);

  const mappedFields = useMemo(
    () =>
      _.map(fields, (field: any) => ({
        ..._.cloneDeep(field),
        conditionalLogic: field.logic,
      })),
    [fields],
  );

  useEffect(() => {
    setIsShowMoreLogic(isCustomConditional);
  }, [isCustomConditional]);

  const handleChangeDefaultLogic = (item: any) => {
    const isCustomize = item.conditionalLogic === 'custom';

    remove();
    setIsShowMoreLogic(isCustomize);

    if (isCustomize) {
      append({
        ..._.cloneDeep(item),
        logic: 'eq',
        conditionalLogic: 'eq',
        conditionalLabel: 'Equal to',
        value: '',
      });
    } else {
      append({
        logic: item.conditionalLogic,
        ..._.cloneDeep(item),
      });
    }
  };

  const handleChangeConditional = (index: number) => (item: any) => {
    update(index, {
      logic: item.conditionalLogic,
      ...item,
    });
  };

  const handleAddMoreConditional = () => {
    if (isMaxConditional) return;
    append({
      logic: '',
      conditionalLogic: '',
      conditionalLabel: '',
      value: '',
    });
  };

  const handleRemoveConditional = (index: number) => () => {
    remove(index);
    if (fields?.length === 1) {
      onRemoveAllLogic?.();
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <MultipleConditionSelect
          name={fieldName}
          control={control}
          selectedConditionalItem={selectedConditionalItem}
          defaultCondition={defaultCondition}
          instructionText="Flag this task, if answer is"
          onChange={handleChangeDefaultLogic}
        />
        {hasComparisons && !isShowMoreLogic && (
          <Button
            disableElevation={true}
            variant="outlined"
            color="error"
            className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
            onClick={onRemoveAllLogic}
          >
            Remove
          </Button>
        )}
      </div>

      {isShowMoreLogic ? (
        <div className="mt-12">
          <div className="mb-16 space-y-4">
            {mappedFields?.map((field: any, index: number) => (
              <div
                key={field.id}
                className="flex items-start space-x-8"
              >
                <MultipleLogicSelect
                  isCustomize={true}
                  selectedConditionalItem={selectedConditionalItem}
                  conditionalComparison={mappedFields}
                  value={field}
                  placeholder="Select conditional"
                  className="mt-4"
                  onChange={handleChangeConditional(index)}
                />
                <div className="flex-1">
                  <ControlledTextInput
                    disabled={!field?.logic}
                    type="number"
                    name={`${name}[${index}].value`}
                    control={control}
                    variant="filled"
                    className="h-full shadow"
                    InputProps={{
                      className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
                      disableUnderline: true,
                      classes: {
                        input: 'p-8 text-11',
                      },
                    }}
                  />
                </div>
                <div>
                  <IconButton onClick={handleRemoveConditional(index)}>
                    <DeleteOutlineIcon className="text-16" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-end space-x-4">
            <Button
              disableElevation={true}
              variant="outlined"
              color="error"
              className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
              onClick={onRemoveAllLogic}
            >
              Remove
            </Button>
            <Button
              disabled={isMaxConditional}
              disableElevation={true}
              variant="contained"
              color="primary"
              className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
              onClick={handleAddMoreConditional}
            >
              Add
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
