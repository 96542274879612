import { Typography } from '@mui/material';

function DisplayTimePreviewHeader() {
  return (
    <div className="sticky top-0 grid grid-cols-12 px-24 mb-8">
      <div className="col-span-4">
        <Typography className="text-12 text-secondaryLight font-500">Display Time</Typography>
      </div>
      <div className="col-span-4">
        <Typography className="text-12 text-secondaryLight font-500">Due In</Typography>
      </div>
      <div className="col-span-4">
        <Typography className="text-12 text-secondaryLight font-500">Expire In</Typography>
      </div>
    </div>
  );
}

export default DisplayTimePreviewHeader;
