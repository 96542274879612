import RadioGroup from 'app/components/cores/inputs/radio-group';
import TimePicker from 'app/components/cores/time-picker/time-picker';
import AttributeContainer from 'app/components/lists/attribute-container';
import { TIME_FORMAT } from 'constants/index';
import { formatTimeValue } from 'domains/display-time.domain';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';

type Props = {
  label: string;
  options: any;
  name: string;
  control?: Control;
};

function TimeAssignment({ label, options, name, control }: Props) {
  const [openingHour, setOpeningHour] = useState(false);
  const {
    field: { value: timeValue, onChange: onTimeChange, ref: timeRef },
  } = useController({
    name,
    control,
  });

  // used for old data
  const formattedValue = useMemo(() => formatTimeValue(timeValue), [timeValue]);

  useEffect(() => {
    if (timeValue === null) {
      setOpeningHour(true);
    } else if (timeValue?.includes('AM') || timeValue?.includes('PM')) {
      onTimeChange(moment(timeValue, 'hh:mm:aa').format(TIME_FORMAT));
    }
  }, [timeValue, onTimeChange]);

  const handleTimeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    setOpeningHour(newValue === 'true');
    onTimeChange(null);
  };

  const handleTimeChange = (newValue: Moment | null) => {
    if (!newValue) {
      return;
    }
    const newTime = newValue?.format(TIME_FORMAT);
    onTimeChange(newTime);
  };

  return (
    <AttributeContainer label={label}>
      <RadioGroup
        value={openingHour}
        onChange={handleTimeOption}
        options={options}
      />
      <div className="w-full mt-4">
        {!openingHour && (
          <TimePicker
            ref={timeRef}
            value={moment(formattedValue, TIME_FORMAT)}
            onChange={handleTimeChange}
          />
        )}
      </div>
    </AttributeContainer>
  );
}

export default TimeAssignment;
