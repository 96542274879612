import { TResponsePagination } from 'models';

import { useGetList } from './request';

const adminEmployeeURL = '/api/select_options/employees';
const employeeURL = '/api/employee/select_options/employees';

export const employeeKeys = {
  all: ['employees'] as const,
  lists: () => [...employeeKeys.all, 'list'] as const,
  list: (filters: any) => [...employeeKeys.lists(), { filters }] as const,
  details: () => [...employeeKeys.all, 'detail'] as const,
  detail: (id: number) => [...employeeKeys.details(), id] as const,
};

export function useAdminGetEmployees(filters: any, options?: any) {
  return useGetList<TResponsePagination<any>>(
    [...employeeKeys.list(filters)],
    `${adminEmployeeURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useGetEmployees(filters: any) {
  return useGetList<TResponsePagination<any>>(
    [...employeeKeys.list(filters)],
    `${employeeURL}`,
    filters,
    {
      refetchOnMount: true,
    },
    true,
  );
}
