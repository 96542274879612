import { SingleSelect } from 'app/components/cores/select';
import { FULL_TYPE_MAPPING } from 'constants/employee/task';
import _ from 'lodash';
import { TSelectItem } from 'models';
import { TTaskStatusGroup } from 'models/employee/task.model';

import { useTheme } from '@mui/material/styles';

type Props = {
  status: string;
  onChange?: (data: TSelectItem) => void;
};

function StatusSelect({ status, onChange }: Props) {
  const theme = useTheme();

  const statusOptions = Object.keys(FULL_TYPE_MAPPING).map((key: string) => ({
    value: _.snakeCase(key),
    label: FULL_TYPE_MAPPING[key as TTaskStatusGroup],
  }));

  const value = statusOptions.find((item: any) => item.value === status) || null;

  const handleChange = (item: any) => {
    onChange?.(item);
  };

  return (
    <SingleSelect
      value={value}
      options={statusOptions}
      maxMenuHeight={1000}
      menuPlacement="auto"
      noOptionsMessage={() => 'No status'}
      placeholder="Status"
      onChange={handleChange}
      isClearable={true}
      styles={{
        control: (baseStyles: any, state: any) => ({
          ...baseStyles,
          border: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 8,
          height: 40,
          minHeight: 40,
          boxShadow: 'none',
          cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: 'auto',
        }),
      }}
    />
  );
}

export default StatusSelect;
