import DataSelect from 'app/components/cores/data-select';
import { SingleSelect } from 'app/components/cores/select';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

type Props = {
  filters: any;
  setFilters: any;
};

function ActionFilters({ filters, setFilters }: Props) {
  const theme = useTheme();

  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const locationOptions = useMemo(() => _.orderBy(locations, 'name'), [locations]);
  const location = locationOptions.find((l: any) => l.externalId === filters['q[location_id_eq]']);

  const handleLocationChange = (item: any, actionMeta: { action: string }) => {
    if (actionMeta.action === 'clear') {
      setFilters((currentFilters: any) => _.omit(currentFilters, ['q[location_id_eq]', 'location_name']));
      return;
    }
    setFilters((currentFilters: any) => ({
      ...currentFilters,
      'q[location_id_eq]': item.externalId,
      location_name: item.name,
    }));
  };

  const LIST_OPTIONS = [
    { value: 'all', label: 'All Source' },
    { value: 'todo', label: 'To-do' },
    { value: 'audit', label: 'Audit' },
    { value: 'regular', label: 'Regular List' },
  ];

  const listType = filters['q[source_eq]'] || LIST_OPTIONS[0].value;

  const handleListTypeChange = (newType: string) => {
    setFilters((currentFilters: any) => ({ ...currentFilters, 'q[source_eq]': newType }));
  };

  return (
    <div className="flex flex-row justify-end pr-4 w-360">
      <DataSelect
        options={LIST_OPTIONS}
        value={listType}
        onChange={handleListTypeChange}
        styleFormControl="max-w-136 min-w-136 rounded-8 mr-4 h-40"
        sx={{ height: '40px' }}
      />
      <div className="max-w-200 min-w-200">
        <SingleSelect
          value={location}
          options={locationOptions}
          maxMenuHeight={1000}
          menuPlacement="auto"
          noOptionsMessage={() => 'No locations'}
          placeholder="Select a location"
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.externalId}
          onChange={handleLocationChange}
          isClearable={true}
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              border: 0,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 8,
              height: 40,
              minHeight: 40,
              boxShadow: 'none',
              cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
            }),
          }}
        />
      </div>
    </div>
  );
}

export default ActionFilters;
