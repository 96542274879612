import { useHistory } from 'react-router-dom';

function useBack(defaultRoute: string) {
  const history = useHistory();

  function goBack() {
    if (history.length > 2) {
      history.goBack();
      return;
    }
    if (history.length <= 2 && !!defaultRoute) {
      history.push(defaultRoute);
      return;
    }
    history.push('/');
  }

  return { goBack };
}

export default useBack;
