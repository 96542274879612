import DataSelect from 'app/components/cores/data-select';
import { InputNumber } from 'app/components/cores/inputs/input-number';
import { useState } from 'react';
import { clsxm } from 'utils/clsxm';

import { Typography } from '@mui/material';

const METRIC_OPTIONS = [
  { label: 'Minute(s)', value: '60' },
  { label: 'Hour(s)', value: '3600' },
  { label: 'Day(s)', value: '86400' },
];

type Props = {
  label: string;
  value?: string;
  disabled?: boolean;
  classes?: {
    root?: string;
    title?: string;
    content?: string;
  };
  onChange?: (newValue: string) => void;
};

function TimeItem({ value, label, disabled, classes, onChange }: Props) {
  const [metric, setMetric] = useState(getDefaultState(value).metric);
  const [time, setTime] = useState(getDefaultState(value).time);

  function getDefaultState(newValue?: string) {
    if (!newValue) {
      return { metric: '60', time: 0 };
    }

    const valueNumber = Number(newValue);

    if (valueNumber % 86400 === 0) {
      return { metric: '86400', time: valueNumber / 86400 };
    }

    if (valueNumber % 3600 === 0) {
      return { metric: '3600', time: valueNumber / 3600 };
    }

    return { metric: '60', time: valueNumber / 60 };
  }

  const handleMetricChange = (newValue: string) => {
    setMetric?.(newValue);
    const newDuration = time * Number(newValue);
    onChange?.(newDuration.toString());
  };

  const handleTimeChange = (timeValue?: number) => {
    const newValue = Number(timeValue);
    setTime(newValue);
    const newDuration = newValue * Number(metric);
    onChange?.(newDuration.toString());
  };

  return (
    <div className={clsxm('h-full space-y-12', classes?.root)}>
      <Typography className={clsxm('text-small-dark', classes?.title)}>{label}</Typography>
      <div className={clsxm('flex flex-row h-40 my-8 space-x-8', classes?.content)}>
        <InputNumber
          disabled={disabled}
          value={time}
          onChange={handleTimeChange}
          variant="filled"
          className="w-80"
          InputProps={{
            disableUnderline: true,
            className: 'bg-paper rounded-4',
            classes: {
              input: 'py-10 rounded-4 bg-paper',
            },
          }}
          allowNegative={false}
        />
        <div className="w-104">
          <DataSelect
            disabled={disabled}
            options={METRIC_OPTIONS}
            value={metric}
            onChange={handleMetricChange}
          />
        </div>
      </div>
    </div>
  );
}

export default TimeItem;
