import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { clsxm } from 'utils/clsxm';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  title: string;
  className?: string;
};

function TodoTitle({ title, className }: Props, ref: any) {
  const classes = useStyles();
  const containerRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const toggleButtonRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    toggle: () => handleToggleText(),
  }));

  useEffect(() => {
    if (!titleRef.current) return;

    // Check if content overflows the 3-line limit
    const isOverflowing = titleRef.current?.scrollHeight > titleRef.current?.clientHeight;

    if (isOverflowing) {
      toggleButtonRef.current.style.display = 'flex'; // [show more]
    }
  }, []);

  const handleToggleText = (event?: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    containerRef.current?.classList.toggle('expanded');
    const isExpanded = containerRef.current?.classList?.contains('expanded');
    toggleButtonRef.current.textContent = isExpanded ? '[show less]' : '[show more]';
  };

  return (
    <div
      ref={containerRef}
      className={clsxm('relative', classes.root)}
    >
      <Typography
        ref={titleRef}
        color="secondary"
        className={clsxm('relative overflow-hidden cursor-pointer', classes.title, classes.preFormatted, className)}
      >
        {title}
      </Typography>
      <Typography
        ref={toggleButtonRef}
        color="primary"
        component="span"
        className={clsxm(
          'toggleButton text-11 font-400 hover:underline mb-4 cursor-pointer bg-white',
          classes.toggleButton,
        )}
        onClick={handleToggleText}
      >
        [show more]
      </Typography>
    </div>
  );
}

export default forwardRef(TodoTitle);

const useStyles = makeStyles(() => ({
  root: {
    '&.expanded': {
      '& $title': {
        display: 'block !important',
      },
      '& $toggleButton': {},
    },
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3 /* Limits to 3 lines */,
    '-webkit-box-orient': 'vertical',
  },
  toggleButton: {
    display: 'none',
  },
  preFormatted: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
}));
