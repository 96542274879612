import AccordionExpandTitle from 'app/components/cores/items-editor/accordion-expand-title';
import clsx from 'clsx';
import { TItem } from 'models';
import { Control, useController } from 'react-hook-form';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles } from '@mui/styles';

import { NotifyDisplaySwitch } from './display';

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  isExpanded?: boolean;
  selectedItem: TItem;
  onPanelChange?: (nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list') => void;
};

function NotifyLogicContainer({ isDisabled, control, name, isExpanded, selectedItem, onPanelChange }: Props) {
  const classes = useStyles();
  const {
    field: { value: currentItem },
    fieldState: { error },
  } = useController({ control, name });

  const notificationConditionals = currentItem?.notificationConditionals ?? [];
  const comparisons = notificationConditionals?.[0]?.comparisons;
  const hasError = !!(error as any)?.notificationConditionals;
  const hasConfig = !!comparisons?.length;

  return (
    <Accordion
      className="bg-white shadow-0"
      classes={{ root: clsx(classes.accordionRoot) }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('notify')}
    >
      <AccordionExpandTitle
        isExpanded={isExpanded}
        hasError={hasError}
        hasConfig={hasConfig}
        title="Add Notify Logic (optional)"
        classes={{
          notifySummaryRoot: classes?.notifySummaryRoot,
          accordionSummaryDisabled: classes?.accordionSummaryDisabled,
        }}
      />

      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <Typography className="mb-16 text-13 font-400 text-secondaryLight">
          You can set up notifications by specifying the notification recipient(s) and selecting the responses that will
          trigger the notifications.
        </Typography>

        <NotifyDisplaySwitch
          control={control}
          name={name}
          selectedConditionalItem={selectedItem}
        />
        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default NotifyLogicContainer;

const useStyles = makeStyles(() => {
  return {
    accordionRoot: {
      '&:before': {
        display: 'none',
      },
    },
    accordionSummaryDisabled: {
      opacity: '1 !important',
    },
    notifySummaryRoot: {
      boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1) !important',
      borderRadius: '16px 16px 0px 0px !important',
    },
  };
});
