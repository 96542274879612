import ConfirmDialog from 'app/components/cores/confirm-dialog';
import DisplayUntilSetting from 'app/components/cores/display-until/setting';
import UntilItem from 'app/components/cores/display-until/until-item';
import ErrorMessage from 'app/components/cores/form/error-message';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { DEFAULT_INTERVAL, TIME_FORMAT } from 'constants/index';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Control, type UseFormReturn, useController, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toNumber } from 'utils/number';

import { usePrevious } from '@fuse/hooks';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';

import EndTime from './end-time';
import NumberOfRun from './number-of-run';
import StartTime from './start-time';
import TimeItem from './time-item';

const MAXIMUM_COUNT = 100;

type Props = {
  index: number;
  name: string;
  control: Control<any>;
  methods?: UseFormReturn<any>;
  frequency: number;
  isUseOpeningTime?: boolean;
  onPreview?: (value: any) => void;
  onRemove?: () => void;
};

// TODO move validation to schema
function DisplayTime({ index, name, control, methods, frequency, isUseOpeningTime, onPreview, onRemove }: Props) {
  const dispatch = useDispatch();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const updatedValue = useWatch({ name, control });

  const [isRunUntilEnd, setIsRunUntilEnd] = useState(value?.count === -1);

  const isUseEndTime = !_.isNumber(toNumber(updatedValue?.time)) && !_.isNumber(toNumber(updatedValue?.startAfter));

  const isShowInterval = !!value?.count && value?.count !== null && value?.count !== 1;
  const isDisplayUntil = !!value?.displayUntil;
  const canShowDisplayUntil = frequency > 0;

  const prevFrequency = usePrevious(frequency);

  useEffect(() => {
    if (prevFrequency === undefined || prevFrequency === frequency) return;

    // reset data while switch between monthly, weekly and daily
    if (frequency > 0) {
      if (value.displayUntil) {
        onChange({
          ...value,
          dueIn: null,
          expireIn: null,
          count: 1,
          dueUntil: { target: null, time: null, after: 0 },
          expireUntil: { target: null, time: null, after: 0 },
        });
      }
      return;
    }

    // reset data when switch to yearly
    if (value.displayUntil) {
      onChange({
        ...value,
        dueIn: '3600',
        expireIn: '7200',
        displayUntil: undefined,
        dueUntil: undefined,
        expireUntil: undefined,
      });
    } else {
      onChange({
        ...value,
        displayUntil: undefined,
      });
    }
  }, [frequency, value, prevFrequency, onChange]);

  const getErrorMessage = () => {
    const nextError = _.cloneDeep(error) as any;
    return (
      nextError?.message ||
      nextError?.time?.message ||
      nextError?.count?.message ||
      nextError?.endTime?.message ||
      nextError?.interval?.message ||
      nextError?.dueIn?.message ||
      nextError?.expireIn?.message
    );
  };

  //
  const handleIntervalChange = (newValue: string) => {
    const valueNumber = Number(newValue);
    const fieldName = `${name}.interval`;

    updateValues({ interval: valueNumber });

    if (!valueNumber) {
      setFieldError(fieldName, 'Please enter interval');
      return;
    }

    clearFieldError(fieldName);
  };

  //
  const handleDueInChange = (newValue: string) => {
    const valueNumber = Number(newValue);
    const expireInValueNumber = Number(value?.expireIn);
    const fieldName = `${name}.dueIn`;
    const expireInFieldName = `${name}.expireIn`;

    updateValues({ dueIn: valueNumber });

    if (!valueNumber) {
      clearFieldError(expireInFieldName);
      setFieldError(fieldName, 'Please enter Due In');
      return;
    }

    if (!expireInValueNumber) {
      clearFieldError(fieldName);
      setFieldError(expireInFieldName, 'Please enter Expire In');
      return;
    }

    if (valueNumber > expireInValueNumber) {
      setFieldError(fieldName, 'Due In must be less than Expire In');
      return;
    }

    clearFieldError([fieldName, expireInFieldName]);
  };

  //
  const handleExpireInChange = (newValue: string) => {
    const valueNumber = Number(newValue);
    const dueInValueNumber = Number(value?.dueIn);
    const fieldName = `${name}.expireIn`;
    const dueInFieldName = `${name}.dueIn`;

    updateValues({ expireIn: valueNumber });

    if (!valueNumber) {
      clearFieldError(dueInFieldName);
      setFieldError(fieldName, 'Please enter Expire In');
      return;
    }

    if (!dueInValueNumber) {
      clearFieldError(fieldName);
      setFieldError(dueInFieldName, 'Please enter Due In');
      return;
    }

    if (valueNumber < dueInValueNumber) {
      setFieldError(fieldName, 'Expire In must be greater than Due In');
      return;
    }

    clearFieldError([fieldName, dueInFieldName]);
  };

  //
  const handleCountChange = (newValue?: number) => {
    const valueNumber = Number(newValue) || 0;
    const fieldName = `${name}.count`;

    const nextValues = {
      count: valueNumber,
    } as any;

    if (!value?.interval) {
      nextValues.interval = DEFAULT_INTERVAL;
    }

    updateValues(nextValues);

    if (valueNumber && valueNumber > MAXIMUM_COUNT) {
      setFieldError(fieldName, `Maximum of ${MAXIMUM_COUNT} counts allowed`);
      return;
    }

    clearFieldError(fieldName);
  };

  //
  const handleStartAtChange = (startTime: any) => {
    const { endTime } = methods?.getValues(name) || {};
    const endTimeValue = moment(endTime, TIME_FORMAT);
    const startTimeValue = moment(startTime, TIME_FORMAT);
    const fieldName = `${name}.time`;
    const endTimeFieldName = `${name}.endTime`;

    if (!startTime) {
      clearFieldError(fieldName);
      return;
    }

    if (endTime !== null && !endTimeValue.isValid()) {
      setFieldError(fieldName, 'End At is invalid');
      return;
    }

    if (endTimeValue.valueOf() < startTimeValue.valueOf()) {
      setFieldError(fieldName, 'Start At must be less than End At');
      return;
    }

    clearFieldError([fieldName, endTimeFieldName]);
  };

  const handleEndAtChange = (endTime: any) => {
    const { time: startTime } = methods?.getValues(name) || {};
    const startTimeValue = moment(startTime, TIME_FORMAT);
    const endTimeValue = moment(endTime, TIME_FORMAT);
    const fieldName = `${name}.endTime`;
    const startTimeFieldName = `${name}.time`;

    if (!endTime) {
      clearFieldError(fieldName);
      return;
    }

    if (startTime !== null && !startTimeValue.isValid()) {
      setFieldError(fieldName, 'Start At is invalid');
      return;
    }

    if (endTimeValue.valueOf() < startTimeValue.valueOf()) {
      setFieldError(fieldName, 'End At must be greater than Start At');
      return;
    }

    clearFieldError([fieldName, startTimeFieldName]);
  };

  //
  const handleRunUntilEndChange = (checked: boolean) => {
    setIsRunUntilEnd(checked);

    if (checked) {
      updateValues({ count: -1, interval: DEFAULT_INTERVAL });
    } else {
      updateValues({ count: 1, interval: null });
    }
  };

  // update form values
  const updateValues = (values: any) => {
    const nextValue = methods?.getValues(name);
    onChange({ ...nextValue, ...values });
  };

  //
  const setFieldError = (fieldName: string, errorString: string) => {
    methods?.setError(fieldName, {
      type: 'manual',
      message: errorString,
    });
  };

  //
  const clearFieldError = (fieldName: string | string[]) => {
    methods?.clearErrors(fieldName);
  };

  //
  const handlePreview = () => {
    onPreview?.(methods?.getValues(name));
  };

  const handleRemove = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete display time"
            message="Are you sure you want to delete this display time?"
            statusVariant="warning"
            confirmButtonLabel="Delete"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              onRemove?.();
            }}
          />
        ),
      }),
    );
  };

  const handleDisplayUntilChange = (isChecked: boolean) => {
    if (isChecked) {
      updateValues({
        dueIn: null,
        expireIn: null,
        count: 1,
        dueUntil: { target: null, time: null, after: 0 },
        expireUntil: { target: null, time: null, after: 0 },
      });
    } else {
      updateValues({
        dueIn: '3600',
        expireIn: '7200',
        dueUntil: undefined,
        expireUntil: undefined,
      });
    }
  };

  return (
    <div className="py-16 border-t-1">
      <div className="flex items-center justify-between mb-16">
        <Typography className="text-small-dark">Display Time {index + 1}</Typography>
        <div className="flex items-center">
          <Tooltip title="Preview">
            <IconButton onClick={handlePreview}>
              <VisibilityOutlinedIcon className="text-20" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove">
            <IconButton onClick={handleRemove}>
              <DeleteOutlineOutlinedIcon className="text-20" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="space-y-12">
        {/* start at */}
        <div className="flex flex-wrap items-start">
          <Typography className="mt-10 text-small-dark w-104">Start At</Typography>
          <StartTime
            name={name}
            control={control}
            isUseOpeningTime={isUseOpeningTime}
            onChange={handleStartAtChange}
          />
        </div>

        {/* count */}
        <NumberOfRun
          isDisabled={isDisplayUntil}
          label="Number of runs"
          value={updatedValue?.count}
          isRunUntilEnd={isRunUntilEnd}
          isUseEndTime={isUseEndTime}
          classes={{
            root: 'flex items-center space-y-0',
            title: 'w-104',
          }}
          onChange={handleCountChange}
          onRunUntilEndChange={handleRunUntilEndChange}
        />

        {/* end at */}
        <div className="flex flex-wrap items-start">
          <Typography className="mt-10 text-small-dark w-104">
            End At{' '}
            <Tooltip title="Note: End At setting has no impact on Due In and Expire In Settings">
              <InfoOutlinedIcon className="text-15 text-secondaryLight" />
            </Tooltip>
          </Typography>
          <EndTime
            name={name}
            control={control}
            isUseOpeningTime={isUseOpeningTime}
            onChange={handleEndAtChange}
          />
        </div>

        {/* interval */}
        {isShowInterval && (
          <TimeItem
            label="Interval"
            value={value?.interval}
            classes={{
              root: 'flex items-center space-y-0',
              title: 'w-104',
            }}
            onChange={handleIntervalChange}
          />
        )}

        {/* due in */}
        {canShowDisplayUntil && (
          <div className="flex items-center pt-10">
            <DisplayUntilSetting
              control={control}
              name={`${name}.displayUntil`}
              onChange={handleDisplayUntilChange}
            />
          </div>
        )}

        {isDisplayUntil ? (
          <>
            <UntilItem
              frequency={frequency}
              label="Not Due Until"
              name={`${name}.dueUntil`}
              control={control}
              classes={{
                title: 'w-104',
              }}
            />
            <UntilItem
              frequency={frequency}
              label="Not Expire Until"
              name={`${name}.expireUntil`}
              control={control}
              classes={{
                title: 'w-104',
              }}
            />
          </>
        ) : (
          <>
            <TimeItem
              label="Due In"
              value={value?.dueIn}
              classes={{
                root: 'flex items-center space-y-0',
                title: 'w-104',
              }}
              onChange={handleDueInChange}
            />

            {/* expire in */}
            <TimeItem
              label="Expire In"
              value={value?.expireIn}
              classes={{
                root: 'flex items-center space-y-0',
                title: 'w-104',
              }}
              onChange={handleExpireInChange}
            />
          </>
        )}
      </div>

      <ErrorMessage message={getErrorMessage()} />
    </div>
  );
}

export default DisplayTime;
