import clsx from 'clsx';
import _ from 'lodash';
import { Control, useWatch } from 'react-hook-form';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Badge, Button, Typography } from '@mui/material';

import ModalScoreSetting from './modal';

type Props = {
  control?: Control;
  name: string;
  isOpenSettingModal?: boolean;
  onOpenSettingModal?: () => void;
  onClose?: () => void;
  onSave?: () => void;
};

function SpecialScoreSetting({ name, control, isOpenSettingModal, onOpenSettingModal, onClose, onSave }: Props) {
  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreSettingsName = `${name}.scoreSettings`;
  const scoreEnabled = useWatch({ name: scoreEnabledName, control });
  const scoreSettings = useWatch({ name: scoreSettingsName, control });
  const customSettings = _.filter(scoreSettings, setting => setting.logic !== 'completed');

  return (
    <div>
      <div
        className={clsx(
          'flex flex-row items-center justify-between space-x-8 mt-4',
          !scoreEnabled && 'cursor-not-allowed',
        )}
      >
        <Typography className="flex-1 text-12 font-400 text-secondaryLight">Score Settings</Typography>
        <div className={clsx('w-104 text-right', !scoreEnabled && 'pointer-events-none')}>
          <Badge
            badgeContent={customSettings?.length ?? 0}
            color="error"
            classes={{
              badge: 'font-500 text-10 p-0',
            }}
          >
            <Button
              disableElevation={true}
              disabled={!scoreEnabled}
              variant="contained"
              color="primary"
              className="w-64 h-24 capitalize text-11 font-500"
              onClick={onOpenSettingModal}
            >
              Config
            </Button>
          </Badge>
        </div>
      </div>

      <div className="mt-12 flex flex-row items-start">
        <Typography className="flex-1 text-11 font-400 text-secondaryLight">
          <InfoOutlinedIcon className="text-14 mr-4 text-secondaryLight" />
          If the answer does not align with the Score Settings, the below score will be applied:
        </Typography>
      </div>

      <ModalScoreSetting
        isOpenSettingModal={isOpenSettingModal}
        name={name}
        control={control}
        onClose={onClose}
        onSave={onSave}
      />
    </div>
  );
}

export default SpecialScoreSetting;
