import * as taskFilterAction from 'app/auth/store/userSlice';
import _ from 'lodash';
import { TUserLocation, TUserRole } from 'models';
import { useDispatch, useSelector } from 'react-redux';

const useTaskFilter = () => {
  const dispatch = useDispatch();
  const taskFilter = useSelector(({ auth }: any) => auth.user?.taskFilter);
  const location = taskFilter?.location;
  const roles: TUserRole[] = taskFilter?.roles || [];
  const taskSearchValue = taskFilter?.searchValue || '';
  const allRoles = { externalId: null, name: 'All Roles' };

  const setTaskSearchValue = (nextSearchValue: string) => {
    dispatch(taskFilterAction.setTaskSearchValue(nextSearchValue));
  };

  const getTaskFilterRoles = (selectedRoles: TUserRole[]) => {
    const nextRoles = _.cloneDeep(selectedRoles);
    const lastRole = _.last(nextRoles);
    const isSelectAllRoles = lastRole?.externalId === null;

    // set default is all roles
    // or select all roles option
    if (_.isEmpty(nextRoles) || isSelectAllRoles) {
      return [allRoles];
    }

    // select role with all roles already
    const allRolesIndex = _.findIndex(nextRoles, role => role.externalId === null);
    const hasAllRolesOption = allRolesIndex > -1;
    if (hasAllRolesOption && nextRoles?.length > 1) {
      nextRoles.splice(allRolesIndex, 1);
    }

    return nextRoles;
  };

  const setTaskFilterRoles = (selectedRoles: TUserRole[]) => {
    dispatch(taskFilterAction.setTaskFilterRoles(getTaskFilterRoles(selectedRoles)));
  };

  const setTaskFilterLocation = (nextLocation: TUserLocation) => {
    dispatch(taskFilterAction.setTaskFilterLocation(nextLocation));
  };

  const clearTaskFilter = () => {
    dispatch(taskFilterAction.clearTaskFilter());
  };

  return {
    allRoles,
    location,
    roles,
    taskSearchValue,
    setTaskSearchValue,
    getTaskFilterRoles,
    setTaskFilterRoles,
    setTaskFilterLocation,
    clearTaskFilter,
  };
};

export default useTaskFilter;
