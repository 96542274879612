import ToggleList from 'app/components/cores/todos/components/toggle-list';

import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { Typography } from '@mui/material';

type Role = {
  externalId: string | number;
  name: string;
};

type Assignee = {
  id: string | number;
  fullName: string;
};

type Props = {
  roles?: Role[];
  assignees?: Assignee[];
};

const RolesAndAssignees = ({ roles = [], assignees = [] }: Props) => {
  return (
    <>
      {roles?.length > 0 && (
        <ToggleList
          data={roles}
          keyExtractor={role => `${role.externalId}`}
          limit={3}
          renderItem={role => (
            <Typography
              color="primary"
              className="inline mr-4 text-13 font-600"
              component="span"
            >
              #{role.name}
            </Typography>
          )}
          icon={
            <HowToRegOutlinedIcon
              fontSize="small"
              className="mb-4"
            />
          }
          sign="#"
          getItemName={role => role.name}
        />
      )}

      {assignees?.length > 0 && (
        <ToggleList
          data={assignees}
          keyExtractor={assignee => `${assignee.id}`}
          limit={3}
          renderItem={assignee => (
            <Typography
              color="primary"
              className="inline mr-4 text-13 font-600"
              component="span"
            >
              @{assignee.fullName}
            </Typography>
          )}
          icon={
            <PeopleOutlinedIcon
              fontSize="small"
              className="mb-4"
            />
          }
          sign="@"
          getItemName={assignee => assignee.fullName}
        />
      )}
    </>
  );
};

export default RolesAndAssignees;
