import _difference from 'lodash/difference';

type UnavailableOrgRole = {
  externalId: number;
  name: string;
};

type LocationResponse = {
  location: Location;
  orgRoles: UnavailableOrgRole[];
};

type TOrgRoles = {
  orgRoleId: number;
  orgRoleName: string;
  _destroy?: boolean;
}[];

type GlobalLocations = {
  locationId: number;
  locationName: string;
}[];

type Variations = {
  assignmentOrgRolesAttributes: { orgRoleId: number; orgRoleName: string }[];
  location: { id: number; name: string };
  customisedSettings: string[];
}[];

export function checkUnavailableRoles(response: LocationResponse[]): boolean {
  if (!response || response.length === 0) return false;

  return response.some(location => location?.orgRoles?.length > 0);
}

export function checkUnavailableRolesForVariations(response: LocationResponse[][]): boolean {
  if (!response || response.length === 0) return false;

  return response.some(checkUnavailableRoles);
}

export function getOrgRoleIds(orgRoles: TOrgRoles) {
  if (!orgRoles) return [];

  return orgRoles.filter(role => !role?._destroy).map(role => role.orgRoleId);
}

export function getVariationLocationId(variation: Variations[number]) {
  return variation?.location?.id;
}

export function filterRoleVariations(variation: Variations[number]) {
  return variation.customisedSettings.includes('assignment_org_roles_attributes');
}

export function getVariationsToCheck(variation: Variations[number]): [number, number[]] {
  return [getVariationLocationId(variation), getOrgRoleIds(variation?.assignmentOrgRolesAttributes)];
}

export function getDataForUnavailable({
  globalLocations = [],
  globalOrgRoles = [],
  variations = [],
}: {
  globalLocations: GlobalLocations;
  globalOrgRoles: TOrgRoles;
  variations: Variations;
}) {
  const allGlobalLocationIds = globalLocations?.map(location => location.locationId);
  const globalOrgRoleIds = getOrgRoleIds(globalOrgRoles);

  const assignRoleVariations = variations?.filter(filterRoleVariations);
  const locationVariationIds = variations?.map(getVariationLocationId);
  const globalLocationIds = _difference(allGlobalLocationIds, locationVariationIds);
  const variationsToCheck = variations.map(getVariationsToCheck);

  return {
    global: { locationIds: globalLocationIds ?? [], orgRoleIds: globalOrgRoleIds ?? [] },
    variations: { assignRoleVariations, variationsToCheck },
  };
}
