import { authRoles } from 'app/auth';

import AccessDenied from './403';

const EmptyPagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.employee,
  routes: [
    {
      path: '/403',
      exact: true,
      component: AccessDenied,
    },
  ],
};

export default EmptyPagesConfig;
