import { TQuestionLayoutView, questionsLayoutView } from 'models';
import { ComponentProps, PropsWithChildren } from 'react';

import HistoryQuestionLayout from './history-question-layout';
import TemplateQuestionLayout from './template-question-layout';

type Props = {
  view: TQuestionLayoutView;
  [key: string]: any;
};

const QuestionLayoutMapper = ({ view, children, ...rest }: PropsWithChildren<Props>) => {
  switch (view) {
    case questionsLayoutView.HISTORY: {
      const props = rest as ComponentProps<typeof HistoryQuestionLayout>;
      return <HistoryQuestionLayout {...props}>{children}</HistoryQuestionLayout>;
    }
    case questionsLayoutView.TEMPLATE: {
      const props = rest as ComponentProps<typeof TemplateQuestionLayout>;
      return <TemplateQuestionLayout {...props}>{children}</TemplateQuestionLayout>;
    }
    default:
      return null;
  }
};

export default QuestionLayoutMapper;
