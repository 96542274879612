import { TASK_COLORS } from 'constants/task';
import moment from 'moment';
import React from 'react';

import { StaticDatePicker as StaticDatePickerMui } from '@mui/x-date-pickers';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

interface Props {
  dayView?: Date;
  calendarRef?: React.RefObject<any>;
  onViewDateChange?: (newDate: Date) => void;
}

export const StaticDatePicker: React.FC<Props> = ({ dayView, calendarRef, onViewDateChange }) => {
  return (
    <StaticDatePickerMui
      sx={{
        '& .MuiPickersCalendarHeader-root': {
          marginTop: 0,
        },
        '& .MuiDayCalendar-header': {
          borderTop: `1px solid #ECEDEE`,
          margin: '0 24px',
        },
        '& .MuiPickersDay-root': {
          fontSize: '16px',
          '&:hover': {
            background: '#FFF6FA',
          },
          '&:focus': {
            background: '#FFF6FA',
          },
          '&.Mui-selected': {
            background: TASK_COLORS.incomplete,
            '&:hover': {
              background: TASK_COLORS.incomplete,
              willChange: 'background',
            },
            '&:focus': {
              background: TASK_COLORS.incomplete,
              willChange: 'background',
            },
          },
        },
        '& .MuiPickersYear-yearButton': {
          fontSize: '16px',
          '&:hover': {
            background: '#FFF6FA',
          },
          '&.Mui-selected': {
            background: TASK_COLORS.incomplete,
            '&:hover': {
              background: TASK_COLORS.incomplete,
              willChange: 'background',
            },
            '&:focus': {
              background: TASK_COLORS.incomplete,
              willChange: 'background',
            },
          },
        },
      }}
      slotProps={{
        actionBar: {
          actions: [],
        },
        toolbar: {
          hidden: true,
        },
        layout: {
          sx: {
            backgroundColor: 'transparent',
            border: 'none',
          },
        },
        switchViewIcon: {
          sx: {
            display: 'none',
          },
        },
        nextIconButton: {
          sx: {
            width: 28,
            height: 28,
            svg: {
              width: 24,
              height: 24,
              fill: '#1F242F',
            },
          },
        },
        previousIconButton: {
          sx: {
            width: 28,
            height: 28,
            svg: {
              width: 24,
              height: 24,
              fill: '#1F242F',
            },
          },
        },
      }}
      value={moment(dayView)}
      onChange={(value: unknown) => {
        if (moment.isMoment(value)) {
          const calendar = calendarRef?.current?.getApi();
          calendar?.gotoDate(value.toDate());
          onViewDateChange?.(value.toDate());
        }
      }}
      dayOfWeekFormatter={(day: string) => {
        return day;
      }}
    />
  );
};
