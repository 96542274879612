import {
  DuplicateTaskContentBodySkeleton,
  TaskContentHeaderSkeleton,
} from 'app/components/cores/tasks/content/skeleton';
import { TTodo } from 'models/employee/todo.model';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import TodoContentBody from './content/body';
import TodoContentHeader from './content/header';

type Props = {
  list?: TTodo;
  isLoading?: boolean;
  initialLoading?: boolean;
  readOnly?: boolean;
  onRefresh?: () => void;
};

function TodoContent({ list, isLoading, initialLoading, readOnly, onRefresh }: Props) {
  const hasSelectedTask = true;
  const { reset } = useFormContext();

  const loadingContainer = () => (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <TaskContentHeaderSkeleton haveSubmit={false} />
      <DuplicateTaskContentBodySkeleton />
    </div>
  );

  const noListContainer = () => (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <div className="flex items-center justify-center h-full">
        <Typography className="text-13">Please select a List to begin</Typography>
      </div>
    </div>
  );

  const noResponseContainer = () => (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <div className="flex items-center justify-center h-full">
        <Typography className="text-13">No to-dos found</Typography>
      </div>
    </div>
  );

  useEffect(() => {
    if (isLoading || !list?.responses?.length) return;
    reset(list);
  }, [list, reset, isLoading]);

  if (initialLoading) return loadingContainer();
  if (!hasSelectedTask) return noListContainer();
  if (isLoading) return loadingContainer();
  if (!list?.responses?.length) return noResponseContainer();

  return (
    <div className="relative flex-1 h-full overflow-hidden bg-white border-gray-200 md:col-span-8 xl:col-span-9 border-l-1">
      <div className="flex flex-col h-full overflow-auto bg-white md:col-span-9">
        <TodoContentHeader
          list={list}
          readOnly={readOnly}
          onRefresh={onRefresh}
        />

        <Box className="flex-1 overflow-auto">
          <TodoContentBody
            list={list as TTodo}
            readOnly={readOnly}
          />
        </Box>
      </div>
    </div>
  );
}

export default TodoContent;
