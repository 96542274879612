import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { refetchTask, useCreateVariation, useDeleteVariation, useUpdateAssignment } from 'app/hooks/api/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { assignmentSchema } from 'domains/task.domain';
import * as variationDomain from 'domains/variation.domain';
import _ from 'lodash';
import {
  TAssignment,
  TAssignmentCustomizeSetting,
  TAssignmentResponse,
  TAssignmentValidationError,
  TTask,
  TTaskLocation,
} from 'models';
import { useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';

import { TaskVariationActions, TaskVariationContainer, TaskVariationList } from './variations';

function TaskVariation() {
  const dispatch = useDispatch();
  const [editorMode, setEditorMode] = useState<'add' | 'edit' | undefined>();
  const [isSavingVariation, setSavingVariation] = useState(false);
  const { state: isShowEditor, setActive: showEditor, setInactive: hideEditor } = useToggle(false);
  const { showError, showSuccess } = useShowMessage();

  // task's assignment attributes
  const { control: taskControl } = useFormContext<TTask>();
  const {
    id: taskId,
    assignmentsAttributes,
    locationTasksAttributes,
    globalAssignmentAttributes,
  } = useWatch({ control: taskControl });

  const hasGlobalSetting = !!globalAssignmentAttributes?.id;
  const hasLocation = !!locationTasksAttributes?.length;

  // assignment
  const methods = useForm<TAssignment>({
    mode: 'onChange',
    resolver: yupResolver(assignmentSchema),
  });
  const { reset, handleSubmit } = methods;

  const { mutateAsync: createVariation, isLoading: isCreatingVariation } = useCreateVariation(Number(taskId));
  const { mutateAsync: deleteVariation, isLoading: isDeletingVariation } = useDeleteVariation(Number(taskId));
  const { mutateAsync: updateVariation, isLoading: isUpdatingVariation } = useUpdateAssignment(Number(taskId));
  const isLoading = isSavingVariation || isCreatingVariation || isDeletingVariation || isUpdatingVariation;

  const resetFormData = () => {
    setEditorMode(undefined);
    hideEditor();
    reset({});
  };

  const handleAddVariation = () => {
    setEditorMode('add');
    showEditor();
    reset({});
  };

  const handleEditVariation = (variationId: number) => {
    setEditorMode('edit');
    showEditor();

    const nextAssignment = variationDomain.getVariation(assignmentsAttributes as TAssignment[], variationId);
    if (!nextAssignment?.id) return;
    reset(nextAssignment);
  };

  const handleCancelVariation = () => {
    resetFormData();
  };

  const handleInvalid = async () => {
    showError('Could not create/update variation');
  };

  const handleShowMessage = (errors: any, successMessage: string) => {
    if (errors?.length > 0) {
      const errorString = errors.join('\n');
      showError(errorString);
    } else {
      showSuccess(successMessage);
    }
  };

  // then update the value of that field to global setting value
  const handleDeleteCustomizedField = async (variationId: number, fieldName: TAssignmentCustomizeSetting) => {
    const variationPayload = variationDomain.deleteVariationCustomizeField(
      assignmentsAttributes as TAssignment[],
      globalAssignmentAttributes as TAssignment,
      variationId,
      fieldName,
    );

    if (variationPayload?.isEmptyCustomisedSettings) {
      handleDeleteLastCustomizeField(variationId);
      return;
    }

    if (!variationPayload?.id) return;

    // update variation
    setSavingVariation(true);
    const errors = await handleUpdateVariation(variationPayload as TAssignment);

    handleShowMessage(errors, 'Customized setting field has been successfully removed');
    setSavingVariation(false);
    refetchTask();
  };

  const handleDeleteLastCustomizeField = (variationId: number) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Reset Variation"
            message="Once confirmed this variation setting will revert to default global settings and will be no longer available"
            statusVariant="warning"
            confirmButtonLabel="Delete"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              handleDeleteVariation(Number(variationId));
            }}
          />
        ),
      }),
    );
  };

  const handleSubmitVariation = async (value: Partial<TAssignment>) => {
    if (editorMode === 'add') {
      if (_.isEmpty(value?.locationIds)) {
        showError('Please select location');
        return;
      }

      if (_.isEmpty(value?.customisedSettings)) {
        showError('Please select category');
        return;
      }

      const variationsPayload = variationDomain.generateVariationPayload(
        value,
        assignmentsAttributes as TAssignment[],
        globalAssignmentAttributes as TAssignment,
      );

      setSavingVariation(true);
      const assignmentHandlers: any = [];

      variationsPayload?.forEach((variation: TAssignment) => {
        if (variationsPayload?.isCreatedVariation) {
          _.unset(variation, 'isCreatedVariation');
          assignmentHandlers.push(handleUpdateVariation(variation));
        } else {
          _.unset(variation, 'isCreatedVariation');
          _.unset(variation, 'locationIds');
          assignmentHandlers.push(handleCreateVariation(variation));
        }
      });

      const result = await Promise.all(assignmentHandlers);
      const errors = _.flatten(result);

      handleShowMessage(errors, 'Variation has been successfully saved');
      setSavingVariation(false);
      resetFormData();
      refetchTask();
    }
    //
    else if (editorMode === 'edit') {
      const variationPayload = variationDomain.updateVariation(
        value,
        assignmentsAttributes as TAssignment[],
        globalAssignmentAttributes as TAssignment,
      );

      if (variationPayload?.isEmptyCustomisedSettings) {
        handleDeleteVariation(Number(Number(value?.id)), resetFormData);
        return;
      }

      if (!variationPayload?.id) return;

      // update variation
      setSavingVariation(true);
      const errors = await handleUpdateVariation(variationPayload);

      handleShowMessage(errors, 'Variation has been successfully updated');
      setSavingVariation(false);
      resetFormData();
      refetchTask();
    }
  };

  const handleDeleteVariation = (variationId: number, onSuccess?: () => void) => {
    deleteVariation({ id: variationId })
      .then(() => {
        showSuccess('Variation has been successfully deleted');
        onSuccess?.();
        refetchTask();
      })
      .catch(() => showError('Could not delete variation'));
  };

  const getErrors = (res: TAssignmentResponse, type: 'create' | 'update') => {
    const variationErrors = res?.variationErrors || [];
    const errors = _.map(
      variationErrors,
      (err: TAssignmentValidationError) =>
        `Could not ${type} variation for location "${err.locationName}". ${err.errorMessage}`,
    );
    return errors;
  };

  const handleUpdateVariation = async (assignment: TAssignment) => {
    try {
      const res = await updateVariation({ assignment });
      return getErrors(res, 'update');
    } catch {
      return ['Could not update variation'];
    }
  };

  const handleCreateVariation = async (assignment: TAssignment) => {
    try {
      const res = await createVariation({ assignment });
      return getErrors(res, 'create');
    } catch {
      return ['Could not create variation'];
    }
  };

  return (
    <div className="relative w-full h-full px-24 py-16 layout-box divide-paper">
      <TaskVariationActions
        isShowEditor={isShowEditor}
        hasGlobalSetting={hasGlobalSetting}
        hasLocation={hasLocation}
        onAdd={handleAddVariation}
        onCancel={handleCancelVariation}
        onSubmit={handleSubmit(handleSubmitVariation, handleInvalid)}
      />

      {isShowEditor && (
        <TaskVariationContainer
          methods={methods}
          mode={editorMode}
          locationTasksAttributes={locationTasksAttributes as TTaskLocation[]}
        />
      )}

      {!isShowEditor && (
        <TaskVariationList
          assignmentsAttributes={assignmentsAttributes as TAssignment[]}
          onEdit={handleEditVariation}
          onDelete={handleDeleteVariation}
          onDeleteCustomizedField={handleDeleteCustomizedField}
        />
      )}

      {isLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
          <CircularProgress
            color="primary"
            size={20}
          />
        </div>
      )}
    </div>
  );
}

export default TaskVariation;
