/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import ChipCell from 'app/components/cores/index-table/cells/chip';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import { useGetRoles } from 'app/hooks/api/roles';
import { useGetTags } from 'app/hooks/api/tags';
import { Paths } from 'constants/index';
import { generateListTypeLabel } from 'domains/task.domain';
import { TTaskBasic } from 'models';
import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { capitalizeFirstCharacter } from 'utils/string';

import _ from '@lodash';
import Typography from '@mui/material/Typography';

import Action from '../detail-list-action';
import LocationListHeaderAction from '../header-action';

type Props = {
  tasks: TTaskBasic[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  selectedRowIds: number[];
  filters: any;
  setFilters: any;
  toggleDrawer?: (open: boolean, unitId: number) => void;
  onFetchData: (params: any) => void;
  setSelectedRowIds: (ids: number[]) => void;
  onAction?: ({ taskId, action }: { taskId: number; action: string }) => void;
  onBulkAction?: (action: string, taskIds: number[]) => void;
};

function DetailList({
  tasks,
  pageCount,
  totalDataCount,
  isLoading,
  searchKey,
  filters,
  selectedRowIds,
  setFilters,
  toggleDrawer,
  onFetchData,
  setSelectedRowIds,
  onBulkAction,
  onAction,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const statusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Pending', value: 'draft' },
    { label: 'Published', value: 'active' },
  ];

  const kindOptions = [
    { label: 'All', value: '_all' },
    { label: 'Recurring', value: 'recurring' },
    { label: 'Fixed', value: 'fixed' },
  ];

  const typeOptions = [
    { label: 'All', value: '_all' },
    { label: 'Location', value: '2' },
    { label: 'Group', value: '1' },
    { label: 'Individual', value: '0' },
  ];

  const handleDeactivateButtonClick = (taskId: any) => {
    onAction?.({ taskId, action: 'deactivate' });
  };

  const handleActivateButtonClick = (taskId: any) => {
    onAction?.({ taskId, action: 'activate' });
  };

  const handleDraftButtonClick = (taskId: any) => {
    onAction?.({ taskId, action: 'draft' });
  };

  const handleViewButtonClick = (taskId: number) => {
    history.push(`${Paths.list}/${taskId}`);
  };

  const handlePreviewClick = (event: React.MouseEvent, listId: number) => {
    event.preventDefault();
    toggleDrawer?.(true, listId);
  };

  const generateAssignmentStatus = (status: string) => {
    const statusOption = _.find(statusOptions, { value: status });
    return statusOption?.label || '-';
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: '20%',
        Cell: ({ row }: { row: Row<TTaskBasic> }) => {
          return (
            <Link
              onClick={event => {
                event.stopPropagation();
              }}
              to={`/lists/${row.original.id}`}
            >
              <Typography className="cursor-pointer font-600">{row.original?.name}</Typography>
            </Link>
          );
        },
      },
      {
        Header: 'Roles',
        accessor: 'orgRoles',
        id: 'role_id',
        width: '20%',
        Filter: (props: any) => (
          <div className="min-w-168">
            <SearchInputDropdownFilter
              name="role_ids_in"
              keySearch="q[name_cont]"
              optionValue="externalId"
              placeholder="Select Role"
              useFetchData={useGetRoles}
              {...props}
            />
          </div>
        ),
        Cell: ({ row }: { row: Row<any> }) => {
          const roles = row.original?.orgRoles || [];
          return (
            <ChipCell
              items={roles}
              onMore={event => handlePreviewClick(event, Number(row.original.id))}
            />
          );
        },
      },
      {
        Header: () => (
          <Typography className="truncate text-13 font-400 text-secondaryLight min-w-128">Completion Type</Typography>
        ),
        accessor: 'kind',
        sortable: false,
        width: '12.5%',
        options: kindOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => {
          return <Typography className="">{capitalizeFirstCharacter(cell?.value)}</Typography>;
        },
      },
      {
        Header: () => <Typography className="truncate text-13 font-400 text-secondaryLight min-w-128">Type</Typography>,
        accessor: 'listType',
        sortable: false,
        width: '10%',
        id: 'global_assignment_list_type',
        options: typeOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => {
          return <Typography className="">{generateListTypeLabel(cell.value)}</Typography>;
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags_id',
        width: '10%',
        sortable: false,
        Filter: (props: any) => {
          return (
            <div className="min-w-168">
              <SearchInputDropdownFilter
                name="tags_ids_in"
                keySearch="q[name_cont]"
                placeholder="Select Tag"
                useFetchData={useGetTags}
                {...props}
              />
            </div>
          );
        },
        Cell: ({ row }: { row: Row<any> }) => {
          const roles = row.original?.tags || [];
          return (
            <ChipCell
              items={roles}
              onMore={event => handlePreviewClick(event, Number(row.original.id))}
            />
          );
        },
      },
      {
        Header: () => (
          <Typography className="truncate text-13 font-400 text-secondaryLight min-w-128">Assignment Status</Typography>
        ),
        accessor: 'assignmentStatus',
        id: 'assignment_status',
        sortable: false,
        width: '20%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTaskBasic> }) => (
          <div className="ml-16">{generateAssignmentStatus(cell.value)}</div>
        ),
      },
      {
        Header: () => {
          return <div className="flex justify-end pr-32">Action</div>;
        },
        id: 'action',
        sortable: false,
        width: '10%',
        className: '',
        Cell: ({ row }: { row: Row<TTaskBasic> }) => (
          <Action
            onView={handleViewButtonClick}
            id={row.original.id}
            onActivate={handleActivateButtonClick}
            onDeactivate={handleDeactivateButtonClick}
            onDraft={handleDraftButtonClick}
            status={row.original.assignmentStatus}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, SearchInputDropdownFilter],
  );

  const handleRowSelected = (ids: number[]) => {
    if (tasks.length === 0) {
      return;
    }
    setSelectedRowIds(ids);
  };

  const selectedTasks = useMemo(() => {
    return _(tasks).keyBy('id').at(selectedRowIds).value();
  }, [tasks, selectedRowIds]);

  const handleBulkAction = (action: string) => {
    const taskIds = _.map(selectedTasks, 'id');
    onBulkAction?.(action, taskIds);
  };

  const currentFilterStatus = filters.find((x: any) => x.id === 'assignment_status')?.value;

  const handleClickRow = (row: Row<TTaskBasic>) => {
    // eslint-disable-next-line react/prop-types
    toggleDrawer?.(true, row?.original?.id);
  };

  return (
    <div className="flex flex-col w-full">
      <IndexTable
        columns={columns as Column<any>[]}
        data={tasks}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        withSelection={true}
        onRowSelected={handleRowSelected}
        onRowClick={handleClickRow}
        headerActions={
          <LocationListHeaderAction
            status={currentFilterStatus}
            totalRecords={totalDataCount}
            pageRecords={tasks.length}
            selectedRecords={selectedRowIds.length}
            title="item"
            onBulkAction={handleBulkAction}
          />
        }
      />
    </div>
  );
}

export default DetailList;
