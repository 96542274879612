import TextInput from 'app/components/cores/inputs/text';
import clsx from 'clsx';
import _ from 'lodash';
import { TItemSoreSetting, TOption } from 'models';
import { useEffect, useRef } from 'react';
import { Control, useFieldArray, useFormState, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  name: string;
  control?: Control;
};

function MultipleChoiceScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;
  const multipleChoiceOptionsName = `${name}.multipleChoiceOptions`;

  const classes = useStyles();
  const multipleChoiceOptions = useWatch({ name: multipleChoiceOptionsName, control });
  const canUpdateScoreSettings = useRef(true);
  const { errors } = useFormState({ control });

  const {
    fields: scoreSettings,
    replace,
    update,
  } = useFieldArray({
    control,
    name: scoreSettingsName,
  });

  // merge options with score settings
  const generateScoreSettings = (_multipleChoiceOptions?: TOption[], _scoreSettings?: any) => {
    if (_.isEmpty(_multipleChoiceOptions)) return [];

    const completedSetting = _.find(_scoreSettings, { logic: 'completed' });
    const mappedSettings = _.map(_multipleChoiceOptions, option => {
      const existedSetting = _.find(_scoreSettings, setting => `${setting.value}` === `${option.value}`);

      if (existedSetting) {
        return { ...existedSetting, label: option.label };
      }

      return {
        logic: 'eq',
        score: 0,
        value: `${option.value}`,
        label: option.label,
      };
    });

    return !completedSetting ? mappedSettings : [completedSetting, ...mappedSettings];
  };

  // set default score settings
  useEffect(() => {
    if (!canUpdateScoreSettings.current) return;

    canUpdateScoreSettings.current = false;
    replace(generateScoreSettings(multipleChoiceOptions, scoreSettings));
  }, [scoreSettings, multipleChoiceOptions, replace]);

  const handleUpdateScore = (index: number, setting: TItemSoreSetting) => (event: any) => {
    if (!/^\d*$/.test(event.target.value)) return;
    const nextScore = Number(event.target.value);
    update(index, { ...setting, score: nextScore ?? 0 });
  };

  // TODO update empty message
  if (!multipleChoiceOptions?.length) {
    return (
      <div>
        <Typography className="text-13 font-400">Please add options before configuring the score</Typography>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between px-24 mb-4">
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score if answer is</Typography>
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score</Typography>
      </div>

      <div className="flex flex-col gap-y-4">
        {scoreSettings?.map((setting: any, index: number) => {
          if (setting.logic === 'completed') return null;
          return (
            <div key={setting.value}>
              <div
                className={clsx(
                  'flex items-center justify-between px-24 py-4 duration-200 hover:bg-gray-50',
                  classes.item,
                )}
              >
                <Typography className="flex-1 text-13 font-500">{setting.label || setting.value}</Typography>
                <div className="flex-1">
                  <TextInput
                    placeholder="Enter score"
                    className={clsx('w-full px-8 bg-paper rounded-8 duration-200', classes.textInput)}
                    variant="standard"
                    InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-500', disableUnderline: true }}
                    value={setting.score ?? ''}
                    onChange={handleUpdateScore(index, setting)}
                  />
                  <ErrorMessage
                    name={`${scoreSettingsName}.${index}.score`}
                    errors={errors}
                    render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MultipleChoiceScoreSetting;

const useStyles = makeStyles((theme: any) => ({
  item: {
    '&:hover $textInput': {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },
  textInput: {},
}));
