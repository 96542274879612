import Header from 'app/components/Layout/headers/internal-search-header';
import DrawerWrapper from 'app/components/cores/drawer-wrapper';
import HeadButtonRight from 'app/components/cores/header-button-right';
import TaskIndexPreview from 'app/components/lists/index-preview';
import TaskList from 'app/components/lists/list';
import { useActivateTask, useDeactivateTask, useGetTasks } from 'app/hooks/api/tasks';
import useObjectAction from 'app/hooks/use-object-action';
import useObjectBulkAction from 'app/hooks/use-object-bulk-action';
import { Paths } from 'constants/index';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { generateURLWithSearch, getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';

interface Props extends RouteComponentProps<{}> {}

function ListIndex(props: Props) {
  const searchString = 'search';
  const history = useHistory();

  // Handle index filter
  const defaultFilters = [{ id: 'status', value: 'active' }];
  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search, defaultFilters));

  const [filter, setFilter] = useState<{ [key: string]: any }>({
    page: 1,
    per_page: 50,
    status: 'active',
  });

  const { data, isLoading } = useGetTasks(filter);

  const tasks = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [previewTaskId, setPreviewTaskId] = useState(0);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const handleSearch = (value: string) => {
    setFilter({ ...filter, page: 1, [searchString]: value });
    history.push({ search: generateURLWithSearch(props.location?.search, searchString, value) });
  };

  const handleFetchData = (fetchParams: any) => {
    setFilter(fetchParams);
  };

  const handleAddTask = () => {
    history.push(`${Paths.list}/new`);
  };

  const handlePreviewEditClick = (id: number) => {
    const { search, pathname } = props.location;
    if (id > 0) {
      props.history.push(`${Paths.list}/${id}`, { previousPath: `${pathname}${search}` });
    }
  };

  const handleDeactivateTask = useObjectAction(useDeactivateTask, 'list', 'deactivate');
  const handleDeleteTask = useObjectAction(useDeactivateTask, 'list', 'delete');
  const handleActivateTask = useObjectAction(useActivateTask, 'list', 'activate');

  const handleBulkDeactivate = useObjectBulkAction(useDeactivateTask, 'list', 'deactivate');
  const handleBulkActivate = useObjectBulkAction(useActivateTask, 'list', 'activate');

  const handleBulkAction = (action: string, objects: { action: string; id: number; skipReload?: boolean }[]) => {
    switch (action) {
      case 'deactivate':
        handleBulkDeactivate(objects);

        break;
      case 'activate':
        handleBulkActivate(objects);
        break;
      default:
        break;
    }
  };

  const handleTaskAction = ({ action, taskId }: { action: string; taskId: number }) => {
    switch (action) {
      case 'delete':
        handleDeleteTask({ action: 'deactivate', id: taskId });
        break;
      case 'deactivate':
        handleDeactivateTask({ action, id: taskId });
        break;
      case 'activate':
        handleActivateTask({ action, id: taskId });
        break;

      default:
        break;
    }
  };

  return (
    <BluefitPage
      classes={{
        contentWrapper: 'p-0 pl-10',
        content: 'flex flex-col relative',
        leftSidebar: 'w-256 border-0',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      drawer={
        <DrawerWrapper
          open={isDrawerOpen}
          onToggle={setIsDrawerOpen}
        >
          <TaskIndexPreview
            taskId={previewTaskId}
            onEditClick={handlePreviewEditClick}
            onCloseClick={() => setIsDrawerOpen(false)}
          />
        </DrawerWrapper>
      }
      header={
        <Header
          title="List"
          searchText={filter?.search || ''}
          onSearch={(value: string) => {
            handleSearch(value);
          }}
          rightHeader={
            <div>
              <HeadButtonRight
                className="ml-4"
                variant="contained"
                btnTitle="Add"
                onClick={handleAddTask}
              />
            </div>
          }
        />
      }
      content={
        <div className="absolute flex w-full h-full">
          <TaskList
            tasks={tasks}
            onFetchData={handleFetchData}
            toggleDrawer={(open: boolean, taskId: number) => {
              setPreviewTaskId(taskId);
              setIsDrawerOpen(open);
            }}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
            selectedRowIds={selectedRowIds}
            filters={filters}
            setFilters={setFilters}
            setSelectedRowIds={setSelectedRowIds}
            onBulkAction={handleBulkAction}
            onAction={handleTaskAction}
            searchKey={searchString}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default ListIndex;
