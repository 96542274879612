import { AttachmentViewer } from 'app/components/cores/tasks/components';
import { useController, useFormContext } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

type Props = {
  name: string;
  listStatus?: string;
};

const Section = ({ name, listStatus }: Props) => {
  const theme: any = useTheme();
  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({ name, control });
  const { prompt, description, attachments } = value?.item || {};
  const actionColor = listStatus === 'overdue' ? theme.palette.overdue.main : theme.palette.primary.main;

  return (
    <Box
      className="py-16 pl-16 pr-16 md:pl-28 md:pr-32"
      sx={{
        borderLeftWidth: '4px',
        borderLeftColor: actionColor,
        backgroundColor: alpha(actionColor, 0.1),
      }}
    >
      {/* Start of prompt */}

      {prompt && (
        <Typography
          className="font-bold text-16"
          sx={{ color: actionColor }}
        >
          {prompt}
        </Typography>
      )}
      {/* End of prompt */}

      {/* Start of description */}
      {description && <Typography>{description}</Typography>}
      {/* End of description */}

      {/* attachments */}
      {attachments && attachments?.length > 0 && <AttachmentViewer attachments={attachments} />}
      {/* end of attachments */}
    </Box>
  );
};

export default Section;
