import { useEffect, useState } from 'react';

function useWindowSize(ref?: any) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });

  const resetWindowSize = () => {
    setWindowSize({
      width: ref?.current?.offsetWidth,
      height: ref?.current?.offsetHeight,
    });
  };

  useEffect(() => {
    if (!ref?.current) return;
    setWindowSize({
      width: ref?.current?.offsetWidth,
      height: ref?.current?.offsetHeight,
    });
    // eslint-disable-next-line
  }, [ref?.current]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: ref?.current ? ref?.current?.offsetWidth : window.innerWidth,
        height: ref?.current ? ref?.current?.offsetHeight : window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount
  return { ...windowSize, resetWindowSize };
}

export default useWindowSize;
