import { TAdminCalendar } from 'models';

import { useGetList } from '../request';

const calendarURL = '/api/manager/calendars';

export const managerCalendarKeys = {
  all: ['managerCalendar'] as const,
  lists: () => [...managerCalendarKeys.all, 'list'] as const,
  list: (filters: any) => [...managerCalendarKeys.lists(), { filters }] as const,
};

export function useGetManagerCalendar(filters: any, options?: any) {
  return useGetList<TAdminCalendar[]>([...managerCalendarKeys.list(filters)], `${calendarURL}`, filters, {
    refetchOnMount: true,
    cacheTime: 0,
    ...options,
  });
}
