import IndexTable from 'app/components/cores/index-table';
import ChipCell from 'app/components/cores/index-table/cells/chip';
import SearchInputDropdownFilter from 'app/components/cores/index-table/filters/search-input-dropdown';
import TransferHeader from 'app/components/cores/transfer-list/transfer-header';
import useTransferList from 'app/components/cores/transfer-list/use-transfer-list';
import { useGetRoles } from 'app/hooks/api/roles';
import { useGetTags } from 'app/hooks/api/tags';
import _ from 'lodash';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {
  values?: any;
  options?: any;
  searchKey?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  filters: any;
  setFilters: any;
  chosenListFilters: any;
  setChosenListFilters: any;
  onChange?: (updatedValues: any) => void;
  onFetchData: (params: any) => void;
};

function TaskTransferList({
  values = [],
  options,
  searchKey,
  isDisabled,
  isLoading,
  filters,
  setFilters,
  chosenListFilters,
  setChosenListFilters,
  onChange,
  onFetchData,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const {
    unselectedOptions,
    setLeftSelectedValues,
    setRightSelectedValues,
    isMoveItemsLeftToRight,
    isMoveItemsRightToLeft,
    handleSelectAllRightItems,
    handleSelectAllLeftItems,
    selectedLeftTitle,
    selectedRightTitle,
    isLeftSelectedAllValues,
    isRightSelectedAllValues,
    isLeftIndeterminate,
    isRightIndeterminate,
    moveItemsFromLeftToRight,
    moveItemsFromRightToLeft,
  } = useTransferList({
    values,
    options,
  });

  const chosenListSearchResult = useMemo(() => {
    if (_.isEmpty(chosenListFilters)) {
      return values;
    }

    const newValues = _.filter(values, (list: any) => {
      // filter all values by combining all filters
      return _.every(chosenListFilters, (filter: any) => {
        if (!filter.value || filter.value === '_all') return true;

        if (filter.id === 'role_id') {
          // search in orgRoles of list
          const roles = list?.orgRoles || [];
          const role = _.find(roles, (_role: any) => _role?.externalId === filter?.value);
          return !!role;
        }

        if (filter.id === 'tags_id') {
          // search in tags of list
          const tags = list?.tags || [];
          const tag = _.find(tags, (_tag: any) => _tag?.id === filter?.value);
          return !!tag;
        }

        const name = list?.[filter?.id]?.toLowerCase?.();
        const search = filter?.value?.toLowerCase?.();
        return name?.includes(search);
      });
    });

    return newValues;
  }, [chosenListFilters, values]);

  // when moving selected items from left column to right column
  const handleMoveItemsFromLeftToRight = () => {
    const nextValues = moveItemsFromLeftToRight(values);
    onChange?.(nextValues);
    setLeftSelectedValues([]);
  };

  // when moving selected items from right column to left column
  const handleMoveItemsFromRightToLeft = () => {
    const nextValues = moveItemsFromRightToLeft(values);
    onChange?.(nextValues);
    setRightSelectedValues([]);
  };

  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
      },
      {
        Header: 'Roles',
        accessor: 'orgRoles',
        id: 'role_id',
        sortable: true,
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'role_ids_in',
            keySearch: 'q[name_cont]',
            optionValue: 'externalId',
            placeholder: 'Select Role',
            useFetchData: useGetRoles,
            ...props,
          }),
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<any> }) => {
          const roles = row.original?.orgRoles || [];
          return (
            <ChipCell
              items={roles}
              onMore={() => {}}
            />
          );
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags_id',
        sortable: true,
        Filter: (props: any) =>
          SearchInputDropdownFilter({
            name: 'tags_ids_in',
            keySearch: 'q[name_cont]',
            placeholder: 'Select Tag',
            useFetchData: useGetTags,
            ...props,
          }),
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<any> }) => {
          const tags = row.original?.tags || [];
          return (
            <ChipCell
              items={tags}
              onMore={() => {}}
            />
          );
        },
      },
    ],
    [],
  );

  const handleLeftColumnSelect = (ids: number[], items: any) => {
    setLeftSelectedValues(items);
  };

  const handleRightColumnSelect = (ids: number[], items: any) => {
    setRightSelectedValues(items);
  };

  return (
    <div className="flex items-start flex-1 space-x-12 overflow-hidden">
      <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
        <TransferHeader
          isSelectAll={false}
          isDisabled={isDisabled}
          isSelected={isLeftSelectedAllValues}
          isIndeterminate={isLeftIndeterminate}
          title="Lists"
          selectedTitle={selectedLeftTitle}
          onChange={handleSelectAllLeftItems}
          rightChildren={
            <Button
              disableElevation={true}
              variant="outlined"
              className="justify-between h-32 px-16 w-96 rounded-16"
              disabled={isDisabled || !isMoveItemsLeftToRight}
              onClick={handleMoveItemsFromLeftToRight}
            >
              <Typography className="text-11 font-400">Add</Typography>
              <AddIcon className="text-20" />
            </Button>
          }
          className="px-24"
          classes={{
            labelRoot: 'ml-0',
          }}
        />

        <IndexTable
          columns={columns as Column<any>[]}
          data={unselectedOptions}
          pageCount={0}
          loading={!!isLoading}
          totalDataCount={0}
          searchText=""
          searchKey={searchKey}
          location={location}
          history={history}
          onFetchData={onFetchData}
          filters={filters}
          setFilters={setFilters}
          withSelection={true}
          isUpdateAddressUrl={false}
          isShowPagination={false}
          onRowSelected={handleLeftColumnSelect}
        />
      </div>

      <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
        <TransferHeader
          isSelectAll={false}
          isDisabled={isDisabled}
          isSelected={isRightSelectedAllValues}
          isIndeterminate={isRightIndeterminate}
          title="Chosen Lists"
          selectedTitle={selectedRightTitle}
          onChange={handleSelectAllRightItems}
          rightChildren={
            <Button
              disableElevation={true}
              variant="outlined"
              color="primary"
              className="justify-between h-32 pl-16 pr-12 w-96 rounded-16"
              disabled={isDisabled || !isMoveItemsRightToLeft}
              onClick={handleMoveItemsFromRightToLeft}
            >
              <Typography className="text-11 font-400">Remove</Typography>
              <RemoveIcon className="text-20" />
            </Button>
          }
          className="px-24"
          classes={{
            labelRoot: 'ml-0',
          }}
        />

        <IndexTable
          columns={columns as Column<any>[]}
          data={chosenListSearchResult}
          pageCount={0}
          loading={!!isLoading}
          totalDataCount={0}
          searchText=""
          searchKey={searchKey}
          location={location}
          history={history}
          onFetchData={onFetchData}
          filters={chosenListFilters}
          setFilters={setChosenListFilters}
          withSelection={true}
          isUpdateAddressUrl={false}
          isShowPagination={false}
          onRowSelected={handleRightColumnSelect}
        />
      </div>
    </div>
  );
}

export default TaskTransferList;
