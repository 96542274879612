import {
  CheckboxEdit,
  CheckboxView,
  ItemTypeSelectEdit,
  ItemTypeSelectView,
  TextView,
} from 'app/components/cores/spreadsheet/cells';
import _ from 'lodash';
import { useState } from 'react';
import Spreadsheet, { DataEditor } from 'react-spreadsheet';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import ImportTypeMappingTable from './import-type-mapping-table';

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& .Spreadsheet__table': {
      width: '100%',
      position: 'relative',
      '& th': {
        background: theme.palette.background.paper,
        position: 'sticky',
        top: -4,
        padding: 0,
        zIndex: 1,
        height: 40,
      },
      '& colgroup': {
        '& col:first-child': {
          width: 50,
        },
        '& col:nth-child(2)': {
          width: 168,
        },
        '& col:nth-child(4)': {
          width: 128,
        },
        '& col:nth-child(5)': {
          width: 128,
        },
      },
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onImport?: (data: any) => void;
};

function BulkImport({ open, onImport, onClose }: Props) {
  const classes = useStyles();

  const col = ['Item Type*', 'Prompt*', 'Required Item', 'Allow N/A'];

  const initData = _.range(1, 100).map(() => {
    return [
      {
        value: undefined,
        DataViewer: ItemTypeSelectView,
        DataEditor: ItemTypeSelectEdit,
      },
      {
        value: undefined,
        DataViewer: TextView,
        DataEditor,
      },
      {
        value: 'true',
        DataViewer: CheckboxView,
        DataEditor: CheckboxEdit,
      },
      {
        value: undefined,
        DataViewer: CheckboxView,
        DataEditor: CheckboxEdit,
      },
    ];
  });

  const [data, setData] = useState(initData);
  const handleDataChange = (newData: any) => {
    setData(newData);
  };

  const handleClickClose = () => {
    onClose();
    setData(initData);
  };

  const handleImport = () => {
    const filteredData = data.filter(item => {
      const hasPrompt = !!item[1]?.value;
      const hasType = !!item[0]?.value;
      return hasPrompt || hasType;
    });
    onImport?.(filteredData);
    onClose();
    setData(initData);
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      classes={{
        paper: 'rounded-16',
      }}
    >
      <DialogTitle
        component="div"
        className="pb-0 space-y-4 bg-white"
      >
        <div className="flex justify-center">
          <Typography className="font-600 text-24">Bulk Import</Typography>
        </div>
      </DialogTitle>

      <Tooltip title="Close">
        <IconButton
          aria-label="close"
          onClick={handleClickClose}
          sx={{ right: 8, top: 8 }}
          className="absolute text-16"
        >
          <CloseIcon className="text-black text-20" />
        </IconButton>
      </Tooltip>
      <div className="bg-white">
        <ImportTypeMappingTable />
      </div>

      <DialogContent className="p-0">
        <div className={classes.root}>
          <Spreadsheet
            data={data}
            columnLabels={col}
            onChange={handleDataChange}
            className="w-full"
          />
        </div>
      </DialogContent>

      <div className="flex justify-end p-8 space-x-8">
        <Button
          disableElevation={true}
          variant="outlined"
          color="primary"
          className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
          onClick={() => setData(initData)}
        >
          Reset
        </Button>
        <Button
          disableElevation={true}
          variant="contained"
          color="primary"
          className="h-32 px-16 capitalize rounded-32 text-13 font-600 md:px-24"
          onClick={handleImport}
        >
          Import
        </Button>
      </div>
    </Dialog>
  );
}

export default BulkImport;
