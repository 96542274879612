import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function UserIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 3.33329C9.33688 3.33329 8.70099 3.59668 8.23215 4.06553C7.76331 4.53437 7.49992 5.17025 7.49992 5.83329C7.49992 6.49633 7.76331 7.13222 8.23215 7.60106C8.70099 8.0699 9.33688 8.33329 9.99992 8.33329C10.663 8.33329 11.2988 8.0699 11.7677 7.60106C12.2365 7.13222 12.4999 6.49633 12.4999 5.83329C12.4999 5.17025 12.2365 4.53437 11.7677 4.06553C11.2988 3.59668 10.663 3.33329 9.99992 3.33329ZM7.05364 2.88701C7.83504 2.10561 8.89485 1.66663 9.99992 1.66663C11.105 1.66663 12.1648 2.10561 12.9462 2.88701C13.7276 3.66842 14.1666 4.72822 14.1666 5.83329C14.1666 6.93836 13.7276 7.99817 12.9462 8.77957C12.1648 9.56097 11.105 9.99996 9.99992 9.99996C8.89485 9.99996 7.83504 9.56097 7.05364 8.77957C6.27224 7.99817 5.83325 6.93836 5.83325 5.83329C5.83325 4.72822 6.27224 3.66842 7.05364 2.88701ZM5.28587 12.7859C6.53612 11.5357 8.23181 10.8333 9.99992 10.8333C11.768 10.8333 13.4637 11.5357 14.714 12.7859C15.9642 14.0362 16.6666 15.7319 16.6666 17.5C16.6666 17.9602 16.2935 18.3333 15.8333 18.3333H4.16659C3.94557 18.3333 3.73361 18.2455 3.57733 18.0892C3.42105 17.9329 3.33325 17.721 3.33325 17.5C3.33325 15.7319 4.03563 14.0362 5.28587 12.7859ZM9.99992 12.5C8.67384 12.5 7.40207 13.0267 6.46438 13.9644C5.72548 14.7033 5.24172 15.6497 5.06983 16.6666H14.93C14.7581 15.6497 14.2744 14.7033 13.5355 13.9644C12.5978 13.0267 11.326 12.5 9.99992 12.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(UserIcon);
