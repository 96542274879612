import {
  TAuditPerformance,
  TAuditPerformanceAuditorStats,
  TAuditPerformanceListStats,
  TResponsePagination,
} from 'models';

import { useGetList } from './request';

export const auditPerformanceKeys = {
  all: ['audit-performance'] as const,
  overview: (locationId: number, params: GetAuditPerformanceParams) =>
    [...auditPerformanceKeys.all, 'overview', locationId, params] as const,
  stats: (locationId: number, params: GetAuditPerformanceStatsParams, type: 'list' | 'auditor') =>
    [...auditPerformanceKeys.all, 'stats', locationId, params, type] as const,
};

// --- get audit performance
type GetAuditPerformanceParams = {
  'q[from_date]': string;
  'q[to_date]': string;
};

export function useGetAuditPerformance(locationId: number, params: GetAuditPerformanceParams, options: any = {}) {
  return useGetList<TAuditPerformance>(
    [...auditPerformanceKeys.overview(locationId, params)],
    `/api/admin/locations/${locationId}/audit_performance`,
    params,
    { ...options },
  );
}
// ---

// --- get audit performance stats
type GetAuditPerformanceStatsParams = {
  'q[from_date]': string;
  'q[to_date]': string;
  'q[name_cont]'?: string;
  page: number;
  per_page: number;
};

type GetAuditPerformanceStatsResponse = TResponsePagination<TAuditPerformanceListStats | TAuditPerformanceAuditorStats>;

export function useGetAuditPerformanceStats(
  locationId: number,
  type: 'list' | 'auditor',
  params: GetAuditPerformanceStatsParams,
  options: any = {},
) {
  return useGetList<GetAuditPerformanceStatsResponse>(
    [...auditPerformanceKeys.stats(locationId, params, type)],
    `/api/admin/locations/${locationId}/audit_statistics/${type}`,
    params,
    options,
  );
}
// ---
