import { useEffect, useRef, useState } from 'react';

type Params = {
  data?: any;
  isLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  totalItems?: number;
  setSearchText: (value: string) => void;
  setPageIndex: (value: number) => void;
};

function useSelectV2({ data, isLoading, pageIndex, pageSize = 50, totalItems, setSearchText, setPageIndex }: Params) {
  const canLoadMore = useRef(true);
  const searchText = useRef('');
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (pageIndex === 1) {
      setOptions(data);
      return;
    }

    if (!data || !canLoadMore.current) return;
    if (data?.length < pageSize) {
      canLoadMore.current = false;
    }

    setOptions((prevData: any) => [...prevData, ...data]);
  }, [data, pageSize, pageIndex]);

  const handleSearch = (value: string, actionPayload: any) => {
    if (actionPayload.action === 'input-change' || (!!searchText.current && actionPayload.action === 'input-blur')) {
      setPageIndex(1);
      canLoadMore.current = true;
      searchText.current = value;
      setSearchText?.(value);
    }
  };

  const handleLoadMore = () => {
    if (!canLoadMore.current || isLoading || totalItems === options?.length) return;
    setPageIndex(pageIndex + 1);
  };

  return {
    options,
    handleSearch,
    handleLoadMore,
  };
}

export default useSelectV2;
