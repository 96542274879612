import Header from 'app/components/lists/preview/task-question-header';
import TaskQuestions from 'app/components/lists/preview/task-questions';
import TemplateHeader from 'app/components/templates/header';
import { useWatch } from 'react-hook-form';

import Container from '@mui/material/Container';

type Props = {
  tabIndex: number;
  onTabChange?: (tabIndex: number) => void;
};

function Preview({ tabIndex, onTabChange }: Props) {
  const taskQuestions = useWatch({ name: 'draftVersion.itemsAttributes' });
  const templateName = useWatch({ name: 'name' });

  return (
    <>
      <TemplateHeader
        tabIndex={tabIndex}
        onTabChange={onTabChange}
      />
      <Container
        className="space-y-8"
        maxWidth="md"
      >
        <Header name={templateName} />
        <TaskQuestions taskQuestions={taskQuestions} />
      </Container>
    </>
  );
}

export default Preview;
