import React from 'react';
import { Control, useController } from 'react-hook-form';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

type Props = {
  name: string;
  control?: Control;
  disabled?: boolean;
  reverse?: boolean;
  onValueChange?: (nextValue: boolean) => void;
};

function Toggle({ control, name, disabled, reverse, onValueChange }: Props) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const required = event.target.checked;
    onChange(reverse ? !required : required);
    onValueChange?.(required);
  };

  return (
    <StyledSwitch
      disabled={disabled}
      checked={reverse ? !value : !!value}
      onChange={handleChange}
    />
  );
}

export default Toggle;
