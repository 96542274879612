import React from 'react';

import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  children: JSX.Element;
};

const PreviewElement = ({ title, children }: Props) => {
  return (
    <div className="flex flex-row pt-20">
      <div className="min-w-88 w-88 pr-4 flex">
        <Typography className="text-11 font-600">{title}</Typography>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default React.memo(PreviewElement);
