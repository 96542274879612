import { authRoles } from 'app/auth';

import MediaPreview from './media-preview';
import TaskNotifyPreview from './task-notify-preview';

const FullScreenConfig = {
  settings: {
    layout: {
      config: {
        noLayout: true,
      },
    },
  },
  auth: authRoles.employee,
  routes: [
    {
      path: '/task-notify-preview',
      exact: true,
      component: TaskNotifyPreview,
    },
    {
      path: '/media/:encode',
      exact: true,
      component: MediaPreview,
    },
  ],
};

export default FullScreenConfig;
