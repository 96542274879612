import NavbarMobileToggleFab from 'app/components/Layout/NavbarMobileToggleFab';
import { adminNavigationConfig } from 'app/configs/navigationConfig';
import { useFetchServices } from 'app/hooks/api/services';
import Navbar from 'app/layouts/bluefitLayout/components/Navbar';
import { navbarCloseFolded, navbarCloseMobile, navbarOpenFolded } from 'app/store/fuse/navbarSlice';
import { selectNavbarTheme } from 'app/store/fuse/settingsSlice';
import clsx, { ClassValue } from 'clsx';
import { SHOW_NAVBAR_SWITCH } from 'constants/navbar';
import {
  getBaseUrl,
  getLoginMenu,
  getLoginNavigationConfig,
  getManagerNavigationConfig,
  getUserNavigationConfig,
  isMyTasks,
  isRoot,
} from 'domains/navbar.domain';
import { TNavigation, TService } from 'models';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { havePermissionToAction } from 'utils/role';

import _ from '@lodash';
import Drawer from '@mui/material/Drawer';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const navbarWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWidth: navbarWidth,
    },
  },
  wrapperFolded: {
    [theme.breakpoints.up('lg')]: {
      width: 64,
      minWidth: 64,
    },
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow: theme.shadows[3],
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  folded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0,
  },
  foldedAndOpened: {
    width: navbarWidth,
    minWidth: navbarWidth,
  },
  navbarContent: {
    flex: '1 1 auto',
  },
  foldedAndClosed: {
    '& $navbarContent': {
      '& .logo-icon': {
        width: 'auto',
        height: 38,
      },
      '& .logo-text': {
        opacity: 0,
      },
      '& .react-badge': {
        opacity: 0,
      },
      '& .list-item-text, & .arrow-icon, & .item-badge': {
        opacity: 0,
      },
      '& .list-subheader .list-subheader-text': {
        opacity: 0,
      },
      '& .list-subheader:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        minWidth: 16,
        borderTop: '2px solid',
        opacity: 0.2,
      },
      '& .collapse-children': {
        display: 'none',
      },
      '& .user': {
        '& .username, & .email': {
          opacity: 0,
        },
        '& .avatar': {
          width: 40,
          height: 40,
          top: 32,
          padding: 0,
        },
      },
      '& .list-item.active': {
        marginLeft: 12,
        width: 40,
        padding: 10,
        borderRadius: 20,
        '&.square': {
          borderRadius: 0,
          marginLeft: 0,
          paddingLeft: 24,
          width: '100%',
        },
      },
      '& .login-item.active': {
        backgroundColor: theme.palette.secondary.main,
        transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
        '& .login-item-text-primary': {
          color: 'inherit',
        },
        '& .login-item-icon': {
          color: 'inherit',
        },
        marginLeft: 8,
        width: 48,
        height: 48,
        padding: 15,
        borderRadius: 32,
        '&.square': {
          borderRadius: 0,
          marginLeft: 0,
          paddingLeft: 24,
          width: '100%',
        },
      },
    },
  },
}));

type NavType = 'my_tasks' | 'admin';

function NavbarWrapper() {
  const theme = useTheme();
  const hiddenLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const config = useSelector(({ fuse }: any) => fuse.settings.current.layout.config);
  const navbarTheme = useSelector(selectNavbarTheme);
  const navbar = useSelector(({ fuse }: any) => fuse.navbar);
  const location = useLocation();
  const userSettings = useSelector(({ auth }: any) => auth.user);

  const userRoles = userSettings.role;

  const permissions = userSettings?.permissions;

  const currentTheme = _.merge(navbarTheme, {
    palette: {
      primary: {
        main: userSettings?.brandPrimaryColor,
      },
    },
  });

  const { data: dataServices } = useFetchServices();
  const services = useMemo(() => dataServices?.services || [], [dataServices]);

  const baseUrl = useMemo(() => getBaseUrl(services), [services]);
  const [navType, setNavType] = useState<NavType>(getDefaultNavType());

  const { folded } = config.navbar;
  const foldedAndClosed = folded && !navbar.foldedOpen;
  const foldedAndOpened = folded && navbar.foldedOpen;
  const navPosition: ClassValue = classes[config.navbar.position as 'left' | 'right'];

  const generateMainMenu = (nextNavType: 'my_tasks' | 'admin') => {
    if (!nextNavType) return [];
    if (!SHOW_NAVBAR_SWITCH) {
      return adminNavigationConfig;
    }
    const menus =
      nextNavType === 'my_tasks' ? getUserNavigationConfig(userRoles) : getManagerNavigationConfig(userRoles);
    return menus.filter((menu: TNavigation) => havePermissionToAction(menu.permissions || [], permissions));
  };

  const generateLoginMenu = (_services: TService[]) => {
    const menu = getLoginMenu(_services);
    return getLoginNavigationConfig(menu, _services);
  };

  // set default selected route when switch between tabs
  const handleNavigationSwitch = (nextNavType: 'my_tasks' | 'admin') => {
    const [firstNavigation] = generateMainMenu(nextNavType);
    if (!firstNavigation?.url) return;
    history.push(firstNavigation.url);
    setNavType(nextNavType);
  };

  function getDefaultNavType(): NavType {
    return isRoot() || isMyTasks() ? 'my_tasks' : 'admin';
  }

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <div
          id="fuse-navbar"
          className={clsx(classes.wrapper, folded && classes.wrapperFolded)}
        >
          {hiddenLgUp && (
            <div
              className={clsx(
                classes.navbar,
                navPosition,
                folded && classes.folded,
                foldedAndOpened && classes.foldedAndOpened,
                foldedAndClosed && classes.foldedAndClosed,
              )}
              onMouseEnter={() => foldedAndClosed && dispatch(navbarOpenFolded({}))}
              onMouseLeave={() => foldedAndOpened && dispatch(navbarCloseFolded({}))}
              style={{ backgroundColor: navbarTheme.palette.background.default }}
            >
              <Navbar
                currentRole={navType}
                userRoles={userRoles}
                className={classes.navbarContent}
                foldedAndClosed={foldedAndClosed}
                mainNavigation={generateMainMenu(navType)}
                loginNavigation={generateLoginMenu(services)}
                location={location}
                onNavigationSwitch={handleNavigationSwitch}
                baseUrl={baseUrl}
              />
            </div>
          )}

          {!hiddenLgUp && (
            <div>
              <Drawer
                anchor={config.navbar.position}
                variant="temporary"
                open={navbar.mobileOpen}
                classes={{ paper: classes.navbar }}
                onClose={() => dispatch(navbarCloseMobile({}))}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <Navbar
                  currentRole={navType}
                  userRoles={userRoles}
                  className={classes.navbarContent}
                  foldedAndClosed={foldedAndClosed}
                  mainNavigation={generateMainMenu(navType)}
                  loginNavigation={generateLoginMenu(services)}
                  location={location}
                  onNavigationSwitch={handleNavigationSwitch}
                  baseUrl={baseUrl}
                />
              </Drawer>
            </div>
          )}
        </div>
      </ThemeProvider>

      {config.navbar.display && !config.toolbar.display && !hiddenLgUp && (
        <div>
          <NavbarMobileToggleFab />
        </div>
      )}
    </>
  );
}

export default React.memo(NavbarWrapper);
