export type TCalendarMode = 'timeGridDay' | 'timeGridWeek' | 'listMonth';

export type TCalendarCommonFilter = {
  roleId?: number;
  status?: string;
  locationId?: number;
  userId?: number;
};

export const TIMEZONE_SELECTOR_TYPES = {
  LOCATION: 'location',
  COMPUTER: 'computer',
} as const;

export type TTimezoneSelectorType = (typeof TIMEZONE_SELECTOR_TYPES)[keyof typeof TIMEZONE_SELECTOR_TYPES];
