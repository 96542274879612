import useScreen from 'app/hooks/use-screen';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import { AUDIT_COLORS } from 'constants/index';
import _ from 'lodash';
import { TAuditPerformance } from 'models';
import { useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { clsxm } from 'utils/clsxm';

import { Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

ChartJS.register(ArcElement, Tooltip);

type Props = {
  isLoading?: boolean;
  overview?: TAuditPerformance;
};

const OverviewContent = ({ isLoading, overview }: Props) => {
  const theme = useTheme();

  const data = useMemo(() => {
    return [
      { name: 'Compliant', value: overview?.completionRate ?? 0, color: AUDIT_COLORS.compliant },
      { name: 'Non-compliant', value: overview?.incompleteRate ?? 0, color: AUDIT_COLORS.nonCompliant },
    ];
  }, [overview]);

  const { is1440, is1536, is1920, is2560, is3840 } = useScreen();

  const completionRate = overview?.completionRate ?? 0;

  const renderOuterRadius = () => {
    if (!is1440) return 60;
    if (!is1536) return 70;
    if (!is1920) return 75;
    if (is3840) return 240;
    if (is2560) return 140;
    return 90;
  };

  const renderInnerRadius = () => {
    if (!is1440) return 45;
    if (!is1536) return 55;
    if (!is1920) return 60;
    if (is3840) return 185;
    if (is2560) return 105;
    return 75;
  };

  const renderCompletionRateValue = () => {
    if (is3840) return 80;
    if (is2560) return 48;
    if (is1920) return 36;
    if (is1440) return 32;
    return 24;
  };

  const renderCompletionRateLabel = () => {
    if (!is1440) return 8;
    if (is3840) return 20;
    if (is2560) return 16;
    if (is1920) return 12;
    return 10;
  };

  const renderLabelCY = (y: number) => {
    return y + 20;
  };

  const renderLegend = (labelData: any) => {
    return (
      <div
        className={clsxm(
          'flex flex-wrap justify-start px-24 pb-12 gap-x-20 gap-y-4',
          !is1920 && 'flex-col px-0 absolute',
        )}
        style={{ left: !is1920 ? '50%' : 0, top: !is1920 ? -50 : 0 }}
      >
        {labelData?.payload?.map(
          (item: any) =>
            _.isNumber(item.payload?.value) && (
              <div
                key={item.value}
                className="flex items-center"
              >
                <div
                  className="mr-4 w-12 h-12 rounded-4"
                  style={{ backgroundColor: item.color }}
                />
                <Typography className={clsxm('text-13 font-500', !is1920 && 'text-11', !is1440 && 'text-10')}>
                  {item.value} {`(${item.payload?.value}%)`}
                </Typography>
              </div>
            ),
        )}

        <div className={clsxm('flex gap-4', is1920 ? 'items-center' : 'flex-col items-start gap-4')}>
          <div className="text-13 text-secondaryLight">Flagged Items</div>
          <div className={clsxm('text-13 font-600 text-secondaryMain', !is1920 && 'text-15')}>
            {overview?.flaggedItems ?? 0}
          </div>
        </div>
        <div className={clsxm('flex gap-4', is1920 ? 'items-center' : 'flex-col items-start gap-4')}>
          <div className="text-13 text-secondaryLight">Completed Actions</div>
          <div className={clsxm('text-13 font-600 text-secondaryMain', !is1920 && 'text-15')}>
            {overview?.completedActions ?? 0}
          </div>
        </div>
      </div>
    );
  };

  const renderLabel = ({ cx, cy, payload }: any) => {
    const canRenderCompletion = payload?.name !== 'Completed on time';

    if (!canRenderCompletion) return null;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={theme.palette.secondary.main}
          fontSize={renderCompletionRateValue()}
          fontWeight={600}
          fontFamily={theme.typography.fontFamily}
        >
          {`${completionRate}%`}
        </text>
        <text
          x={cx}
          y={renderLabelCY(cy)}
          dy={8}
          textAnchor="middle"
          fill={theme.palette.secondary.light}
          fontSize={renderCompletionRateLabel()}
          fontFamily={theme.typography.fontFamily}
        >
          Completion rate
        </text>
      </g>
    );
  };

  if (isLoading) {
    return (
      <div className={clsxm('flex flex-col justify-between h-full py-16', !is1920 && 'flex-row pl-24 items-center')}>
        <div className="mx-auto">
          <Skeleton
            className={clsxm('w-160 h-160', !is1920 && 'w-128 h-128', is2560 && 'w-320 h-320', is3840 && 'w-512 h-512')}
          />
        </div>
        <div className="flex flex-wrap gap-y-4 gap-x-16 justify-center px-24">
          {[145, 146, 147, 148, 149].map(width => (
            <Skeleton
              key={width}
              width={width}
              height={!is1920 ? 15 : 25}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <ResponsiveContainer
      width="90%"
      height="90%"
    >
      <PieChart
        key={Math.random()}
        className="py-12"
      >
        <Legend
          layout="horizontal"
          verticalAlign={!is1920 ? 'middle' : 'bottom'}
          align="center"
          content={renderLegend}
        />
        <Pie
          data={data}
          cx={!is1920 ? '22%' : '50%'}
          cy="50%"
          labelLine={false}
          label={renderLabel}
          outerRadius={renderOuterRadius()}
          innerRadius={renderInnerRadius()}
          dataKey="value"
        >
          {data.map((entry: any) => (
            <Cell
              key={`cell-${entry.name}`}
              fill={entry.color}
              className="outline-none"
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OverviewContent;
