import { TTodo } from 'models/employee/todo.model';

import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import { IconButton, Typography } from '@mui/material';

type Props = {
  list?: TTodo;
  readOnly?: boolean;
  onRefresh?: () => void;
};

// TODO display refresh time, group user
function TodoContentHeader({ list, readOnly, onRefresh }: Props) {
  return (
    <div className="flex flex-col p-16 bg-white md:px-32 border-b-1 border-selected">
      <div className="flex items-center">
        <div className="flex flex-col flex-1 pr-16">
          <Typography className="uppercase text-18 font-600">{list?.name}</Typography>
          <div className="flex items-center mt-4 space-x-8">
            <Typography className="flex-initial text-14 font-500 text-secondaryLight">
              {`${list?.incompleteTodoLists}/${list?.totalTodos} incomplete item(s)`}
            </Typography>
          </div>
        </div>
        {!readOnly && (
          <div className="flex items-center justify-end">
            {!readOnly && (
              <IconButton
                color="secondary"
                className="mr-8"
                onClick={() => {
                  onRefresh?.();
                }}
              >
                <RefreshSharpIcon className="text-20 text-secondary" />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TodoContentHeader;
