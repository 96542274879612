import AnimateWrapper from 'app/components/cores/animate-wrapper';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import _ from '@lodash';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    searchIcon: {
      color: theme.palette.primary.main,
    },
    divider: {
      backgroundColor: theme.palette.secondary.light,
    },
  };
});

type Props = {
  title: string;
  rightHeader: JSX.Element;
  searchText?: string;
  onSearch?: (searchText: string) => void;
};

function InternalSearchHeader({ searchText = '', title, onSearch, rightHeader }: Props) {
  const classes = useStyles();
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  /**
   * Just set searchText first time when loading page with a url that has search string,
   * next time searchText will be handled by component state and onSearch function
   */
  const firstMountRef = useRef(true);
  useEffect(() => {
    if (!firstMountRef.current || !searchText) return;
    firstMountRef.current = false;
    setCurrentSearchText(searchText);
  }, [searchText]);

  const handleSearch = () => {
    onSearch?.(currentSearchText);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(_.debounce(handleSearch, 500), [currentSearchText]);

  useEffect(() => {
    if (!isChanged) return;
    delayedQuery();
    // eslint-disable-next-line
    return delayedQuery.cancel;
  }, [currentSearchText, delayedQuery, isChanged]);

  const handleInputChange = (event: any) => {
    const text = event?.target?.value || '';
    if (!isChanged) {
      setIsChanged(true);
    }
    setCurrentSearchText(text);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearchClick = () => {
    handleSearch();
  };

  const handleClearSearchText = () => {
    setCurrentSearchText('');
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  return (
    <div className="relative flex flex-col w-full border-b">
      <div className="flex justify-start flex-1 px-24 mt-16">
        <div className="absolute flex items-center">
          <AnimateWrapper>
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              {title} Index
            </Typography>
          </AnimateWrapper>
        </div>

        <div className="flex justify-end w-full mr-0">
          <AnimateWrapper>{rightHeader}</AnimateWrapper>{' '}
        </div>
      </div>

      <div className="flex items-center justify-start flex-1 px-24 mb-12">
        <AnimateWrapper
          className="w-full"
          animation={{}}
        >
          <Paper
            className="flex items-center w-full h-40 py-4 rounded-none max-w-688 bg-paper"
            elevation={0}
          >
            <Input
              placeholder="Search"
              className="flex flex-1 px-16 text-black"
              disableUnderline={true}
              fullWidth={true}
              value={currentSearchText || ''}
              inputProps={{
                'aria-label': 'search',
              }}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            {currentSearchText && (
              <div className="w-40 h-40 p-2 mr-4">
                <IconButton
                  onClick={handleClearSearchText}
                  className="w-full h-full text-secondaryLight"
                >
                  <ClearOutlinedIcon className="text-18" />
                </IconButton>
              </div>
            )}
            <div className="w-40 h-40 p-2 mx-4">
              <IconButton
                className={clsx(classes.searchIcon, 'w-full h-full')}
                onClick={handleSearchClick}
                data-testid="search-header"
              >
                <SearchOutlinedIcon />
              </IconButton>
            </div>
          </Paper>
        </AnimateWrapper>
      </div>
    </div>
  );
}

export default InternalSearchHeader;
