import Header from 'app/components/Layout/headers/blank-header-v2';
import ReportFilter from 'app/components/reports/filters/list-report-filter';
import ReportList from 'app/components/reports/list';
import ReportTabs from 'app/components/reports/tabs';
import { useGetReports } from 'app/hooks/api/manager/report';
import { useFilter } from 'app/hooks/use-filter';
import useScreen from 'app/hooks/use-screen';
import { REPORT_TABS } from 'constants/tabs';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { clsxm } from 'utils/clsxm';
import { isAdmin, isManagerOnly } from 'utils/role';
import { getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';

export const DEFAULT_PAGINATION = { page: 1, per_page: 50 };

interface Props extends RouteComponentProps<{}> {}

function ListReport(props: Props) {
  const searchString = 'search';

  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));
  const { role: roles, permissions } = useSelector(({ auth }: any) => auth.user);
  const isManagerAccessOnly = isManagerOnly(roles);
  const { is1600 } = useScreen();

  const canViewExportReport = permissions?.['managerGridReports.view'] || isAdmin(roles);

  const ignoreFilters = [
    'q[location_external_id_cont]',
    'q[display_ateq_cont]',
    'q[location_id_eq]',
    'q[task_root_id_cont]',
  ];

  const today = moment().format('DD/MM/YYYY');

  const [reportFilters, setReportFilters] = useFilter(
    {
      ...DEFAULT_PAGINATION,
      'q[display_at_gteq]': today,
      'q[display_at_lteq]': today,
      'q[location_id_in]': [],
    },
    true,
  );

  const { data, isFetching } = useGetReports(reportFilters, {
    enabled: !!hasSelectedLocation() && canViewExportReport,
  });

  function hasSelectedLocation() {
    const defaultLocationFilterValue = reportFilters?.['q[location_id_in]'];
    return Array.isArray(defaultLocationFilterValue)
      ? !!defaultLocationFilterValue?.length
      : !!defaultLocationFilterValue;
  }

  const reports = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const transformFilter = (filter: any) => {
    const nextFilter = _.cloneDeep(filter);
    const nextListFilterValue = nextFilter?.['q[task_root_id_in]'];
    const nextLocationFilterValue = nextFilter?.['q[location_id_in]'];

    if (nextListFilterValue) {
      nextFilter['q[task_root_id_in]'] = Array.isArray(nextListFilterValue)
        ? _.map(nextListFilterValue, id => Number(id))
        : [Number(nextListFilterValue)];
    }
    if (nextLocationFilterValue) {
      nextFilter['q[location_id_in]'] = Array.isArray(nextLocationFilterValue)
        ? _.map(nextLocationFilterValue, id => Number(id))
        : [Number(nextLocationFilterValue)];
    }
    return nextFilter;
  };

  const handleFetchData = (fetchParams: any) => {
    setReportFilters({ ...reportFilters, ...transformFilter(fetchParams) } as any);
  };

  const handleFilterChange = (filter: any) => {
    setReportFilters({ ...reportFilters, ...transformFilter(filter) });
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: clsxm('-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24', !is1600 && 'h-96 -mt-96 mx-0'),
        header: clsxm(
          'min-h-header h-header sm:h-header sm:min-h-header',
          !is1600 && 'min-h-168 sm:min-h-168 sm:h-168',
        ),
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              Reports
            </Typography>
          }
        />
      }
      contentToolbar={
        <div className={clsxm('flex items-center justify-between w-full', !is1600 && 'flex-col h-full')}>
          <ReportTabs
            currentTab={REPORT_TABS[1].tab}
            classes={{
              root: clsxm(!is1600 && 'px-24 border-b-1'),
            }}
          />
          <div className={clsxm('flex items-center', !is1600 && 'w-full pl-40 pb-8')}>
            <ReportFilter
              defaultFilter={reportFilters}
              isHideListFilter={isManagerAccessOnly}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      }
      content={
        <div className="flex absolute py-8 w-full h-full border-t-1">
          <ReportList
            reports={canViewExportReport ? reports : []}
            onFetchData={handleFetchData}
            filters={filters}
            setFilters={setFilters}
            searchKey={searchString}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isFetching}
            ignoreFilters={ignoreFilters}
            reportFilters={reportFilters}
            hasSelectedLocation={hasSelectedLocation()}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default ListReport;
