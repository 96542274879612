import React from 'react';

interface Props {
  initialCount: number;
  component: JSX.Element;
}
export const useDuplicate = (props: Props) => {
  const { initialCount, component } = props;

  return [...Array(initialCount)].map(() => <div key={Math.random()}>{component}</div>);
};
