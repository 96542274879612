import { FullItemViewMultipleChipSelect } from 'app/components/cores/select';
import clsx from 'clsx';
import { DAY_OPTIONS, MONTHS } from 'constants/index';
import _ from 'lodash';
import { TFrequencyMonthDay } from 'models';

import { FormControlLabel, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  bymonthday?: TFrequencyMonthDay;
  bymonth?: TFrequencyMonthDay;
  isChecked?: boolean;
  isDisabled?: boolean;
  isShowMonthSelect?: boolean;
  onMonthsChange?: (nextMonths: TFrequencyMonthDay) => void;
  onMonthDaysChange?: (nextMonthDays: TFrequencyMonthDay) => void;
  onTypeChange?: (type: 'on' | 'on_the') => void;
};

export function FrequencyOn({
  bymonthday,
  bymonth,
  isChecked,
  isDisabled,
  isShowMonthSelect,
  onMonthsChange,
  onMonthDaysChange,
  onTypeChange,
}: Props) {
  const classes = useStyles();

  const getMonthsValue = (currentMonths?: TFrequencyMonthDay) => {
    if (_.isNumber(currentMonths)) {
      return _.filter(MONTHS, (item: any) => currentMonths === item.value) || null;
    }
    return _.filter(MONTHS, (item: any) => currentMonths?.includes(item.value)) || null;
  };

  const getMonthDaysValue = (currentMonthDays?: TFrequencyMonthDay) => {
    if (_.isNumber(currentMonthDays)) {
      return _.filter(DAY_OPTIONS, (item: any) => currentMonthDays === item.value) || null;
    }
    return _.filter(DAY_OPTIONS, (item: any) => currentMonthDays?.includes(item.value)) || null;
  };

  const handleMonthsChange = (items: any, actionPayload: any) => {
    if (actionPayload?.action === 'clear' || _.isEmpty(items)) {
      const firstMonth = _.first(MONTHS)?.value as number;
      onMonthsChange?.([firstMonth]);
      return;
    }

    const nextMonths = _.map(items, 'value');
    onMonthsChange?.(nextMonths);
  };

  const handleDaysChange = (items: any, actionPayload: any) => {
    if (actionPayload?.action === 'clear' || _.isEmpty(items)) {
      const firstMonth = _.first(DAY_OPTIONS)?.value as number;
      onMonthDaysChange?.([firstMonth]);
      return;
    }

    const nextMonthDays = _.map(items, 'value');
    onMonthDaysChange?.(nextMonthDays);
  };

  const handleChangeType = () => {
    onTypeChange?.('on');
  };

  return (
    <div className="flex items-start pl-10">
      <FormControlLabel
        value="on"
        checked={isChecked}
        control={<Radio />}
        label={<Typography className={clsx('text-13 font-600', classes.labelTypo)}>On</Typography>}
        onChange={handleChangeType}
      />

      <div className={clsx('flex-1 mt-8', isDisabled && classes.rootDisabled)}>
        <div className={clsx('space-y-8', isDisabled && 'pointer-events-none')}>
          {isShowMonthSelect && (
            <FullItemViewMultipleChipSelect
              isDisabled={isDisabled}
              placeholder={_.first(MONTHS)?.label}
              value={getMonthsValue(bymonth)}
              options={MONTHS}
              isSelectAll={true}
              menuPlacement="auto"
              menuLabel="Select Months"
              onChange={handleMonthsChange}
            />
          )}

          <FullItemViewMultipleChipSelect
            isDisabled={isDisabled}
            placeholder={_.first(DAY_OPTIONS)?.label}
            value={getMonthDaysValue(bymonthday)}
            options={DAY_OPTIONS}
            isSelectAll={true}
            menuPlacement="auto"
            menuLabel="Select Days"
            onChange={handleDaysChange}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  rootDisabled: {
    cursor: 'not-allowed',
    '& *': {
      color: `${theme.palette.secondary.light} !important`,
      fontWeight: '400 !important',
    },
  },
  selectContainer: {
    width: 120,
  },
  labelTypo: {
    width: 80,
  },
}));
