import { TTimezoneSelectorType } from 'models';
import { createContext } from 'react';

export type TFilterContext = {
  roleId?: number;
  status?: string;
  userId?: number;
  locationId?: number;
  timezoneSelector?: TTimezoneSelectorType;
  timezone?: string;
  isGlobalView?: boolean;
  timezoneBySelector: string;
  isListView?: boolean;
};

const FilterContext = createContext<TFilterContext | null>(null);

export default FilterContext;
