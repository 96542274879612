import AddActionForm from 'app/components/cores/audit/add-action-form';
import { ACTION_DEFAULT_VALUES } from 'constants/audit';
import { convertActionToFormValues, convertFormValuesToSubmitValues } from 'domains/action.domain';
import { TActionItem } from 'models';
import { EmployeeAction, EmployeeAddActionFormValues } from 'models/employee/action.model';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { adjustToLocalTimezone, isBeforeToday } from 'utils/date';
import { validateAudit } from 'utils/validation-schemas/audit';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EmployeeAction) => void;
  value?: TActionItem;
  isLoading?: boolean;
};

const ActionFormModal = ({ isOpen, onClose, onSubmit, value, isLoading }: Props) => {
  const isEdit = !!value?.id;
  const actionInPast = isEdit && isBeforeToday(adjustToLocalTimezone(value.displayAt));
  const validationSchema = validateAudit(actionInPast);
  const methods = useForm<EmployeeAddActionFormValues>({
    defaultValues: ACTION_DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;

  const handleSubmitAction = (data: EmployeeAddActionFormValues) => {
    const body: EmployeeAction = convertFormValuesToSubmitValues(data);
    onSubmit(body);
  };

  useEffect(() => {
    if (isEdit) {
      const formValues = convertActionToFormValues(value);
      reset(formValues);
    }
  }, [value, isEdit, reset]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      classes={{
        paper: 'rounded-16',
      }}
    >
      <DialogTitle
        component="div"
        className="space-y-8 uppercase bg-white"
      >
        {isEdit ? 'Update' : 'Create'} Action
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ right: 8, top: 8 }}
        className="absolute text-16"
      >
        <CloseIcon className="text-black text-20" />
      </IconButton>
      <form onSubmit={handleSubmit(handleSubmitAction)}>
        <DialogContent className="flex flex-col gap-4 px-24 bg-white">
          <FormProvider {...methods}>
            <AddActionForm actionInPast={actionInPast} />
          </FormProvider>
        </DialogContent>

        <DialogActions className="flex justify-between items-center p-24 bg-white">
          <Button
            disableElevation
            className="text-11 bg-paper text-secondaryLight font-400"
            onClick={onClose}
            disabled={isLoading}
            variant="outlined"
            sx={{
              borderColor: 'secondary.light',
              ':hover': { borderColor: 'secondary.light' },
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disableElevation
            className="text-white text-11 font-400"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isEdit ? 'Update' : 'Create'} Action
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ActionFormModal;
