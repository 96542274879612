import DataSelect from 'app/components/cores/data-select';

type Props = {
  column: { options?: { label: string; value: string }[]; [key: string]: any };
};

function SelectFilter({ column: { filterValue, setFilter, options } }: Props) {
  if (!options) {
    return null;
  }

  return (
    <div className="w-11/12 h-36 bg-paper">
      <DataSelect
        options={options}
        onChange={setFilter}
        value={filterValue}
      />
    </div>
  );
}

export default SelectFilter;
