import ConfirmDialog from 'app/components/cores/confirm-dialog';
import TransferHeader from 'app/components/cores/transfer-list/transfer-header';
import TransferSelection from 'app/components/cores/transfer-list/transfer-selection';
import useTransferList from 'app/components/cores/transfer-list/use-transfer-list';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { clsxm } from 'utils/clsxm';

import AddIcon from '@mui/icons-material/Add';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export type TransferListProps = {
  className?: string;
  values?: any;
  options?: any;
  isDisabled?: boolean;
  onChange?: (updatedValues: any) => void;
  onPublishPendingAssignmentList?: (locationIds: number[], onDone?: () => void) => void;
  onPendingAssignmentList?: (locationIds: number[], onDone?: () => void) => void;
};

function LocationTransferList({
  className,
  values = [],
  options,
  isDisabled,
  onChange,
  onPublishPendingAssignmentList,
  onPendingAssignmentList,
}: TransferListProps) {
  const dispatch = useDispatch();
  const optionLabel = 'locationName';
  const optionValue = 'locationId';

  const {
    unselectedOptions,
    leftSelectedValues,
    setLeftSelectedValues,
    rightSelectedValues,
    setRightSelectedValues,
    isMoveItemsLeftToRight,
    isMoveItemsRightToLeft,
    handleSelectAllRightItems,
    handleSelectAllLeftItems,
    selectedLeftTitle,
    selectedRightTitle,
    isLeftSelectedAllValues,
    isRightSelectedAllValues,
    isLeftIndeterminate,
    isRightIndeterminate,
    moveItemsFromLeftToRight,
    moveItemsFromRightToLeft,
  } = useTransferList({
    values,
    options,
    optionValue,
    isDestroyItem: true,
  });

  const hasPendingAssignment = useMemo(() => {
    return !!_.find(rightSelectedValues, { assignmentStatus: 'draft' });
  }, [rightSelectedValues]);

  const hasPublishedAssignment = useMemo(() => {
    return !!_.find(rightSelectedValues, item => !item.assignmentStatus || item.assignmentStatus === 'active');
  }, [rightSelectedValues]);

  const updateLocationListHeight = useCallback(() => {
    const locationWrapper = document.querySelector('.locationWrapper') as any;
    const locationContainer = document.querySelectorAll('.locationContainer') as any;
    if (!locationWrapper || !locationContainer) return;

    // reset location container height
    locationContainer?.forEach((elem: any) => {
      elem.style.height = 0;
    });

    // get location wrapper height and search box height
    const locationWrapperHeight = locationWrapper?.offsetHeight;
    const locationSearchBoxHeight = 80;

    // calculate location container height
    const locationContainerHeight = locationWrapperHeight - locationSearchBoxHeight;

    // update location container height
    locationContainer?.forEach((elem: any) => {
      elem.style.height = `${locationContainerHeight}px`;
    });
  }, []);

  // when moving selected items from left column to right column
  const handleMoveItemsFromLeftToRight = () => {
    const nextValues = moveItemsFromLeftToRight(values);
    onChange?.(nextValues);
    setLeftSelectedValues([]);
  };

  // when moving selected items from right column to left column
  const handleMoveItemsFromRightToLeft = () => {
    const nextValues = moveItemsFromRightToLeft(values);
    onChange?.(nextValues);
    setRightSelectedValues([]);
  };

  useEffect(() => {
    const handleResizeThrottle = _.throttle(updateLocationListHeight, 100);

    updateLocationListHeight();

    window.addEventListener('resize', handleResizeThrottle);

    return () => {
      window.removeEventListener('resize', handleResizeThrottle);
    };
  }, [updateLocationListHeight]);

  const getPendingAssignmentListLocations = () => {
    const pendingAssignmentListLocations = _.filter(
      rightSelectedValues,
      (item: any) => item.assignmentStatus === 'draft' && !item._destroy,
    );
    const pendingAssignmentListLocationIds = _.map(pendingAssignmentListLocations, 'locationId');
    return { pendingAssignmentListLocations, pendingAssignmentListLocationIds };
  };

  const getPublishedAssignmentListLocations = () => {
    const publishedAssignmentListLocations = _.filter(
      rightSelectedValues,
      item => (!item.assignmentStatus || item.assignmentStatus === 'active') && !item._destroy,
    );
    const publishedAssignmentListLocationIds = _.map(publishedAssignmentListLocations, 'locationId');
    return { publishedAssignmentListLocations, publishedAssignmentListLocationIds };
  };

  const handlePublishPendingAssignment = () => {
    const { pendingAssignmentListLocations, pendingAssignmentListLocationIds } = getPendingAssignmentListLocations();
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Publish pending assignment"
            message={
              <div>
                <Typography className="mb-16">
                  Are you sure you want to publish assignment for the locations below:
                </Typography>
                <ul className="pl-16 overflow-auto list-disc max-h-232">
                  {pendingAssignmentListLocations?.map((item: any) => (
                    <li key={item.id}>
                      <Typography className="text-13 font-400">{item.locationName}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            }
            statusVariant="warning"
            confirmButtonLabel="Publish"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              onPublishPendingAssignmentList?.(pendingAssignmentListLocationIds, () => {
                setRightSelectedValues([]);
              });
            }}
          />
        ),
      }),
    );
  };

  const handlePendingAssignment = () => {
    const { publishedAssignmentListLocations, publishedAssignmentListLocationIds } =
      getPublishedAssignmentListLocations();
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Pending assignment"
            message={
              <div>
                <Typography className="mb-16">
                  Are you sure you want to pending assignment for the locations below:
                </Typography>
                <ul className="pl-16 overflow-auto list-disc max-h-232">
                  {publishedAssignmentListLocations?.map((item: any) => (
                    <li key={item.id}>
                      <Typography className="text-13 font-400">{item.locationName}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            }
            statusVariant="warning"
            confirmButtonLabel="Pending"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              onPendingAssignmentList?.(publishedAssignmentListLocationIds, () => {
                setRightSelectedValues([]);
              });
            }}
          />
        ),
      }),
    );
  };

  return (
    <div className={clsxm('flex items-start w-full h-full', className)}>
      <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
        <TransferHeader
          isDisabled={isDisabled}
          isSelected={isLeftSelectedAllValues}
          isIndeterminate={isLeftIndeterminate}
          title="Locations"
          selectedTitle={selectedLeftTitle}
          onChange={handleSelectAllLeftItems}
          rightChildren={
            <Button
              disableElevation={true}
              variant="outlined"
              className="justify-between h-32 px-12 w-96 rounded-16"
              disabled={isDisabled || !isMoveItemsLeftToRight}
              onClick={handleMoveItemsFromLeftToRight}
            >
              <Typography className="text-11 font-400">Add</Typography>
              <AddIcon className="text-20" />
            </Button>
          }
        />
        <Divider />
        <TransferSelection
          isDisabled={isDisabled}
          side="left"
          list={unselectedOptions}
          selectedValues={leftSelectedValues}
          noItemMessage="No Locations"
          className="w-full h-full locationWrapper"
          classes={{ container: 'h-0 locationContainer' }}
          optionLabel={optionLabel}
          optionValue={optionValue}
          onSelect={setLeftSelectedValues}
        />
      </div>

      <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
        <TransferHeader
          isDisabled={isDisabled}
          isSelected={isRightSelectedAllValues}
          isIndeterminate={isRightIndeterminate}
          title="Chosen Locations"
          selectedTitle={selectedRightTitle}
          onChange={handleSelectAllRightItems}
          rightChildren={
            <div className="flex items-center space-x-12">
              <Button
                disableElevation={true}
                variant="outlined"
                color="primary"
                className="justify-between h-32 pl-12 pr-8 w-96 rounded-16"
                disabled={isDisabled || !hasPendingAssignment}
                onClick={handlePublishPendingAssignment}
              >
                <Typography className="text-11 font-400">Publish</Typography>
                <ControlPointIcon className="text-16" />
              </Button>
              <Button
                disableElevation={true}
                variant="outlined"
                color="primary"
                className="justify-between h-32 pl-12 pr-8 w-96 rounded-16"
                disabled={isDisabled || !hasPublishedAssignment}
                onClick={handlePendingAssignment}
              >
                <Typography className="text-11 font-400">Pending</Typography>
                <PendingActionsIcon className="text-16" />
              </Button>
              <Button
                disableElevation={true}
                variant="outlined"
                color="primary"
                className="justify-between h-32 pl-12 pr-8 w-96 rounded-16"
                disabled={isDisabled || !isMoveItemsRightToLeft}
                onClick={handleMoveItemsFromRightToLeft}
              >
                <Typography className="text-11 font-400">Remove</Typography>
                <RemoveIcon className="text-20" />
              </Button>
            </div>
          }
        />
        <Divider />
        <TransferSelection
          isDisabled={isDisabled}
          side="right"
          list={values}
          selectedValues={rightSelectedValues}
          noItemMessage="No Locations"
          className="w-full h-full locationWrapper"
          classes={{ container: 'h-0 locationContainer' }}
          optionLabel={optionLabel}
          optionValue={optionValue}
          renderLabel={(item: any) => (
            <div className="flex items-center">
              <Typography className="text-13 font-400">{item?.[optionLabel]}</Typography>
              {item?.assignmentStatus === 'draft' && (
                <Chip
                  label="Pending Assignment"
                  className="ml-8 text-11 font-500"
                  size="small"
                />
              )}
            </div>
          )}
          onSelect={setRightSelectedValues}
        />
      </div>
    </div>
  );
}

export default LocationTransferList;
