import HolidayDate from 'app/components/locations/tabs/overview/holiday-date';
import { TUpcomingEvent } from 'models/upcoming-events.model';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';

type Props = {
  event: TUpcomingEvent & { type: 'public_holiday' };
};

const PublicHolidayDisplayTime = ({ event }: Props) => {
  const { date, openingTimes } = event;
  const dateStr = formatDate(date);

  if (!openingTimes || openingTimes.length === 0)
    return <Typography className="text-13 font-500 text-secondaryMain">{dateStr}</Typography>;

  return (
    <>
      <HolidayDate
        date={date}
        openingTimes={openingTimes}
      />
    </>
  );
};
export default PublicHolidayDisplayTime;
