import DataSelect from 'app/components/cores/data-select';
import { InputNumber } from 'app/components/cores/inputs/input-number';
import clsx from 'clsx';
import { DEFAULT_END_AFTER, DEFAULT_END_TIME, METRIC_OPTIONS, POSITION_OPTIONS, TIME_FORMAT } from 'constants/index';
import { formatTimeValue } from 'domains/display-time.domain';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const useStyles = makeStyles((theme: any) => ({
  startTime: {},
  layout: {
    '& ul': {
      flex: 1,
    },
    '& li': {
      flex: 1,
    },
  },
  button: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

type Props = {
  name: string;
  control: Control<any>;
  isUseOpeningTime?: boolean;
  onChange?: (nextTime: any) => void;
};

function EndTime({ name, control, isUseOpeningTime = true, onChange }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [metric, setMetric] = useState('60');
  const [position, setPosition] = useState('after');
  const isSetMetric = useRef(false);

  // time
  const {
    field: { value: time, onChange: onTimeChange },
  } = useController({
    name: `${name}.endTime`,
    control,
    defaultValue: DEFAULT_END_TIME,
  });

  // start after
  const {
    field: { value: startAfter, onChange: onStartAfterChange },
  } = useController({
    name: `${name}.endAfter`,
    control,
    defaultValue: null,
    disabled: !isUseOpeningTime,
  });

  useEffect(() => {
    if (time === null) return;
    const isValidTime = moment(time, 'HH:mm').isValid();
    if (isValidTime) return;
    onTimeChange?.(DEFAULT_END_TIME);
  }, [time, onTimeChange]);

  // used for old data
  const formattedValue = useMemo(() => formatTimeValue(time), [time]);

  const isUseStartTime = !formattedValue && isUseOpeningTime;

  const handleChangeType = useCallback(
    (_: any, checked: boolean) => {
      if (checked) {
        onTimeChange?.(null);
        onChange?.(null);
        onStartAfterChange(DEFAULT_END_AFTER);
        closePicker();
      } else {
        onTimeChange?.(DEFAULT_END_TIME);
        onChange?.(DEFAULT_END_TIME);
        onStartAfterChange(null);
      }
    },
    [onChange, onTimeChange, onStartAfterChange],
  );

  const ActionBar = useCallback(() => {
    return (
      <div className="absolute bottom-0 w-full px-12 py-4">
        <FormControlLabel
          label="Use End Time"
          control={<Checkbox checked={!formattedValue} />}
          classes={{ label: 'text-12' }}
          onChange={handleChangeType}
        />
      </div>
    );
  }, [formattedValue, handleChangeType]);

  // effect to set position
  useEffect(() => {
    if (!startAfter) {
      return;
    }
    setPosition(startAfter < 0 ? 'before' : 'after');
  }, [startAfter]);

  // effect to set metric
  useEffect(() => {
    if (!startAfter || isSetMetric.current) {
      return;
    }
    isSetMetric.current = true;
    setMetric(startAfter % 3600 === 0 ? '3600' : '60');
  }, [startAfter]);

  const openPicker = () => {
    setIsOpen(true);
  };

  const closePicker = () => {
    setIsOpen(false);
  };

  const handleTimeChange = (value: any) => {
    const nextTime = moment(value).format(TIME_FORMAT);
    onTimeChange?.(nextTime);
    onChange?.(nextTime);
    onStartAfterChange(null);
  };

  const handleStarAfterChange = (value?: number) => {
    const nextStartAfter = Number(value) * Number(metric);
    updateStartAfter(position, nextStartAfter);
  };

  const handleMetricChange = (value: string) => {
    const originalStartAfter = startAfter / Number(metric);
    const nextStartAfter = originalStartAfter * Number(value);
    setMetric(value);
    onStartAfterChange(nextStartAfter);
  };

  const handlePositionChange = (value: string) => {
    setPosition(value);
    updateStartAfter(value, startAfter);
  };

  const updateStartAfter = (changedPosition: string, changedStartAfter: number) => {
    const nextStartAfter = Math.abs(changedStartAfter);
    if (changedPosition === 'after') {
      onStartAfterChange(nextStartAfter);
    } else {
      onStartAfterChange(-nextStartAfter);
    }
  };

  return (
    <div className="flex flex-wrap flex-1 gap-x-8 item-row gap-y-6">
      <div className={clsx('relative', classes.startTime)}>
        <TimePicker
          open={isOpen}
          ampm={false}
          timeSteps={{ minutes: 1 }}
          value={formattedValue}
          className="min-w-128 max-w-160"
          format={TIME_FORMAT}
          views={['hours', 'minutes']}
          slots={
            isUseOpeningTime
              ? {
                  actionBar: ActionBar,
                }
              : {}
          }
          slotProps={{
            layout: {
              classes: {
                contentWrapper: clsx('w-160', isUseOpeningTime && 'mb-48 ', classes.layout),
              },
            },
            textField: {
              size: 'small',
              variant: 'filled',
              InputProps: {
                disableUnderline: true,
                className: 'bg-paper rounded-4',
                classes: {
                  input: 'py-10 rounded-4 bg-paper text-13',
                },
              },
            },
          }}
          onOpen={openPicker}
          onClose={closePicker}
          onAccept={closePicker}
          onChange={handleTimeChange}
        />
        {isUseStartTime && (
          <Button
            disableElevation={true}
            disableFocusRipple={true}
            disableRipple={true}
            disableTouchRipple={true}
            className={clsx(
              'absolute inset-0 z-10 flex items-center justify-between w-full h-40 capitalize text-13 font-400 text-secondaryMain',
              classes.button,
            )}
            onClick={openPicker}
          >
            <Typography className="pl-4 text-13">End Time</Typography>
            <ArrowDropDownIcon className="-mr-6 text-secondaryLight" />
          </Button>
        )}
      </div>

      {isUseStartTime && (
        <>
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={POSITION_OPTIONS}
              value={position}
              onChange={handlePositionChange}
            />
          </div>
          <InputNumber
            value={startAfter / Number(metric)}
            variant="filled"
            className="w-72"
            InputProps={{
              disableUnderline: true,
              className: 'bg-paper rounded-4',
              classes: {
                input: 'pt-12 pb-10 rounded-4 bg-paper text-13',
              },
            }}
            allowNegative={false}
            decimalScale={0}
            disabled={!!formattedValue}
            onChange={handleStarAfterChange}
          />
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={METRIC_OPTIONS}
              value={metric}
              onChange={handleMetricChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default EndTime;
