import clsx from 'clsx';
import { TResponse } from 'models';
import { TTaskStatus } from 'models/employee/task.model';

import CheckIcon from '@mui/icons-material/Check';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

type Value = boolean | null;

type Props = {
  disabled?: boolean;
  value?: Value;
  status?: TResponse['status'];
  isLoading?: boolean;
  onChange?: (value: Value) => void;
  listStatus?: TTaskStatus;
};

const CheckMarkButton = ({ disabled, value, status, isLoading, onChange, listStatus }: Props) => {
  const handleClick = () => {
    onChange?.(!value);
  };

  const renderButtonTitle = () => {
    if (isLoading) {
      return (
        <CircularProgress
          size={16}
          color="inherit"
        />
      );
    }
    return value ? <DoneRoundedIcon className="font-bold text-40" /> : <CheckIcon className="font-bold text-24" />;
  };

  return (
    <StyledButton
      disabled={disabled}
      type="button"
      className={clsx(
        'flex items-center justify-center w-full h-40 px-24 text-left rounded-8',
        isLoading && 'pointer-events-none',
        disabled && 'cursor-not-allowed',
      )}
      active={value}
      onClick={handleClick}
      status={status}
      isLoading={isLoading}
      listStatus={listStatus}
    >
      {renderButtonTitle()}
    </StyledButton>
  );
};

export default CheckMarkButton;

const StyledButton = styled('button')<{
  active?: Value;
  status?: TResponse['status'];
  isLoading?: boolean;
  listStatus?: TTaskStatus;
}>(({ theme, active, status, isLoading, listStatus }: any) => {
  const { palette } = theme;

  const getBgColorByStatus = () => {
    if (status === 'overdue' || (isLoading && listStatus === 'overdue')) {
      return palette.overdue.light;
    }
    return '#E0F1FB';
  };

  const getColorByStatus = () => {
    if (status === 'overdue' || (isLoading && listStatus === 'overdue')) return palette.overdue.main;
    return palette.primary.main;
  };

  return {
    backgroundColor: active ? getBgColorByStatus() : palette.grey[200],
    color: active ? getColorByStatus() : palette.grey[500],
  };
});
