import ErrorMessage from 'app/components/cores/form/error-message';
import { LogicSelect } from 'app/components/cores/items-editor/conditional-logic/display/logic-select';
import { TItem } from 'models';
import { Control, useController, useWatch } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  instructionText?: string;
  hasComparisons?: boolean;
  onRemoveAllLogic?: () => void;
};

export function SingleCondition({
  control,
  name,
  selectedConditionalItem,
  instructionText = 'Flag this task, if answer is',
  hasComparisons,
  onRemoveAllLogic,
}: Props) {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ control, name: `${name}` });

  const currentItem = useWatch({ control, name: `${name}` });

  const errorMessage = error?.message;

  const index = 0;
  const conditionalComparison = currentItem?.[index] as any;

  const handleChangeConditional = (item: any) => {
    const conditionalLogic = item.conditionalLogic?.split('::');
    const nextLogic = conditionalLogic?.[0];
    const nextValue = conditionalLogic?.[1] || '';

    onChange([
      {
        ...conditionalComparison,
        ...item,
        logic: nextLogic,
        value: nextValue,
      },
    ]);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Typography className="mr-8 text-13 font-400">{instructionText}</Typography>
          <LogicSelect
            selectedConditionalItem={selectedConditionalItem}
            value={conditionalComparison}
            placeholder="Select conditional"
            onChange={handleChangeConditional}
          />
        </div>
        {hasComparisons && (
          <Button
            disableElevation={true}
            variant="outlined"
            color="error"
            className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
            onClick={onRemoveAllLogic}
          >
            Remove
          </Button>
        )}
      </div>

      <ErrorMessage message={errorMessage} />
    </div>
  );
}
