import useWindowSize from 'app/hooks/use-window-resize';
import { useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { clsxm } from 'utils/clsxm';

import { Typography } from '@mui/material';

import UntilAfter from './after';
import UntilTarget from './target';
import UntilTime from './time';

type Props = {
  frequency: number;
  label: string;
  classes?: {
    root?: string;
    title?: string;
    content?: string;
  };
  name: string;
  control: Control<any>;
};

function UntilItem({ frequency, label, classes, name, control }: Props) {
  const itemRef = useRef<any>(null);
  const { width: itemWidth } = useWindowSize(itemRef);
  const untilValue = useWatch({ name, control });

  const isShowDateTime = !['end_time', 'next_start_time'].includes(untilValue?.target);
  const isMediumScreen = itemWidth >= 460 && itemWidth < 656;
  const isSmallScreen = itemWidth < 460;

  return (
    <div
      ref={itemRef}
      className="flex flex-wrap"
    >
      <Typography className={clsxm('text-small-dark mt-12', classes?.title)}>{label}</Typography>
      <div className={clsxm('flex-1 flex gap-x-12 flex-wrap gap-y-6', classes?.content)}>
        <UntilTarget
          name={`${name}.target`}
          timeName={`${name}.time`}
          afterName={`${name}.after`}
          control={control}
          frequency={frequency}
        />
        {isShowDateTime && (
          <>
            <UntilAfter
              name={`${name}.after`}
              targetName={`${name}.target`}
              control={control}
              frequency={frequency}
              classes={{
                root: clsxm(isSmallScreen && 'w-full -ml-24'),
              }}
            />
            <UntilTime
              name={`${name}.time`}
              targetName={`${name}.target`}
              control={control}
              frequency={frequency}
              classes={{
                root: clsxm(isSmallScreen && 'w-full -ml-24', isMediumScreen && 'flex-1 ml-168 pl-6'),
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default UntilItem;
