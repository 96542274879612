import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function CalendarIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66675 1.66663C7.12699 1.66663 7.50008 2.03972 7.50008 2.49996V3.33329H12.5001V2.49996C12.5001 2.03972 12.8732 1.66663 13.3334 1.66663C13.7937 1.66663 14.1667 2.03972 14.1667 2.49996V3.33329H15.8334C16.4965 3.33329 17.1323 3.59668 17.6012 4.06553C18.07 4.53437 18.3334 5.17025 18.3334 5.83329V15.8333C18.3334 16.4963 18.07 17.1322 17.6012 17.6011C17.1323 18.0699 16.4965 18.3333 15.8334 18.3333H4.16675C3.50371 18.3333 2.86782 18.0699 2.39898 17.6011C1.93014 17.1322 1.66675 16.4963 1.66675 15.8333V5.83329C1.66675 5.17025 1.93014 4.53437 2.39898 4.06553C2.86782 3.59668 3.50371 3.33329 4.16675 3.33329H5.83342V2.49996C5.83342 2.03972 6.20651 1.66663 6.66675 1.66663ZM5.83342 4.99996H4.16675C3.94573 4.99996 3.73377 5.08776 3.57749 5.24404C3.42121 5.40032 3.33341 5.61228 3.33341 5.83329V15.8333C3.33341 16.0543 3.42121 16.2663 3.57749 16.4225C3.73377 16.5788 3.94573 16.6666 4.16675 16.6666H15.8334C16.0544 16.6666 16.2664 16.5788 16.4227 16.4225C16.579 16.2663 16.6667 16.0543 16.6667 15.8333V5.83329C16.6667 5.61228 16.579 5.40032 16.4227 5.24404C16.2664 5.08776 16.0544 4.99996 15.8334 4.99996H14.1667V5.83329C14.1667 6.29353 13.7937 6.66663 13.3334 6.66663C12.8732 6.66663 12.5001 6.29353 12.5001 5.83329V4.99996H7.50008V5.83329C7.50008 6.29353 7.12699 6.66663 6.66675 6.66663C6.20651 6.66663 5.83342 6.29353 5.83342 5.83329V4.99996ZM5.00008 9.16663C5.00008 8.70639 5.37318 8.33329 5.83342 8.33329H14.1667C14.627 8.33329 15.0001 8.70639 15.0001 9.16663C15.0001 9.62686 14.627 9.99996 14.1667 9.99996H5.83342C5.37318 9.99996 5.00008 9.62686 5.00008 9.16663Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(CalendarIcon);
