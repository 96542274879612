import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyAccountIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1493 14.5605C10.279 14.5291 10.4156 14.569 10.508 14.6653L12.0403 16.2614L13.6072 14.6625C13.7003 14.5675 13.8368 14.5289 13.966 14.5612L14.0641 14.5858C14.1271 14.6015 14.2177 14.6243 14.3285 14.6521C14.5502 14.7078 14.8528 14.7841 15.1766 14.8662C15.822 15.0299 16.5579 15.2186 16.9004 15.3137C17.3047 15.426 17.6167 15.6212 17.7896 16.0102C17.9391 16.3467 17.9625 16.7912 17.9625 17.325C17.9625 17.5321 17.7946 17.7 17.5875 17.7C17.3804 17.7 17.2125 17.5321 17.2125 17.325C17.2125 16.7713 17.1796 16.4846 17.1042 16.3148C17.0521 16.1975 16.9703 16.1115 16.6996 16.0363C16.3671 15.9439 15.6405 15.7576 14.9922 15.5932C14.6691 15.5112 14.367 15.4351 14.1457 15.3795C14.0889 15.3652 14.0375 15.3523 13.9924 15.341L12.3053 17.0625C12.2343 17.1349 12.137 17.1755 12.0356 17.175C11.9341 17.1745 11.8372 17.1329 11.767 17.0597L10.1163 15.3403C10.0684 15.3519 10.0134 15.3653 9.95242 15.3802C9.72413 15.4358 9.41275 15.5119 9.08037 15.5938C8.41283 15.7583 7.6689 15.9444 7.33787 16.0363C7.06325 16.1126 6.96895 16.2022 6.90975 16.3252C6.82918 16.4925 6.7875 16.7779 6.7875 17.325C6.7875 17.5321 6.61961 17.7 6.4125 17.7C6.20539 17.7 6.0375 17.5321 6.0375 17.325C6.0375 16.7846 6.07082 16.3387 6.234 15.9998C6.41855 15.6165 6.73675 15.4249 7.13713 15.3137C7.4811 15.2181 8.23717 15.0292 8.90088 14.8656C9.23412 14.7834 9.54619 14.7072 9.77492 14.6515C9.8893 14.6236 9.98286 14.6009 10.0478 14.5851L10.1493 14.5605Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3702 7.03378C10.9161 6.72184 11.531 6.675 11.9625 6.675C12.3389 6.675 12.919 6.74672 13.4574 7.02667C14.0087 7.31337 14.5146 7.82007 14.6917 8.6588C14.8393 9.33546 14.7618 10.1468 14.5966 10.8611C14.4317 11.5742 14.1651 12.2507 13.8858 12.6598C13.6125 13.0696 13.3469 13.3766 13.0513 13.5795C12.7431 13.791 12.4236 13.875 12.075 13.875C11.6913 13.875 11.3511 13.783 11.0357 13.5903C10.7286 13.4026 10.462 13.1297 10.2045 12.7934C9.93427 12.4538 9.65621 11.8276 9.46405 11.1454C9.26908 10.4533 9.14492 9.64187 9.22742 8.91992L9.22815 8.914C9.35447 7.94555 9.80019 7.3595 10.3702 7.03378ZM9.97224 9.00804C9.90542 9.59799 10.0062 10.304 10.186 10.9421C10.3681 11.5889 10.6139 12.1046 10.7928 12.3282L10.7979 12.3347C11.0271 12.6345 11.2286 12.8292 11.4268 12.9503C11.6177 13.067 11.8212 13.125 12.075 13.125C12.2889 13.125 12.4569 13.0778 12.6269 12.9611C12.8089 12.8362 13.0118 12.6188 13.263 12.242L13.2655 12.2382C13.4732 11.9347 13.7123 11.356 13.8659 10.6921C14.0193 10.0287 14.0731 9.34069 13.9587 8.81764L13.958 8.81476C13.8348 8.22935 13.4972 7.89271 13.1114 7.69208C12.7122 7.48453 12.2611 7.425 11.9625 7.425C11.569 7.425 11.1152 7.47191 10.7423 7.68497C10.394 7.88398 10.0714 8.25349 9.97224 9.00804Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.375C7.23654 3.375 3.375 7.23654 3.375 12C3.375 16.7635 7.23654 20.625 12 20.625C16.7635 20.625 20.625 16.7635 20.625 12C20.625 7.23654 16.7635 3.375 12 3.375ZM2.625 12C2.625 6.82233 6.82233 2.625 12 2.625C17.1777 2.625 21.375 6.82233 21.375 12C21.375 17.1777 17.1777 21.375 12 21.375C6.82233 21.375 2.625 17.1777 2.625 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(MyAccountIcon);
