import DatePicker from 'app/components/cores/date-picker';
import RadioGroup from 'app/components/cores/inputs/radio-group';
import AttributeContainer from 'app/components/lists/attribute-container';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';

type Props = {
  name: string;
  control?: Control;
};

function EndDateAssignment({ name, control }: Props) {
  const endDateFormat = 'YYYY-MM-DD';
  const {
    field: { value: endDateValue, onChange: onEndDateChange, ref: endDateRef },
  } = useController({
    name,
    control,
  });

  const [noEndDate, setIsNoEndDate] = useState(false);

  useEffect(() => {
    if (endDateValue === null) {
      setIsNoEndDate(true);
    }
  }, [endDateValue]);

  const handleChangeEndDateOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    setIsNoEndDate(newValue === 'true');
    onEndDateChange(null);
  };

  const handleEndDateChange = (newValue: Moment | null) => {
    onEndDateChange(newValue?.format(endDateFormat));
  };

  return (
    <AttributeContainer label="End Date">
      <RadioGroup
        value={noEndDate}
        onChange={handleChangeEndDateOption}
        options={[
          { label: 'No end date', value: true },
          { label: 'Customize End Date', value: false },
        ]}
      />

      <div className="mt-8">
        {!noEndDate && (
          <DatePicker
            ref={endDateRef}
            value={moment(endDateValue, endDateFormat) || null}
            onChange={handleEndDateChange}
          />
        )}
      </div>
    </AttributeContainer>
  );
}

export default EndDateAssignment;
