/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import Action from 'app/components/cores/index-table/action';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import { Paths } from 'constants/index';
import { TTemplateBasic } from 'models';
import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';

import _ from '@lodash';
import { Typography } from '@mui/material';

import TemplateHeaderAction from './header-action';

type Props = {
  templates: TTemplateBasic[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  selectedRowIds: number[];
  filters: any;
  setFilters: any;
  onFetchData: (params: any) => void;
  setSelectedRowIds: (ids: number[]) => void;
  onAction?: ({ templateId, action }: { templateId: number; action: string }) => void;
  onBulkAction?: (action: string, templates: { action: string; id: number; skipReload?: boolean }[]) => void;
};

function TemplateList({
  templates,
  pageCount,
  totalDataCount,
  isLoading,
  searchKey,
  selectedRowIds,
  filters,
  setFilters,
  onFetchData,
  setSelectedRowIds,
  onBulkAction,
  onAction,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const statusOptions = [
    { label: 'All', value: 'active' },
    { label: 'Draft', value: 'draft' },
    { label: 'Published', value: 'published' },
    { label: 'Inactive', value: 'inactive' },
  ];

  const handleDeactivateButtonClick = (templateId: any, templateStatus: string) => {
    return templateStatus === 'draft'
      ? onAction?.({ templateId, action: 'delete' })
      : onAction?.({ templateId, action: 'deactivate' });
  };

  const handleActivateButtonClick = (templateId: any) => {
    onAction?.({ templateId, action: 'activate' });
  };

  const handleViewButtonClick = (templateId: number) => {
    history.push(`${Paths.template}/${templateId}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
        id: 'code',
        sortable: true,
        width: '15%',
        className: 'capitalize',
      },
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        width: '50%',
        Cell: ({ row }: { row: Row<TTemplateBasic> }) => {
          return (
            <Link
              onClick={event => {
                event.stopPropagation();
              }}
              to={`/templates/${row.original.id}`}
            >
              <Typography className="cursor-pointer font-600">{row.original?.name}</Typography>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        sortable: true,
        width: '15%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TTemplateBasic> }) => <div className="ml-16">{cell.value}</div>,
      },
      {
        Header: () => {
          return <div className="flex justify-end pr-32">Action</div>;
        },
        id: 'action',
        sortable: false,
        className: '',
        Cell: ({ row }: { row: Row<TTemplateBasic> }) => (
          <Action
            onView={handleViewButtonClick}
            id={row.original.id}
            onActivate={handleActivateButtonClick}
            onDeactivate={(id: number) => {
              handleDeactivateButtonClick(id, row.original.status);
            }}
            status={row.original.status}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [location],
  );

  const handleRowSelected = (ids: number[]) => {
    if (templates.length === 0) {
      return;
    }
    setSelectedRowIds(ids);
  };

  const selectedTemplates = useMemo(() => {
    return _(templates).keyBy('id').at(selectedRowIds).value();
  }, [templates, selectedRowIds]);

  const handleBulkAction = (action: string) => {
    const bulk = selectedTemplates.map((x: TTemplateBasic) => ({
      action,
      id: x.id,
      skipReload: true,
    }));
    onBulkAction?.(action, bulk);
  };

  const currentFilterStatus = filters.find((x: any) => x.id === 'status')?.value;

  return (
    <div className="flex flex-col w-full">
      <IndexTable
        columns={columns as Column<any>[]}
        data={templates}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        withSelection={true}
        onRowSelected={handleRowSelected}
        headerActions={
          <TemplateHeaderAction
            status={currentFilterStatus}
            totalRecords={totalDataCount}
            pageRecords={templates.length}
            selectedRecords={selectedRowIds.length}
            title="template"
            onBulkAction={handleBulkAction}
          />
        }
      />
    </div>
  );
}

export default TemplateList;
