export function isFloat(num: number) {
  return Number(num) === num && num % 1 !== 0;
}

export function toFixed(num: number, fractionDigits: number) {
  if (!num) return 0;
  if (!isFloat(num)) return Number(num); // e.g. 94.0 -> 94

  const fixedNumber = num.toFixed(fractionDigits);
  const [intPart, floatPart] = fixedNumber.split('.');

  // e.g. 94.00 -> 94
  //      94.23 -> 94.23
  const zeros = ''.padStart(fractionDigits, '0');
  return floatPart === zeros ? intPart : fixedNumber;
}

export function toNumber(num?: null | string | number) {
  return num === undefined || num === null || num === '' ? null : Number(num);
}

export function pad(num: number) {
  return num < 10 ? `0${num}` : num;
}
