import ErrorMessage from 'app/components/cores/form/error-message';
import { TItem } from 'models';
import { Control, useController } from 'react-hook-form';

import { Button, Typography } from '@mui/material';

import { NotifyForm } from './form';
import { LogicSelect } from './logic-select';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  onShowMessageModal?: () => void;
};

export function SingleCondition({ control, name, selectedConditionalItem, onShowMessageModal }: Props) {
  const notificationConditionalName = `${name}.notificationConditionals.0`;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: notificationConditionalName });

  const errorMessage = error?.message;
  const index = 0;
  const conditionalComparison = value?.comparisons?.[index] as any;

  const handleChangeConditional = (item: any) => {
    const conditionalLogic = item.conditionalLogic?.split('::');
    const nextLogic = conditionalLogic?.[0];
    const nextValue = conditionalLogic?.[1] || '';
    onChange({
      ...value,
      comparisons: [
        {
          ...conditionalComparison,
          ...item,
          logic: nextLogic,
          value: nextValue,
        },
      ],
    });
  };

  const handleRemoveAllConditional = () => {
    onChange({
      message: null,
      overrideSubject: null,
      sendToRoles: [],
      sendToRolesDetail: [],
      sendToUsers: [],
      sendToUsersDetail: [],
      comparisons: [],
    });
  };

  return (
    <div>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">Trigger notification if</Typography>
        <LogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={conditionalComparison}
          placeholder="Select conditional"
          menuPlacement="auto"
          onChange={handleChangeConditional}
        />
      </div>

      <ErrorMessage message={errorMessage} />

      {conditionalComparison && (
        <div>
          <NotifyForm
            name={notificationConditionalName}
            control={control}
            onShowMessageModal={onShowMessageModal}
          />
          <div className="flex items-center justify-end mt-8 space-x-4">
            <Button
              disableElevation={true}
              variant="outlined"
              color="error"
              className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
              onClick={handleRemoveAllConditional}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
