import { useUnavailableRoles } from 'app/hooks/api/locations';
import { ASSIGNMENT_FIELDS, ASSIGNMENT_FIELDS_MAP } from 'constants/index';
import { checkUnavailableRoles, getOrgRoleIds } from 'domains/role.domain';
import { getVariationCustomizeField } from 'domains/variation.domain';
import _ from 'lodash';
import { TAssignment, TAssignmentCustomizeSetting } from 'models';
import { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  CommonChip,
  DatesChip,
  DisplayFrequenciesChip,
  EndTimeChip,
  ListTypeChip,
  RolesChip,
  StartTimeChip,
} from './assignment-field-chips';

type Props = {
  customisedSettings: TAssignmentCustomizeSetting[];
  assignmentAttributes: TAssignment;
  onDelete?: (fieldName: TAssignmentCustomizeSetting) => void;
};

function AssignmentFieldContainer({ customisedSettings, assignmentAttributes, onDelete }: Props) {
  const classes = useStyles();

  const orgRoleIds = getOrgRoleIds(assignmentAttributes.assignmentOrgRolesAttributes as any);
  const locationIds = [assignmentAttributes.location.id];

  const { data: unavailable } = useUnavailableRoles(locationIds, orgRoleIds);
  const isUnavailable = checkUnavailableRoles(unavailable);

  const handleDeleteField = (fieldName: TAssignmentCustomizeSetting) => () => {
    onDelete?.(fieldName);
  };

  const components = useMemo(() => {
    const componentMapping = new Map();

    componentMapping.set(ASSIGNMENT_FIELDS_MAP.START_DATE, DatesChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.END_DATE, DatesChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.START_TIME, StartTimeChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.END_TIME, EndTimeChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.LIST_TYPE, ListTypeChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.ON_DEMAND, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.REPORT_TO_ROLES, RolesChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.ASSIGNMENT_ORG_ROLES_ATTRIBUTES, RolesChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.FREQUENCIES, DisplayFrequenciesChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.NOTIFICATION_AVAILABLE, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.HOLIDAY, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.BLACKOUT, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.ENABLE_ON_CLOSED_DAY, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.LIST_SUBMISSION, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.GENERATE_BETWEEN_BREAK_TIME, CommonChip);
    componentMapping.set(ASSIGNMENT_FIELDS_MAP.LIST_TRIGGER, CommonChip);

    return componentMapping;
  }, []);

  const renderChips = (fieldName: TAssignmentCustomizeSetting) => {
    const { value } = getVariationCustomizeField(fieldName, assignmentAttributes);
    const fieldLabel = _.find(ASSIGNMENT_FIELDS, { value: fieldName })?.label || '';
    const Comp = components.get(fieldName);

    if (!Comp) return null;

    return (
      <Chip
        key={fieldName}
        label={
          <Comp
            label={fieldLabel}
            value={value}
            isUnavailable={isUnavailable}
          />
        }
        classes={{
          root: 'bg-selected pr-4 mt-8',
          label: 'pl-16 pr-12',
          deleteIcon: classes.chipDeleteIcon,
        }}
        deleteIcon={<CloseIcon />}
        onDelete={handleDeleteField(fieldName)}
      />
    );
  };

  return <div className="space-x-8">{customisedSettings?.map(renderChips)}</div>;
}

export default AssignmentFieldContainer;

const useStyles = makeStyles((theme: any) => ({
  chipDeleteIcon: {
    fill: theme.palette.secondary.main,
    width: 24,
    height: 24,
    borderRadius: 12,
    padding: 4,
    '&:hover': {
      backgroundColor: '#b3d2e6',
    },
  },
}));
