import { ControlledTextInput } from 'app/components/cores/inputs/text';
import { ConditionInputs } from 'app/components/cores/items-editor/common-date-time-condition';
import clsx from 'clsx';
import { AFTER_END_OF_VALUE, BEFORE_END_OF_VALUE, CONDITIONAL_DATE_TIME_OPTIONS, WITHIN_VALUE } from 'constants/index';
import { getConditionalDateTimeOptionsByType } from 'domains/date-time-logic.domain';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, useFieldArray, useFormState, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LogicSelect from './logic-select';

type Props = {
  name: string;
  control?: Control;
};

function DateTimeScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;
  const dateTimeTypeName = `${name}.dateTimeType`;

  const classes = useStyles();
  const { errors } = useFormState({ control });
  const dateTimeType = useWatch({ name: dateTimeTypeName, control });
  const updatedScoreSettings = useWatch({ name: scoreSettingsName, control });

  const {
    fields: scoreSettings,
    update,
    remove,
    append,
  } = useFieldArray({
    control,
    name: scoreSettingsName,
  });

  const presentSettings = _.filter(scoreSettings, (field: any) => field?.logic !== 'completed');
  const canRemove = presentSettings?.length > 1;

  const options = useMemo(() => {
    const dateTimeOptions = getConditionalDateTimeOptionsByType(dateTimeType);
    return _.map(dateTimeOptions, option => ({
      value: option.conditionalLogic,
      label: option.conditionalLabel,
    }));
  }, [dateTimeType]);

  const handleRemoveSetting = (index: number) => () => {
    remove(index);
  };

  const handleAddSetting = () => {
    append({ logic: 'in_the_past', value: null, score: 0 });
  };

  const getSettingValue = (logic: string) => {
    const option = _.find(CONDITIONAL_DATE_TIME_OPTIONS, { conditionalLogic: logic }) as any;
    if (!option) return null;
    return {
      value: option.conditionalLogic,
      label: option.conditionalLabel,
    };
  };

  const handleChangeLogic = (index: number) => (selectedOption: any) => {
    const nextLogic = selectedOption?.value;
    let timeType;
    let value = null;

    if ([BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE].includes(nextLogic)) {
      timeType = 'day';
      value = '0';
    }

    if (WITHIN_VALUE === nextLogic) {
      timeType = dateTimeType === 'date' ? 'day' : 'hour';
      value = '1';
    }

    const setting = updatedScoreSettings[index];
    update(index, { ...setting, logic: nextLogic, timeType, value });
  };

  return (
    <div className="pb-24">
      {/* header */}
      <div className="flex items-center justify-between px-24 mb-4">
        <Typography className="flex-1 pr-24 text-secondaryLight text-11 font-500">Score if answer is</Typography>
        <Typography className="w-136 text-secondaryLight text-11 font-500">Score</Typography>
      </div>

      {/* list */}
      <div className="flex flex-col">
        {scoreSettings?.map((setting: any, index: number) => {
          if (setting.logic === 'completed') return null;
          return (
            <div key={setting.id}>
              <div className={clsx('flex justify-between px-24 py-8 duration-200 hover:bg-gray-50', classes.item)}>
                <div className="flex flex-1 pr-16">
                  {/* type */}
                  <div className="pr-16 w-136">
                    <LogicSelect
                      value={getSettingValue(setting.logic)}
                      options={options}
                      onChange={handleChangeLogic(index)}
                    />
                  </div>

                  {/* value */}
                  <div className="flex-1">
                    {[BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE].includes(setting?.logic) && (
                      <ConditionInputs
                        name={`${scoreSettingsName}.${index}`}
                        control={control}
                        dateTimeType={dateTimeType}
                        logic={setting?.logic}
                        classes={{
                          control: classes.selectControl,
                        }}
                        selectProps={{
                          menuPortalTarget: document.body,
                          menuPosition: 'fixed',
                          menuPlacement: 'auto',
                        }}
                      />
                    )}
                    {setting?.logic === WITHIN_VALUE && (
                      <ConditionInputs
                        name={`${scoreSettingsName}.${index}`}
                        control={control}
                        dateTimeType={dateTimeType}
                        logic={setting?.logic}
                        classes={{
                          control: classes.selectControl,
                        }}
                        selectProps={{
                          menuPortalTarget: document.body,
                          menuPosition: 'fixed',
                          menuPlacement: 'auto',
                        }}
                      />
                    )}
                  </div>
                </div>

                {/* score */}
                <div className="w-136">
                  <div className="flex items-center">
                    <div className="flex-1">
                      <ControlledTextInput
                        showErrorMessage={false}
                        type="number"
                        name={`${scoreSettingsName}.${index}.score`}
                        control={control}
                        variant="standard"
                        className={clsx('w-full px-8 bg-paper rounded-8 duration-200', classes.textInput)}
                        placeholder="Enter score"
                        InputProps={{
                          className: 'min-h-32 text-11 text-secondaryLight font-500',
                          disableUnderline: true,
                        }}
                      />
                    </div>
                    <div className="ml-8">
                      <IconButton
                        disabled={!canRemove}
                        onClick={handleRemoveSetting(index)}
                      >
                        <DeleteOutlinedIcon className="text-16" />
                      </IconButton>
                    </div>
                  </div>
                  <ErrorMessage
                    name={`${scoreSettingsName}.${index}.score`}
                    errors={errors}
                    render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex items-center justify-end pr-24 mt-8">
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="w-64 h-24 capitalize text-11 font-400 rounded-4"
            onClick={handleAddSetting}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DateTimeScoreSetting;

const useStyles = makeStyles((theme: any) => ({
  item: {
    '&:hover $textInput, &:hover $selectControl': {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },
  textInput: {},
  selectControl: {},
}));
