import { Control } from 'react-hook-form';

import { UntilTargetDaily, UntilTargetMonthly, UntilTargetWeekly } from './until-target';

type Props = {
  frequency: number;
  name: string;
  timeName: string;
  afterName: string;
  control: Control<any>;
};

function UntilTarget({ frequency, ...props }: Props) {
  const renderTarget = () => {
    switch (frequency) {
      case 1:
        return (
          <UntilTargetMonthly
            key="monthly-target"
            {...props}
          />
        );
      case 2:
        return (
          <UntilTargetWeekly
            key="weekly-target"
            {...props}
          />
        );
      case 3:
        return (
          <UntilTargetDaily
            key="daily-target"
            {...props}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderTarget()}</div>;
}

export default UntilTarget;
