import clsx from 'clsx';
import { components as ReactSelectComponents } from 'react-select';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    '& *': {
      fill: theme.palette.secondary.light,
    },
  },
}));

function TypeOptionArrow(props: any) {
  const classes = useStyles();
  const { isDisabled } = props;

  return (
    <ReactSelectComponents.IndicatorsContainer
      {...props}
      className={clsx(isDisabled && classes.root)}
    />
  );
}

export default TypeOptionArrow;
