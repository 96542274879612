import clsx from 'clsx';
import pluralize from 'pluralize';
import React, { useState } from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    button: {
      color: theme.palette.primary.main,
    },
  };
});

type Props = {
  status: string;
  totalRecords: number;
  pageRecords: number;
  selectedRecords: number;
  title: string;
  onBulkAction?: (action: string) => void;
};

function LocationListHeaderAction({ status, selectedRecords, totalRecords, pageRecords, title, onBulkAction }: Props) {
  const classes = useStyles();

  const [isSelectedAllPages, setIsSelectedAllPages] = useState(false);

  const isShowSelectedAllPage = selectedRecords === pageRecords && totalRecords !== selectedRecords;
  const handleBulkAction = (action: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    if (isSelectedAllPages) {
      onBulkAction?.(action);
      return;
    }
    onBulkAction?.(action);
  };

  const handelClearSelected = () => {
    setIsSelectedAllPages(false);
  };
  return (
    <div className="flex flex-row">
      <div className="flex flex-row items-center h-full space-x-8">
        {status !== 'active' && (
          <div className="flex items-center">
            <Button
              disableElevation={true}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              variant="text"
              className="capitalize text-20 hover:bg-transparent"
              onClick={handleBulkAction('activate')}
            >
              <ControlPointIcon color="primary" />
              <Typography className="ml-4 text-13 font-500 text-secondaryMain">Publish</Typography>
            </Button>
          </div>
        )}

        <div className="flex items-center">
          <Button
            disableElevation={true}
            disableFocusRipple={true}
            disableRipple={true}
            disableTouchRipple={true}
            variant="text"
            className="capitalize text-20 hover:bg-transparent"
            onClick={handleBulkAction('deactivate')}
          >
            <PowerSettingsNewIcon color="primary" />
            <Typography className="ml-4 text-13 font-500 text-secondaryMain">Unpublish</Typography>
          </Button>
        </div>

        {status !== 'draft' && (
          <div className="flex items-center">
            <Button
              disableElevation={true}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              variant="text"
              className="capitalize text-20 hover:bg-transparent"
              onClick={handleBulkAction('draft')}
            >
              <PendingActionsIcon color="primary" />
              <Typography className="ml-4 text-13 font-500 text-secondaryMain">Pending</Typography>
            </Button>
          </div>
        )}
      </div>

      <div className="flex flex-row justify-center w-full ml-32">
        {!isShowSelectedAllPage && (
          <>
            <div className="flex items-center h-full">
              <Typography className="mt-2 normal-case text-12 font-500">
                {`${pluralize(title, selectedRecords, true)} selected`}
              </Typography>
            </div>
          </>
        )}

        {isShowSelectedAllPage &&
          (isSelectedAllPages ? (
            <>
              <div className="flex items-center h-full">
                <Typography className="mt-2 normal-case text-12 font-500">
                  {`All ${pluralize(title, selectedRecords, true)} are selected`}
                </Typography>
              </div>

              <Button
                className={clsx('text-12 normal-case font-500', classes.button)}
                onClick={handelClearSelected}
              >
                Clear all selection
              </Button>
            </>
          ) : (
            <>
              <div className="flex items-center h-full">
                <Typography className="mt-2 normal-case text-12 font-500">
                  All {selectedRecords} users on this page are selected.
                </Typography>
              </div>
              <Button
                className={clsx('text-12 normal-case font-500', classes.button)}
                onClick={() => setIsSelectedAllPages(true)}
              >
                Select all {totalRecords} users in this training
              </Button>
            </>
          ))}
      </div>
    </div>
  );
}

export default LocationListHeaderAction;
