import { authRoles } from 'app/auth';

import Index from './index';

const EmptyPagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.employee,
  routes: [
    {
      component: Index,
    },
  ],
};

export default EmptyPagesConfig;
