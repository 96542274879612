import { InputNumber } from 'app/components/cores/inputs/input-number';
import { useRef } from 'react';
import { clsxm } from 'utils/clsxm';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type Props = {
  label: string;
  value?: number;
  isRunUntilEnd?: boolean;
  isUseEndTime?: boolean;
  isDisabled?: boolean;
  classes?: {
    root?: string;
    title?: string;
    content?: string;
  };
  onChange?: (newValue?: number) => void;
  onRunUntilEndChange?: (checked: boolean) => void;
};

function NumberOfRun({
  value,
  isRunUntilEnd,
  isUseEndTime,
  isDisabled,
  label,
  classes,
  onChange,
  onRunUntilEndChange,
}: Props) {
  const inputRef = useRef<any>(null);

  const handleRunUntilEndChange = (_: any, checked: boolean) => {
    onRunUntilEndChange?.(checked);

    if (!checked) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  };

  return (
    <div className={clsxm('h-full space-y-12', classes?.root)}>
      <Typography className={clsxm('text-small-dark', classes?.title)}>{label}</Typography>
      <div className={clsxm('flex flex-row h-40 my-8 space-x-8', classes?.content)}>
        <InputNumber
          inputRef={inputRef}
          disabled={value === -1 || isDisabled}
          value={value || 1}
          onChange={onChange}
          variant="filled"
          className="w-80"
          InputProps={{
            disableUnderline: true,
            className: 'bg-paper rounded-4',
            classes: {
              input: 'py-10 rounded-4 bg-paper',
            },
          }}
          allowNegative={false}
        />

        <FormControlLabel
          disabled={isUseEndTime || isDisabled}
          label="Run until end time"
          control={<Checkbox checked={isUseEndTime ? false : isRunUntilEnd} />}
          classes={{
            label: 'text-13',
          }}
          onChange={handleRunUntilEndChange}
        />
      </div>
    </div>
  );
}

export default NumberOfRun;
