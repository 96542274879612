import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const defaultOptions = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
    legend: { display: false },
  },
};

type Props = {
  labels: string[];
  data: number[];
  options?: Object;
};

function LineChart({ labels, data, options }: Props) {
  const chartData = {
    labels,
    datasets: [
      {
        data,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <Line
      options={{ ...defaultOptions, ...options }}
      data={chartData}
    />
  );
}

export default LineChart;
