import SplashScreen from 'app/components/cores/splash-screen';
import bluefitSSOService from 'app/services/bluefitSSOService';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from '@reduxjs/toolkit';

import { logoutUser, setUserData } from './store/userSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.bluefitCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  bluefitCheck = () =>
    new Promise(resolve => {
      bluefitSSOService.on('onAutoLogin', () => {
        /**
         * Sign in and retrieve user data from Api
         */
        bluefitSSOService
          .signInWithTokenAndGetSetting()
          .then(user => {
            this.props.setUserData(user);

            resolve();
          })
          .catch(error => {
            this.props.showMessage({ message: error.message });

            resolve();
          });
      });

      bluefitSSOService.on('onAutoLogout', message => {
        if (message) {
          this.props.showMessage({ message });
        }

        this.props.logout();

        resolve();
      });

      bluefitSSOService.on('onNoAccessToken', () => {
        resolve();
      });

      bluefitSSOService.init();

      return Promise.resolve();
    });
  render() {
    return this.state.waitAuthCheck ? <SplashScreen /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Auth);
