import { Search } from 'history';
import queryString from 'query-string';

import _ from '@lodash';

import { generateQueryObject } from './string';

/**
 * Generate url with pagination's page index param
 * @param {Location} currentLocation
 * @param {number} newPage
 * @return {Location}
 */
export function generatePaginationURLWithPage(currentLocation: Location, newPage: number): Location {
  if (newPage < 1) {
    return currentLocation;
  }
  const searchParams = queryString.parse(currentLocation.search);
  return {
    ...currentLocation,
    search: queryString.stringify({ ...searchParams, page: newPage }),
  };
}

/**
 * Generate url with filter data
 * @param {currentSearch} currentSearch
 * @param {object} queryObject
 * @return {Search}
 */
export function generateURLWithFilter(currentSearch: Search, queryObject: object): Search {
  const searchParams = queryString.parse(currentSearch);
  return queryString.stringify({ ...searchParams, ...queryObject }, { skipEmptyString: true });
}

/**
 * Generate url with pagination's page size param
 * @param {currentSearch} currentSearch
 * @param {number} newPage
 * @return {Location}
 */
export function generateURLWithPerPage(currentSearch: Search, newSize: number): Search {
  if (newSize < 0) {
    return currentSearch;
  }
  const searchParams = queryString.parse(currentSearch);
  return queryString.stringify({ ...searchParams, per_page: newSize, page: 1 });
}

/**
 * Get filter from url
 * @param {string} searchText
 * @return {Object[]}
 */
export function getFilterFromURL(searchText: string): {}[] {
  if (!searchText) return [];
  const urlQuery = queryString.parse(searchText);
  const result: any = [];

  Object.keys(urlQuery).forEach(field => {
    const value = urlQuery[field];
    let id = '';

    const keepFields = ['status', 'role'];
    const keepSuffixFields = ['org_role_id_in'];

    if (keepFields.includes(field)) {
      id = field;
    } else if (/^q\[[a-zA-Z_]+\]$/.test(field)) {
      // remove `q[` and `]`
      const pattern1 = /q\[|\]/g;
      id = field.replace(pattern1, '');

      // remove suffix
      const pattern2 = /_eq|_in|_lt|_lteq|_gt|_gteq|_cont$/;
      if (!keepSuffixFields.includes(id)) {
        id = id.replace(pattern2, '');
      }
    }

    if (id && value) {
      result.push({ id, value });
    }
  });

  return result;
}

export function getDefaultUrl(queryObject: any, pageSize: number) {
  if (pageSize < 0) return queryString.stringify(queryObject);
  return queryString.stringify({ per_page: pageSize, page: 1, ...queryObject });
}

export function getDefaultFilterFromUrl(currentSearch: Search, defaultFilters?: any) {
  const filterFromUrl = getFilterFromURL(currentSearch);
  if (!defaultFilters) return filterFromUrl;
  return _.uniqBy([...filterFromUrl, ...defaultFilters], 'id');
}

/**
 * Generate url with search param
 * @param {currentSearch} currentSearch
 * @param {string} searchText
 * @return {Search}
 */
export function generateURLWithSearch(currentSearch: Search, searchString: string, searchText: string): Search {
  const searchParams = queryString.parse(currentSearch);
  return queryString.stringify({ ...searchParams, page: 1, [searchString]: searchText });
}

export function getQueryObject(currentSearch: Search, filters?: any, ignoreFilters?: string[]) {
  const parsedSearchString = queryString.parse(currentSearch);
  if (!filters || filters.length === 0) return parsedSearchString;
  const queryObject = generateQueryObject(filters);
  return _.omit({ ...queryObject, ...parsedSearchString }, ignoreFilters || []);
}

export function parseFilterNumberToString(filter: { [key: string]: any }) {
  return _.mapValues(filter, item => (_.isNumber(item) ? String(item) : item));
}
