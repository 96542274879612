import { createContext } from 'react';

export type TPerformanceContext = {
  statsType: string;
  setStatsType: (nextStatsType: string) => void;
};

const PerformanceContext = createContext<TPerformanceContext | null>(null);

export default PerformanceContext;
