import { SingleSelect } from 'app/components/cores/select';
import clsx from 'clsx';
import { MONTHS, POSITIONS, WEEKDAYS } from 'constants/index';
import _ from 'lodash';
import { TFrequencyMonthDay } from 'models';
import { Weekday as RRuleWeekday } from 'rrule';

import { FormControlLabel, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  bysetpos?: number;
  byweekday?: RRuleWeekday[];
  by1month?: TFrequencyMonthDay;
  isChecked?: boolean;
  isDisabled?: boolean;
  isShowMonthSelect?: boolean;
  onPositionChange?: (nextPosition: number) => void;
  onWeekDayChange?: (nextWeekDay: RRuleWeekday[]) => void;
  onMonthChange?: (nextMonth: TFrequencyMonthDay) => void;
  onTypeChange?: (type: 'on' | 'on_the') => void;
};

export function FrequencyOnThe({
  bysetpos,
  byweekday,
  by1month,
  isChecked,
  isDisabled,
  isShowMonthSelect,
  onPositionChange,
  onWeekDayChange,
  onMonthChange,
  onTypeChange,
}: Props) {
  const classes = useStyles();

  const getPositionValue = (currentPosition?: number) => {
    return POSITIONS?.find((item: any) => item.value === currentPosition);
  };

  const getWeekDayValue = (currentByWeekDay?: RRuleWeekday[]) => {
    const firstWeekDay = currentByWeekDay?.[0];
    if (!firstWeekDay) return undefined;
    return _.find(WEEKDAYS, (item: any) => item.value?.weekday === firstWeekDay?.weekday);
  };

  const getMonthValue = (currentMonth?: TFrequencyMonthDay) => {
    return _.find(MONTHS, (item: any) => currentMonth === item.value) || null;
  };

  const handlePositionChange = (item: any) => {
    const nextPosition = item.value;
    onPositionChange?.(nextPosition);
  };

  const handleWeekDayChange = (item: any) => {
    const nextPosition = item.value;
    onWeekDayChange?.([nextPosition]);
  };

  const handleMonthChange = (item: any) => {
    const nextMonth = item.value;
    onMonthChange?.(nextMonth);
  };

  const handleChangeType = () => {
    onTypeChange?.('on_the');
  };

  return (
    <div className="flex items-start pl-10">
      <FormControlLabel
        value="on_the"
        checked={isChecked}
        control={<Radio />}
        label={<Typography className={clsx('text-13 font-600', classes.labelTypo)}>On The</Typography>}
        onChange={handleChangeType}
      />

      <div className={clsx('flex-1 mt-8', isDisabled && classes.rootDisabled)}>
        <div className={clsx('flex items-center flex-1 flex-wrap', isDisabled && 'pointer-events-none')}>
          <div className={clsx('mr-8 mb-8', classes.selectContainer)}>
            <SingleSelect
              placeholder={_.first(POSITIONS)?.label}
              value={getPositionValue(bysetpos)}
              options={POSITIONS}
              menuPlacement="auto"
              onChange={handlePositionChange}
            />
          </div>

          <div className={clsx('mr-8 mb-8', classes.selectContainer)}>
            <SingleSelect
              placeholder={_.first(WEEKDAYS)?.label}
              value={getWeekDayValue(byweekday)}
              options={WEEKDAYS}
              menuPlacement="auto"
              onChange={handleWeekDayChange}
            />
          </div>

          {isShowMonthSelect && (
            <div className="flex items-center mb-8">
              <Typography className="ml-8 mr-32 text-11 font-500 text-secondaryLight">Of</Typography>
              <div className={clsx('mr-8', classes.selectContainer)}>
                <SingleSelect
                  isDisabled={isDisabled}
                  placeholder={_.first(MONTHS)?.label}
                  value={getMonthValue(by1month)}
                  options={MONTHS}
                  menuPlacement="auto"
                  onChange={handleMonthChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: any) => ({
  rootDisabled: {
    cursor: 'not-allowed',
    '& *': {
      color: `${theme.palette.secondary.light} !important`,
      fontWeight: '400 !important',
    },
  },
  selectContainer: {
    width: 120,
  },
  labelTypo: {
    width: 80,
  },
}));
