import GridViewIcon from '@mui/icons-material/GridView';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';

const Sidebar = () => {
  return (
    <Box
      className="flex flex-col items-center flex-shrink-0 h-screen py-16 space-y-16 bg-gray-200"
      sx={{ width: '60px' }}
    >
      <GridViewIcon
        sx={{ color: 'primary.main' }}
        className="font-bold text-24"
      />
      <SearchIcon className="font-bold text-24" />
    </Box>
  );
};

export default Sidebar;
