import { CircleProgressBarWithLabel } from 'app/components/cores/statistics';
import { taskStatus, taskStatusLabels } from 'constants/performance';
import { TBaseTaskStatistics } from 'models';

type Props = {
  rowData: TBaseTaskStatistics;
  filterTask?: string | null;
  circularProgressSize?: number;
};

const DistributionCell = ({ rowData, filterTask = null, circularProgressSize }: Props) => {
  const values = [
    rowData.completedOnTime,
    rowData.completedLate,
    rowData.incomplete,
    rowData.inProgress,
    rowData.pending,
  ];

  return (
    <div className="flex justify-between space-x-8 w-full">
      {values?.map((value, index: number) => (
        <CircleProgressBarWithLabel
          key={taskStatus[index]}
          type={taskStatus[index] as any}
          value={value}
          filterTask={filterTask}
          tooltipText={`${taskStatusLabels[index]}: ${value}%`}
          circularProgressSize={circularProgressSize}
        />
      ))}
    </div>
  );
};

export default DistributionCell;
