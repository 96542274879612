import Header from 'app/components/Layout/headers/blank-header-v2';
import HistoryList from 'app/components/histories/list';
import ReportTabs from 'app/components/reports/tabs';
import { useGetHistories } from 'app/hooks/api/manager/histories';
import { useFilter } from 'app/hooks/use-filter';
import { Paths } from 'constants/paths';
import { REPORT_TABS } from 'constants/tabs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { isAdmin } from 'utils/role';
import { getDefaultFilterFromUrl, parseFilterNumberToString } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import { Typography } from '@mui/material';

export const DEFAULT_PAGINATION = { page: 1, per_page: 50 };

interface Props extends RouteComponentProps<{}> {}

function HistoriesIndex(props: Props) {
  const searchString = 'search';
  const history = useHistory();

  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));
  const { permissions, role: roles } = useSelector(({ auth }: any) => auth.user);
  const employeeSubmissionTabNotAvailable = !permissions?.managerReports && !isAdmin(roles);
  const employeeSubmissionTabNotViewable = !permissions?.['managerReports.histories'] && !isAdmin(roles);

  const ignoreFilters = ['q[location_external_id_cont]', 'q[completed_ateq_cont]', 'q[org_role_id_eq]'];

  const today = moment().format('DD/MM/YYYY');

  const [historyFilters, setHistoryFilters] = useFilter(
    {
      ...DEFAULT_PAGINATION,
      'q[completed_at_gteq]': today,
      'q[completed_at_lteq]': today,
    },
    true,
  );

  const { data, isFetching } = useGetHistories(parseFilterNumberToString(historyFilters), {
    enabled: !employeeSubmissionTabNotViewable,
  });

  const histories = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (employeeSubmissionTabNotAvailable) {
      history.replace(`${Paths.history}/report`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const handleFetchData = (fetchParams: any) => {
    setHistoryFilters({ ...historyFilters, ...fetchParams } as any);
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <Typography
              variant="h6"
              className="flex uppercase text-18 text-secondaryMain"
            >
              Reports
            </Typography>
          }
        />
      }
      contentToolbar={<ReportTabs currentTab={REPORT_TABS[0].tab} />}
      content={
        <div className="flex absolute py-8 w-full h-full border-t-1">
          <HistoryList
            histories={histories}
            onFetchData={handleFetchData}
            filters={filters}
            setFilters={setFilters}
            searchKey={searchString}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isFetching}
            ignoreFilters={ignoreFilters}
            historyFilters={historyFilters}
            setHistoryFilters={setHistoryFilters}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default HistoriesIndex;
