import LanguageSwitcher from 'app/components/Layout/LanguageSwitcher';
import NavbarMobileToggleButton from 'app/components/Layout/NavbarMobileToggleButton';
import UserMenu from 'app/components/Layout/UserMenu';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
}));

function ToolbarBluefitLayout(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(selectToolbarTheme);

  const classes = useStyles(props);
  const theme = useTheme();
  const hiddenLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(classes.root, 'flex relative z-10')}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
        elevation={2}
      >
        <Toolbar className="p-0">
          {config.navbar.display && config.navbar.position === 'left' && !hiddenLgUp && (
            <NavbarMobileToggleButton className="w-48 h-48 p-0" />
          )}

          <div className="flex flex-1" />

          <div className="flex items-center px-16">
            <LanguageSwitcher />
            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && !hiddenLgUp && <NavbarMobileToggleButton />}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default React.memo(ToolbarBluefitLayout);
