import { useUpcomingEventsByLocation } from 'app/hooks/api/locations';
import clsx from 'clsx';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { formatDate } from 'utils/date';

import _ from '@lodash';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BlackoutCalendar from './calendar';
import UpcomingEventCardItem from './tabs/overview/upcoming-event-card-item';

type Props = {
  locationId: number;
};

type CalendarFilter = {
  startDate: string;
  endDate: string;
};

const useStyles = makeStyles({
  calendar: {
    maxHeight: '360px',
  },
});

function UpcomingEvents({ locationId }: Props) {
  const classes = useStyles();

  const [calendarFilter, setCalendarFilter] = useState<CalendarFilter>({
    startDate: formatDate(moment().startOf('month').toISOString()),
    endDate: formatDate(moment().endOf('month').toISOString()),
  });

  const { data: upcomingEventsByLocation } = useUpcomingEventsByLocation(locationId, {
    'q[from_date]': calendarFilter.startDate,
    'q[to_date]': calendarFilter.endDate,
  });

  const upcomingEvents = useMemo(() => upcomingEventsByLocation?.records || [], [upcomingEventsByLocation?.records]);

  const getDayMonthYear = (date: string) => {
    const [year, month, day] = _.split(date, '-', 3);

    return {
      year: +year || moment().year(),
      month: +month || moment().month() + 1,
      day: +day || moment().date(),
    };
  };

  const dateRange = _.chain(upcomingEvents)
    .map(item => {
      if (item.type === 'public_holiday') {
        return [new DateObject(item.date), new DateObject(item.date)];
      }

      if (item.type === 'blackout_time') {
        const startDate = getDayMonthYear(item.startDate);
        const endDate = getDayMonthYear(item.endDate);
        return [new DateObject(startDate), new DateObject(endDate)];
      }

      return [];
    })
    .value();

  const handleChange = (dateObj: DateObject) => {
    const { year, month } = dateObj;

    const startDate = `01-${month.number}-${year}`;
    const endDate = `${month.length}-${month.number}-${year}`;

    setCalendarFilter({
      startDate,
      endDate,
    });
  };

  return (
    <div>
      <div />

      <div className="flex flex-col md:flex-row">
        {/* calendar */}
        <div className="flex flex-col justify-start w-full px-24 pt-8 pb-24 md:border-r-1 md:w-320 lg:w-360">
          <div>
            <BlackoutCalendar
              value={dateRange}
              onMonthChange={handleChange}
              onYearChange={handleChange}
            />
          </div>
        </div>

        {/* list */}
        {upcomingEvents?.length === 0 ? (
          <div className="flex justify-center flex-1 w-full h-full p-8 pt-24 md:w-auto">
            <Typography className="italic text-11">No upcoming event</Typography>
          </div>
        ) : (
          <div className={clsx('flex-1 w-full p-8 mx-auto space-y-8 overflow-y-scroll md:w-auto', classes.calendar)}>
            {upcomingEvents?.map((event, i) => {
              return (
                <UpcomingEventCardItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  event={event}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default UpcomingEvents;
