import { TUpcomingEvent } from 'models/upcoming-events.model';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';

type Props = {
  event: TUpcomingEvent & { type: 'blackout_time' };
};

const BlackoutDisplayTime = ({ event }: Props) => {
  const { startTime, endTime, startDate, endDate, allDay } = event;

  const isSameDay = startDate === endDate;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const getFormattedTime = () => {
    if (allDay) {
      if (isSameDay) return formattedStartDate;
      return `${formattedStartDate} - ${formattedEndDate}`;
    }

    if (isSameDay) return `${startTime} - ${endTime} ${formattedStartDate}`;
    return `${startTime} ${formattedStartDate} - ${endTime} ${formattedEndDate}`;
  };

  return <Typography className="ml-2 text-13 text-secondaryMain font-500">{getFormattedTime()}</Typography>;
};

export default BlackoutDisplayTime;
