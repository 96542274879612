import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const googleTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

function useGa() {
  const location = useLocation();

  useEffect(() => {
    if (!googleTrackingId) return;
    initializeGA();
  }, []);

  useEffect(() => {
    if (!googleTrackingId) return;
    pageViewsTracking();
  }, [location.pathname, location.search]);

  function initializeGA() {
    ReactGA.initialize(googleTrackingId as string);
  }

  function pageViewsTracking() {
    const page = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: 'pageview', page });
  }

  return null;
}

export default useGa;
