import { TItem } from 'models';
import { Control } from 'react-hook-form';

import { Typography } from '@mui/material';

import { NotifyForm } from './form';
import { MultipleLogicSelect } from './multiple-logic-select';

type Props = {
  name: string;
  control?: Control;
  selectedConditionalItem: TItem;
  defaultCondition?: any;
  onChange?: (item: any) => void;
  onShowMessageModal?: () => void;
};

export function MultipleConditionSelect({
  name,
  control,
  selectedConditionalItem,
  defaultCondition,
  onChange,
  onShowMessageModal,
}: Props) {
  const notificationConditionalName = `${name}.notificationConditionals.0`;

  return (
    <>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">Trigger notification if</Typography>
        <MultipleLogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={defaultCondition || null}
          placeholder="Select conditional"
          menuPlacement="auto"
          onChange={onChange}
        />
      </div>
      {defaultCondition && (
        <NotifyForm
          name={notificationConditionalName}
          control={control}
          onShowMessageModal={onShowMessageModal}
        />
      )}
    </>
  );
}
