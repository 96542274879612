import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useAdminGetEmployees } from 'app/hooks/api/employees';
import useSelect from 'app/hooks/use-select';
import { TSelectItem } from 'models';
import { useState } from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  control: {
    minHeight: '32px !important',
  },
  text: {
    fontSize: '1.1rem !important',
  },
}));

type Props = {
  value: TSelectItem | null;
  onChange?: (data: TSelectItem) => void;
};

function UserSelect({ value, onChange }: Props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(50);

  const { data, isLoading } = useAdminGetEmployees({
    page: pageIndex,
    per_page: pageSize,
    'q[full_name_cont]': searchText,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  return (
    <SelectInfiniteScroll
      isLoading={isLoading}
      isClearable={true}
      defaultOptions={true}
      className="w-full"
      name="q[user_id_eq]"
      value={value}
      placeholder="User"
      SelectProps={{
        classes: {
          control: classes.control,
          text: classes.text,
          placeholder: classes.text,
        },
      }}
      options={options || []}
      onChange={onChange}
      onInputChange={handleSearch}
      getOptionValue={(option: TSelectItem) => option.id}
      getOptionLabel={(option: TSelectItem) => option.name}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}

export default UserSelect;
