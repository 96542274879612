import { hideMessage } from 'app/store/fuse/messageSlice';
import clsx from 'clsx';
import _omit from 'lodash/omit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { amber, blue, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: '#FFFFFF',
  },
  warning: {
    backgroundColor: amber[600],
    color: '#FFFFFF',
  },
  capitalizeFirstLetter: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info',
};

function FuseMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ fuse }) => fuse.message.state);
  const options = useSelector(({ fuse }) => fuse.message.options);
  const errors = options?.errors;

  const convertErrorToString = errors => {
    const errorMessages = [];

    if (typeof errors === 'string') {
      errorMessages.push(errors);
    }

    if (Array.isArray(errors)) {
      errors.forEach(e => {
        if (typeof e === 'string') {
          errorMessages.push(e);
        }
      });
    }

    return errorMessages;
  };
  const errorMessages = convertErrorToString(errors);

  const classes = useStyles();

  return (
    <Snackbar
      {..._omit(options, ['isShowVariantIcon'])}
      open={state}
      onClose={() => dispatch(hideMessage())}
      classes={{
        root: classes.root,
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={
          <>
            <div className="flex items-center">
              {options.isShowVariantIcon && variantIcon[options.variant] && (
                <Icon color="inherit">{variantIcon[options.variant]}</Icon>
              )}
              <Box>
                <Typography className={clsx('mx-8 whitespace-pre-line', classes.capitalizeFirstLetter)}>
                  {options.message}
                </Typography>
                {errorMessages?.length > 0 &&
                  errorMessages?.map((error, index) => (
                    <Typography
                      key={index}
                      className={clsx('mx-8 whitespace-pre-line text-11 break-words')}
                    >
                      {error}
                    </Typography>
                  ))}
              </Box>
            </div>
          </>
        }
        action={
          options?.action || [
            <div
              className="flex-initial w-fit"
              key={Date.now()}
            >
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => dispatch(hideMessage())}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>,
          ]
        }
      />
    </Snackbar>
  );
}

export default React.memo(FuseMessage);
