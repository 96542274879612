import clsx from 'clsx';
import { useMemo } from 'react';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';

type Props = {
  date: string;
  isToday?: boolean;
  isShowMonth?: boolean;
};

function CalendarHeader({ date, isToday, isShowMonth }: Props) {
  const { month, day, weekday } = useMemo(() => {
    return {
      month: formatDate(date, 'MMM'),
      day: formatDate(date, 'DD'),
      weekday: formatDate(date, 'ddd'),
    };
  }, [date]);

  return (
    <div className="flex h-0">
      <div className="flex items-center justify-center bg-white rounded-4 w-104 min-h-24">
        <Typography
          color="primary"
          className={clsx('mr-8 text-18 font-600', !isToday && 'text-secondaryMain')}
        >
          {day}
        </Typography>

        {isShowMonth && (
          <Typography
            color="primary"
            className={clsx('mr-8 text-13 font-400 uppercase', !isToday && 'text-secondaryMain')}
          >
            {`${month}, `}
          </Typography>
        )}

        <Typography
          color="primary"
          className={clsx('text-13 font-500', !isToday && 'text-secondaryLight')}
        >
          {weekday}
        </Typography>
      </div>
    </div>
  );
}

export default CalendarHeader;
