import { ControlledTextInput } from 'app/components/cores/inputs/text';
import clsx from 'clsx';
import { CONDITIONAL_NUMERIC_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TItemSoreSetting } from 'models';
import { useMemo } from 'react';
import { Control, useFieldArray, useFormState } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LogicSelect from './logic-select';

type Props = {
  name: string;
  control?: Control;
};

function NumericScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;

  const classes = useStyles();
  const { errors } = useFormState({ control });

  const {
    fields: scoreSettings,
    update,
    remove,
    append,
  } = useFieldArray({
    control,
    name: scoreSettingsName,
  });

  const presentSettings = _.filter(scoreSettings, (field: any) => field?.logic !== 'completed');
  const canRemove = presentSettings?.length > 1;

  const options = useMemo(() => {
    return _.map(CONDITIONAL_NUMERIC_OPTIONS, option => ({
      value: option.conditionalLogic,
      label: option.conditionalLabel,
    }));
  }, []);

  const handleRemoveSetting = (index: number) => () => {
    remove(index);
  };

  const handleAddSetting = () => {
    append({ logic: 'eq', value: '', score: 0 });
  };

  const getSettingValue = (logic: string) => {
    const option = _.find(CONDITIONAL_NUMERIC_OPTIONS, { conditionalLogic: logic }) as any;
    if (!option) return null;
    return {
      value: option.conditionalLogic,
      label: option.conditionalLabel,
    };
  };

  const handleChangeLogic = (index: number, setting: TItemSoreSetting) => (selectedOption: any) => {
    const nextLogic = selectedOption?.value;
    update(index, { ...setting, logic: nextLogic });
  };

  return (
    <div className="pb-24">
      {/* header */}
      <div className="flex items-center justify-between px-24 mb-4">
        <div className="flex items-center flex-1 pr-24">
          <Typography className="pr-16 w-192 text-secondaryLight text-11 font-500">Score if answer is</Typography>
          <Typography className="flex-1 text-secondaryLight text-11 font-500">Value</Typography>
        </div>
        <Typography className="w-136 text-secondaryLight text-11 font-500">Score</Typography>
      </div>

      {/* list */}
      <div className="flex flex-col">
        {scoreSettings?.map((setting: any, index: number) => {
          if (setting.logic === 'completed') return null;
          return (
            <div key={setting.id}>
              <div className={clsx('flex justify-between px-24 py-8 duration-200 hover:bg-gray-50', classes.item)}>
                <div className="flex flex-1 pr-16">
                  {/* type */}
                  <div className="pr-16 mt-4 w-192">
                    <LogicSelect
                      value={getSettingValue(setting.logic)}
                      options={options}
                      onChange={handleChangeLogic(index, setting)}
                    />
                  </div>

                  {/* value */}
                  <div className="flex-1">
                    <ControlledTextInput
                      showErrorMessage={false}
                      type="number"
                      name={`${scoreSettingsName}.${index}.value`}
                      control={control}
                      variant="standard"
                      className={clsx('w-full px-8 bg-paper rounded-8 duration-200', classes.textInput)}
                      placeholder="Enter value"
                      InputProps={{
                        className: 'min-h-32 text-11 text-secondaryLight font-500',
                        disableUnderline: true,
                      }}
                    />
                    <ErrorMessage
                      name={`${scoreSettingsName}.${index}.value`}
                      errors={errors}
                      render={({ message }: any) => (
                        <Typography className="text-11 text-errorMain">{message}</Typography>
                      )}
                    />
                  </div>
                </div>

                {/* score */}
                <div className="w-136">
                  <div className="flex items-center">
                    <div className="flex-1">
                      <ControlledTextInput
                        showErrorMessage={false}
                        type="number"
                        name={`${scoreSettingsName}.${index}.score`}
                        control={control}
                        variant="standard"
                        className={clsx('w-full px-8 bg-paper rounded-8 duration-200', classes.textInput)}
                        placeholder="Enter score"
                        InputProps={{
                          className: 'min-h-32 text-11 text-secondaryLight font-500',
                          disableUnderline: true,
                        }}
                      />
                    </div>
                    <div className="ml-8">
                      <IconButton
                        disabled={!canRemove}
                        onClick={handleRemoveSetting(index)}
                      >
                        <DeleteOutlinedIcon className="text-16" />
                      </IconButton>
                    </div>
                  </div>
                  <ErrorMessage
                    name={`${scoreSettingsName}.${index}.score`}
                    errors={errors}
                    render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex items-center justify-end pr-24 mt-8">
          <Button
            disableElevation={true}
            variant="contained"
            color="primary"
            className="w-64 h-24 capitalize text-11 font-400 rounded-4"
            onClick={handleAddSetting}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NumericScoreSetting;

const useStyles = makeStyles((theme: any) => ({
  item: {
    '&:hover $textInput, &:hover $selectControl': {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },
  textInput: {},
  selectControl: {},
}));
