import { formatRule } from 'domains/frequency.domain';
import _ from 'lodash';
import { TFrequencyDaily, TFrequencyMonthly, TFrequencyRuleFormat, TFrequencyWeekly, TFrequencyYearly } from 'models';
import { useCallback, useMemo } from 'react';
import { RRule, Frequency as RRuleFrequency, Options as RRuleOptions } from 'rrule';

import { FrequencyDailyType, FrequencyMonthlyType, FrequencyWeeklyType, FrequencyYearlyType } from '../types';

type Props = {
  frequency?: RRuleFrequency;
  settings: any;
  onChange?: (nextFrequencySettings: TFrequencyYearly | TFrequencyMonthly | TFrequencyWeekly | TFrequencyDaily) => void;
  onRuleFormat?: (formattedRule: TFrequencyRuleFormat) => void;
};

export function FrequencyTypeSwitch({ frequency, settings, onChange, onRuleFormat }: Props) {
  const handleRuleFormat = useCallback(
    (rule: Partial<RRuleOptions>) => {
      const nextSettings = {
        ...settings,
        [frequency as RRuleFrequency]: rule,
      };
      const { ruleString, ruleText } = formatRule(frequency, nextSettings);
      onRuleFormat?.({ rule, ruleString, ruleText });
    },
    // eslint-disable-next-line
    [frequency, settings],
  );

  const mappingTypeToComponent = (_frequency?: RRuleFrequency) => {
    const typeMapping = new Map();

    typeMapping.set(RRule.YEARLY, FrequencyYearlyType);
    typeMapping.set(RRule.MONTHLY, FrequencyMonthlyType);
    typeMapping.set(RRule.WEEKLY, FrequencyWeeklyType);
    typeMapping.set(RRule.DAILY, FrequencyDailyType);

    return typeMapping.get(_frequency) || null;
  };

  const Component = useMemo(() => mappingTypeToComponent(frequency), [frequency]);

  if (!Component || _.isUndefined(frequency)) {
    return null;
  }

  return (
    <Component
      settings={settings[frequency] || {}}
      onChange={onChange}
      onRuleFormat={handleRuleFormat}
    />
  );
}
