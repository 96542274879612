import Logo from 'app/components/Layout/Logo';
import NavbarFoldedToggleButton from 'app/components/Layout/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from 'app/components/Layout/NavbarMobileToggleButton';
import Navigation from 'app/components/Layout/Navigation';
import NavigationSwitch from 'app/components/Layout/NavigationSwitch';
import clsx from 'clsx';
import { SHOW_NAVBAR_SWITCH } from 'constants/navbar';
import { canShowNavigationSwitch } from 'domains/navbar.domain';
import React from 'react';

import FuseScrollbars from '@fuse/core/FuseScrollbars';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const navbarWidth = 240;

const useStyles = makeStyles(theme => {
  return {
    content: {
      overflowX: 'hidden',
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      background:
        'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 40px, 100% 10px',
      backgroundAttachment: 'local, scroll',
    },
    settings: {
      background: theme.palette.primary.dark,
      overflowX: 'hidden',
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 40px, 100% 10px',
      backgroundAttachment: 'local, scroll',
    },
    menuLogin: {
      position: 'absolute !important',
      bottom: 0,
      width: navbarWidth,
    },
  };
});

function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    foldedAndClosed,
    userRoles,
    mainNavigation = [],
    loginNavigation = [],
    onNavigationSwitch,
    currentRole,
    baseUrl,
  } = props;

  const hiddenLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className={clsx('flex flex-col overflow-hidden h-full', props.className)}>
      <AppBar
        color="secondary"
        position="static"
        elevation={0}
        className="flex flex-row items-center flex-shrink h-64 px-12 min-h-64"
      >
        <a
          href={baseUrl}
          target="_self"
          className={foldedAndClosed ? 'flex flex-1 mr-4' : 'flex flex-1 mx-8 justify-center h-full'}
        >
          <Logo
            brandLogo={props.settings?.brandLogo}
            brandLogoSymbol={props.settings?.brandLogoSymbol}
            foldedAndClosed={foldedAndClosed}
          />
        </a>

        {hiddenLgUp && <NavbarFoldedToggleButton className="w-24 h-24 p-0" />}

        {!hiddenLgUp && (
          <NavbarMobileToggleButton className="w-24 h-24 p-0">
            <Icon>{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}"</Icon>
          </NavbarMobileToggleButton>
        )}
      </AppBar>

      <Divider />

      <NavigationSwitch
        show={SHOW_NAVBAR_SWITCH && canShowNavigationSwitch(userRoles)}
        userRoles={userRoles}
        foldedAndClosed={foldedAndClosed}
        onNavigationSwitch={onNavigationSwitch}
        currentRole={currentRole}
      />

      <FuseScrollbars
        className={clsx(classes.content)}
        option={{ suppressScrollX: true }}
      >
        <Navigation
          layout="vertical"
          navigation={mainNavigation}
        />
      </FuseScrollbars>

      <FuseScrollbars
        className={clsx(classes.settings, classes.menuLogin, 'mt-auto order-2')}
        option={{ suppressScrollX: true }}
      >
        <Navigation
          layout="vertical"
          navigation={loginNavigation}
          className="py-0"
        />
      </FuseScrollbars>
    </div>
  );
}

export default React.memo(Navbar);
