import { TResponsePagination } from 'models';

import { useGetList } from './request';

const areaURL = '/api/select_options/areas';

export const areaKeys = {
  all: ['areas'] as const,
  lists: () => [...areaKeys.all, 'list'] as const,
  list: (filters: any) => [...areaKeys.lists(), { filters }] as const,
  details: () => [...areaKeys.all, 'detail'] as const,
  detail: (id: number) => [...areaKeys.details(), id] as const,
};

export function useGetAreas(filters: any) {
  return useGetList<TResponsePagination<any>>([...areaKeys.list(filters)], `${areaURL}`, filters, {
    refetchOnMount: true,
  });
}
