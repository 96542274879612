import React from 'react';

import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  children: JSX.Element;
  icon?: JSX.Element;
};

const PreviewBox = ({ title, children, icon }: Props) => {
  return (
    <div className="h-440 flex flex-col px-24 py-16 border-t border-gray-400">
      <div className={icon && 'flex items-center justify-between'}>
        <Typography
          className="text-13 font-400"
          style={{ color: '#7C808B' }}
        >
          {title}
        </Typography>
        {icon && <div>{icon}</div>}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default React.memo(PreviewBox);
