import FilterContext, { TFilterContext } from 'app/components/cores/employee-calendar/filter-context';
import { useDateTimeTz } from 'app/hooks/use-date-time-tz';
import useTask from 'app/hooks/use-task';
import clsx from 'clsx';
import { getDisplayRoleOrUserName } from 'domains/employee/task.domain';
import { TAdminCalendar } from 'models';
import React, { useContext, useState } from 'react';

import { EventContentArg, ViewApi } from '@fullcalendar/core';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

import { ModalReviewCalendar } from './modal-review-calendar';
import { ModalReviewGlobalCalendar } from './modal-review-global-calendar';

type Props = {
  eventInfo: EventContentArg;
  isMyList?: boolean;
};

export const ListViewCalendarTaskCell: React.FC<Props> = ({ eventInfo, isMyList }) => {
  const { getListStatusColor } = useTask();

  const { timezoneBySelector } = useContext(FilterContext) as TFilterContext;

  const { extractDateTimeInTz, checkSameDateTz } = useDateTimeTz(timezoneBySelector);

  const task = eventInfo?.event?.extendedProps as TAdminCalendar & {
    startDate: string | Date;
    endDate: string | Date;
  };
  const view = eventInfo?.view as ViewApi;
  const startListDate = task?.startDate;
  const endListDate = task?.endDate;
  const { name, listRootStatus } = task;
  const viewTitle = view?.title;
  const isShowRootStatus = !isMyList && listRootStatus && listRootStatus !== 'published';

  const displayName = getDisplayRoleOrUserName(task);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listColor = getListStatusColor(task.status, task.onDemandList);

  const renderListTime = () => {
    const isSameListDate = checkSameDateTz(startListDate, endListDate);

    const {
      tzStartDate: startDate,
      tzStartTime: startTime,
      tzEndDate: endDate,
      tzEndTime: endTime,
    } = extractDateTimeInTz(startListDate, endListDate);

    if (!endListDate) {
      return (
        <div className="flex pr-12 ml-12 w-104">
          <Typography className="text-13 font-400 text-secondaryLight">{startTime}</Typography>
        </div>
      );
    }

    return (
      <div className="flex pr-12 ml-12 w-104">
        <div>
          <Typography className="text-13 font-400 text-secondaryLight">{startTime}</Typography>
          {!isSameListDate && <Typography className="text-10 font-400 text-secondaryLight">{startDate}</Typography>}
        </div>
        <Typography className="px-4 text-13 font-400 text-secondaryLight">-</Typography>
        <div>
          <Typography className="text-13 font-400 text-secondaryLight">{endTime}</Typography>
          {!isSameListDate && <Typography className="text-10 font-400 text-secondaryLight">{endDate}</Typography>}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={clsx(
          'flex items-center w-full h-full cursor-pointer break-words pl-136 py-8 pr-16 list-event-cell',
          isShowRootStatus && 'bg-gray-50',
        )}
        onClick={handleOpen}
        role="button"
        tabIndex={0}
      >
        <CircleIcon
          className="text-9"
          sx={{ color: listColor }}
        />
        {renderListTime()}
        <Typography className="pr-12 text-13 font-400 text-secondaryLight w-160">{displayName}</Typography>
        <div className="flex justify-between flex-1">
          <Typography className="flex-1 px-6 py-2 leading-4 text-black text-13 font-600">{name}</Typography>
          {isShowRootStatus && (
            <Typography
              className={clsx('text-11 font-500 mt-2 capitalize w-56 text-right', {
                'text-gray-400': listRootStatus === 'draft',
                'text-taskPending': listRootStatus === 'pending',
              })}
            >
              {listRootStatus}
            </Typography>
          )}
        </div>
      </div>

      {open && (
        <>
          {isMyList ? (
            <ModalReviewCalendar
              open={open}
              onClose={handleClose}
              title={viewTitle}
              task={task}
              view={view}
              eventInfo={eventInfo}
            />
          ) : (
            <ModalReviewGlobalCalendar
              open={open}
              onClose={handleClose}
              title={viewTitle}
              task={task}
              view={view}
              eventInfo={eventInfo}
            />
          )}
        </>
      )}
    </>
  );
};
