import { Control, useWatch } from 'react-hook-form';

import CommonScoreSetting from './common';
import SpecialScoreSetting from './specical';

type Props = {
  control?: Control;
  name: string;
  isOpenSettingModal?: boolean;
  onOpenSettingModal?: () => void;
  onClose?: () => void;
  onSave?: () => void;
};

function ScoreSettingContainer({ control, name, isOpenSettingModal, onOpenSettingModal, onClose, onSave }: Props) {
  const typeName = `${name}.type`;
  const listType = useWatch({ name: typeName, control });

  const generateItems = (type?: string) => {
    if (!type) return null;

    const mapping = new Map();

    mapping.set('Items::YesNo', SpecialScoreSetting);
    mapping.set('Items::MultipleChoice', SpecialScoreSetting);
    mapping.set('Items::Rating', SpecialScoreSetting);
    mapping.set('Items::Numeric', SpecialScoreSetting);
    mapping.set('Items::DateTime', SpecialScoreSetting);

    return mapping.get(type) || null;
  };

  const ComponentConfiguration = generateItems(listType);

  return (
    <>
      {ComponentConfiguration && (
        <ComponentConfiguration
          name={name}
          control={control}
          isOpenSettingModal={isOpenSettingModal}
          onOpenSettingModal={onOpenSettingModal}
          onClose={onClose}
          onSave={onSave}
        />
      )}
      <CommonScoreSetting
        name={name}
        control={control}
      />
    </>
  );
}

export default ScoreSettingContainer;
