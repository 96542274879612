import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyTaskServiceIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillOpacity={0.54}
        d="M7.014 22.667h-2a.666.666 0 0 1-.587-.96L8.367 14H4a.667.667 0 0 1-.566-1.027l7.12-11.333a.666.666 0 0 1 .54-.307h8.953a.666.666 0 0 1 .513 1.127l-6.04 6.873h3.587a.667.667 0 0 1 .487 1.107l-11.087 12a.666.666 0 0 1-.493.227Zm-.894-1.334h.607l9.847-10.666H13.06a.667.667 0 0 1-.513-1.127l6.04-6.873H11.46l-6.28 10h4.287a.667.667 0 0 1 .587.96L6.12 21.333Z"
      />
    </SvgIcon>
  );
}

export default React.memo(MyTaskServiceIcon);
