import useShowMessage from 'app/hooks/use-show-message';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { isFirefox } from 'react-device-detect';

import { UseMutationResult } from '@tanstack/react-query';

type Props = {
  useQueryHook: () => UseMutationResult<any, unknown, any>;
  // useQueryHook: () => any;
};

const useExport = ({ useQueryHook }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { showError } = useShowMessage();
  const { mutateAsync: exportData, isLoading: isExporting } = useQueryHook();

  const doExport = (payload: any) => {
    setIsModalOpen(true);
    exportData(payload)
      .then(({ csvUrl }: { csvUrl: string }) => {
        if (isFirefox) {
          window.location.href = csvUrl;
        } else {
          saveAs(csvUrl);
        }
      })
      .catch(() => {
        showError('Error while exporting');
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };
  return { isModalOpen, handleCloseModal, isExporting, doExport };
};

export default useExport;
