import moment from 'moment';

import { Typography } from '@mui/material';

type Props = {
  label: string;
  value: any;
};

export function DatesChip({ label, value }: Props) {
  const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');

  return (
    <div className="flex items-center">
      <Typography className="mr-4 text-11 font-400">
        {label} {value && ':'}
      </Typography>
      {value && <Typography className="text-11 font-700">{formatDate(value)}</Typography>}
    </div>
  );
}
