import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';

type Props = {
  when?: boolean;
  condition?: (nextLocation: Location) => boolean;
  onSave?: () => Promise<any>;
};

const PagePrompt = ({ when, condition, onSave }: Props) => {
  const dispatch = useDispatch();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    dispatch(closeDialog({}));
  };

  const handleOpenDialog = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Unsaved Changes"
            message="You are about to leave this page without saving. All changes will be lost. Do you want to save before leaving?"
            statusVariant="warning"
            confirmButtonLabel="Save"
            cancelButtonLabel="Leave without saving"
            onHide={closeModal}
            onClose={() => {
              handleConfirmNavigationClick();
              closeModal();
            }}
            onConfirm={() => {
              onSave?.().then(() => {
                handleConfirmNavigationClick();
              });
              closeModal();
            }}
          />
        ),
      }),
    );
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && condition?.(nextLocation)) {
      handleOpenDialog();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  return (
    <Prompt
      when={when}
      message={handleBlockedNavigation}
    />
  );
};

export default PagePrompt;
