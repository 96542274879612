import jwtDecode from 'jwt-decode';
import api from 'utils/api';

import FuseUtils from '@fuse/utils/FuseUtils';

class BluefitSSOService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    api.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      },
    );
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = data => {
    return new Promise((resolve, reject) => {
      api.post('/api/auth/register', data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      api
        .post('/api/login', {
          user: {
            email,
            password,
          },
        })
        .then(response => {
          const { authorization } = response.headers;
          if (response.data.email && authorization) {
            const token = authorization.split(' ')[1];
            this.setSession(token);
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        // eslint-disable-next-line
        .catch(error => reject('Login fail!'));
    });
  };

  signInWithEmailAndPasswordAndGetSetting = (email, password) => {
    const promises = [this.signInWithEmailAndPassword(email, password)];
    return Promise.all(promises).then(data =>
      data.reduce((userData, responseData) => ({ ...userData, ...responseData }), {}),
    );
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/api/me')
        .then(response => {
          const accessToken = this.getAccessToken();
          if (!response.data.email || !accessToken) {
            this.logout();
            reject(new Error('Failed to login with token.'));
          } else {
            this.setSession(accessToken);
            resolve(response.data);
          }
        })
        .catch(error => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  signInWithTokenAndGetSetting = () => {
    const promises = [this.signInWithToken()];
    return Promise.all(promises).then(data =>
      data.reduce((userData, responseData) => ({ ...userData, ...responseData }), {}),
    );
  };

  updateUserData = user => {
    return api.post('/api/auth/user/update', {
      user,
    });
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem('jwt_access_token', accessToken);
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete api.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = accessToken => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new BluefitSSOService();

export default instance;
