import React from 'react';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  id: number;
  status?: string;

  onView?: (id: number) => void;
  onDeactivate?: (id: number) => void;
  onActivate?: (id: number) => void;
  onDraft?: (id: number) => void;
}

const Action = ({ id, status, onView, onDeactivate, onActivate, onDraft }: Props) => {
  const isActive = status === 'active';
  const isDraft = status === 'draft';

  const handleView = (event: React.MouseEvent) => {
    event.stopPropagation();
    onView?.(id);
  };

  const handleDeactivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDeactivate?.(id);
  };

  const handleDraft = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDraft?.(id);
  };

  const handleActivate = (event: React.MouseEvent) => {
    event.stopPropagation();
    onActivate?.(id);
  };

  return (
    <div className="flex items-center justify-end">
      {isActive && (
        <>
          <Tooltip title="Pending">
            <IconButton onClick={handleDraft}>
              <PendingActionsIcon className="text-20" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Unpublish">
            <IconButton onClick={handleDeactivate}>
              <PowerSettingsNewIcon className="text-20" />
            </IconButton>
          </Tooltip>
        </>
      )}

      {isDraft && (
        <>
          <Tooltip title="Publish">
            <IconButton onClick={handleActivate}>
              <ControlPointIcon className="text-20" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Unpublish">
            <IconButton onClick={handleDeactivate}>
              <PowerSettingsNewIcon className="text-20" />
            </IconButton>
          </Tooltip>
        </>
      )}

      <Tooltip title="View">
        <IconButton onClick={handleView}>
          <VisibilityOutlinedIcon className="text-20" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Action;
