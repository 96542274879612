import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import IndexTable from 'app/components/cores/index-table';
import { useGetListStatisticsInDetails, useGetStatisticsDetailsByType } from 'app/hooks/api/location-performance';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from 'utils/date';

import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import RolesHeader from '../statistic-details-modal/roles-header';

const useStyles = makeStyles(() => ({
  pagination: {
    display: 'none !important',
  },
  distribution: {
    paddingRight: '12px !important',
  },
}));

type Props = {
  isGettingDetailData?: boolean;
  isOpenListInRole: boolean;
  columns?: any;
  totalDataCount?: number;
  selectedId?: number;
  locationId?: number;
  roleId?: number;
  formattedSelectedDate?: string;
  statsType: string;
  exportingTitle?: string;
  locationName?: string;
  from: string | null;
  to: string | null;
  isGroup?: boolean;
  parentData?: {
    roles: string;
    employee: string;
  };
};

function PerformanceExportingRoleDetailAction({
  isGettingDetailData,
  isOpenListInRole,
  columns,
  totalDataCount,
  selectedId,
  locationId,
  roleId,
  formattedSelectedDate,
  statsType,
  exportingTitle,
  locationName,
  from,
  to,
  isGroup,
  parentData,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const printRef = useRef<any>();
  const classes = useStyles();

  const customColumns = _.map(columns, (column: any) => {
    const nextColumns = { ...column };
    nextColumns.className = 'border-gray-100 border-t-1';
    if (nextColumns.Header === 'List') {
      nextColumns.width = '50%';
    }
    if (nextColumns.Header === 'Distribution') {
      nextColumns.className = clsx(nextColumns.className, classes.distribution);
      nextColumns.width = '35%';
    }
    return nextColumns;
  });

  const { data: statistics, isFetching: isGettingStatisticDetails } = useGetStatisticsDetailsByType(
    Number(locationId),
    {
      type: statsType,
      id: Number(selectedId),
      filter: {
        page: 1,
        per_page: totalDataCount,
        'q[date_eq]': formattedSelectedDate,
      },
    },
    {
      enabled: !!locationId && !!statsType && !!selectedId && !!totalDataCount,
    },
  );

  const { data: listInRoleDetails, isFetching: isGettingListInRoleDetails } = useGetListStatisticsInDetails(
    Number(locationId),
    {
      roleId,
      detailId: Number(selectedId),
      active: isOpenListInRole,
      type: statsType,
      filter: {
        page: 1,
        per_page: totalDataCount,
        'q[date_eq]': formattedSelectedDate,
      },
    },
    {
      enabled: !!locationId && !!roleId && !!selectedId && isOpenListInRole && !!totalDataCount,
    },
  );

  const data = isOpenListInRole ? listInRoleDetails?.records || [] : statistics?.records || [];
  const isLoading = isGettingDetailData || isGettingStatisticDetails || isGettingListInRoleDetails;

  const generatePeriodString = (fromString: string, toString: string) => {
    const fromDate = formatDate(moment(fromString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
    if (fromString === toString) {
      return fromDate;
    }
    const toDate = formatDate(moment(toString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
    return `${fromDate} - ${toDate}`;
  };

  const getExportingDocumentTitle = () => {
    return `${locationName} Performance Report - ${exportingTitle}`;
  };

  const handlePrint = useReactToPrint({
    content: () => (printRef as any).current,
    documentTitle: getExportingDocumentTitle(),
  });

  const handleExportPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    handlePrint();
  };

  const periodString = generatePeriodString(from || '', to || '');

  return (
    <>
      <IconButton
        color="primary"
        className="h-40 ml-8"
        onClick={handleExportPDF}
        disabled={isLoading}
      >
        {isLoading ? <SyncIcon className="animate-spin" /> : <ExportPDFIcon />}
      </IconButton>

      <div
        ref={printRef}
        className="hidden print:block"
      >
        <div className="flex justify-between mb-24">
          <div className="flex flex-col items-start flex-1 pr-24">
            <Typography className="capitalize text-18 font-500 text-secondaryMain">{exportingTitle}</Typography>
            <Typography className="text-14 font-400 text-secondaryLight">{periodString}</Typography>

            {parentData?.roles && (
              <div className="flex items-center mt-12">
                <RolesHeader roles={parentData?.roles} />
              </div>
            )}

            {parentData?.employee && (
              <div className="flex items-center mt-12">
                <RolesHeader roles={parentData?.employee} />
              </div>
            )}

            {isGroup && (
              <IconButton
                className="bg-paper"
                size="small"
              >
                <GroupsOutlined className="text-16" />
              </IconButton>
            )}
          </div>

          <div className="flex flex-col items-end">
            <Typography className="text-15 font-500">{locationName}</Typography>
          </div>
        </div>

        <IndexTable
          columns={customColumns}
          data={data}
          pageCount={0}
          totalDataCount={0}
          searchKey=""
          loading={!!isLoading}
          location={location}
          history={history}
          searchText=""
          filters={[]}
          setFilters={() => null}
          onFetchData={() => null}
          PaginationProps={{
            classes: {
              root: classes?.pagination,
            },
          }}
        />
      </div>
    </>
  );
}

export default PerformanceExportingRoleDetailAction;
