import { ASSIGNMENT_FIELDS_MAP, NOTIFICATION_FIELDS } from 'constants/index';
import { updateRecurringDTStart } from 'domains/frequency.domain';
import _ from 'lodash';
import { TAssignment, TAssignmentCustomizeSetting } from 'models';

export const getFieldName = (fieldName: TAssignmentCustomizeSetting) => {
  return _.camelCase(fieldName) as keyof TAssignment;
};

export const getVariationCustomizeField = (
  fieldName: TAssignmentCustomizeSetting,
  assignmentAttributes: Partial<TAssignment>,
) => {
  const fieldNameCamel = getFieldName(fieldName);
  const fieldValue = assignmentAttributes?.[fieldNameCamel];

  if (fieldName === ASSIGNMENT_FIELDS_MAP.FREQUENCIES) {
    const nextFieldValue = updateRecurringDTStart({
      frequencies: fieldValue,
      startDate: assignmentAttributes?.startDate,
    } as TAssignment);
    return {
      field: getFieldName(fieldName),
      value: nextFieldValue?.frequencies || [],
    };
  }

  return {
    field: getFieldName(fieldName),
    value: fieldValue,
  };
};

export const generateVariationPayload = (
  variation: Partial<TAssignment>,
  variations: TAssignment[],
  globalAssignmentAttributes: TAssignment,
) => {
  const variationsPayload: any = [];
  const { locationIds } = variation || {};

  _.forEach(locationIds, locationId => {
    const selectedVariation = _.find(variations, { location: { id: locationId } }) || ({} as TAssignment);
    const isCreatedVariation = !!selectedVariation?.id;

    if (isCreatedVariation) {
      const nextCustomisedSettings = _.union(selectedVariation.customisedSettings, variation.customisedSettings);
      const variationPayload = {
        customisedSettings: nextCustomisedSettings,
        locationId,
        isCreatedVariation,
      } as any;

      _.forEach(variation.customisedSettings, fieldName => {
        const { field, value } = getVariationCustomizeField(fieldName, variation);
        variationPayload[field] = value;
      });

      // update recurring rule that depends on start date
      const startDate = variationPayload?.startDate || selectedVariation?.startDate;
      const frequencies = variationPayload?.frequencies || selectedVariation?.frequencies;
      const updatedRecurringRule = updateRecurringDTStart({ frequencies, startDate } as TAssignment);

      variationsPayload.push({ ...variationPayload, ...updatedRecurringRule });
    } else {
      const extendVariation = _.omit(globalAssignmentAttributes, ['id', 'location']);
      let nextVariation = _.cloneDeep(variation) as TAssignment;

      // append dtstart to recurring rule
      if (nextVariation?.frequencies?.length) {
        if (nextVariation?.startDate) {
          nextVariation = updateRecurringDTStart(nextVariation);
        } else {
          nextVariation = updateRecurringDTStart({
            ...nextVariation,
            startDate: globalAssignmentAttributes?.startDate,
          });
        }
      }

      // set default org roles
      if (_.isEmpty(nextVariation?.assignmentOrgRolesAttributes)) {
        nextVariation.assignmentOrgRolesAttributes =
          _.map(globalAssignmentAttributes?.assignmentOrgRolesAttributes, item => _.omit(item, 'id')) || [];
      }

      variationsPayload.push({
        ...extendVariation,
        ...nextVariation,
        locationId,
        isCreatedVariation,
      });
    }
  });

  return variationsPayload;
};

export const getVariation = (variations: TAssignment[], variationId: number) => {
  const clonedVariations = _.cloneDeep(variations);
  const selectedVariation = _.find(clonedVariations, { id: variationId });
  if (!selectedVariation) return {} as TAssignment;

  return {
    ...selectedVariation,
    locationId: selectedVariation?.location?.id,
  };
};

export const getVariationOldVersion = (variations: TAssignment[], variationId: number) => {
  const clonedVariations = _.cloneDeep(variations);
  const selectedVariation = _.find(clonedVariations, { id: variationId });

  if (!selectedVariation) return {};

  const { id, location, customisedSettings = [] } = selectedVariation;
  const locationId = location?.id;

  const variationPayload = {
    id,
    location,
    locationId,
    customisedSettings,
  } as any;

  _.forEach(customisedSettings, (fieldName: TAssignmentCustomizeSetting) => {
    const { field, value } = getVariationCustomizeField(fieldName, selectedVariation);
    variationPayload[field] = value;
  });

  return variationPayload;
};

export const deleteVariationCustomizeField = (
  variations: TAssignment[],
  globalAssignmentAttributes: TAssignment,
  variationId: number,
  fieldName: TAssignmentCustomizeSetting,
) => {
  const clonedVariations = _.cloneDeep(variations);
  const selectedVariation = _.find(clonedVariations, { id: variationId });
  if (!selectedVariation) return {};

  const { id, location, customisedSettings = [] } = selectedVariation;
  const locationId = location?.id;

  // remove field name from `customisedSettings`
  const isNotificationField = NOTIFICATION_FIELDS?.includes(fieldName);
  if (isNotificationField) {
    _.forEach(NOTIFICATION_FIELDS, (field: string) => {
      _.pull(customisedSettings, field);
    });
  } else {
    _.pull(customisedSettings, fieldName);
    if (fieldName === ASSIGNMENT_FIELDS_MAP.ENABLE_ON_CLOSED_DAY) {
      _.pull(customisedSettings, 'closed_day_display_times');
    }
  }

  if (_.isEmpty(customisedSettings)) {
    return { isEmptyCustomisedSettings: true };
  }

  const variationPayload = {
    id,
    locationId,
    customisedSettings,
  } as any;

  // get data of that field name from `globalAssignmentAttributes`
  // update assignment payload
  if (isNotificationField) {
    _.forEach(NOTIFICATION_FIELDS, (mappingFieldName: string) => {
      const { field, value } = getVariationCustomizeField(
        mappingFieldName as TAssignmentCustomizeSetting,
        globalAssignmentAttributes as TAssignment,
      );
      variationPayload[field] = value;
    });
  } else {
    const { field, value } = getVariationCustomizeField(fieldName, globalAssignmentAttributes as TAssignment);
    variationPayload[field] = value;
  }

  return variationPayload;
};

export const updateVariation = (
  variation: Partial<TAssignment>,
  variations: TAssignment[],
  globalAssignmentAttributes: TAssignment,
) => {
  if (!variation?.customisedSettings?.length) {
    return { isEmptyCustomisedSettings: true };
  }

  // get previous assignment (find item by locationId)
  const { id, locationId, customisedSettings } = variation || {};
  const variationPayload: any = {
    id,
    locationId,
    customisedSettings,
  };

  const clonedVariations = _.cloneDeep(variations);
  const selectedVariation = _.find(clonedVariations, { location: { id: locationId } });
  if (!selectedVariation) return {};

  // compare customisedSettings attribute to get difference
  const isEqualCustomizeFields = _.isEqual(selectedVariation?.customisedSettings, variation?.customisedSettings);
  if (isEqualCustomizeFields) {
    _.forEach(variation?.customisedSettings, fieldName => {
      const { field, value } = getVariationCustomizeField(fieldName, selectedVariation);
      const { value: updatedValue } = getVariationCustomizeField(fieldName, variation);

      if (!_.isEqual(updatedValue, value)) {
        variationPayload[field] = updatedValue;
      }
    });

    return variationPayload;
  }

  const removedCustomizeFields = _.difference(
    selectedVariation?.customisedSettings,
    variation?.customisedSettings || [],
  );
  const addedCustomizeFields = _.difference(variation?.customisedSettings, selectedVariation?.customisedSettings || []);

  //
  if (removedCustomizeFields?.length > 0) {
    _.forEach(removedCustomizeFields, fieldName => {
      const { field, value } = getVariationCustomizeField(fieldName, globalAssignmentAttributes);
      variationPayload[field] = value;
    });
  }

  //
  if (addedCustomizeFields?.length > 0) {
    _.forEach(addedCustomizeFields, fieldName => {
      const { field, value } = getVariationCustomizeField(fieldName, variation);
      variationPayload[field] = value;
    });
  }

  // update recurring rule that depends on start date
  const startDate = variationPayload?.startDate || variation?.startDate;
  const frequencies = variationPayload?.frequencies || variation?.frequencies;
  const updatedRecurringRule = updateRecurringDTStart({ frequencies, startDate } as TAssignment);

  return { ...variationPayload, ...updatedRecurringRule };
};
