import clsx from 'clsx';
import {
  Draggable,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import { Theme, useTheme } from '@mui/material/styles';

const getItemStyle = (theme: Theme, isDragging: boolean, draggableStyle: any) => ({
  background: isDragging ? theme.palette.background.paper : theme.palette.background.default,
  userSelect: 'none',
  margin: '0 0 8px 0',
  ...draggableStyle,
});

type Props = {
  id: string;
  index: number;
  isDragDisabled?: boolean;
  disableInteractiveElementBlocking?: boolean;
  styleContainer?: string;
  children?: React.ReactNode;
  renderContent?: (dragHandleProps?: DraggableProvidedDragHandleProps | null) => React.ReactNode;
};
function DragItemWrapper({
  id,
  index,
  children,
  isDragDisabled,
  disableInteractiveElementBlocking,
  styleContainer,
  renderContent,
}: Props) {
  const theme = useTheme();
  const isRenderContent = !!renderContent;

  const getDragHandleProps = (dragHandleProps?: DraggableProvidedDragHandleProps | null) =>
    isRenderContent ? {} : dragHandleProps;

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
      disableInteractiveElementBlocking={disableInteractiveElementBlocking}
    >
      {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...getDragHandleProps(draggableProvided.dragHandleProps)}
          style={getItemStyle(theme, draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
          className={clsx('rounded-lg', styleContainer)}
        >
          {isRenderContent ? renderContent?.(draggableProvided.dragHandleProps) : children}
        </div>
      )}
    </Draggable>
  );
}

export default DragItemWrapper;
