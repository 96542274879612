import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { convertErrorMessages } from 'utils/errors';

import { SnackbarProps } from '@mui/material';

const useShowMessage = () => {
  const dispatch = useDispatch();

  const showSuccess = (message: string) => {
    dispatch(showMessage({ message, variant: 'success', text1: 1 }));
  };

  const showError = (message: string, errors?: Error[], isShowVariantIcon = true, options?: Partial<SnackbarProps>) => {
    const flatMapErrors = convertErrorMessages(errors || []);

    dispatch(
      showMessage({
        message,
        variant: 'error',
        errors: flatMapErrors,
        ...options,
        isShowVariantIcon,
      }),
    );
  };

  return { showSuccess, showError };
};

export default useShowMessage;
