import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';

import { Button, Typography } from '@mui/material';

type Props = {
  isShowEditor?: boolean;
  hasGlobalSetting?: boolean;
  hasLocation?: boolean;
  onAdd?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
};

function TaskVariationActions({ isShowEditor, hasGlobalSetting, hasLocation, onAdd, onCancel, onSubmit }: Props) {
  const dispatch = useDispatch();
  const canAddVariation = hasGlobalSetting && hasLocation;

  const handleAddVariation = () => {
    if (canAddVariation) {
      onAdd?.();
      return;
    }

    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Add Variation Confirmation"
            message='To generate variations with customized settings that differ from the global configurations, please add location to the list in the "Locations" tab'
            statusVariant="warning"
            confirmButtonLabel="Ok"
            hideCancelButton={true}
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };

  return (
    <div className="flex items-start justify-end pb-16 border-b-1">
      <Typography className="flex-1 pr-16 text-13 font-400">
        To generate variations with customized settings that differ from the global configurations, click Add Variation
        button, then select the desired parameter and the locations for variant creation
      </Typography>
      {!isShowEditor ? (
        <div className="flex justify-end w-128 lg:w-360">
          <Button
            disableElevation={true}
            variant="contained"
            color="inherit"
            className="uppercase text-11 font-600 bg-warning text-secondaryMain rounded-16"
            onClick={handleAddVariation}
          >
            Add Variation
          </Button>
        </div>
      ) : (
        <div className="flex items-center justify-end space-x-8 w-360">
          <Button
            disableElevation={true}
            variant="contained"
            color="inherit"
            className="uppercase text-11 font-600 bg-paper text-secondaryMain rounded-16"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disableElevation={true}
            variant="contained"
            color="inherit"
            className="uppercase text-11 font-600 bg-warning text-secondaryMain rounded-16"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
}

export default TaskVariationActions;
