import { TItem } from 'models';

import { Typography } from '@mui/material';

import { MultipleLogicSelect } from './multiple-logic-select';

type Props = {
  indicateText: string;
  selectedConditionalItem: TItem;
  defaultCondition?: any;
  onChange?: (item: any) => void;
};

export function MultipleConditionSelect({ selectedConditionalItem, defaultCondition, onChange, indicateText }: Props) {
  return (
    <>
      <div className="flex items-center">
        <Typography className="mr-8 text-13 font-400">{indicateText}</Typography>
        <MultipleLogicSelect
          selectedConditionalItem={selectedConditionalItem}
          value={defaultCondition || null}
          placeholder="Select conditional"
          menuPlacement="auto"
          onChange={onChange}
        />
      </div>
    </>
  );
}
