import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import { useGetAuditPerformanceStats } from 'app/hooks/api/audit-performance';
import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';

import SyncIcon from '@mui/icons-material/Sync';
import { IconButton } from '@mui/material';

import ExportingAuditModal from './exporting-audit-modal';

type Props = {
  isGettingStatistic?: boolean;
  locationId: number;
  statsType: string;
  from: null | string;
  to: null | string;
  filterTask?: string | null;
  locationName?: string;
};

function ExportingAuditAction({
  isGettingStatistic,
  locationId,
  statsType,
  from,
  to,
  filterTask,
  locationName,
}: Props) {
  const history = useHistory();
  const location = useLocation();
  const printRef = useRef();

  const { data: statistics, isFetching: isGettingExportingData } = useGetAuditPerformanceStats(
    locationId,
    statsType as 'list' | 'auditor',
    {
      page: 1,
      per_page: 9999,
      'q[from_date]': from ?? '',
      'q[to_date]': to ?? '',
    },
    {
      enabled: !!statsType,
    },
  );

  const data = statistics?.records || [];
  const isLoading = isGettingStatistic || isGettingExportingData;
  const exportingTitle = `${statsType === 'list' ? 'List' : 'Auditor'} Statistics`;

  const getExportingDocumentTitle = () => {
    return `${locationName} Performance Report - ${exportingTitle}`;
  };

  const handlePrint = useReactToPrint({
    content: () => (printRef as any).current,
    documentTitle: getExportingDocumentTitle(),
  });

  const handleExportPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    handlePrint();
  };

  return (
    <>
      <IconButton
        color="primary"
        className="h-40 ml-8"
        onClick={handleExportPDF}
        disabled={isLoading}
      >
        {isLoading ? <SyncIcon className="animate-spin" /> : <ExportPDFIcon />}
      </IconButton>

      {!isLoading && (
        <ExportingAuditModal
          ref={printRef}
          data={data}
          statsType={statsType}
          from={from}
          to={to}
          filterTask={filterTask}
          location={location}
          history={history}
          locationName={locationName}
          exportingTitle={exportingTitle}
        />
      )}
    </>
  );
}

export default ExportingAuditAction;
