import Switch from 'app/components/cores/switch';
import { Control, useController } from 'react-hook-form';

import { Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = {
  name: string;
  control: Control<any>;
  onChange?: (isChecked: boolean) => void;
};

function DisplayUntilSetting({ name, control, onChange }: Props) {
  const {
    field: { value, onChange: onSettingChange },
  } = useController({
    name,
    control,
  });

  const handleDisplayUntilChange = (__: any, checked: boolean) => {
    onSettingChange(checked);
    onChange?.(checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={!!value}
          onChange={handleDisplayUntilChange}
        />
      }
      label={<Typography className="pr-4 text-small-dark">Use Until Setting</Typography>}
      labelPlacement="start"
      className="ml-0"
    />
  );
}

export default DisplayUntilSetting;
