import {
  ReporTableCommonCell,
  ReportTableDateTimeCell,
  ReportTableMediaCell,
  ReportTableSignatureCell,
} from 'app/components/reports/table/items';
import { ITEM_TYPES_MAP } from 'constants/index';

import { Typography } from '@mui/material';

type Props = {
  type: string;
  response: any;
};

function ReportTableAnswerCell({ type, response }: Props) {
  const isUpperCaseAnswer = [ITEM_TYPES_MAP.YES_NO, ITEM_TYPES_MAP.CHECK_MARK].includes(type as any);

  const generateItems = (itemType: string) => {
    const mapping = new Map();

    mapping.set('Items::Numeric', ReporTableCommonCell);
    mapping.set('Items::Rating', ReporTableCommonCell);
    mapping.set('Items::Text', ReporTableCommonCell);
    mapping.set('Items::YesNo', ReporTableCommonCell);
    mapping.set('Items::CheckMark', ReporTableCommonCell);
    mapping.set('Items::MultipleChoice', ReporTableCommonCell);
    mapping.set('Items::Area', ReporTableCommonCell);
    mapping.set('Items::Employee', ReporTableCommonCell);
    mapping.set('Items::SaltOpsForm', ReporTableCommonCell);
    mapping.set('Items::BarCode', ReporTableCommonCell);
    mapping.set('Items::QrCode', ReporTableCommonCell);
    mapping.set('Items::Media', ReportTableMediaCell);
    mapping.set('Items::DateTime', ReportTableDateTimeCell);
    mapping.set('Items::Signature', ReportTableSignatureCell);

    return mapping.get(itemType) || null;
  };

  if (!response) {
    return (
      <div className="px-16">
        <Typography className="text-13 font-400">-</Typography>
      </div>
    );
  }

  const Item = generateItems(type);

  return (
    <div className="px-16 text-center w-136">
      <Item
        responseId={response?.id}
        answer={response?.answer}
        comment={response?.comment}
        isUpperCaseAnswer={isUpperCaseAnswer}
      />
    </div>
  );
}

export default ReportTableAnswerCell;
