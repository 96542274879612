import AuditActionItem from 'app/components/cores/audit/audit-action-item';
import { TResponse } from 'models';

import { Typography } from '@mui/material';

type Props = {
  auditActions: TResponse['auditActions'];
  listId: number;
  canAuditInteractions: boolean;
};

const AuditActionList = ({ auditActions, listId, canAuditInteractions }: Props) => {
  if (!auditActions || !auditActions.length) {
    return null;
  }

  return (
    <>
      <Typography className="text-13 font-600 text-primaryMain"> To-dos </Typography>
      <div className="mt-12 space-y-8">
        {auditActions.map(auditAction => (
          <AuditActionItem
            key={auditAction.id}
            auditAction={auditAction}
            listId={listId}
            canAuditInteractions={canAuditInteractions}
          />
        ))}
      </div>
    </>
  );
};

export default AuditActionList;
