/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table/index-table';
import { useGetPublicHolidaysByLocation } from 'app/hooks/api/locations';
import _ from 'lodash';
import { THoliday } from 'models/holiday.model';
import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Cell, Row } from 'react-table';
import { convertDateStringToStringV2, getDateStringFromDayMonth } from 'utils/date';
import { getFilterFromURL } from 'utils/url';

import HolidayDate from './holiday-date';

const Holidays = () => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = React.useState(getFilterFromURL(location?.search));
  const { locationId } = useParams<{ locationId: string }>();

  const { data, isLoading } = useGetPublicHolidaysByLocation(Number(locationId));
  const holidays = data?.records;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Holidays',
        accessor: 'name',
        className: 'text-13 font-600 relative',
        width: '60%',
        sortable: true,
      },
      {
        Header: 'Date',
        id: 'date',
        sortable: false,
        width: '40%',
        className: 'pr-0 w-full',
        Cell: ({ row, cell }: { row: Row<THoliday>; cell: Cell<THoliday> }) => {
          const { day, month, year, openingTimes, closedOnThisHoliday } = row.original;

          return (
            <HolidayDate
              date={
                cell.value
                  ? convertDateStringToStringV2(cell.value)
                  : getDateStringFromDayMonth(day, month, Number(year))
              }
              openingTimes={openingTimes}
              closedOnThisHoliday={closedOnThisHoliday}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  );

  const filteredLocations = useMemo(
    () =>
      holidays?.filter((holiday: any) => {
        if (filters.length === 0) {
          return true;
        }
        const { name, state } = holiday;

        let matchName = true;
        let matchState = true;
        filters.forEach((filter: any) => {
          if (filter.id === 'name' && !(_.includes(name, filter.value) || filter.value === '_all')) {
            matchName = false;
            return;
          }
          if (filter.id === 'state' && !(_.includes(state, filter.value) || filter.value === '_all')) {
            matchState = false;
          }
        });
        return matchName && matchState;
      }),
    [holidays, filters],
  );

  return (
    <div className="flex flex-col w-full p-12">
      <IndexTable
        data={filteredLocations || []}
        columns={columns as any}
        pageCount={0}
        totalDataCount={0}
        loading={isLoading}
        location={location}
        history={history}
        onFetchData={() => {}}
        searchText=""
        filters={filters}
        setFilters={setFilters}
        isShowPagination={false}
      />
    </div>
  );
};

export default Holidays;
