import { CheckMark } from './responses';

type Props = {
  name: string;
  response: any; // TODO update type
  list?: any;
  readOnly?: boolean;
};

function TodoResponse({ name, list, response, readOnly }: Props) {
  const listStatus = list?.status;
  const listId = response?.todoList?.id;
  const responseId = response?.id;
  const displayable = response?.displayable;

  const getResponseComponent = (type: string) => {
    const mapping = new Map();
    mapping.set('Responses::CheckMark', CheckMark);
    return mapping.get(type);
  };

  const ResponseComponent = getResponseComponent(response?.type);

  if (!ResponseComponent) return null;

  return (
    <ResponseComponent
      name={name}
      listId={listId}
      listStatus={listStatus}
      responseId={responseId}
      response={response}
      readOnly={readOnly}
      displayable={displayable}
    />
  );
}

export default TodoResponse;
