import { ElementRef, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import { Typography } from '@mui/material';

type Props = {
  answer: any;
};

function ReportTableSignatureCell({ answer }: Props) {
  const signatureRef = useRef<ElementRef<typeof SignatureCanvas>>(null);

  useEffect(() => {
    signatureRef.current?.off();
  }, []);

  useEffect(() => {
    if (!answer || !signatureRef.current) return;
    signatureRef.current?.fromDataURL(answer);
  }, [answer]);

  if (!answer) return <Typography className="text-13 font-400">-</Typography>;
  return (
    <div className="h-96">
      <SignatureCanvas
        ref={signatureRef}
        canvasProps={{ className: 'h-full w-full' }}
      />
    </div>
  );
}

export default ReportTableSignatureCell;
