import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Timer10OutlinedIcon from '@mui/icons-material/Timer10Outlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

function QuestionIconType(type: string) {
  const icons = {
    'Items::YesNo': ToggleOffOutlinedIcon,
    'Items::Media': VideocamOutlinedIcon,
    'Items::MultipleChoice': QuestionAnswerOutlinedIcon,
    'Items::CheckMark': CheckOutlinedIcon,
    'Items::Numeric': Timer10OutlinedIcon,
    'Items::Area': PlaceOutlinedIcon,
    'Items::QrCode': QrCode2OutlinedIcon,
    'Items::BarCode': SubtitlesOutlinedIcon,
    'Items::Rating': StarOutlineOutlinedIcon,
    'Items::DateTime': CalendarMonthOutlinedIcon,
    'Items::Employee': AssignmentIndOutlinedIcon,
    'Items::Text': TextSnippetOutlinedIcon,
    'Items::SaltOpsForm': ListAltOutlinedIcon,
    'Items::Signature': DrawOutlinedIcon,
  } as any;

  return icons[type];
}

export default QuestionIconType;
