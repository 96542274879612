import api from 'utils/api';

import { useMutation, useQuery } from '@tanstack/react-query';

type UploadProgressType = (progressEvent: any) => void;

export async function uploadMedia(params: { file: File; onUploadProgress?: UploadProgressType }, isPublic?: boolean) {
  const { file, onUploadProgress } = params || {};
  const url = isPublic ? '/api/media/public' : '/api/media';
  const data = new FormData();
  data.append('media[files][]', file);

  const response = await api.post(url, data, {
    headers: { 'Content-Type': `multipart/form-data` },
    onUploadProgress,
  });
  return response.data;
}

export async function getMediaEncoded(encode: string) {
  const response = await api.get(`/api/media/${encode}/show_encoded`);
  return response?.data;
}

export function useUploadMedia({ isPublic }: { isPublic: boolean }) {
  return useMutation((params: { file: File; onUploadProgress?: UploadProgressType }) => uploadMedia(params, isPublic));
}

export function useGetMediaEncoded(encode: string) {
  return useQuery(['getMediaEncoded', encode], () => getMediaEncoded(encode), {
    enabled: !!encode,
  });
}

export default {};
