import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyRosterIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.21053 6.36842V3M13.9474 6.36842V3M8.68421 18.1579H4.68421C4.23753 18.1579 3.80914 17.9805 3.49329 17.6646C3.17744 17.3488 3 16.9204 3 16.4737V6.36842C3 5.92174 3.17744 5.49336 3.49329 5.1775C3.80914 4.86165 4.23753 4.68421 4.68421 4.68421H16.4737C16.9204 4.68421 17.3488 4.86165 17.6646 5.1775C17.9805 5.49336 18.1579 5.92174 18.1579 6.36842V8.68421"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.2632 14.1579V16.2631L17.8422 17.8421M21.0001 16.2631C21.0001 16.8852 20.8775 17.5012 20.6395 18.0759C20.4014 18.6506 20.0525 19.1727 19.6127 19.6126C19.1728 20.0525 18.6506 20.4014 18.0759 20.6394C17.5012 20.8775 16.8853 21 16.2632 21C15.6412 21 15.0252 20.8775 14.4505 20.6394C13.8758 20.4014 13.3536 20.0525 12.9138 19.6126C12.4739 19.1727 12.125 18.6506 11.8869 18.0759C11.6489 17.5012 11.5264 16.8852 11.5264 16.2631C11.5264 15.0069 12.0254 13.802 12.9138 12.9137C13.8021 12.0254 15.0069 11.5263 16.2632 11.5263C17.5195 11.5263 18.7243 12.0254 19.6127 12.9137C20.501 13.802 21.0001 15.0069 21.0001 16.2631Z"
        stroke="currentColor"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export default React.memo(MyRosterIcon);
