import clsx from 'clsx';

import { Typography } from '@mui/material';

type Props = {
  headerGroups: any;
  rows: any;
  prepareRow?: any;
};

function ReportTable({ headerGroups, rows, prepareRow }: Props) {
  return (
    <table className="min-w-full border-collapse table-fixed">
      <thead className="relative z-10">
        {headerGroups?.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((header: any) => (
              <th
                {...header.getHeaderProps()}
                className={clsx('border-gray-300 border-1 py-8', header.classes?.header)}
              >
                <div className="flex items-center justify-start px-16 min-h-28">
                  <Typography
                    data-testid="report-location"
                    className={clsx('text-13 text-secondaryMain font-600', header.classes?.title)}
                  >
                    {header.render('Header')}
                  </Typography>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows?.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="py-8 border-gray-300 border-1"
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ReportTable;
