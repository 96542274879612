import { TOpeningTime } from 'models';

export const getTimePeriod = (item: TOpeningTime) => {
  if (!item?.timePeriod) {
    return 'Whole year';
  }

  const { startDay, startMonth, endDay, endMonth } = item;

  if (startDay && startMonth && endDay && endMonth) {
    if (startDay === endDay && startMonth === endMonth) return `${startDay}/${startMonth}`;

    return `${startDay}/${startMonth} - ${endDay}/${endMonth}`;
  }

  if (startDay && startMonth) {
    return `From ${startDay}/${startMonth}`;
  }

  if (endDay && endMonth) {
    return `To ${endDay}/${endMonth}`;
  }

  return '';
};
