import { TIMEZONE_SELECTOR_TYPES, TTimezoneSelectorType } from 'models';
import { getBrowserTimezone } from 'utils/date';

export const getTimezoneBySelector = (
  tzSelector: TTimezoneSelectorType = TIMEZONE_SELECTOR_TYPES.COMPUTER,
  timezone?: string,
): string => {
  const computerTimezone = getBrowserTimezone();

  if (!timezone) {
    if (tzSelector === TIMEZONE_SELECTOR_TYPES.COMPUTER) return computerTimezone;
    return 'Australia/Melbourne';
  }

  return tzSelector === TIMEZONE_SELECTOR_TYPES.COMPUTER ? computerTimezone : timezone;
};
