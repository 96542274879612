import { TService } from 'models';

import { useGetList } from './request';

export const serviceKeys = {
  all: ['services'] as const,
  lists: () => [...serviceKeys.all, 'list'] as const,
  list: (filters: any) => [...serviceKeys.lists(), { filters }] as const,
  details: () => [...serviceKeys.all, 'detail'] as const,
  detail: (id: number) => [...serviceKeys.details(), id] as const,
};

export function useFetchServices() {
  return useGetList<{ total: number; services: TService[] }>(
    [...serviceKeys.lists()],
    '/api/services',
    {},
    {
      // never refetch data
      staleTime: Infinity,
    },
  );
}
