import clsx from 'clsx';
import moment from 'moment';
import { forwardRef } from 'react';
import { formatDate } from 'utils/date';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import StatisticTable from './audit-table-elements/table';

const useStyles = makeStyles(() => ({
  pagination: {
    display: 'none !important',
  },
  distribution: {
    paddingRight: '12px !important',
  },
}));

type Props = {
  data?: any;
  statsType: string;
  from: null | string;
  to: null | string;
  filterTask?: string | null;
  location?: any;
  history?: any;
  locationName?: string;
  exportingTitle?: string;
};

const ExportingAuditModal = forwardRef(
  (
    { data, statsType, from, to, filterTask, location, history, locationName, exportingTitle }: Props,
    printRef: any,
  ) => {
    const classes = useStyles();

    const generatePeriodString = (fromString: string, toString: string) => {
      const fromDate = formatDate(moment(fromString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
      if (fromString === toString) {
        return fromDate;
      }
      const toDate = formatDate(moment(toString, 'DD/MM/YYYY').toDate(), 'DD MMM YYYY');
      return `${fromDate} - ${toDate}`;
    };

    const periodString = generatePeriodString(from || '', to || '');

    if (!statsType) return null;

    return (
      <div
        className="hidden pl-12 print:block"
        ref={printRef as any}
      >
        <div className="flex justify-between pr-24 mb-24">
          <div className="flex flex-col items-start">
            <Typography className={clsx('text-18 font-500 text-secondaryMain capitalize')}>{exportingTitle}</Typography>
            <Typography className={clsx('text-14 font-400 text-secondaryLight')}>{periodString}</Typography>
          </div>

          <div className="flex flex-col items-end">
            <Typography className="text-15 font-500">{locationName}</Typography>
          </div>
        </div>

        <StatisticTable
          filterTask={filterTask}
          statsType={statsType}
          data={data as any}
          location={location}
          history={history}
          searchText=""
          filters={[]}
          setFilters={() => null}
          onFetchData={() => null}
          classes={{
            pagination: classes.pagination,
            distribution: classes.distribution,
          }}
          tableConfig={{
            total: {
              width: '10%',
            },
            completionRate: {
              width: '15%',
              hideHelpText: true,
            },
            distribution: {
              circularProgressSize: 40,
            },
          }}
        />
      </div>
    );
  },
);

export default ExportingAuditModal;
