import _ from 'lodash';

type CleanedObject = { [key: string]: any } | any[] | undefined;

export function removeDeepUndefined(obj: any): CleanedObject {
  // Check if the input is an array
  if (Array.isArray(obj)) {
    return obj
      .map(removeDeepUndefined) // Recursively clean each item
      .filter(item => !_.isUndefined(item) && !(_.isObject(item) && _.isEmpty(item))); // Filter out undefined and empty objects
  }

  // If the input is an object
  if (_.isObject(obj) && obj !== null) {
    return _.transform(obj, (result: { [key: string]: any }, value: any, key: string) => {
      const cleanedValue = removeDeepUndefined(value);
      if (
        !_.isUndefined(cleanedValue) ||
        (Array.isArray(cleanedValue) && (cleanedValue as any)?.length > 0) ||
        (_.isObject(cleanedValue) && !_.isEmpty(cleanedValue))
      ) {
        result[key] = cleanedValue; // Add the cleaned value to the result if it's defined
      }
    });
  }

  return obj; // Return the value if it's neither an object nor an array
}
