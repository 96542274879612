import { SingleSelect } from 'app/components/cores/select';
import { useGetLocations } from 'app/hooks/api/locations';
import useSelect from 'app/hooks/use-select';
import useToggle from 'app/hooks/use-toggle';
import _ from 'lodash';
import { TUserLocation } from 'models';
import { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import OnDemandModal from './on-demand-modal';

type Props = {
  title: string;
  location: TUserLocation;
  canAddOnDemand?: boolean;
  onSelectLocation?: (nextLocation: TUserLocation) => void;
};

function AdminTaskHeader({ title, location, canAddOnDemand = true, onSelectLocation }: Props) {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(200);
  const { data, isLoading } = useGetLocations({
    page: pageIndex,
    per_page: pageSize,
    'q[name_cont]': searchText,
  });

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading,
    pageIndex,
    pageSize,
    setPageIndex,
    setSearchText,
  });

  const locationOptions = useMemo(() => _.orderBy(options, 'name'), [options]);
  const { state: isOpenOnDemandModal, setActive: openOnDemandModal, setInactive: closeOnDemandModal } = useToggle();

  // effect to set first location by default
  useEffect(() => {
    if (_.isEmpty(locationOptions) || !!location?.externalId) return;
    const [firstLocation] = locationOptions;
    onSelectLocation?.(firstLocation);
  }, [locationOptions, location, onSelectLocation]);

  const handleChange = (item: any) => {
    onSelectLocation?.(item);
  };

  return (
    <div className="flex items-center justify-between h-64 px-16 bg-white shadow-md md:px-24">
      {isOpenOnDemandModal && (
        <OnDemandModal
          location={location}
          isOpen={isOpenOnDemandModal}
          onClose={closeOnDemandModal}
        />
      )}

      <Typography
        variant="h6"
        className="uppercase text-18 text-secondaryMain"
      >
        {title}
      </Typography>
      <div className="flex items-center justify-end gap-16">
        {canAddOnDemand && (
          <Button
            type="button"
            className="px-12 capitalize text-secondaryMain text-13 rounded-8 font-500"
            endIcon={<AddIcon className="text-16 text-secondaryMain" />}
            sx={{
              bgcolor: '#F5D02F',
              ':hover': {
                bgcolor: '#F5D02F',
              },
            }}
            onClick={openOnDemandModal}
          >
            On-Demand
          </Button>
        )}

        <div className="md:w-232 w-192">
          <SingleSelect
            value={location}
            options={locationOptions}
            maxMenuHeight={1000}
            menuPlacement="auto"
            noOptionsMessage={() => 'No locations'}
            placeholder="Select a location"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.externalId}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                border: 0,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 8,
                height: 40,
                minHeight: 40,
                boxShadow: 'none',
                cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
                pointerEvents: 'auto',
              }),
            }}
            onInputChange={handleSearch}
            onChange={handleChange}
            onMenuScrollToBottom={handleLoadMore}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminTaskHeader;
