import ControlledInputNumber from 'app/components/cores/inputs/input-number/controlled-input-number';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import TextInput from 'app/components/cores/inputs/text/text';
import { SingleSelect } from 'app/components/cores/select';
import { MEASUREMENT_OPTIONS } from 'models';
import { PropsWithChildren } from 'react';
import { Control, useController } from 'react-hook-form';
import { MenuListProps, components as ReactSelectComponents } from 'react-select';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography } from '@mui/material';

import MeasurementUnits from './measurements';

type Props = {
  control?: Control;
  name: string;
};

const commonInputProps = {
  className: 'bg-paper hover:bg-gray-200 border-right-width-1',
  disableUnderline: true,
  classes: {
    input: 'p-8 text-11',
  },
};

const NumericOption = ({ name, control }: Props) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  const handleChangeSelect = (option: unknown) => {
    const optionValue = option as (typeof MEASUREMENT_OPTIONS)[number];
    onChange({ ...value, measurement: optionValue?.value || '', unit: '' });
  };

  const handleChooseCustomUnit = () => {
    onChange({ ...value, measurement: 'custom', unit: '' });
  };

  const renderMenuList = ({ children, ...props }: PropsWithChildren<MenuListProps>) => {
    // eslint-disable-next-line react/prop-types
    const closeMenu = props?.selectProps?.onMenuClose;

    const onClick = () => {
      handleChooseCustomUnit();
      closeMenu();
    };

    return (
      <ReactSelectComponents.MenuList {...props}>
        <div className="flex items-center px-8">
          <button
            type="button"
            onClick={onClick}
          >
            <AddCircleIcon className="text-secondaryLight text-20" />
          </button>

          <button
            type="button"
            className="flex items-center p-8 font-bold text-13"
            onClick={onClick}
          >
            Add Custom Unit
          </button>
        </div>
        {children}
      </ReactSelectComponents.MenuList>
    );
  };

  const measurement =
    value.measurement === 'custom'
      ? { label: 'Custom Unit', value: value?.unit }
      : MEASUREMENT_OPTIONS.find(option => option?.value === value?.measurement);

  return (
    <div className="flex flex-col pb-16 space-y-16">
      <Typography className="font-600 text-12">Numeric Options</Typography>

      <div className="flex items-center justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Units of measurement</Typography>
        <SingleSelect
          onChange={handleChangeSelect}
          options={MEASUREMENT_OPTIONS}
          value={measurement}
          components={{ MenuList: renderMenuList }}
          className="flex-auto"
          placeholder="Select unit"
          styles={{ menu: base => ({ ...base, zIndex: 100 }) }}
          maxMenuHeight={200}
          isClearable
        />
      </div>

      {measurement?.value && (
        <MeasurementUnits
          measurement={value.measurement}
          control={control!}
          name={name}
        />
      )}

      {value.measurement === 'custom' && (
        <div className="flex items-center justify-between space-x-8">
          <Typography className="text-12 text-secondaryLight font-400">Custom Unit</Typography>

          <div className="w-80">
            <TextInput
              className="w-full px-8 bg-paper rounded-8"
              variant="standard"
              InputProps={{ className: 'h-32 text-11', disableUnderline: true }}
              value={value.unit}
              onChange={event => onChange({ ...value, unit: event.target.value })}
            />
          </div>
        </div>
      )}

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Minimum</Typography>
        <div className="w-80">
          <ControlledInputNumber
            name={`${name}.minNumber`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={commonInputProps}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Minimum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.minLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={commonInputProps}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Maximum</Typography>
        <div className="w-80">
          <ControlledInputNumber
            name={`${name}.maxNumber`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={commonInputProps}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between space-x-8">
        <Typography className="text-12 text-secondaryLight font-400">Maximum Label</Typography>
        <div className="w-80">
          <ControlledTextInput
            name={`${name}.maxLabel`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={commonInputProps}
          />
        </div>
      </div>
    </div>
  );
};

export default NumericOption;
