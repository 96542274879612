import { TASK_TABS } from 'constants/tabs';

import LocationAssignment from './assignment/location-assignment';
import GlobalSetting from './global-setting';
import TaskInfo from './info';
import PreviewLayout from './preview/preview-layout';
import QuestionVersionSwitch from './question-version-switch';
import TaskVariation from './variation';

type Props = {
  tabIndex: number;
  onSetPageLoading?: (isLoading: boolean) => void;
  onSave?: (options: any, onSuccess?: () => void) => void;
};

function TaskForm({ tabIndex, onSetPageLoading, onSave }: Props) {
  return (
    <div className="h-full space-y-8">
      {tabIndex === TASK_TABS.INFO.value && <TaskInfo />}
      {tabIndex === TASK_TABS.LOCATION.value && <LocationAssignment onSetPageLoading={onSetPageLoading} />}
      {tabIndex === TASK_TABS.GLOBAL_SETTING.value && <GlobalSetting />}
      {tabIndex === TASK_TABS.VARIATION.value && <TaskVariation />}
      {tabIndex === TASK_TABS.QUESTION.value && <QuestionVersionSwitch onSave={onSave} />}
      {tabIndex === TASK_TABS.PREVIEW.value && (
        <div className="flex flex-row justify-center w-full">
          <PreviewLayout />
        </div>
      )}
    </div>
  );
}

export default TaskForm;
