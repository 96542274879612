import clsx from 'clsx';
import { ReactNode } from 'react';

import { makeStyles } from '@mui/styles';

type Props = {
  children: ReactNode;
};

function TaskContainer({ children }: Props) {
  const classes = useStyles();
  return (
    <div className={clsx('p-16 md:p-24', classes.root)}>
      <div className="flex h-full grid-cols-12 overflow-hidden bg-white shadow-md md:gap-16 md:grid rounded-8">
        {children}
      </div>
    </div>
  );
}

export default TaskContainer;

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100% - 64px) !important',
  },
}));
