import { authRoles } from 'app/auth';
import { Paths } from 'constants/paths';

import Edit from './edit';
import Index from './index';
import New from './new';

const NewsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: Paths.template,
      exact: true,
      component: Index,
    },
    {
      path: `${Paths.template}/new`,
      exact: true,
      component: New,
    },
    {
      path: `${Paths.template}/:templateId`,
      exact: true,
      component: Edit,
    },
  ],
};

export default NewsPagesConfig;
