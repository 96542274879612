import clsx from 'clsx';

import Typography from '@mui/material/Typography';

type Props = {
  title?: string;
  description?: string;
};

function PreviewHeaderTitle({ title, description }: Props) {
  return (
    <div className={clsx('flex flex-col justify-end px-24', 'pt-80')}>
      <div className="max-w-192 overflow-hidden">
        <Typography className="text-14 font-700 text-white whitespace-no-wrap line-clamp-1">{title}</Typography>
      </div>
      <div className="max-w-192 overflow-hidden">
        <Typography
          noWrap={true}
          className="text-13 font-400"
          style={{ color: '#7C808B' }}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
}

export default PreviewHeaderTitle;
