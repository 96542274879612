import { ControlledDataSelect } from 'app/components/cores/data-select';
import CheckboxDefault from 'app/components/cores/items-editor/list-trigger/checkbox-default';
import { SingleSelect } from 'app/components/cores/select';
import RoleSelection from 'app/components/lists/assignment/role-selection';
import AttributeContainer from 'app/components/lists/attribute-container';
import { useGetTasks } from 'app/hooks/api/tasks';
import { GROUP_OPTIONS } from 'constants/index';
import { getOrgRoleIds, transformOrgRoles } from 'domains/conditional-task-settings';
import { TListTrigger } from 'models/task.model';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import { type ActionMeta } from 'react-select';

type Props = {
  name: string;
};

const TriggerList = ({ name }: Props) => {
  const { setValue } = useFormContext();
  const { data } = useGetTasks({ page: 1, per_page: 999, status: 'published' });

  const { listId: listIdParam } = useParams<{ listId: string }>();

  const currentTaskName = `${name}`;
  const defaultName = `${currentTaskName}.default`;
  const listTypeName = `${currentTaskName}.listType`;
  const orgRoleIdsName = `${currentTaskName}.orgRoleIds`;
  const orgRolesName = `${currentTaskName}.orgRoles`;
  const listIdName = `${currentTaskName}.id`;

  const listIdValue = useWatch({ name: listIdName });
  const defaultValue = useWatch({ name: defaultName });

  const lists = data?.records || [];

  const getSelectedList = (id: number) => lists.find(list => list.id === id);

  const selectedList = getSelectedList(listIdValue) as unknown as TListTrigger;

  const setRoles = (roles: TListTrigger['orgRoles']) => {
    const orgRoleIds = getOrgRoleIds(roles);
    setValue(orgRolesName, roles);
    setValue(orgRoleIdsName, orgRoleIds);
  };

  const handleChangeItem = (item: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action === 'clear') {
      setValue(name, undefined);
      return;
    }

    const { id, listType, orgRoles } = (item || {}) as TListTrigger;

    setValue(listIdName, id);
    setValue(listTypeName, listType);
    setRoles(orgRoles);
    setValue(defaultName, true);
  };

  const handleCheckboxChange = (checked: boolean) => {
    const { orgRoles, listType } = selectedList;

    if (checked) {
      setRoles(orgRoles);
      setValue(listTypeName, listType);
    }
  };

  const handleChangeRoles = (roles: TListTrigger['orgRoles']) => {
    const orgRoles = transformOrgRoles(roles);
    setRoles(orgRoles);
  };

  useEffect(() => {
    if (!listIdValue) return;
    if (defaultValue) {
      setValue(listTypeName, selectedList?.listType);
      setRoles(transformOrgRoles(selectedList?.orgRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIdValue, defaultValue, selectedList?.orgRoles, selectedList?.listType]);

  return (
    <div className="space-y-8">
      <>
        <SingleSelect
          placeholder="List Name"
          value={selectedList}
          options={lists}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          onChange={handleChangeItem}
          isClearable
          key={listIdValue ?? 0}
          filterOption={(option: any, input: string) =>
            Number(option.value) !== Number(listIdParam) && option.label.toLowerCase().includes(input.toLowerCase())
          }
        />

        {!!listIdValue && (
          <>
            <CheckboxDefault
              name={defaultName}
              label="Use default Assign Role(s) and List Type"
              key={listIdValue ?? 0}
              onChange={handleCheckboxChange}
            />

            <AttributeContainer
              label="Assign Role(s)"
              labelClassName="text-11 font-400"
            >
              <RoleSelection
                name={orgRolesName}
                placeholder="Assign Role to List"
                menuPlacement="top"
                disabled={defaultValue}
                onChange={handleChangeRoles}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.externalId}
                key={listIdValue ?? 0}
              />
            </AttributeContainer>

            <AttributeContainer
              label="List Type"
              labelClassName="text-11 font-400"
            >
              <div className="w-128">
                <ControlledDataSelect
                  options={GROUP_OPTIONS}
                  name={listTypeName}
                  key={listIdValue ?? 0}
                  disabled={defaultValue}
                />
              </div>
            </AttributeContainer>
          </>
        )}
      </>
    </div>
  );
};
export default TriggerList;
