import { Typography } from '@mui/material';

type Props = {
  ruleText: string;
};

export function FrequencyRuleText({ ruleText }: Props) {
  return (
    <div className="flex items-center mt-24">
      <Typography className="mr-4 text-11 font-500 text-secondaryLight">List will repeat</Typography>
      <Typography className="text-11 font-500 text-secondaryMain">{ruleText}.</Typography>
    </div>
  );
}
