import { TResponse } from 'models';

import CheckIcon from '@mui/icons-material/Check';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { styled } from '@mui/material/styles';

type Value = boolean | null;

type Props = {
  value?: Value;
  onChange?: (value: Value) => void;
  status?: TResponse['status'];
  disabled?: boolean;
};

const StyledButton = styled('button')<{
  active?: Value;
  status?: TResponse['status'];
}>(({ theme, active, status }: any) => {
  const { palette } = theme;

  const getBgColorByStatus = () => {
    if (status === 'overdue') return palette.overdue.light;
    return '#E0F1FB';
  };

  const getColorByStatus = () => {
    if (status === 'overdue') return palette.overdue.main;
    return palette.primary.main;
  };

  return {
    backgroundColor: active ? getBgColorByStatus() : palette.grey[200],
    color: active ? getColorByStatus() : palette.grey[500],
  };
});

const CheckMarkButton = ({ value, onChange, status, disabled }: Props) => {
  const handleClick = () => {
    if (disabled) return;
    onChange?.(!value);
  };

  return (
    <StyledButton
      type="button"
      className="flex items-center justify-center w-full h-40 px-24 text-left rounded-8"
      active={value}
      onClick={handleClick}
      status={status}
    >
      {value ? <DoneRoundedIcon className="font-bold text-40" /> : <CheckIcon className="font-bold text-24" />}
    </StyledButton>
  );
};

export default CheckMarkButton;
