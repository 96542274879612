/* eslint-disable */
import clsx from 'clsx';
import React, { ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BluefitPageSidebarContent from './BluefitPageSidebarContent';

interface IProps {
  // TODO: find a correct type here
  classes: any;
  variant: string | JSX.Element;
  position: 'left' | 'top' | 'right' | 'bottom';
  rootRef: React.RefObject<HTMLDivElement>;
  innerScroll?: boolean;
  header?: JSX.Element;
  sidebarInner?: boolean;
  leftSidebarHeader?: JSX.Element;
  leftSidebarContent?: JSX.Element;
  leftSidebarVariant?: JSX.Element;
  rightSidebarHeader?: JSX.Element;
  rightSidebarContent?: JSX.Element;
  rightSidebarVariant?: JSX.Element;
  content?: JSX.Element;
}

interface IRef {
  toggleSidebar: () => void;
}

const FusePageSimpleSidebar: ForwardRefRenderFunction<IRef, IProps> = (props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = props;

  const theme = useTheme();
  const hiddenLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const hiddenMdDown = useMediaQuery(theme.breakpoints.down('md'));

  useImperativeHandle(ref, () => ({
    toggleSidebar: handleToggleDrawer,
  }));

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {!hiddenLgUp && props.variant === 'permanent' && (
        <div>
          <Drawer
            variant="temporary"
            anchor={props.position}
            open={isOpen}
            onClose={ev => handleToggleDrawer()}
            classes={{
              root: clsx(classes.sidebarWrapper, props.variant),
              paper: clsx(
                classes.sidebar,
                props.variant,
                props.position === 'left' ? classes.leftSidebar : classes.rightSidebar,
              ),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            container={props.rootRef.current}
            BackdropProps={{
              classes: {
                root: classes.backdrop,
              },
            }}
            style={{ position: 'absolute' }}
          >
            <BluefitPageSidebarContent {...props} />
          </Drawer>
        </div>
      )}
      {props.variant === 'permanent' && !hiddenMdDown && (
        <div>
          <Drawer
            variant="permanent"
            className={clsx(classes.sidebarWrapper, props.variant)}
            open={isOpen}
            classes={{
              paper: clsx(
                classes.sidebar,
                props.variant,
                props.position === 'left' ? classes.leftSidebar : classes.rightSidebar,
              ),
            }}
          >
            <BluefitPageSidebarContent {...props} />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default React.forwardRef(FusePageSimpleSidebar);
