import { SingleSelect } from 'app/components/cores/select';
import { Props as ReactSelectProps } from 'react-select';

type Props = {} & ReactSelectProps;

function LogicSelect(props: Props) {
  const styles = {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: '100%',
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: '#F5D02F',
      borderRadius: 16,
      height: 24,
      minHeight: 24,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
      paddingLeft: 8,
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
      },
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      width: '100%',
      borderRadius: 16,
      overflow: 'hidden',
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    menuPortal: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
        fontWeight: '600',
        color: state.isDisabled ? '#AAA' : '#1F242F',
      },
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
        lineHeight: 2,
      },
    }),
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: '#eee',
      primary25: '#eee',
      primary: '#F5D02F',
    },
  });

  return (
    <SingleSelect
      menuPlacement="auto"
      styles={styles}
      theme={generateTheme}
      isSearchable={false}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      {...props}
    />
  );
}

export default LogicSelect;
