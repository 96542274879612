import clsx from 'clsx';
import React, { ComponentProps } from 'react';

import Typography from '@mui/material/Typography';

type Props = {
  label: string;
  children: React.ReactNode;
  labelClassName?: ComponentProps<typeof Typography>['className'];
};

function AttributeContainer({ label, children, labelClassName }: Props) {
  return (
    <div className="flex flex-col">
      <Typography className={clsx(labelClassName ?? 'text-medium-dark')}>{label}</Typography>
      <div className="mt-4">{children}</div>
    </div>
  );
}

export default AttributeContainer;
