import { TTaskStatusGroup } from 'models/employee/task.model';

export const TASK_GROUP_MAPPING: { [key in TTaskStatusGroup]: string } = {
  todo: 'Todo',
  overdue: 'Overdue',
  onDemand: 'On Demand',
  inProgress: 'In Progress',
  pending: 'Pending',
  upcoming: 'Upcoming',
  audit: 'Audit',
};

export const FULL_TYPE_MAPPING = {
  todo: 'Todo',
  overdue: 'Overdue',
  incomplete: 'Incomplete',
  onDemand: 'On Demand',
  inProgress: 'In Progress',
  pending: 'Pending',
  upcoming: 'Upcoming',
  expired: 'Expired',
  completed: 'Completed',
  audit: 'Audit',
};

export const TASK_SIDEBAR_ORDER: { [key in TTaskStatusGroup]: number } = {
  todo: 0,
  onDemand: 1,
  audit: 2,
  overdue: 3,
  inProgress: 4,
  pending: 5,
  upcoming: 6,
};

export const TASK_SIDEBAR_ITEM_FORMAT = 'hh:mmA';

export const TASK_DATE_ITEM_FORMAT = 'DD-MM-YYYY';

export const TASK_REFETCH_TIME = 1000 * 60 * 5; // 5m
