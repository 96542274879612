import { components as ReactSelectComponents } from 'react-select';

import { Typography } from '@mui/material';

import QuestionIconType from '../question-icon-type';

function TypeOption(props: any) {
  const { isSelected, value } = props;
  const Icon = QuestionIconType(value);

  return (
    <ReactSelectComponents.Option {...props}>
      <div className="flex items-center">
        {Icon && (
          <Icon
            color={isSelected ? 'default' : 'primary'}
            className="mr-12 text-18"
          />
        )}
        <Typography className="text-13 font-700">{props.children}</Typography>
      </div>
    </ReactSelectComponents.Option>
  );
}

export default TypeOption;
