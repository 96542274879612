import PreviewBox from 'app/components/cores/preview/preview-box';
import PreviewElement from 'app/components/cores/preview/preview-element';
import PreviewHeader from 'app/components/cores/preview/preview-header';
import PreviewHeaderTitle from 'app/components/cores/preview/preview-header-title';
import { useGetLocation, useUpcomingEventsByLocation } from 'app/hooks/api/locations';
import clsx from 'clsx';
import { holidayStateOptions } from 'constants/options';
import { TIME_ZONES } from 'constants/time-zones';
import { formatDate } from 'utils/date';
import { capitalizeFirstCharacter } from 'utils/string';

import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';

import OpeningHours from './tabs/overview/opening/opening-hours';
import UpcomingEventCardItem from './tabs/overview/upcoming-event-card-item';

const useStyles = makeStyles(() => {
  return {
    content: {
      width: 312,
      overflow: 'auto',
      borderLeft: '1px solid',
      borderColor: '#BDBDBD',
      height: '100%',
    },
    icon: {
      fontSize: '1.8rem',
      color: 'white',
    },
    bgHoliday: {
      backgroundColor: 'rgba(255, 246, 250, 1)',
    },
    borderHoliday: {
      borderColor: 'rgba(224, 17, 95, 1)',
    },
  };
});

type Props = {
  locationId: number;
  onEditClick: (id: number) => void;
  onCloseClick: () => void;
};

function LocationPreview({ locationId, onEditClick, onCloseClick }: Props) {
  const theme: any = useTheme();
  const classes = useStyles();
  const fromDate = formatDate(new Date().toISOString(), 'DD-MM-YYYY');

  const { data: location, isLoading } = useGetLocation(locationId);
  const { data: upcomingEventsByLocation } = useUpcomingEventsByLocation(locationId, {
    'q[from_date]': fromDate,
    per_page: 5,
  });
  const upcomingEvents = upcomingEventsByLocation?.records || [];

  /**
   * If object user is empty or preview component is loading data,
   * we will return loading state
   */

  if (isLoading) {
    return (
      <div
        className={clsx(classes.content)}
        role="presentation"
      >
        <PreviewHeader>
          <></>
        </PreviewHeader>
        <div className="flex flex-col justify-end h-256">
          <CircularProgress className="ml-136" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.content)}
      role="presentation"
    >
      <div className="flex flex-col w-full">
        <PreviewHeader>
          <div className="relative">
            <div className="flex flex-row justify-end mb-8 mr-24">
              <IconButton
                aria-label="Close"
                className="px-8"
                onClick={onCloseClick}
              >
                <Icon className={clsx(classes.icon)}>close</Icon>
              </IconButton>
            </div>
            <PreviewHeaderTitle title={location?.name} />
            <Fab
              style={{ backgroundColor: theme.palette.primary.main }}
              aria-label="Edit"
              size="medium"
              className="absolute ltr:left-0 rtl:right-0 mx-232 -mb-36 z-999"
              onClick={() => onEditClick(locationId)}
              sx={{ bottom: 'translateY(-50%)' }}
            >
              <Icon sx={{ color: 'white' }}>edit</Icon>
            </Fab>
          </div>
        </PreviewHeader>
        <div>
          <PreviewBox title="Location Information">
            <div className="flex flex-col">
              <PreviewElement title="Status">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {capitalizeFirstCharacter(location?.status || '')}
                </Typography>
              </PreviewElement>

              <PreviewElement title="Regions">
                <>
                  {location?.regionsAttributes?.map(region => (
                    <Typography
                      key={region.id}
                      color="textSecondary"
                      className="text-11 font-400"
                    >
                      {region.name}
                    </Typography>
                  ))}
                </>
              </PreviewElement>

              <PreviewElement title="State">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {holidayStateOptions.find(state => state.value === location?.state)?.label}
                </Typography>
              </PreviewElement>

              <PreviewElement title="Timezone">
                <Typography
                  color="textSecondary"
                  className="text-11 font-400"
                >
                  {TIME_ZONES.find(timezone => timezone.value === location?.timezone)?.label}
                </Typography>
              </PreviewElement>

              <PreviewElement title="Opening Times">
                <div className="space-y-8">
                  {location?.openingHours?.map(opening => (
                    <div
                      key={opening?.externalId}
                      color="textSecondary"
                      className="flex justify-between w-full space-x-8"
                    >
                      <div>
                        <OpeningHours
                          openingTimes={opening.openingTimes}
                          textClassName="text-11"
                        />
                      </div>

                      <Typography className="flex-1 text-11 font-500">
                        {opening.timePeriod
                          ? `${opening.startDay}/${opening.startMonth} - ${opening.endDay}/${opening.endMonth}`
                          : 'Whole Year'}
                      </Typography>
                    </div>
                  ))}
                </div>
              </PreviewElement>
            </div>
          </PreviewBox>
        </div>
        <Divider />
        <div>
          <PreviewBox title="Upcoming Events">
            <div className="flex flex-col mt-24 space-y-8">
              {upcomingEvents.map((event, i) => (
                <UpcomingEventCardItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  event={event}
                />
              ))}
            </div>
          </PreviewBox>
        </div>
      </div>
    </div>
  );
}

export default LocationPreview;
