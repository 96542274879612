import {
  ConditionInputs,
  LogicSelects,
  MultipleConditionSelect,
} from 'app/components/cores/items-editor/common-date-time-condition';
import {
  AFTER_END_OF_VALUE,
  BEFORE_END_OF_VALUE,
  CONDITIONAL_DATE_TIME_OPTIONS,
  CONDITIONAL_DEFAULT_OPTIONS,
  IN_THE_PAST_VALUE,
  WITHIN_VALUE,
} from 'constants/index';
import { getTimeTypeOptionsByLogic, getValueOptionsInfoByLogic } from 'domains/date-time-logic.domain';
import _ from 'lodash';
import { TDateTimeIconCondition, TItem } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Control, useController, useFieldArray, useFormContext } from 'react-hook-form';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, IconButton, Typography } from '@mui/material';

import { NotifyForm } from '../form';

type Props = {
  control?: Control;
  name: string;
  selectedConditionalItem: TItem;
  onShowMessageModal?: () => void;
};

export function DateTimeCondition({ control, name, selectedConditionalItem, onShowMessageModal }: Props) {
  const { clearErrors } = useFormContext();
  const notificationConditionalName = `${name}.notificationConditionals.0`;
  const notificationConditionalComparisonName = `${notificationConditionalName}.comparisons`;

  const [isShowMoreLogic, setIsShowMoreLogic] = useState(false);

  const { dateTimeType } = selectedConditionalItem;

  // notification conditional
  const {
    field: { onChange },
  } = useController({ control, name: notificationConditionalName });

  // comparisons
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: notificationConditionalComparisonName,
  });

  const firstFieldLogic = (fields?.[0] as any)?.logic;
  const hasConditional = !!fields?.length;

  const isCustomConditional = useMemo(() => {
    if (fields?.length === 1) {
      if (firstFieldLogic === 'custom') return true;
      return !['completed', 'not_completed', 'not_applicable'].includes(firstFieldLogic);
    }
    return false;
  }, [firstFieldLogic, fields]);

  const defaultCondition = useMemo(() => {
    return !isCustomConditional
      ? _.find(CONDITIONAL_DEFAULT_OPTIONS, { conditionalLogic: firstFieldLogic })
      : CONDITIONAL_DEFAULT_OPTIONS[0];
  }, [firstFieldLogic, isCustomConditional]);

  const mappedFields = useMemo(
    () =>
      _.map(fields, (field: TDateTimeIconCondition) => ({
        ...field,
        conditionalLogic: field.logic,
      })),
    [fields],
  );

  useEffect(() => {
    setIsShowMoreLogic(isCustomConditional);
  }, [isCustomConditional]);

  const handleChangeDefaultLogic = (item: any) => {
    const isCustomize = item.conditionalLogic === 'custom';
    remove();
    setIsShowMoreLogic(isCustomize);

    if (isCustomize) {
      append({
        ...CONDITIONAL_DATE_TIME_OPTIONS[0],
        logic: CONDITIONAL_DATE_TIME_OPTIONS[0].conditionalLogic,
      });
    } else {
      append({
        ...item,
        logic: item.conditionalLogic,
      });
    }
  };

  const handleChangeConditional = (index: number) => (item: unknown) => {
    const { conditionalLabel, conditionalLogic } = item as TDateTimeIconCondition;

    const defaultTimeType = getTimeTypeOptionsByLogic(conditionalLogic, dateTimeType)?.defaultValue;
    const defaultValue = getValueOptionsInfoByLogic(conditionalLogic)?.defaultValue;

    update(index, {
      conditionalLabel,
      conditionalLogic,
      logic: conditionalLogic,
      value: defaultValue?.id ?? '',
      timeType: defaultTimeType?.id ?? '',
    });

    clearErrors(`${notificationConditionalComparisonName}`);
  };

  const handleRemoveConditional = (index: number) => () => {
    remove(index);
  };

  const handleRemoveAllConditional = () => {
    onChange({});
    remove();
  };

  return (
    <div>
      <MultipleConditionSelect
        indicateText="Trigger notification if"
        selectedConditionalItem={selectedConditionalItem}
        defaultCondition={defaultCondition}
        onChange={handleChangeDefaultLogic}
      />

      {hasConditional &&
        (isShowMoreLogic ? (
          <div className="mt-12">
            <div className="mb-16 space-y-12">
              {mappedFields?.map((field: any, index: number) => (
                <div
                  key={field.id}
                  className="flex items-start justify-between space-x-8"
                >
                  <LogicSelects
                    isCustomize={true}
                    selectedConditionalItem={selectedConditionalItem}
                    conditionalComparison={mappedFields as any}
                    value={field}
                    placeholder="Select"
                    onChange={handleChangeConditional(index)}
                    dateTimeType={dateTimeType}
                  />

                  {field?.logic === IN_THE_PAST_VALUE && <Typography className="w-40 text-center text-11" />}

                  {[BEFORE_END_OF_VALUE, AFTER_END_OF_VALUE].includes(field?.logic) && (
                    <ConditionInputs
                      name={`${notificationConditionalComparisonName}.${index}`}
                      control={control}
                      dateTimeType={dateTimeType}
                      logic={field?.logic}
                    />
                  )}

                  {field?.logic === WITHIN_VALUE && (
                    <ConditionInputs
                      name={`${notificationConditionalComparisonName}.${index}`}
                      control={control}
                      dateTimeType={dateTimeType}
                      logic={field?.logic}
                    />
                  )}
                  {mappedFields?.length > 1 && (
                    <div>
                      <IconButton onClick={handleRemoveConditional(index)}>
                        <DeleteOutlineIcon className="text-16" />
                      </IconButton>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null)}

      {defaultCondition && (
        <NotifyForm
          name={notificationConditionalName}
          control={control}
          onShowMessageModal={onShowMessageModal}
        />
      )}

      <div className="flex items-center justify-end space-x-4">
        <Button
          disableElevation={true}
          variant="outlined"
          color="error"
          className="w-64 h-24 capitalize text-11 font-500 min-w-64 rounded-4"
          onClick={handleRemoveAllConditional}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
