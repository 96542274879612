import { SelectBox } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { SaltFormOption } from 'app/components/cores/tasks/components';
import { useGetSaltOpsForm } from 'app/hooks/api/external-service/salt-ops';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import { useFetchServices } from 'app/hooks/api/services';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _isEmpty from 'lodash/isEmpty';
import { TQuestionLayoutView, TSaltOpsFormItem } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ArrowDropDown } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

type Props = {
  data: TSaltOpsFormItem;
  name: string;
  readOnly?: boolean;
  listId: number;
  view: TQuestionLayoutView;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const SaltOpsForm = ({ data, name, readOnly, listId, view, isAudit, canAuditInteractions = true }: Props) => {
  const { resetField, setValue, setError, clearErrors, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { mutateAsync: editTask, isLoading } = useEditHistoryTask({
    taskId: listId,
    responseId: value?.id,
  });
  const isRequired = value?.item?.required;
  const validAnswer = !_isEmpty(value?.answer);

  const formType = value?.item?.formType;
  const location = getValues('location');

  const {
    data: saltOpsFormData,
    refetch: handleRefetchSaltOpsForm,
    isLoading: isFetchingSaltOpsForm,
  } = useGetSaltOpsForm(
    formType,
    {
      'q[location_salt_id_eq]': location?.saltId,
    },
    { enabled: !!formType && !readOnly },
  );
  const { showError, showSuccess } = useShowMessage();

  const { data: dataServices } = useFetchServices();
  const baseUrlSalt = dataServices?.services?.find(service => service.kind === 'salt');

  const renderPlaceholder = () => {
    const typeFormMap = new Map<TSaltOpsFormItem['formType'], string>();
    typeFormMap.set('feedback', 'add new feedback report');
    typeFormMap.set('hazard', 'add new hazard report');
    typeFormMap.set('headcount', 'add new head count report');
    typeFormMap.set('incident', 'add new incident report');
    typeFormMap.set('maintenance', 'add new maintenance report');
    typeFormMap.set('water_chemistry', 'add new water chemistry report');
    typeFormMap.set('pooltest', 'add new pooltest report');

    return typeFormMap.get(data.formType) || '';
  };

  const renderModalTitle = () => {
    const typeFormMap = new Map<TSaltOpsFormItem['formType'], string>();
    typeFormMap.set('feedback', 'Feedback');
    typeFormMap.set('hazard', 'Hazard');
    typeFormMap.set('headcount', 'Head Count');
    typeFormMap.set('incident', 'Incident');
    typeFormMap.set('maintenance', 'Maintenance');
    typeFormMap.set('water_chemistry', 'Water Chemistry');
    typeFormMap.set('pooltest', 'Pooltest');

    return typeFormMap.get(data.formType) || '';
  };

  const getLinkFormType = () => {
    const typeFormMap = new Map<TSaltOpsFormItem['formType'], string>();
    typeFormMap.set('feedback', 'feedback-form');
    typeFormMap.set('hazard', 'hazard-form');
    typeFormMap.set('headcount', 'head-count-form');
    typeFormMap.set('incident', 'incident-form');
    typeFormMap.set('maintenance', 'maintenance-form');
    typeFormMap.set('water_chemistry', 'water-chemistry');
    typeFormMap.set('pooltest', 'pooltest');

    return typeFormMap.get(data.formType) || '';
  };

  const handleClickPlaceholder = () => {
    // if have no baseUrlSalt, do nothing
    if (readOnly) return;
    if (!baseUrlSalt?.url) return;
    window.open(`/${baseUrlSalt?.url}/operation/${getLinkFormType()}`, '_blank');
  };

  const placeholder = renderPlaceholder().replace(/\b\w/g, l => l.toUpperCase());
  const modalTitle = renderModalTitle();

  const handleChange = (selectedForm: any) => {
    const nextAnswer = !selectedForm ? {} : { id: selectedForm?.id, name: selectedForm?.name };
    onChange({ ...value, answer: nextAnswer });
  };

  const answer = async (payload: any) => {
    try {
      const res = await editTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  useEffect(() => {
    if (isRequired && validAnswer) clearErrors(name);
  }, [validAnswer, isRequired, clearErrors, name]);

  const checkAnswerIsValid = () => {
    if (isRequired && !validAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview
      task={value}
      name={name}
      onSaveEdit={handleSave}
      view={view}
      isLoading={isLoading}
      isAudit={isAudit}
      canAuditInteractions={canAuditInteractions}
    >
      {!readOnly ? (
        <SaltFormOption
          value={value?.answer}
          formType={formType}
          responseId={value?.id}
          listId={listId}
          isFetchingSaltOpsForm={isFetchingSaltOpsForm}
          saltOpsFormData={saltOpsFormData}
          onChange={handleChange}
          onRefetchSaltOpsForm={handleRefetchSaltOpsForm}
          disabled
        />
      ) : (
        <div className="space-y-16">
          <div className="space-y-8">
            <SelectBox
              modalTitle={modalTitle}
              placeholder={placeholder}
              disabled={true}
              options={[]}
              placeholderStyle="text-left font-bold text-secondaryMain"
              rightIcon={<AddIcon className="text-16 text-secondaryMain" />}
              onClickPlaceholder={handleClickPlaceholder}
            />
            <SelectBox
              modalTitle={modalTitle}
              placeholder="Link to existing report"
              disabled={true}
              options={[]}
              placeholderStyle="text-left text-400 text-secondaryLight"
              rightIcon={<ArrowDropDown className="text-20 text-secondaryMain" />}
              onClickPlaceholder={handleClickPlaceholder}
            />
          </div>

          {value?.answer && (
            <button
              className="flex items-center px-16 py-8 space-x-8 w-full bg-gray-200 rounded-20 min-h-40"
              type="button"
            >
              <Typography className="font-bold text-left text-secondaryMain text-13">{value?.answer?.name}</Typography>
            </button>
          )}
        </div>
      )}
    </QuestionLayoutMapper>
  );
};
export default SaltOpsForm;
