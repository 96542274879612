import useTask from 'app/hooks/use-task';

import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

function ListStatusLegend() {
  const { getListStatus } = useTask();
  const status = ['overdue', 'incomplete', 'on_demand', 'in_progress', 'pending', 'upcoming', 'expired', 'completed'];

  return (
    <div className="px-24">
      {status.map(s => {
        const { name, color } = getListStatus(s);
        return (
          <div
            key={s}
            className="flex items-center mb-4"
          >
            <CircleIcon
              sx={{ color }}
              className="text-9"
            />
            <Typography className="ml-8 text-300 font-400 text-secondaryLight">{name}</Typography>
          </div>
        );
      })}
    </div>
  );
}

export default ListStatusLegend;
