import ErrorMessage from 'app/components/cores/form/error-message';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll/select-infinite-scroll';
import LocationTransferList from 'app/components/lists/location/transfer-list';
import { useInfiniteLocationCategories } from 'app/hooks/api/location-categories';
import { useAssignListsToLocation2, useGetLocations } from 'app/hooks/api/locations';
import useShowMessage from 'app/hooks/use-show-message';
import _ from 'lodash';
import { TLocation, TLocationCategoryOptions } from 'models';
import { useMemo } from 'react';
import { FieldValues, useController, useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';

type Props = {
  onSetPageLoading?: (isLoading: boolean) => void;
};

function LocationAssignment({ onSetPageLoading }: Props) {
  const { showError, showSuccess } = useShowMessage();
  const { control, getValues } = useFormContext<FieldValues>();
  const { data: locationData } = useGetLocations({ per_page: 200 });
  const { mutateAsync: assignListsToLocation } = useAssignListsToLocation2();
  const {
    setSearchText,
    data: locationCategoriesList,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteLocationCategories({ per_page: 10 });

  const locations = useMemo(
    () =>
      locationData?.records?.map((location: TLocation) => ({
        locationId: location?.externalId,
        locationName: location?.name,
      })) || [],
    [locationData],
  );

  const handleLoadMoreLocationCategories = () => {
    if (isFetchingNextPage || !hasNextPage) return;
    fetchNextPage();
  };

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: 'locationTasksAttributes',
    control,
  });

  const {
    field: { value: locationCategoryTasksAttributes, onChange: onChangeLocationCategoryTasksAttributes },
  } = useController({
    name: 'locationCategoryTasksAttributes',
    control,
  });

  const hasError = !!error?.message;

  const handleChange = (newValue: any) => {
    onChange(newValue);
  };

  const updateTaskLocations = async ({ locationIds, status, errorMessage, successMessage }: any) => {
    const listId = getValues('id');
    const payload = {
      location: {
        status,
        taskIds: [listId],
      },
    };
    const publisher: any = [];

    locationIds?.forEach((locationId: number) => {
      publisher.push(
        assignListsToLocation({
          id: locationId,
          action: 'update_assignment_status',
          payload,
        }),
      );
    });

    const updatedAssignmentResult = await Promise.all(publisher);
    const assignmentData = _.map(updatedAssignmentResult, item => item.data?.[0]);

    const getResultMapping = (data: any) => {
      const output: {
        updatedAssignmentListLocationIds: number[];
        errors: string[];
      } = {
        updatedAssignmentListLocationIds: [],
        errors: [],
      };

      _.forEach(data, (item: any, index: number) => {
        if (item.success) {
          const locationId = locationIds[index];
          output.updatedAssignmentListLocationIds.push(locationId);
        } else {
          output.errors.push(item.message);
        }
      });

      return output;
    };

    const resultMapping = getResultMapping(assignmentData);

    // handle show message
    if (resultMapping.errors?.length > 0) {
      let errors = errorMessage;
      _.forEach(resultMapping.errors, err => {
        errors += `\n${err}`;
      });
      showError(errors);
    } else {
      showSuccess(successMessage);
    }

    return resultMapping;
  };

  const handlePublishPendingAssignmentList = async (locationIds: number[], onDone?: () => void) => {
    onSetPageLoading?.(true);
    const errorMessage = 'Could not publish pending assignment';
    const successMessage = 'Pending assignment has been successfully published';
    try {
      const { updatedAssignmentListLocationIds } = await updateTaskLocations({
        status: 'active',
        locationIds,
        errorMessage,
        successMessage,
      });

      // update `assignmentStatus` of the assigned locations
      if (updatedAssignmentListLocationIds?.length > 0) {
        const nextTaskLocations = _.map(value, (item: any) => {
          const isPublishedAssignmentListLocation = updatedAssignmentListLocationIds?.includes(item.locationId);
          if (!isPublishedAssignmentListLocation) return item;
          return { ...item, assignmentStatus: 'active' };
        });

        onChange(nextTaskLocations);
      }
    } catch {
      showError(errorMessage);
    } finally {
      onDone?.();
      onSetPageLoading?.(false);
    }
  };

  const processLocationCategories = (original: TLocationCategoryOptions, newData: TLocationCategoryOptions) => {
    const unique = _.unionBy(original, newData, 'locationCategoryId');

    const newList = unique?.map(item => {
      if (!newData.find(newItem => newItem.locationCategoryId === item.locationCategoryId))
        return { ...item, _destroy: true };

      if (!original?.find(originalItem => originalItem.locationCategoryId === item.locationCategoryId))
        return { ...item };

      return { ...item, _destroy: undefined };
    });

    return newList;
  };

  const handleChangeLocationCategory = (locationCategories: any) => {
    const processedLocationCategories = processLocationCategories(locationCategoryTasksAttributes, locationCategories);
    onChangeLocationCategoryTasksAttributes(processedLocationCategories);
  };

  const handlePendingAssignmentList = async (locationIds: number[], onDone?: () => void) => {
    onSetPageLoading?.(true);
    const errorMessage = 'Could not pending assignment';
    const successMessage = 'Assignment has been successfully pending';
    try {
      const { updatedAssignmentListLocationIds } = await updateTaskLocations({
        status: 'draft',
        locationIds,
        errorMessage,
        successMessage,
      });

      // update `assignmentStatus` of the assigned locations
      if (updatedAssignmentListLocationIds?.length > 0) {
        const nextTaskLocations = _.map(value, (item: any) => {
          const isPendingAssignmentListLocation = updatedAssignmentListLocationIds?.includes(item.locationId);
          if (!isPendingAssignmentListLocation) return item;
          return { ...item, assignmentStatus: 'draft' };
        });

        onChange(nextTaskLocations);
      }
    } catch {
      showError(errorMessage);
    } finally {
      onDone?.();
      onSetPageLoading?.(false);
    }
  };

  const locationCategoriesValue = useMemo(
    () =>
      _.orderBy(
        locationCategoryTasksAttributes
          ?.filter((item: any) => !item._destroy)
          .map((item: any) =>
            item.locationCategoryId === 0 ? { locationCategoryId: 0, locationCategoryName: 'Global' } : item,
          ),
        'locationCategoryId',
      ),
    [locationCategoryTasksAttributes],
  );

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-wrap items-center gap-16 px-24 py-8 bg-white layout-box">
        <Typography className="text-13 font-400 min-w-104">Apply to new locations</Typography>
        <SelectInfiniteScroll
          options={locationCategoriesList}
          value={locationCategoriesValue}
          onChange={handleChangeLocationCategory}
          className="flex-1"
          placeholder="Select category"
          getOptionLabel={(option: any) => option.locationCategoryName}
          getOptionValue={(option: any) => option.locationCategoryId}
          onMenuScrollToBottom={handleLoadMoreLocationCategories}
          isLoading={isFetching}
          closeMenuOnSelect={false}
          isMulti
          SelectProps={{
            classes: {
              control: 'min-h-40',
            },
          }}
          onInputChange={setSearchText}
        />
      </div>
      <Typography className="mt-4 ml-20 text-10 font-400 text-secondaryLight">
        By selecting an option, this list will be assigned by default to all future locations created in the system (
        <span className="font-600">Global </span>option) or within the specified category
      </Typography>

      <div className="flex flex-col flex-1 mt-24">
        <Typography className="mb-8 ml-20 text-11 font-500 text-secondaryMain">Assign Location(s)</Typography>
        <div className="flex flex-col h-full p-24 bg-white layout-box">
          <div className="flex flex-col flex-1 w-full">
            <LocationTransferList
              className="h-full pl-8 lg:mx-auto"
              values={value}
              options={locations}
              onChange={handleChange}
              onPublishPendingAssignmentList={handlePublishPendingAssignmentList}
              onPendingAssignmentList={handlePendingAssignmentList}
            />

            {hasError && <ErrorMessage message={error?.message} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationAssignment;
