import AccordionExpandTitle from 'app/components/cores/items-editor/accordion-expand-title';
import { ITEM_TYPES_MAP } from 'constants/index';
import _ from 'lodash';
import { TItemType } from 'models';
import { useCallback, useEffect } from 'react';
import { Control, useController, useFormContext, useWatch } from 'react-hook-form';

import { Accordion, AccordionDetails } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DateTimeCondition } from './display/date-time-condition/conditions';
import { MultipleCondition } from './multiple-condition';
import { SingleCondition } from './single-condition';

type Props = {
  isDisabled?: boolean;
  control?: Control;
  name: string;
  isExpanded?: boolean;
  onPanelChange?: (
    nextExpandedPanel: 'conditional-task' | 'notify' | 'conditional-list' | 'flagged-item' | undefined,
  ) => void;
};

function FlaggedItemLogicContainer({ isDisabled, control, name, isExpanded, onPanelChange }: Props) {
  const classes = useStyles();
  const { setValue } = useFormContext();

  const {
    field: { value: currentItem },
    fieldState: { error },
  } = useController({ control, name });

  const flagConditionalsName = `${name}.flagConditionals`;
  const firstFlagConditionals = `${name}.flagConditionals[0]`;
  const comparisonsName = `${firstFlagConditionals}.comparisons`;

  const flagConditionals = currentItem?.flagConditionals ?? [];
  const comparisons = flagConditionals?.[0]?.comparisons;

  const isNumericConditions = ([ITEM_TYPES_MAP.NUMERIC] as TItemType[]).includes(currentItem?.type);
  const isDateTimeConditions = ([ITEM_TYPES_MAP.DATE_TIME] as TItemType[]).includes(currentItem?.type);
  const comparisonsWatch = useWatch({ control, name: comparisonsName });

  const hasComparisons = !!comparisonsWatch?.length;
  const hasError = !!(error as any)?.flagConditionals;
  const hasConfig = !!comparisons?.length;

  const handleRemoveAllLogic = useCallback(() => {
    setValue(flagConditionalsName, []);
  }, [setValue, flagConditionalsName]);

  useEffect(() => {
    const isEmptyComparisons = !comparisons || _.isEmpty(comparisons?.[0]) || !comparisons?.[0]?.logic;

    if (isEmptyComparisons) {
      setValue(flagConditionalsName, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisons?.[0]?.logic]);

  const renderConditionComponent = useCallback(() => {
    if (isNumericConditions) {
      return (
        <MultipleCondition
          control={control}
          name={comparisonsName}
          selectedConditionalItem={currentItem}
          key={comparisons?.[0]?.logic ?? 0}
          hasComparisons={hasComparisons}
          onRemoveAllLogic={handleRemoveAllLogic}
        />
      );
    }
    if (isDateTimeConditions) {
      return (
        <DateTimeCondition
          control={control}
          name={firstFlagConditionals}
          selectedConditionalItem={currentItem}
          key={comparisonsWatch?.[0]?.logic ?? 0}
          hasComparisons={hasComparisons}
          onRemoveAllLogic={handleRemoveAllLogic}
        />
      );
    }
    return (
      <SingleCondition
        control={control}
        name={comparisonsName}
        selectedConditionalItem={currentItem}
        instructionText="Flag this task, if answer is"
        key={comparisons?.[0]?.logic ?? 0}
        hasComparisons={hasComparisons}
        onRemoveAllLogic={handleRemoveAllLogic}
      />
    );
  }, [
    comparisons,
    comparisonsName,
    comparisonsWatch,
    control,
    currentItem,
    firstFlagConditionals,
    handleRemoveAllLogic,
    isNumericConditions,
    isDateTimeConditions,
    hasComparisons,
  ]);

  return (
    <Accordion
      className="bg-white shadow-0"
      classes={{
        disabled: classes.accordionRootDisabled,
      }}
      disableGutters={true}
      expanded={isExpanded}
      onChange={() => onPanelChange?.('flagged-item')}
      sx={{ '&:before': { display: 'none' } }}
    >
      <AccordionExpandTitle
        isExpanded={isExpanded}
        hasError={hasError}
        hasConfig={hasConfig}
        title="Add Flagged Item Logic (optional)"
        classes={{
          notifySummaryRoot: classes?.notifySummaryRoot,
          accordionSummaryDisabled: classes?.accordionSummaryDisabled,
        }}
      />

      <AccordionDetails classes={{ root: 'px-24 relative' }}>
        <div className="space-y-8">
          <div className="pt-8">{renderConditionComponent()}</div>
        </div>

        {isDisabled && <div className="absolute inset-0" />}
      </AccordionDetails>
    </Accordion>
  );
}

export default FlaggedItemLogicContainer;

const useStyles = makeStyles(() => {
  return {
    accordionRootDisabled: {
      backgroundColor: 'white !important',
      '& *': {
        color: '#C8CBD2',
      },
    },
    accordionSummaryDisabled: {
      opacity: '1 !important',
    },
    notifySummaryRoot: {
      boxShadow: '0px -1px 1px rgba(0, 0, 0, 0.1) !important',
      borderRadius: '16px 16px 0px 0px !important',
    },
  };
});
