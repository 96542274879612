import au from 'app/configs/i18n/au.json';
import en from 'app/configs/i18n/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  au: { translation: au },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      fallbackLng: 'en',
      defaultLocale: 'en',
      debug: false,
      // have a common namespace used around the full app
      ns: ['translation'],
      defaultNS: 'translation',
      lng: 'en',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        useSuspense: true,
      },
    },
    err => {
      if (err) {
        console.error('Error loading translation files: ', err); // eslint-disable-line
      }
    },
  );

export function useHelperFunction(key, parameterName = null, parameterValue = null) {
  return i18n.t(key, { parameterName: parameterValue });
}

export default i18n;
