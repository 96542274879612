import ErrorMessage from 'app/components/cores/form/error-message';
import { TFrequencyRuleFormat } from 'models';
import { useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { Frequency } from './frequency';
import { FrequencyRuleText } from './ui/frequency-rule-text';

type Props = {
  name: string;
  control?: Control<any>;
  rules?: any;
  showErrorMessage?: boolean;
  showRuleText?: boolean;
};

export function ControlledFrequency({ name, control, rules, showErrorMessage = true, showRuleText = true }: Props) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const [ruleError, setRuleError] = useState('');
  const [ruleText, setRuleText] = useState('');

  const errorMessage = error?.message || ruleError;
  const hasError = !!errorMessage;

  const handleRuleFormat = (formattedRule: TFrequencyRuleFormat) => {
    onChange?.(formattedRule?.ruleString);
    setRuleText(formattedRule?.ruleText);
  };

  const handleRuleChange = () => {
    setRuleError('');
  };

  const handleRuleError = useCallback((ruleErrorString: string) => {
    setRuleError(ruleErrorString);
  }, []);

  return (
    <div>
      <Frequency
        value={value}
        onRuleFormat={handleRuleFormat}
        onChange={handleRuleChange}
        onError={handleRuleError}
      />
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
      {showRuleText && !hasError && <FrequencyRuleText ruleText={ruleText} />}
    </div>
  );
}
