import Header from 'app/components/Layout/headers/internal-search-header';
import DrawerWrapper from 'app/components/cores/drawer-wrapper';
import LocationPreview from 'app/components/locations/index-preview';
import LocationList from 'app/components/locations/list';
import { useGetLocations } from 'app/hooks/api/locations';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isManagerOnly } from 'utils/role';

import BluefitPage from '@fuse/core/BluefitPage';

function LocationIndex() {
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    page: 1,
    per_page: 50,
  });
  const { role: roles, managerLocationIds } = useSelector(({ auth }: any) => auth.user);

  const isManagerAccessOnly = isManagerOnly(roles);

  const { data, isLoading } = useGetLocations(filter);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [previewLocationId, setPreviewLocationId] = useState(0);
  const history = useHistory();

  const locationRecords = data?.records || [];

  const managerRecords = locationRecords.filter((record: { externalId?: number }) =>
    managerLocationIds.includes(record.externalId || 0),
  );
  const locations = isManagerAccessOnly ? managerRecords : locationRecords;
  const pageSize = data?.perPage || 50;
  const totalItems = isManagerAccessOnly ? locations.length : data?.total;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const searchString = 'q[name_cont]';

  const handleSearch = (value: string) => {
    setFilter({ ...filter, page: 1, [searchString]: value });
  };

  const handleFetchData = (fetchParams: any) => {
    setFilter(fetchParams);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleEditDrawerClick = () => {
    history.push(`/locations/${previewLocationId}`);
  };

  const handleToggleDrawer = (open: boolean, locationId: number) => {
    setPreviewLocationId(locationId);
    setIsDrawerOpen(open);
  };

  return (
    <BluefitPage
      classes={{
        contentWrapper: 'p-0 pl-10',
        content: 'flex flex-col relative',
        leftSidebar: 'w-256 border-0',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      drawer={
        <DrawerWrapper
          open={isDrawerOpen}
          onToggle={setIsDrawerOpen}
        >
          <LocationPreview
            locationId={previewLocationId}
            onEditClick={handleEditDrawerClick}
            onCloseClick={handleCloseDrawer}
          />
        </DrawerWrapper>
      }
      header={
        <Header
          title="Location"
          searchText=""
          onSearch={(value: string) => {
            handleSearch(value);
          }}
          rightHeader={<></>}
        />
      }
      content={
        <div className="absolute flex w-full h-full">
          <LocationList
            locations={locations}
            onFetchData={handleFetchData}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
            searchKey={searchString}
            toggleDrawer={handleToggleDrawer}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default LocationIndex;
