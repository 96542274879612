import queryClient from 'app/configs/query-client';
import { TAdminCalendar } from 'models';

import { useGetList } from '../request';

const calendarURL = '/api/admin/calendars';

export const adminCalendarKeys = {
  all: ['adminCalendar'] as const,
  lists: () => [...adminCalendarKeys.all, 'list'] as const,
  list: (filters: any) => [...adminCalendarKeys.lists(), { filters }] as const,
};

export const refetchTask = () => queryClient.invalidateQueries(adminCalendarKeys.all);

export function useGetAdminCalendar(filters: any, options?: any) {
  return useGetList<TAdminCalendar[]>([...adminCalendarKeys.list(filters)], `${calendarURL}`, filters, {
    refetchOnMount: true,
    cacheTime: 0,
    ...options,
  });
}
