import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  name: string;
};

const TaskQuestionHeader = ({ name }: Props) => {
  return (
    <Paper
      className="px-24 py-8 pb-16 bg-white border-b-2 space-y-8"
      square
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          className="font-bold break-words text-16"
          sx={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}
        >
          {name}
        </Typography>
        <IconButton color="secondary">
          <RefreshSharpIcon className="text-20 text-secondary" />
        </IconButton>
      </Stack>

      <Typography
        className="text-11 text-secondaryLight"
        sx={{ mt: '0 !important' }}
      >
        Refreshed a few seconds ago
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnGap={2}
      >
        <div className="flex items-center justify-center w-1/4 h-40 text-center bg-gray-200 rounded-4">
          <Typography className="text-11">08:00</Typography>
        </div>

        <Button
          variant="outlined"
          className="w-full h-40"
        >
          submit report
        </Button>
      </Stack>
    </Paper>
  );
};

export default TaskQuestionHeader;
