import useOnClickOutside from 'app/hooks/use-on-click-outside';
import useTask from 'app/hooks/use-task';
import clsx from 'clsx';
import { TTaskStatus } from 'models/employee/task.model';
import moment, { Moment } from 'moment';
import { useCallback, useRef } from 'react';

import { CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
  isLoading?: boolean;
  value?: Date | null;
  disabled?: boolean;
  onChange?: (value: Date | null) => void;
  listStatus?: TTaskStatus;
};

const DateInput = ({ isLoading, value, disabled, onChange, listStatus }: Props) => {
  const inputRef = useRef<any>(null);
  const tmpDate = useRef<any>(null);
  const isOpen = useRef(false);
  const { triggerSubmitTask } = useTask();

  const LoadingIcon = useCallback(
    () => (
      <CircularProgress
        className={listStatus === 'overdue' ? 'text-taskOverdue' : undefined}
        size={24}
      />
    ),
    [listStatus],
  );

  useOnClickOutside(inputRef, (event: any) => {
    if (isOpen.current || !tmpDate.current || !tmpDate.current?.isValid()) return;
    onChange?.(tmpDate.current?.toDate() || null);
    tmpDate.current = null;
    triggerSubmitTask(event?.target);
  });

  const handleChange = (momentValue: Moment | null) => {
    if (!momentValue?.isValid()) return;

    if (isOpen.current) {
      onChange?.(momentValue?.toDate() || null);
    } else {
      tmpDate.current = momentValue;
    }
  };

  const handleClose = () => {
    isOpen.current = false;
  };

  const handleOpen = () => {
    isOpen.current = true;
  };

  return (
    <DatePicker
      ref={inputRef}
      value={value ? moment(value, 'DD/MM/YYYY') : null}
      slots={isLoading ? { openPickerButton: LoadingIcon } : {}}
      slotProps={{
        textField: {
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-root': {
              height: '40px',
            },
          },
        },
      }}
      format="DD/MM/YYYY"
      disabled={disabled}
      className={clsx('w-full bg-gray-200 rounded-20', isLoading && 'pointer-events-none')}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};
export default DateInput;
