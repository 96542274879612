import queryClient from 'app/configs/query-client';
import { TReportMetadata, TResponsePagination } from 'models';

import { useExportCSV, useGetList, useGetOne } from '../request';

const reportURL = '/api/manager/histories/list_report';
const exportURL = '/api/manager/histories/export';
const reportDetailURL = '/api/manager/histories/list_report_detail';

export const reportKeys = {
  all: ['report'] as const,
  lists: () => [...reportKeys.all, 'list'] as const,
  list: (filters: any) => [...reportKeys.lists(), { filters }] as const,
  details: () => [...reportKeys.all, 'detail'] as const,
  detail: (filters: any) => [...reportKeys.details(), filters] as const,
};

export const refetchTask = () => queryClient.invalidateQueries(reportKeys.all);

export function useGetReports(filters: any, options?: any) {
  return useGetList<TResponsePagination<TReportMetadata>>([...reportKeys.list(filters)], `${reportURL}`, filters, {
    refetchOnMount: true,
    ...options,
  });
}

export function useExportReport() {
  return useExportCSV(exportURL);
}

export function useGetReportDetail(reportId: number, filters: any, options?: any) {
  return useGetOne<any>([...reportKeys.detail({ ...filters, reportId })], `${reportDetailURL}/${reportId}`, filters, {
    refetchOnMount: true,
    ...options,
  });
}
