import ItemsEditor from 'app/components/cores/items-editor';
import TemplateHeader from 'app/components/templates/header';
import { TTask } from 'models';
import { DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  tabIndex: number;
  onTabChange?: (tabIndex: number) => void;
};

function TemplateItems({ tabIndex, onTabChange }: Props) {
  const methods = useFormContext<TTask>();
  const control = methods?.control;
  const register = methods?.register;
  const itemsAttributesName = 'draftVersion.itemsAttributes';

  const { fields, append, move, update, remove } = useFieldArray({
    control,
    name: itemsAttributesName,
    keyName: 'DOMId',
  });

  const getNewOrder = () => {
    return fields.filter(x => !x._destroy).length + 1;
  };

  const handleAddQuestion = () => {
    append({ prompt: '', type: 'Items::Area', order: getNewOrder(), require: false } as any);
  };

  const handleAddSection = () => {
    append({ type: 'Items::Section', prompt: '', order: getNewOrder() } as any);
  };

  const handleOrderChange = (dropResult: DropResult) => {
    if (!dropResult?.destination) {
      return;
    }
    const { source, destination } = dropResult;

    move(source.index, destination.index);
  };

  const handleRemove = (index: number) => {
    const existingItem = fields[index].id;
    if (existingItem) {
      update(index, { ...fields[index], _destroy: true });
      return;
    }
    remove(index);
  };

  return (
    <>
      <TemplateHeader
        tabIndex={tabIndex}
        onTabChange={onTabChange}
        onAddQuestion={handleAddQuestion}
        onAddSection={handleAddSection}
      />
      <ItemsEditor
        name={itemsAttributesName}
        control={control}
        items={fields}
        register={register}
        onOrderChange={handleOrderChange}
        onRemove={handleRemove}
      />
    </>
  );
}

export default TemplateItems;
