import AnimateWrapper from 'app/components/cores/animate-wrapper';
import React from 'react';

type Props = {
  leftHeader?: JSX.Element;
  rightHeader?: JSX.Element;
};

function BlankHeaderV2({ leftHeader, rightHeader }: Props) {
  return (
    <div className="flex justify-between w-full px-24 mt-20">
      <div className="flex-1 pr-16">{leftHeader && <AnimateWrapper>{leftHeader}</AnimateWrapper>}</div>
      <div>{rightHeader && <AnimateWrapper>{rightHeader}</AnimateWrapper>}</div>
    </div>
  );
}

export default BlankHeaderV2;
