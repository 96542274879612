import queryClient from 'app/configs/query-client';
import { TAction, TActionStatistic, TResponsePagination } from 'models';

import { useAction, useCreate, useDelete, useGetInfiniteList, useGetList, useGetOne, useUpdate } from './request';

const actionURL = '/api/manager/actions';

export const actionKeys = {
  all: ['actions'] as const,
  lists: () => [...actionKeys.all, 'list'] as const,
  list: (filters: any) => [...actionKeys.lists(), { filters }] as const,
  details: () => [...actionKeys.all, 'detail'] as const,
  detail: (id: number) => [...actionKeys.details(), id] as const,
  statistic: (filters: any) => [...actionKeys.all, 'statistic', { ...filters }] as const,
  infinite: () => [...actionKeys.all, 'infinite'] as const,
};

export const refetchAction = async () => {
  return queryClient.invalidateQueries(actionKeys.all);
};

export function useGetActions(filters: any, options = {}) {
  return useGetList<TResponsePagination<TAction>>([...actionKeys.list(filters)], `${actionURL}`, filters, {
    refetchOnMount: false,
    ...options,
  });
}

export function useGetAction(actionId: number) {
  return useGetOne<TAction>(
    [...actionKeys.detail(actionId)],
    `${actionURL}/${actionId}`,
    {},
    {
      enabled: !!actionId,
      cacheTime: 0,
    },
  );
}

export function useCreateAction() {
  return useCreate<{ action: Partial<TAction> }, TAction>(`${actionURL}`, {
    onSuccess: () => refetchAction(),
  });
}

export function useUpdateAction(actionId: number) {
  return useUpdate<{ actionItem: Partial<TAction> }, TAction>(`${actionURL}/${actionId}`, {
    onSuccess: () => refetchAction(),
  });
}

export function useDeactivateAction() {
  return useAction(`${actionURL}`, {
    onSuccess: () => refetchAction(),
  });
}

export function useDeleteAction() {
  return useDelete(actionURL, {
    onSuccess: () => refetchAction(),
  });
}

export function useInfiniteQueryActions(filters: { per_page: number; status: string }, options = {}) {
  const {
    data = [],
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetInfiniteList(actionKeys.infinite(), actionURL, filters, options);

  const convertedData = data.map((item: any) => ({ ...item, value: item.id, label: item.name }));

  return {
    data: convertedData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}

export function useGetActionStatistics(filters: any, options = {}) {
  return useGetList<TActionStatistic>([...actionKeys.statistic(filters)], `${actionURL}/statistics`, filters, {
    refetchOnMount: false,
    ...options,
  });
}
