import MyAccountIcon from 'app/components/cores/icons/my-account';
import MyFacilityIcon from 'app/components/cores/icons/my-facility';
import MyMessageIcon from 'app/components/cores/icons/my-message';
import MyNewsIcon from 'app/components/cores/icons/my-news';
import MyPeopleIcon from 'app/components/cores/icons/my-people';
import MyResourcesIcon from 'app/components/cores/icons/my-resources';
import MyRosterIcon from 'app/components/cores/icons/my-roster';
import MyTaskServiceIcon from 'app/components/cores/icons/my-task-service';
import MyTrainingIcon from 'app/components/cores/icons/my-training';
import SaltIcon from 'app/components/cores/icons/salt';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  link: {
    borderRadius: 48,
    height: 40,
    padding: '0 16px',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:hover > p': {
      maxWidth: 300,
    },
  },
  linkActive: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '& > p': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderBottomWidth: 1,
    },
  },
  title: {
    minWidth: 0,
    maxWidth: 0,
    borderColor: 'transparent',
    transition: 'all 0.5s',
  },
  titleActive: {
    width: 'auto !important',
    maxWidth: 300,
  },
  iconActive: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const icons: { [key: string]: any } = {
  sso: <SaltIcon />,
  sso_my_messages: <MyMessageIcon />,
  my_home: <SaltIcon />,
  salt: <MyFacilityIcon />,
  hris_my_roster: <MyRosterIcon />,
  hris_my_account: <MyAccountIcon />,
  hris: <MyPeopleIcon />,
  internal_lms: <MyTrainingIcon />,
  internal_lms_my_resources: <MyResourcesIcon />,
  rto_lms: <MyTrainingIcon />,
  news: <MyNewsIcon className="ml-2 text-20" />,
  task: <MyTaskServiceIcon className="ml-2 text-18" />,
};

type Props = {
  items?: any;
};

function MenuTablet({ items }: Props) {
  const classes = useStyles();

  const isActive = (kind: string) => {
    return kind === 'task';
  };

  return (
    <>
      {items?.map((item: any) => (
        <a
          key={item.order}
          href={item.url}
          target="_self"
          className={clsx(
            'inline-flex items-center whitespace-no-wrap mx-4 overflow-hidden',
            classes.link,
            isActive(item.kind) && classes.linkActive,
          )}
        >
          <div className={clsx('mr-8', isActive(item.kind) && classes.iconActive)}>{icons[item.kind]}</div>
          <Typography
            className={clsx(
              'font-400 secondaryMain uppercase border-b text-13 overflow-hidden',
              classes.title,
              isActive(item.kind) && classes.titleActive,
            )}
          >
            {item.name}
          </Typography>
        </a>
      ))}
    </>
  );
}

export default MenuTablet;
