import { TTab } from 'models';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function useTab(initTab: number, tabs?: TTab[]) {
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(getDefaultTab(initTab, tabs));

  useEffect(() => {
    if (!tabs?.length) {
      const tab = getTabQuery();

      if (tab) {
        history.replace(location.pathname);
      }
      return;
    }

    const tabName = tabs?.find((t: TTab) => t.value === tabValue)?.tab;
    if (!tabName) return;

    history.replace(`${location.pathname}?tab=${tabName}`);

    // eslint-disable-next-line
  }, [tabValue, tabs]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function getTabQuery() {
    const { tab } = queryString.parse(location.search) || {};
    return tab;
  }

  function getDefaultTab(_initTab: number, _tabs?: TTab[]) {
    if (!_tabs?.length) return _initTab;

    const tab = getTabQuery();
    if (!tab) return _initTab;

    const nextTabValue = _tabs?.find((t: TTab) => t.tab === tab)?.value;
    if (nextTabValue === undefined) return _initTab;

    return nextTabValue;
  }

  return {
    tabValue,
    setTabValue,
    handleTabChange,
  };
}

export default useTab;
