import { LOCALE_DATE_FORMAT, THIS_YEAR } from 'constants/index';
import { formatTaskItemDate } from 'domains/employee/task.domain';
import moment, { Moment } from 'moment';
import mmtz, { MomentInput } from 'moment-timezone';

export function formatDate(date?: string | Date | null, format: string = 'DD/MM/YYYY', withTimezone?: boolean) {
  if (!date) return '';
  const newFormat = withTimezone ? `${format}Z` : format;
  return moment(date).format(newFormat);
}

export function convertDateStringToStringV2(dateString?: string): string {
  if (!dateString || dateString === '') return '';

  const date = moment(new Date(dateString));
  if (!date.isValid()) return '';

  return date.format('DD/MM/YYYY');
}

export function getDateStringFromDayMonth(day?: number, month?: number, year?: number) {
  return `${day || 1}/${month || 1}/${year || THIS_YEAR}`;
}

export function formatDateWithTimezone(date?: string | Date, format?: string, withTimezone?: boolean) {
  if (!date) {
    return '';
  }

  const newFormat = withTimezone ? `${format}Z` : format;
  return moment(date).format(newFormat);
}

export function getStartOfWeek(date?: Date | string) {
  return moment(date).startOf('isoWeek').toDate(); // isoWeek: start the week from monday
}

export function getEndOfWeek(date?: Date | string) {
  return moment(date).endOf('isoWeek').toDate();
}

export function getPrevWeek(date?: Date | string) {
  const newPrevWeek = new Date(date!);
  newPrevWeek.setDate(newPrevWeek.getDate() - 7);
  return newPrevWeek;
}

export function getNextWeek(date?: Date | string) {
  const newNextWeek = new Date(date!);
  newNextWeek.setDate(newNextWeek.getDate() + 7);
  return newNextWeek;
}

export function getPrevDate(date?: Date | string) {
  const prevDate = new Date(date!);
  prevDate.setDate(prevDate.getDate() - 1);
  return prevDate;
}

export function getNextDate(date?: Date | string) {
  const prevDate = new Date(date!);
  prevDate.setDate(prevDate.getDate() + 1);
  return prevDate;
}

export const displayTime = (displayAt: string, dueAt: string) => {
  const diff = moment(dueAt).diff(moment(displayAt), 'days');

  if (!Number.isNaN(diff) && diff > 0) {
    return `${formatTaskItemDate(displayAt)} ${formatDate(displayAt, 'DD/MM')} - ${formatTaskItemDate(
      dueAt,
    )} ${formatDate(dueAt, 'DD/MM')}`;
  }

  return [formatTaskItemDate(displayAt), formatTaskItemDate(dueAt)].filter(Boolean).join(' - ');
};

export const delay = async (seconds: number = 0) => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise(resolve => setTimeout(resolve, seconds));
};

export const isSameDates = (startDate: string | Date | Moment, endDate: string | Date | Moment) => {
  const sd = moment(startDate).format(LOCALE_DATE_FORMAT);
  const ed = moment(endDate).format(LOCALE_DATE_FORMAT);
  return sd === ed;
};

export const isToday = (date: string | Date | Moment) => {
  const now = moment();
  return isSameDates(date, now);
};

export const endOfDay = (date: string | Date | Moment) => {
  return moment(date).endOf('day');
};

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDateByLocationTimezone = (
  date: MomentInput,
  locationTz: string,
  format: string = LOCALE_DATE_FORMAT,
) => {
  if (!locationTz) return formatDate(date as string | Date | null, format);

  return mmtz(date).tz(locationTz).format(format);
};

export const convertDateByTimezone = (date: string, tz: string) => {
  return new Date(new Date(date).toLocaleString('en-US', { timeZone: tz }));
};

export function getServerTime(date?: string) {
  if (!date) return '';
  const time = date.split('T')?.[1].substring(0, 5);
  const [hours, minutes] = time.split(':').map(Number);
  const serverTime = new Date();
  serverTime.setHours(hours, minutes);
  return serverTime;
}

export function getServerDate(date?: string) {
  if (!date) return '';
  const dateString = date.split('T')?.[0];
  const serverDate = new Date(dateString);
  return serverDate;
}

// This convert a date but keep local timezone
// e.g. 2024-10-04T13:34:20.324+10:00 -> 2024-10-04T13:34:20.324+07:00
export const adjustToLocalTimezone = (dateString: string) => {
  if (!dateString) return new Date();
  const localTimezone = moment().format('Z');
  const formattedDate = moment.parseZone(dateString).utcOffset(localTimezone, true);
  return formattedDate?.toISOString();
};

export const isBeforeToday = (date: string | Date | Moment) => {
  return moment(date).isBefore(moment().startOf('day'));
};
