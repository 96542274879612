import { YesNoButton } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type Props = {
  data: TItem;
  readOnly?: boolean;
  name: string;
  listId: number;
  view: TQuestionLayoutView;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const YesNo = ({ data, readOnly = false, name, listId, view, isAudit, canAuditInteractions = true }: Props) => {
  const { resetField, setError, setValue, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const { showError, showSuccess } = useShowMessage();
  const isRequired = value?.item?.required;
  const validAnswer = value?.answer;

  const { mutateAsync: editHistoryTask, isLoading } = useEditHistoryTask({
    taskId: listId,
    responseId: value?.id,
  });

  const getValue = () => {
    if (value?.answer === 'true') return true;
    if (value?.answer === 'false') return false;
    return null;
  };

  const handleAnswerChange = (nextAnswer: boolean | null) => {
    const answerString = nextAnswer !== null ? `${nextAnswer}` : null;
    onChange({ ...value, answer: answerString });
  };

  useEffect(() => {
    if (isRequired && value?.answer) clearErrors(name);
  }, [value?.answer, isRequired, clearErrors, name]);

  const editTask = async (payload: any) => {
    try {
      const res = await editHistoryTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (_: unknown) {
      showError('Could not submit the answer');
    }
  };

  const checkAnswerIsValid = () => {
    if (isRequired && !validAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    editTask(answerPayload);
  };

  return (
    <QuestionLayoutMapper
      question={data}
      preview={true}
      task={value}
      name={name}
      onSaveEdit={handleSave}
      view={view}
      isLoading={isLoading}
      isAudit={isAudit}
      canAuditInteractions={canAuditInteractions}
    >
      <YesNoButton
        disabled={readOnly}
        value={getValue()}
        status={value?.status}
        onChange={handleAnswerChange}
      />
    </QuestionLayoutMapper>
  );
};

export default YesNo;
