import React from 'react';
import { clsxm } from 'utils/clsxm';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

type Props = {
  isSelected?: boolean;
  isIndeterminate?: boolean;
  title?: string;
  selectedTitle?: string;
  rightChildren?: React.ReactNode;
  isDisabled?: boolean;
  isSelectAll?: boolean;
  className?: string;
  classes?: any;
  onChange?: (event: any) => void;
};

function TransferHeader({
  isSelected,
  isIndeterminate,
  title,
  selectedTitle,
  rightChildren,
  isDisabled,
  isSelectAll = true,
  className,
  classes,
  onChange,
}: Props) {
  return (
    <div className={clsxm('flex flex-row w-full px-24 py-4', className)}>
      <FormControlLabel
        control={
          isSelectAll ? (
            <Checkbox
              disabled={isDisabled}
              indeterminate={isIndeterminate}
              checked={isSelected}
              checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
              icon={<CheckBoxOutlineBlankOutlinedIcon />}
              indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon color="primary" />}
            />
          ) : (
            <></>
          )
        }
        label={
          <div>
            <div className="flex items-center">
              <Typography className="text-13">{title}</Typography>
            </div>
            <Typography className="text-13 text-secondaryLight">{selectedTitle}</Typography>
          </div>
        }
        onChange={onChange}
        classes={{
          root: classes?.labelRoot,
        }}
      />
      <div className="flex items-center ml-auto">{rightChildren}</div>
    </div>
  );
}

export default TransferHeader;
