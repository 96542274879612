import clsx from 'clsx';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { pad } from 'utils/number';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  value: { startDate?: Date; endDate?: Date };
  onChange?: ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => void;
};

export function StaticDateRangePicker({ value, onChange }: Props) {
  const classes = useStyles();

  const handleChange = (dateObj: DateObject[]) => {
    const sDate = formatDateObject(dateObj?.[0]);
    const eDate = formatDateObject(dateObj?.[1]);
    if (sDate && eDate) {
      onChange?.({ startDate: new Date(sDate), endDate: new Date(eDate) });
    }
  };

  const formatDateObject = (dateObj: DateObject) => {
    if (!dateObj) return undefined;
    const { year, month, day } = dateObj;
    return `${year}-${pad(month.number)}-${pad(day)}`;
  };

  return (
    <Calendar
      range={true}
      value={[value?.startDate || new Date(), value?.endDate || new Date()]}
      weekStartDayIndex={1}
      className={clsx('w-full', classes.calendar)}
      headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
      monthYearSeparator=" "
      renderButton={(direction: any, handleClick: any) => (
        <IconButton
          size="small"
          onClick={handleClick}
        >
          {direction === 'right' ? (
            <ChevronRightOutlinedIcon className="text-secondaryMain text-24" />
          ) : (
            <ChevronLeftOutlinedIcon className="text-secondaryMain text-24" />
          )}
        </IconButton>
      )}
      onChange={handleChange}
    />
  );
}

const useStyles = makeStyles((theme: any) => ({
  calendar: {
    boxShadow: 'none !important',
    paddingLeft: 16,
    paddingRight: 16,

    '& *': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },

    '& .rmdp-header': {
      borderBottom: `1px solid #ECEDEE`,
      padding: 0,
      marginTop: 0,
      paddingBottom: 10,
      '& button': {
        padding: 2,
        marginRight: -10,
      },
    },
    '& .rmdp-header-values': {
      margin: 0,
      flex: 1,
      textAlign: 'left',
      fontSize: '1.4rem',
      fontWeight: 600,
      '& span': {
        paddingLeft: 0,
      },
      '& + button': {
        marginRight: '0 !important',
      },
    },
    '& .rmdp-week-day': {
      flex: 1,
      color: theme.palette.secondary.light,
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    '& .rmdp-day': {
      width: 38,
      height: 38,
      '& .sd': {
        color: theme.palette.secondary.main,
        fontSize: '1.6rem',
        fontWeight: 400,
        '&:hover': {
          backgroundColor: '#FFF6FA !important',
          color: `${theme.palette.secondary.main} !important`,
        },
      },
    },
    '& .rmdp-today': {
      '& .sd': {
        backgroundColor: 'unset',
        borderRadius: '50%',
        border: '1px solid #E0115F',
      },
    },
    '& .rmdp-range': {
      backgroundColor: '#E0115F',
      boxShadow: 'none',
      '& .sd': {
        color: 'white !important',
        fontWeight: '600 !important',
      },
    },
  },
}));
