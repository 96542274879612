/* eslint-disable react/no-unstable-nested-components */
import IndexTable from 'app/components/cores/index-table';
import GeneratingModal from 'app/components/cores/modals/generating';
import { useExportReport } from 'app/hooks/api/manager/report';
import useExport from 'app/hooks/use-export';
import { Paths } from 'constants/index';
import _ from 'lodash';
import { TReportMetadata } from 'models';
import { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { generateURLWithFilter, getQueryObject } from 'utils/url';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';

type Props = {
  reports: TReportMetadata[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  filters: any;
  setFilters: any;
  onFetchData: (params: any) => void;
  ignoreFilters?: string[];
  reportFilters: any;
  hasSelectedLocation?: boolean;
};

function ReportList({
  reports,
  totalDataCount,
  isLoading,
  searchKey,
  filters,
  setFilters,
  pageCount,
  ignoreFilters,
  onFetchData,
  reportFilters,
  hasSelectedLocation,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const { isModalOpen, handleCloseModal } = useExport({
    useQueryHook: useExportReport,
  });

  function transformFilter(filter: any) {
    const nextFilter = _.cloneDeep(filter);
    return _.pick(nextFilter, ['q[location_id_in]', 'q[display_at_gteq]', 'q[display_at_lteq]']);
  }

  const columns = useMemo(
    () => {
      return [
        {
          Header: 'List',
          accessor: 'name',
          id: 'name',
          sortable: false,
          width: '60%',
          Filter: <></>,
          Cell: ({ row, cell }: { row: Row<TReportMetadata>; cell: Cell<TReportMetadata> }) => {
            const id = row.original?.id;
            const reportDetailLink = generateReportDetailLink(id);
            return (
              <Link
                onClick={e => e.stopPropagation()}
                to={reportDetailLink}
              >
                <Typography className="cursor-pointer font-600">{cell.value}</Typography>
              </Link>
            );
          },
        },
        {
          Header: 'Locations',
          accessor: 'locations',
          id: 'locations',
          sortable: false,
          width: '15%',
          Filter: <></>,
          Cell: ({ cell }: { cell: Cell<TReportMetadata> }) => {
            return <Typography>{cell.value.join(', ')}</Typography>;
          },
        },
        {
          Header: 'No. Employee Lists',
          accessor: 'total',
          id: 'total',
          sortable: false,
          width: '10%',
          Filter: <></>,
        },
        {
          Header: () => {
            return <div className="flex justify-end pr-32">Action</div>;
          },
          id: 'action',
          sortable: false,
          width: '15%',
          Cell: ({ row }: { row: Row<TReportMetadata> }) => {
            const id = row.original?.id;
            const reportDetailLink = generateReportDetailLink(id);
            return (
              <div className="flex items-center justify-end">
                <Tooltip title="View">
                  <IconButton
                    component={Link}
                    to={reportDetailLink}
                    onClick={e => e.stopPropagation()}
                    className="mr-24"
                  >
                    <VisibilityOutlinedIcon className="text-20 text-secondaryLight" />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportFilters],
  );

  function generateReportDetailLink(reportId: number) {
    const queryObject = getQueryObject(window.location.search);
    const nextQueryObject = transformFilter(queryObject);
    const queryString = generateURLWithFilter('', nextQueryObject);
    return `${Paths.history}/report/${reportId}?${queryString}`;
  }

  const handleClickRow = (row: Row<any>) => {
    // eslint-disable-next-line react/prop-types
    const id = row.original?.id;
    const reportDetailLink = generateReportDetailLink(id);
    history.push(reportDetailLink);
  };

  return (
    <div className="relative flex flex-col w-full px-12">
      <GeneratingModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <IndexTable
        columns={columns as Column<any>[]}
        pageCount={pageCount}
        data={reports}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
        ignoreFilters={ignoreFilters}
        withSelection={false}
        onRowClick={handleClickRow}
      />
      {!hasSelectedLocation && (
        <div className="absolute top-0 left-0 right-0 text-center pt-128">
          <Typography className="text-center text-13 font-400">Please select a location</Typography>
        </div>
      )}
    </div>
  );
}

export default ReportList;
