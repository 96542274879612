import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { ControlledTextInput } from 'app/components/cores/inputs/text';
import { taskKeys } from 'app/hooks/api/tasks';
import useQRCode from 'app/hooks/use-qr-code';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { Control, useController, useFormContext, useWatch } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import AttributeContainer from './attribute-container';

type Props = {
  name: string;
  control?: Control;
};

function QRCodeField({ name, control }: Props) {
  const dispatch = useDispatch();
  const code = useWatch({ control, name: `${name}.code` }) || '';
  const id = useWatch({ control, name: `${name}.id`, defaultValue: null });
  const description = useWatch({ control, name: `${name}.description` }) || '';
  const { state: isConfirmed, setActive: confirm, setInactive: clearConfirm } = useToggle(false);
  const status = useWatch({ control, name: 'status' });
  const params = useParams<{ listId: string }>();
  const queryClient = useQueryClient();
  const { setValue } = useFormContext();

  const taskDetails = queryClient.getQueryData<{
    scannableCodeAttributes: { code: string };
  }>(taskKeys.detail(+params.listId));
  const defaultCode = taskDetails?.scannableCodeAttributes?.code;

  const {
    field: { onChange },
  } = useController({
    control,
    name: `${name}.code`,
  });

  const { printAreaRef, printCode, generateCode, downloadCode } = useQRCode({
    downloadElement: 'qrcode',
    downloadFileName: code,
  });

  const canShowConfirmDialog = status === 'published' && !isConfirmed && defaultCode && id;

  const handleCloseDialog = () => {
    dispatch(closeDialog({}));
  };

  const handleConfirm = (cb?: () => void) => () => {
    confirm();
    dispatch(closeDialog({}));
    cb?.();
    setValue(`${name}.id`, null);
    clearConfirm();
  };

  const handleShowConfirmDialog = (cb?: () => void) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Generate New QR Code"
            onClose={handleCloseDialog}
            onConfirm={handleConfirm(cb)}
            cancelButtonLabel="Cancel"
            confirmButtonLabel="Generate"
            message="The current code will be invalid. Are you sure you want to generate a new code?"
          />
        ),
      }),
    );
  };

  const handleGenerateCode = () => {
    if (canShowConfirmDialog) {
      handleShowConfirmDialog(() => onChange(generateCode(20)));
      return;
    }
    onChange(generateCode(20));
  };

  const handleKeyDown = () => {
    if (canShowConfirmDialog) {
      handleShowConfirmDialog();
    }
  };

  return (
    <div className="max-w-512">
      <div className="flex items-center w-full space-x-8">
        <div className="flex-1 ">
          <ControlledTextInput
            name={`${name}.code`}
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 ',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-11',
              },
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <Button
          disableElevation={true}
          color="primary"
          variant="contained"
          className="h-24 px-12 capitalize text-11 font-500 rounded-4"
          onClick={handleGenerateCode}
        >
          Generate Code
        </Button>
      </div>

      <div className="mt-16">
        <AttributeContainer label="QR Code Description">
          <ControlledTextInput
            name={`${name}.description`}
            placeholder="Add QR Code description here"
            control={control}
            variant="filled"
            className="h-full shadow"
            multiline={true}
            minRows={3}
            InputProps={{
              className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
              disableUnderline: true,
              classes: {
                input: 'p-8 text-13',
              },
            }}
            value={description}
          />
        </AttributeContainer>
      </div>

      <div ref={printAreaRef}>
        <div
          style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}
          className="pt-16 pb-8 print-content-container"
        >
          <QRCode
            id="qrcode"
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={code}
            viewBox="0 0 256 256"
          />
        </div>
        <Typography className="text-center text-13 font-500">{code}</Typography>
        <Typography className="text-center break-words text-13 print-content-description">{description}</Typography>
      </div>

      <div className="flex items-center justify-center pt-16 space-x-8">
        <Button
          disableElevation={true}
          color="primary"
          variant="outlined"
          className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
          onClick={downloadCode}
        >
          Download
        </Button>
        <Button
          disableElevation={true}
          color="primary"
          variant="contained"
          className="h-24 px-12 capitalize w-88 min-w-88 text-11 font-500 rounded-4"
          onClick={printCode}
        >
          Print
        </Button>
      </div>
    </div>
  );
}

export default QRCodeField;
