import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { TTask } from 'models';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Typography } from '@mui/material';

import TaskDisplayTimesSetting from './display-times-setting-v2';
import TaskFrequencySetting from './frequency-setting';
import FrequencyContainer from './frequency/container';

type Props = {
  name?: string;
  methods: UseFormReturn<TTask>;
  control: any;
};

function DisplayFrequenciesSetting({ name, methods, control }: Props) {
  const dispatch = useDispatch();
  const displayFrequencyName = !name ? 'frequencies' : `${name}.frequencies`;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `${displayFrequencyName}` as const,
    keyName: 'DOMId',
  });

  const handleAddFrequency = () => {
    append({});
  };

  const handleRemoveFrequency = (index: number) => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Delete frequency"
            message="Are you sure you want to delete this frequency?"
            statusVariant="warning"
            confirmButtonLabel="Delete"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              remove(index);
            }}
          />
        ),
      }),
    );
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <Typography className="mb-16 text-medium-dark">Frequency Setting</Typography>
        <Button
          className="h-32 px-10 capitalize whitespace-no-wrap text-12 font-500"
          color="primary"
          variant="contained"
          onClick={handleAddFrequency}
        >
          New Frequency
        </Button>
      </div>

      <div>
        {fields.map((value: Record<'DOMId', string>, index: number) => {
          return (
            <FrequencyContainer
              name={`${displayFrequencyName}[${index}]`}
              key={value.DOMId}
              index={index}
              onRemove={handleRemoveFrequency}
              methods={methods}
            >
              <div>
                <TaskFrequencySetting
                  methods={methods}
                  name={`${displayFrequencyName}[${index}]`}
                />
                <TaskDisplayTimesSetting
                  methods={methods}
                  name={`${displayFrequencyName}[${index}]`}
                  index={index}
                />
              </div>
            </FrequencyContainer>
          );
        })}
      </div>
    </div>
  );
}

export default DisplayFrequenciesSetting;
