import { ComponentProps } from 'react';
import { Control, useController } from 'react-hook-form';

import InputNumber from './input-number';

type Props = {
  name: string;
  control: Control | undefined;
} & ComponentProps<typeof InputNumber>;

const ControlledInputNumber = ({ control, name, ...otherProps }: Props) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <InputNumber
      value={value}
      onChange={onChange}
      {...otherProps}
    />
  );
};

export default ControlledInputNumber;
