import { SingleSelect } from 'app/components/cores/select';
import { generateUntilAfterLabel, generateUntilAfterOptions } from 'domains/display-time.domain';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { clsxm } from 'utils/clsxm';

import { Typography, useTheme } from '@mui/material';

type Props = {
  frequency: number;
  name: string;
  targetName: string;
  control: Control<any>;
  classes?: {
    root: string;
  };
};

function UntilAfter({ frequency, name, targetName, control, classes }: Props) {
  const theme = useTheme();
  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  const after = useWatch({ name, control });
  const target = useWatch({ name: targetName, control });

  const label = useMemo(() => generateUntilAfterLabel(frequency, target), [target, frequency]);
  const options = useMemo(() => generateUntilAfterOptions(frequency), [frequency]);

  const generateValue = () => _.find(options, { value: after }) || options[0];

  const handleValueChange = (item: any) => {
    onChange(item.value);
  };

  return (
    <div className={clsxm('flex', classes?.root)}>
      <Typography className="w-12 mt-12 mr-12 text-center font-500 text-11 text-secondaryLight">{label}</Typography>
      <div className="w-160">
        <SingleSelect
          minMenuHeight={400}
          menuPlacement="auto"
          placeholder="Select after"
          value={generateValue()}
          options={options}
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              border: 0,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 4,
              height: 40,
              minHeight: 40,
              boxShadow: 'none',
              cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
            }),
            singleValue: (baseStyles: any) => ({
              ...baseStyles,
              '& *': {
                fontWeight: '400 !important',
              },
            }),
            option: (baseStyles: any) => ({
              ...baseStyles,
              '& *': {
                fontWeight: '400 !important',
              },
            }),
          }}
          onChange={handleValueChange}
        />
      </div>
    </div>
  );
}

export default UntilAfter;
