/* eslint-disable react/no-unstable-nested-components */
import ConfirmDialog from 'app/components/cores/confirm-dialog';
import IndexTable from 'app/components/cores/index-table';
import SelectFilter from 'app/components/cores/index-table/filters/select';
import StatusBadge from 'app/components/cores/tasks/status-badge';
import { useDeleteAction, useUpdateAction } from 'app/hooks/api/actions';
import useShowMessage from 'app/hooks/use-show-message';
import useToggle from 'app/hooks/use-toggle';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { TAction } from 'models';
import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Cell, Column, Row } from 'react-table';
import { convertDateStringToString } from 'utils/string';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';

import ActionFormModal from './action-form-modal';
import ActionHeaderFilter from './header-filter';

type Props = {
  actions: TAction[];
  pageCount: number;
  totalDataCount: number;
  isLoading?: boolean;
  searchKey: string;
  onFetchData: (params: any) => void;
  filters: any;
  setFilters: any;
  actionFilters: any;
  setActionFilters: Dispatch<SetStateAction<any>>;
};

function ActionList({
  actions,
  pageCount,
  totalDataCount,
  isLoading,
  searchKey,
  onFetchData,
  actionFilters,
  setActionFilters,
  filters,
  setFilters,
}: Props) {
  const history = useHistory();
  const location = useLocation();

  const statusOptions = [
    { label: 'All', value: '_all' },
    { label: 'Completed', value: 'completed' },
    { label: 'Completed Late', value: 'completed_late' },
    { label: 'Pending', value: 'pending' },
    { label: 'Overdue', value: 'overdue' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'description',
        sortable: true,
        width: '20%',
        Cell: ({ cell, row }: { cell: Cell<TAction>; row: Row<TAction> }) => {
          const shortString = cell.value.slice(0, 100);
          return (
            <Typography className="overflow-hidden font-500 text-13">
              {row.original.important && (
                <StarIcon
                  className="mb-4 mr-4 text-20"
                  sx={{ color: yellow[700] }}
                />
              )}
              {shortString}
              {shortString.length !== cell.value.length && '...'}
            </Typography>
          );
        },
      },
      {
        Header: 'List',
        accessor: 'list',
        id: 'root_action_task_root_name',
        sortable: true,
        width: '20%',
        Cell: ({ cell }: { cell: Cell<TAction> }) => {
          return <Typography className="font-500 text-13">{cell.value?.name}</Typography>;
        },
      },
      {
        Header: () => <Typography className="truncate max-w-104 min-w-104 text-12">Assigned By</Typography>,
        accessor: 'author',
        id: 'author_full_name',
        sortable: true,
        width: '10%',
        Cell: ({ cell }: { cell: Cell<TAction> }) => (
          <Typography className="font-400 text-13">{cell.value?.fullName}</Typography>
        ),
      },
      {
        Header: () => <Typography className="truncate max-w-104 min-w-104 text-12">Completed By</Typography>,
        accessor: 'completedBy',
        sortable: true,
        width: '10%',
        Filter: <div />,
        Cell: ({ cell }: { cell: Cell<TAction> }) => (
          <Typography className="font-400 text-13">{cell.value?.fullName}</Typography>
        ),
      },
      {
        Header: () => <Typography className="truncate max-w-80 min-w-80 text-12">Due Date</Typography>,
        accessor: 'expireAt',
        sortable: true,
        width: '10%',
        Filter: () => (
          <ActionHeaderFilter
            setActionFilters={setActionFilters}
            actionFilters={actionFilters}
            inputClass="!w-128"
            startDateKey="q[expire_at_gteq]"
            endDateKey="q[expire_at_lteq]"
          />
        ),
        Cell: ({ cell }: { cell: Cell<TAction> }) => (
          <Typography className="font-400">{convertDateStringToString(cell.value)}</Typography>
        ),
      },
      {
        Header: () => <Typography className="truncate max-w-104 min-w-104 text-12">Completion Date</Typography>,
        accessor: 'completedAt',
        sortable: true,
        width: '10%',
        Filter: () => (
          <ActionHeaderFilter
            setActionFilters={setActionFilters}
            actionFilters={actionFilters}
            inputClass="!w-128"
          />
        ),
        Cell: ({ cell }: { cell: Cell<TAction> }) => (
          <Typography className="font-400">{convertDateStringToString(cell.value)}</Typography>
        ),
      },
      {
        Header: 'Status',
        accessor: 'submitStatus',
        id: 'todo_submit_status',
        sortable: true,
        width: '10%',
        className: 'capitalize',
        options: statusOptions,
        Filter: SelectFilter,
        Cell: ({ cell }: { cell: Cell<TAction> }) => {
          const status = cell.value;
          return <StatusBadge status={status} />;
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Filter: <></>,
        width: '10%',
        Cell: ({ row }: { row: Row<TAction> }) => {
          const action = row.original;
          const { mutate: deleteAction, isLoading: isDeletingAction } = useDeleteAction();
          const { mutate: updateAction, isLoading: isUpdatingAction } = useUpdateAction(action.id);
          const { showSuccess, showError } = useShowMessage();
          const {
            state: isOpenActionFormModal,
            setActive: openActionFormModal,
            setInactive: hideActionFormModal,
          } = useToggle();
          const dispatch = useDispatch();

          const handleSubmitAction = (data: any) => {
            updateAction(
              { actionItem: data },
              {
                onSuccess: () => hideActionFormModal(),
              },
            );
          };

          const handleClickEdit = () => {
            openActionFormModal();
          };

          const handleClickDelete = () => {
            dispatch(
              openDialog({
                children: (
                  <ConfirmDialog
                    title="Delete Action"
                    message="Are you sure you want to delete this action?"
                    onClose={() => dispatch(closeDialog({}))}
                    onConfirm={() => {
                      deleteAction(
                        { id: action.id },
                        {
                          onSuccess: () => {
                            showSuccess('Action has been deleted successfully');
                          },
                          onError: () => {
                            showError('Could not delete action');
                          },
                        },
                      );

                      dispatch(closeDialog({}));
                    }}
                    cancelButtonLabel="No, Cancel"
                    confirmButtonLabel="Yes, Delete"
                    isLoading={isDeletingAction}
                  />
                ),
              }),
            );
          };

          return (
            <div className="flex items-center justify-center gap-16">
              <IconButton
                size="small"
                onClick={handleClickEdit}
              >
                <EditIcon className="text-16 text-secondaryLight" />
              </IconButton>

              <IconButton
                size="small"
                onClick={handleClickDelete}
              >
                <DeleteIcon className="text-16 text-secondaryLight" />
              </IconButton>

              {isOpenActionFormModal && (
                <ActionFormModal
                  isOpen={isOpenActionFormModal}
                  onClose={hideActionFormModal}
                  value={action as any}
                  onSubmit={handleSubmitAction}
                  isLoading={isUpdatingAction}
                />
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [location],
  );

  return (
    <div className="flex flex-col w-full px-12 pt-8">
      <IndexTable
        columns={columns as Column<any>[]}
        data={actions}
        pageCount={pageCount}
        loading={!!isLoading}
        totalDataCount={totalDataCount}
        searchText=""
        searchKey={searchKey}
        location={location}
        history={history}
        onFetchData={onFetchData}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}

export default ActionList;
