import { TLocationOverview, TResponsePagination } from 'models';

import { useGetList, useGetOne } from './request';

export const performanceKeys = {
  all: ['performance'] as const,
  lists: (locationId: number) => [...performanceKeys.all, 'list', locationId] as const,
  list: (locationId: number, filters: any) => [...performanceKeys.lists(locationId), { filters }] as const,
  details: (locationId: number) => [...performanceKeys.all, 'detail', locationId] as const,
  detail: (locationId: number, id: number) => [...performanceKeys.details(locationId), id] as const,
  overview: (locationId: number) => [...performanceKeys.all, 'overview', locationId] as const,
};

export function useGetOverview(id: number, filter?: any) {
  return useGetOne<TLocationOverview>(
    [...performanceKeys.overview(id), { filter }],
    `/api/admin/locations/${id}/performance`,
    { ...filter },
    { enabled: !!id },
  );
}

export function useGetStatistics(locationId: string | number, type: any, filters?: any, options?: any) {
  return useGetList<TResponsePagination<any>>(
    [...performanceKeys.list(Number(locationId), filters), type],
    `/api/admin/locations/${locationId}/statistics/${type}`,
    { ...filters },
    { enabled: !!type, ...options },
  );
}

export function useGetStatisticsDetailsByType(
  locationId: string | number,
  { type, id, filter }: { type: string; id: number; filter: any },
  options?: any,
) {
  const enabled = !!locationId && !!type && !!id;

  return useGetList<TResponsePagination<any>>(
    [...performanceKeys.detail(+locationId, +id), 'list', type, { filter }],
    `/api/admin/locations/${locationId}/statistics/${type}/${id}`,
    { ...filter },
    { enabled, ...options },
  );
}

export function useGetListStatisticsInDetails(
  locationId: string | number,
  {
    roleId,
    detailId,
    active,
    filter,
    type,
  }: { roleId?: number; detailId: number; active: boolean; filter: any; type: string },
  options?: any,
) {
  return useGetList<TResponsePagination<any>>(
    [...performanceKeys.detail(+locationId, +detailId), 'list-in-details', type, roleId, { filter }],
    `/api/admin/locations/${locationId}/statistics/${type === 'role' ? type : 'user'}/${detailId}/list/${roleId}`,
    { ...filter },
    {
      enabled: !!locationId && !!roleId && !!detailId && active,
      keepPreviousData: true,
      ...options,
    },
  );
}
