import { TItem } from 'models';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';

type Props = {
  attachments?: TItem['attachmentsAttributes'];
};

const AttachmentViewer = ({ attachments }: Props) => {
  if (attachments?.length === 0) {
    return null;
  }

  const files = attachments?.[0].files || [];

  return (
    <>
      {files.map(file => (
        <button
          className="flex items-center justify-between w-full px-24 bg-gray-200 space-x-4 min-h-40 rounded-20"
          key={file.id}
          type="button"
        >
          <Typography className="underline text-13 line-clamp-1">{file.name}</Typography>
          <OpenInNewIcon className="text-20" />
        </button>
      ))}
    </>
  );
};

export default AttachmentViewer;
