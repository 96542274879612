import useToggle from 'app/hooks/use-toggle';
import { getStatusNameByStatus } from 'domains/task.domain';
import _isEmpty from 'lodash/isEmpty';
import { TItem, TResponse } from 'models';
import moment from 'moment';
import { PropsWithChildren, useState } from 'react';

import CachedIcon from '@mui/icons-material/Cached';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import AttachmentViewer from './attachment-viewer';
import TaskComment from './task-comment';

type Props = {
  question: TItem;
  preview: boolean;
  task?: Omit<TResponse, 'item'>;
  canReset?: boolean;
};

const menuItemStyle = {
  fontWeight: 600,
  fontSize: 11,
};

const TemplateQuestionLayout = ({ question, children, preview, task, canReset = false }: PropsWithChildren<Props>) => {
  const { prompt, description, notApplicable: allowNA } = question;
  const { status, user, comment = '', updatedAt, location } = task || {};
  const { state: isOpenComment, setToggle: toggleComment } = useToggle();
  const isCode = ['Items::BarCode', 'Items::QrCode'].includes(question.type);

  const statusName = getStatusNameByStatus(status);

  const attachments = question.attachmentsAttributes || [];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      {/* Start of prompt */}
      <div className="flex flex-row items-center justify-between gap-16 pb-4">
        {prompt && <Typography className="font-bold text-13">{prompt}</Typography>}
      </div>
      {/* End of prompt */}

      {/* Start of description */}
      {description && <Typography className="text-11">{description}</Typography>}
      {/* End of description */}

      {/* attachments */}
      {attachments && attachments?.length > 0 && <AttachmentViewer attachments={attachments} />}
      {/* end of attachments */}

      {/* start of answer helper text */}
      <div className="flex items-center justify-between">
        <Typography className="text-secondaryLight text-11 font-400">
          Your Answer/Action {question.required && '*'}
        </Typography>

        <div className="flex items-center justify-between">
          {canReset && (
            <IconButton size="small">
              <CachedIcon className="text-20 text-secondaryMain" />
            </IconButton>
          )}

          {allowNA && (
            <>
              <IconButton
                onClick={handleClick}
                size="small"
              >
                <MoreVertIcon className="text-18 text-secondaryMain" />
              </IconButton>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <MenuItem sx={menuItemStyle}>Applicable</MenuItem>
                <MenuItem sx={menuItemStyle}>Not Applicable</MenuItem>
              </Menu>
            </>
          )}

          {!isCode && (
            <IconButton
              onClick={toggleComment}
              size="small"
            >
              <CommentOutlinedIcon className="mt-2 text-18 text-secondaryMain" />
            </IconButton>
          )}
        </div>
      </div>
      {/* end of answer helper text */}

      {/* Start of answer */}
      <div>{children}</div>
      {/* End of answer */}

      {/* Start of Status */}

      <div>
        <Box sx={{ mt: '8px' }}>
          {status && (
            <Typography
              className="font-bold capitalize text-secondaryLight text-11"
              component="span"
            >
              {statusName}
            </Typography>
          )}
          {!_isEmpty(user) && (
            <>
              <Typography
                className="font-bold text-secondaryLight text-11"
                component="span"
              >
                {' '}
                | {user?.fullName} :
              </Typography>
              <Typography
                className="text-secondaryLight text-11"
                component="span"
              >
                {!!location && ` ${location},`} {updatedAt && moment(updatedAt).format('MMM DD, YYYY @ hh:mmA')}
              </Typography>
            </>
          )}
        </Box>
      </div>

      {/* End of status */}

      {isOpenComment && (
        <>
          <div>
            <Divider
              sx={{
                marginTop: '16px !important',
                borderStyle: 'dashed',
              }}
            />
          </div>
          {/* Start of Comment */}
          <div>
            <div className="flex justify-between space-x-16">
              <CommentOutlinedIcon sx={{ color: 'secondary.light', mt: '4px' }} />
              <TaskComment
                comment={comment}
                preview={preview}
              />
            </div>
          </div>
          {/* End of Comment */}
        </>
      )}
    </div>
  );
};

export default TemplateQuestionLayout;
