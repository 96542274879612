import clsx from 'clsx';
import React from 'react';

import Typography from '@mui/material/Typography';

type Props = {
  title?: string;
  classes?: {
    header?: string;
    content: string;
  };
  children?: React.ReactNode;
};

function OperationContainer({ title, classes, children }: Props) {
  return (
    <div className="bg-white shadow-1 rounded-8">
      <div className={clsx('flex items-center justify-between px-16 py-16', classes?.header)}>
        <Typography className="text-16 font-600">{title}</Typography>
      </div>

      <div className={clsx('border-t-1', classes?.content)}>{children}</div>
    </div>
  );
}

export default OperationContainer;
