import _ from 'lodash';
import { RRule } from 'rrule';

export const FREQUENCIES = [
  { value: RRule.YEARLY, label: 'Yearly' },
  { value: RRule.MONTHLY, label: 'Monthly' },
  { value: RRule.WEEKLY, label: 'Weekly' },
  { value: RRule.DAILY, label: 'Daily' },
] as const;

export const WEEKDAYS = [
  { value: RRule.MO, shortLabel: 'Mon', label: 'Monday' },
  { value: RRule.TU, shortLabel: 'Tue', label: 'Tuesday' },
  { value: RRule.WE, shortLabel: 'Wed', label: 'Wednesday' },
  { value: RRule.TH, shortLabel: 'Thu', label: 'Thursday' },
  { value: RRule.FR, shortLabel: 'Fri', label: 'Friday' },
  { value: RRule.SA, shortLabel: 'Sat', label: 'Saturday' },
  { value: RRule.SU, shortLabel: 'Sun', label: 'Sunday' },
] as const;

export const MONTHS = [
  { value: 1, shortLabel: 'Jan', label: 'January' },
  { value: 2, shortLabel: 'Feb', label: 'February' },
  { value: 3, shortLabel: 'Mar', label: 'March' },
  { value: 4, shortLabel: 'Apr', label: 'April' },
  { value: 5, shortLabel: 'May', label: 'May' },
  { value: 6, shortLabel: 'Jun', label: 'June' },
  { value: 7, shortLabel: 'Jul', label: 'July' },
  { value: 8, shortLabel: 'Aug', label: 'August' },
  { value: 9, shortLabel: 'Sep', label: 'September' },
  { value: 10, shortLabel: 'Oct', label: 'October' },
  { value: 11, shortLabel: 'Nov', label: 'November' },
  { value: 12, shortLabel: 'Dec', label: 'December' },
] as const;

export const POSITIONS = [
  { value: 1, label: 'First' },
  { value: 2, label: 'Second' },
  { value: 3, label: 'Third' },
  { value: 4, label: 'Fourth' },
  { value: 5, label: 'Fifth' },
] as const;

export const DAY_OPTIONS = _.range(1, 32).map((day: number) => ({ value: day, label: `${day}` }));

export const WEEK_OPTIONS = _.range(1, 53).map((week: number) => ({ value: week, label: `${week}` }));

export const MONTH_OPTIONS = _.range(1, 13).map((month: number) => ({ value: month, label: `${month}` }));
