import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';

const useStyles = makeStyles((theme: any) => ({
  background: {
    background: theme.palette.background.paper,
    '& .MuiSelect-select': {
      background: theme.palette.background.paper,
    },
  },
}));

type Props = {
  value?: string;
  options: { value: any; label: string }[];
  onChange?: (value: string) => void;
  styleFormControl?: string;
  styleInputProps?: string;
  variantType?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

function DataSelect({ options, onChange, value, styleFormControl, styleInputProps, variantType, disabled, sx }: Props) {
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    onChange?.(event.target.value as string);
  };

  return (
    <FormControl className={clsx(classes.background, 'min-h-40 h-full w-full flex justify-center', styleFormControl)}>
      <Select
        className="text-center px-8"
        value={value || options[0]?.value}
        disableUnderline={true}
        inputProps={{ className: clsx(styleInputProps), style: { textAlign: 'center' } }}
        classes={{ select: clsx('text-13', classes.background) }}
        onChange={handleChange}
        variant={variantType || 'standard'}
        disabled={disabled}
        sx={sx}
      >
        {options.map((option: { value: number | string; label: string }) => {
          return (
            <MenuItem
              value={option.value}
              key={uuidv4()}
              className="text-13"
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default DataSelect;
