import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { formatDate } from 'utils/date';

function TaskNotifyPreview() {
  const location = useLocation();
  const { list, task, subject, message } = queryString.parse(location.search) || {};

  const generateDate = () => {
    return formatDate(new Date().toISOString(), 'MMM DD, YYYY hh:mm:ss A Z');
  };

  return (
    <div
      id="container"
      className="w-full px-10 pt-10 bg-white"
    >
      <div className="py-10 border-b-1">
        <dl>
          <dt className="float-left font-bold text-right text-12 text-secondaryLight w-92">From:</dt>
          <dd className="text-12 ml-104">BlueFit Notification &lt;From&gt;</dd>

          <dt className="float-left font-bold text-right text-12 text-secondaryLight w-92">Subject:</dt>
          <dd className="ml-104">
            <strong className="text-12">{subject || '[Subject]'}</strong>
          </dd>

          <dt className="float-left font-bold text-right text-12 text-secondaryLight w-92">Date:</dt>
          <dd className="text-12 ml-104">{generateDate()}</dd>

          <dt className="float-left font-bold text-right text-12 text-secondaryLight w-92">To:</dt>
          <dd className="text-12 ml-104">[To]</dd>
        </dl>
      </div>

      <iframe
        title="email-preview"
        className="w-full h-full"
        seamless={true}
        srcDoc={`<base target=&apos;_top&apos;><!DOCTYPE html>
        <html>
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <style>
              /* Email styles need to be inline */
            </style>
          </head>
        
          <body style="margin: 0">
            <table
              cellpadding="0"
              cellspacing="0"
              border="0"
              style="max-width:1100px; width: 100%; margin:auto; font-family: Arial"
            >
              <tbody>
                <tr>
                  <td>
                    <div style="padding: 0 8px;">
                      <img
                        src="https://storage.googleapis.com/hris_production/uploads/bluefit/email/Letterhead-Top-2.png"
                        style="width: 100%; height: 100%;"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="padding:12px 12px 40px">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style="width:100%;font-family:&apos;Helvetica neue&apos;,Helvetica,Arial,Verdana,sans-serif;font-size:15px;line-height:22px;text-align:left !important;background:white;">
                      <tbody>
                        <tr>
                          <td style="text-align:left !important; padding:35px;">
                            <p>Dear
                              <b>[Employee Name]</b>,
                            </p>
                            <p>
                            ${
                              message ||
                              `This is to notify you of the response to the task belonging to the list [${
                                list || 'List Name'
                              }]`
                            }
                            </p>
                            <p></p>
                            <p>
                              Kindly find below the task response details: 
                            </p>
                            <table
                              width="100%"
                              cellspacing="0"
                              cellpadding="0">
                              <tr>
                                <td style="padding: 0 0 0 0;">
                                  <div style="width:100%;background:#F2FCFF;border-radius:8px;">
                                    <table
                                      cellspacing="1"
                                      cellpadding="5"
                                      class="responsive-table">
                                      <tr>
                                        <td style="padding-top: 24px;padding-left: 24px;">
                                          <b>Task Name:</b>
                                        </td>
                                        <td style="padding-top: 24px">${task || '[Task Name]'}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-left: 24px;">
                                          <b>Role:</b>
                                        </td>
                                        <td>[Role]</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-left: 24px">
                                          <b>Location:</b>
                                        </td>
                                        <td>[Location]</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-left: 24px">
                                          <b>Date and time submitted:</b>
                                        </td>
                                        <td>[Date and time submitted]</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-left: 24px">
                                          <b>Completed by:</b>
                                        </td>
                                        <td>[Name of the employee that completed the task]</td>
                                      </tr>
                                      <tr>
                                      <td style="padding-left: 24px">
                                        <b>State:</b>
                                      </td>
                                      <td>[This task has been answered: [answer] | This task has been completed | This task hasn't been completed | This task has been Not Applicable]</td>
                                    </tr>
                                      <tr>
                                        <td style="padding-left: 24px;padding-bottom: 24px;">
                                          <b>Comment:</b>
                                        </td>
                                        <td style="padding-bottom: 24px;">[Comment]</td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <p>
                              Thank you.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="font-family:&apos;Helvetica neue&apos;,Helvetica,Arial,Verdana,sans-serif;font-size:12px;line-height:19px;color:#999;text-align:center;">
                    <table cellpadding="0" cellspacing="0" style="width:100%;background:white;padding: 35px;border-top: 1px solid #ccc;">
                      <tbody>
                        <tr>
                          <td> © 2023 <b>BlueFit</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </body>
        </html>
        `}
      />
    </div>
  );
}

export default TaskNotifyPreview;
