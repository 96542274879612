import { Typography } from '@mui/material';

type Props = {
  label: string;
  value: any;
};

export function CommonChip({ label, value }: Props) {
  return (
    <div className="flex items-center">
      <Typography className="mr-4 text-11 font-400">{label} :</Typography>
      <Typography className="text-11 font-700">{value ? 'Enabled' : 'Disabled'}</Typography>
    </div>
  );
}
