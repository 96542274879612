import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import _ from '@lodash';

export function useFilter<T>(defaultFilters: T, isUpdateUrl?: boolean) {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<T>(getFilters(defaultFilters));

  function getFilters(_filters: T) {
    if (!isUpdateUrl) return _filters;
    return {
      ..._filters,
      ...queryString.parse(location.search, { parseNumbers: true }),
    };
  }

  useEffect(() => {
    if (!isUpdateUrl) return;
    history.replace(`?${queryString.stringify(filters as any)}`);
  }, [filters, history, isUpdateUrl]);

  // reset filter by select sidebar item
  useEffect(() => {
    if (!isUpdateUrl) return;

    const search = queryString.parse(location.search, { parseNumbers: true });
    if (_.isEmpty(search)) {
      setFilters(defaultFilters);
    }
    // eslint-disable-next-line
  }, [location.search]);

  return [filters, setFilters] as const;
}
