import { TResponse } from 'models';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import ResponseMap from './response-map';

type Props = {
  responses: TResponse[];
  location?: string;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const ListTaskLayout = ({ responses, location, isAudit, canAuditInteractions = true }: Props) => {
  return (
    <Stack
      divider={<Divider />}
      className="space-y-8"
    >
      {responses?.map(
        (question: TResponse, i) =>
          question.displayable && (
            <ResponseMap
              key={question.id || 0}
              question={question}
              location={location}
              name={`responses.${i}`}
              isAudit={isAudit}
              canAuditInteractions={canAuditInteractions}
            />
          ),
      )}
    </Stack>
  );
};

export default ListTaskLayout;
