import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import AuditIndex from './audit-index';
import PerformanceIndex from './performance-index';

const TagsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.manager,
  routes: [
    {
      path: `${Paths.performance}`,
      exact: true,
      component: PerformanceIndex,
    },
    {
      path: `${Paths.performance}/audit`,
      exact: true,
      component: AuditIndex,
    },
  ],
};

export default TagsPagesConfig;
