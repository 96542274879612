/* eslint-disable react/prop-types */
import PaginationActions from 'app/components/cores/index-table/PaginationActions';
import clsx from 'clsx';
import H from 'history';
import React, { useEffect, useRef } from 'react';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  SortingRule,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { generateQueryObject } from 'utils/string';
import { generateURLWithFilter, generateURLWithPerPage, getDefaultUrl, getQueryObject } from 'utils/url';
import { v4 as uuidv4 } from 'uuid';

import { usePrevious } from '@fuse/hooks';
import _ from '@lodash';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SortIcon from '@mui/icons-material/Sort';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { makeStyles, withStyles } from '@mui/styles';

import TextFilter from './filters/text';

const useStyles = makeStyles((theme: any) => {
  return {
    checkbox: {
      fill: theme.palette.primary.main,
    },
    tableContainer: {
      minHeight: 'calc(100% - 54px)',
    },
    table: {},
  };
});

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

type IndexTableProps<T extends object> = {
  data: T[];
  columns: Column<T>[];
  pageCount: number;
  loading: boolean;
  totalDataCount: number;
  location: H.Location;
  history: H.History;
  searchText: string;
  searchKey?: string;
  onFetchData: (params: any) => void;
  onRowSelected?: (params: number[], selectedItems?: T[]) => void;
  onRowClick?: (row: any) => void;
  withSelection?: boolean;
  filters?: any;
  setFilters?: any;
  isUpdateAddressUrl?: boolean;
  isShowPagination?: boolean;
  headerActions?: React.ReactNode;
  ignoreFilters?: string[];
  classes?: {
    table: string;
  };
  PaginationProps?: {
    classes?: {
      root: string;
    };
  };
};

/**
 *  tslint:disable-next-line: jsx-key
 *  1. props already has the key value, however eslint did not recognize it
 *  https://github.com/yannickcr/eslint-plugin-react/issues/613
 *  2. if we use an explicit key creates new alert about overwrite attributes
 */

/**
 * IndexTable
 * Pagination table with states synced with url query search
 * @augments {Component<Props, State>}
 * @param {T[]} data: data of table with type
 * @param {Column<T>} columns Definition of table columns, can customize render by Cell attribute
 * @param {boolean} loading When data is loading
 * @param {number} totalDataCount Total number record of an object, using for pagination
 * @param {H.location} location Table depends on URL query search
 * @param {H.History} history An internal table state change make an update for url: pageSize, pageIndex, sort
 * @param {string} searchText table depends on external search
 * @param {function} onFetchData callback function to fetch data when table state changes
 * Current table state: pageSize, pageIndex, sort
 * TODO: handle sort url: sorting isn't synced with url now
 */
function IndexTable<T extends object>({
  withSelection,
  columns,
  data,
  pageCount: controlledPageCount,
  totalDataCount,
  loading,
  location,
  history,
  searchText,
  filters,
  setFilters,
  onFetchData,
  onRowClick,
  onRowSelected,
  isUpdateAddressUrl = true,
  isShowPagination = true,
  ignoreFilters,
  headerActions,
  classes: propClasses,
  PaginationProps,
}: IndexTableProps<T>) {
  const urlQuery = getQueryObject(location?.search, filters, ignoreFilters);
  const { page: initPage = '1', per_page: initSize = '50' } = urlQuery as any;
  const classes = useStyles({ classes: propClasses });
  const prevFilters = usePrevious(filters);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: TextFilter,
    }),
    [],
  );
  const hiddenColumns = columns.map((column: any) => (column.hide ? column.id || column.accessor : null));

  const tableHook = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: Number(initPage) - 1,
        pageSize: Number(initSize),
        filters,
        hiddenColumns,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetFilters: false,
      autoResetPage: false,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (withSelection) {
        hooks.allColumns.push(_columns => [
          {
            id: 'selection',
            sortable: false,
            width: '5%',
            // eslint-disable-next-line react/no-unstable-nested-components
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox
                checkedIcon={<CheckBoxOutlinedIcon className={clsx(classes.checkbox)} />}
                className="mt-28"
                icon={<CheckBoxOutlineBlankOutlinedIcon className={clsx(classes.checkbox)} />}
                {...getToggleAllPageRowsSelectedProps()}
              />
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }: any) => (
              <div className="w-24">
                <Checkbox
                  checkedIcon={<CheckBoxOutlinedIcon className={clsx(classes.checkbox)} />}
                  icon={<CheckBoxOutlineBlankOutlinedIcon className={clsx(classes.checkbox)} />}
                  {...row.getToggleRowSelectedProps()}
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}
                />
              </div>
            ),
          },
          ..._columns,
        ]);
      }
    },
  );
  const {
    columns: loadingColumns,
    selectedFlatRows,
    page,
    state,
    getTableProps,
    prepareRow,
    gotoPage,
    setPageSize,
  } = tableHook;
  const { pageIndex, pageSize, sortBy } = state;
  const prevSortBy = usePrevious(sortBy);
  const headerGroups = tableHook?.headerGroups;

  const havingSelected = selectedFlatRows.length > 0;
  useEffect(() => {
    const selected = selectedFlatRows.map((row: any) => row.original.id);
    const selectedItems = selectedFlatRows.map((row: any) => row.original);
    onRowSelected?.(selected || [], selectedItems);
    // eslint-disable-next-line
  }, [selectedFlatRows]);

  useEffect(() => {
    setFilters(state.filters);
  }, [state, setFilters]);

  // Show page & per_page on browser url by default
  useEffect(() => {
    const defaultUrl = getDefaultUrl(urlQuery, pageSize);
    if (isUpdateAddressUrl) {
      history.push({ search: defaultUrl });
    }
    // eslint-disable-next-line
  }, []);

  const handleFetchData = ({
    pIndex,
    pSize,
    sBy,
    query,
  }: {
    pIndex: number;
    pSize: number;
    sBy: SortingRule<string>[];
    query: any;
  }) => {
    const sortRuleStrings = generateSortRuleStrings(sBy);
    const fetchParams = {
      ...query,
      per_page: pSize,
      page: pIndex,
      'q[s]': sortRuleStrings[0],
    };
    onFetchData(fetchParams);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    gotoPage(newPage);
    handleFetchData({
      pIndex: newPage + 1,
      pSize: pageSize,
      sBy: sortBy,
      query: urlQuery,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    /**
     * After change page size, we will go to the first page
     * to prevent unhandled situations
     */
    gotoPage(0);
    if (isUpdateAddressUrl) {
      history.push({ search: generateURLWithPerPage(location.search, newSize) });
    }

    handleFetchData({
      pIndex: 1,
      pSize: newSize,
      sBy: sortBy,
      query: urlQuery,
    });
  };

  /**
   * generateSortRuleStrings
   * @param {SortingRules<string>} sortRules
   * @return {string[]}
   */
  const generateSortRuleStrings = (sortRules: SortingRule<string>[]): string[] => {
    return sortRules.map(sort => {
      const sortField = _.snakeCase(sort.id);
      return `${sortField} ${sort.desc ? 'desc' : 'asc'}`;
    });
  };

  const didMountRef = useRef(false);

  /**
   * When doing search, we always go to the first page
   * to prevent unexpected behavior of pagination.
   * This behavior just applies after table mounted, not on the first time
   * Using useRef hook to avoid the extra rerender
   */
  useEffect(() => {
    if (didMountRef.current) {
      gotoPage(0);
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line
  }, [searchText]);

  // did mount
  // fetch data first time
  useEffect(() => {
    handleFetchData({
      pIndex: pageIndex + 1,
      pSize: pageSize,
      sBy: sortBy,
      query: urlQuery,
    });
    // eslint-disable-next-line
  }, []);

  // effect for filter
  useEffect(() => {
    if (prevFilters && !_.isEqual(filters, prevFilters)) {
      const queryObject = generateQueryObject(filters);

      // reset page to first page
      queryObject.page = '1';
      gotoPage(0);

      // update browser url
      const search = generateURLWithFilter(location.search, queryObject);
      if (isUpdateAddressUrl) {
        history.push({ search });
      }

      // fetch data
      handleFetchData({
        pIndex: 1,
        pSize: pageSize,
        sBy: sortBy,
        query: { ...urlQuery, ...queryObject },
      });
    }
  });

  // effect for sortBy
  useEffect(() => {
    if (_.size(sortBy) && !_.isEqual(sortBy, prevSortBy)) {
      const queryObject = { ...urlQuery };

      // reset page to first page
      queryObject.page = '1';
      gotoPage(0);

      // update browser url
      const search = generateURLWithFilter(location.search, queryObject);
      if (isUpdateAddressUrl) {
        history.push({ search });
      }

      // fetch data
      handleFetchData({
        pIndex: 1,
        pSize: pageSize,
        sBy: sortBy,
        query: { ...queryObject },
      });
    }
  });

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      {havingSelected && (
        <div className="absolute left-0 z-10 px-24 mt-32 ml-80 rounded-20 bg-paper">{headerActions}</div>
      )}
      <TableContainer className={clsx('flex', classes?.tableContainer)}>
        <Table
          {...getTableProps()}
          stickyHeader={true}
          className={clsx('h-0', classes?.table)}
        >
          {!havingSelected ? (
            <TableHead>
              {headerGroups.map((headerGroup: HeaderGroup<T>) => {
                const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <TableRow
                    key={headerGroupKey}
                    {...restHeaderGroupProps}
                    style={{ padding: 0 }}
                  >
                    {headerGroup.headers.map((column: HeaderGroup<T>) => {
                      const { key: headerKey, ...restHeaderProps } = column.getHeaderProps();
                      const sortProps = column.getHeaderProps(column.getSortByToggleProps());
                      _.unset(sortProps, 'key');
                      return (
                        <TableCell
                          key={headerKey}
                          className="px-8 py-4 overflow-hidden whitespace-no-wrap align-top first:pl-4 last:pr-32"
                          {...restHeaderProps}
                          style={{ width: column.width }}
                        >
                          <div
                            {...(!column.sortable ? { ...restHeaderProps } : { ...sortProps })}
                            className="flex items-center justify-between"
                          >
                            <Typography
                              component="div"
                              className="w-full pl-8 text-13 font-400 text-secondaryLight"
                            >
                              {column.render('Header')}
                            </Typography>

                            {column.sortable ? (
                              <TableSortLabel
                                active={column.isSorted}
                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                                IconComponent={SortIcon}
                              />
                            ) : null}
                          </div>
                          <div className="mt-12">{column.canFilter ? column.render('Filter') : null}</div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          ) : (
            <TableHead>
              {headerGroups.map((headerGroup: HeaderGroup<T>) => {
                const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <TableRow
                    key={headerGroupKey}
                    {...restHeaderGroupProps}
                    style={{ padding: 0, height: 90 }}
                  >
                    {headerGroup.headers.map((column: HeaderGroup<T>) => {
                      const { key: headerKey, ...restHeaderProps } = column.getHeaderProps();
                      return (
                        <TableCell
                          key={headerKey}
                          className="px-8 py-4 overflow-hidden whitespace-no-wrap align-top cursor-pointer first:pl-4 last:pr-32"
                          {...restHeaderProps}
                          style={{ width: column.width }}
                        >
                          {column.id === 'selection' && (
                            <div
                              {...column.getHeaderProps()}
                              className="flex items-center justify-between"
                            >
                              <div className="w-full pl-8 text-13 font-400 text-secondaryLight">
                                {column.render('Header')}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          )}

          {loading ? (
            <TableBody>
              <TableRow className="h-64 transition-colors duration-300 border-b hover:bg-blue-gray-100 hover:bg-opacity-50 last:border-b-0">
                {loadingColumns?.map(() => {
                  return (
                    // tslint:disable-next-line: jsx-key
                    <TableCell
                      key={uuidv4()}
                      className="px-16 py-6 first:pl-40 last:pr-40"
                    >
                      <Skeleton
                        variant="text"
                        animation="wave"
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {page.map((row: Row<T>) => {
                prepareRow(row);
                const { key: rowKey, ...restRowProps } = row.getRowProps();

                return (
                  <TableRow
                    key={rowKey}
                    {...restRowProps}
                    onClick={(event: any) => {
                      const { target } = event;
                      if (!target.closest) return;
                      const table = target?.closest('table');
                      if (!table) return;
                      onRowClick?.(row);
                    }}
                    className={clsx(
                      'h-64 border-b transition-colors duration-300 hover:bg-blue-gray-100 hover:bg-opacity-50 last:border-b-0',
                      { 'cursor-pointer': onRowClick },
                    )}
                  >
                    {row.cells.map((cell: Cell<T>) => {
                      const { key: cellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <TableCell
                          key={cellKey}
                          {...restCellProps}
                          className={clsx('py-6 px-16 first:pl-12 last:pr-40', cell.column.className)}
                          style={{ width: cell.column.width }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {isShowPagination && data.length > 0 ? (
        <TablePagination
          component="div"
          classes={{
            root: clsx('overflow-hidden flex-shrink-0 border-0 border-t-2 mt-auto', PaginationProps?.classes?.root),
            spacer: 'w-0 max-w-0',
            toolbar: 'flex justify-end',
          }}
          rowsPerPageOptions={[50, 75, 100, 200]}
          colSpan={5}
          count={totalDataCount}
          rowsPerPage={pageSize}
          page={pageIndex > 0 && totalDataCount < pageSize ? 0 : pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // eslint-disable-next-line react/no-unstable-nested-components
          ActionsComponent={(props: any) => (
            <PaginationActions
              {...props}
              isUpdateAddressUrl={isUpdateAddressUrl}
            />
          )}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export default IndexTable;
