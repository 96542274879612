export function generateImageSize(width: number, aspectRatio: '16:9' | '4:3' = '16:9') {
  let height = 0;

  switch (aspectRatio) {
    case '16:9':
      height = (width * 9) / 16;
      break;
    case '4:3':
      height = (width * 3) / 4;
      break;
    default:
      height = (width * 9) / 16;
      break;
  }

  return { width, height };
}
