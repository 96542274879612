import { useMemo, useState } from 'react';

import _ from '@lodash';

function useTransferList({ values, options, optionValue = 'id', isDestroyItem }: any) {
  const [leftSelectedValues, setLeftSelectedValues] = useState<any>([]);
  const [rightSelectedValues, setRightSelectedValues] = useState<any>([]);

  // left items
  const unselectedOptions = useMemo(() => {
    const valueIds = _.map(values, optionValue);
    const unselectedItems = _.filter(options, (item: any) => !valueIds.includes(item?.[optionValue])) || [];
    if (!isDestroyItem) return unselectedItems;

    const destroyedItems = _.filter(values, '_destroy') || [];
    return [...unselectedItems, ...destroyedItems];
  }, [values, options, optionValue, isDestroyItem]);

  // left items
  const isSelectAll = (selectedItems?: any, _options?: any) => {
    return !!selectedItems?.length && selectedItems?.length === _options?.length;
  };

  const isIndeterminate = (selectedItems?: any, _options?: any) => {
    return !!selectedItems?.length && selectedItems?.length !== _options?.length;
  };

  const getSelectedTitle = (selectedItems?: any, _options?: any) => {
    return `${selectedItems?.length}/${_options?.length} selected`;
  };

  const isMoveItemsLeftToRight = !!leftSelectedValues?.length; // is move items from left to right?
  const isMoveItemsRightToLeft = !!rightSelectedValues?.length; // is move items from right to left?

  // select all items on the left items
  const handleSelectAllLeftItems = (event: any) => {
    const isChecked = event.target.checked;
    const nextLeftSelectedValues = isChecked ? _.cloneDeep(unselectedOptions) : [];
    setLeftSelectedValues(nextLeftSelectedValues);
  };

  // select all items on the right items
  const handleSelectAllRightItems = (event: any) => {
    const isChecked = event.target.checked;
    const nextRightSelectedValues = isChecked ? _.cloneDeep(values) : [];
    setRightSelectedValues(nextRightSelectedValues);
  };

  // for item has _destroy: true, just remove _destroy attribute
  // otherwise, push to the list
  const moveItemsFromLeftToRight = (_values: any) => {
    const nextValues = _.cloneDeep(_values);

    _.forEach(leftSelectedValues, (item: any) => {
      if (item._destroy && !!isDestroyItem) {
        const selectedValue = _.find(nextValues, { [optionValue]: item?.[optionValue] });
        _.unset(selectedValue, '_destroy');
      } else {
        nextValues.push(item);
      }
    });

    return nextValues;
  };

  // for item have id, add _destroy: true
  // otherwise, just remove from the list
  const moveItemsFromRightToLeft = (_values: any) => {
    const nextValues = _.cloneDeep(_values);

    _.forEach(rightSelectedValues, (item: any) => {
      if (item.id && !!isDestroyItem) {
        const selectedValue = _.find(nextValues, { [optionValue]: item?.[optionValue] });
        selectedValue._destroy = true;
      } else {
        _.remove(nextValues, { [optionValue]: item?.[optionValue] });
      }
    });

    return nextValues;
  };

  const selectedLeftTitle = getSelectedTitle(leftSelectedValues, unselectedOptions);
  const selectedRightTitle = getSelectedTitle(rightSelectedValues, values);

  const isLeftSelectedAllValues = isSelectAll(leftSelectedValues, unselectedOptions);
  const isRightSelectedAllValues = isSelectAll(rightSelectedValues, values);

  const isLeftIndeterminate = isIndeterminate(leftSelectedValues, unselectedOptions);
  const isRightIndeterminate = isIndeterminate(rightSelectedValues, values);

  return {
    unselectedOptions,
    leftSelectedValues,
    setLeftSelectedValues,
    rightSelectedValues,
    setRightSelectedValues,
    isMoveItemsLeftToRight,
    isMoveItemsRightToLeft,
    handleSelectAllRightItems,
    handleSelectAllLeftItems,
    selectedLeftTitle,
    selectedRightTitle,
    isLeftSelectedAllValues,
    isRightSelectedAllValues,
    isLeftIndeterminate,
    isRightIndeterminate,
    moveItemsFromLeftToRight,
    moveItemsFromRightToLeft,
  };
}

export default useTransferList;
