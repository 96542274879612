import queryClient from 'app/configs/query-client';
import { historyKeys } from 'app/hooks/api/manager/histories';
import { TActionItem, TAuditReport, TResponsePagination } from 'models';
import { EmployeeAction } from 'models/employee/action.model';

import { useCreate, useDelete, useGetInfiniteList, useGetList, useUpdate } from '../request';

const auditURL = '/api/manager/histories';

export const auditKeys = {
  all: ['audit'] as const,
  lists: () => [...auditKeys.all, 'list'] as const,
  list: (filters: any) => [...auditKeys.lists(), { filters }] as const,
  details: () => [...auditKeys.all, 'detail'] as const,
  detail: (id: number) => [...auditKeys.details(), id] as const,
};

export const refetchAll = () => queryClient.invalidateQueries(auditKeys.all);

export function useGetAuditReport(filters: any, options?: { enabled?: boolean }) {
  return useGetList<TResponsePagination<TAuditReport>>(
    [...auditKeys.list(filters)],
    `${auditURL}/audit_lists`,
    filters,
    { refetchOnMount: true, ...options },
  );
}

export function useGetActionsByListId(listId: number, options?: any) {
  return useGetInfiniteList<TActionItem>(
    [...auditKeys.detail(listId), 'infinite', { listId }],
    `${auditURL}/${listId}/actions`,
    { per_page: 50 },
    options,
  );
}

export function useDeleteActionItem(listId: number, options?: any) {
  return useDelete(`${auditURL}/${listId}/actions`, {
    onSuccess: () => {
      refetchAll();
    },
    ...options,
  });
}

export function useManagerAddAction(listId: number, responseId: number) {
  return useCreate<{ auditAction: EmployeeAction }, unknown>(`${auditURL}/${listId}/responses/${responseId}/actions`, {
    onSuccess: () => {
      refetchAll();
      queryClient.invalidateQueries(historyKeys.detail(listId));
    },
  });
}

// TODO: maybe can update endpoint
export function useManagerUpdateAction(listId: number, actionId: number) {
  return useUpdate<{ auditAction: EmployeeAction }, unknown>(`${auditURL}/${listId}/actions/${actionId}`, {
    onSuccess: () => {
      refetchAll();
    },
  });
}

export function useManagerAddActionForAuditList(listId: number) {
  return useCreate<{ auditAction: EmployeeAction }, unknown>(`${auditURL}/${listId}/actions`, {
    onSuccess: () => {
      refetchAll();
      queryClient.invalidateQueries(historyKeys.detail(listId));
    },
  });
}
