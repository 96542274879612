import ErrorMessage from 'app/components/cores/form/error-message';
import { Control, useController } from 'react-hook-form';

import DataSelect from './data-select';

type Props = {
  options: { value: any; label: string }[];
  name: string;
  control?: Control<any>;
  rules?: any;
  disabled?: boolean;
};

function ControlledDataSelect({ options, name, control, rules, disabled }: Props) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const errorMessage = error?.message;

  const handleChange = (newValue: any) => {
    onChange(newValue);
  };

  return (
    <div>
      <DataSelect
        options={options}
        onChange={handleChange}
        value={!value ? options[0]?.value : `${value}`}
        disabled={disabled}
      />

      <ErrorMessage message={errorMessage} />
    </div>
  );
}

export default ControlledDataSelect;
