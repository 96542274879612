import { CircleProgressBarWithLabel } from 'app/components/cores/statistics';
import { taskStatus, taskStatusLabels } from 'constants/performance';

type Props = {
  distribution: number[];
  filterTask?: string | null;
};

const DistributionHeader = ({ distribution, filterTask }: Props) => {
  if (!distribution) {
    return null;
  }

  return (
    <>
      {distribution.length > 0 &&
        distribution.map((value, index) => (
          <CircleProgressBarWithLabel
            value={value}
            filterTask={filterTask}
            type={taskStatus[index] as any}
            key={taskStatus[index]}
            tooltipText={`${taskStatusLabels[index]}: ${value}%`}
          />
        ))}
    </>
  );
};
export default DistributionHeader;
