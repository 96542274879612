import ActionFormModal from 'app/components/actions/action-form-modal';
import AddActionManagerModal from 'app/components/histories/audit/action-items-modal/add-action-manager-modal';
import { useAddEmployeeAction } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';

import { Button } from '@mui/material';

type CreateActionButtonProps = {
  listId: number;
  responseId: number;
  disabled: boolean;
  belongTo?: 'employee' | 'manager';
  className?: string;
};

const CreateActionButton = ({
  listId,
  responseId,
  className,
  disabled,
  belongTo = 'employee',
}: CreateActionButtonProps) => {
  const { showSuccess, showError } = useShowMessage();
  const { state: isOpen, setActive: show, setInactive: hide } = useToggle(false);
  const { mutate: addEmployeeAction, isLoading: isAddingAction } = useAddEmployeeAction(listId, responseId);

  const handleCreateEmployeeAction = (data: any) => {
    addEmployeeAction(
      { auditAction: data },
      {
        onSuccess: () => {
          showSuccess('Action created successfully');
          hide();
        },
        onError: () => {
          showError('Could not create action');
        },
      },
    );
  };

  return (
    <>
      <Button
        className={clsx(
          'normal-case text-11 bg-paper text-secondaryMain font-400',
          disabled && 'cursor-not-allowed',
          className,
        )}
        disableElevation
        onClick={show}
        disabled={disabled}
      >
        Add Action
      </Button>

      {isOpen &&
        (belongTo === 'employee' ? (
          <ActionFormModal
            isOpen={isOpen}
            onClose={hide}
            onSubmit={handleCreateEmployeeAction}
            isLoading={isAddingAction}
          />
        ) : (
          <AddActionManagerModal
            isOpen={isOpen}
            onClose={hide}
            listId={listId}
            responseId={responseId}
          />
        ))}
    </>
  );
};

export default CreateActionButton;
