import Header from 'app/components/Layout/headers/blank-header-v2';
import DataSelect from 'app/components/cores/data-select';
import DateRange from 'app/components/cores/date-range';
import HeadButtonRight from 'app/components/cores/header-button-right';
import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import LocationAssignListModal from 'app/components/locations/assign-list-modal';
import SetStatusModal from 'app/components/locations/set-status-modal';
import LocationDetailList from 'app/components/locations/tabs/detail-list';
import Overview from 'app/components/locations/tabs/overview';
import { PerformanceLayout } from 'app/components/locations/tabs/performance';
import PerformanceExportingModal from 'app/components/locations/tabs/performance/exporting/exporting-modal';
import PerformanceContext from 'app/components/performances/context';
import { useAssignListsToLocation, useGetLocation, useGetLocationTasks } from 'app/hooks/api/locations';
import useTab from 'app/hooks/ui/use-tab';
import useObjectAction from 'app/hooks/use-object-action';
import useShowMessage from 'app/hooks/use-show-message';
import { Paths } from 'constants/index';
import { LIST_VALUE, PERIOD_OPTIONS } from 'constants/performance';
import {
  LOCATION_MANAGER_TABS,
  LOCATION_MANAGER_TABS_CONFIG,
  LOCATION_TABS,
  LOCATION_TABS_CONFIG,
} from 'constants/tabs';
import { TDateRange } from 'models';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { isManagerOnly } from 'utils/role';
import { getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import SyncIcon from '@mui/icons-material/Sync';
import { Button, Icon, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';

const modalMapper = {
  locationAssignListModal: 'location-assign-list-modal',
  setStatusModal: 'setStatusModal',
} as const;

type TModal = (typeof modalMapper)[keyof typeof modalMapper];

type TFilter = {
  page: number;
  per_page: number;
} & Record<string, string | number>;

interface ParamsTypes {
  locationId: string;
}

interface Props extends RouteComponentProps<{}> {}

const initLocationPayload = {
  status: 'draft' as 'draft' | 'active' | 'inactive',
  taskIds: [] as number[],
} as const;

function LocationDetail(props: Props) {
  const { showError, showSuccess } = useShowMessage();
  const isFetchingPerformance = useIsFetching({ queryKey: ['performance'] }) > 0;

  const history = useHistory();
  const routeParams = useParams<ParamsTypes>();
  const { locationId: stringLocationId } = routeParams;
  const locationId = Number(stringLocationId);
  const { role: roles, permissions } = useSelector(({ auth }: any) => auth.user);

  const isManagerAccessOnly = isManagerOnly(roles);
  const TABS = isManagerAccessOnly ? LOCATION_MANAGER_TABS : LOCATION_TABS;
  const TABS_CONFIG = isManagerAccessOnly ? LOCATION_MANAGER_TABS_CONFIG : LOCATION_TABS_CONFIG;

  const overviewNotAvailable = isManagerAccessOnly && !permissions['managerLocations.performance'];
  const listNotAvailable = !permissions?.task;

  const [selectedModals, setSelectedModals] = useState<TModal[]>([]);

  const { tabValue, setTabValue } = useTab(TABS_CONFIG.OVERVIEW.value, TABS);
  const [period, setPeriod] = useState<string>(PERIOD_OPTIONS[0].value);

  const todayRange = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  };
  const [tmpPeriod, setTmpPeriod] = useState<TDateRange>(todayRange);
  const [customPeriod, setCustomPeriod] = useState<TDateRange>(todayRange);

  const handleApplyCustomPeriod = () => {
    setCustomPeriod({ startDate: tmpPeriod.startDate, endDate: tmpPeriod.endDate });
  };

  const handleOpenModal = (modalName: TModal) => {
    setSelectedModals([...selectedModals, modalName]);
  };

  const handleCloseModal = (modalName: string) => {
    const newSelectedModals = selectedModals.filter(modal => modal !== modalName);
    setSelectedModals(newSelectedModals);
  };

  const checkModalVisible = (modalName: string) => {
    return selectedModals.length > 0 ? selectedModals[selectedModals.length - 1] === modalName : false;
  };

  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search));
  const [filter, setFilter] = useState<TFilter>({
    page: 1,
    per_page: 50,
    'q[status_eq]': 'published',
  });

  const { data, isLoading: isGettingLocationTasks } = useGetLocationTasks(locationId, filter, {
    enabled: !isManagerAccessOnly,
  });
  const { data: location } = useGetLocation(locationId);
  const { mutateAsync: assignListsToLocation, isLoading: isAssigning } = useAssignListsToLocation(locationId);

  const isLoading = isManagerAccessOnly ? false : isGettingLocationTasks || isAssigning;
  const tasks = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [modalPayload, setModalPayload] = useState<typeof initLocationPayload>(initLocationPayload);
  const [statsType, setStatsType] = useState<string>(LIST_VALUE);
  const memorizedContextState = useMemo(() => ({ statsType, setStatsType }), [statsType, setStatsType]);

  const commonModalProps = (name: TModal) => {
    return {
      open: checkModalVisible(name),
      onClose: () => handleCloseModal(name),
      isLoading,
    };
  };

  const handleDeactivateTask = useObjectAction(
    useAssignListsToLocation,
    'list',
    'unpublish',
    'unpublishing',
    locationId,
  );
  const handleDeleteTask = useObjectAction(useAssignListsToLocation, 'list', 'pending', 'pending', locationId);
  const handleActivateTask = useObjectAction(useAssignListsToLocation, 'list', 'publish', 'publishing', locationId);

  const handleBulkDeactivate = useObjectAction(
    useAssignListsToLocation,
    'lists',
    'unpublish',
    'unpublishing',
    locationId,
  );
  const handleBulkActivate = useObjectAction(useAssignListsToLocation, 'lists', 'publish', 'publishing', locationId);
  const handleBulkDelete = useObjectAction(useAssignListsToLocation, 'lists', 'pending', 'pending', locationId);

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  // reset modalPayload when selectedModals is empty2
  useEffect(() => {
    if (selectedModals.length === 0) {
      setModalPayload(initLocationPayload);
    }
  }, [selectedModals]);

  const searchString = 'q[name_cont]';

  const handleBackClick = () => {
    history.push(Paths.location);
  };

  const handleFetchData = (fetchParams: any) => {
    const { tab, ...otherParams } = fetchParams;
    setFilter(prevFilter => ({ ...prevFilter, ...otherParams }));
  };

  const handleAddListsButtonClick = () => {
    handleOpenModal(modalMapper.locationAssignListModal);
  };

  const handleTaskAction = ({ action, taskId }: { action: string; taskId: number }) => {
    const payload = {
      location: {
        status: '',
        taskIds: [taskId],
      },
    };

    switch (action) {
      case 'draft':
        payload.location.status = 'draft';
        handleDeleteTask(payload);
        break;
      case 'deactivate':
        payload.location.status = 'inactive';
        handleDeactivateTask(payload);
        break;
      case 'activate':
        payload.location.status = 'active';
        handleActivateTask(payload);
        break;

      default:
        break;
    }
  };

  const handleBulkAction = (action: string, taskIds: number[]) => {
    const payload = {
      location: {
        status: '',
        taskIds,
      },
    };

    switch (action) {
      case 'draft':
        payload.location.status = 'draft';
        handleBulkDelete(payload);
        break;
      case 'deactivate':
        payload.location.status = 'inactive';
        handleBulkDeactivate(payload);
        break;
      case 'activate':
        payload.location.status = 'active';
        handleBulkActivate(payload);
        break;
      default:
        break;
    }
  };

  const handleAddLists = (taskIds: number[]) => {
    handleOpenModal(modalMapper.setStatusModal);
    setModalPayload(prev => ({ ...prev, taskIds }));
  };

  const handleFinish = (status: 'draft' | 'active' | 'inactive') => {
    const newModalPayload = { ...modalPayload, status };
    setModalPayload(newModalPayload);

    assignListsToLocation({ location: newModalPayload })
      .then(() => {
        showSuccess('List(s) has been successfully assigned');
        setSelectedModals([]);
        setModalPayload(initLocationPayload);
      })
      .catch(() => {
        showError('Could not assign list(s)');
      });
  };

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => (printRef as any).current,
    documentTitle: `${location?.name} Performance Report`,
  });

  const handleExportPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    handlePrint();
  };

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        toolbar: '-mt-48 min-h-48 h-48 bg-white mx-24 rounded-t-24',
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <>
              <div
                role="button"
                className="flex text-secondaryMain w-fit"
                tabIndex={0}
                onClick={handleBackClick}
              >
                <Icon
                  role="button"
                  className="mt-4 text-18"
                >
                  arrow_back_ios
                </Icon>
                <Typography className="ml-16 uppercase text-18 font-600 line-clamp-1">
                  {location?.name || 'Location Detail'}
                </Typography>
              </div>
            </>
          }
          rightHeader={
            <div>
              {tabValue === LOCATION_TABS_CONFIG.LISTS.value && !isManagerAccessOnly && (
                <HeadButtonRight
                  className="ml-4"
                  variant="contained"
                  btnTitle="Add List"
                  onClick={handleAddListsButtonClick}
                />
              )}
            </div>
          }
        />
      }
      contentToolbar={
        <div className="flex items-center justify-between w-full">
          <Tabs
            value={tabValue}
            indicatorColor="secondary"
            textColor="primary"
            scrollButtons="auto"
            classes={{ root: 'w-full h-48 relative' }}
            onChange={handleChangeTab}
          >
            {TABS.map(tab => (
              <Tab
                key={tab.value}
                label={tab.label}
                className="h-48 normal-case font-400"
                disabled={
                  (overviewNotAvailable && tab.label === 'Performance') || (listNotAvailable && tab.label === 'Lists')
                }
              />
            ))}
          </Tabs>

          {tabValue === TABS_CONFIG.PERFORMANCE.value && (
            <>
              <DataSelect
                options={PERIOD_OPTIONS}
                value={period}
                onChange={setPeriod}
                styleFormControl="max-w-136 rounded-8 mr-4 h-40"
                sx={{ height: '40px' }}
              />
              {period === 'custom' && (
                <div className="flex items-center space-x-16">
                  <DateRange
                    value={tmpPeriod}
                    onChange={setTmpPeriod}
                    noAllTime={true}
                  />
                  <Button
                    disableElevation={true}
                    color="primary"
                    variant="contained"
                    className="h-40 capitalize min-w-88 rounded-8 text-13"
                    onClick={handleApplyCustomPeriod}
                  >
                    Apply
                  </Button>
                </div>
              )}
              <IconButton
                color="primary"
                className="h-40 ml-8"
                onClick={handleExportPDF}
                disabled={isFetchingPerformance}
              >
                {isFetchingPerformance ? <SyncIcon className="animate-spin" /> : <ExportPDFIcon />}
              </IconButton>
            </>
          )}
        </div>
      }
      content={
        <div className="absolute flex flex-col w-full h-full border-t-1">
          {!isManagerAccessOnly && tabValue === LOCATION_TABS_CONFIG.LISTS.value && (
            <div className="absolute flex w-full h-full py-8 bg-white">
              {checkModalVisible(modalMapper.locationAssignListModal) && (
                <LocationAssignListModal
                  locationId={locationId}
                  onAddLists={handleAddLists}
                  taskIds={modalPayload.taskIds}
                  {...commonModalProps(modalMapper.locationAssignListModal)}
                />
              )}
              <SetStatusModal
                status={modalPayload.status}
                onFinish={handleFinish}
                onBack={() => handleCloseModal(modalMapper.setStatusModal)}
                {...commonModalProps(modalMapper.setStatusModal)}
              />
              <LocationDetailList
                tasks={tasks}
                onFetchData={handleFetchData}
                pageCount={pageCount}
                totalDataCount={totalItems || 1}
                isLoading={isLoading}
                searchKey={searchString}
                selectedRowIds={selectedRowIds}
                setSelectedRowIds={setSelectedRowIds}
                filters={filters}
                setFilters={setFilters}
                onAction={handleTaskAction}
                onBulkAction={handleBulkAction}
              />
            </div>
          )}
          {tabValue === TABS_CONFIG.OVERVIEW.value && <Overview locationId={locationId} />}
          {tabValue === TABS_CONFIG.PERFORMANCE.value && (
            <PerformanceContext.Provider value={memorizedContextState}>
              <PerformanceExportingModal
                period={period}
                customPeriod={customPeriod}
                locationId={locationId}
                locationName={location?.name || ''}
                ref={printRef}
              />
              <PerformanceLayout
                locationName={location?.name ?? ''}
                period={period}
                customPeriod={customPeriod}
                locationId={locationId}
              />
            </PerformanceContext.Provider>
          )}
        </div>
      }
      innerScroll={false}
      sidebarInner={true}
      isFixedHeader={true}
      isLoading={isLoading}
    />
  );
}

export default LocationDetail;
