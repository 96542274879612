import { MONTH_OPTIONS } from 'constants/index';
import {
  formatMonthlyRule,
  generateDefaultMonthlyOn,
  generateDefaultMonthlyOnThe,
  isEmptyFrequencyOn,
  isEmptyFrequencyOnThe,
} from 'domains/frequency.domain';
import _ from 'lodash';
import { TFrequencyMonthDay, TFrequencyMonthly } from 'models';
import { useEffect, useState } from 'react';
import { Options as RRuleOptions, Weekday as RRuleWeekday } from 'rrule';

import { FrequencyEvery } from '../ui/frequency-every';
import { FrequencyOn } from '../ui/frequency-on';
import { FrequencyOnThe } from '../ui/frequency-on-the';

type TType = 'on' | 'on_the';

type Props = {
  settings: TFrequencyMonthly;
  onChange?: (nextFrequencySettings: TFrequencyMonthly) => void;
  onRuleFormat?: (formattedRule: Partial<RRuleOptions>) => void;
};

export function FrequencyMonthlyType({ settings, onChange, onRuleFormat }: Props) {
  const [type, setType] = useState<TType>(isEmptyFrequencyOn(settings) ? 'on_the' : 'on');
  const bysetpos = settings?.byweekday?.[0]?.n || 1;

  useEffect(() => {
    const newSettings = formatMonthlyRule(type, settings);
    onRuleFormat?.(newSettings);
  }, [type, settings, onRuleFormat]);

  const handleEveryChange = (nextInterval: number) => {
    updateData('interval', nextInterval);
  };

  const handlePositionChange = (nextPosition: number) => {
    const nextByweekday = settings?.byweekday;
    const newData = nextByweekday?.[0]?.nth(nextPosition) as any;
    updateData('byweekday', newData);
  };

  const handleWeekDayChange = (nextWeekDay: RRuleWeekday[]) => {
    const nextBysetpos = settings?.byweekday?.[0]?.n || 1;
    const newData = nextWeekDay?.[0]?.nth(nextBysetpos);
    updateData('byweekday', newData);
  };

  const handleMonthDaysChange = (nextMonthDays: TFrequencyMonthDay) => {
    updateData('bymonthday', nextMonthDays);
  };

  const updateData = (name: string, data: any) => {
    const nextFrequencySettings = _.cloneDeep(settings);
    nextFrequencySettings[name as keyof TFrequencyMonthly] = data;
    onChange?.(nextFrequencySettings);
  };

  const handleTypeChange = (value: TType) => {
    if (value === 'on' && isEmptyFrequencyOn(settings)) {
      const defaultMonthlyOn = generateDefaultMonthlyOn();
      const nextFrequencySettings = _.cloneDeep(settings);
      onChange?.({ ...nextFrequencySettings, ...defaultMonthlyOn });
    }
    //
    else if (value === 'on_the' && isEmptyFrequencyOnThe(settings)) {
      const defaultMonthlyOnThe = generateDefaultMonthlyOnThe();
      const nextFrequencySettings = _.cloneDeep(settings);
      onChange?.({ ...nextFrequencySettings, ...defaultMonthlyOnThe });
    }

    setType(value as TType);
  };

  return (
    <div className="pt-24 space-y-16">
      <FrequencyEvery
        interval={settings?.interval}
        label="Month(s)"
        options={MONTH_OPTIONS}
        onChange={handleEveryChange}
      />
      <div className="flex flex-col space-y-16">
        <FrequencyOn
          bymonthday={settings?.bymonthday}
          isChecked={type === 'on'}
          isDisabled={type === 'on_the'}
          onMonthDaysChange={handleMonthDaysChange}
          onTypeChange={handleTypeChange}
        />
        <FrequencyOnThe
          bysetpos={bysetpos}
          byweekday={settings?.byweekday}
          isChecked={type === 'on_the'}
          isDisabled={type === 'on'}
          onPositionChange={handlePositionChange}
          onWeekDayChange={handleWeekDayChange}
          onTypeChange={handleTypeChange}
        />
      </div>
    </div>
  );
}
