import QuestionIconType from 'app/components/cores/items-editor/question-icon-type';

import { Typography } from '@mui/material';

type Props = {
  type: string;
  prompt: string;
};

function ReportTableItemCell({ type, prompt }: Props) {
  const Icon = QuestionIconType(type);
  return (
    <div className="flex items-center px-16 min-h-28">
      <div className="w-28">
        <Icon className="text-secondaryLight text-20" />
      </div>
      <Typography className="flex-1 text-12 font-500">{prompt || '-'}</Typography>
    </div>
  );
}

export default ReportTableItemCell;
