import { showMessage } from 'app/store/fuse/messageSlice';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { TMedia } from 'models/index';
import { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import api from 'utils/api';

import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: '100%',
      borderStyle: 'dashed',
      borderWidth: 1,
      borderRadius: '4px',
      borderColor: theme.palette.grey[400],
    },
  };
});

type Props = {
  acceptFiles: Accept;
  isPublic?: boolean;
  uploadZipFile?: boolean;
  children?: JSX.Element;
  multiple?: boolean;
  classes?: { [key: string]: any };
  onChange?: (media: TMedia) => void;
  onLoading?: (loading: boolean) => void;
};

const DragAndDrop = ({
  acceptFiles,
  uploadZipFile,
  children,
  multiple,
  classes: importedClasses,
  onChange,
  onLoading,
}: Props) => {
  const dispatch = useDispatch();

  const handleUploadProgress = ({ loaded, total: tmpTotal }: { loaded: number; total?: number }) => {
    const total = tmpTotal || 0;
    setUploadComplete(false);
    let currentProgress: number = Math.round((loaded * 100) / total);
    /** This is the progress of the upload, i.e., the progress of the content you send to the server. It often 100% (Except for slow network).
     *  We need to progress 100% when the request is done. So we update progress = 98%
     *  We update progress 100% when we got a response from the server.
     */
    if (currentProgress === 100) {
      currentProgress = 98;
    }
    setProgress(currentProgress);
  };

  const setLoading = useCallback((value: boolean) => onLoading?.(value), [onLoading]);

  const onDrop = useCallback(
    async (files: File[]) => {
      if (files.length < 1) {
        /** This case is called when upload an unaccepted file type with drop action
         * For example: drap a zip file to drop zone when we just accept image files
         */
        dispatch(showMessage({ message: 'Could not upload this file', variant: 'error' }));
        return;
      }
      const file = files[0];
      const data = new FormData();
      setLoading(true);

      const handleChange = (media: TMedia) => {
        if (onChange) {
          onChange(media);
        }
      };

      const handleUploadSuccess = (response: AxiosResponse<any>) => {
        setProgress(100);

        setTimeout(() => {
          setUploadComplete(true);
          const media = response.data;
          dispatch(showMessage({ message: 'File has been successfully uploaded', variant: 'success' }));
          handleChange(media);
          setProgress(null);
        }, 200);
      };

      const mediaKey = uploadZipFile ? 'media[zip]' : 'media[files][]';
      data.append(mediaKey, file, file.name);
      try {
        const response = await api.post('/api/media', data, {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
          onUploadProgress: handleUploadProgress,
        });

        setLoading(false);

        if (response.data) {
          handleUploadSuccess(response);
        } else {
          setProgress(null);
          dispatch(showMessage({ message: 'Could not upload file', variant: 'error' }));
        }
      } catch (error) {
        setLoading(false);
        setProgress(null);
        dispatch(showMessage({ message: 'Could not upload file', variant: 'error' }));
      }
    },
    [dispatch, onChange, uploadZipFile, setLoading],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFiles,
    multiple: !!multiple,
  });
  const classes = useStyles();

  // TODO: double check behavior here, it doesn't work well
  // upload process isn't be shown well
  const [progress, setProgress] = useState<number | null>(null);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);

  return (
    <div
      {...getRootProps({
        className: clsx(
          classes.root,
          importedClasses?.root,
          'flex flex-col justify-center items-center h-full w-full rounded-4 relative',
        ),
      })}
    >
      <input
        data-testid="file-input"
        {...getInputProps()}
      />
      {children}

      {progress && !uploadComplete && (
        <div className="absolute flex flex-col justify-center w-full h-full px-16 py-8 bg-white min-h-64 bg-opacity-75">
          <LinearProgress
            variant="determinate"
            value={progress}
          />
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;
