import clsx from 'clsx';
import _ from 'lodash';
import { ComponentProps } from 'react';
import { components as ReactSelectComponents, Props as ReactSelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  control: {
    borderColor: `${theme.palette.common.white} !important`,
    boxShadow: 'none !important',
    minHeight: '32px !important',
    background: `${theme.palette.background.paper} !important`,
  },
  menu: {
    'z-index': '999 !important', // use zIndex with important will get error
  },
  valueContainer: {
    minHeight: '32px !important',
  },
}));

function Option(props: any) {
  const { SelectProps, isDisabled } = props.selectProps;
  let textStyles = props.isSelected ? 'text-white font-600' : 'text-secondaryMain';
  textStyles += isDisabled ? 'text-paper' : '';

  return (
    <ReactSelectComponents.Option {...props}>
      <Typography className={clsx('text-13', textStyles, SelectProps?.classes?.text)}>{props.children}</Typography>
    </ReactSelectComponents.Option>
  );
}

function Control(props: any) {
  const classes = useStyles();
  const { SelectProps } = props.selectProps;

  return (
    <ReactSelectComponents.Control
      {...props}
      className={clsx(classes.control, SelectProps?.classes?.control)}
    >
      {props.children}
    </ReactSelectComponents.Control>
  );
}

function DropdownIndicator() {
  return <ArrowDropDownIcon className="mr-4 text-20 text-secondaryLight" />;
}

function SingleValue(props: any) {
  const {
    SelectProps,
    options,
    value: { id, name },
  } = props.selectProps;

  // eslint-disable-next-line prefer-destructuring
  let children = props.children;

  // used for value is a number
  if (id === name && typeof id === 'number') {
    const option = options.find((o: any) => o.id === id);
    children = option?.name || '';
  }

  return (
    <ReactSelectComponents.SingleValue {...props}>
      <Typography className={clsx('text-13 font-400 text-secondaryMain', SelectProps?.classes?.text)}>
        {children}
      </Typography>
    </ReactSelectComponents.SingleValue>
  );
}

function Placeholder(props: any) {
  const { SelectProps } = props.selectProps;
  return (
    <ReactSelectComponents.Placeholder {...props}>
      <Typography className={clsx('text-13 font-400 opacity-75', SelectProps?.classes?.placeholder)}>
        {props.children}
      </Typography>
    </ReactSelectComponents.Placeholder>
  );
}

function Menu(props: any) {
  const classes = useStyles();
  return (
    <ReactSelectComponents.Menu
      {...props}
      className={classes.menu}
    >
      {props.children}
    </ReactSelectComponents.Menu>
  );
}

function NoOptionsMessage(props: any) {
  const onCreateOption = props?.selectProps?.onCreateOption;
  const isShowAddItem = !!onCreateOption && !props?.options?.length;
  const inputValue = props?.selectProps?.inputValue;

  if (isShowAddItem && !!inputValue) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className="flex items-center h-40 px-12 cursor-pointer"
        onClick={() => onCreateOption?.(inputValue)}
      >
        <Typography className="text-13 font-500">{`Create "${inputValue}"`}</Typography>
      </div>
    );
  }

  return <ReactSelectComponents.NoOptionsMessage {...props} />;
}

function ValueContainer(props: any) {
  const classes = useStyles();
  const { SelectProps } = props.selectProps;
  return (
    <ReactSelectComponents.ValueContainer
      {...props}
      className={clsx(classes.valueContainer, SelectProps?.classes?.valueContainer)}
    />
  );
}

function IndicatorsContainer(props: any) {
  return (
    <ReactSelectComponents.IndicatorsContainer
      {...props}
      className="h-48 cursor-pointer"
    />
  );
}

const ClearIndicator = (props: any) => {
  const { isMulti, getValue } = props;
  const value = getValue?.();
  const presentValue = _.filter(value, (item: any) => !item._destroy);
  return !isMulti || !!presentValue?.length ? (
    <ReactSelectComponents.ClearIndicator
      {...props}
      className="text-8"
    />
  ) : null;
};

type Props = ComponentProps<typeof CreatableSelect> & ReactSelectProps;

function CreatableChipSelect(props: Props) {
  const theme = useTheme();

  const components = {
    Option,
    Control,
    DropdownIndicator,
    SingleValue,
    Placeholder,
    Menu,
    NoOptionsMessage,
    ValueContainer,
    IndicatorsContainer,
    ClearIndicator,
    IndicatorSeparator: () => null,
  };

  const styles = {
    multiValue: (_props: any) => ({
      ..._props,
      backgroundColor: theme.palette.primary.main,
      padding: 4,
    }),
    multiValueLabel: (_props: any) => ({
      ..._props,
      color: 'white',
      fontSize: 15,
    }),
    input: (_props: any) => ({
      ..._props,
      fontWeight: 400,
      fontSize: 13,
    }),
    multiValueRemove: (_props: any) => ({
      ..._props,
      width: 24,
      height: 24,
      marginLeft: 8,
      marginTop: 2,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'white !important',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    }),
    ...props.styles,
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: theme.palette.primary.main,
      primary50: theme.palette.primary.light,
    },
  });

  return (
    <CreatableSelect
      styles={styles}
      components={components}
      theme={generateTheme}
      {...props}
      isMulti
      // isClearable
      classNamePrefix="creatableChipSelect"
      closeMenuOnSelect={false}
      menuShouldBlockScroll
    />
  );
}

export default CreatableChipSelect;
