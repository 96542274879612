import _omit from 'lodash/omit';
import { ComponentProps } from 'react';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { TextField } from '@mui/material';

type Props = {
  onChange?: (value?: number) => void;
  value?: number;
} & Omit<ComponentProps<typeof NumericFormat>, 'onChange' | 'value'> &
  Omit<ComponentProps<typeof TextField>, 'onChange' | 'value'>;

const InputNumber = (props: Props) => {
  const { value, onChange, inputRef } = props;

  const otherProps = _omit(props, ['onChange', 'value', 'inputRef']);

  const handleChange = ({ floatValue }: NumberFormatValues) => {
    onChange?.(floatValue);
  };

  return (
    <NumericFormat
      inputRef={inputRef}
      value={value ?? ''}
      onValueChange={handleChange}
      {...otherProps}
      customInput={TextField}
    />
  );
};

export default InputNumber;
