import TodoResponse from 'app/components/cores/todos/response';
import { TTodo, TTodoResponse } from 'models/employee/todo.model';

import { useIsMutating } from '@tanstack/react-query';

type Props = {
  list?: TTodo;
  readOnly?: boolean;
  useIndexAsKey?: boolean;
};

function TodoContentBody({ list, readOnly = false, useIndexAsKey }: Props) {
  const responses = list?.responses || [];
  const isMutating = useIsMutating();

  return (
    <div className="py-24 space-y-16">
      {responses?.map(
        (response: TTodoResponse, index: number) =>
          response.displayable && (
            <TodoResponse
              key={useIndexAsKey ? index : response.id}
              name={`responses.${index}`}
              response={response}
              list={list}
              readOnly={readOnly || isMutating > 0}
            />
          ),
      )}
    </div>
  );
}

export default TodoContentBody;
