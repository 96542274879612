import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyTaskIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        d="M16.2723 18.8888H3.72789C3.60387 18.8896 3.48091 18.8659 3.36604 18.8191C3.25118 18.7723 3.14665 18.7033 3.05844 18.6161C2.97022 18.529 2.90004 18.4252 2.85191 18.3109C2.80377 18.1966 2.77862 18.074 2.77789 17.9499V3.7166C2.78119 3.57917 2.8136 3.444 2.87297 3.32002C2.93234 3.19603 3.01733 3.08605 3.12234 2.99732C3.22734 2.90859 3.34996 2.84314 3.48212 2.80529C3.61427 2.76743 3.75295 2.75803 3.889 2.77771H5.00011V3.88882H3.889V17.7777H16.1112V3.88882H15.0001V2.77771H16.2501C16.376 2.77401 16.5013 2.79551 16.6188 2.84097C16.7362 2.88642 16.8434 2.9549 16.934 3.04238C17.0246 3.12986 17.0967 3.23458 17.1463 3.35036C17.1958 3.46615 17.2216 3.59067 17.2223 3.7166V17.9499C17.2216 18.074 17.1965 18.1966 17.1483 18.3109C17.1002 18.4252 17.03 18.529 16.9418 18.6161C16.8536 18.7033 16.749 18.7723 16.6342 18.8191C16.5193 18.8659 16.3964 18.8896 16.2723 18.8888Z"
        fill="currentColor"
      />
      <path
        d="M9.25541 14.311L6.27764 11.3333C6.18732 11.2264 6.14069 11.0893 6.14706 10.9495C6.15343 10.8097 6.21234 10.6774 6.312 10.5792C6.41166 10.4809 6.54474 10.4239 6.68463 10.4195C6.82451 10.4151 6.9609 10.4637 7.06652 10.5555L9.25541 12.7444L14.0554 7.94438C14.1617 7.85336 14.2984 7.8058 14.4382 7.81121C14.578 7.81661 14.7107 7.87457 14.8096 7.97351C14.9086 8.07245 14.9665 8.20508 14.9719 8.3449C14.9773 8.48472 14.9298 8.62143 14.8387 8.72771L9.25541 14.311Z"
        fill="currentColor"
      />
      <path
        d="M14.4444 6.11105H5.55554V4.07216C5.557 3.7293 5.69385 3.4009 5.93629 3.15846C6.17873 2.91602 6.50713 2.77918 6.84999 2.77771H7.84443C7.96682 2.29971 8.24482 1.87603 8.6346 1.57347C9.02439 1.27091 9.50378 1.10669 9.99721 1.10669C10.4906 1.10669 10.97 1.27091 11.3598 1.57347C11.7496 1.87603 12.0276 2.29971 12.15 2.77771H13.1444C13.4883 2.77771 13.8181 2.91391 14.0617 3.15651C14.3053 3.39911 14.443 3.72834 14.4444 4.07216V6.11105ZM6.66665 4.99994H13.3333V4.07216C13.3333 4.02353 13.314 3.9769 13.2796 3.94252C13.2452 3.90814 13.1986 3.88882 13.15 3.88882H11.1111V3.33327C11.1111 3.03858 10.994 2.75597 10.7857 2.54759C10.5773 2.33922 10.2947 2.22216 9.99999 2.22216C9.7053 2.22216 9.42269 2.33922 9.21431 2.54759C9.00594 2.75597 8.88887 3.03858 8.88887 3.33327V3.88882H6.84999C6.82591 3.88882 6.80207 3.89357 6.77983 3.90278C6.75758 3.91199 6.73737 3.9255 6.72035 3.94252C6.70333 3.95955 6.68982 3.97976 6.68061 4.002C6.67139 4.02424 6.66665 4.04808 6.66665 4.07216V4.99994Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(MyTaskIcon);
