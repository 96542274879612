import { TResponse } from 'models';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  onClick?: () => void;
  status?: TResponse['status'];
  matched?: boolean;
  code?: string;
  comment?: string;
  disabled?: boolean;
  onCommentChange?: (comment: string) => void;
};

const ScanCode = ({ title, onClick, matched, status, code, comment = '', disabled, onCommentChange }: Props) => {
  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCommentChange?.(e.target.value);
  };

  return (
    <div className="space-y-16">
      <button
        type="button"
        className="flex items-center justify-center w-full h-40 space-x-8 bg-gray-200 rounded-20"
        onClick={onClick}
      >
        <PhotoCameraOutlinedIcon className="text-secondaryLight" />
        <Typography className="text-secondaryLight font-600 text-13">{title}</Typography>
        {code &&
          (matched ? (
            <CheckIcon
              className="font-bold text-24"
              sx={{
                color: status === 'overdue' ? 'overdue.main' : 'primary.main',
              }}
            />
          ) : (
            <CloseIcon
              className="font-bold text-24"
              sx={{
                color: 'error.main',
              }}
            />
          ))}
      </button>

      <div className="space-y-8">
        <Typography className="text-left text-13 text-secondaryMain">If Fail, please state your reason</Typography>
        <TextField
          value={comment ?? ''}
          placeholder="State your comments here"
          multiline
          className="w-full bg-gray-200 rounded-20"
          sx={{
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            minHeight: '100px',
          }}
          disabled={disabled}
          onChange={handleCommentChange}
        />
      </div>
    </div>
  );
};

export default ScanCode;
