import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { TConditionalListTriggerSetting } from 'models/task.model';
import { Control, useFieldArray } from 'react-hook-form';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import TriggerListVariant from './trigger-list-variant';

type Props = {
  name: string;
  control: Control;
};

const AVAILABLE_OPTIONS = [
  { type: 'task_submitted', label: 'List Submitted' },
  { type: 'task_incomplete', label: 'List Incomplete' },
  { type: 'late_submission', label: 'Late Submission' },
] as const;

type ConditionalListOptions = TConditionalListTriggerSetting[number];

const ConditionalListVariant = ({ name, control }: Props) => {
  const newName = name ? `${name}.conditionalTaskSettings` : 'conditionalTaskSettings';
  const { fields: conditionalTaskSettings, append, remove } = useFieldArray({ name: newName, control });

  const foundItemByType = (type: ConditionalListOptions['type']) => {
    return _find(conditionalTaskSettings as any, { type });
  };

  const checkIsActiveOption = (type: ConditionalListOptions['type']) => {
    return !!foundItemByType(type);
  };

  const handleChangeTaskType =
    (type: ConditionalListOptions['type'], fieldIndex: number) => (__: unknown, checked: boolean) => {
      if (checked) {
        append({ type, tasks: null });
      } else {
        remove(fieldIndex);
      }
    };

  return (
    <>
      {AVAILABLE_OPTIONS.map(option => {
        const fieldIndex = _findIndex(conditionalTaskSettings as any, { type: option.type });
        const foundItem = foundItemByType(option.type);
        const isActive = checkIsActiveOption(option.type);

        return (
          // to fix key issue with foundItem?.id
          <div key={foundItem?.id ?? option.type}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                  icon={<CheckBoxOutlineBlankOutlinedIcon color="primary" />}
                />
              }
              label={<Typography className="text-12 font-400">{option.label}</Typography>}
              onChange={handleChangeTaskType(option.type, fieldIndex)}
            />

            {isActive && (
              <div className="mb-8 ml-24">
                <TriggerListVariant
                  name={`${newName}[${fieldIndex}].tasks[0]`}
                  control={control}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ConditionalListVariant;
