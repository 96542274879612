import _ from '@lodash';
import { Chip, Tooltip } from '@mui/material';

type Props = {
  items: { id: number; name: string }[];
  onMore?: (event: React.MouseEvent) => void;
};

function ChipCell({ items, onMore }: Props) {
  if (!onMore) {
    return (
      <div>
        {items.map(l => (
          <Chip
            key={l.id}
            size="small"
            label={l.name}
            className="mt-4 mr-4 text-11 font-500"
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      {_.take(items, 3).map(l => (
        <Chip
          key={l.id}
          size="small"
          label={l.name}
          className="mt-4 mr-4 text-11 font-500"
        />
      ))}
      {items.length > 3 && (
        <Tooltip
          title="More"
          className="cursor-pointer"
          onClick={onMore}
        >
          <Chip
            key={0}
            size="small"
            label="..."
            className="mt-4"
            classes={{ label: 'h-full' }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default ChipCell;
