import auth from 'app/auth/store';

import { combineReducers } from '@reduxjs/toolkit';

import fuse from './fuse';
import report from './report';

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    fuse,
    report,
    ...asyncReducers,
  });

export default createReducer;
