import { authRoles } from 'app/auth';
import { logoutUser } from 'app/auth/store/userSlice';
import store from 'app/store';

const LogoutConfig = {
  auth: authRoles.employee,
  routes: [
    {
      path: '/logout',
      component: () => {
        store.dispatch(logoutUser());
        return 'Logging out..';
      },
    },
  ],
};

export default LogoutConfig;
