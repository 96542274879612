import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { convertDateStringToString } from 'utils/string';

import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';

import InfoItem from './info-item';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    columnGap: '8px',
    rowGap: '16px',
  },
}));

function EmployeeListInfo() {
  const methods = useFormContext();
  const theme = useTheme();
  const classes = useStyles(theme);

  const list = methods.getValues();

  return (
    <div className="p-24 space-y-16 layout-box print:shadow-0">
      <Typography className="mb-8 text-18 font-600 text-secondaryMain">Info</Typography>

      <div className={clsx('w-full', classes.container)}>
        <div className="col-span-4 space-y-16">
          <div className="flex flex-wrap gap-2">
            <InfoItem
              name="Location"
              value={list?.location?.name}
              containerClassName="flex-1"
            />
            <InfoItem
              name="Start time"
              value={convertDateStringToString(list.displayAt, 'DD/MM/YYYY HH:mm')}
              containerClassName="flex-1"
            />

            <InfoItem
              name="Due time"
              value={convertDateStringToString(list.dueAt, 'DD/MM/YYYY HH:mm')}
              containerClassName="flex-1"
            />
            <InfoItem
              name="Submitted time"
              value={convertDateStringToString(list.completedAt, 'DD/MM/YYYY HH:mm')}
              containerClassName="flex-1"
            />
          </div>

          <div className="flex flex-row items-center">
            <Typography className="mr-8 text-13 font-400 text-secondaryLight">Tags</Typography>
            <div className="flex flex-row flex-wrap items-center gap-4">
              {list?.tags?.map((x: any) => (
                <Typography
                  className="px-8 py-2 text-12 font-400 text-secondaryMain rounded-16 bg-paper"
                  key={x.id}
                >
                  {x.name}
                </Typography>
              ))}
            </div>
          </div>
        </div>

        <div>
          <InfoItem
            name="Role"
            value={list?.roles?.[0]?.name}
          />
        </div>

        <div>
          {list?.task?.individual ? (
            <InfoItem
              name="Completed by"
              value={list?.user?.fullName}
            />
          ) : (
            <div className="flex flex-col items-start">
              <Typography className="text-13 font-400 text-secondaryLight">Completed by</Typography>
              <div className="flex flex-col">
                {list?.users?.map((x: any) => (
                  <Typography
                    className="text-13 font-600 text-secondaryMain"
                    key={x.id}
                  >
                    {x.fullName}
                  </Typography>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeListInfo;
