import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from '@lodash';
import { ThemeProvider } from '@mui/material/styles';

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

function FuseTheme(props) {
  const direction = useSelector(({ fuse }) => fuse.settings.defaults.direction);
  const mainTheme = useSelector(selectMainTheme);
  const brandPrimaryColor = useSelector(({ auth }) => auth.user.brandPrimaryColor);
  const currentTheme = _.merge(mainTheme, { palette: { primary: { main: brandPrimaryColor } } });

  useEnhancedEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  // console.warn('FuseTheme:: rendered',mainTheme);
  return <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>;
}

export default React.memo(FuseTheme);
