import withReducer from 'app/store/withReducer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import reducer from './store';
import { toggleQuickPanel } from './store/stateSlice';

const useStyles = makeStyles(theme => ({
  root: {
    width: 280,
  },
}));

function QuickPanel(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ quickPanel }) => quickPanel.state);

  const classes = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.root }}
      open={state}
      anchor="right"
      onClose={ev => dispatch(toggleQuickPanel())}
    >
      <FuseScrollbars>
        <Typography>Quick Panel</Typography>
      </FuseScrollbars>
    </Drawer>
  );
}

export default withReducer('quickPanel', reducer)(React.memo(QuickPanel));
