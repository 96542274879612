import IndexTable from 'app/components/cores/index-table';
import { useGetListStatisticsInDetails, useGetStatisticsDetailsByType } from 'app/hooks/api/location-performance';
import { EMPLOYEE_VALUE, LIST_VALUE, ROLE_VALUE } from 'constants/index';
import { getYearRange } from 'domains/locations/performance';
import _debounce from 'lodash/debounce';
import { TBaseTaskStatistics, TTaskListStatistics } from 'models';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Cell, Row } from 'react-table';
import { formatDate } from 'utils/date';
import { calcPagination } from 'utils/pagination';
import { getFilterFromURL } from 'utils/url';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';

import PerformanceExportingRoleDetailAction from '../exporting/exporting-role-detail-action';
import DistributionCell from '../statistic-table-elements/distribution-cell';
import ListCell from '../statistic-table-elements/list-cell';
import TimeLineYear from '../time-line-year';
import DistributionHeader from './distribution-header';
import RolesHeader from './roles-header';

const DEFAULT_PAGING_PARAMS = {
  page: 1,
  per_page: 50,
};

const DEFAULT_ROLE_PARENT_STATE = {
  name: '',
  distribution: [] as number[],
  role: '',
  isGroup: false,
};

const ROLE_EMPLOYEE_VALUES = [ROLE_VALUE, EMPLOYEE_VALUE];

const useStyles = makeStyles(() => ({
  distribution: {
    paddingRight: '12px !important',
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  modalTitle?: string;
  distribution: number[];
  filterTask?: string | null;
  statsType: string;
  selectedId: number | undefined;
  from: string | null;
  to: string | null;
  rolesHeader?: string;
  isGroup: boolean;
  locationName?: string;
  locationId: number;
  parentData?: {
    roles: string;
    employee: string;
  };
  setParentData?: (nextParentData: any) => void;
};

const StatisticDetailsModal = ({
  open,
  onClose,
  modalTitle = '',
  distribution,
  filterTask,
  statsType,
  selectedId = 0,
  from,
  to,
  rolesHeader,
  isGroup,
  locationName,
  parentData,
  locationId,
  setParentData,
}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [searchTextDebounce, setSearchTextDebounce] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(getFilterFromURL(location?.search));
  const [pagingParams, setPagingParams] = useState(DEFAULT_PAGING_PARAMS);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const formattedSelectedDate = formatDate(selectedDate.toISOString(), 'DD/MM/YYYY');
  const { data: statistics, isLoading: isGettingStatisticDetails } = useGetStatisticsDetailsByType(locationId, {
    type: statsType,
    id: Number(selectedId),
    filter: {
      ...pagingParams,
      'q[date_eq]': formattedSelectedDate,
      'q[name_cont]': searchTextDebounce,
      'q[s]': '',
    },
  });
  const [roleId, setRoleId] = useState<number | undefined>(undefined);
  const [isOpenListInRole, setIsOpenListInRole] = useState(false);
  const [listInRolePagingParams, setListInRolePagingParams] = useState(DEFAULT_PAGING_PARAMS);
  const { data: listInRoleDetails, isLoading: isGettingListInRoleDetails } = useGetListStatisticsInDetails(locationId, {
    roleId,
    detailId: selectedId,
    active: isOpenListInRole,
    type: statsType,
    filter: { ...listInRolePagingParams, 'q[date_eq]': formattedSelectedDate },
  });
  const [roleParent, setRoleParent] = useState(DEFAULT_ROLE_PARENT_STATE);
  const [pageCount, setPageCount] = useState(0);
  const data = isOpenListInRole ? listInRoleDetails?.records || [] : statistics?.records || [];
  const totalDataCount = isOpenListInRole ? listInRoleDetails?.total : statistics?.total;

  const listColumns = useMemo(
    () => [
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Filter: <></>,
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: Cell }) => {
          return <Typography>{cell?.value}</Typography>;
        },
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        Filter: <></>,
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: Cell }) => {
          return <Typography>{cell?.value}</Typography>;
        },
      },
      {
        Header: 'Distribution',
        accessor: 'distribution',
        Filter: <></>,
        width: '40%',
        className: classes.distribution,
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<TBaseTaskStatistics> }) => (
          <DistributionCell
            rowData={row.original}
            filterTask={filterTask}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [filterTask],
  );

  const roleUserColumns = useMemo(
    () => [
      {
        Header: 'List',
        accessor: 'list.name',
        Filter: <></>,
        // eslint-disable-next-line
        Cell: ({ cell, row }: { cell: Cell; row: Row<TTaskListStatistics> }) => {
          const { list } = row.original;
          return (
            <ListCell
              text={cell.value}
              list={list}
            />
          );
        },
      },
      {
        Header: 'No. Lists',
        accessor: 'totalTasks',
        Filter: <></>,
        width: '15%',
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: Cell; row: Row<TBaseTaskStatistics> }) => {
          return <Typography>{cell?.value}</Typography>;
        },
      },
      {
        Header: 'Distribution',
        accessor: 'distribution',
        Filter: <></>,
        width: '40%',
        className: classes.distribution,
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<TBaseTaskStatistics> }) => (
          <DistributionCell
            rowData={row.original}
            filterTask={filterTask}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [filterTask],
  );

  const employeeColumns = useMemo(
    () => [
      {
        Header: 'List',
        accessor: 'list.name',
        Filter: <></>,
        width: '45%',
        // eslint-disable-next-line
        Cell: ({ cell, row }: { cell: Cell; row: Row<TTaskListStatistics> }) => {
          const { list } = row.original;

          return (
            <ListCell
              text={cell.value}
              list={list}
            />
          );
        },
      },
      {
        Header: 'No. Lists',
        accessor: 'totalTasks',
        Filter: <></>,
        width: '15%',
        // eslint-disable-next-line
        Cell: ({ cell }: { cell: Cell }) => {
          return <Typography>{cell?.value}</Typography>;
        },
      },
      {
        Header: 'Distribution',
        accessor: 'distribution',
        Filter: <></>,
        width: '40%',
        className: classes.distribution,
        // eslint-disable-next-line
        Cell: ({ row }: { row: Row<TBaseTaskStatistics> }) => (
          <DistributionCell
            rowData={row.original}
            filterTask={filterTask}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    [filterTask],
  );

  const columns = useMemo(() => {
    if (isOpenListInRole) {
      return listColumns;
    }

    const columnsMap = {
      [LIST_VALUE]: listColumns,
      [ROLE_VALUE]: roleUserColumns,
      [EMPLOYEE_VALUE]: employeeColumns,
    };

    return columnsMap[statsType] || undefined;
    // eslint-disable-next-line
  }, [statsType, isOpenListInRole]);

  const handleFetchData = (fetchParams: any) => {
    const fetchHandler = isOpenListInRole ? setListInRolePagingParams : setPagingParams;

    fetchHandler({
      page: fetchParams.page,
      per_page: fetchParams.per_page,
    });
  };

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClickTableRow = (_row: Row<TTaskListStatistics>) => {
    const rowData = _row.original;
    const nextDistribution = [
      rowData.completedOnTime,
      rowData.completedLate,
      rowData.incomplete,
      rowData.inProgress,
      rowData.pending,
    ];

    setRoleId(rowData.list?.id);
    setRoleParent({
      name: rowData.list.name,
      distribution: nextDistribution,
      role: rowData.list.roles,
      isGroup: rowData.list.group,
    });
    setIsOpenListInRole(true);
    setParentData?.((prevData: any) => {
      const defaultValue = modalTitle || '';
      if (statsType === 'role') {
        prevData.roles = prevData?.roles === modalTitle ? '' : defaultValue;
      }
      if (statsType === 'user') {
        prevData.employee = prevData.employee === modalTitle ? '' : defaultValue;
      }
      return { ...prevData };
    });
  };

  const handleClickClose = () => {
    onClose();
    setIsOpenListInRole(false);
    setRoleId(undefined);
    setRoleParent(DEFAULT_ROLE_PARENT_STATE);
    setListInRolePagingParams(DEFAULT_PAGING_PARAMS);
  };

  useEffect(
    function updateDebounceSearch() {
      const debounceSearch = _debounce(setSearchTextDebounce, 500);
      debounceSearch(searchText);

      return debounceSearch.cancel;
    },
    [searchText],
  );

  const isShowTextInput = ROLE_EMPLOYEE_VALUES.includes(statsType) && !isOpenListInRole;

  const handleCloseListInRole = () => {
    if (!isOpenListInRole) return;

    setIsOpenListInRole(false);
    setRoleId(undefined);
    setRoleParent(DEFAULT_ROLE_PARENT_STATE);
  };

  useEffect(() => {
    const details = isOpenListInRole ? listInRoleDetails : statistics;
    if (!details || !details.records || !details.records.length) return;

    const { pageCount: nextPageCount } = calcPagination(details?.total, details?.perPage);
    setPageCount(nextPageCount);
  }, [statistics, listInRoleDetails, isOpenListInRole]);

  const isLoading = isOpenListInRole ? isGettingListInRoleDetails : isGettingStatisticDetails;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      classes={{
        paper: 'rounded-16',
      }}
    >
      <DialogTitle
        component="div"
        className="space-y-8 bg-white"
      >
        <div className="flex items-center gap-16">
          {isOpenListInRole && (
            <button
              type="button"
              onClick={handleCloseListInRole}
              className="cursor-pointer"
            >
              <ArrowBackIosIcon className="text-black text-20" />
            </button>
          )}

          <div>
            <div className="flex items-center">
              <button
                type="button"
                onClick={handleCloseListInRole}
                className="cursor-pointer"
              >
                <Typography className="text-18 font-600">{isOpenListInRole ? roleParent.name : modalTitle}</Typography>
              </button>
              <PerformanceExportingRoleDetailAction
                isGettingDetailData={isLoading}
                isOpenListInRole={isOpenListInRole}
                columns={columns}
                totalDataCount={totalDataCount}
                selectedId={selectedId}
                locationId={locationId}
                roleId={roleId}
                formattedSelectedDate={formattedSelectedDate}
                statsType={statsType}
                exportingTitle={isOpenListInRole ? roleParent.name : modalTitle}
                locationName={locationName}
                from={from}
                to={to}
                isGroup={isGroup}
                parentData={parentData}
              />
            </div>

            <div className="flex items-center space-x-8">
              <RolesHeader roles={rolesHeader} />
              {isGroup && (
                <IconButton
                  className="bg-paper"
                  size="small"
                >
                  <GroupsOutlined className="text-16" />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-16">
          <div className="space-x-8">
            <DistributionHeader
              distribution={isOpenListInRole ? roleParent.distribution : distribution}
              filterTask={filterTask}
            />
          </div>

          <div className="w-1/2">
            {isShowTextInput && (
              <Input
                placeholder="Search for List..."
                className="h-40 px-16 text-black rounded-4"
                disableUnderline={true}
                fullWidth={true}
                value={searchText}
                inputProps={{ 'aria-label': 'search' }}
                sx={{ backgroundColor: '#F5F5F5' }}
                onChange={handleChangeSearchText}
              />
            )}
          </div>
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClickClose}
        sx={{ right: 8, top: 8 }}
        className="absolute text-16"
      >
        <CloseIcon className="text-black text-20" />
      </IconButton>
      <DialogContent
        dividers
        className="flex gap-4 p-0 bg-white"
        sx={{ height: '70vh' }}
      >
        <div className="flex flex-col space-y-8 w-224">
          <Typography
            className="pl-16 text-36 font-600"
            sx={{ color: '#ECEDEE' }}
          >
            {getYearRange(from, to)}
          </Typography>
          <TimeLineYear
            from={from}
            to={to}
            dateValue={selectedDate}
            onDateValueChange={setSelectedDate}
          />
        </div>

        <div className="flex-1 border-1">
          <IndexTable
            columns={columns as any[]}
            data={data}
            pageCount={pageCount}
            totalDataCount={totalDataCount || 0}
            searchKey={searchText}
            loading={isLoading}
            location={location}
            onFetchData={handleFetchData}
            history={history}
            searchText=""
            filters={filters}
            setFilters={setFilters}
            isUpdateAddressUrl={false}
            onRowClick={ROLE_EMPLOYEE_VALUES.includes(statsType) && !isOpenListInRole ? handleClickTableRow : undefined}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default StatisticDetailsModal;
