import _ from '@lodash';

export const convertErrorMessages = (errors: Error[]) => {
  return _.chain(errors)
    .map((error: Error) => error.message)
    .flatMap()
    .uniq()
    .compact()
    .value();
};
