import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTimeout } from '@fuse/hooks';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

function FuseLoading(props) {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay || false);

  if (!showLoading) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center flex-1">
      <Typography
        className="mb-16 text-20"
        color="textSecondary"
      >
        Loading...
      </Typography>
      <LinearProgress
        className="w-xs"
        color="secondary"
      />
    </div>
  );
}

FuseLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default FuseLoading;
