import { SingleSelect } from 'app/components/cores/select';
import useToggle from 'app/hooks/use-toggle';
import _ from 'lodash';
import { TUserLocation } from 'models';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import OnDemandModal from './on-demand-modal';

type Props = {
  title: string;
  isHideLocation?: boolean;
  location: TUserLocation;
  canAddOnDemand?: boolean;
  onSelectLocation?: (nextLocation: TUserLocation) => void;
};

function TaskHeader({ title, isHideLocation, location, canAddOnDemand = true, onSelectLocation }: Props) {
  const theme = useTheme();
  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const locationOptions = useMemo(() => _.orderBy(locations, 'name'), [locations]);
  const { state: isOpenOnDemandModal, setActive: openOnDemandModal, setInactive: closeOnDemandModal } = useToggle();

  // effect to set first location by default
  useEffect(() => {
    if (_.isEmpty(locationOptions) || !!location) return;
    const [firstLocation] = locationOptions;
    onSelectLocation?.(firstLocation);
  }, [locationOptions, location, onSelectLocation]);

  const handleChange = (item: any) => {
    onSelectLocation?.(item);
  };

  return (
    <div className="flex items-center justify-between h-64 px-16 bg-white shadow-md md:px-24">
      {isOpenOnDemandModal && (
        <OnDemandModal
          location={location}
          isOpen={isOpenOnDemandModal}
          onClose={closeOnDemandModal}
        />
      )}

      <Typography
        variant="h6"
        className="uppercase text-18 text-secondaryMain"
      >
        {title}
      </Typography>

      {!isHideLocation && (
        <div className="flex items-center justify-end gap-16">
          {canAddOnDemand && (
            <Button
              type="button"
              className="px-12 capitalize text-secondaryMain text-13 rounded-8 font-500"
              endIcon={<AddIcon className="text-16 text-secondaryMain" />}
              sx={{
                bgcolor: '#F5D02F',
                ':hover': {
                  bgcolor: '#F5D02F',
                },
              }}
              onClick={openOnDemandModal}
            >
              On-Demand
            </Button>
          )}

          <div className="md:w-232 w-192">
            <SingleSelect
              value={location}
              options={locationOptions}
              maxMenuHeight={1000}
              menuPlacement="auto"
              noOptionsMessage={() => 'No locations'}
              placeholder="Select a location"
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.externalId}
              onChange={handleChange}
              styles={{
                control: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  border: 0,
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 8,
                  height: 40,
                  minHeight: 40,
                  boxShadow: 'none',
                  cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
                  pointerEvents: 'auto',
                }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskHeader;
