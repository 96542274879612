import { SingleSelect } from 'app/components/cores/select';
import { useGetRoles } from 'app/hooks/api/roles';
import useSelect from 'app/hooks/use-select-v2';
import _ from 'lodash';
import { TSelectItem } from 'models';
import { useEffect, useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';

type Props = {
  locationId: number;
  onChange?: (data: TSelectItem) => void;
};

function AdminRoleSelect({ locationId, onChange }: Props) {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize] = useState(200);
  const [value, setValue] = useState(null);
  const { data, isFetching } = useGetRoles(
    {
      page: pageIndex,
      per_page: pageSize,
      'q[name_cont]': searchText,
      'q[locations_external_id_eq]': locationId,
    },
    {
      enabled: !!locationId,
    },
  );

  const { options, handleSearch, handleLoadMore } = useSelect({
    data: data?.records,
    isLoading: isFetching,
    pageIndex,
    pageSize,
    totalItems: data?.total,
    setPageIndex,
    setSearchText,
  });

  const roleOptions = useMemo(() => _.orderBy(options, 'name'), [options]);

  const handleChange = (item: any) => {
    setValue(item);
    onChange?.(item);
  };

  // reset dropdown when location changed
  useEffect(() => {
    if (!locationId) return;
    setPageIndex(1);
    setValue(null);
  }, [locationId]);

  return (
    <SingleSelect
      isLoading={isFetching}
      value={value}
      options={roleOptions}
      maxMenuHeight={1000}
      menuPlacement="auto"
      noOptionsMessage={() => 'No Role'}
      placeholder="All Roles"
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.externalId}
      isClearable={true}
      styles={{
        control: (baseStyles: any, state: any) => ({
          ...baseStyles,
          border: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 8,
          height: 40,
          minHeight: 40,
          boxShadow: 'none',
          cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
          pointerEvents: 'auto',
        }),
      }}
      onInputChange={handleSearch}
      onChange={handleChange}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}

export default AdminRoleSelect;
