import { Paths } from 'constants/paths';
import { TService } from 'models';

import _ from '@lodash';

export function generateHeaderItems(services: TService[]) {
  const nextServices = _.cloneDeep(services) || [];
  return _.chain(nextServices)
    .orderBy('order')
    .map((service: TService) => ({
      ...service,
      url: service.kind === 'hris_my_roster' ? `${service.url}${Paths.myShift}` : service.url,
    }))
    .value();
}
