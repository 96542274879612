import Header from 'app/components/Layout/headers/blank-header-v2';
import HeadButtonRight from 'app/components/cores/header-button-right/header-button-right';
import TemplateForm from 'app/components/templates/form';
import { useGetTask } from 'app/hooks/api/tasks';
import { useUpdateTemplate } from 'app/hooks/api/templates';
import useTab from 'app/hooks/ui/use-tab';
import useBack from 'app/hooks/use-back';
import useShowMessage from 'app/hooks/use-show-message';
import { Paths } from 'constants/index';
import { reorderItems } from 'domains/item.domain';
import { templateFullSchema as schema } from 'domains/template.domain';
import { TTask } from 'models';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import BluefitPage from '@fuse/core/BluefitPage';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';

interface ParamsTypes {
  templateId: string;
}
function Edit() {
  // Common
  const { showError, showSuccess } = useShowMessage();

  const { goBack } = useBack(Paths.template);

  const routeParams = useParams<ParamsTypes>();
  const { templateId: stringTemplateId } = routeParams;
  const templateId = Number(stringTemplateId);

  const { tabValue, setTabValue } = useTab(0);

  // Data
  const { data: template } = useGetTask(templateId);

  const { mutateAsync: updateTemplate, isLoading } = useUpdateTemplate(templateId);

  const form = useForm<TTask>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: template,
    values: template,
  });

  const { handleSubmit, reset } = form;

  // Effect
  useEffect(() => {
    reset(template);
  }, [template, reset]);

  // Functions
  const handleBackClick = () => {
    goBack();
  };

  const handleCancelButton = () => {
    handleBackClick();
  };

  const handleUpdateTemplate = (status?: string) => (value: Partial<TTask>) => {
    const itemsAttributes = reorderItems(value?.itemsAttributes);
    const newStatus = status || value?.status;
    const payload = { template: { ...value, status: newStatus, itemsAttributes } };

    updateTemplate(payload)
      .then(() => {
        showSuccess('Template has been successfully updated');
      })
      .catch((res: any) => {
        let statusError: string | null = null;
        res?.errors.forEach((error: any) => {
          const message = `${error?.field} ${error?.message?.[0]}`;
          if (error?.field === 'status') {
            statusError = message;
          }
          form.setError(error?.field, { type: 'custom', message });
        });
        showError(statusError || 'Could not update Template');
      });
  };

  const handleInvalid = async () => {
    showError('Could not update Template');
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-72 h-72 border-b-1',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <Typography className="ml-16 text-18 font-600 line-clamp-1">{template?.name}</Typography>
            </div>
          }
          rightHeader={
            <div className="flex flex-row">
              {isLoading ? (
                <div>
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                  <HeadButtonRight
                    className="mx-8 normal-case whitespace-no-wrap"
                    disabled={true}
                    isFetchingData={true}
                  />
                </div>
              ) : (
                <div>
                  {template?.status === 'published' ? (
                    <div className="flex flex-row">
                      <HeadButtonRight
                        className="whitespace-no-wrap"
                        onClick={handleCancelButton}
                        btnTitle="Cancel"
                      />
                      <HeadButtonRight
                        className="ml-8 whitespace-no-wrap"
                        variant="contained"
                        onClick={handleSubmit(handleUpdateTemplate(), handleInvalid)}
                        btnTitle="Save"
                      />
                    </div>
                  ) : (
                    <div>
                      <HeadButtonRight
                        className="whitespace-no-wrap"
                        onClick={handleSubmit(handleUpdateTemplate('draft'), handleInvalid)}
                        btnTitle="Draft"
                      />
                      <HeadButtonRight
                        className="ml-4 whitespace-no-wrap"
                        variant="contained"
                        onClick={handleSubmit(handleUpdateTemplate('published'), handleInvalid)}
                        btnTitle="Publish"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          }
        />
      }
      content={
        <div className="relative w-full h-full px-16 pb-64 sm:px-24 bg-paper">
          <FormProvider {...form}>
            <TemplateForm
              tabIndex={tabValue}
              onTabChange={setTabValue}
            />
          </FormProvider>
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
    />
  );
}

export default Edit;
