import clsx from 'clsx';
import { TResponse } from 'models';
import { useEffect, useState } from 'react';

import { ButtonProps, CircularProgress, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  minLabel: string;
  maxLabel: string;
  minNumber: number;
  maxNumber: number;
  value?: number | null;
  disabled?: boolean;
  status?: TResponse['status'];
  isLoading?: boolean;
  onChange?: (nextRating: number | null) => void;
};

const RatingButtons = ({
  minLabel,
  maxLabel,
  minNumber,
  maxNumber,
  disabled,
  value,
  status,
  isLoading,
  onChange,
}: Props) => {
  const ratingButtons = Array.from({ length: maxNumber - minNumber + 1 }, (_, i) => i + minNumber);

  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  const buttonWidth = 100 / ratingButtons.length;

  const handleClickRating = (rating: number) => () => {
    const nextRating = rating === selectedRating ? null : rating;
    setSelectedRating(nextRating);
    onChange?.(nextRating);
  };

  useEffect(() => {
    if (value === undefined) return;
    setSelectedRating(value);
  }, [value]);

  return (
    <div className="space-y-16">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        useFlexGap
        className="space-x-8"
      >
        {ratingButtons.map(rating => (
          <StyledRatingButton
            key={rating}
            onClick={handleClickRating(rating)}
            active={rating === selectedRating}
            disabled={disabled}
            sx={{
              width: `${buttonWidth}%`,
              maxWidth: 120,
              height: 40,
            }}
            status={status}
            className={clsx(isLoading && 'pointer-events-none')}
          >
            {isLoading ? (
              <CircularProgress
                size={16}
                color="inherit"
              />
            ) : (
              rating
            )}
          </StyledRatingButton>
        ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        className="px-8"
      >
        <Typography className="font-bold capitalize text-13">{minLabel}</Typography>
        <Typography className="font-bold capitalize text-13"> {maxLabel}</Typography>
      </Stack>
    </div>
  );
};

export default RatingButtons;

const StyledRatingButton = styled('button')<ButtonProps & { active?: boolean; status?: TResponse['status'] }>(
  ({ theme, active, status }: any) => {
    const { palette } = theme;

    const getBgColorByStatus = () => {
      if (status === 'overdue') return palette.overdue.main;
      return palette.primary.main;
    };

    return {
      backgroundColor: active ? getBgColorByStatus() : palette.grey[200],
      color: active ? palette.common.white : palette.text.primary,
      borderRadius: 20,

      '&:hover': {
        backgroundColor: active ? getBgColorByStatus() : palette.grey[300],
      },
    };
  },
);
