import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import TableHeader from './table-header';

const ActionItemsLoading = () => {
  return (
    <TableContainer component="div">
      <Table sx={{ minWidth: 650 }}>
        <TableHeader />
        <TableBody>
          {[1, 2, 3, 4, 5].map(row => (
            <TableRow
              key={row}
              className="border-0"
            >
              <TableCell
                width="35%"
                className="border-0"
              >
                <Skeleton
                  variant="text"
                  width="90%"
                  height={24}
                />
              </TableCell>
              <TableCell
                width="15%"
                className="border-0"
              >
                <Skeleton
                  variant="text"
                  width="80%"
                  height={24}
                />
              </TableCell>
              <TableCell
                width="15%"
                className="border-0"
              >
                <Skeleton
                  variant="text"
                  width="70%"
                  height={24}
                />
              </TableCell>
              <TableCell
                width="20%"
                className="border-0"
              >
                <Skeleton
                  variant="rounded"
                  width="80%"
                  height={32}
                />
              </TableCell>
              <TableCell
                width="15%"
                className="border-0"
              >
                <div className="flex gap-8">
                  <Skeleton
                    variant="circular"
                    width={32}
                    height={32}
                  />
                  <Skeleton
                    variant="circular"
                    width={32}
                    height={32}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActionItemsLoading;
