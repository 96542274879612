import { TTask } from 'models';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';

import TaskAssignmentSetting from './assignment-setting';

function GlobalSetting() {
  const methods = useFormContext<TTask>();
  const name = 'globalAssignmentAttributes';

  return (
    <div className="w-full h-full px-24 py-16 layout-box divide-paper">
      <Typography className="mb-16 text-13 font-400">
        Global Settings are universal configurations applied to all locations selected in the &quot;Locations&ldquo;
        tab. Once these settings are configured, users can proceed to the &quot;Variant&ldquo; tab to customize and
        create distinct variants.
      </Typography>
      <TaskAssignmentSetting
        name={name}
        methods={methods}
      />
    </div>
  );
}

export default GlobalSetting;
