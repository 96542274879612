import { ControlledDataSelect } from 'app/components/cores/data-select';
import { ControlledDatePicker } from 'app/components/cores/date-picker';
import AttributeContainer from 'app/components/lists/attribute-container';
import {
  END_TIME_OPTIONS,
  GROUP_OPTIONS,
  LIST_SUBMISSION_OPTIONS,
  ON_DEMAND_OPTIONS,
  SHOW_SWEEP_LIST_SETTING,
  START_TIME_OPTIONS,
  TASK_TYPE_OPTIONS,
} from 'constants/index';
import { getDataForUnavailable } from 'domains/role.domain';
import { useWatch } from 'react-hook-form';

import { Typography } from '@mui/material';

import AlertExistedRoles from './alert-existed-roles';
import ConditionalList from './assignment/conditional-list';
import EndDateAssignment from './assignment/end-date-assignment';
import OthersAssignment from './assignment/others';
import ReportTo from './assignment/report-to';
import RoleSelection from './assignment/role-selection';
import TimeAssignment from './assignment/time-assignment';
import DisplayFrequenciesSetting from './display-frequencies-setting';
import ListSubmission from './list-submission';
import TaskNotification from './notification';

type Props = {
  name: string;
  methods: any;
};

function TaskAssignmentSetting({ name, methods }: Props) {
  const control = methods?.control;

  const locations = useWatch({ name: 'locationTasksAttributes', control, defaultValue: [] });
  const orgRoles = useWatch({ name: `${name}.assignmentOrgRolesAttributes`, control });
  const assignmentsAttributes = useWatch({ name: 'assignmentsAttributes', control, defaultValue: [] });

  const { global, variations } = getDataForUnavailable({
    globalLocations: locations,
    globalOrgRoles: orgRoles,
    variations: assignmentsAttributes,
  });

  const locationVariations = variations.assignRoleVariations.map(variation => variation.location);

  return (
    <div className="grid flex-1 grid-cols-8 md:divide-x-1 divide-paper">
      <div className="flex flex-col col-span-8 space-y-12 md:col-span-4 md:pr-24">
        <div className="space-y-12">
          <AttributeContainer label="Start Date *">
            <ControlledDatePicker
              name={`${name}.startDate` as const}
              control={control}
            />
          </AttributeContainer>

          <EndDateAssignment
            name={`${name}.endDate` as const}
            control={control}
          />

          <TimeAssignment
            label="Start Time"
            options={START_TIME_OPTIONS}
            name={`${name}.startTime` as const}
            control={control}
          />

          <TimeAssignment
            label="End Time"
            options={END_TIME_OPTIONS}
            name={`${name}.endTime` as const}
            control={control}
          />

          <OthersAssignment
            name={name}
            control={control}
            methods={methods}
          />
        </div>

        <div className="space-y-24">
          <DisplayFrequenciesSetting
            methods={methods}
            name={name}
            control={control}
          />
        </div>
      </div>

      <div className="flex flex-col col-span-8 mt-24 space-y-16 md:col-span-4 md:pl-24 md:mt-0">
        <AttributeContainer label="Assign Role(s)">
          <RoleSelection
            control={control}
            name={`${name}.assignmentOrgRolesAttributes`}
            placeholder="Assign Role to List"
          />
          <AlertExistedRoles
            roleIds={global.orgRoleIds}
            locationIds={global.locationIds}
            locationVariations={locationVariations}
          />
        </AttributeContainer>

        <AttributeContainer label="Report To">
          <ReportTo
            name={name}
            control={control}
          />
        </AttributeContainer>

        <AttributeContainer label="List Type">
          <div className="w-128">
            <ControlledDataSelect
              options={GROUP_OPTIONS}
              name={`${name}.listType`}
              control={control}
            />
          </div>
        </AttributeContainer>
        <div className="w-full whitespace-break-spaces border-b-1 border-b-paper">
          <Typography className="text-small-light">
            <span className="font-700">Location</span>: All the employees that belong to the same location have to
            complete 1 list together
          </Typography>
          <Typography className="text-small-light">
            <span className="font-700">Group</span>: All the employees that belong to the same role have to complete 1
            list together
          </Typography>
          <Typography className="text-small-light">
            <span className="font-700">Individual</span>: All the employees that belong to the same role have to
            complete the list individually
          </Typography>
        </div>

        <AttributeContainer label="List Completion Type">
          <div className="w-128">
            <ControlledDataSelect
              options={TASK_TYPE_OPTIONS}
              name={`${name}.kind`}
              control={control}
            />
          </div>
        </AttributeContainer>
        <div className="w-full whitespace-break-spaces border-b-1 border-b-paper">
          <Typography className="text-small-light">
            <span className="font-700">Recurring </span>: Lists will be wiped off from My Task Index when the time ends
            up to complete the list
          </Typography>
          <Typography className="text-small-light">
            <span className="font-700">Fixed</span>: Lists will stack and remain in My Task Index until user completes
            and submits the list
          </Typography>
        </div>

        <AttributeContainer label="On Demand">
          <div className="w-128">
            <ControlledDataSelect
              options={ON_DEMAND_OPTIONS}
              name={`${name}.onDemand`}
              control={control}
            />
          </div>
        </AttributeContainer>
        <div className="w-full whitespace-break-spaces border-b-1 border-b-paper">
          <Typography className="text-small-light">
            <span className="font-700">Enabled</span>: This list will be accessible to employees on demand, enabling
            them to use it as a point of reference as needed.
          </Typography>
        </div>

        {/* Start of Conditional List Setting */}
        <AttributeContainer label="List Trigger Setting">
          <ConditionalList name={name} />
        </AttributeContainer>

        <div className="w-full whitespace-break-spaces border-b-1 border-b-paper" />
        {/* End of Conditional List Setting */}

        <AttributeContainer label="Notification">
          <TaskNotification
            name={name}
            control={control}
          />
        </AttributeContainer>

        {SHOW_SWEEP_LIST_SETTING && (
          <AttributeContainer label="List Submission">
            <ListSubmission
              options={LIST_SUBMISSION_OPTIONS}
              name={`${name}.sweep`}
              control={control}
            />
          </AttributeContainer>
        )}
      </div>
    </div>
  );
}

export default TaskAssignmentSetting;
