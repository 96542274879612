import { ControlledTextInput } from 'app/components/cores/inputs/text';
import Toggle from 'app/components/cores/inputs/toggle/toggle';
import useTask from 'app/hooks/use-task';
import clsx from 'clsx';
import { ITEM_TYPES, ITEM_TYPES_MAP } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';

import ActionItemLogic from './action-item-logic/container';
import ConditionalLogicContainer from './conditional-logic/container';
import FileUploadField from './file-upload-field';
import FlaggedItemLogic from './flagged-item-logic/container';
import ConditionalLogicContainerList from './list-trigger/container-list';
import NotifyLogicContainer from './notify-logic/container';
import QuestionOptionsSwitch from './question-options-switch';
import ScoreSetting from './score-setting';

const useStyles = makeStyles(() => {
  return {
    content: {
      width: 432,
      overflow: 'hidden',
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: '1.6rem',
    },
    mainSetting: {
      paddingBottom: 248,
      overflow: 'hidden',
    },
    mainSettingNoAudit: {
      paddingBottom: '172px !important',
    },
    accordionRoot: {
      '&:before': {
        display: 'none',
      },
    },
    expandIconWrapper: {
      paddingRight: 8,
      transition: 'none',
      '& > .expandIconButton': {
        display: 'inline-flex',
      },
      '& > .expandedIconButton': {
        display: 'none',
      },
    },
    expanded: {
      '& > .expandIconButton': {
        display: 'none',
      },
      '& > .expandedIconButton': {
        display: 'inline-flex',
      },
    },
    notifyContainer: {
      bottom: 48,
      height: 'calc(100% - 48px)',
      overflow: 'auto',
    },
    panel: {
      height: 215,
    },
    panelExpanded: {
      maxHeight: 'calc(100% - 48px) !important',
    },
  };
});

type Props = {
  control?: Control;
  name: string;
  itemsAttributesName: string;
  isDisabled?: boolean;
  onClose?: () => void;
  onSave?: ({ hideSuccessMessage, hideErrorMessage }: any) => void;
};

type ExpandPanel = 'conditional-task' | 'notify' | 'conditional-list' | 'action-item' | 'flagged-item' | undefined;

function ItemSetting({ isDisabled, name, itemsAttributesName, control, onClose, onSave }: Props) {
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState<ExpandPanel>();
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [expandedAttachment, setExpandedAttachment] = useState(false);
  const { isShowAudit } = useTask();
  const item = useWatch({ control, name });

  const { type, description, attachmentsAttributes } = item || {};
  const isSection = type === ITEM_TYPES_MAP.SECTION;

  useEffect(() => {
    if (description) {
      setExpandedDescription(true);
    }
  }, [description]);

  useEffect(() => {
    if (attachmentsAttributes?.length) {
      setExpandedAttachment(true);
    }
  }, [attachmentsAttributes]);

  const handleLogicChange = (nextExpandedPanel: ExpandPanel) => {
    setExpandedPanel(expandedPanel === nextExpandedPanel ? undefined : nextExpandedPanel);
  };

  const handleExpandDescription = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpandedDescription(expanded);
  };

  const handleExpandAttachment = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpandedAttachment(expanded);
  };

  const handleClose = () => {
    if (!isDisabled) {
      onSave?.({
        hideSuccessMessage: true,
        hideErrorMessage: true,
        hideUpdateListLoading: true,
      });
    } else {
      onClose?.();
    }
  };

  return (
    <div className={clsx(classes.content, 'drawer flex flex-col bg-white border-t-1 border-gray-200')}>
      <div
        className={clsx(
          'flex flex-col justify-between flex-1 space-y-0',
          classes.mainSetting,
          !isShowAudit && classes.mainSettingNoAudit,
        )}
      >
        <div className="flex flex-col h-full">
          {/* title */}
          <div className="flex items-center title min-h-48 border-b-1">
            <Typography className="ml-24 uppercase text-secondaryMain text-15 font-500">
              {ITEM_TYPES.find(x => x.type === type)?.label || ''}
            </Typography>
          </div>

          <div className="flex-1 overflow-auto">
            {/* required items */}
            <div className="flex flex-col">
              {!isSection ? (
                <div className="flex flex-row items-center justify-between h-16 px-24 py-20">
                  <Typography className="text-12 font-600">Required items*</Typography>
                  <Toggle
                    control={control}
                    name={`${name}.required`}
                    disabled={isDisabled}
                  />
                </div>
              ) : (
                <div className="h-0" />
              )}

              <Accordion
                className={clsx('bg-white shadow-0', isDisabled && 'pointer-events-none')}
                classes={{ root: classes.accordionRoot }}
                disableGutters={true}
                expanded={expandedDescription}
                onChange={handleExpandDescription}
              >
                <AccordionSummary
                  classes={{
                    root: 'min-h-40',
                    expandIconWrapper: classes.expandIconWrapper,
                    expanded: classes.expanded,
                  }}
                  expandIcon={
                    <>
                      <Button
                        disabled={isDisabled}
                        disableElevation={true}
                        color="primary"
                        variant="contained"
                        className="w-64 h-24 capitalize expandIconButton min-w-64 rounded-4 text-11 font-500"
                      >
                        Add
                      </Button>
                      <ExpandMoreIcon className="expandedIconButton" />
                    </>
                  }
                  aria-controls="description"
                  id="description"
                >
                  <Typography className="ml-8 text-secondaryLight text-12 font-400">Description (optional)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col justify-between w-full px-8 space-y-8">
                    <ControlledTextInput
                      placeholder="Add description here"
                      name={`${name}.description`}
                      control={control}
                      variant="filled"
                      className="h-full shadow"
                      multiline={true}
                      minRows={3}
                      InputProps={{
                        className: 'bg-paper hover:bg-gray-200 border-right-width-1 p-8',
                        disableUnderline: true,
                        classes: {
                          input: 'px-8 text-13',
                        },
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className={clsx('bg-white shadow-0', isDisabled && 'pointer-events-none')}
                classes={{ root: classes.accordionRoot }}
                disableGutters={true}
                expanded={expandedAttachment}
                onChange={handleExpandAttachment}
              >
                <AccordionSummary
                  classes={{
                    root: 'min-h-40',
                    expandIconWrapper: classes.expandIconWrapper,
                    expanded: classes.expanded,
                  }}
                  expandIcon={
                    <>
                      <Button
                        disabled={isDisabled}
                        disableElevation={true}
                        color="primary"
                        variant="contained"
                        className="w-64 h-24 capitalize expandIconButton min-w-64 rounded-4 text-11 font-500"
                      >
                        Upload
                      </Button>
                      <ExpandMoreIcon className="expandedIconButton" />
                    </>
                  }
                  aria-controls="description"
                  id="description"
                >
                  <Typography className="ml-8 text-secondaryLight text-12 font-400">Attachment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col justify-between w-full px-8 space-y-8">
                    <FileUploadField
                      name={`${name}.attachmentsAttributes`}
                      acceptFiles={{
                        'application/pdf': ['.pdf'],
                        'image/png': ['.png'],
                      }}
                      isPublic={false}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              {!isSection && (
                <div className="flex flex-row items-center justify-between h-16 p-24">
                  <Typography className="text-12 font-600">Allow users to mark as N/A</Typography>
                  <Toggle
                    control={control}
                    name={`${name}.notApplicable`}
                    disabled={isDisabled}
                  />
                </div>
              )}

              {item?.type === ITEM_TYPES_MAP.MEDIA && (
                <div className="flex flex-row items-center justify-between h-16 p-24">
                  <Typography className="text-11 font-600">Allow media file to be compressed</Typography>
                  <Toggle
                    control={control}
                    name={`${name}.keepMediaOriginal`}
                    disabled={isDisabled}
                    reverse={true}
                  />
                </div>
              )}
            </div>

            {/* item settings */}
            <QuestionOptionsSwitch
              control={control}
              name={name}
              className="flex flex-col w-full pt-16 space-y-8 border-t-1"
            />

            {/* score settings */}
            {isShowAudit && (
              <ScoreSetting
                control={control}
                name={name}
                isDisabled={isDisabled}
                className="flex flex-col w-full space-y-8 border-t-1"
              />
            )}
          </div>
        </div>
      </div>

      {/* logics */}
      <div
        className={clsx(
          'absolute z-10 w-full duration-300 overflow-hidden',
          classes.notifyContainer,
          !expandedPanel ? classes.panel : 'bg-paper bg-opacity-25',
        )}
      >
        <div className="h-full">
          <div
            className={clsx(
              'absolute bottom-0 flex flex-col max-h-full pt-4 overflow-auto bg-white',
              !!expandedPanel && classes.panelExpanded,
            )}
          >
            {/* conditional - task */}
            {!isSection && (
              <ConditionalLogicContainer
                isDisabled={isDisabled}
                control={control}
                name={name}
                itemsAttributesName={itemsAttributesName}
                isExpanded={expandedPanel === 'conditional-task'}
                onPanelChange={handleLogicChange}
              />
            )}

            {/* action item logic */}
            {!isSection && isShowAudit && (
              <ActionItemLogic
                isDisabled={isDisabled}
                control={control}
                name={name}
                isExpanded={expandedPanel === 'action-item'}
                onPanelChange={handleLogicChange}
              />
            )}

            {/* flagged item logic */}
            {!isSection && isShowAudit && (
              <FlaggedItemLogic
                isDisabled={isDisabled}
                control={control}
                name={name}
                isExpanded={expandedPanel === 'flagged-item'}
                onPanelChange={handleLogicChange}
              />
            )}

            {/* list trigger */}
            {!isSection && (
              <ConditionalLogicContainerList
                isDisabled={isDisabled}
                control={control}
                name={name}
                isExpanded={expandedPanel === 'conditional-list'}
                onPanelChange={handleLogicChange}
                key={item?.dateTimeType}
              />
            )}

            {/* notify */}
            {!isSection && (
              <NotifyLogicContainer
                isDisabled={isDisabled}
                control={control}
                name={name}
                isExpanded={expandedPanel === 'notify'}
                selectedItem={item}
                onPanelChange={handleLogicChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 z-10 flex flex-row items-center justify-end w-full px-24 mt-auto space-x-8 bg-white min-h-48 border-t-1">
        <Button
          disableElevation={true}
          color="inherit"
          className="bg-gray-200 text-11 font-500 min-w-80 w-80"
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
}

export default ItemSetting;
