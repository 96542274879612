import ErrorMessage from 'app/components/cores/form/error-message';
import TimePicker from 'app/components/cores/time-picker/time-picker';
import { TIME_FORMAT } from 'constants/index';
import moment from 'moment';
import { Control, useController, useWatch } from 'react-hook-form';
import { RRule } from 'rrule';
import { clsxm } from 'utils/clsxm';

import { Typography } from '@mui/material';

type Props = {
  name: string;
  targetName: string;
  control: Control<any>;
  frequency: number;
  classes?: {
    root: string;
  };
};

function UntilTime({ name, targetName, control, frequency, classes }: Props) {
  const {
    field: { onChange: onTimeChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const {
    field: { onChange: onTargetChange },
  } = useController({
    name: targetName,
    control,
  });

  const time = useWatch({ name, control });
  const target = useWatch({ name: targetName, control });

  const generateValue = () => {
    if (target === 'end') return moment('23:59', TIME_FORMAT);
    if (!time) return null;
    return moment(time, TIME_FORMAT);
  };

  const handleTimeChange = (nextTime: any) => {
    if (!moment(nextTime).isValid()) return;

    onTimeChange(moment(nextTime).format(TIME_FORMAT));

    if (frequency === RRule.DAILY) {
      onTargetChange(null);
    }
  };

  return (
    <div className={clsxm('flex', classes?.root)}>
      <Typography className="mt-12 mr-12 font-500 text-11 text-secondaryLight">at</Typography>
      <div className="w-160">
        <TimePicker
          disabled={target === 'end'}
          value={generateValue()}
          slotProps={{
            textField: {
              size: 'small',
              variant: 'filled',
              InputProps: {
                disableUnderline: true,
                className: 'bg-paper rounded-4',
                classes: {
                  input: 'py-10 rounded-4 bg-paper text-13',
                },
              },
            },
          }}
          onChange={handleTimeChange}
        />
        <ErrorMessage message={error?.message} />
      </div>
    </div>
  );
}

export default UntilTime;
