import clsx from 'clsx';
import _ from 'lodash';
import RSelect, { components as OriginComponents, Props as RSelectProps } from 'react-select';

import { Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    placeholder: {
      backgroundColor: theme.palette.primary.main,
      alignItems: 'center',
    },
    placeholderText: {
      color: '#aaa',
    },
  }),
);

function MultiValue(props: any) {
  if (props.data?._destroy) return null;
  const { children, ...rest } = props;
  return <OriginComponents.MultiValue {...rest}>{children}</OriginComponents.MultiValue>;
}

const Placeholder = (props: any) => {
  const styles = useStyles();
  if (!props?.selectProps?.allOptionsPlaceholder) {
    return <OriginComponents.Placeholder {...props} />;
  }
  return (
    <OriginComponents.Placeholder
      className="flex flex-row items-center"
      {...props}
    >
      <div className={clsx('flex items-center justify-center border-1 w-96 h-32', styles.placeholder)}>
        <Typography className="text-white text-13">All {props.children}</Typography>
      </div>
      <Typography className={clsx('text-13 ml-4', styles.placeholderText)}>Select {props.children}</Typography>
    </OriginComponents.Placeholder>
  );
};

const Input = (props: any) => {
  if (props.isHidden || !props?.selectProps?.allOptionsPlaceholder || !!props.value) {
    return <OriginComponents.Input {...props} />;
  }
  return (
    <OriginComponents.Input
      className={clsx(props.hasValue ? '' : 'ml-104')}
      {...props}
    />
  );
};

const ClearIndicator = (props: any) => {
  const { isMulti, getValue } = props;
  const value = getValue?.();
  const presentValue = _.filter(value, (item: any) => !item._destroy);
  return !isMulti || !!presentValue?.length ? <OriginComponents.ClearIndicator {...props} /> : null;
};

// TODO update styles
function Select(props: { allOptionsPlaceholder?: boolean } & RSelectProps) {
  const theme = useTheme();
  const selectStyles = generateSelectStyles(theme);
  const selectTheme = generateSelectTheme(theme);

  const handleChange = (value: any, actionPayload: any) => {
    const isRemove = actionPayload?.action === 'remove-value';
    const isClear = actionPayload?.action === 'clear';

    // add _destroy true to item
    if (props?.isMulti && isRemove && actionPayload?.removedValue?.recordId) {
      const destroyedItem = {
        ...actionPayload?.removedValue,
        _destroy: true,
      };
      const newValue = [...value, destroyedItem];
      return props?.onChange?.(newValue as any, actionPayload);
    }

    // add _destroy true to all existed items
    if (props?.isMulti && isClear) {
      const newValue =
        _.chain(actionPayload?.removedValues)
          .filter((item: any) => item.recordId)
          .map((item: any) => ({ ...item, _destroy: true }))
          .value() || [];
      return props?.onChange?.(newValue as any, actionPayload);
    }

    return props?.onChange?.(value, actionPayload);
  };

  return (
    <RSelect
      {...props}
      onChange={handleChange}
      styles={{ ...selectStyles, ...props.styles }}
      theme={selectTheme}
      components={{
        MultiValue,
        Placeholder,
        Input,
        ClearIndicator,
      }}
    />
  );
}

const generateSelectStyles = (theme: any) => ({
  multiValue: (provided: any) => ({
    ...provided,
    // borderRadius: 16,
    height: 32,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 8,
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    fontSize: 13,
    fontFamily: theme.typography.fontFamily,
    color: 'white',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    borderRadius: '50%',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: 56,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: theme.typography.fontFamily,
    color: '#aaa',
  }),
  option: (provided: any) => ({
    ...provided,
    fontFamily: theme.typography.fontFamily,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: 8,
    paddingBottom: 8,
  }),
});

const generateSelectTheme = (theme: any) => (selectTheme: any) => ({
  ...selectTheme,
  colors: {
    ...selectTheme.colors,
    primary: theme.palette.primary.main,
  },
});

export default Select;
