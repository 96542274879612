import { TTask } from 'models';
import { useFormContext } from 'react-hook-form';

import DraftQuestionSetting from './questions/draft-question-setting';
import PublishedQuestionSetting from './questions/published-question-setting';

type Props = {
  onSave?: (options: any, onSuccess?: () => void) => void;
};

function QuestionVersionSwitch({ onSave }: Props) {
  const methods = useFormContext<TTask>();

  const [taskStatus, draftVersion] = methods.getValues(['status', 'draftVersion']);
  const havingDraftVersion = draftVersion?.version;

  const isTaskDraft = taskStatus === 'draft';

  const showCurrent = () => {
    if (isTaskDraft) {
      return false;
    }
    if (havingDraftVersion) {
      return false;
    }
    return true;
  };

  return showCurrent() ? <PublishedQuestionSetting /> : <DraftQuestionSetting onSave={onSave} />;
}

export default QuestionVersionSwitch;
