import queryClient from 'app/configs/query-client';
import _ from 'lodash';
import { EmployeeAction } from 'models/employee/action.model';
import { TEmployeeTaskItem, TEmployeeTasks, TTaskFilter } from 'models/employee/task.model';
import { TTodo } from 'models/employee/todo.model';

import { useCreate, useGetList, useGetOne, usePutUpdate } from '../request';

const taskURL = '/api/employee/tasks';

export const employeeTaskKeys = {
  all: ['employeeTasks'] as const,
  lists: () => [...employeeTaskKeys.all, 'list'] as const,
  list: (filters: any) => [...employeeTaskKeys.lists(), { filters }] as const,
  details: () => [...employeeTaskKeys.all, 'detail'] as const,
  detail: (id: number, locationId?: number) => [...employeeTaskKeys.details(), id, locationId] as const,
};

export function refetchTasks() {
  queryClient.invalidateQueries(employeeTaskKeys.lists(), { refetchType: 'all' });
}

export function refetchTask() {
  queryClient.invalidateQueries(employeeTaskKeys.details());
}

export function useGetEmployeeTasks(filters: TTaskFilter, options?: any) {
  // remove empty query
  setTimeout(() => {
    if (_.isEmpty(filters)) {
      queryClient.removeQueries({ queryKey: employeeTaskKeys.list(filters), exact: true });
    }
  }, 200);

  return useGetList<TEmployeeTasks>(
    [...employeeTaskKeys.list(filters)],
    `${taskURL}`,
    filters,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}

export function useGetTask(taskId?: number | string, locationId?: number, options?: any) {
  const url = locationId ? `${taskURL}/${taskId}?location_id=${locationId}` : `${taskURL}/${taskId}`;
  return useGetOne<TEmployeeTaskItem | TTodo>(
    [...employeeTaskKeys.detail(Number(taskId), Number(locationId))],
    url,
    {},
    {
      enabled: !!taskId,
      cacheTime: 0,
      ...options,
    },
    true,
  );
}

export function useAnswerTask(taskId: number | string, responseId: number) {
  return usePutUpdate<any, any>(`${taskURL}/${taskId}/responses/${responseId}`, {
    onSuccess: () => {
      refetchTasks();
      refetchTask();
    },
  });
}

export function useSubmitTask(taskId: number) {
  return useCreate<any, any>(`${taskURL}/${taskId}/submit`);
}

export function useEmployeeFlagTask(taskId: number, responseId: number) {
  return usePutUpdate<any, any>(`${taskURL}/${taskId}/responses/${responseId}/flag`, {
    onSuccess: () => {
      refetchTasks();
      refetchTask();
    },
  });
}

export function useAddEmployeeAction(listId: number, responseId: number) {
  return useCreate<{ auditAction: EmployeeAction }, any>(`${taskURL}/${listId}/responses/${responseId}/actions`, {
    onSuccess: () => {
      refetchTask();
    },
  });
}
