import AlbumMedia from 'app/components/cores/icons/album-media';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  disabled?: boolean;
  value?: any;
  uploadMediaRef?: any;
  isLoading?: boolean;
  uploadProgress?: number;
  onChange?: (files: File[]) => void;
};

const UploadMedia = ({ disabled, value, uploadMediaRef, isLoading, uploadProgress = 0, onChange }: Props) => {
  const classes = useStyles();
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({ multiple: false });
  const previewRef = useRef<any>(null);

  useImperativeHandle(uploadMediaRef, () => ({
    clear() {
      acceptedFiles.splice(0, 1);
    },
  }));

  const hasRemoteMedia = !!value?.id && !!value?.files?.length;
  const remoteMediaUrl = value?.files?.[0]?.url;
  const remoteMediaName = value?.files?.[0]?.name;

  const hasLocalMedia = !!acceptedFiles?.length;
  const localMediaName = acceptedFiles?.[0]?.name;

  const hasMedia = hasRemoteMedia || hasLocalMedia;

  const preview = useCallback((files: File[]) => {
    const file = files?.[0];
    previewRef.current.src = URL.createObjectURL(file);
  }, []);

  // browser file
  useEffect(() => {
    if (_isEmpty(acceptedFiles)) return;
    preview(acceptedFiles);
    onChange?.(acceptedFiles);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  const generateProgress = () => {
    const percentage = uploadProgress * 100;
    return percentage.toFixed(0);
  };

  return (
    <>
      {!hasMedia ? (
        <Box
          className={clsx(
            'flex items-center justify-center w-full duration-300 bg-gray-200 rounded-16 ',
            !disabled && 'cursor-pointer hover:border-gray-500',
          )}
          sx={{ border: '1px dashed #7C808B', height: '184px' }}
          {...(disabled ? {} : getRootProps({}))}
        >
          <input {...getInputProps()} />
          <div className="text-center">
            <ImageOutlinedIcon className="text-secondaryLight text-24" />
            <Typography className="flex items-center mt-8 text-13">Drag and drop or browser your file</Typography>
          </div>
        </Box>
      ) : (
        <Box
          className="flex items-center justify-center w-full bg-gray-200 rounded-16"
          sx={{ border: '1px dashed #7C808B', height: '184px' }}
        >
          <img
            ref={previewRef}
            className="object-cover object-center h-full"
            src={remoteMediaUrl}
            alt={localMediaName || remoteMediaName}
          />
        </Box>
      )}

      {/* loading */}
      {isLoading && (
        <div className="px-16 my-8">
          <Typography className="text-13 font-600">{localMediaName}</Typography>
          <LinearProgress
            variant="determinate"
            value={uploadProgress * 100}
          />
          <Typography
            color="primary"
            className="text-12 font-500"
          >
            Uploading... {generateProgress()}%
          </Typography>
        </div>
      )}

      {/* actions */}
      <div className="flex items-center justify-between px-16 mt-12">
        <button
          disabled={disabled}
          type="button"
          className={clsx('flex items-center space-x-8', disabled && classes.disabledButton)}
          onClick={open}
        >
          <AlbumMedia className="text-20" />
          <Typography className="text-13 text-secondaryMain font-600">Album</Typography>
        </button>
        <button
          disabled={true}
          type="button"
          className={clsx('flex items-center space-x-8', classes.disabledButton)}
        >
          <CameraAltOutlinedIcon className="text-20" />
          <Typography className="text-13 text-secondaryMain font-600">Take Picture</Typography>
        </button>
      </div>
    </>
  );
};

export default UploadMedia;

const useStyles = makeStyles(() => ({
  disabledButton: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}));
