import { Typography } from '@mui/material';

export function DisplayFrequenciesChip() {
  return (
    <div className="flex items-center">
      <Typography className="mr-4 text-11 font-400">Frequencies :</Typography>
      <Typography className="text-11 font-700">Updated</Typography>
    </div>
  );
}
