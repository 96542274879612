import { useMemo, useState } from 'react';
import { clsxm } from 'utils/clsxm';

import _ from '@lodash';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

type Props = {
  side: 'left' | 'right';
  list?: any;
  selectedValues?: any;
  className?: string;
  classes?: {
    container?: string;
  };
  noItemMessage?: string;
  optionLabel?: string;
  optionValue?: string;
  isDisabled?: boolean;
  renderLabel?: (item: any) => React.ReactNode;
  onSelect?: (values: any) => void;
};

function TransferSelection({
  side,
  list,
  selectedValues,
  className,
  classes,
  noItemMessage,
  optionLabel = 'name',
  optionValue = 'id',
  isDisabled,
  renderLabel,
  onSelect,
}: Props) {
  const [searchText, setSearchText] = useState('');

  // sort by name by default, or depends on optionLabel
  // for left column: always show full items of list, even item is _destroy
  // for right column: hide _destroy item from the list
  const sortedList = useMemo(() => {
    const newSortedList = _.sortBy(list, optionLabel);
    if (side !== 'right') return newSortedList;

    return _.filter(newSortedList, item => !item._destroy);
  }, [list, optionLabel, side]);

  const searchList = useMemo(() => {
    if (!searchText) return sortedList;
    return _.filter(sortedList, (item: any) => item?.[optionLabel]?.toLowerCase().includes(searchText?.toLowerCase()));
  }, [searchText, sortedList, optionLabel]);

  const handleSelect = (value: any) => (event: any) => {
    const isChecked = event.target.checked;
    if (side === 'left') return handleSelectLeftSide(isChecked, value);
    return handleSelectRightSide(isChecked, value);
  };

  // select item on the left column
  const handleSelectLeftSide = (isChecked: boolean, value: any) => {
    const nextSelectedValues = _.cloneDeep(selectedValues);

    if (isChecked) {
      nextSelectedValues.push(value);
    } else {
      _.remove(nextSelectedValues, { [optionValue]: value?.[optionValue] });
    }

    onSelect?.(nextSelectedValues);
  };

  // select item on the right column
  const handleSelectRightSide = (isChecked: boolean, value: any) => {
    const nextSelectedValues = _.cloneDeep(selectedValues);

    if (isChecked) {
      nextSelectedValues.push(value);
    } else {
      _.remove(nextSelectedValues, { [optionValue]: value?.[optionValue] });
    }

    onSelect?.(nextSelectedValues);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchText(value);
  };

  return (
    <div className={clsxm('flex flex-col pt-12', className)}>
      <div className="px-24 mb-12">
        <TextField
          variant="outlined"
          placeholder="Search..."
          className="w-full"
          InputProps={{
            classes: { input: 'py-8' },
            endAdornment: searchText ? (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleClearSearch}
                >
                  <CloseIcon className="text-16" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>

      {!searchList?.length && <Typography className="italic text-center text-11">{noItemMessage}</Typography>}

      <div className={clsxm('overflow-auto flex flex-col', classes?.container)}>
        {searchList?.map((item: any) => {
          return (
            <FormControlLabel
              key={item?.[optionValue]}
              className="px-24"
              control={
                <Checkbox
                  disabled={isDisabled}
                  checked={!!_.find(selectedValues, { [optionValue]: item?.[optionValue] })}
                  checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                />
              }
              label={renderLabel ? renderLabel?.(item) : item?.[optionLabel]}
              classes={{
                label: 'text-13',
              }}
              onChange={handleSelect(item)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default TransferSelection;
