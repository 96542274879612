import { AxiosResponse } from 'axios';
import { TResponsePagination } from 'models';
import { useEffect, useRef } from 'react';
import api from 'utils/api';

import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

const useAbortController = () => {
  const controller = useRef(new AbortController());

  if (controller.current === null) {
    controller.current = new AbortController();
  }

  useEffect(() => {
    return () => {
      controller.current.abort('Component un-mount');
    };
  }, []);

  return controller;
};

const useGetList = <T>(key: any, url: string, params: any, options?: any, isNotSignal?: boolean) => {
  const controller = useAbortController();

  const signal = !isNotSignal ? controller.current.signal : undefined;

  const service = async () => {
    const response: AxiosResponse<T> = await api.get(`${url}`, {
      params,
      signal,
    });

    return response?.data;
  };
  return useQuery(key, () => service(), options);
};

const useGetOne = <T>(key: any, url: string, params?: any, options?: any, isNotSignal?: boolean) => {
  const controller = useAbortController();

  const signal = !isNotSignal ? controller.current.signal : undefined;

  const service = async () => {
    const response: AxiosResponse<T> = await api.get(`${url}`, {
      params,
      signal,
    });

    return response?.data;
  };
  return useQuery(key, () => service(), options);
};

const useCreate = <T, U>(url: string, options?: any) => {
  const controller = useAbortController();
  return useMutation(async (payload: T) => {
    try {
      const response: AxiosResponse<U> = await api.post(`${url}`, payload, {
        signal: controller.current.signal,
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const useUpdate = <T, U>(url: string, options: any) => {
  const controller = useAbortController();
  return useMutation(async (payload: T) => {
    try {
      const response: AxiosResponse<U> = await api.patch(`${url}`, payload, {
        signal: controller.current.signal,
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const usePutUpdate = <T, U>(url: string, options: any) => {
  const controller = useAbortController();
  return useMutation(async (payload: T) => {
    try {
      const response: AxiosResponse<U> = await api.put(`${url}`, payload, {
        signal: controller.current.signal,
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const useAction = (url: string, options: any) => {
  const controller = useAbortController();
  return useMutation(async ({ id, action }: { id: number; action: string }) => {
    try {
      const response: AxiosResponse<any> = await api.post(
        `${url}/${id}/${action}`,
        {},
        {
          signal: controller.current.signal,
        },
      );
      return response;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const usePatchAction = (url: string, options: any) => {
  const controller = useAbortController();
  return useMutation(async ({ id, action, payload }: { id: number; action: string; payload: any }) => {
    try {
      const response: AxiosResponse<any> = await api.patch(`${url}/${id}/${action}`, payload, {
        signal: controller.current.signal,
      });
      return response;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const useDelete = (url: string, options: any) => {
  const controller = useAbortController();
  return useMutation(async ({ id }: { id: number }) => {
    try {
      const response: AxiosResponse<any> = await api.delete(`${url}/${id}`, {
        signal: controller.current.signal,
      });
      return response?.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

const useGetInfiniteList = <T>(key: any, url: string, params: any, options?: any) => {
  const controller = useAbortController();

  const service = async (pageParam: number) => {
    const response: AxiosResponse<T> = await api.get(url, {
      params: {
        page: pageParam,
        ...params,
      },
      signal: controller.current.signal,
    });

    return response?.data;
  };

  const infiniteQuery = useInfiniteQuery<TResponsePagination<T>>({
    queryKey: key,
    queryFn: ({ pageParam = 1 }) => service(pageParam),
    getNextPageParam: (lastPage: Omit<TResponsePagination<T>, 'records'>) => {
      return lastPage.page * lastPage.perPage >= lastPage.total ? undefined : lastPage.page + 1;
    },
    ...options,
  });

  const data = infiniteQuery.data?.pages?.flatMap(page => page.records);

  return {
    ...infiniteQuery,
    data,
  };
};

const useExportCSV = (url: string, options?: any) => {
  return useMutation(async ({ filters }: { filters: any }) => {
    try {
      const params = { ...filters };
      delete params.page;
      delete params.per_page;
      const response = await api.post(url, null, { params });
      return response?.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  }, options);
};

export {
  useGetList,
  useGetOne,
  useCreate,
  useUpdate,
  usePutUpdate,
  useAction,
  usePatchAction,
  useDelete,
  useGetInfiniteList,
  useExportCSV,
};
