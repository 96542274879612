import ConfirmDialog from 'app/components/cores/confirm-dialog';
import useTask from 'app/hooks/use-task';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { Control, useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

type Props = {
  control?: Control;
  name: string;
};

const SALT_OPS_OPTIONS = [
  { label: 'Date Time', value: 'datetime' },
  { label: 'Date', value: 'date' },
  { label: 'Time', value: 'time' },
];

const DateTimeOptions = ({ name, control }: Props) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });

  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  const { isShowAudit } = useTask();

  const scoreEnabledName = `${name}.scoreEnabled`;
  const scoreSettingsName = `${name}.scoreSettings`;
  const comparisonsNotificationName = `${name}.notificationConditionals.0.comparisons`;
  const comparisonsConditionalTasksName = `${name}.conditionalTasks.0.comparisons`;
  const comparisonsActionItemName = `${name}.conditionalActions.0.comparisons`;
  const flagConditionalsName = `${name}.flagConditionals.0.comparisons`;

  const comparisonsNotify = getValues(comparisonsNotificationName);
  const comparisonsConditionalTasks = getValues(comparisonsConditionalTasksName);
  const comparisonsActionItem = getValues(comparisonsActionItemName);
  const flagConditionals = getValues(flagConditionalsName);

  const {
    field: { value: scoreEnabled, onChange: onChangeScoreEnabled },
  } = useController({
    control,
    name: scoreEnabledName,
  });

  const {
    field: { value: scoreSettings, onChange: onChangeScoreSettings },
  } = useController({
    control,
    name: scoreSettingsName,
  });

  const hasScoreSettings = scoreEnabled && scoreSettings?.length > 0;

  const hasComparisons =
    hasScoreSettings ||
    comparisonsNotify?.length > 0 ||
    comparisonsConditionalTasks?.length > 0 ||
    comparisonsActionItem?.length > 0 ||
    flagConditionals?.length > 0;

  const handleOpenConfirmDialog = (dateTimeType: string) => {
    const message = isShowAudit
      ? 'Changing Date Time Options will remove the current Action Item, Flagged Item, Notify, List Trigger Logic and Score Settings, are you sure you want to continue?'
      : 'Changing Date Time Options will remove the current Notify, List Trigger Logic and Score Settings, are you sure you want to continue?';

    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Date Time Options Change"
            message={message}
            statusVariant="warning"
            confirmButtonLabel="Continue"
            cancelButtonLabel="Cancel"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              onChange({
                ...value,
                dateTimeType,
                notificationConditionals: [
                  {
                    ...value.notificationConditionals[0],
                    comparisons: undefined,
                    excludedFromSaltInbox: false,
                    message: null,
                    overrideSubject: null,
                    sendToRoles: [],
                    sendToRolesDetail: [],
                    sendToUsers: [],
                    sendToUsersDetail: [],
                  },
                ],
                conditionalTasks: [],
                conditionalActions: [],
                flagConditionals: [],
              });

              onChangeScoreEnabled(false);
              onChangeScoreSettings([]);
              dispatch(closeDialog({}));
            }}
          />
        ),
      }),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateTimeType = (event.target as HTMLInputElement).value;

    const handler =
      hasComparisons || hasScoreSettings
        ? () => handleOpenConfirmDialog(dateTimeType)
        : () => onChange({ ...value, dateTimeType });

    handler();
  };

  return (
    <div className="flex flex-col pb-16 space-y-8">
      <Typography className="text-12 font-600">Date Time Options</Typography>
      <div className="flex flex-row justify-between space-x-8">
        <FormControl className="w-full">
          <RadioGroup
            value={value?.dateTimeType}
            onChange={handleChange}
          >
            {SALT_OPS_OPTIONS.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    size="small"
                    className="ml-auto text-11"
                  />
                }
                label={option.label}
                labelPlacement="start"
                classes={{
                  label: 'text-11 font-600',
                  root: 'mx-0',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default DateTimeOptions;
