import { SingleSelect } from 'app/components/cores/select';
import { CONDITIONAL_DEFAULT_OPTIONS, CONDITIONAL_NUMERIC_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TItem, TItemConditional, TItemConditionalOption } from 'models';
import { useMemo } from 'react';
import { Props as ReactSelectProps } from 'react-select';

type Props = {
  selectedConditionalItem: TItem;
  isCustomize?: boolean;
  conditionalComparison?: TItemConditional[];
} & ReactSelectProps;

export function MultipleLogicSelect({
  selectedConditionalItem,
  isCustomize,
  value,
  conditionalComparison,
  ...reactSelectProps
}: Props) {
  const options = useMemo(() => {
    if (!isCustomize) {
      return CONDITIONAL_DEFAULT_OPTIONS;
    }

    // this is handled for numeric only, update for other types if needed
    return _.map(CONDITIONAL_NUMERIC_OPTIONS, (option: TItemConditionalOption) => {
      const isDisabled = !!_.find(conditionalComparison, { conditionalLogic: option.conditionalLogic });
      return { ...option, isDisabled };
    });
  }, [conditionalComparison, isCustomize]);

  const getValue = (selectionOption: any, _options: any) => {
    const nextOption = _.cloneDeep(selectionOption);

    // for default logic
    if (!isCustomize || (nextOption?.conditionalLogic && nextOption?.conditionalLabel)) {
      return nextOption;
    }

    if (!nextOption?.logic) {
      return undefined;
    }

    // TODO set value for customize logic here

    return _.find(_options, { conditionalLogic: nextOption.conditionalLogic }) || nextOption;
  };

  const styles = {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: isCustomize ? 160 : 140,
    }),
    control: (baseStyles: any) => ({
      ...baseStyles,
      border: 0,
      backgroundColor: '#F5D02F',
      borderRadius: 16,
      height: 24,
      minHeight: 24,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
      paddingLeft: 8,
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
      },
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      width: isCustomize ? 160 : 140,
      borderRadius: 16,
      overflow: 'hidden',
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      padding: 0,
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
        fontWeight: '600',
        color: state.isDisabled ? '#AAA' : '#1F242F',
      },
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      '& *': {
        fontSize: '11px !important',
        lineHeight: 2,
      },
    }),
  };

  const generateTheme = (baseTheme: any) => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary50: '#eee',
      primary25: '#eee',
      primary: '#F5D02F',
    },
  });

  return (
    <SingleSelect
      options={options}
      menuPlacement="top"
      getOptionLabel={(option: any) => option.conditionalLabel}
      getOptionValue={(option: any) => option.conditionalLogic}
      styles={styles}
      theme={generateTheme}
      isSearchable={false}
      value={getValue(value, options)}
      {...reactSelectProps}
    />
  );
}
