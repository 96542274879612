import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function AdminIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2794 7.14847C12.2794 8.79628 10.9447 10.1309 9.29691 10.1309C7.6491 10.1309 6.31445 8.79628 6.31445 7.14847C6.31445 5.50066 7.6491 4.16602 9.29691 4.16602C10.9447 4.16602 12.2794 5.50066 12.2794 7.14847ZM10.7882 7.14848C10.7882 6.3283 10.1171 5.65725 9.29694 5.65725C8.47676 5.65725 7.80571 6.3283 7.80571 7.14848C7.80571 7.96865 8.47676 8.63971 9.29694 8.63971C10.1171 8.63971 10.7882 7.96865 10.7882 7.14848ZM4.82326 14.6046C4.97238 14.1349 6.73949 13.352 8.52151 13.1581L10.0426 11.6669C9.75177 11.6371 9.53554 11.6222 9.29694 11.6222C7.30615 11.6222 3.33203 12.6213 3.33203 14.6046V16.0958H10.0426L8.55133 14.6046H4.82326ZM12.6299 14.3586L16.4549 10.5037L17.4987 11.5551L12.6299 16.4687L10.0426 13.859L11.0865 12.8077L12.6299 14.3586Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(AdminIcon);
