import ControlledSelectList from 'app/components/cores/items-editor/list-trigger/controlled-select-list';
import { getTimeTypeOptionsByLogic, getValueOptionsInfoByLogic } from 'domains/date-time-logic.domain';
import { TDateTimeLogic } from 'models/date-time-condition.model';
import { Control } from 'react-hook-form';
import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { Theme, useTheme } from '@mui/material/styles';

const generateStyles = (theme: Theme): StylesConfig => ({
  container: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
  }),
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    border: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    height: 24,
    minHeight: 24,
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  valueContainer: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: 0,
    paddingLeft: 8,
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    '& *': {
      fontSize: '11px !important',
    },
  }),
  input: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: 0,
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    borderRadius: 16,
    overflow: 'hidden',
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles: CSSObjectWithLabel, state: any) => ({
    ...baseStyles,
    '& *': {
      fontSize: '11px !important',
      fontWeight: '600',
      color: state.isDisabled ? '#AAA' : '#1F242F',
    },
  }),
  placeholder: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    '& *': {
      fontSize: '11px !important',
      lineHeight: 2,
    },
  }),
});

type Props = {
  name: string;
  control?: Control;
  dateTimeType?: string;
  logic: TDateTimeLogic;
  classes?: {
    control?: string;
  };
  selectProps?: {
    menuPortalTarget?: HTMLElement | null;
    menuPosition?: 'fixed' | 'absolute';
    menuPlacement?: 'top' | 'bottom' | 'auto';
  };
};

const ConditionInputs = ({ name, control, logic, dateTimeType, classes, selectProps }: Props) => {
  const theme = useTheme();
  const styles = generateStyles(theme);
  const valueName = `${name}.value`;
  const timeTypeName = `${name}.timeType`;

  function filterValueOptions() {
    return getValueOptionsInfoByLogic(logic)?.options ?? [];
  }

  function filterTimeTypeOptions() {
    return getTimeTypeOptionsByLogic(logic, dateTimeType)?.options ?? [];
  }

  return (
    <div className="flex-1 space-y-4">
      <ControlledSelectList
        options={filterValueOptions()}
        name={valueName}
        control={control}
        menuPlacement={selectProps?.menuPlacement || 'top'}
        placeholder="Select"
        styles={styles}
        isClearable={false}
        maxMenuHeight={200}
        classNames={{
          control: () => classes?.control || '',
        }}
        {...selectProps}
      />
      <ControlledSelectList
        options={filterTimeTypeOptions()}
        name={timeTypeName}
        control={control}
        menuPlacement={selectProps?.menuPlacement || 'top'}
        placeholder="Select"
        styles={styles}
        isClearable={false}
        maxMenuHeight={200}
        classNames={{
          control: () => classes?.control || '',
        }}
        {...selectProps}
      />
    </div>
  );
};
export default ConditionInputs;
