import ErrorMessage from 'app/components/cores/form/error-message';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import { Control, useController } from 'react-hook-form';

import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import DatePicker from './date-picker';

type Props = {
  name: string;
  control?: Control;
  rules?: any;
  dateFormat?: string;
  noDefault?: boolean;
} & DatePickerProps<Moment>;

function ControlledDatePicker({ name, control, rules, dateFormat, noDefault, ...datePickerProps }: Props) {
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: noDefault ? undefined : moment(),
  });

  useEffect(() => {
    if (!value) {
      if (noDefault) return;
      onChange(moment());
    }
  }, [value, onChange, noDefault]);

  const format = dateFormat ?? 'YYYY-MM-DD';

  const hasError = !!error?.message;

  const handleChange = (newValue: Moment | null) => {
    onChange(newValue?.format(format));
  };

  return (
    <div>
      <DatePicker
        {...datePickerProps}
        ref={ref}
        value={moment(value, format) || null}
        onChange={handleChange}
      />
      {hasError && <ErrorMessage message={error?.message} />}
    </div>
  );
}

export default ControlledDatePicker;
