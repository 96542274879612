import useOnClickOutside from 'app/hooks/use-on-click-outside';
import { TMeasurementType, UNIT_OPTIONS, groupOptions } from 'models';
import { ElementRef, useMemo, useRef } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { styled } from '@mui/material/styles';

type Props = {
  value?: string;
  disabled?: boolean;
  unit: string;
  measurement: TMeasurementType;
  status?: string;
  onChange?: (value?: number) => void;
  onAnswer?: (value?: number) => void;
  onEnter?: (value?: number) => void;
};

const StyledNumericInput = styled(NumericFormat)<{ disabled?: boolean }>(() => {
  return {
    ':focus-visible': {
      outline: 'none',
    },
  };
});

const NumericInput = ({ value, onChange, disabled = false, unit, measurement, status, onAnswer, onEnter }: Props) => {
  const inputRef = useRef<ElementRef<'div'>>(null);
  const tmpValue = useRef<any>(null);
  const isFocused = useRef(false);

  useOnClickOutside(inputRef, () => {
    if (!isFocused.current || tmpValue.current === value) return;
    onAnswer?.(Number(value));
    isFocused.current = false;
  });

  const handleChange = ({ floatValue }: NumberFormatValues) => {
    onChange?.(floatValue);
  };

  const unitLabel = useMemo(() => {
    if (!unit) return '';
    if (measurement === 'custom') return unit;

    const unitOption = groupOptions
      .find(group => group.label === measurement)
      ?.options.find(option => option.value === unit);

    return unitOption?.display || '';
  }, [unit, measurement]);
  const canBeNegative = useMemo(() => UNIT_OPTIONS.TEMP.some(({ value: tempValue }) => tempValue === unit), [unit]);

  const getBgColorByStatus = () => {
    if (status === 'editing') return 'grey.200';
    if (status === 'overdue') return 'overdue.main';
    return 'primary.main';
  };

  const getTextColorByStatus = () => {
    if (status === 'editing') return 'black';
    return value ? 'common.white' : 'text.primary';
  };

  const handleFocus = () => {
    isFocused.current = true;
    tmpValue.current = value;
  };

  const handleBlur = () => {
    isFocused.current = false;
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      onEnter?.(Number(value));
      isFocused.current = false;
    }
  };

  return (
    <div ref={inputRef}>
      <StyledNumericInput
        disabled={disabled}
        value={value || ''}
        allowNegative={canBeNegative}
        className="w-full h-40 text-center outline-none rounded-20"
        placeholder={unit ? `0 ${unitLabel}` : '0'}
        suffix={unit ? ` ${unitLabel}` : ''}
        sx={{
          backgroundColor: value ? getBgColorByStatus() : 'grey.200',
          color: getTextColorByStatus(),
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleEnter}
        onValueChange={handleChange}
      />
    </div>
  );
};
export default NumericInput;
