import { SelectBox } from 'app/components/cores/employee/questions';
import QuestionLayoutMapper from 'app/components/cores/employee/questions/question-layout-mapper';
import SelectInfiniteScroll from 'app/components/cores/select-infinite-scroll';
import { useGetAreas } from 'app/hooks/api/areas';
import { useEditHistoryTask } from 'app/hooks/api/manager/histories';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import _isEmpty from 'lodash/isEmpty';
import { TItem, TQuestionLayoutView } from 'models';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material';

type Props = {
  data: TItem;
  name: string;
  listId: number;
  readOnly?: boolean;
  view: TQuestionLayoutView;
  isAudit?: boolean;
  canAuditInteractions?: boolean;
};

const Area = ({ data, name, listId, readOnly, view, isAudit, canAuditInteractions = true }: Props) => {
  const theme = useTheme();
  const { resetField, setValue, setError, clearErrors, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name });
  const location = getValues('location');

  const { mutateAsync: answerTask, isLoading } = useEditHistoryTask({
    taskId: listId,
    responseId: value?.id,
  });
  const { showError, showSuccess } = useShowMessage();
  const isRequired = value?.item?.required;
  const isValidAnswer = Array.isArray(value?.answer) && value?.answer?.length > 0;

  const { data: areaData, isLoading: isGettingAreas } = useGetAreas({
    'q[location_salt_id_eq]': location?.saltId,
    enabled: !!location?.saltId,
  });
  const areaOptions = areaData?.records || [];

  const handleChange = (values: any) => {
    const nextAnswer = _isEmpty(values) ? [] : values;
    onChange({ ...value, answer: nextAnswer });
  };

  const checkAnswerIsValid = () => {
    if (isRequired && !isValidAnswer) {
      setError(name, { type: 'manual', message: 'This field is required' });
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!checkAnswerIsValid()) return;
    const additionalPayload = { comment: value?.comment ?? '' };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  useEffect(() => {
    if (isRequired && isValidAnswer) clearErrors(name);
  }, [isValidAnswer, isRequired, clearErrors, name]);

  const answer = async (payload: any) => {
    try {
      const res = await answerTask(payload);
      resetField(name, { defaultValue: { ...value, ...res } });
      showSuccess('The answer has been edited successfully');
      setValue(`${name}-isEditing`, false);
    } catch (error) {
      showError('Could not submit the answer');
    }
  };

  return (
    <QuestionLayoutMapper
      view={view}
      question={data}
      preview
      task={value}
      name={name}
      onSaveEdit={handleSave}
      isLoading={isLoading}
      isAudit={isAudit}
      canAuditInteractions={canAuditInteractions}
    >
      {!readOnly ? (
        <SelectInfiniteScroll
          value={value?.answer || []}
          onChange={handleChange}
          isMulti={true}
          isLoading={isGettingAreas}
          isClearable={true}
          defaultOptions={true}
          className="w-full"
          name={name}
          placeholder="Select area"
          styles={{
            indicatorsContainer: (_props: any) => ({
              ..._props,
              marginTop: 7,
            }),
            multiValue: (_props: any) => ({
              ..._props,
              padding: 4,
              backgroundColor: theme.palette.primary.main,
            }),
          }}
          options={areaOptions}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => option.name}
        />
      ) : (
        <SelectBox
          modalTitle="Areas"
          placeholder="Select Areas"
          options={[]}
          disabled={readOnly}
          value={value?.answer}
        />
      )}
    </QuestionLayoutMapper>
  );
};
export default Area;
