import PerformanceContext, { TPerformanceContext } from 'app/components/performances/context';
import { useGetAuditPerformanceStats } from 'app/hooks/api/audit-performance';
import { useDebounceValue } from 'app/hooks/use-debounce-value';
import clsx from 'clsx';
import { getFormattedDateRange } from 'domains/locations/performance';
import { TDateRange } from 'models';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { calcPagination } from 'utils/pagination';
import { getFilterFromURL } from 'utils/url';

import { Card, Chip, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import { makeStyles } from '@mui/styles';

import AuditTable from './audit-table-elements/table';
import ExportingAuditAction from './exporting-audit-action';

const useStyles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 !important',
    },
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      overflow: 'auto',
    },
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  header: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
    },
  },
  headerButton: {
    [theme.breakpoints.down('sm')]: {
      width: 'unset !important',
      paddingLeft: '4px !important',
      paddingRight: '4px !important',
    },
  },
  statisticLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem !important',
    },
  },
  search: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  pagination: {
    [theme.breakpoints.down('lg')]: {
      backgroundColor: 'white',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
    },
  },
}));

type Props = {
  locationId: number;
  period: string;
  customPeriod: TDateRange;
  locationName?: string;
};

const LIST_TYPES = [
  { value: 'list', label: 'List' },
  { value: 'auditor', label: 'Auditor' },
];

const AuditPerformanceList = ({ locationId, period, customPeriod, locationName }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { statsType, setStatsType } = useContext(PerformanceContext) as TPerformanceContext;
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<any>(getFilterFromURL(location?.search));
  const { from, to } = getFormattedDateRange(period, customPeriod);
  const [params, setParams] = useState({ page: 1, per_page: 50 });
  const debouncedSearchText = useDebounceValue(searchText, 500);
  const { data: statistics, isLoading } = useGetAuditPerformanceStats(locationId, statsType as 'list' | 'auditor', {
    ...params,
    'q[from_date]': from ?? '',
    'q[to_date]': to ?? '',
    [statsType === 'auditor' ? 'q[full_name_cont]' : 'q[name_cont]']: debouncedSearchText,
  });

  const data = statistics?.records ?? [];
  const { pageCount } = calcPagination(statistics?.total, statistics?.perPage);

  const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFetchData = (fetchParams: any) => {
    setParams({ ...params, ...fetchParams });
  };

  const handleStatsTypeChange = (nextStatsType: string) => {
    setStatsType(nextStatsType);
  };

  return (
    <Card className={clsx('h-full p-24 bg-white rounded-8', classes.root)}>
      <div className={clsx('flex items-center justify-between', classes.header)}>
        <div className="flex items-center">
          <Typography className={clsx('text-18 font-600 text-secondaryMain', classes.statisticLabel)}>
            Statistics
          </Typography>

          <ExportingAuditAction
            isGettingStatistic={false}
            locationId={locationId}
            statsType={statsType}
            from={from}
            to={to}
            locationName={locationName}
          />
        </div>

        <div className="space-x-8">
          {LIST_TYPES.map(chip => (
            <Chip
              key={chip.value}
              label={chip.label}
              className={clsx('px-0 py-8 h-36 w-104', classes.headerButton)}
              onClick={() => handleStatsTypeChange(chip.value)}
              color={statsType === chip.value ? 'primary' : 'default'}
              sx={{ borderRadius: '32px' }}
            />
          ))}
        </div>
      </div>

      <div className={clsx('mt-16', classes.search)}>
        <Input
          placeholder="Search"
          className="flex flex-1 px-16 h-40 text-black rounded-4"
          disableUnderline={true}
          fullWidth={true}
          value={searchText || ''}
          inputProps={{
            'aria-label': 'search',
          }}
          sx={{ backgroundColor: '#F5F5F5' }}
          onChange={handleSearchText}
        />
      </div>

      <div className={clsx('mt-16', classes.tableContainer)}>
        <AuditTable
          statsType={statsType}
          data={data}
          pageCount={pageCount}
          totalDataCount={statistics?.total ?? 0}
          isLoading={isLoading}
          location={location}
          history={history}
          searchText={searchText}
          filters={filters}
          setFilters={setFilters}
          onFetchData={handleFetchData}
          classes={{
            table: classes.table,
            pagination: classes.pagination,
          }}
        />
      </div>
    </Card>
  );
};

export default AuditPerformanceList;
