import { SingleSelect } from 'app/components/cores/select';
import clsx from 'clsx';
import _ from 'lodash';
import { TOption } from 'models';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  interval: number;
  label: string;
  options?: TOption[];
  onChange?: (nextInterval: number) => void;
};

export function FrequencyEvery({ interval, label, options, onChange }: Props) {
  const classes = useStyles();

  const getValue = (_interval?: number) => {
    return options?.find((item: any) => item.value === _interval);
  };

  const handleIntervalChange = (item: any) => {
    const nextInterval = item.value;
    onChange?.(nextInterval);
  };

  return (
    <div className={clsx('flex items-center', classes.root)}>
      <Typography className={clsx('text-13 font-600 text-secondaryMain', classes.label)}>Every</Typography>
      <div className={clsx('mx-8', classes.selectContainer)}>
        <SingleSelect
          placeholder={_.first(options)?.label}
          value={getValue(interval)}
          options={options}
          onChange={handleIntervalChange}
        />
      </div>
      <Typography className="mx-8 text-13 font-600 text-secondaryMain">{label}</Typography>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 42,
  },
  label: {
    width: 86,
  },
  selectContainer: {
    width: 120,
  },
}));
