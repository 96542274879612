import DateRange from 'app/components/cores/date-range';
import MultipleSelect from 'app/components/cores/select/multiple-select';
import { useGetInfiniteTasks } from 'app/hooks/api/manager/tasks';
import _ from 'lodash';
import { TTask, TUserLocation } from 'models';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';

import { ListValueContainer, LocationValueContainer, Menu, getDefaultDateRange } from './commons';
import { TDateRangeValue, TListValue, TLocationValue, TReportFilter } from './type';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex !important',
  },
  dateRangeInput: {
    height: '3.2rem !important',
    borderRadius: 8,
  },
}));

type Props = {
  defaultFilter?: any;
  isHideListFilter?: boolean;
  onChange?: (filter: TReportFilter) => void;
};

function ReportFilter({ defaultFilter, isHideListFilter, onChange }: Props) {
  // ===== lists
  const [listFilters, setListFilters] = useState({
    per_page: 200,
    status: 'published',
    'q[name_cont]': undefined,
    'q[s]': 'name asc',
  });

  const {
    data: listOptions,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingLists,
    isFetchingNextPage,
  } = useGetInfiniteTasks(listFilters, {
    enabled: !isHideListFilter,
  });

  const [selectedLists, setSelectedLists] = useState<TListValue[]>([]);

  const handleSelectList = (values: any) => {
    const nextSelectedLists = _.map(values, (list: TTask) => _.pick(list, ['id', 'name'])) || [];
    const nextFilter = generateFilter(nextSelectedLists, selectedLocations, selectedDateRange);
    setSelectedLists(nextSelectedLists);
    onChange?.(nextFilter);
  };
  // end lists

  // ===== locations
  const locations = useSelector(({ auth }: any) => auth.user.locations);
  const locationOptions: TUserLocation[] = useMemo(() => _.orderBy(locations, 'name'), [locations]);
  const [selectedLocations, setSelectedLocations] = useState<TLocationValue[]>([]);

  const handleSelectLocation = (values: any) => {
    const nextSelectedLocations =
      _.map(values, (location: TUserLocation) => _.pick(location, ['externalId', 'name'])) || [];
    const nextFilter = generateFilter(selectedLists, nextSelectedLocations, selectedDateRange);
    setSelectedLocations(nextSelectedLocations);
    onChange?.(nextFilter);
  };
  // end locations

  // ===== date range
  const [selectedDateRange, setSelectedDateRange] = useState<TDateRangeValue>(getDefaultDateRange(defaultFilter));

  const handleDateRangeChange = (values: TDateRangeValue) => {
    const nextFilter = generateFilter(selectedLists, selectedLocations, values);
    setSelectedDateRange(values);
    onChange?.(nextFilter);
  };
  // end date range

  // ===== common
  const classes = useStyles();
  const isMounted = useRef(false);

  function generateFilter(
    _selectedLists: TListValue[],
    _selectedLocations: TLocationValue[],
    _selectedDateRange: TDateRangeValue,
  ) {
    const listFilterValue = {
      'q[task_root_id_in]': _.map(_selectedLists, 'id'),
    };
    const locationFilterValue = {
      'q[location_id_in]': _.map(_selectedLocations, 'externalId'),
    };
    const dateRangeFilterValue = {
      'q[display_at_gteq]': moment(_selectedDateRange?.startDate).format('DD/MM/YYYY'),
      'q[display_at_lteq]': moment(_selectedDateRange?.endDate).format('DD/MM/YYYY'),
    };
    return {
      ...listFilterValue,
      ...locationFilterValue,
      ...dateRangeFilterValue,
    };
  }

  // set default filter value when page reloaded
  useEffect(() => {
    if (isMounted.current || !defaultFilter || (!listOptions && !isHideListFilter) || !locations) return;
    isMounted.current = true;

    const defaultListFilterValue = defaultFilter?.['q[task_root_id_in]'];
    const defaultLocationFilterValue = defaultFilter?.['q[location_id_in]'];

    // set default lists
    if (defaultListFilterValue) {
      const filteredLists = _.filter(listOptions, (list: TTask) => {
        return Array.isArray(defaultListFilterValue)
          ? _.find(defaultListFilterValue, d => Number(d) === list?.id)
          : Number(defaultListFilterValue) === list?.id;
      });

      const nextSelectedLists = _.map(filteredLists, (list: TTask) => _.pick(list, ['id', 'name'])) as any;

      if (nextSelectedLists?.length) {
        setSelectedLists(nextSelectedLists);
      }
    }

    // set default locations
    if (defaultLocationFilterValue) {
      const filteredLocations = _.filter(locations, (location: TUserLocation) => {
        return Array.isArray(defaultLocationFilterValue)
          ? _.find(defaultLocationFilterValue, d => Number(d) === location?.externalId)
          : Number(defaultLocationFilterValue) === location?.externalId;
      });

      const nextSelectedLocations = _.map(filteredLocations, (location: TUserLocation) =>
        _.pick(location, ['externalId', 'name']),
      ) as any;

      if (nextSelectedLocations?.length) {
        setSelectedLocations(nextSelectedLocations);
      }
    }
  }, [defaultFilter, listOptions, isHideListFilter, locations]);

  const handleSearchListDebounce = (searchText: string, actionMeta?: any) => {
    if (actionMeta?.action === 'menu-close' && !searchText) return;

    setListFilters((prevFilter: any) => {
      const nextFilters = _.cloneDeep(prevFilter);
      nextFilters['q[name_cont]'] = searchText;
      return nextFilters;
    });
  };

  const handleLoadMoreList = () => {
    if (!hasNextPage || isFetchingNextPage) return;
    fetchNextPage();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchList = useCallback(_.debounce(handleSearchListDebounce, 500), []);

  return (
    <div className="flex items-center pr-24 space-x-8">
      {!isHideListFilter && (
        <div className="w-232">
          <MultipleSelect
            placeholder="Lists"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            filterOption={null}
            isLoading={isFetchingLists || isFetchingNextPage}
            value={selectedLists}
            options={listOptions || []}
            getOptionLabel={option => `${(option as TTask).name}`}
            getOptionValue={option => `${(option as TTask).id}`}
            components={{ Menu, ValueContainer: ListValueContainer }}
            onChange={handleSelectList}
            onInputChange={handleSearchList}
            onLoadMore={handleLoadMoreList}
          />
        </div>
      )}

      <div className="w-232">
        <MultipleSelect
          placeholder="Locations"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          value={selectedLocations}
          options={locationOptions || []}
          getOptionLabel={option => `${(option as TUserLocation).name}`}
          getOptionValue={option => `${(option as TUserLocation).externalId}`}
          components={{ ValueContainer: LocationValueContainer }}
          onChange={handleSelectLocation}
        />
      </div>
      <div className="w-200">
        <DateRange
          noAllTime={true}
          classes={{
            input: classes.dateRangeInput,
          }}
          value={selectedDateRange}
          onChange={handleDateRangeChange}
        />
      </div>
    </div>
  );
}

export default ReportFilter;
