import { AttachmentViewer } from 'app/components/cores/employee/questions';
import { TItem } from 'models';

import { Box, Typography } from '@mui/material';

type Props = {
  data: TItem;
};

const Section = ({ data: { prompt, description, attachmentsAttributes } }: Props) => {
  const attachments = attachmentsAttributes || [];

  return (
    <Box
      className="space-y-8"
      sx={{
        borderLeftWidth: '4px',
        borderLeftColor: 'primary.light',
        px: '24px',
        pt: '8px',
        pb: '16px',
      }}
    >
      {/* Start of prompt */}

      {prompt && (
        <Typography
          className="font-bold text-16"
          sx={{
            color: 'primary.main',
          }}
        >
          {prompt}
        </Typography>
      )}
      {/* End of prompt */}

      {/* Start of description */}
      {description && <Typography>{description}</Typography>}
      {/* End of description */}

      {/* attachments */}
      {attachments && attachments?.length > 0 && <AttachmentViewer attachments={attachments} />}
      {/* end of attachments */}
    </Box>
  );
};

export default Section;
