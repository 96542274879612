import clsx from 'clsx';

import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';

import Menu from './Menu';

const useStyles = makeStyles(() => ({
  hidden: {
    heigh: '0 !important',
    position: 'absolute',
    top: 0,
    zIndex: -1,
    visibility: 'hidden',
  },
}));

type Props = {
  items?: any;
  isSmallerScreen?: boolean;
};

function TopBarDesktop({ items, isSmallerScreen }: Props) {
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(
          'desktop-top-bar h-64 bg-white items-center px-24 py-6 flex w-full overflow-x-auto overflow-y-hidden',
          isSmallerScreen && classes.hidden,
        )}
      >
        <Menu
          items={items}
          isDesktopScreen={true}
        />
      </div>
      <Divider className="bg-gray-100" />
    </>
  );
}

export default TopBarDesktop;
