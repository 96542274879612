import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function ExportPDFIcon(props: Props) {
  return (
    <SvgIcon
      style={{ fill: 'none' }}
      viewBox="0 0 24 24"
      width="24"
      height="24"
      {...props}
    >
      <path
        d="M21 14.25C21 14.4489 20.921 14.6397 20.7803 14.7803C20.6397 14.921 20.4489 15 20.25 15H18V16.5H19.5C19.6989 16.5 19.8897 16.579 20.0303 16.7197C20.171 16.8603 20.25 17.0511 20.25 17.25C20.25 17.4489 20.171 17.6397 20.0303 17.7803C19.8897 17.921 19.6989 18 19.5 18H18V19.5C18 19.6989 17.921 19.8897 17.7803 20.0303C17.6397 20.171 17.4489 20.25 17.25 20.25C17.0511 20.25 16.8603 20.171 16.7197 20.0303C16.579 19.8897 16.5 19.6989 16.5 19.5V14.25C16.5 14.0511 16.579 13.8603 16.7197 13.7197C16.8603 13.579 17.0511 13.5 17.25 13.5H20.25C20.4489 13.5 20.6397 13.579 20.7803 13.7197C20.921 13.8603 21 14.0511 21 14.25ZM8.625 16.125C8.625 16.8212 8.34844 17.4889 7.85616 17.9812C7.36387 18.4734 6.69619 18.75 6 18.75H5.25V19.5C5.25 19.6989 5.17098 19.8897 5.03033 20.0303C4.88968 20.171 4.69891 20.25 4.5 20.25C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25C3.75 14.0511 3.82902 13.8603 3.96967 13.7197C4.11032 13.579 4.30109 13.5 4.5 13.5H6C6.69619 13.5 7.36387 13.7766 7.85616 14.2688C8.34844 14.7611 8.625 15.4288 8.625 16.125ZM7.125 16.125C7.125 15.8266 7.00647 15.5405 6.7955 15.3295C6.58452 15.1185 6.29837 15 6 15H5.25V17.25H6C6.29837 17.25 6.58452 17.1315 6.7955 16.9205C7.00647 16.7095 7.125 16.4234 7.125 16.125ZM15.375 16.875C15.375 17.7701 15.0194 18.6286 14.3865 19.2615C13.7535 19.8944 12.8951 20.25 12 20.25H10.5C10.3011 20.25 10.1103 20.171 9.96967 20.0303C9.82902 19.8897 9.75 19.6989 9.75 19.5V14.25C9.75 14.0511 9.82902 13.8603 9.96967 13.7197C10.1103 13.579 10.3011 13.5 10.5 13.5H12C12.8951 13.5 13.7535 13.8556 14.3865 14.4885C15.0194 15.1215 15.375 15.9799 15.375 16.875ZM13.875 16.875C13.875 16.3777 13.6775 15.9008 13.3258 15.5492C12.9742 15.1975 12.4973 15 12 15H11.25V18.75H12C12.4973 18.75 12.9742 18.5525 13.3258 18.2008C13.6775 17.8492 13.875 17.3723 13.875 16.875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2498 2C14.3812 1.9999 14.5113 2.02568 14.6327 2.07587C14.754 2.12605 14.8644 2.19967 14.9573 2.2925L20.2074 7.5426C20.3002 7.63554 20.3739 7.74595 20.4241 7.86735C20.4743 7.98874 20.5001 8.11884 20.5 8.2502V10.5C20.5 10.7652 20.3946 11.0196 20.2071 11.2071C20.0196 11.3946 19.7652 11.5 19.5 11.5C19.2348 11.5 18.9804 11.3946 18.7929 11.2071C18.6054 11.0196 18.5 10.7652 18.5 10.5V9.25H14.25C13.9848 9.25 13.7304 9.14464 13.5429 8.95711C13.3554 8.76957 13.25 8.51522 13.25 8.25V4H5.5V10.5C5.5 10.7652 5.39464 11.0196 5.20711 11.2071C5.01957 11.3946 4.76522 11.5 4.5 11.5C4.23478 11.5 3.98043 11.3946 3.79289 11.2071C3.60536 11.0196 3.5 10.7652 3.5 10.5V3.75C3.5 3.28587 3.68437 2.84075 4.01256 2.51256C4.34075 2.18438 4.78587 2 5.25 2H14.2498ZM17.6897 7.49999L15 4.8103V7.49999H17.6897Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(ExportPDFIcon);
