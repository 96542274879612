import { DAY_OPTIONS } from 'constants/index';
import _ from 'lodash';
import { TFrequencyDaily } from 'models';
import { useEffect } from 'react';
import { Options as RRuleOptions } from 'rrule';

import { FrequencyEvery } from '../ui/frequency-every';

type Props = {
  settings: TFrequencyDaily;
  onChange?: (nextFrequencySettings: TFrequencyDaily) => void;
  onRuleFormat?: (formattedRule: Partial<RRuleOptions>) => void;
};

export function FrequencyDailyType({ settings, onChange, onRuleFormat }: Props) {
  useEffect(() => {
    onRuleFormat?.(settings);
  }, [settings, onRuleFormat]);

  const handleEveryChange = (nextInterval: number) => {
    const nextFrequencySettings = _.cloneDeep(settings);
    nextFrequencySettings.interval = nextInterval;
    onChange?.(nextFrequencySettings);
  };

  return (
    <div className="pt-24">
      <FrequencyEvery
        interval={settings?.interval}
        label="Day(s)"
        options={DAY_OPTIONS}
        onChange={handleEveryChange}
      />
    </div>
  );
}
