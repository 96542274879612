import { TASK_COLORS, TASK_INACTIVE_COLORS } from './task';

export const PERIOD_OPTIONS = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last_week', label: 'Last week' },
  { value: 'this_week', label: 'This week' },
  { value: 'this_month', label: 'This month' },
  { value: 'this_year', label: 'This year' },
  { value: 'custom', label: 'Custom' },
];

export const STATISTICS_TYPES = [
  { value: 'list', label: 'List' },
  { value: 'role', label: 'Roles' },
  { value: 'user', label: 'Employees' },
];

const [LIST_VALUE, ROLE_VALUE, EMPLOYEE_VALUE] = STATISTICS_TYPES.map(filter => filter.value);

export { LIST_VALUE, ROLE_VALUE, EMPLOYEE_VALUE };

export const initializedBackground = [
  TASK_COLORS.onTime,
  TASK_COLORS.late,
  TASK_COLORS.incomplete,
  TASK_COLORS.inProgress,
  TASK_COLORS.pending,
];

export const inactiveBackground = [
  TASK_INACTIVE_COLORS.onTime,
  TASK_INACTIVE_COLORS.late,
  TASK_INACTIVE_COLORS.incomplete,
  TASK_INACTIVE_COLORS.inProgress,
  TASK_INACTIVE_COLORS.pending,
];

export const taskStatusLabels = ['Completed on time', 'Completed late', 'Incomplete', 'In progress', 'Pending'];

export const taskStatusColors = [
  TASK_COLORS.onTime,
  TASK_COLORS.late,
  TASK_COLORS.incomplete,
  TASK_COLORS.inProgress,
  TASK_COLORS.pending,
];

export const taskStatus = ['onTime', 'late', 'incomplete', 'inProgress', 'pending'];

export const auditStatusLabels = ['Compliant', 'Non-compliant'];
export const auditStatusColors = ['#9C27B0', '#FF9F5D'];
