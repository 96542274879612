import { TTask } from 'models';
import { UseFormReturn } from 'react-hook-form';

import TaskDisplayTimesSetting from './display-times-setting-v2';
import FrequencyContainer from './frequency/container';

type Props = {
  name?: string;
  methods: UseFormReturn<TTask>;
};

function DisplayFrequenciesSetting({ name, methods }: Props) {
  const index = -1;

  return (
    <div className="space-y-8">
      <div>
        <FrequencyContainer
          name={`${name}`}
          index={index}
          methods={methods}
        >
          <div>
            <TaskDisplayTimesSetting
              methods={methods}
              name={`${name}`}
              index={index}
            />
          </div>
        </FrequencyContainer>
      </div>
    </div>
  );
}

export default DisplayFrequenciesSetting;
