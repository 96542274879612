import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import _ from 'lodash';
import { TAssignment, TAssignmentCustomizeSetting } from 'models';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import HistoryIcon from '@mui/icons-material/History';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';

import AssignmentFieldContainer from './assignment-field-container';

type Props = {
  assignmentsAttributes?: TAssignment[];
  onEdit?: (variationId: number) => void;
  onDelete?: (variationId: number) => void;
  onDeleteCustomizedField?: (variationId: number, fieldName: TAssignmentCustomizeSetting) => void;
};

function TaskVariationList({ assignmentsAttributes, onEdit, onDelete, onDeleteCustomizedField }: Props) {
  const dispatch = useDispatch();

  const sortedAssignmentsAttributes = useMemo(
    () => _.orderBy(assignmentsAttributes, 'location.name', 'asc'),
    [assignmentsAttributes],
  );

  const handleDeleteVariation = (variationId: number) => () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title="Reset Variation"
            message="Once confirmed this variation setting will revert to default global settings and will be no longer available"
            statusVariant="warning"
            confirmButtonLabel="Ok"
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              onDelete?.(variationId);
            }}
          />
        ),
      }),
    );
  };

  const handleEditButtonClick = (variationId: number) => () => {
    onEdit?.(variationId);
  };

  const handleDeleteCustomizedField = (variationId: number) => (fieldName: TAssignmentCustomizeSetting) => {
    onDeleteCustomizedField?.(variationId, fieldName);
  };

  if (!assignmentsAttributes?.length) {
    return <Typography className="mt-16 text-11 font-400 text-secondaryLight">No active variation</Typography>;
  }

  return (
    <div>
      {sortedAssignmentsAttributes?.map((item: TAssignment) => (
        <div
          key={item.id}
          className="py-16 border-b-1"
        >
          <div className="flex justify-between">
            <Typography className="text-13 font-600 text-secondaryMain">{item.location?.name}</Typography>
            <div className="flex items-center space-x-8">
              <Tooltip title="Reset Variation">
                <IconButton
                  className="bg-paper"
                  onClick={handleDeleteVariation(item.id)}
                >
                  <HistoryIcon className="text-16" />
                </IconButton>
              </Tooltip>
              <Button
                disableElevation={true}
                variant="contained"
                color="inherit"
                className="uppercase text-11 font-600 bg-warning text-secondaryMain rounded-16"
                onClick={handleEditButtonClick(item.id)}
              >
                Edit
              </Button>
            </div>
          </div>

          <AssignmentFieldContainer
            customisedSettings={item?.customisedSettings}
            assignmentAttributes={item}
            onDelete={handleDeleteCustomizedField(item.id)}
          />
        </div>
      ))}
    </div>
  );
}

export default TaskVariationList;
