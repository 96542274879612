import _ from 'lodash';
import { TSpreadSheetCell } from 'models';
import * as React from 'react';
import { DataEditorComponent, DataViewerComponent } from 'react-spreadsheet';

import Checkbox from '@mui/material/Checkbox';

export const CheckboxView: DataViewerComponent<TSpreadSheetCell> = ({ cell }: any) => {
  return (
    <div className="flex justify-center ">
      <Checkbox checked={_.lowerCase(cell.value) === 'true'} />
    </div>
  );
};

export const CheckboxEdit: DataEditorComponent<TSpreadSheetCell> = ({ cell, onChange }: any) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...cell, value: event.target.checked ? 'true' : 'false' });
    },
    [onChange, cell],
  );

  return (
    <div className="flex justify-center">
      <Checkbox
        checked={_.lowerCase(cell.value) === 'true'}
        onChange={handleChange}
        autoFocus={true}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
};
