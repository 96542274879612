import Header from 'app/components/Layout/headers/internal-search-header';
import HeadButtonRight from 'app/components/cores/header-button-right';
import TemplateList from 'app/components/templates/list';
import { useActivateTemplate, useDeactivateTemplate, useGetTemplates } from 'app/hooks/api/templates';
import useObjectAction from 'app/hooks/use-object-action';
import useObjectBulkAction from 'app/hooks/use-object-bulk-action';
import { Paths } from 'constants/index';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { generateURLWithSearch, getDefaultFilterFromUrl } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';

interface Props extends RouteComponentProps<{}> {}

function TemplateIndex(props: Props) {
  const searchString = 'search';

  const history = useHistory();

  const [filter, setFilter] = useState<{ [key: string]: any }>({
    page: 1,
    per_page: 50,
  });
  const { data, isLoading } = useGetTemplates(filter);

  const templates = data?.records || [];
  const pageSize = data?.perPage || 50;
  const totalItems = data?.total;
  const [pageCount, setPageCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  // Handle index filter
  const defaultFilters = [{ id: 'status', value: 'active' }];
  const [filters, setFilters] = useState(getDefaultFilterFromUrl(props?.location?.search, defaultFilters));

  useEffect(() => {
    if (!pageSize || !totalItems) return;

    const count = Math.ceil(totalItems / pageSize);
    setPageCount(count);
  }, [pageSize, totalItems]);

  const handleSearch = (value: string) => {
    setFilter({ ...filter, page: 1, [searchString]: value });
    props.history.push({ search: generateURLWithSearch(props.location?.search, searchString, value) });
  };

  const handleFetchData = (fetchParams: any) => {
    setFilter(fetchParams);
  };

  const handleAddTemplate = () => {
    history.push(`${Paths.template}/new`);
  };

  const handleDeactivateTemplate = useObjectAction(useDeactivateTemplate, 'template', 'deactivate');
  const handleDeleteTemplate = useObjectAction(useDeactivateTemplate, 'template', 'delete');
  const handleActivateTemplate = useObjectAction(useActivateTemplate, 'template', 'activate');

  const handleBulkDeactivate = useObjectBulkAction(useDeactivateTemplate, 'template', 'deactivate');
  const handleBulkActivate = useObjectBulkAction(useActivateTemplate, 'template', 'activate');

  const handleBulkAction = (action: string, objects: { action: string; id: number; skipReload?: boolean }[]) => {
    switch (action) {
      case 'deactivate':
        handleBulkDeactivate(objects);

        break;
      case 'activate':
        handleBulkActivate(objects);
        break;
      default:
        break;
    }
  };

  const handleTemplateAction = ({ action, templateId }: { action: string; templateId: number }) => {
    switch (action) {
      case 'delete':
        handleDeleteTemplate({ action: 'deactivate', id: templateId });
        break;
      case 'deactivate':
        handleDeactivateTemplate({ action, id: templateId });
        break;
      case 'activate':
        handleActivateTemplate({ action, id: templateId });
        break;

      default:
        break;
    }
  };

  return (
    <BluefitPage
      classes={{
        header: 'min-h-header h-header sm:h-header sm:min-h-header',
        content: 'flex flex-col relative',
        leftSidebar: 'w-256 border-0',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          title="Template"
          searchText=""
          onSearch={(value: string) => {
            handleSearch(value);
          }}
          rightHeader={
            <div>
              <HeadButtonRight
                className="ml-4"
                variant="contained"
                btnTitle="Add"
                onClick={handleAddTemplate}
              />
            </div>
          }
        />
      }
      content={
        <div className="absolute flex w-full h-full">
          <TemplateList
            templates={templates}
            onFetchData={handleFetchData}
            pageCount={pageCount}
            totalDataCount={totalItems || 1}
            isLoading={isLoading}
            searchKey={searchString}
            selectedRowIds={selectedRowIds}
            filters={filters}
            setFilters={setFilters}
            setSelectedRowIds={setSelectedRowIds}
            onBulkAction={handleBulkAction}
            onAction={handleTemplateAction}
          />
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
    />
  );
}

export default TemplateIndex;
