import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';

type Props = {
  label: string;
  value: any;
  isUnavailable?: boolean;
};

export function RolesChip({ label, value, isUnavailable }: Props) {
  return (
    <div className="flex items-center">
      {isUnavailable && (
        <ErrorOutlineOutlinedIcon
          className="mr-4 text-16"
          color="error"
        />
      )}
      <Typography className="mr-4 text-11 font-400">
        {label} {!!value?.length && ':'}
      </Typography>
      {!!value?.length && <Typography className="text-11 font-700">{value ? 'On' : 'Off'}</Typography>}
    </div>
  );
}
