import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

type Props = {
  [key: string]: any;
};

function MyResourcesIcon(props: Props) {
  return (
    <SvgIcon
      {...props}
      style={{ fill: 'none' }}
      viewBox="0 0 24 24"
    >
      <path
        d="M2.71522 8.06592H7.12174C7.2958 8.06592 7.43649 7.92522 7.43649 7.75117V6.80691C7.43649 5.95803 6.8734 5.23851 6.101 5.0015C6.34147 4.72483 6.49224 4.36853 6.49224 3.97415C6.49224 3.10638 5.78625 2.40039 4.91848 2.40039C4.05071 2.40039 3.34472 3.10638 3.34472 3.97415C3.34472 4.36853 3.49549 4.72483 3.73596 5.0015C2.96356 5.23851 2.40047 5.95803 2.40047 6.80691V7.75117C2.40047 7.92522 2.54116 8.06592 2.71522 8.06592ZM4.91848 3.02989C5.43908 3.02989 5.86274 3.45355 5.86274 3.97415C5.86274 4.49475 5.43908 4.9184 4.91848 4.9184C4.39788 4.9184 3.97423 4.49475 3.97423 3.97415C3.97423 3.45355 4.39788 3.02989 4.91848 3.02989ZM3.02997 6.80691C3.02997 6.11257 3.59464 5.54791 4.28898 5.54791H5.54798C6.24233 5.54791 6.80699 6.11257 6.80699 6.80691V7.43641H3.02997V6.80691Z"
        fill="currentColor"
      />
      <path
        d="M16.2495 6.80691V7.75117C16.2495 7.92522 16.3902 8.06592 16.5643 8.06592H20.9708C21.1449 8.06592 21.2856 7.92522 21.2856 7.75117V6.80691C21.2856 5.95803 20.7225 5.23851 19.9501 5.0015C20.1905 4.72483 20.3413 4.36853 20.3413 3.97415C20.3413 3.10638 19.6353 2.40039 18.7675 2.40039C17.8998 2.40039 17.1938 3.10638 17.1938 3.97415C17.1938 4.36853 17.3446 4.72483 17.585 5.0015C16.8126 5.23851 16.2495 5.95803 16.2495 6.80691ZM18.7675 3.02989C19.2881 3.02989 19.7118 3.45355 19.7118 3.97415C19.7118 4.49475 19.2881 4.9184 18.7675 4.9184C18.2469 4.9184 17.8233 4.49475 17.8233 3.97415C17.8233 3.45355 18.2469 3.02989 18.7675 3.02989ZM16.879 6.80691C16.879 6.11257 17.4437 5.54791 18.138 5.54791H19.3971C20.0914 5.54791 20.6561 6.11257 20.6561 6.80691V7.43641H16.879V6.80691Z"
        fill="currentColor"
      />
      <path
        d="M2.71522 21.2855H7.12174C7.2958 21.2855 7.43649 21.1448 7.43649 20.9707V20.0265C7.43649 19.1776 6.8734 18.4581 6.101 18.2211C6.34147 17.9444 6.49224 17.5881 6.49224 17.1937C6.49224 16.3259 5.78625 15.62 4.91848 15.62C4.05071 15.62 3.34472 16.3259 3.34472 17.1937C3.34472 17.5881 3.49549 17.9444 3.73596 18.2211C2.96356 18.4581 2.40047 19.1776 2.40047 20.0265V20.9707C2.40047 21.1448 2.54116 21.2855 2.71522 21.2855ZM4.91848 16.2495C5.43908 16.2495 5.86274 16.6731 5.86274 17.1937C5.86274 17.7143 5.43908 18.138 4.91848 18.138C4.39788 18.138 3.97423 17.7143 3.97423 17.1937C3.97423 16.6731 4.39788 16.2495 4.91848 16.2495ZM3.02997 20.0265C3.02997 19.3321 3.59464 18.7675 4.28898 18.7675H5.54798C6.24233 18.7675 6.80699 19.3321 6.80699 20.0265V20.656H3.02997V20.0265Z"
        fill="currentColor"
      />
      <path
        d="M19.9501 18.2211C20.1905 17.9444 20.3413 17.5881 20.3413 17.1937C20.3413 16.3259 19.6353 15.62 18.7675 15.62C17.8998 15.62 17.1938 16.3259 17.1938 17.1937C17.1938 17.5881 17.3446 17.9444 17.585 18.2211C16.8126 18.4581 16.2495 19.1776 16.2495 20.0265V20.9707C16.2495 21.1448 16.3902 21.2855 16.5643 21.2855H20.9708C21.1449 21.2855 21.2856 21.1448 21.2856 20.9707V20.0265C21.2856 19.1776 20.7225 18.4581 19.9501 18.2211ZM18.7675 16.2495C19.2881 16.2495 19.7118 16.6731 19.7118 17.1937C19.7118 17.7143 19.2881 18.138 18.7675 18.138C18.2469 18.138 17.8233 17.7143 17.8233 17.1937C17.8233 16.6731 18.2469 16.2495 18.7675 16.2495ZM20.6561 20.656H16.879V20.0265C16.879 19.3321 17.4437 18.7675 18.138 18.7675H19.3971C20.0914 18.7675 20.6561 19.3321 20.6561 20.0265V20.656Z"
        fill="currentColor"
      />
      <path
        d="M10.2693 19.0822H13.4168C13.5908 19.0822 13.7315 18.9415 13.7315 18.7675V14.6757H14.6758C14.8498 14.6757 14.9905 14.535 14.9905 14.3609V12.7872C14.9905 11.7167 14.2225 10.8228 13.209 10.6255C13.532 10.2865 13.7315 9.82916 13.7315 9.32492C13.7315 8.28341 12.8845 7.43641 11.843 7.43641C10.8015 7.43641 9.95451 8.28341 9.95451 9.32492C9.95451 9.82916 10.1541 10.2865 10.477 10.6255C9.46349 10.8228 8.6955 11.7167 8.6955 12.7872V14.3609C8.6955 14.535 8.83619 14.6757 9.01025 14.6757H9.95451V18.7675C9.95451 18.9415 10.0952 19.0822 10.2693 19.0822ZM11.843 8.06592C12.5374 8.06592 13.102 8.63058 13.102 9.32492C13.102 10.0193 12.5374 10.5839 11.843 10.5839C11.1487 10.5839 10.584 10.0193 10.584 9.32492C10.584 8.63058 11.1487 8.06592 11.843 8.06592ZM9.325 14.0462V12.7872C9.325 11.9194 10.031 11.2134 10.8988 11.2134H12.7873C13.655 11.2134 14.361 11.9194 14.361 12.7872V14.0462H13.7315V12.4724H13.102V18.4527H12.1578V14.6757H11.5283V18.4527H10.584V12.4724H9.95451V14.0462H9.325Z"
        fill="currentColor"
      />
      <path
        d="M19.9343 10.0466L19.4893 10.4917L20.5257 11.5282H16.2495V12.1577H20.5257L19.4893 13.1942L19.9343 13.6392L21.5081 12.0655C21.6312 11.9424 21.6312 11.7435 21.5081 11.6204L19.9343 10.0466Z"
        fill="currentColor"
      />
      <path
        d="M4.06645 13.6392L4.51151 13.1942L3.47503 12.1577H7.43649V11.5282H3.47503L4.51151 10.4917L4.06645 10.0466L2.49269 11.6204C2.36962 11.7435 2.36962 11.9424 2.49269 12.0655L4.06645 13.6392Z"
        fill="currentColor"
      />
      <path
        d="M16.2495 15.3052H15.62V15.8044L14.8983 15.0827L14.4532 15.5277L15.175 16.2495H14.6758V16.879H15.9348C16.1088 16.879 16.2495 16.7383 16.2495 16.5642V15.3052Z"
        fill="currentColor"
      />
      <path
        d="M9.01025 16.2495H8.51106L9.23278 15.5277L8.78772 15.0827L8.066 15.8044V15.3052H7.43649V16.5642C7.43649 16.7383 7.57719 16.879 7.75125 16.879H9.01025V16.2495Z"
        fill="currentColor"
      />
      <path
        d="M14.5836 9.86221L15.3053 9.14048V9.63968H15.9348V8.38067C15.9348 8.20661 15.7941 8.06592 15.62 8.06592H14.361V8.69542H14.8602L14.1385 9.41715L14.5836 9.86221Z"
        fill="currentColor"
      />
      <path
        d="M9.325 8.06592H8.066C7.89194 8.06592 7.75125 8.20661 7.75125 8.38067V9.63968H8.38075V9.14048L9.10247 9.86221L9.54753 9.41715L8.82581 8.69542H9.325V8.06592Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default React.memo(MyResourcesIcon);
