import { generateUntilText } from 'domains/display-time.domain';
import { TUntilItem } from 'models';
import { clsxm } from 'utils/clsxm';

import { Typography } from '@mui/material';

type Props = {
  isEven?: boolean;
  startTime?: string;
  dueIn?: number | string;
  expireIn: number | string;
  displayUntil?: boolean;
  dueUntil?: TUntilItem;
  expireUntil?: TUntilItem;
  frequency?: number;
};

function DisplayTimePreviewItem({
  isEven,
  startTime,
  dueIn,
  expireIn,
  displayUntil,
  dueUntil,
  expireUntil,
  frequency,
}: Props) {
  const getTimeString = (time?: number | string) => {
    if (!time) {
      return '';
    }

    const timeNumber = Number(time);
    const result = {
      time: timeNumber / 60,
      suffix: 'minute',
    };

    if (timeNumber % 86400 === 0) {
      result.time = timeNumber / 86400;
      result.suffix = 'day';
    }
    //
    else if (timeNumber % 3600 === 0) {
      result.time = timeNumber / 3600;
      result.suffix = 'hour';
    }

    if (result.time !== 1) {
      result.suffix += 's';
    }

    return `${result.time} ${result.suffix}`;
  };

  return (
    <div className={clsxm('grid grid-cols-12 px-24 py-8 bg-paper', isEven && 'bg-white')}>
      <div className="col-span-4">
        <Typography className="text-13">{startTime}</Typography>
      </div>
      <div className="col-span-4">
        {displayUntil ? (
          <div>
            <Typography className="inline mr-4 text-13">Not due until</Typography>
            <Typography className="inline lowercase text-13">
              {generateUntilText(Number(frequency), dueUntil)}
            </Typography>
          </div>
        ) : (
          <Typography className="text-13">{getTimeString(dueIn)}</Typography>
        )}
      </div>
      <div className="col-span-4">
        {displayUntil ? (
          <div>
            <Typography className="inline mr-4 text-13">Not expire until</Typography>
            <Typography className="inline lowercase text-13">
              {generateUntilText(Number(frequency), expireUntil)}
            </Typography>
          </div>
        ) : (
          <Typography className="text-13">{getTimeString(expireIn)}</Typography>
        )}
      </div>
    </div>
  );
}

export default DisplayTimePreviewItem;
