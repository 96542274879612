import { TItem } from './item.model';

export type TDisplayTime = {
  time: string | null;
  dueIn: string;
  expireIn: string;
  dueUntil?: TUntilItem;
  expireUntil?: TUntilItem;
};

export type TSchedule = {
  id: number;
  recurringRule: string;
  displayTimes: TDisplayTime[];
};

export type TAssignmentOrgRole = {
  id?: number;
  orgRoleId: number;
  orgRoleName: string;
};

export type TAssignmentCompletionType = 'recurring' | 'fixed';

export type TAssignmentCustomizeSetting =
  | 'start_date'
  | 'end_date'
  | 'start_time'
  | 'recurring_rule'
  | 'display_times'
  | 'assignment_org_roles_attributes'
  | 'report_to_roles'
  | 'notification_available'
  | 'notification_settings'
  | 'send_to_roles'
  | 'send_to_users'
  | 'individual'
  | 'kind'
  | 'on_demand'
  | 'sweep';

export type TAssignment = {
  id: number;
  startDate: string;
  endDate: string;
  startTime: string;
  assignmentOrgRolesAttributes: TAssignmentOrgRole[];
  holidayAvailable: boolean;
  individual: boolean;
  kind: TAssignmentCompletionType;
  onDemand: boolean;
  locationId: number;
  location: {
    id: number;
    name: string;
  };
  locationIds?: number[];
  customisedSettings: TAssignmentCustomizeSetting[];
  locationDetail: {
    id: number;
    name: string;
  };
  reportToRoles: number[];
  reportToRolesDetail: {
    id: number;
    name: string;
  }[];
  reportToUsers: number[];
  reportToUsersDetail: {
    id: number;
    name: string;
  }[];
  sendToRoles: number[];
  sendToRolesDetail: {
    id: number;
    name: string;
  }[];
  sendToUsers: number[];
  sendToUsersDetail: {
    id: number;
    name: string;
  }[];
  excludedFromSaltInbox: boolean;
  notificationSettings: TNotificationSetting[];
  notificationAvailable?: boolean;
  frequencies: {
    recurringRule: string;
    displayTimes: TDisplayTime[];
  }[];
};

export type TAssignmentResponse = TAssignment & {
  variationErrors: TAssignmentValidationError[];
};

export type TAssignmentValidationError = TAssignment & {
  id: number;
  locationId: number;
  locationName: string;
  errorMessage: string;
};

export type TVersion = {
  id: number;
  version: number;
  status: string;
  lastModified: string;
};

export type TTaskLocation = {
  id: number;
  locationId: number;
  locationName: string;
};

export type TTask = {
  id: number;
  name: string;
  code: string;
  status: string;
  templateId: number;
  cloneFromId: number;
  updatedAt: string;
  createdAt: string;
  kind: string;
  locationTasksAttributes: TTaskLocation[];
  scannableCodeAttributes: {
    code: string;
    codeType: string;
  };
  version: number;
  description: string;
  itemsAttributes: TItem[];
  schedulesAttributes: TSchedule[];
  assignmentsAttributes: TAssignment[];
  attachmentsAttributes: any;
  currentVersion: {
    id: number;
    version: number;
    status: string;
    itemsAttributes: TItem[];
  };
  draftVersion: {
    id: number;
    version: number;
    itemsAttributes: TItem[];
  };
  versionHistory: TVersion[];
  globalAssignmentAttributes?: TAssignment;
  taskTagsAttributes?: TTaskTag[];
  applyToNewLocation?: boolean;
  audit?: boolean;
};

export type TTaskBasic = Pick<TTask, 'id' | 'name' | 'status'> & {
  orgRoles: { id: number; name: string; externalId: number }[];
  locations: { id: number; name: string; externalId: number }[];
  kind: TAssignmentCompletionType | null;
  assignmentStatus?: string;
};

export type TTaskTag = {
  id?: number;
  tagId: number;
  tagName?: string;
  _destroy?: boolean;
};

export type TNotificationSettingType = 'task_submitted' | 'task_incomplete' | 'late_submission';
export type TNotificationSetting = {
  type: TNotificationSettingType;
  sendToRoles: number[];
  sendToRolesDetail: { id: number; name: string }[];
  sendToUsers: number[];
  sendToUsersDetail: { id: number; name: string }[];
  excludedFromSaltInbox: boolean;
};

export const questionsLayoutView = {
  HISTORY: 'HISTORY',
  TEMPLATE: 'TEMPLATE',
} as const;

export type TQuestionLayoutView = keyof typeof questionsLayoutView;

export type TUntilItem = {
  after: number;
  target: string;
  time: string;
};

export type TListTrigger = {
  id: number;
  listType: string;
  orgRoles: { externalId: number; name: string; orgRoleName?: string; orgRoleId?: number; _destroy?: boolean }[];
  orgRoleIds: number[];
};

export type TTaskSubmittedTrigger = {
  type: 'task_submitted';
  tasks: TListTrigger[];
};

export type TTaskIncompleteTrigger = {
  type: 'task_incomplete';
  tasks: TListTrigger[];
};

export type TLateSubmissionTrigger = {
  type: 'late_submission';
  tasks: TListTrigger[];
};

export type TConditionalListTriggerSetting = (
  | TTaskSubmittedTrigger
  | TTaskIncompleteTrigger
  | TLateSubmissionTrigger
)[];
