import { ControlledTextInput } from 'app/components/cores/inputs/text';
import StatusBox from 'app/components/cores/status-box';
import { useFormContext } from 'react-hook-form';

import Typography from '@mui/material/Typography';

type Props = {
  tabIndex: number;
  type?: 'new' | 'edit';
};

function TagForm({ tabIndex, type }: Props) {
  const methods = useFormContext();
  const control = methods?.control;

  return (
    <div>
      {tabIndex === 0 && (
        <>
          {type === 'edit' && (
            <div className="mb-16">
              <StatusBox
                control={control}
                name="status"
                showErrorMessage={true}
              />
            </div>
          )}
          <div className="mb-8">
            <Typography className="text-secondaryLight text-10 font-400">General Information</Typography>
          </div>
          <ControlledTextInput
            label="Tag Name *"
            name="name"
            control={control}
            variant="filled"
            className="h-full shadow"
            InputProps={{
              className: 'bg-white hover:bg-gray-200 border-right-width-1',
              disableUnderline: true,
              classes: {
                input: 'px-16',
              },
            }}
          />
        </>
      )}
    </div>
  );
}

export default TagForm;
