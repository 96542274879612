import ConfirmDialog from 'app/components/cores/confirm-dialog';
import { SingleSelect } from 'app/components/cores/select';
import { useFetchServices } from 'app/hooks/api/services';
import useTaskFilter from 'app/hooks/use-task-filter';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from 'clsx';
import { generateReturnUrl } from 'domains/employee/task.domain';
import { renderFormLabel } from 'domains/salt-ops.domain';
import _ from 'lodash';
import { TSaltOpsForm, TSaltOpsFormType } from 'models';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { formatDate } from 'utils/date';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Props = {
  value: TSaltOpsForm | null;
  formType: TSaltOpsFormType;
  placeholderStyle?: string;
  disabled?: boolean;
  responseId?: number;
  listId?: number;
  listStatus?: string;
  isFetchingSaltOpsForm?: boolean;
  saltOpsFormData?: any;
  onChange?: (selectedForm: TSaltOpsForm) => void;
  onRefetchSaltOpsForm?: () => void;
};

function SaltFormOption({
  value,
  formType,
  placeholderStyle,
  disabled,
  responseId,
  listId,
  listStatus,
  isFetchingSaltOpsForm,
  saltOpsFormData,
  onChange,
  onRefetchSaltOpsForm,
}: Props) {
  const isLargeScreen = useMediaQuery('(min-width:960px)');
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const { location } = useTaskFilter();
  const { data: dataServices } = useFetchServices();

  const formOptions = useMemo(() => {
    if (!saltOpsFormData || typeof saltOpsFormData === 'string') return [];

    const generateFormOptions = (formList?: TSaltOpsForm[]) => {
      const groupedObject = _.chain(formList)
        .map((option: TSaltOpsForm) => {
          const label = option?.timestamp?.split(' ')[0];
          return {
            ...option,
            label,
            name: renderFormLabel(formType, option),
          };
        })
        .groupBy('label')
        .value();

      const groupArray = Object.keys(groupedObject).map((key: string) => {
        return { label: String(key), options: groupedObject[key] };
      });

      return _.orderBy(groupArray, 'label', 'desc').map((item: { label: string }) => ({
        ...item,
        label: formatDate(item.label, 'DD/MM/YYYY'),
      }));
    };

    return generateFormOptions(saltOpsFormData?.records) || [];
  }, [saltOpsFormData, formType]);

  const saltService = dataServices?.services?.find(service => service.kind === 'salt');

  const renderPlaceholder = (label: string = 'add new') => {
    const typeFormMap = new Map<TSaltOpsFormType, string>();
    typeFormMap.set('hazard', `${label} hazard report`);
    typeFormMap.set('headcount', `${label} head count report`);
    typeFormMap.set('incident', `${label} incident report`);
    typeFormMap.set('maintenance', `${label} maintenance report`);
    typeFormMap.set('water_chemistry', `${label} water chemistry report`);
    typeFormMap.set('pooltest', `${label} pool test report`);

    return typeFormMap.get(formType) || '';
  };

  const handleClickPlaceholder = () => {
    if (!saltService?.url) return;
    const saltReportUrl = generateReturnUrl({
      listId: Number(listId),
      responseId: Number(responseId),
      locationId: Number(location?.saltId),
      formType,
      saltUrl: saltService?.url,
    });
    window.open(saltReportUrl, '_self');
  };

  const showConfirmDialog = () => {
    dispatch(
      openDialog({
        children: (
          <ConfirmDialog
            title={renderPlaceholder('Create')}
            message="You will be redirected to My Facility to create a new form. Once completed, please return to this task and select the newly submitted form from the dropdown to finish the task."
            statusVariant="warning"
            confirmButtonLabel="Ok"
            hideCancelButton={true}
            onClose={() => {
              dispatch(closeDialog({}));
            }}
            onConfirm={() => {
              dispatch(closeDialog({}));
              handleClickPlaceholder();
            }}
          />
        ),
      }),
    );
  };

  const placeholder = renderPlaceholder().replace(/\b\w/g, l => l.toUpperCase());

  const handleChange = (selectedForm: any) => {
    onChange?.(selectedForm);
  };

  const handleRefetchSaltOpsForm = () => {
    if (!formType) return;
    onRefetchSaltOpsForm?.();
  };

  return (
    <div className="flex items-center space-x-12">
      <div className="flex-1">
        <SingleSelect
          isClearable={true}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          options={formOptions || []}
          menuPlacement="auto"
          noOptionsMessage={() => 'No form'}
          placeholder="Select form"
          value={value}
          isLoading={isFetchingSaltOpsForm}
          onChange={handleChange}
          onMenuOpen={handleRefetchSaltOpsForm}
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              border: 0,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 32,
              height: 40,
              minHeight: 40,
              boxShadow: 'none',
              cursor: state?.isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
              paddingLeft: 8,
              paddingRight: 4,
            }),
          }}
          theme={(baseTheme: any) => ({
            ...baseTheme,
            colors: {
              ...baseTheme.colors,
              primary50:
                listStatus === 'overdue' ? alpha(theme.palette.overdue.main, 0.2) : theme.palette.primary.light,
              primary25: '#eee',
              primary: listStatus === 'overdue' ? theme.palette.overdue.main : theme.palette.primary.main,
            },
          })}
        />
      </div>

      <button
        disabled={disabled}
        type="button"
        className={clsx(
          'flex items-center justify-between px-16 py-8 bg-gray-200 min-h-40 rounded-20 md:w-320',
          disabled && 'cursor-not-allowed',
        )}
        onClick={showConfirmDialog}
      >
        <Typography className={clsx('text-13 text-secondaryMain font-600 mr-12', placeholderStyle)}>
          {isLargeScreen ? placeholder : 'Add Report'}
        </Typography>
        <AddIcon className="text-20 text-secondaryMain" />
      </button>
    </div>
  );
}

export default SaltFormOption;
