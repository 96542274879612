import React, { memo } from 'react';

import Drawer from '@mui/material/Drawer';

type Props = {
  open: boolean;
  children: JSX.Element;
  onToggle: (open: boolean) => void;
};

function ItemSettingDrawer(props: Props) {
  const toggleDrawer = (localOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    props.onToggle(localOpen);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={props.open}
      onClose={toggleDrawer(false)}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        sx: {
          marginTop: 2,
          height: 'calc(100vh - 32px)',
          borderRadius: '1.6rem',
          border: 0,
        },
        classes: {
          root: 'shadow-lg',
        },
      }}
    >
      {props?.open ? props.children : <div />}
    </Drawer>
  );
}
export default memo(ItemSettingDrawer);
