import { Paths } from 'constants/paths';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { clsxm } from 'utils/clsxm';

import { Tab, Tabs } from '@mui/material';

type Props = {
  classes?: {
    root?: string;
  };
};

const TABS = [
  { tab: 'location', label: 'Location Performance', value: 0 },
  { tab: 'audit', label: 'Audit Performance', value: 1 },
] as const;

function PerformanceTabs({ classes }: Props) {
  const history = useHistory();

  const currentTab = useMemo(() => {
    const { pathname } = history.location;
    if (pathname.includes('audit')) return 1;
    return 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const handleChangeTab = (__: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      history.push(`${Paths.performance}`);
    } else {
      history.push(`${Paths.performance}/audit`);
    }
  };

  return (
    <Tabs
      value={currentTab}
      indicatorColor="secondary"
      textColor="primary"
      scrollButtons="auto"
      classes={{ root: clsxm('w-full h-48 relative', classes?.root) }}
      onChange={handleChangeTab}
    >
      {TABS.map(tab => (
        <Tab
          key={tab.value}
          label={tab.label}
          className="h-48 normal-case font-400"
          value={+tab.value}
        />
      ))}
    </Tabs>
  );
}

export default PerformanceTabs;
