import { TTaskStatus } from 'models/employee/task.model';

import {
  Area,
  CheckMark,
  DateTime,
  Employee,
  Media,
  MultipleChoice,
  Numeric,
  QRCode,
  Rating,
  SaltOpsForm,
  Section,
  Signature,
  Text,
  YesNo,
} from './responses';

type Props = {
  name: string;
  response: any; // TODO update type
  list?: any;
  readOnly?: boolean;
};

function TaskResponse({ name, list, response, readOnly }: Props) {
  const listStatus = list?.status as TTaskStatus;
  const listId = list?.id;
  const responseId = response?.id;
  const isUpcoming = list?.status === 'upcoming';
  const isAudit = !!list?.audit;

  const getResponseComponent = (type: string) => {
    const mapping = new Map();
    mapping.set('Responses::YesNo', YesNo);
    mapping.set('Responses::Numeric', Numeric);
    mapping.set('Responses::Rating', Rating);
    mapping.set('Responses::CheckMark', CheckMark);
    mapping.set('Responses::MultipleChoice', MultipleChoice);
    mapping.set('Responses::Media', Media);
    mapping.set('Responses::Area', Area);
    mapping.set('Responses::DateTime', DateTime);
    mapping.set('Responses::Text', Text);
    mapping.set('Responses::Employee', Employee);
    mapping.set('Responses::SaltOpsForm', SaltOpsForm);
    mapping.set('Responses::BarCode', QRCode);
    mapping.set('Responses::QrCode', QRCode);
    mapping.set('Responses::Signature', Signature);
    mapping.set('Responses::Section', Section);

    return mapping.get(type);
  };

  const ResponseComponent = getResponseComponent(response?.type);

  if (!ResponseComponent) return null;

  return (
    <ResponseComponent
      name={name}
      listId={listId}
      listStatus={listStatus}
      responseId={responseId}
      response={response}
      readOnly={readOnly || isUpcoming}
      isAudit={isAudit}
    />
  );
}

export default TaskResponse;
