import TemplateInfo from './info';
import TemplateItems from './items';
import TemplatePreview from './preview/preview-layout';

type Props = {
  tabIndex: number;
  onTabChange?: (tabIndex: number) => void;
};

function TemplateForm(props: Props) {
  return (
    <div>
      {props.tabIndex === 0 && <TemplateInfo {...props} />}
      {props.tabIndex === 1 && <TemplateItems {...props} />}
      {props.tabIndex === 2 && <TemplatePreview {...props} />}
    </div>
  );
}

export default TemplateForm;
