import LineChart from 'app/components/cores/statistics/line-chart';
import clsx from 'clsx';
import { initializedBackground, taskStatusColors, taskStatusLabels } from 'constants/index';
import _zip from 'lodash/zip';
import { TLocationOverview } from 'models';
import { ElementRef, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { toFixed } from 'utils/number';

import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  completionRateValue: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.6rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem',
    },
  },
  completionRateLabel: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem !important',
      marginTop: '0 !important',
      lineHeight: 1,
      textAlign: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1rem !important',
    },
  },
}));

type Props = {
  isLoading?: boolean;
  locationName: string;
  periodString: string;
  overview?: TLocationOverview;
};

const donutOptions = {
  cutout: '80%',
  responsive: true,
  events: [],
  plugins: {
    legend: { display: false },
  },
};

const OverviewContent = ({ isLoading: loading, locationName, overview, periodString }: Props) => {
  const data = [
    overview?.completedOnTime,
    overview?.completedLate,
    overview?.incomplete,
    overview?.inProgress,
    overview?.pending,
  ];

  const listData = [
    overview?.totalCompletedLateLists,
    overview?.totalCompletedLateLists,
    overview?.totalIncompleteLists,
    overview?.totalInProgressLists,
    overview?.totalPendingLists,
  ];

  const completedRatePerDate = overview?.completionRatePerDate || [];

  const lineChartLabels = completedRatePerDate.map((item: { date: string }) => item.date);
  const lineChartData = completedRatePerDate.map((item: { rate: number }) => item.rate);

  const classes = useStyles();
  const ref = useRef<ElementRef<typeof Doughnut>>();

  const backgroundColor = initializedBackground;

  const chartData = {
    taskStatusLabels,
    datasets: [
      {
        data,
        backgroundColor,
        borderWidth: 5, // Set the thickness of each segment
      },
    ],
  };

  const memoChartData = useMemo(
    () => chartData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const createPieChartData = () => {
    return _zip(taskStatusLabels, taskStatusColors, data, listData);
  };

  const pieChartData = createPieChartData();

  if (loading) {
    return (
      <div className={clsx('p-24 bg-white rounded-8 flex flex-col w-1024')}>
        <CardContent className={clsx('flex-1 flex flex-col justify-between')}>
          <div>
            <Skeleton
              variant="text"
              height={40}
            />
            <div className="flex items-center justify-center mt-16">
              <div className={clsx('relative w-256 h-256')}>
                <Skeleton
                  height={180}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className={clsx('px-0 mt-16')}>
            {[70, 50, 30, 60, 40].map(width => (
              <Skeleton
                key={width}
                width={`${width}%`}
                height={30}
              />
            ))}
          </div>
        </CardContent>
      </div>
    );
  }

  return (
    <div className={clsx('p-24 bg-white rounded-8 flex flex-col mb-32 border-0')}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col items-start ">
          <Typography className={clsx('text-center text-18 font-500 text-secondaryMain capitalize')}>
            {locationName}
          </Typography>
          <Typography className={clsx('text-center text-14 font-400 text-secondaryLight')}>{periodString}</Typography>
        </div>

        <div className={clsx('flex justify-between space-x-40 mr-40')}>
          <div className="flex flex-col items-start">
            <Typography className={clsx('text-center text-13 font-500 text-secondaryLight')}>Total Lists</Typography>
            <Typography className={clsx('text-center text-18 font-500 text-secondaryMain capitalize')}>
              {overview?.totalLists}
            </Typography>
          </div>
          <div className="flex flex-col items-start">
            <Typography className={clsx('text-center text-13 font-500 text-secondaryLight')}>
              Completed Lists
            </Typography>
            <Typography className={clsx('text-center text-18 font-500 text-secondaryMain capitalize')}>
              {overview?.completedLists}
            </Typography>
          </div>
        </div>
      </div>

      <div className={clsx('flex-1 flex flex-col justify-between')}>
        {/* Charts */}

        <div className="flex flex-row mt-16">
          {/* Donut */}
          <div className="flex items-center justify-start">
            <div className={clsx('relative w-256 h-256')}>
              <Doughnut
                data={memoChartData}
                options={donutOptions}
                ref={ref}
              />
              <Card
                className="absolute flex flex-col items-center bg-transparent min-w-128"
                sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', boxShadow: 'none' }}
              >
                <Typography
                  sx={{ fontSize: '52px' }}
                  className={clsx('font-600 text-primaryDark', classes.completionRateValue)}
                  component="span"
                >
                  {overview?.completionRate ? toFixed(overview?.completionRate, 2) : 0}%
                </Typography>
                <div className="flex items-center">
                  <Typography
                    component="span"
                    className={clsx('text-14 font-600 text-secondaryLight', classes.completionRateLabel)}
                  >
                    Completion rate
                  </Typography>
                </div>
              </Card>
            </div>
          </div>

          {/* Line */}
          <div className="ml-32 w-512 h-256">
            <LineChart
              labels={lineChartLabels}
              data={lineChartData}
              options={{ events: [] }}
            />
          </div>
        </div>

        {/* Statistic */}
        <div className="flex flex-col justify-center ml-16 w-720">
          <div className="grid flex-wrap grid-cols-5 gap-2 mt-52">
            {pieChartData.map(([label, color, rate, count]) => {
              return (
                <div
                  key={label}
                  className="flex flex-row w-160"
                >
                  <div
                    className="w-8 h-40 rounded-8"
                    style={{
                      backgroundColor: color,
                    }}
                  />
                  <div className="flex flex-col mt-2 ml-8">
                    <Typography className="text-secondaryMain font-500">
                      {rate ? toFixed(rate, 2) : 0}% ({count})
                    </Typography>
                    <Typography className="text-secondaryDark text-12">{label}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-20">
            <Typography className="text-secondaryLight text-12">
              Completion rate = Completed on time + Completed late
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverviewContent;
