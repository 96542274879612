import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

type Item = {
  name: string;
  externalId: number;
};

type Props = {
  unavailableRolesByLocations: {
    location: Item;
    orgRoles: Item[];
  }[];
  locationVariations?: { id: number; name: string }[];
};

const UnavailableRolesDialog = ({ unavailableRolesByLocations, locationVariations }: Props) => {
  const dispatch = useDispatch();

  const filteredUnavailableRoles = unavailableRolesByLocations.filter(role => role.orgRoles.length > 0);

  const handleClose = () => {
    dispatch(closeDialog({}));
  };

  return (
    <div className="bg-white">
      <DialogTitle className="flex items-center justify-between">
        <Typography className="capitalize font-600 text-15">Missing Roles</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: '40px', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ backgroundColor: 'white' }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className="font-bold text-13">Location</Typography>
                </TableCell>
                <TableCell>
                  <Typography className="font-bold text-13">Role</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUnavailableRoles.map(role => (
                <TableRow key={role.location.externalId}>
                  <TableCell
                    sx={{
                      width: '30%',
                      borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    <Typography className="text-13">{role.location.name}</Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: '70%',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                      borderLeft: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    <Typography className="text-13">{role.orgRoles.map(r => r.name).join(', ')}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {locationVariations && locationVariations?.length > 0 && (
          <Typography className="mt-24 text-13 text-secondaryLight">
            The following locations do not use Global&apos;s Assigned Roles, please verify in the Variations tab:{' '}
            <Typography
              className="text-13 text-secondaryMain font-600"
              component="span"
            >
              {locationVariations.map(v => v.name).join(', ')}
            </Typography>
          </Typography>
        )}
      </DialogContent>
    </div>
  );
};
export default UnavailableRolesDialog;
