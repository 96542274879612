import { navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

function NavbarMobileToggleButton(props) {
  const dispatch = useDispatch();

  return (
    <IconButton
      className={props.className}
      onClick={ev => dispatch(navbarToggleMobile())}
      color="inherit"
      disableRipple
    >
      {props.children || <Icon>menu</Icon>}
    </IconButton>
  );
}

export default NavbarMobileToggleButton;
