import Header from 'app/components/Layout/headers/blank-header-v2';
import ExportCSVIcon from 'app/components/cores/icons/export-csv';
import ExportPDFIcon from 'app/components/cores/icons/export-pdf';
import GeneratingModal from 'app/components/cores/modals/generating';
import ReportFilter from 'app/components/reports/filters/list-report-filter';
import ReportTable, { ReportTableAnswerCell, ReportTableItemCell } from 'app/components/reports/table';
import { useExportReport, useGetReportDetail } from 'app/hooks/api/manager/report';
import useBack from 'app/hooks/use-back';
import useExport from 'app/hooks/use-export';
import useToggle from 'app/hooks/use-toggle';
import clsx from 'clsx';
import { formatData, getExportFileBlob } from 'domains/list-report.domain';
import _ from 'lodash';
import { TListReport, TListReportEmployeeTask, TListReportItem, TListReportLocation } from 'models';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Cell, Row, useTable } from 'react-table';
import { useExportData } from 'react-table-plugins';
import { delay } from 'utils/date';
import { isAdmin } from 'utils/role';
import { generateURLWithFilter, getQueryObject } from 'utils/url';

import BluefitPage from '@fuse/core/BluefitPage';
import SyncIcon from '@mui/icons-material/Sync';
import { Icon, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ParamsTypes {
  reportId: string;
}

function ListReportDetail() {
  const isMediaResponseDownloading = useSelector(({ report }: any) => report?.mediaResponse?.isDownloading);
  const classes = useStyles();
  const routeParams = useParams<ParamsTypes>();
  const history = useHistory();

  const { goBack } = useBack('/histories/report');

  const { permissions, role: roles } = useSelector(({ auth }: any) => auth.user);
  const canExportPDF = permissions?.['managerGridReports.export'] || isAdmin(roles);

  const { state: isExportingPDF, setActive: showExportingPDFModal, setInactive: hideExportingPDFModal } = useToggle();
  const {
    isModalOpen: isExportingCSV,
    handleCloseModal: hideExportingCSVModal,
    doExport,
  } = useExport({
    useQueryHook: useExportReport,
  });

  // prepare report data
  const { reportId: stringReportId } = routeParams;
  const reportId = Number(stringReportId);
  const [reportFilters, setReportFilters] = useState(getDefaultFilter());
  const { data: report, isFetching: isFetchingReport } = useGetReportDetail(
    reportId,
    removeDuplicateFilter(reportFilters),
  );

  // table
  const columns = useMemo(() => formatColumn(report), [report]);
  const data = useMemo(() => formatData(report), [report]);
  const { headerGroups, rows, prepareRow, exportData }: any = useTable(
    {
      data,
      columns,
      getExportFileBlob: (props: any) => getExportFileBlob(props, report?.list?.name),
    },
    useExportData,
  );

  // vars
  const isShowExportingModal = isExportingPDF || isExportingCSV;
  const isEmptyReport = !isFetchingReport && !report?.items?.length;

  const hideExportingModal = () => {
    hideExportingPDFModal();
    hideExportingCSVModal();
  };

  const handleExportCSV = () => {
    doExport({
      filters: {
        ...removeDuplicateFilter(reportFilters),
        'q[task_root_id_eq]': reportId,
      },
    });
  };

  const handleExport = async () => {
    if (isEmptyReport) return;
    showExportingPDFModal();
    await delay(0);
    await exportData('pdf', true);
    hideExportingModal();
  };

  const handleFilterChange = (filter: any) => {
    const nextFilter = _.pick(filter, ['q[display_at_gteq]', 'q[display_at_lteq]', 'q[location_id_in]']);
    history.replace({
      pathname: window.location.pathname,
      search: generateURLWithFilter('', nextFilter),
    });
    setReportFilters({ ...reportFilters, ...transformFilter(nextFilter) });
  };

  function transformFilter(filter: any) {
    const nextFilter = _.cloneDeep(filter);
    const nextLocationFilterValue = nextFilter?.['q[location_id_in]'];

    if (nextLocationFilterValue) {
      const nextLocationFilter = Array.isArray(nextLocationFilterValue)
        ? _.map(nextLocationFilterValue, id => Number(id))
        : [Number(nextLocationFilterValue)];

      nextFilter['q[location_id_in]'] = nextLocationFilter;
      nextFilter['q[location_id_in][]'] = nextLocationFilter;
    }
    return nextFilter;
  }

  function getDefaultFilter() {
    const queryObject = getQueryObject(window.location.search);
    return transformFilter(queryObject);
  }

  function removeDuplicateFilter(filter: any) {
    const nextFilter = _.cloneDeep(filter);
    return _.pick(nextFilter, ['q[location_id_in][]', 'q[display_at_gteq]', 'q[display_at_lteq]']);
  }

  function handleBackClick() {
    goBack();
  }

  return (
    <BluefitPage
      classes={{
        content: 'flex flex-col relative',
        header: 'min-h-64 h-64 sm:h-64 sm:min-h-64 border-b-1 border-gray-300',
        wrapper: 'min-h-0',
      }}
      header={
        <Header
          leftHeader={
            <div
              role="button"
              className="flex text-secondaryMain w-fit"
              tabIndex={0}
              onClick={handleBackClick}
            >
              <Icon
                role="button"
                className="mt-4 text-18"
              >
                arrow_back_ios
              </Icon>
              <div className="flex flex-col">
                <Typography className="ml-16 uppercase text-18 font-600 line-clamp-1">
                  {report?.list?.name || ''}
                </Typography>
              </div>
            </div>
          }
          rightHeader={
            <div className="flex items-center space-x-8 text-secondaryMain">
              <ReportFilter
                defaultFilter={reportFilters}
                isHideListFilter={true}
                onChange={handleFilterChange}
              />
              {canExportPDF && (
                <div>
                  <Tooltip title={isFetchingReport ? '' : 'Export CSV'}>
                    <span>
                      <IconButton
                        disabled={isFetchingReport}
                        color="primary"
                        className="h-40 ml-8"
                        onClick={handleExportCSV}
                      >
                        {isFetchingReport ? <SyncIcon className="animate-spin" /> : <ExportCSVIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={isFetchingReport ? '' : 'Export PDF'}>
                    <span>
                      <IconButton
                        disabled={isFetchingReport || isMediaResponseDownloading}
                        color="primary"
                        className="h-40 ml-8"
                        onClick={handleExport}
                      >
                        {isFetchingReport || isMediaResponseDownloading ? (
                          <SyncIcon className="animate-spin" />
                        ) : (
                          <ExportPDFIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          }
        />
      }
      content={
        <div
          className={clsx('flex flex-col w-full h-full pb-64', classes.reportTable, !canExportPDF && 'print:hidden')}
        >
          <GeneratingModal
            isOpen={isShowExportingModal}
            onClose={hideExportingModal}
          />
          <ReportTable
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          />
          {isEmptyReport && (
            <Typography className="mt-32 text-center text-13 font-400 text-secondaryLight">
              No report available
            </Typography>
          )}
        </div>
      }
      innerScroll={true}
      sidebarInner={true}
      isFixedHeader={true}
      isLoading={isFetchingReport}
    />
  );
}

export default ListReportDetail;

function formatColumn(data: TListReport) {
  const columns: any = [];
  if (!data?.items?.length) return columns;

  columns.push({
    Header: 'Items',
    accessor: 'item',
    classes: {
      header: 'w-1/3 min-w-256',
      title: 'font-600',
    },
    Cell: ({ cell }: { cell: Cell<TListReportItem> }) => {
      const { type, prompt } = cell.value || {};
      return (
        <ReportTableItemCell
          type={type}
          prompt={prompt}
        />
      );
    },
  });

  _.forEach(data?.locations, (location: TListReportLocation) => {
    const locationColumn: any = {
      Header: location.name,
      columns: [],
      classes: { title: 'font-600' },
    };

    _.forEach(location?.employeeTasks, (employeeTask: TListReportEmployeeTask) => {
      locationColumn.columns.push({
        Header: moment(employeeTask.displayAt).format('HH:mm DD/MM/YYYY'),
        accessor: `employeeTask-${employeeTask.id}`,
        classes: { title: 'font-400' },
        Cell: ({ cell, row }: { cell: Cell<any>; row: Row<any> }) => {
          const { type } = row.original.item || {};
          return (
            <ReportTableAnswerCell
              type={type}
              response={cell.value?.response}
            />
          );
        },
      });
    });

    columns.push(locationColumn);
  });

  return columns;
}

const useStyles = makeStyles(() => ({
  reportTable: {
    '& thead th': {
      backgroundColor: 'white',
      position: 'sticky',
      borderTopWidth: '0 !important',
    },
    '& thead tr:nth-child(1) th': {
      top: 0,
    },
    '& thead tr:nth-child(2) th': {
      top: 40,
    },
  },
}));
