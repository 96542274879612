export const TIME_ZONES = [
  { label: 'Australian Western Standard Time (AWST) - UTC +08:00', value: 'Australia/Perth' },
  {
    label: 'Australian Western Central Standard Time (ACWST) - UTC +8:45',
    value: 'Australia/Eucla',
  },
  {
    label: 'Australian Central Standard Time (ACST) - UTC +09:30',
    value: 'Australia/Darwin',
  },
  {
    label: 'Australian Eastern Standard Time (AEST) - UTC +10:00',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Australian Central Daylight Time (ACDT) - UTC +09:30 / +10:30',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Australian Eastern Daylight Time (AEDT) - UTC +10:00 / +11:00',
    value: 'Australia/Sydney',
  },
];
