import { clsx } from 'clsx';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  backgroundLate: {
    background: '#FFF8DB',
  },
  backgroundOnTime: {
    background: '#F2FCFF',
  },
  backgroundExpired: {
    background: '#F7F7F7',
  },
  backgroundIncomplete: {
    background: '#FFF6FA',
  },
  backgroundPending: {
    background: '#F6F6F6',
  },
  backgroundOverdue: {
    background: '#FEF3E2',
  },
  backgroundInProgress: {
    background: '#F5FEF8',
  },
}));

const StatusBadge = ({ status }: { status: string }) => {
  const classes = useStyles();

  let backgroundColor;
  let textColor;
  switch (status) {
    case 'Completed Late':
      backgroundColor = classes.backgroundLate;
      textColor = '#806C14';
      break;

    case 'Expired':
      textColor = '#E0115F';
      backgroundColor = classes.backgroundExpired;
      break;

    case 'Incomplete':
      textColor = '#E0115F';
      backgroundColor = classes.backgroundIncomplete;
      break;

    case 'Pending':
      textColor = '#1F242F';
      backgroundColor = classes.backgroundPending;
      break;

    case 'Overdue':
      textColor = '#DA7835';
      backgroundColor = classes.backgroundOverdue;
      break;

    case 'In progress':
      textColor = '#3E8455';
      backgroundColor = classes.backgroundInProgress;
      break;

    default:
      textColor = 'primary';
      backgroundColor = classes.backgroundOnTime;
      break;
  }

  return (
    <div className={clsx('p-4 text-center rounded-16', backgroundColor)}>
      <Typography
        color={textColor}
        className="text-11 font-500"
      >
        {status}
      </Typography>
    </div>
  );
};
export default StatusBadge;
