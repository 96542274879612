import clsx from 'clsx';
import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
}));

function GeneratingModal({ isOpen, onClose }: Props) {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose?.();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <div
        style={modalStyle}
        className={clsx(classes.paper, 'flex items-center')}
      >
        <div>
          <p>Generating</p>
        </div>
        <LinearProgress className="w-full ml-8" />
      </div>
    </Modal>
  );
}

export default React.memo(GeneratingModal);
