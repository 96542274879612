import { QuestionLayout } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useOnClickOutside from 'app/hooks/use-on-click-outside';
import useShowMessage from 'app/hooks/use-show-message';
import useTask from 'app/hooks/use-task';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import { IconButton, TextField, useTheme } from '@mui/material';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
  isAudit?: boolean;
};

function TextResponse({ name, readOnly, listId, listStatus, responseId, response, isAudit }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const theme = useTheme();
  const [originalAnswer, setOriginalAnswer] = useState<string | undefined>();
  const inputRef = useRef<any>(null);
  const tmpInputValue = useRef<any>(null);
  const isInputFocused = useRef(false);
  const { triggerSubmitTask } = useTask();

  useOnClickOutside(inputRef, (event: any) => {
    if (!isInputFocused.current || tmpInputValue.current === value?.answer) return;
    handleAnswerSend();
    triggerSubmitTask(event?.target);
  });

  const isAnswerChanged = useMemo(() => {
    return originalAnswer !== undefined && value?.answer !== originalAnswer;
  }, [value, originalAnswer]);

  const iconColor = useMemo(() => {
    return listStatus === 'overdue' ? 'overdue.main' : theme.palette.primary.main;
  }, [listStatus, theme]);

  useEffect(() => {
    if (!response) return;
    setOriginalAnswer(response?.answer);
  }, [response]);

  const handleAnswerChange = (event: any) => {
    const nextAnswer = event.target.value;
    onChange({ ...value, answer: nextAnswer });
  };

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleAnswerSend = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment,
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment,
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value?.answer, additionalPayload);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        setOriginalAnswer(res?.answer);
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  const handleFocusInput = () => {
    isInputFocused.current = true;
    tmpInputValue.current = value?.answer;
  };

  const handleBlurInput = () => {
    isInputFocused.current = false;
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
      isAudit={isAudit}
      disabled={readOnly}
    >
      <div
        className="relative"
        ref={inputRef}
      >
        <TextField
          value={value?.answer || ''}
          placeholder="Enter your answer here"
          multiline={true}
          className="w-full bg-gray-200 rounded-20"
          sx={{
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            minHeight: '100px',
          }}
          disabled={readOnly}
          onChange={handleAnswerChange}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
        />
        {!readOnly && isAnswerChanged && (
          <IconButton
            className="absolute right-0 bottom-0 mr-12 mb-12"
            sx={{ color: iconColor }}
            onClick={handleAnswerSend}
          >
            <SendIcon
              className="text-16"
              color="inherit"
            />
          </IconButton>
        )}
      </div>
    </QuestionLayout>
  );
}

export default TextResponse;
