import { QuestionLayout, SignaturePad } from 'app/components/cores/tasks/components';
import { useAnswerTask } from 'app/hooks/api/employee/tasks';
import useShowMessage from 'app/hooks/use-show-message';
import { generateTaskAnswerPayload } from 'domains/employee/task.domain';
import { TEmployeeTaskResponse, TTaskStatus } from 'models/employee/task.model';
import { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  name: string;
  readOnly?: boolean;
  listId: number;
  listStatus?: TTaskStatus;
  responseId: number;
  response?: TEmployeeTaskResponse;
  isAudit?: boolean;
};

function SignatureResponse({ name, readOnly, listId, listStatus, responseId, response, isAudit }: Props) {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const { mutateAsync: answerTask, isLoading } = useAnswerTask(listId, responseId);
  const { showError, showSuccess } = useShowMessage();
  const signaturePadRef = useRef<any>(null);

  const handleCommentChange = (nextComment: string) => {
    onChange({ ...value, comment: nextComment });
  };

  const handleSendComment = () => {
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleApplicableChange = (notApplicable: boolean) => {
    const additionalPayload = {
      comment: value?.comment ?? '',
      notApplicable,
    };
    const answerPayload = generateTaskAnswerPayload(value.answer, additionalPayload);
    answer(answerPayload);
  };

  const handleUpdate = () => {
    signaturePadRef.current?.open();
  };

  const handleClear = () => {
    signaturePadRef.current?.clear();
    updateFormAndAnswer(null);
  };

  const handleChange = (nextAnswer: string) => {
    updateFormAndAnswer(nextAnswer);
  };

  const updateFormAndAnswer = (nextAnswer: string | null) => {
    onChange({ ...value, answer: nextAnswer });
    const additionalPayload = {
      comment: value?.comment ?? '',
    };
    const answerPayload = generateTaskAnswerPayload(nextAnswer, additionalPayload);
    answer(answerPayload);
  };

  const answer = (payload: any) => {
    answerTask(payload)
      .then(res => {
        onChange({ ...value, ...res });
        showSuccess('Your answer has been submitted successfully');
      })
      .catch(() => {
        showError('Could not submit your answer');
      });
  };

  return (
    <QuestionLayout
      question={value?.item || {}}
      preview={readOnly ?? false}
      task={value}
      rightActions={
        value?.answer &&
        !readOnly && (
          <div className="flex items-center">
            <Tooltip title="Update">
              <IconButton onClick={handleUpdate}>
                <EditIcon className="text-20" />
              </IconButton>
            </Tooltip>
            {/* TODO show confirm dialog */}
            <Tooltip title="Clear">
              <IconButton onClick={handleClear}>
                <LoopIcon className="text-20" />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
      isLoading={isLoading}
      listStatus={listStatus}
      comment={response?.comment}
      onCommentChange={handleCommentChange}
      onCommentSend={handleSendComment}
      onApplicableChange={handleApplicableChange}
      name={`${name}.answer`}
      isAudit={isAudit}
      disabled={readOnly}
    >
      <SignaturePad
        signaturePadRef={signaturePadRef}
        disabled={readOnly}
        value={value?.answer}
        listStatus={listStatus}
        onChange={handleChange}
      />
    </QuestionLayout>
  );
}

export default SignatureResponse;
