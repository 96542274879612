import clsx from 'clsx';
import { TAdminCalendar } from 'models';
import React, { useState } from 'react';

import { EventContentArg, ViewApi } from '@fullcalendar/core';
import { Typography } from '@mui/material';

import { ModalReviewCalendar } from './modal-review-calendar';
import { ModalReviewGlobalCalendar } from './modal-review-global-calendar';

type Props = {
  eventInfo: EventContentArg;
  isMyList?: boolean;
};

export const CalendarTaskCell: React.FC<Props> = ({ eventInfo, isMyList }) => {
  const task = eventInfo?.event?.extendedProps as TAdminCalendar & {
    startDate: string | Date;
    endDate: string | Date;
  };
  const view = eventInfo?.view as ViewApi;

  const { status, name } = task;

  const viewTitle = view?.title;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={clsx('w-full h-full cursor-pointer rounded-4 break-words', {
          'bg-taskPending': status === 'pending',
          'bg-taskInProgress': status === 'in_progress',
          'bg-taskOverdue': status === 'overdue',
          'bg-taskExpired': status === 'expired',
          'bg-paper': status === 'upcoming',
          'bg-taskCompleted': status === 'completed',
        })}
        onClick={handleOpen}
        role="button"
        tabIndex={0}
      >
        <Typography className="px-6 py-2 leading-4 text-black text-13 font-600">{name}</Typography>
      </div>

      {open && (
        <>
          {isMyList ? (
            <ModalReviewCalendar
              open={open}
              onClose={handleClose}
              title={viewTitle}
              task={task}
              view={view}
              eventInfo={eventInfo}
            />
          ) : (
            <ModalReviewGlobalCalendar
              open={open}
              onClose={handleClose}
              title={viewTitle}
              task={task}
              view={view}
              eventInfo={eventInfo}
            />
          )}
        </>
      )}
    </>
  );
};
