import { TSaltOpsFormType } from 'models';

import { useGetList } from '../request';

const saltOpsUrl = '/api/select_options/salt_ops_forms';

export const SaltOpsKeys = {
  all: ['saltOps'] as const,
  lists: () => [...SaltOpsKeys.all, 'list'] as const,
  list: (filters: any) => [...SaltOpsKeys.lists(), { filters }] as const,
  details: () => [...SaltOpsKeys.all, 'detail'] as const,
  detail: (id: number) => [...SaltOpsKeys.details(), id] as const,
};

export function useGetSaltOpsForm(type: TSaltOpsFormType, params?: any, options?: any) {
  return useGetList<any>(
    [...SaltOpsKeys.list(type)],
    `${saltOpsUrl}/${type}/list`,
    params,
    {
      refetchOnMount: true,
      ...options,
    },
    true,
  );
}
