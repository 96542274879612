import { TTaskLocation } from 'models';
import { UseFormReturn } from 'react-hook-form';

import TaskVariationLocation from './location';
import TaskVariationSetting from './setting';

type Props = {
  methods: UseFormReturn<any>;
  mode?: 'add' | 'edit';
  locationTasksAttributes?: TTaskLocation[];
};

function TaskVariationContainer({ methods, mode, locationTasksAttributes }: Props) {
  const control = methods?.control;

  return (
    <div className="grid flex-1 grid-cols-12 py-16">
      <div className="flex flex-col col-span-3 pr-24 space-y-24">
        <TaskVariationLocation
          mode={mode}
          control={control}
          locationTasksAttributes={locationTasksAttributes}
        />
      </div>
      <div className="flex flex-col col-span-9 pr-24 space-y-24">
        <TaskVariationSetting
          mode={mode}
          methods={methods}
        />
      </div>
    </div>
  );
}

export default TaskVariationContainer;
