import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

interface IProps {
  children: React.ReactNode;
  animation?: any;
  className?: string;
}

const AnimateWrapper = ({ children, animation, className }: IProps) => {
  const defaultAnimation = {
    initial: {
      opacity: 0,
      x: 20,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    transition: {
      duration: 1,
    },
  };

  const animationProps = animation || defaultAnimation;

  return (
    <motion.div
      className={clsx(className)}
      {...animationProps}
    >
      {children}
    </motion.div>
  );
};

export default AnimateWrapper;
