import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';

type Props = {
  onClickEdit: () => void;
  onClickDelete: () => void;
  isLoading: boolean;
};

const Actions = ({ onClickEdit, onClickDelete, isLoading }: Props) => {
  return (
    <>
      <IconButton
        className="rounded-8"
        sx={{ backgroundColor: '#F6F6F6' }}
        onClick={onClickEdit}
        disabled={isLoading}
      >
        <EditIcon className="text-secondaryLight text-16" />
      </IconButton>
      <IconButton
        className="rounded-8"
        sx={{ backgroundColor: '#F6F6F6' }}
        onClick={onClickDelete}
        disabled={isLoading}
      >
        <DeleteIcon className="text-secondaryLight text-16" />
      </IconButton>
    </>
  );
};

export default Actions;
