import { authRoles } from 'app/auth';
import { Paths } from 'constants/index';

import Edit from './edit';
import Index from './index';
import New from './new';

const listsPagesConfig: any = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: `${Paths.list}`,
      exact: true,
      component: Index,
    },
    {
      path: `${Paths.list}/new`,
      exact: true,
      component: New,
    },
    {
      path: `${Paths.list}/:listId`,
      exact: true,
      component: Edit,
    },
  ],
};

export default listsPagesConfig;
