import clsx from 'clsx';

import AddIcon from '@mui/icons-material/Add';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseIcon from '@mui/icons-material/Close';
import { AccordionSummary, Typography } from '@mui/material';

type Props = {
  isExpanded?: boolean;
  hasError?: boolean;
  hasConfig?: boolean;
  title?: string;
  classes?: {
    notifySummaryRoot?: string;
    accordionSummaryDisabled?: string;
  };
};

function AccordionExpandTitle({ isExpanded, hasError, hasConfig, title, classes }: Props) {
  return (
    <AccordionSummary
      classes={{
        root: clsx('min-h-40 px-24', isExpanded && classes?.notifySummaryRoot),
        content: 'my-4',
        disabled: classes?.accordionSummaryDisabled,
      }}
      expandIcon={
        isExpanded ? (
          <CloseIcon
            color={hasError ? 'error' : 'primary'}
            fontSize="small"
          />
        ) : (
          <AddIcon
            color={hasError ? 'error' : 'primary'}
            fontSize="small"
          />
        )
      }
    >
      <div className="flex items-center justify-between flex-1 pr-8">
        <Typography
          color="primary"
          className={clsx('text-12 font-600', hasError && 'text-errorMain')}
        >
          {title}
        </Typography>
        {hasConfig && (
          <CheckCircleRoundedIcon
            color={hasError ? 'error' : 'primary'}
            className="text-16"
          />
        )}
      </div>
    </AccordionSummary>
  );
}

export default AccordionExpandTitle;
