import clsx from 'clsx';
import { validateSettings } from 'domains/task.domain';
import _ from 'lodash';
import { Control, useFormContext, useWatch } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DateTimeScoreSetting from './date-time';
import MultipleChoiceScoreSetting from './multiple-choice';
import NumericScoreSetting from './numeric';
import RatingScoreSetting from './rating';
import YesNoScoreSetting from './yes-no';

type Props = {
  isOpenSettingModal?: boolean;
  control?: Control;
  name: string;
  onClose?: () => void;
  onSave?: () => void;
};

function ModalScoreSetting({ isOpenSettingModal, control, name, onClose, onSave }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;
  const typeName = `${name}.type`;

  const classes = useStyles();
  const listType = useWatch({ name: typeName, control });
  const { clearErrors, getValues, setError } = useFormContext();

  const handleSave = () => {
    clearErrors(scoreSettingsName);
    const scoreSettings = getValues(scoreSettingsName);
    const { isValid, emptyScoreItemIndexes, emptyValueItemIndexes } = validateSettings(scoreSettings, listType);

    if (!isValid) {
      _.forEach(emptyValueItemIndexes, index => {
        setError(`${scoreSettingsName}.${index}.value`, { type: 'custom', message: 'Value is required' });
      });
      _.forEach(emptyScoreItemIndexes, index => {
        setError(`${scoreSettingsName}.${index}.score`, { type: 'custom', message: 'Score is required' });
      });
    } else {
      onSave?.();
    }
  };

  const handleClose = () => {
    clearErrors(scoreSettingsName);
    onClose?.();
  };

  const generateItems = (type?: string) => {
    if (!type) return null;

    const mapping = new Map();
    mapping.set('Items::MultipleChoice', MultipleChoiceScoreSetting);
    mapping.set('Items::Rating', RatingScoreSetting);
    mapping.set('Items::Numeric', NumericScoreSetting);
    mapping.set('Items::DateTime', DateTimeScoreSetting);
    mapping.set('Items::YesNo', YesNoScoreSetting);

    return mapping.get(type) || null;
  };

  const ComponentConfiguration = generateItems(listType);

  return (
    <Modal
      open={!!isOpenSettingModal}
      className="flex items-center justify-center"
    >
      <div className="outline-none">
        <Card
          sx={{ width: '500px' }}
          className={clsx('bg-white rounded-16 min-h-320 flex flex-col', classes.card)}
        >
          {/* header */}
          <CardHeader
            title="Config score"
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon className="text-20" />
              </IconButton>
            }
            classes={{ title: 'text-18 font-600 text-secondaryMain' }}
            className="px-24"
          />
          <Divider />

          {/* content */}
          <CardContent className="flex-1 px-24 space-y-24 overflow-auto">
            <div className="h-full -mx-24">
              <ComponentConfiguration
                name={name}
                control={control}
              />
            </div>
          </CardContent>
          <Divider />

          {/* footer */}
          <CardActions className="flex items-center justify-end px-24">
            <Button
              variant="outlined"
              color="primary"
              className="h-32 w-80 text-11 font-400"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disableElevation={true}
              variant="contained"
              color="primary"
              className="h-32 text-11 font-400 w-80"
              onClick={handleSave}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </div>
    </Modal>
  );
}

export default ModalScoreSetting;

const useStyles = makeStyles(() => ({
  card: {
    maxHeight: '90vh',
  },
}));
