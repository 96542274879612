import DataSelect from 'app/components/cores/data-select';
import DateRange from 'app/components/cores/date-range';
import InfoItem from 'app/components/histories/info-item';
import { useGetActionStatistics } from 'app/hooks/api/actions';
import { PERIOD_OPTIONS } from 'constants/performance';
import { getFormattedDateRange } from 'domains/locations/performance';
import { TDateRange } from 'models';
import moment from 'moment';
import { useState } from 'react';

import { Typography } from '@mui/material';

type Props = {
  locationId?: string;
  locationName?: string;
};
function ActionStatistic({ locationId, locationName }: Props) {
  const todayRange = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  };
  const [period, setPeriod] = useState<string>(PERIOD_OPTIONS[0].value);
  const [customPeriod, setCustomPeriod] = useState<TDateRange>(todayRange);
  const { from, to } = getFormattedDateRange(period, customPeriod);
  const { data, isFetching } = useGetActionStatistics({
    'q[start_date_eq]': from,
    'q[location_id_eq]': locationId,
    'q[end_date_eq]': to,
  });

  return (
    <div className="flex flex-row">
      <div className="flex flex-row w-1/3 min-w-640">
        <div className="flex flex-col items-start mr-4">
          <Typography className="text-13 font-400 text-secondaryLight">Statistics</Typography>
          <Typography className="mt-4 truncate text-13 font-600 text-secondaryMain max-w-192 min-w-128">
            {locationName || 'All locations'}
          </Typography>
        </div>
        <InfoItem
          isLoading={isFetching}
          name="Regular Lists"
          value={data?.regular.toString() || ''}
          containerClassName="flex-1"
          valueClassName="text-15"
        />
        <InfoItem
          isLoading={isFetching}
          name="Audit"
          value={data?.audit.toString() || ''}
          containerClassName="flex-1"
          valueClassName="text-15"
        />

        <InfoItem
          isLoading={isFetching}
          name="Diary To-do"
          value={data?.diary.toString() || ''}
          containerClassName="flex-1"
          valueClassName="text-15"
        />
        <InfoItem
          isLoading={isFetching}
          name="Pending"
          value={data?.pending.toString() || ''}
          containerClassName="flex-1"
          valueClassName="text-15"
        />
        <InfoItem
          isLoading={isFetching}
          name="Overdue"
          value={data?.overdue.toString() || ''}
          containerClassName="flex-1"
          valueClassName="text-15"
        />
      </div>
      <div className="flex items-center justify-end w-full h-40 mt-4 ml-auto">
        <DataSelect
          options={PERIOD_OPTIONS.slice(0, -1)}
          value={period}
          onChange={setPeriod}
          styleFormControl="max-w-136 min-w-136 rounded-8 mr-4 h-32"
          sx={{ height: '32px' }}
        />

        {period === 'custom' && (
          <div className="flex items-center space-x-16">
            <DateRange
              value={customPeriod}
              onChange={setCustomPeriod}
              noAllTime={true}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionStatistic;
