import TextInput from 'app/components/cores/inputs/text';
import clsx from 'clsx';
import { TItemSoreSetting } from 'models';
import { Control, useFieldArray, useFormState } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  name: string;
  control?: Control;
};

function RatingScoreSetting({ name, control }: Props) {
  const scoreSettingsName = `${name}.scoreSettings`;

  const classes = useStyles();
  const { errors } = useFormState({ control });

  const { fields: scoreSettings, update } = useFieldArray({
    control,
    name: scoreSettingsName,
  });

  const handleUpdateScore = (index: number, setting: TItemSoreSetting) => (event: any) => {
    if (!/^\d*$/.test(event.target.value)) return;
    const nextScore = Number(event.target.value);
    update(index, { ...setting, score: nextScore ?? 0 });
  };

  return (
    <div>
      <div className="flex items-center justify-between px-24 mb-4">
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score if answer is</Typography>
        <Typography className="flex-1 text-secondaryLight text-11 font-500">Score</Typography>
      </div>

      <div className="flex flex-col gap-y-4">
        {scoreSettings?.map((setting: any, index: number) => {
          if (setting.logic === 'completed') return null;
          return (
            <div key={setting.value}>
              <div
                className={clsx(
                  'flex items-center justify-between px-24 py-4 duration-200 hover:bg-gray-50',
                  classes.item,
                )}
              >
                <div className="flex items-center flex-1">
                  <Typography className="w-16 text-13 font-500">{setting.value}</Typography>
                  <StarRoundedIcon className="text-warning text-20" />
                </div>
                <div className="flex-1">
                  <TextInput
                    placeholder="Enter score"
                    className={clsx('w-full px-8 bg-paper rounded-8 duration-200', classes.textInput)}
                    variant="standard"
                    InputProps={{ className: 'min-h-32 text-11 text-secondaryLight font-500', disableUnderline: true }}
                    value={setting.score ?? ''}
                    onChange={handleUpdateScore(index, setting)}
                  />
                  <ErrorMessage
                    name={`${scoreSettingsName}.${index}.score`}
                    errors={errors}
                    render={({ message }: any) => <Typography className="text-11 text-errorMain">{message}</Typography>}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RatingScoreSetting;

const useStyles = makeStyles((theme: any) => ({
  item: {
    '&:hover $textInput': {
      backgroundColor: `${theme.palette.grey[200]} !important`,
    },
  },
  textInput: {},
}));
