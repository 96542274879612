import moment from 'moment';

import { Typography } from '@mui/material';

type Props = {
  answer: any;
};

function ReportTableDateTimeCell({ answer }: Props) {
  if (!answer) return <Typography className="text-13 font-400">-</Typography>;

  // answer from server: 28-12-2023 00:00+07:00 to moment date
  const formatDate = (date: string) => {
    return moment(date, 'DD-MM-YYYY HH:mm:z').format('HH:mm DD/MM/YYYY');
  };

  return <Typography className="text-13 font-400">{formatDate(answer)}</Typography>;
}

export default ReportTableDateTimeCell;
