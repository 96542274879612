import useOnClickOutside from 'app/hooks/use-on-click-outside';
import useTask from 'app/hooks/use-task';
import { TMeasurementType, UNIT_OPTIONS, groupOptions } from 'models';
import { useMemo, useRef } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { styled } from '@mui/material/styles';

type Props = {
  value?: string | number;
  disabled?: boolean;
  unit: string;
  measurement: TMeasurementType;
  status?: string;
  isSubmitted?: boolean;
  onChange?: (value?: number) => void;
  onAnswer?: (value?: number) => void;
  onEnter?: (value?: number) => void;
};

const StyledNumericInput = styled(NumericFormat)<{ disabled?: boolean }>(() => {
  return {
    ':focus-visible': {
      outline: 'none',
    },
  };
});

const NumericInput = ({
  value,
  disabled = false,
  unit,
  measurement,
  status,
  isSubmitted = true,
  onChange,
  onAnswer,
  onEnter,
}: Props) => {
  const inputRef = useRef<any>(null);
  const tmpValue = useRef<any>(null);
  const isFocused = useRef(false);
  const { triggerSubmitTask } = useTask();

  useOnClickOutside(inputRef, (event: any) => {
    if (!isFocused.current || tmpValue.current === value) return;
    onAnswer?.(Number(value));
    triggerSubmitTask(event?.target);
    isFocused.current = false;
  });

  const handleChange = ({ floatValue }: NumberFormatValues) => {
    onChange?.(floatValue);
  };

  const unitLabel = useMemo(() => {
    if (!unit) {
      return '';
    }

    if (measurement === 'custom') {
      return unit;
    }

    const unitOption = groupOptions
      .find(group => group.label === measurement)
      ?.options.find(option => option.value === unit);

    return unitOption?.display || '';
  }, [unit, measurement]);
  const canBeNegative = useMemo(() => UNIT_OPTIONS.TEMP.some(({ value: tempValue }) => tempValue === unit), [unit]);

  const getBgColorByStatus = () => {
    if (status === 'overdue') return 'overdue.main';
    return 'primary.main';
  };

  const handleFocus = () => {
    isFocused.current = true;
    tmpValue.current = value;
  };

  const handleBlur = () => {
    isFocused.current = false;
  };

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      onEnter?.(Number(value));
      isFocused.current = false;
    }
  };

  return (
    <div ref={inputRef}>
      <StyledNumericInput
        disabled={disabled}
        value={value || ''}
        allowNegative={canBeNegative}
        className="w-full h-40 text-center outline-none rounded-20"
        placeholder={unit ? `0 ${unitLabel}` : '0'}
        suffix={unit ? ` ${unitLabel}` : ''}
        sx={{
          backgroundColor: value && isSubmitted ? getBgColorByStatus() : 'grey.200',
          color: value && isSubmitted ? 'common.white' : 'text.primary',
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onValueChange={handleChange}
        onKeyDown={handleEnter}
      />
    </div>
  );
};
export default NumericInput;
