import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

type Props = {
  downloadElement?: string;
  downloadFileName?: string;
};

function useQRCode({ downloadElement, downloadFileName }: Props) {
  const printAreaRef = useRef(null);

  const generateCode = (length: number) => nanoid(length);

  const printCode = useReactToPrint({
    content: () => printAreaRef.current,
  });

  const downloadCode = () => {
    if (!downloadElement || !downloadFileName) return;
    const svg = document.getElementById(downloadElement);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');

      downloadLink.download = downloadFileName;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
  };

  return {
    printAreaRef,
    generateCode,
    printCode,
    downloadCode,
  };
}

export default useQRCode;
