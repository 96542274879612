import { ControlledDataSelect } from 'app/components/cores/data-select';
import { ControlledDatePicker } from 'app/components/cores/date-picker';
import AttributeContainer from 'app/components/lists/attribute-container';
import {
  ASSIGNMENT_FIELDS_MAP,
  END_TIME_OPTIONS,
  GROUP_OPTIONS,
  LIST_SUBMISSION_OPTIONS,
  ON_DEMAND_OPTIONS,
  SHOW_SWEEP_LIST_SETTING,
  START_TIME_OPTIONS,
} from 'constants/index';
import { generateDefaultDisplayTime } from 'domains/display-time.domain';
import { getOrgRoleIds } from 'domains/role.domain';
import { UseFormReturn, useController, useWatch } from 'react-hook-form';

import { Typography } from '@mui/material';

import AlertExistedRoles from '../alert-existed-roles';
import ConditionalListVariant from '../assignment/conditional-list-variant';
import EndDateAssignment from '../assignment/end-date-assignment';
import OthersAssignment from '../assignment/others';
import ReportTo from '../assignment/report-to';
import RoleSelection from '../assignment/role-selection';
import TimeAssignment from '../assignment/time-assignment';
import DisplayFrequenciesSetting from '../display-frequencies-setting';
import ListSubmission from '../list-submission';
import TaskNotification from '../notification';
import VariationAssignmentFields from './assignment-fields';
import TaskVariationVisibleCustomizeField from './visible-customize-field';

type Props = {
  methods: UseFormReturn<any>;
  mode?: 'add' | 'edit';
};

function TaskVariationSetting({ methods, mode }: Props) {
  const control = methods?.control;

  const isAddVariation = mode === 'add';
  const locationName = isAddVariation ? 'locationIds' : 'location';
  const locations = useWatch({ name: locationName, control });
  const orgRoles = useWatch({ name: 'assignmentOrgRolesAttributes', control });

  const locationIds = isAddVariation ? locations : [locations?.id];
  const orgRoleIds = getOrgRoleIds(orgRoles) ?? [];

  const customisedSettingsName = 'customisedSettings';
  const displayTimesName = 'displayTimes';

  const customisedSettings = useWatch({
    name: customisedSettingsName,
    control,
  });

  const {
    field: { value: displayTimes, onChange: onChangeDisplayTimes },
  } = useController({ name: displayTimesName, control });

  const handleAddNewField = (fieldName: string) => {
    if (fieldName === ASSIGNMENT_FIELDS_MAP.DISPLAY_TIMES && !displayTimes?.length) {
      onChangeDisplayTimes([generateDefaultDisplayTime()]);
    }

    if (fieldName === ASSIGNMENT_FIELDS_MAP.ON_DEMAND) {
      const prevOnDemandValue = methods?.getValues('onDemand');
      if (prevOnDemandValue === undefined) {
        methods?.setValue('onDemand', false);
      }
    }

    if (fieldName === ASSIGNMENT_FIELDS_MAP.LIST_TYPE) {
      methods?.setValue('listType', 'individual_list');
    }
  };

  return (
    <div>
      <Typography className="mb-16 text-13 font-400">2. Select a variation category to edit</Typography>

      <VariationAssignmentFields
        name={customisedSettingsName}
        control={control}
        onAddNew={handleAddNewField}
      />

      {/* start_date */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="start_date"
      >
        <AttributeContainer label="Start Date *">
          <ControlledDatePicker
            name="startDate"
            control={control}
          />
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* end_date */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="end_date"
      >
        <EndDateAssignment
          name="endDate"
          control={control}
        />
      </TaskVariationVisibleCustomizeField>

      {/* start_time */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="start_time"
      >
        <TimeAssignment
          label="Start Time"
          options={START_TIME_OPTIONS}
          name="startTime"
          control={control}
        />
      </TaskVariationVisibleCustomizeField>

      {/* end_time */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="end_time"
      >
        <TimeAssignment
          label="End Time"
          options={END_TIME_OPTIONS}
          name="endTime"
          control={control}
        />
      </TaskVariationVisibleCustomizeField>

      {/*  frequency */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="frequencies"
      >
        <DisplayFrequenciesSetting
          methods={methods}
          control={control}
        />
      </TaskVariationVisibleCustomizeField>

      {/* assignment_org_roles_attributes */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="assignment_org_roles_attributes"
      >
        <AttributeContainer label="Assign Role(s)">
          <RoleSelection
            control={control}
            name="assignmentOrgRolesAttributes"
            placeholder="Assign Role to List"
          />
          <AlertExistedRoles
            locationIds={locationIds}
            roleIds={orgRoleIds}
          />
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* report_to_roles */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="report_to_roles"
      >
        <AttributeContainer label="Report To">
          <ReportTo control={control} />
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* notification_available */}
      {/* notification_settings */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="notification_available"
      >
        <AttributeContainer label="Notifications">
          <TaskNotification control={control} />
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* list_type */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="list_type"
      >
        <AttributeContainer label="List Type">
          <div className="w-128">
            <ControlledDataSelect
              options={GROUP_OPTIONS}
              name="listType"
              control={control}
            />
          </div>
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* on_demand */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName="on_demand"
      >
        <AttributeContainer label="On Demand">
          <div className="w-128">
            <ControlledDataSelect
              options={ON_DEMAND_OPTIONS}
              name="onDemand"
              control={control}
            />
          </div>
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>

      {/* holiday and blackout */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName={['holiday_available', 'blackout_time_enable', 'generate_between_break_time', 'enable_on_closed_day']}
      >
        <OthersAssignment
          control={control}
          methods={methods}
          isShowHoliday={customisedSettings?.includes(ASSIGNMENT_FIELDS_MAP.HOLIDAY)}
          isShowBlackout={customisedSettings?.includes(ASSIGNMENT_FIELDS_MAP.BLACKOUT)}
          isShowBreakTime={customisedSettings?.includes(ASSIGNMENT_FIELDS_MAP.GENERATE_BETWEEN_BREAK_TIME)}
          isShowClosedDay={customisedSettings?.includes(ASSIGNMENT_FIELDS_MAP.ENABLE_ON_CLOSED_DAY)}
        />
      </TaskVariationVisibleCustomizeField>

      {/* list submission */}
      {SHOW_SWEEP_LIST_SETTING && (
        <TaskVariationVisibleCustomizeField
          customisedSettings={customisedSettings}
          fieldName="sweep"
        >
          <AttributeContainer label="List Submission">
            <ListSubmission
              options={LIST_SUBMISSION_OPTIONS}
              name="sweep"
              control={control}
            />
          </AttributeContainer>
        </TaskVariationVisibleCustomizeField>
      )}

      {/* trigger list */}
      <TaskVariationVisibleCustomizeField
        customisedSettings={customisedSettings}
        fieldName={ASSIGNMENT_FIELDS_MAP.LIST_TRIGGER}
      >
        <AttributeContainer label="List Trigger Setting">
          <ConditionalListVariant
            control={control}
            name=""
          />
        </AttributeContainer>
      </TaskVariationVisibleCustomizeField>
    </div>
  );
}

export default TaskVariationSetting;
