import TransferHeader from 'app/components/cores/transfer-list/transfer-header';
import TransferSelection from 'app/components/cores/transfer-list/transfer-selection';
import useTransferList from 'app/components/cores/transfer-list/use-transfer-list';
import { clsxm } from 'utils/clsxm';

import _ from '@lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

export type TransferListProps = {
  className?: string;
  classes?: {
    container?: string;
  };
  values?: any;
  options?: any;
  optionLabel?: string;
  optionValue?: string;
  isDisabled?: boolean;
  isAllLocation?: boolean;
  onChange?: (updatedValues: any) => void;
  onClose?: () => void;
};

function LocationTransferList({
  className,
  classes: classesProp,
  values = [],
  options,
  optionLabel = 'name',
  optionValue = 'id',
  isDisabled,
  isAllLocation,
  onChange,
  onClose,
}: TransferListProps) {
  const classes = useStyles({ classes: classesProp });

  const {
    unselectedOptions,
    leftSelectedValues,
    setLeftSelectedValues,
    rightSelectedValues,
    setRightSelectedValues,
    isMoveItemsLeftToRight,
    isMoveItemsRightToLeft,
    handleSelectAllRightItems,
    handleSelectAllLeftItems,
    selectedLeftTitle,
    selectedRightTitle,
    isLeftSelectedAllValues,
    isRightSelectedAllValues,
    isLeftIndeterminate,
    isRightIndeterminate,
  } = useTransferList({ values, options, optionValue });

  // when moving selected items from left column to right column
  const handleMoveItemsFromLeftToRight = () => {
    const nextValues = _.cloneDeep(values);

    onChange?.([...nextValues, ...leftSelectedValues]);
    setLeftSelectedValues([]);
  };

  // when moving selected items from right column to left column
  const handleMoveItemsFromRightToLeft = () => {
    const nextValues = _.cloneDeep(values);

    _.forEach(rightSelectedValues, (item: any) => {
      _.remove(nextValues, { id: item.id });
    });

    onChange?.(nextValues);
    setRightSelectedValues([]);
  };

  return (
    <div className="bg-white rounded-8">
      <div className={clsxm('flex items-center w-full h-full', className)}>
        <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
          <TransferHeader
            isDisabled={isDisabled}
            isSelected={isLeftSelectedAllValues}
            isIndeterminate={isLeftIndeterminate}
            title="Locations"
            selectedTitle={selectedLeftTitle}
            onChange={handleSelectAllLeftItems}
            rightChildren={
              <Button
                disableElevation={true}
                variant="outlined"
                className="justify-between h-32 px-16 w-96 rounded-16"
                disabled={isDisabled || !isMoveItemsLeftToRight}
                onClick={handleMoveItemsFromLeftToRight}
              >
                <Typography className="text-11 font-400">Add</Typography>
                <AddIcon className="text-20" />
              </Button>
            }
          />
          <Divider />
          <TransferSelection
            isDisabled={isDisabled}
            side="left"
            list={unselectedOptions}
            selectedValues={leftSelectedValues}
            noItemMessage="No Locations"
            className="w-full h-full"
            classes={{ container: classes?.container }}
            optionLabel={optionLabel}
            optionValue={optionValue}
            onSelect={setLeftSelectedValues}
          />
        </div>

        <div className="flex flex-col w-1/2 h-full bg-white border border-gray-400 rounded-2">
          <TransferHeader
            isDisabled={isDisabled}
            isSelected={isRightSelectedAllValues}
            isIndeterminate={isRightIndeterminate}
            title="Chosen Locations"
            selectedTitle={selectedRightTitle}
            onChange={handleSelectAllRightItems}
            rightChildren={
              <Button
                disableElevation={true}
                variant="outlined"
                color="primary"
                className="justify-between h-32 pl-16 pr-12 w-96 rounded-16"
                disabled={isDisabled || !isMoveItemsRightToLeft}
                onClick={handleMoveItemsFromRightToLeft}
              >
                <Typography className="text-11 font-400">Remove</Typography>
                <RemoveIcon className="text-20" />
              </Button>
            }
          />
          <Divider />
          <TransferSelection
            isDisabled={isDisabled}
            side="right"
            list={values}
            selectedValues={rightSelectedValues}
            noItemMessage="No Locations"
            className="w-full h-full"
            classes={{ container: classes?.container }}
            optionLabel={optionLabel}
            optionValue={optionValue}
            onSelect={setRightSelectedValues}
          />
        </div>
      </div>

      <div className="flex items-start justify-between py-8 pr-24 space-x-88">
        <div className="flex-1" />
        <div className="flex items-center justify-between flex-1 space-x-8">
          <Typography className="italic text-10 text-secondaryLight">
            {!isAllLocation
              ? 'The task will be shown in chosen locations. New locations will not see the task automatically'
              : ''}
          </Typography>
          <Button
            disableElevation={true}
            variant="contained"
            className="text-11 font-400 h-28"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LocationTransferList;

const useStyles = makeStyles(() => ({
  container: {},
}));
